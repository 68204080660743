import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Divider, Tooltip, Typography, IconButton, SvgIcon, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Select, Menu, MenuItem, FormControl, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { createUpdatePlaylist, getInvoicePreview, getPlaylistsByPage, getProductTags, getSignageTemplates } from '../../api';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_404, ERROR_409, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG, formatDate } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery, } from '../../../shared/lib/TableQueryParams';
import { PaginatedResponse } from '../../../shared/models';
import { Playlist } from '../../models';
import { Toast, ToastError, enqueueSnackbar } from '../../../shared/components/Notifier';
import Autocomplete from '@material-ui/lab/Autocomplete';



export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCenter: {
      padding: theme.spacing(1, 1),
      textAlign: 'center'
    },
    subDivider: {
      margin: theme.spacing(1, 0),
    },
    pageTitle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

    }
  }),
);



const PlaylistScreen = (props: any) => {
  // console.log(props);
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [newPlaylist, setNewPlaylist] = useState<any>({});
  const [newPlaylistNameExists, setNewPlaylistNameExists] = useState(null);
  const [selectedBusinessId, setSelectedBusinessId] = useState(props?.userData?.selectedBusiness?.business_id);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
  const [selectedBusinessName, setSelectedBusinessName] = useState('');
  const [newPlaylistTemplate, setNewPlaylistTemplate] = useState('');
  const [newPlaylistTags, setNewPlaylistTags] = useState([]);
  const [listingTemplates, setListingTemplates] = useState();
  const [productTags, setProductTags] = useState();
  const [isOpen, setIsOpen] = useState(false);
  // console.log("user data");
  // console.log(props.userData);

  useEffect(() => {
    getSignageTemplates()
        .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            setListingTemplates(jsonData);
          })
          .catch((error: any) => {
            // props.setPageLoader(false);
            props.showToast({
              message: 'Failed to fetch Listing templates.',
              options: ToastError
            });
          });
  }, [])


  useEffect(() => {
    if (props.userData && props.userData.selectedBusiness) {
      const sbId = props.userData.selectedBusiness.business_id;
      setSelectedBusinessId(sbId);
      setSelectedBusinessName(props.userData.selectedBusiness.business_name);
      tableRef.current && tableRef.current.onQueryChange();


      const pageData = {
        page: 1,
        pageSize: 500,
        businessId: props?.userData?.selectedBusiness?.business_id
      }
      getProductTags(pageData.page, pageData.pageSize, "", pageData.businessId)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            setProductTags(jsonData.results);
          })
          .catch((error: any) => {
            // props.setPageLoader(false);
            props.showToast({
              message: 'Failed to fetch Product Tags.',
              options: ToastError
            });
          });
      // const query = {
      //   page: 0,
      //   pageSize: 
      // }
      // getPlaylistsByPage()         
    }
  }, [props?.userData?.selectedBusiness])

  const navigateToPlaylistDetailScreen = (rowData: any) => {
    const playlistDetailUrl = '/web/signage/playlist/' + rowData.id;
    props.history.push(playlistDetailUrl, rowData);
  };

  const getPlaylistsForBusiness = (query, sbId) => {
    if (!sbId) {
      throw new Error("Select a business first!");
    }
    let pageData = {
      data: [] as Playlist[],
      totalCount: 0,
      page: query.page
    };
    let orderBy = '';
    if (query.orderBy) {
      orderBy = query.orderBy.field as string;
      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
    }
    return getPlaylistsByPage(query.page + 1, query.pageSize, query.search, sbId, orderBy)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PaginatedResponse<Playlist>) => {
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        pageData = {
          data: data.results,
          totalCount: data.count,
          page: query.page
        };
        setTableQuery({
          ...tableQuery,
          orderBy,
          totalCount: pageData.totalCount,
          page: query.page,
          pageSize: query.pageSize,
        });
        return pageData
      })
      .catch((error: any) => {
        if (error) {
          if (error.status && error.status === 500) {
            setEmptyDataSourceMessage(ERROR_500);
            setErrorStatusVisible(false);
          } else if ((error.status === 403 || error.status === 401)
          ) {
            setEmptyDataSourceMessage(ERROR_MSG);
            setErrorStatusVisible(true);
          } else {
            setEmptyDataSourceMessage(ERROR_MSG);
            setErrorStatusVisible(false);
          }
        }
        setTableQuery({
          ...tableQuery,
          totalCount: 0,
          page: query.page,
          pageSize: query.pageSize,
        });
        return pageData;
      });
  } 

  const handleNewPlaylistNameChange = (event: any) => {
    // console.log("NEW-BRAND-NAME",event.target.value)
    let val = event.target.value
    setNewPlaylist({...newPlaylist, name: val});
    setNewPlaylistNameExists(false);
  }

  const onHandleSelectedTagChange = (event: object, value: any, reason: string) => {
    console.log("tag value - ", value);
    // setSelectedTags([...value]);
    setNewPlaylist({...newPlaylist, filter_tags: value});
    setNewPlaylistTags(value)
  }
  const onTagInputChange = (e) => {
    const val = e.target ? (e.target.value ? e.target.value.trim() : '') : '';
    // setSearchTerm(val);
  }


  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
    setNewPlaylist({});
  }
  const handleSave = () => {
    setDialogLoading(true)
    let data = {
      name: newPlaylist.name,
      business: selectedBusinessId,
      playlist_files: [],
      filter_tags: newPlaylistTags.map(t => t.uuid),
      signage_template: newPlaylistTemplate
    }
    let payload = data

    createUpdatePlaylist(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setDialogLoading(false)
        handleDialogBoxCancel()
        tableRef.current && tableRef.current.onQueryChange()
      })
      .catch((error: any) => {
        let msg = 'Failed to add Brand.';

        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
          else if (error.status === 404) {
            msg = ERROR_404
          }
          else if (error.status === 400){
            if (error.data.hasOwnProperty("non_field_errors")) {
              let non_field_errors = error.data.non_field_errors
              non_field_errors = non_field_errors.length > 0 ? non_field_errors[0] : 'Failed'
              if (non_field_errors.includes("business, name must make a unique set")) {
                setNewPlaylistNameExists(true)
              }
            }
            
            msg = ERROR_409
          }

        }
        setDialogLoading(false)
        props.showToast({
          message: msg,
          options: ToastError
        });
      });

  }

  const renderFilterOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.id}>
          {o.name}
        </MenuItem>
      );
    });
  };

  const handleSelectedTemplateChange = (e: any) => {
    console.log("template value - ", e.target.value);
    // setSelectedTags([...value]);
    setNewPlaylist({...newPlaylist, signage_template: e.target.value});
    setNewPlaylistTemplate(e.target.value)
  }

  const renderAddPlaylistDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogBoxOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add a new Playlist</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              <div className={classes.dialogContent} >
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  value={newPlaylist && newPlaylist != null ? newPlaylist.name : null}
                  onChange={handleNewPlaylistNameChange}
                  label="Playlist name"
                  type="text"
                  style={{ width: '12rem' }}
                  disabled={dialogLoading}
                  error={newPlaylistNameExists}
                  helperText={newPlaylistNameExists ? "Already exists for this business" : null}
                  variant='outlined'
                />
                <FormControl style={{ marginBottom: 15 }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    size="small"
                    value={selectedBusinessName}
                    label="Business"
                    type="text"
                    style={{ width: '12rem' }}
                    disabled={true}
                    error={newPlaylistNameExists}
                    variant='outlined'
                  />
                </FormControl>
                <FormControl variant="outlined" style={{ marginBottom: 15 }}>
                  <InputLabel id="demo-simple-select-outlined-label">Template</InputLabel>
                  <Select
                    // label="Template"
                    labelId="demo-simple-select-outlined-label"
                    id={`listing-templates`}
                    value={newPlaylist && newPlaylist != null ? newPlaylist.signage_template : null}
                    onChange={(e) => handleSelectedTemplateChange(e)}
                    // variant="outlined"
                    style={{ width: '12rem' }}
                    label={`Template`}
                    fullWidth={true}
                    margin="dense"
                  >
                    {listingTemplates ? renderFilterOptions(listingTemplates): <></>}
                  </Select>
                </FormControl>
                <div style={{padding: "1rem"}}>
                  <Autocomplete
                    multiple
                    
                    size="small"
                    style={{ width: '100%' }}
                    open={isOpen}
                    onOpen={() => {
                      setIsOpen(true);
                    }}
                    onClose={() => {
                      setIsOpen(false);
                    }}
                    filterSelectedOptions={true}
                    value={newPlaylist && newPlaylist != null ? newPlaylist.filter_tags : []}
                    getOptionSelected={(option, value) => {
                      return option["uuid"] === value["uuid"];
                    }}
                    getOptionLabel={(option) => option["name"]}
                    onChange={onHandleSelectedTagChange}
                    onInputChange={onTagInputChange}
                    options={productTags}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tags"
                        InputProps={{
                          ...params.InputProps
                        }}
                      />
                    )
                    }
                  />
                </div>
                {
                  dialogLoading &&
                  <div style={{ textAlign: 'center', marginTop: 5 }}>
                    <CircularProgress />
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogBoxCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleSave()} style={{ color: "#ff1744" }} disabled={newPlaylistNameExists} >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }

  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <div className="table-wrapper">
        <MaterialTable
          options={{
            debounceInterval: 500,
            ...tableOptions(tableQuery),
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
            draggable: false,
            sorting: true
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage
            }
          }}
          onRowClick={(event, rowData) => navigateToPlaylistDetailScreen(rowData)}
          columns={[
            {
              title: 'Id',
              field: 'id'
            },
            { title: 'name', field: 'name' },
            {
              title: 'Business', 
              field: "business__name",
              render: rowData => {
                return <div>{rowData.business?.name}</div>;
              }
          
            },
            {
              title: 'Created',
              field: 'created_datetime',
              render: rowData => {
                return <div>{formatDate(rowData.created_datetime)}</div>;
              }
            }
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              const pageDataPromise = getPlaylistsForBusiness(query, selectedBusinessId);
              pageDataPromise.then(pageData => {
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                resolve(pageData);
              });
            })
          }
          title={
            <CustomTableTitle
              history={props.history}
              title={'Playlists'}
              icon={SVG_ICONS.template} />
          }
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
            {
              icon: 'add',
              tooltip: 'Add Playlist',
              isFreeAction: true,
              onClick: () => setDialogBoxOpen(true),
            },
          ]}
        />
      </div>
      {renderAddPlaylistDialogBox()}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PlaylistScreen);