import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';

import { 
  Typography, Snackbar, SvgIcon, Paper, Icon, IconButton, Divider, Checkbox,FormControl,Button,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, InputLabel,Select, CircularProgress
 } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { StockReceipt } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';
import { Filter, FilterType } from '../../../shared/models/Filter';

import { getPriceRoundList, updatePriceRound, deletePriceRound, addPriceRound } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { getProductPriceForPos } from 'components/src/modules/products/lib/price';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import { getMasterCompaniesFullList } from '../../../master-data/api'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style_1.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    divider: {
      margin: theme.spacing(0.5, 0),
    },

    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

    },
  }),
);

const PriceRoundScreen = (props) => {
  const classes = useStyles();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  const [addNewPriceRoundRecDialogOpen, setAddNewPriceRoundRecDialogOpen] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)

  const [newPriceMin, setNewPriceMin] = useState('')
  const [newPriceMax, setNewPriceMax] = useState('')
  const [newPriceRound, setNewPriceRound] = useState('')


  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);

  const changePriceRound = (newData, oldData = null) => {
    setLoading(true);
    console.log("props", props)
    const { userData } = props.userData.userData;
    if (userData) {
      const sb = props.userData.selectedBusiness;
      newData['business_details'] = sb;
      newData['user_details'] = { auth_token: userData.auth_token };
      updatePriceRound(newData)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then((jsonData) => {
          setLoading(false);
          setErrorStatusVisible(false);
          setEmptyDataSourceMessage(EMPTY_ROW_MSG);
          props.showToast({
            message: 'Price round updated successfully!',
            options: ToastSuccess,
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          let msg = 'Failed to update Price round.';
          if (error && error.status) {
            if (error.status === 403 || error.status === 401) {
              msg = NO_PERMISSIONS_ERROR_MSG;
            } else if (error.status === 500) {
              msg = ERROR_500;
            }
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false);
          tableRef.current && tableRef.current.onQueryChange();

        });
    }
  };

  const deletePriceRoundRecord = (newData, oldData = null) => {
    setLoading(true);
    const { userData } = props.userData.userData;
    if (userData) {
      const sb = props.userData.selectedBusiness;
      newData['business_details'] = sb;
      newData['user_details'] = { auth_token: userData.auth_token };
      deletePriceRound(newData)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then((jsonData) => {
          setLoading(false);
          setErrorStatusVisible(false);
          setEmptyDataSourceMessage(EMPTY_ROW_MSG);
          props.showToast({
            message: 'Record deleted successfully!',
            options: ToastSuccess,
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          let msg = 'Failed to delete record';
          if (error && error.status) {
            if (error.status === 403 || error.status === 401) {
              msg = NO_PERMISSIONS_ERROR_MSG;
            } else if (error.status === 500) {
              msg = ERROR_500;
            }
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false);
          tableRef.current && tableRef.current.onQueryChange();

        });
    }
  };

  const renderTable = () => {
    return (
      <div>
        {isErrorStatusVisible && (
          <Snackbar
            open={isErrorStatusVisible}
            message="An error occurred"
            action={
              <IconButton size="small" color="inherit" onClick={() => setErrorStatusVisible(false)}>
                Close
              </IconButton>
            }
          />
        )}
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true,
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage,
              },
            }}
            columns={[
              { title: 'Price Min', field: 'price_min', editable: 'always', cellStyle: { minWidth: 50 } },
              { title: 'Price Max', field: 'price_max', editable: 'always', cellStyle: { minWidth: 50 } },
              { title: 'Price Round', field: 'price_round', editable: 'always', cellStyle: { minWidth: 50 } },
              {
                title: 'Enabled',
                field: 'is_deleted',
                type: 'boolean',
                sorting: false,
                render: (rowData: any) => (
                  <FiberManualRecordIcon
                    style={{ color: !rowData.is_deleted ? GlobalStyles.green : GlobalStyles.red }}
                  />
                ),
                editComponent: (props) => (
                  <Checkbox
                    checked={!props.value}
                    onChange={(e) => props.onChange(!e.target.checked)}
                    color="primary"
                  />
                ),
              },
            ]}

            data={(query) =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  const sb = props.userData.selectedBusiness;
                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [],
                      totalCount: 0,
                      page: query.page,
                    };
                    let orderBy: any;
                    if (query.orderBy) {
                      orderBy = query.orderBy.field;
                      orderBy = `${query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    }
                    setLoading(true);
                    getPriceRoundList(sb.business_id, query.page + 1, query.pageSize, query.search, orderBy)
                      .catch(handleError)
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page,
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize,
                        });
                        setLoading(false);
                        resolve(pageData);
                      })
                      .catch((error) => {
                        if (error) {
                          if (error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                          } else if (error.status === 403 || error.status === 401) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                          }
                        }
                        setLoading(false);
                        resolve(pageData);
                      });
                  }
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Price Round'}
                icon={SVG_ICONS.addTag}
              />
            }
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  changePriceRound(newData, oldData);
                  // @ts-ignore
                  resolve();
                }),

              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  deletePriceRoundRecord(oldData)
                  // @ts-ignore
                  resolve();
                }),
            }}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'add_box',
                tooltip: 'Add Price Round Off',
                isFreeAction: true,
                onClick: (event) => setAddNewPriceRoundRecDialogOpen(true),
              },
            ]}
          />
        </div>
      </div>
    );
  };

  const handleNewPriceRoundDialogBoxCancel =()=>{
    setAddNewPriceRoundRecDialogOpen(false)
    setNewPriceMin('')
    setNewPriceMax('')
    setNewPriceRound('')

  }

  const handleNewPriceMinChange = (event) => {
    const newPriceMinValue = event.target.value;
    // Check if the value is a valid number
    if (newPriceMinValue === '' || newPriceMinValue.match(/^-?\d*\.?\d*$/)) {
      setNewPriceMin(newPriceMinValue);
    }
  }
  const handleNewPriceMaxChange = (event) => {
    const newPriceMaxValue = event.target.value;
    // Check if the value is a valid number
    if (newPriceMaxValue === '' || newPriceMaxValue.match(/^-?\d*\.?\d*$/)) {
      setNewPriceMax(newPriceMaxValue);
    }
  }
  const handleNewPriceRoundChange = (event) => {
    const newPriceRoundValue = event.target.value;
    // Check if the value is a valid number
    if (newPriceRoundValue === '' || newPriceRoundValue.match(/^-?\d*\.?\d*$/)) {
      setNewPriceRound(newPriceRoundValue);
    }
  }

  const handleNewRecSave =() =>{
    console.log("Save is called")
    setLoading(true);
    const { userData } = props.userData.userData;
    if (userData) {
      let newData = {}
      const sb = props.userData.selectedBusiness;
      newData['business_details'] = sb;
      newData['user_details'] = { auth_token: userData.auth_token };
      newData['price_min'] =  newPriceMin
      newData['price_max'] =  newPriceMax
      newData['price_round'] = newPriceRound

      addPriceRound(newData)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then((jsonData) => {
          setLoading(false);
          setErrorStatusVisible(false);
          setEmptyDataSourceMessage(EMPTY_ROW_MSG);
          props.showToast({
            message: 'New record successfully!',
            options: ToastSuccess,
          });
          handleNewPriceRoundDialogBoxCancel()
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          let msg = 'Failed to add record';
          if (error && error.status) {
            if (error.status === 403 || error.status === 401) {
              msg = NO_PERMISSIONS_ERROR_MSG;
            } else if (error.status === 500) {
              msg = ERROR_500;
            }
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false);
          // handleNewPriceRoundDialogBoxCancel()
          // tableRef.current && tableRef.current.onQueryChange();

        });
    }
  }

  const newPriceRoundRecDialog =() =>{
    return (
      <div>
        {
          <Dialog
            open={addNewPriceRoundRecDialogOpen}
            onClose={handleNewPriceRoundDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add a new Price Round Off</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              {
                dialogLoading &&
                <div style={{ textAlign: 'center', marginTop: 5 }}>
                  <CircularProgress />
                </div>
              }
              {
                !dialogLoading &&
                <div className={classes.dialogContent} >
                  <TextField
                    autoFocus
                    margin="dense"
                    size="small"
                    value={newPriceMin && newPriceMin != null ? newPriceMin : null}
                    onChange={handleNewPriceMinChange}
                    label="Price Min"
                    type="text"
                    style={{ width: '8rem' }}
                    disabled={dialogLoading}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    size="small"
                    value={newPriceMax && newPriceMax != null ? newPriceMax : null}
                    onChange={handleNewPriceMaxChange}
                    label="Price Max"
                    type="text"
                    style={{ width: '8rem' }}
                    disabled={dialogLoading}
                  />
                  <TextField
                    autoFocus
                    margin="dense"
                    size="small"
                    value={newPriceRound && newPriceRound != null ? newPriceRound : null}
                    onChange={handleNewPriceRoundChange}
                    label="Price Round"
                    type="text"
                    style={{ width: '8rem' }}
                    disabled={dialogLoading}
                  />
                  
                </div>
              }

            </DialogContent>
            {
              !dialogLoading &&
              <DialogActions>
                <Button onClick={handleNewPriceRoundDialogBoxCancel} color="primary" autoFocus>
                  Cancel
                </Button>
                <Button onClick={() => handleNewRecSave()}>
                  OK
                </Button>
              </DialogActions>
            }

          </Dialog>
        }
      </div>
    )
  }

  return (
  <div>
    {renderTable()}
    {newPriceRoundRecDialog()}
  </div>
  )
};

const mapStateToProps = (state) => ({
  userData: state.userLocalData,
});

const mapDispatchToProps = (dispatch) => ({
  showToast: (toast) => dispatch(enqueueSnackbar(toast)),
  setPageLoader: (isLoading) => dispatch(setPageLoading(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceRoundScreen);
