import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Paper, Typography, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { EXPECTED_HEADERS_SHORT_NAME } from '../../models';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import isEqual from 'lodash/isEqual';
import { LocationBusiness } from '../../../shared/models';
import { CustomTableTitle } from '../../../shared/components';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
//import {getMatchedItemSummary} from '../../../screens/StockOrderImport/helper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import cloneDeep from 'lodash/cloneDeep';

import { getDispatchNotificationsInvoiceProductList } from '.././../api'
import FixInvoiceItemModal from '../FixInvoiceItemModal/FixInvoiceItemModal';
import { updateDnProductInList, deleteDnProductInList, addNewDnItem } from '../../data/actions';
// import { getDNManifestItemsToMatch, getDNProductsMatchedItemsSummary, getDNRequestData, } from '../../screens/ImportDispatchNotification/helper';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningText: {
      color: '#ff8c00',
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    tableWrapper: {
      padding: theme.spacing(2, 2),
      borderRadius: 0
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    summaryWrapper: {
      border: '1px solid #ddd',
      paddingLeft: 16,
      paddingRight: 16,
      marginBottom: 16
    },
    rowSummary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: 8
    },
    errorText: {
      fontSize: '0.875rem',
      color: theme.palette.error.main,
      fontWeight: 400
    },
    successText: {
      color: '#43a047',
      fontSize: '0.875rem',
      fontWeight: 400
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    helpBtn: {
      margin: theme.spacing(1),
      padding: 8,

      // color: theme.palette.primary.main
    }
  }),
);
const initalDialogData = {
  open: false,
  business: null,
  onClose: null,
  mode: 'add',
  linkedRecord: null,
  suggestions: [],
  invoiceItemDescription: null
};

const fixDialogData = {
  open: false,
  onClose: null as any,
  invoiceItem: null as any,
  business: null as null | LocationBusiness
};

const MapInvoiceProduct = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    dNSummary,
    getDNProducts,
    specificHeaders,
    caseQtyDataError,
    isCaseQtyMapped,
    uiLoading,
    windowWidth, windowHeight, DNProductList,
    selectedFile
  } = props;

  // console.log("getDNProducts",getDNProducts)
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));

  const difference = Number(dNSummary.expectedTotal) - Number(dNSummary.calculatedTotal);
  const differenceString = difference.toFixed(2);
  const [invoiceItemModalData, setInvoiceItemModaldata] = useState(fixDialogData);
  const [invoiceItemList, setInvoiceItemList] = useState({
    data: [],
    isFetching: true,
    success: false,
    error: false,
    errorData: null,
    hasErrors: false,
  });

  const [toggleFilterButton, setToggleFilterButton] = useState(false);
  const [filteredErrorData, setFilteredErrorData] = useState([]);
  const tableRef = useRef();
  const [itemData, setItemData] = useState([]);
  const [errorSuccess, setErrorSuccess] = useState(false);

  useEffect(() => {
    console.log("PROPSSSS-STEP4", props)
  }, [props])

  useEffect(() => {
    if (props.dnData.dn_list != null) {

      console.log("PROPS-STEp 4", props)
      if (!isEqual(invoiceItemList.data, props.dnData.dn_list.dnItemsList)) {
        //console.log("DNProductList",DNProductList);
        setInvoiceItemList({ ...invoiceItemList, data: props.dnData.dn_list.dnItemsList });
        if (toggleFilterButton) {
          const items = props.dnData.dn_list.dnItemsList
          const filteredByError = items.filter(data => data.error ? data.error !== '' : null)
          setItemData(cloneDeep(filteredByError))
        }
        else {
          setItemData(cloneDeep(props.dnData.dn_list.dnItemsList))
        }
        console.log("invoiceItemList----", invoiceItemList)
      }
    }

  }, [props.dnData.dn_list]);

  useEffect(() => {
    if (toggleFilterButton === true) {
      const items = invoiceItemList.data
      const filteredByError = items.filter(data => data.error ? data.error !== '' : null)
      console.log("filteredbyerror", filteredByError)
      setItemData(cloneDeep(filteredByError))
      setErrorSuccess(false)
    }
    else {
      if (invoiceItemList && invoiceItemList.data && invoiceItemList.data.length > 0) {
        setItemData(cloneDeep(invoiceItemList.data))
      }
    }
  }, [toggleFilterButton, errorSuccess])


  useEffect(() => {
    tableRef && tableRef.current
    // tableRef && tableRef.current && tableRef.current.onChangePage();
  }, [itemData]);

  const fixInvoiceItem = (data: any) => {
    let { id, name } = props.selectedSupplier.master_company;
    let business = { business_id: id, business_name: name };
    console.log("data -115--- ", data)
    switch (data.error_type) {
      case 'link_business_product':
        const sb = props.userData.selectedBusiness;
        business = sb;
        break;
      case 'supplier_product':
        break;
      case 'link_supplier_product':
        break;
      case 'supplier_code_or_quantity_missing':
        break;
      case 'different_quantity_same_reorder_code':
        break;
      case 'different_reorder_code_same_quantity':
        break;
      case 'supplier_record_missing':
        break;
      case 'link_supplier_missing':
        break;
      case 'different_quantity_different_reorder_code':
        break;
      case 'supplier_product_not_approved':
        break;
    }
    //console.log("data -135 ",data)
    if (data.error_type === 'different_reorder_code_same_quantity' && data.product.suppliers.length > 1) {
      //find the matching quantity reorder code and set retailer order code and number here
      data.supplier_code = data.confirmed_reorder_code
      data.supplier_quantity = data.master_supplier_container_qty
      data.product.suppliers.forEach((element) => {
        if (element.boxSize === data.master_supplier_container_qty) {
          data.retailer_quantity = element.boxSize
          data.retailer_reorder_code = element.orderNumber
        }
      })
    }
    else if (data.error_type === 'different_quantity_same_reorder_code' && data.product.suppliers.length > 1) {
      data.supplier_code = data.confirmed_reorder_code
      data.supplier_quantity = data.master_supplier_container_qty

      data.product.suppliers.forEach((element) => {
        if (element.orderNumber == data.confirmed_reorder_code) {
          data.retailer_quantity = element.boxSize
          data.retailer_reorder_code = element.orderNumber
        }
      })
    }
    else {
      data.supplier_code = data.confirmed_reorder_code
      data.supplier_quantity = data.master_supplier_container_qty

      if (data.error_type === 'supplier_record_missing' || data.error_type === 'link_business_product' || data.error_type === 'link_supplier_product' || data.error_type === 'supplier_product' || data.error_type === 'link_supplier_missing' || data.error_type === 'supplier_product_not_approved') {
        data.retailer_quantity = null
        data.retailer_reorder_code = null
      }
      else {
        data.retailer_quantity = data.product.suppliers[0].boxSize
        data.retailer_reorder_code = data.product.suppliers[0].orderNumber
      }
    }


    setInvoiceItemModaldata({
      open: true,
      business: business,
      invoiceItem: data,

      onClose: (invoiceItem: any) => {
        if (invoiceItem) {
          props.updateMatchedItem(invoiceItem);
        }
        setInvoiceItemModaldata(fixDialogData);
      }
    });

  };




  const showHelpText = () => {
    return (
      <div>
        <Typography variant="body1" style={{ padding: '4px 8px' }}>
          {`If calculated total is in green and item has error, still dispatch notification can be created without fixing the error.`}
        </Typography>
        <Typography variant="body1" style={{ padding: '4px 8px' }}>
          {`Its upto the user to fix such errors.`}
        </Typography>
      </div>
    )
  }

  const matchInvoiceItem = (invoiceItem:any, newRecordToBeAdded:Boolean) => {//used when editing and updating a single record
    let item = { ...invoiceItem };
    ////    delete these keys for a new response from api;
    console.log("item", item)
    delete item.error_type;
    delete item.error;
    delete item.suggestions;
    item.product_edited_or_updated = true
    item.product = {}
    item.master_supplier_container_qty = ''
    item.casesDelivered = Number(item.casesDelivered)
    item.unitsDelivered = Number(item.unitsDelivered)
    item.retailer_quantity = ""
    item.retailer_reorder_code = ""
    item.supplier_code = ""
    item.supplier_quantity = ""
    item.product_sku= (item.product_sku)
    if (item.costPerCase !== '') {
      let cpc = item.costPerCase
      let dec = cpc.split('.')[1]
      let len = dec && dec.length > 2 ? dec.length : 2
      item.costPerCase = Number(cpc).toFixed(len)
    }
    if (item.costPerUnit !== '') {
      let cpu = item.costPerUnit
      let dec = cpu.split('.')[1]
      let len = dec && dec.length > 2 ? dec.length : 2
      item.costPerUnit = Number(cpu).toFixed(len)
    }
    console.log('matchInvoiceItem before passing', item);
    let payload = {
      vendor_data: props.dnData.vendorData.products,
      dnItems: [item],
      business_id: props.selectedSupplier.business.id,
      korona_supplier_business_id: null,
      master_company_id: props.selectedSupplier.master_company.id,
      user_auth_token : props.userData.userData.userData.auth_token
    };
    getDispatchNotificationsInvoiceProductList(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData: any) => {
        // if (jsonData && jsonData.has_errors) {
        //   let msg = 'Failed to match some invoice items!';
        //   props.showToast({ message: msg, options: ToastError });
        // }
        // if (jsonData.dnItemsList && jsonData.dnItemsList.length > 0) {
        //   const matchedItemSummary = getDNProductsMatchedItemsSummary(jsonData.dnItemsList, dNSummary);
        //   jsonData['dnItemsList'] = cloneDeep(matchedItemSummary.items)
        //   setDNSummary(matchedItemSummary.summary);
        //   props.saveDnList(cloneDeep(jsonData));
        // }
        // let data = cloneDeep(DNProductList)
        console.log("newRecordToBeAdded", newRecordToBeAdded)
        if (newRecordToBeAdded === true) {
          console.log("Addnew", jsonData.dnItemList[0])
          props.addNewItemDNList(jsonData.dnItemList[0])
        }else{
          props.updateMatchedItem(jsonData.dnItemList[0]);

        }
      })
      .catch((err: any) => {

      });
  };

  const handleToggleChange = () => {
    if (toggleFilterButton === true) {
      setToggleFilterButton(false);
    }
    else {

      setToggleFilterButton(true)
    }

  }

  const getTableBodyMaxHeight = () => {
    const style = { maxHeight: 0 };
    if (windowWidth) {
      if (windowWidth >= 2260) {
        style.maxHeight = 900;
      } else if (windowWidth >= 1920) {
        style.maxHeight = 462;
        // style.maxHeight = 552;
      } else if (windowWidth >= 1600) {
        style.maxHeight = 400;
      } else if (windowWidth >= 1440) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1366) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1280) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1080) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1024) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 800) {
        style.maxHeight = 245;
      }
      return style;
    }
    return style;
  };

  const handleCloseFixItem = (item?: any) => {
    if (item) {
      console.log("InMAtchInvoice---", item)
      props.updateMatchedItem(item);
    }
    setInvoiceItemModaldata(cloneDeep(fixDialogData));
  }

  const renderTable =()=>{
    return(
      <div>
      {
        invoiceItemList &&
        !uiLoading &&
        invoiceItemList.data &&
        <MaterialTable
          tableRef={tableRef}
          options={{
            ...tableOptions(tableQuery),
            padding: 'dense',
            pageSizeOptions: [10, 50, 100, 500],
            pageSize: 100,
            maxBodyHeight: getTableBodyMaxHeight().maxHeight,

          }}
          columns={[
            {
              title: 'Row',
              field: EXPECTED_HEADERS_SHORT_NAME.itemIndex,
              editable: 'never',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              }
            },
            {
              title: 'Serial Number',
              field: EXPECTED_HEADERS_SHORT_NAME.SERIAL_NUMBER,
              editable: 'never',
              hidden: !specificHeaders.serialNumber,
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              }

            },
            {
              title: 'ReOrder Code',
              field: EXPECTED_HEADERS_SHORT_NAME.RE_ORDER_CODE,
              editable: 'always',
              hidden: !specificHeaders.reOrderCode,
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              }

            },
            {
              title: 'Invoice Description',
              field: EXPECTED_HEADERS_SHORT_NAME.SUPPLIER_PRODUCT_NAME,
              editable: 'always',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.supplierProductName && rowData.supplierProductName) {
                  return <Typography style={{ minWidth: 200 }} className={classes.defaultText} >{rowData.supplierProductName}</Typography>
                }
                return ''
              }
            },
            {
              title: 'Korona Product',
              field: 'product.name',
              editable: 'never',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.product && rowData.product['name']) {
                  return <Typography style={{ minWidth: 213 }} className={classes.defaultText}>{rowData.product['name']}</Typography>;
                }
                return <Typography style={{ minWidth: 213 }} className={classes.errorText}>Product Mapping Not Found</Typography>;
              }
            },
            {
              title: 'Case Quantity',
              field: EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY,
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY] && rowData.master_supplier_container_qty) {
                  if (Number(rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]) !== Number(rowData.master_supplier_container_qty))
                    return <Typography className={classes.errorText}>{rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]}</Typography>;
                }
                return <Typography >{rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]}</Typography>;
              },
              editable: 'onUpdate',
              hidden: !specificHeaders.caseQuantity
            },
            {
              title: 'Container Quantity',
              field: 'master_supplier_container_qty',
              editable: 'never',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.master_supplier_container_qty && rowData.master_supplier_container_qty) {
                  return <Typography className={classes.defaultText} >{`${rowData.master_supplier_container_qty}`}</Typography>
                }
                return ''
              }
            },
            {
              title: 'Cases Delivered',
              field: EXPECTED_HEADERS_SHORT_NAME.CASES_DELIVERED,
              editable: 'always',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              hidden: !specificHeaders.casesDelivered,
              render: rowData => {
                if (rowData.casesDelivered && rowData.casesDelivered) {
                  return <Typography className={classes.defaultText} >{`${rowData.casesDelivered}`}</Typography>
                }
                return ''
              }
            },
            {
              title:'Korona Cost',
              field:'product',
              editable:'never',
              cellStyle: {
                textAlign: 'left',
              },
              render: rowData => {
                if (rowData.product && rowData.product.suppliers) {
                  if(rowData.warning_type === 'new_supp_rec'){
                    return ''
                  }
                  let kPrices= []
                  kPrices = rowData.product.suppliers.map((s)=>s.price)
                  // show the prices here
                  return <Typography className={classes.defaultText} >{`${kPrices.toString()}`}</Typography>
                }
                return ''
              }
            },
            {
              title: 'Cost Per Case',
              field: EXPECTED_HEADERS_SHORT_NAME.COST_PER_CASE,
              editable: 'always',
              hidden: !specificHeaders.costPerCase,
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.costPerCase && rowData.costPerCase) {
                  return (
                    <Typography className={classes.defaultText} >{`$ ${rowData.costPerCase}`}</Typography>
                  )
                }
                return ''
              }
            },
            {
              title: 'Units Delivered',
              field: EXPECTED_HEADERS_SHORT_NAME.UNITS_DELIVERED,
              editable: 'always',
              hidden: !specificHeaders.unitsDelivered,
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.unitsDelivered && rowData.unitsDelivered) {
                  return <Typography className={classes.defaultText} >{`${rowData.unitsDelivered}`}</Typography>
                }
                return ''
              }
            },
            {
              title: 'Cost Per Unit',
              field: EXPECTED_HEADERS_SHORT_NAME.COST_PER_UNIT,
              editable: 'always',
              hidden: !specificHeaders.costPerUnit,
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.costPerUnit && rowData.costPerUnit) {
                  return <Typography className={classes.defaultText} >{`$ ${rowData.costPerUnit}`}</Typography>
                }
                return ''
              }
            },
            {
              title: 'Line Total',
              field: EXPECTED_HEADERS_SHORT_NAME.LINE_TOTAL,
              editable: 'always',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.lineTotal && rowData.lineTotal) {
                  return <Typography className={classes.defaultText} >{`$ ${rowData.lineTotal}`}</Typography>
                }
                return ''
              }
            },
            {
              title: 'Calculated Total',
              field: EXPECTED_HEADERS_SHORT_NAME.calculatedLineTotal,
              editable: 'never',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.lineTotal === rowData.calculatedLineTotal) {
                  return (
                    <Typography
                      className={classes.successText}>
                      {`$ ${rowData.calculatedLineTotal}`}
                    </Typography>
                  );
                }
                return (
                  <Typography
                    className={classes.errorText}>
                    {`$ ${rowData.calculatedLineTotal}`}
                  </Typography>
                );
              }
            },
            {
              title: 'Issue',
              field: 'error',
              editable: 'never',
              cellStyle: {
                textAlign: 'left',
              },
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.error) {
                  return <Typography style={{ minWidth: 165 }} className={classes.errorText}>{rowData.error}</Typography>;
                }
                else if (rowData.warning) {
                  return <Typography style={{ minWidth: 165 }} className={classes.warningText}>{rowData.warning}</Typography>;
                }
                return <Typography style={{ minWidth: 30 }} className={classes.defaultText}>----</Typography>;
              }
            },
            {
              title: 'Action',
              field: 'error',
              editable: 'never',
              headerStyle: {
                minWidth: 30
              },
              render: rowData => {
                if (rowData.error) {
                  if (!(['link_supplier_missing', 'supplier_code_or_quantity_missing', 'new_code_already_exists_in_diff_product', 'supplier_product_reorder_code'].includes(rowData.error_type))) {
                    return (
                      <div>
                        <Button
                          variant="contained"
                          style={{ textTransform: 'none' }}
                          onClick={() => fixInvoiceItem(rowData)}>
                          Fix
                        </Button>
                      </div>
                    );
                  }
                }
                else if (rowData.warning) {
                  if (rowData.warning_type === 'new_supp_rec') {
                    return (
                      <>
                        <Typography className={classes.warningText} style={{ minWidth: 250 }}>
                          {`Add new supplier record for this item on Korona with ${rowData.reOrderCode},${rowData.master_supplier_container_qty}, ${rowData.costPerUnit}. `}
                        </Typography>

                        <Typography className={classes.warningText} style={{ minWidth: 250 }}>
                          {`(Automatic, when Create Dispatch Notification is clicked)`}
                        </Typography>
                      </>
                    )
                  }
                  return (
                    <Typography className={classes.warningText} style={{ minWidth: 165 }}>
                      {rowData.warning_type && rowData.warning_type === 'cost_diff' ? `There is a difference in price, imported price and CODE/PLU will be updated in Korona` : `Supplier Record is missing on Retailer, New Record will be created on korona`}
                    </Typography>
                  )
                }
                return <Typography className={classes.defaultText}>----</Typography>;
              }
            },
          ]}
          // data={invoiceItemList.data}
          data={itemData}

          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const newRecordToBeAdded = false;
                  resolve(matchInvoiceItem(newData, newRecordToBeAdded));
                }, 1000)
              }),
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  // console.log(newData)
                  newData.product_sku =''
                  newData.calculatedLineTotal = ''
                  newData.caseQuantity = ''
                  newData.costPerCase = ''
                  newData.master_supplier_container_qty = ''
                  newData.product = {}
                  if (DNProductList.slice(-1)[0].headerContainsReOrderCode === true) { //check in the last item if pdf contains reOrderCode
                    newData.headerContainsReOrderCode = true
                  }
                  else {
                    newData.headerContainsReOrderCode = false
                    newData.reOrderCode = ''
                  }
                  // newData.reOrderCode = ''
                  newData.reOrderCodeOfRetailerToSend = false
                  newData.serialNumber = ''
                  newData.casesDelivered = ''
                  // newData.headerContainsReOrderCode=false
                  newData.itemIndex = Number(DNProductList.slice(-1)[0].itemIndex + 1)
                  const newRecordToBeAdded = true;
                  console.log("newData~~~~~", newData)
                  resolve(matchInvoiceItem(newData, newRecordToBeAdded));
                }, 1000)
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const data = invoiceItemList.data.find(element => element.itemIndex === oldData.itemIndex);
                  resolve(props.deleteDnProductInList(data));

                }, 1000)
              }),

          }}
          title={
            invoiceItemList &&
            !uiLoading &&
            invoiceItemList.data &&
            <div>
              <div className={classes.rowSummary}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <span style={{ fontSize: 17 }}
                      className={
                        dNSummary.unMappedProducts > 0 ?
                          classes.errorText : classes.successText
                      }
                    >Items: {dNSummary.itemCount}({dNSummary.unMappedProducts})&nbsp;&nbsp;&nbsp;</span>
                    <span style={{ fontSize: 17 }}
                      className={
                        dNSummary.calculatedTotal !== dNSummary.expectedTotal ?
                          classes.errorText : classes.successText
                      }
                    >{`Total: $ ${dNSummary.expectedTotal} ($ ${differenceString})`}</span>
                  </div>
                  <div style={{ justifySelf: 'flex-end', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button variant="contained"
                      className={classes.button}
                      disabled={props.reloadMappingButtonDisabled}
                      onClick={() => getDNProducts()}
                    >
                      Reload Mapping
                    </Button>
                  </div>
                  <div>
                    <Tooltip

                      title={showHelpText()}
                    >
                      <IconButton aria-label="help" color="primary"
                        className={classes.helpBtn}
                      >
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div>
                    <FormControlLabel
                      control={<Switch checked={toggleFilterButton} onChange={handleToggleChange} name="checkedA" />}
                      label="Filter Errors"
                    />
                  </div>
                </div>
              </div>
              {
                isCaseQtyMapped && caseQtyDataError.hasError &&
                <div style={{ textAlign: 'right' }}>
                  <Typography variant='body1'
                    className={classes.errorText}>{caseQtyDataError.message}</Typography>
                </div>
              }
            </div>
          }
        />
      }
      </div>
    )
  }

  return (
    <div>
      <Paper className={classes.tableWrapper} elevation={0}>
        {renderTable()}        
        {
          invoiceItemList &&
          !uiLoading &&
          invoiceItemList.data && invoiceItemList.hasErrors === false &&
          props.setStepFourSuccess(true)

        }
        <FixInvoiceItemModal
          isOpen={invoiceItemModalData.open}
          business={invoiceItemModalData.business}
          invoiceItem={invoiceItemModalData.invoiceItem}
          selectedSupplier={props.selectedSupplier}
          onClose={(item?) => handleCloseFixItem(item && item)}
          errorSuccess={setErrorSuccess}
          selectedFile={props.selectedFile && props.selectedFile}
          setInvoiceItemModaldata={(data: any) => setInvoiceItemModaldata(data)}
          fixDialogData={fixDialogData}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
    dnData: state.dnModuleData.dnData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    updateMatchedItem: (invoiceItem: any) => dispatch(updateDnProductInList(invoiceItem)),
    deleteDnProductInList: (itemIndex: number) => dispatch(deleteDnProductInList(itemIndex)),
    addNewItemDNList:(item:any)=>dispatch(addNewDnItem(item))
  };


};


export default connect(mapStateToProps, mapDispatchToProps)(MapInvoiceProduct);
