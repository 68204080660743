
import { combineReducers, Action } from 'redux';
import {
    GET_DEVICES, GET_DEVICES_FAILURE, GET_DEVICES_SUCCESS,
    UPDATE_DEVICE, DELETE_DEVICE, ADD_DEVICE, GET_DEVICE_AUTHORIZED_ACTIVE_COUNT,
    GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_SUCCESS, GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_FAILURE,
    GET_DEVICE_SUMMARY, GET_DEVICE_SUMMARY_FAILURE, GET_DEVICE_SUMMARY_SUCCESS
} from '../actions/actionTypes';
import { Device } from '../../models';


export interface InitialState {
    data: Device[];
    selectedDevice: Device | null;
}

const initialState: InitialState = {
    data: [] as Device[],
    selectedDevice: null
};


export function deviceListReducer(state = initialState, action: any) {
    switch (action.type) {
        case GET_DEVICES: return {
            ...state,
            isFetching: true,
            success: false,
            error: false
        };
        case GET_DEVICES_SUCCESS: return {
            ...state,
            isFetching: false,
            data: action.data.results,
            success: true,
            error: false
        };
        case GET_DEVICES_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true
        };
        case ADD_DEVICE:
            if (action.data) {
                const newState = { ...state };
                const deviceList = [...newState.data];
                const filteredList = deviceList.filter(d => d.device_id === action.data.device_id);
                if (filteredList.length === 0) {
                    deviceList.push(action.data);
                }
                return {
                    ...state,
                    isFetching: false,
                    data: deviceList,
                    success: true,
                    error: false
                };
            }
        case UPDATE_DEVICE:
            if (action.data) {
                const newState = { ...state };
                const deviceList = [...newState.data];
                for (let i = 0; i < deviceList.length; i++) {
                    if (deviceList[i].id === action.data.id) {
                        deviceList[i] = action.data;
                    }
                }
                return {
                    ...state,
                    isFetching: false,
                    data: deviceList,
                    success: true,
                    error: false
                };
            }
        case DELETE_DEVICE:
            if (action.data) {
                const newState = { ...state };
                const deviceList = [...newState.data];
                for (let i = 0; i < deviceList.length; i++) {
                    if (deviceList[i].id === action.data.id) {
                        deviceList.splice(i, 1);
                    }
                }
                return {
                    ...state,
                    isFetching: false,
                    data: deviceList,
                    success: true,
                    error: false
                };
            }

        default: return state;

    }
}

const initialDeviceSummaryState = {
    activeCount: 0,
    authorizedCount: 0,
    totalCount: 0,
    isFetching: false,
    success: false,
    error: false,
    errorData: null
};

export function deviceSummaryDataReducer(state = initialDeviceSummaryState, action: any) {
    switch (action.type) {
        case GET_DEVICE_SUMMARY: return {
            ...state,
            isFetching: true,
            success: false,
            error: false,
            errorData: null
        };
        case GET_DEVICE_SUMMARY_SUCCESS: return {
            ...state,
            isFetching: false,
            ...action.data,
            success: true,
            error: false,
            errorData: null
        };
        case GET_DEVICE_SUMMARY_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        };
        default: return state;
    }
}

export const deviceModuleReducer = combineReducers({
    deviceList: deviceListReducer,
    deviceSummaryData: deviceSummaryDataReducer,
});