import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { RMSItemIssue } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import moment from 'moment';

const getProductsByPage = (locationIds: number[], page: number, pageSize: number, searchTerm: string) => {
    let URL = API_URLS.rmsHQItemsReport + `?location_id__in=${locationIds}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};

const RmsHQItemIssues = (props: any) => {
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            tableRef && tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedLocations]);

    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{
                        ...tableOptions(tableQuery),
                        exportAllData: true,
                        exportButton: true,
                        exportFileName: `RMS_HQ_Item_Issues_${moment().format('YYY-MM-DD')}`,
                        exportDelimiter: "\t"
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={[
                        { title: 'HQ Item ID', field: 'HQItemID' },
                        { title: 'HQ Item Name', field: 'HQItemName' },
                        { title: 'HQ Codes', field: 'HQCodes' },
                        { title: 'Store Name', field: 'StoreName' },
                        { title: 'Store HQ ID', field: 'StoreHQID' },
                        { title: 'Store Item Name', field: 'StoreItemName' },
                        { title: 'Store Codes', field: 'StoreCodes' },
                        { title: 'Issue', field: 'Issue' },
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            if (props.userData && props.userData.selectedLocations && !isLoading) {
                                setLoading(true);
                                replaceHistory(query, props.history);
                                let pageData = {
                                    data: [] as RMSItemIssue[],
                                    totalCount: 0,
                                    page: query.page
                                };
                                const { selectedLocations } = props.userData;
                                const locationsIDs = Object.keys(selectedLocations).map(key => selectedLocations[key].id);
                                getProductsByPage(locationsIDs,
                                    query.page + 1, query.pageSize,
                                    query.search)
                                    .catch(handleError) // handle network issues
                                    .then(checkStatus)
                                    .then(parseJSON)
                                    .then((data: PaginatedResponse<RMSItemIssue>) => {
                                        setErrorStatusVisible(false);
                                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                        pageData = {
                                            data: data.results,
                                            totalCount: data.count,
                                            page: query.page
                                        };
                                        setTableQuery({
                                            ...tableQuery,
                                            totalCount: data.count,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        setLoading(false);
                                        resolve(pageData);
                                    })
                                    .catch((error: any) => {
                                        if (error) {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                        }
                                        setTableQuery({
                                            ...tableQuery,
                                            totalCount: 0,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        setLoading(false);
                                        resolve(pageData);
                                    });
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'RMS HQ Item Issues'}
                            icon={SVG_ICONS.product} />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(RmsHQItemIssues);