import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Paper, Typography,
  MenuItem, CircularProgress, TextField, Checkbox,
  FormControlLabel, FormHelperText,
  FormControl, Select, InputLabel, SvgIcon
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MatExpansionPanel, MatExpansionPanelSummary, MatExpansionPanelDetails } from './components';
import 'react-datasheet/lib/react-datasheet.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.css';
import { MyReactDataSheet } from '../../../models';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../../shared/components/Notifier';
import { useStyles } from './styles';
import { GridElement, stockOrderHeaders } from '../../../models';

const VerifyImportData = (props: any) => {
  const classes = useStyles({});
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const {
    parsedPdfData, uploadPDF,
    selectedFile, selectedFileError,
    selectedSupplier, supplierError,
    gridData, setGridData,
    parseCSV,
    mappedHeaders, setMappedHeaders,
    headersError, uiLoading, setUILoading,
    checkColumnsInput, setCheckColumnsInput,
    checkRowsInput, setCheckRowsInput,
    definedHeaders, mapHeadersFromConfig,
    shouldUpdateRowColSelection,
    windowHeight, windowWidth,
  } = props;

  useEffect(()=>{
    console.log("GridData", gridData)
    console.log("mappedHeaders", mappedHeaders)

  },[gridData,mappedHeaders])

  const businessHasInvoiceConfig = () => {
    if (selectedSupplier) {
      const { business_settings } = selectedSupplier.master_company;
      if (business_settings && business_settings.length > 0) {
        const bs = business_settings.find((s: { key: string, value: string; }) => s.key === "INVOICE_HEADER_MAP_CONFIG");
        return bs ? true : false;
      }
    }
    return false;
  };

  const expandPanel = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    onCheckMultiRowsOrCols('col');
    onCheckMultiRowsOrCols('row');
  }, [shouldUpdateRowColSelection]);

  const getMappedHeader = (key: number) => {
    console.log("MappedHeader", key, props.mappedHeaders)
    if (props.mappedHeaders.get(key)) {
      return props.mappedHeaders.get(key);
    }
    return '';
  };

  const handleChangeMappedHeader = (header: string, key: number) => {
    const map = new Map(mappedHeaders);
    map.set(key, header);
    setMappedHeaders(map);
  };

  const onClearMappedHeaders = () => {
    setMappedHeaders(new Map<number, string>());
  };

  const onClearCheckedButtonClick = () => {
    if (gridData && gridData.length > 0) {
      const grid = gridData.map((row: any[]) => [...row]);
      for (let x = 0; x < grid.length; x++) {
        for (let y = 0; y < grid[x].length; y++) {
          grid[x][y].isSelected = false;
          grid[x][y].isIgnored = false;
        }
      }
      setGridData(grid);
    }
  };

  const setSelectedRowOrColumn = (row: number, column: number, cell: any, isChecked: boolean) => {
    if (row === 0) {
      toggleSelectedColumnInGrid(column, isChecked);
    } else if (column === 0) {
      toggleSelectedRowInGrid(row, isChecked);
    }
  };

  const toggleSelectedColumnInGrid = (col: number, isChecked: boolean) => {
    const grid = gridData.map((row: any[]) => [...row]);
    for (let x = 0; x < grid.length; x++) {
      for (let y = 0; y < grid[x].length; y++) {
        if (y === col) {
          grid[x][y].isSelected = isChecked;
          grid[x][y].isIgnored = isChecked;
          if (grid[x][0].isSelected) { // check if row is already selected
            grid[x][y].isSelected = true;
            grid[x][y].isIgnored = true;
          }
        }
      }
    }
    setGridData(grid);
    // clear the header mapping for this column
    console.log("mappedHeaders", mappedHeaders)
    const map = new Map(mappedHeaders);
    map.delete(col);
    setMappedHeaders(map);
  };

  const toggleMultipleColumnsInGrid = (columns: number[], isChecked: boolean) => {
    const grid = gridData.map((row: any[]) => [...row]);
    const map = new Map(mappedHeaders);
    for (let x = 0; x < grid.length; x++) {
      for (let y = 0; y < grid[x].length; y++) {
        if (y === 0) {
          continue;
        }
        if (columns.includes(y)) {
          grid[x][y].isSelected = isChecked;
          grid[x][y].isIgnored = isChecked;
          if (grid[x][0].isSelected) { // check if row is already selected
            grid[x][y].isSelected = true;
            grid[x][y].isIgnored = true;
          }
          // clear the header mapping for this column
          map.delete(y);
        }
      }
    }
    setGridData(grid);
    setMappedHeaders(map);
  };

  const toggleSelectedRowInGrid = (row: number, isChecked: boolean) => {
    const grid = gridData.map((r: any[]) => [...r]);
    for (let x = 0; x < grid.length; x++) {
      for (let y = 0; y < grid[x].length; y++) {
        if (x === row) {
          // if (isFirstRowHeaders && row === 1) {
          //   grid[x][y].isSelected = true;
          //   grid[x][y].isIgnored = true;
          // } else {
          grid[x][y].isSelected = isChecked;
          grid[x][y].isIgnored = isChecked;
          // }
          if (grid[0][y].isSelected) {
            grid[x][y].isSelected = true;
            grid[x][y].isIgnored = true;
          }
        }
      }
    }
    setGridData(grid);
  };

  const toggleMultipleRowsInGrid = (rows: number[], isChecked: boolean) => {
    const grid = gridData.map((r: any[]) => [...r]);
    for (let x = 0; x < grid.length; x++) {
      for (let y = 0; y < grid[x].length; y++) {
        if (rows.includes(x)) {
          // if (isFirstRowHeaders && x === 1) {
          //   grid[x][y].isSelected = true;
          //   grid[x][y].isIgnored = true;
          // } else {
          grid[x][y].isSelected = isChecked;
          grid[x][y].isIgnored = isChecked;
          // }
          if (grid[0][y].isSelected) {
            grid[x][y].isSelected = true;
            grid[x][y].isIgnored = true;
          }
        }
      }
    }
    setGridData(grid);
  };

  const onPreFilterBtnClick = () => {
    const grid = gridData.map((r: any[]) => [...r]);
    const rowsToIgnore = [];
    const filterCellCount = 1;

    if (filterCellCount > 0) {
      for (let row = 0; row < grid.length; row++) {
        if (row === 0) {
          continue;
        }
        if (grid[row][0] && grid[row][0].isSelected) {
          continue;
        }
        let cellCount = 0;
        for (let col = 0; col < grid[row].length; col++) {
          if (grid[row][col].isSelected) {
            // console.log('col is checked:' + 'row: ' + row + ' col: ' + col);
            continue;
          } else {
            const columnHeader = mappedHeaders.get(col);
            // console.log('columnHeader',columnHeader);
            if (columnHeader) {
              const value = grid[row][col].value;
              if (!value.replace(/\s/g, '').length) {
                // console.log('row: ' + row + ' col: ' + col + ' has blank value')
                cellCount++;
              }
            }
          }
          if (cellCount === filterCellCount) {
            // console.log('ignoring row...', row)
            rowsToIgnore.push(row);
            break;
          }
        }
      }
      if (rowsToIgnore.length > 0) {
        toggleMultipleRowsInGrid(rowsToIgnore, true);
      }
    }
  };

  const onCheckMultiRowsOrCols = (type: string) => {
    let input = null;
    if (type === 'col') {
      input = String(checkColumnsInput).trim();
    } else if (type === 'row') {
      input = String(checkRowsInput).trim();
    }
    if (input) {
      if (input.replace(/\s/g, '').length) {
        const inputStringArray = input.split(',');
        const ranges: string[] = [];
        const indices: number[] = [];
        inputStringArray.forEach(string => {
          const s = string.trim();
          if (s.includes('-')) {
            ranges.push(s);
          } else {
            indices.push(Number(s));
          }
        });
        ranges.forEach(r => {
          const rangeSplitArray = r.split('-');
          if (rangeSplitArray.length >= 2) {
            const start = Number(rangeSplitArray[0]);
            const end = Number(rangeSplitArray[1]);
            for (let i = start; i <= end; i++) {
              indices.push(i);
            }
          }
        });
        indices.sort((a, b) => { return a - b; });
        if (type === 'col') {
          toggleMultipleColumnsInGrid(indices, true);
        } else if (type === 'row') {
          toggleMultipleRowsInGrid(indices, true);
        }
      }
    }
  };

  const getTableBodyMaxHeight = () => {
    const style = { maxHeight: 0 };
    if (windowWidth) {
      if (windowWidth >= 2260) {
        style.maxHeight = 900;
      } else if (windowWidth >= 1920) {
        style.maxHeight = 472;
      } else if (windowWidth >= 1600) {
        style.maxHeight = 456;
      } else if (windowWidth >= 1440) {
        style.maxHeight = 372;
      } else if (windowWidth >= 1366) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1280) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 445;
        }
      } else if (windowWidth >= 1024) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 445;
        }
      } else if (windowWidth >= 800) {
        style.maxHeight = 245;
        if (windowHeight >= 900) {
          style.maxHeight = 500;
        }
      }
      if (expanded === 'panel1') {
        style.maxHeight = style.maxHeight * 0.6;
      }
      return style;
    }
    return style;
  };

  const cellRenderer: React.FC = (props: any) => {
    // console.log(props)
    const classes = useStyles();
    const { row, col, cell } = props;

    let backgroundStyle = props.cell.isSelected ? { backgroundColor: '#eee' } : undefined;

    switch (props.cell.type) {
      case 'header':
        return (
          <td className="action-cell">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.cell.isSelected}
                    onChange={e => setSelectedRowOrColumn(row, col, cell, e.target.checked)}
                    color="primary" />
                }
                label={'C' + col}
              />
              {
                !props.cell.isSelected &&
                <FormControl className={classes.formControl}>
                  <Select
                    value={getMappedHeader(col)}
                    onChange={(event) => handleChangeMappedHeader(String(event.target.value), col)}
                    inputProps={{
                      name: 'header',
                      id: `defined-header-${col}`
                    }}
                  >
                    {
                      definedHeaders && definedHeaders.map((h: string, i: number) => {
                        return <MenuItem value={h} key={i}>{h}</MenuItem>;
                      })
                    }
                  </Select>
                </FormControl>
              }
            </div>
            {props.children}
          </td>
        );
      case 'checkbox':
        const rowIndex = row;
        return (
          <td className="action-cell">
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.cell.isSelected}
                  onChange={e => setSelectedRowOrColumn(row, col, cell, e.target.checked)}
                  color="primary" />
              }
              label={'R' + rowIndex}
            />
            {props.children}
          </td>
        );
      case 'data':
        // if (row === 1 && isFirstRowHeaders && props.cell.isSelected) {
        //   backgroundStyle = { backgroundColor: '#bbdefb' };
        // }
        return (
          <td style={{ ...backgroundStyle, width: '1%', whiteSpace: 'nowrap' }}
            onMouseDown={props.onMouseDown}
            onMouseOver={props.onMouseOver}
            className="cell">
            {props.children}
          </td>
        );
    }
    return null;
  };

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        {
          uiLoading &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8 }}>
          {
            !selectedFile && selectedFileError.hasError &&
            <div className={classes.textLabel}>
              <Typography variant="body1" className={classes.errorText}>{selectedFileError.message}</Typography>
            </div>
          }
        </div>
        {
          gridData.length > 0 &&
          headersError &&
          headersError.hasError &&
          <div className={classes.actionRow}>
            <div style={{ justifySelf: 'flex-start', alignSelf: 'center', paddingBottom: 8 }}>
              <div>
                <Typography variant="body1" className={classes.errorText}>{headersError.message}</Typography>
                <Typography variant="body1" className={classes.errorText}>{headersError.message2}</Typography>
              </div>
            </div>
          </div>
        }
        {
          gridData.length > 0 &&
          <MatExpansionPanel
            expanded={expanded === 'panel1'}
            onChange={expandPanel('panel1')}>
            <MatExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Advanced Data Controls</Typography>
            </MatExpansionPanelSummary>
            <MatExpansionPanelDetails>
              <div style={{ padding: '12px 16px', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 12, flexWrap: 'wrap' }}>
                  <div style={{ display: 'flex', justifySelf: 'flex-start', alignSelf: 'center', flexWrap: 'wrap' }}>
                    <Typography variant="body1" style={{ paddingRight: 24, fontWeight: 500 }}>Check Rows or Columns to Ignore Data</Typography>
                    <Typography variant="body1" style={{ alignSelf: 'center', fontSize: '0.95rem' }}
                    >(Empty Rows & Columns are automatically ignored.)</Typography>
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  width: '100%'
                }}>
                  <div className={classes.actionCol}>
                    <div className={classes.actionRow}>
                      <div className={classes.actionRow}>
                        <Typography variant="body1" style={{ alignSelf: 'center' }}>Clear Column Mapping</Typography>
                        <div style={{ alignSelf: 'center' }}>
                          <Button variant="contained"
                            style={{ marginLeft: 16 }}
                            onClick={onClearMappedHeaders}>
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className={classes.actionRow}>
                      <div className={classes.actionRow}>
                        <Typography variant="body1" style={{ alignSelf: 'center' }}>Clear All Checks</Typography>
                        <div style={{ alignSelf: 'center' }}>
                          <Button variant="contained"
                            style={{ marginLeft: 16 }}
                            onClick={onClearCheckedButtonClick}>
                            Apply
                      </Button>
                        </div>
                      </div>
                    </div>
                    {
                      businessHasInvoiceConfig() &&
                      <div className={classes.actionRow}>
                        <div className={classes.actionRow}>
                          <Typography variant="body1"
                            style={{ alignSelf: 'center' }}
                          >Reload Saved Column Mapping</Typography>
                          <div style={{ alignSelf: 'center' }}>
                            <Button variant="contained"
                              style={{ marginLeft: 16 }}
                              onClick={mapHeadersFromConfig}>
                              Apply
                        </Button>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className={classes.actionCol}>
                    <div className={classes.actionRow}>
                      <div className={classes.actionRow}>
                        <Typography variant="body1"
                          style={{ alignSelf: 'center' }}
                        >Check All Rows With Missing Required Data</Typography>
                        <div style={{ alignSelf: 'center' }}>
                          <Button variant="contained"
                            className={classes.actionRowBtn}
                            style={{ marginLeft: 16 }}
                            onClick={() => onPreFilterBtnClick()}>
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className={classes.actionRow}>
                      <div className={classes.actionRow}>
                        <TextField
                          id="check-columns"
                          className={classes.textField}
                          value={checkColumnsInput}
                          onChange={(e) => setCheckColumnsInput(e.target.value)}
                          label="Check Columns by Range: 1-3, 6"
                        // margin="normal"
                        />
                        <div style={{ alignSelf: 'center' }}>
                          <Button variant="contained"
                            style={{ marginLeft: 16 }}
                            onClick={() => onCheckMultiRowsOrCols('col')}>
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className={classes.actionRow}>
                      <div className={classes.actionRow}>
                        <TextField
                          id="check-rows"
                          className={classes.textField}
                          value={checkRowsInput}
                          onChange={(e) => setCheckRowsInput(e.target.value)}
                          label="Check Rows by Range: 3-5, 7"
                        // margin="normal"
                        />
                        <div style={{ alignSelf: 'center' }}>
                          <Button variant="contained"
                            style={{ marginLeft: 16 }}
                            onClick={() => onCheckMultiRowsOrCols('row')}>
                            Apply
                  </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MatExpansionPanelDetails>
          </MatExpansionPanel>
        }
        {
          gridData.length > 0 &&
          <MyReactDataSheet
            data={gridData}
            sheetRenderer={props => (
              <table className={'data-grid parsedDataTable'}>
                <tbody
                  style={getTableBodyMaxHeight()}>
                  {props.children}
                </tbody>
              </table>
            )}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={changes => {
              const grid = gridData.map((row: any[]) => [...row]);
              changes.forEach(({ cell, row, col, value }) => {
                grid[row][col] = { ...grid[row][col], value };
              });
              props.setGridData(grid);
            }}
            cellRenderer={cellRenderer} />
        }
      </Paper>
      {/* {props.expressMode && 
        props.setStepThreeSuccess(true)
      } */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyImportData);
