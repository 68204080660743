import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography, Divider, SvgIcon, CircularProgress, Select, MenuItem, FormControl } from '@material-ui/core';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@material-ui/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import Papa from 'papaparse';
import MaterialTable from 'material-table';
import { EMPTY_ROW_MSG } from '../../../shared/lib/Localization';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { initQueryParams, replaceHistory, tableOptions, useQuery, } from '../../../shared/lib/TableQueryParams';
import { useLocation } from 'react-router-dom';
import { MasterSupplierDataImportStatusCode } from '../../models';
import { getAllSuppCompanies, importMasterCompanyData,validateCompanyData } from '../../api';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy'
import { PaginatedResponse } from '../../../shared/models';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      display: 'inline-block',
      tabSize: 4
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: 16,
    },
    summaryPaper: {
      padding: theme.spacing(1),
      // marginBottom: 16,
    },
    content: {
      display: 'flex',
      alignContent: 'center',
      width: '100%',
      backgroundColor: '#fff',
      paddingLeft: 24,
      paddingRight: 24
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    textField: {
      display: 'flex',
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      width: '100%',
      // paddingTop: 24,
      padding: theme.spacing(30),
    },
    card: {
      padding: theme.spacing(30),
    },
    statusText: {
      fontSize: 16,
      textAlign: 'left',
      marginLeft: 58,
      padding: "6px 0",
      display: 'flex',
      alignItems: 'center'
    },
    helpTooltipRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 8,
    },
    helpTooltipText: {
      padding: '0 8px 0 0',
    },
    requiredField: {
      color: GlobalStyles.red,
      fontSize: 18,

    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.primaryColor,
      },
      '&:after': {
        borderColor: GlobalStyles.primaryColor,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
  }),
);

const CompanyProductImportScreen = (props: any) => {
  const classes = useStyles({});
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [parserError, setParserError] = useState(false);
  const [uiLoading, setUILoading] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [parsedData, setParsedData] = useState([]);
  const [disableImport, setDisableImport] = useState(true);
  const [disableValidate, setDisableValidate] = useState(true);
  const [validatedData, setValidatedData] = useState([]);
  const [validatedDatachecked, setValidatedDataChecked] = useState(false);
  const [createdLink, setCreatedLink] = useState(false);
  const [quantityMapped, setQuantityMapped] = useState('');
  const [attributesPushed, setAttributesPushed] = useState(false);
  const [errorRecordsCount, setErrorRecordsCount] = useState(0);
  const [successRecordsCount, setSuccessRecordsCount] = useState(0);
  const [warningRecordsCount, setWarningRecordsCount] = useState(0);
  const [totalCount, setTotalCount] = useState('');
  const [importedSuccessfully, setImportedSuccessfully] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setExporting] = useState(false);
  const [fetchingSuppCompanies, setFetchingSuppCompanies]= useState(false)
  const [suppCompanies, setSuppCompanies] = useState(null as any) 
  const [selectedSuppCompany, setSelectedSuppCompany] = useState(null as any)

  useEffect(() => {
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();
      const validExtensions = ['csv', 'tsv'];
      if (validExtensions.includes(fileExtension)) {
        // console.log("test");
        parseCSV();
      }
    }
  }, [selectedFile]);

  useEffect(() => {
    if (validatedDatachecked === true) {
      let callGetAttribute = "";
      for (let i = 0; i < validatedData.length; i++) {
        if (validatedData[i].status != "Ready to Import") {
          callGetAttribute = "true";
        }
      }
      if (callGetAttribute === "true") {
        getAttributes();
      }

    }
  }, [validatedDatachecked]);

  useEffect(() => {
    if (validatedDatachecked === true) {
      getQtyAndName();
    }
  }, [validatedDatachecked]);

  useEffect(() => {
    if (attributesPushed) {
      // console.log("ATTRIBUTES-PUSHED", validatedData);
    }
  }, [attributesPushed]);


  useEffect(() => {
    setFetchingSuppCompanies(true)
    getAllSuppCompanies()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PaginatedResponse<any>) => {
        // console.log("results",data)
        setFetchingSuppCompanies(false)
        setSuppCompanies(data.results);
      })
      .catch((error: any) => {
      });
  }, [])

  const parseCSV = () => {
    if (selectedFile) {
      const CSVParserConfig = {
        complete: function (results: any, file: any) {
          if (results.data.length > 0) {
            // console.log('papa parser', results.data);
            csvJson(results.data);
          }
          setParserError(false);
        },
        dynamicTyping: false,
        error: function (error: any, file: any) {
          console.log("Error occurred while parsing csv!");
          setParserError(true);
        },
        skipEmptyLines: true
      };
      Papa.parse(selectedFile, CSVParserConfig);
    }
  };

  const onFileSelectChangeHandler = (e: any) => {
    if (e.target) {
      if (e.target.files && e.target.files[0]) {
        setSelectedFile(e.target.files[0]);
        setDisableValidate(false);
        setDisableImport(true);
        e.target.value = '';
      }
    }
  };

  const handleUploadClick = () => {
    const el = document.getElementById('import-file');
    el && el.click();
  };

  const getUnique = (items: any) => {
    const uniqueArray = items.reduce((uniqueRows, currentRow) => {
      if (!uniqueRows.some(
        uniqueRow =>
          uniqueRow.mp_unit_qty === currentRow.mp_unit_qty &&
          uniqueRow.mp_unit_name === currentRow.mp_unit_name &&
          uniqueRow.brand_name === currentRow.brand_name &&
          uniqueRow.retail_barcode === currentRow.retail_barcode &&
          uniqueRow.invoice_item_desc === currentRow.invoice_item_desc &&
          uniqueRow.reorder_code === currentRow.reorder_code &&
          uniqueRow.attr_qty === currentRow.attr_qty
      )) {
        const dupIndex = uniqueRows.findIndex(uniqueRow => uniqueRow.master_product_name.split(' ').join('').toLowerCase() === currentRow.master_product_name.split(' ').join('').toLowerCase());
        uniqueRows.push({
          ...currentRow,
          disabled: dupIndex < 0 ? false : true,
          isMpNameDuplicate: dupIndex < 0 ? false : true,
        });

      }
      return uniqueRows;
    }, []);
    return uniqueArray;

  };

  const checkMissingAttrValues = (items: any) => {
    let validatedRecs = cloneDeep(items)
    validatedRecs.forEach((item: any) => {
      if ((item.invoice_item_desc !== null) || (item.invoice_item_desc !== '')) {
        item['invoice_item_desc_exists'] = true
      }
      if ((item.reorder_code !== null) || (item.reorder_code !== '')) {
        item['roc_exists'] = true
      }
    })
    console.log("Missing Attr vAlues", validatedRecs)
    return validatedRecs
  }


  const csvJson = (csv) => {
    // var str = csv.toString()
    const lines = csv;
    const result = [];
    const headers = csv[0];

    for (let i = 1; i < lines.length; i++) {
      let obj = {};
      let currentline = lines[i];
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      obj['warning_msg'] = '';
      obj['identifiers_mp_mapped'] = '';
      obj['disabled'] = false;
      obj['unitqty_valid'] = false;
      obj['unitname_valid'] = false;
      obj['attr_qty_valid'] = false
      obj['invoice_item_desc_exists'] = false;
      obj['roc_exists'] = false;
      obj['status'] = '';

      result.push(obj);
    }
    // console.log(result);

    for (let i of result) {
      const requiredKeys = ['attr_qty', 'brand_name', 'master_product_name', 'mp_unit_qty', 'mp_unit_name'];
      i['is_empty'] = false;
      for (let key of requiredKeys) {
        if (key in i && i[key] === "") {
          i['is_empty'] = true;
          break;
        }
      }
    }

    const validRecords = checkUnitQtyValid(result);
    const uniqueRecords = getUnique(validRecords);
    const resultant = checkMissingAttrValues(uniqueRecords);
    // console.log("new", resultant);

    setParsedData(resultant);

  };

  const isValidateButtonDisabled = () => {
    if (parsedData && parsedData.length > 0) {
      const invalidRow = parsedData.find(i => i.is_empty);
      // console.log("INVALID-ROW", invalidRow)
      return invalidRow ? true : false;
    }
    return false;
  };

  const checkUnitQtyValid = (items: any) => {
    const records = items.map(i => i);
    let letters = /^[a-z]+$/;
    for (let i = 0; i < records.length; i++) {
      let { mp_unit_qty } = records[i];
      let { mp_unit_name } = records[i];
      let { invoice_item_desc } = records[i];
      let { reorder_code } = records[i];
      let { attr_qty } = records[i];

      const updatedRecord = { ...records[i] };
      const unitQtyNotValid = isNaN(mp_unit_qty) ? true : false;
      const attrQtyNotValid = ((invoice_item_desc != null && invoice_item_desc != '') || (reorder_code != null && reorder_code != '')) ? isNaN(attr_qty) ? true : false : true;

      if (mp_unit_name.match(letters)) {
        updatedRecord['unitname_valid'] = true;
        records[i] = updatedRecord;
      }

      if (!unitQtyNotValid) {
        if (mp_unit_qty != 0 && mp_unit_qty > 0) {
          updatedRecord['unitqty_valid'] = true;
          records[i] = updatedRecord;
        }
      }

      if (!attrQtyNotValid) {
        if (attr_qty != 0 && attr_qty > 0) {
          updatedRecord['attr_qty_valid'] = true;
          records[i] = updatedRecord;
        }
      }
    }
    return records;

  };

  const getAttributes = () => {

    const validatedRecords = validatedData.map(i => i);
    let linkBusinessExists = false;
    for (let i = 0; i < validatedRecords.length; i++) {
      let exisitingMpexists = validatedRecords[i].existing_mp ? true : false;
      const { status_code, warning_msg } = validatedRecords[i];

      if (exisitingMpexists) {
        const { existing_mp } = validatedRecords[i];
        linkBusinessExists = existing_mp.business_products.values(0) ? true : false;
      }
      if (linkBusinessExists) {
        status_code === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR;
      }
      if (!linkBusinessExists) {
        status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT;
      }
    }



  };

  const getQtyAndName = () => {
    const validatedRecords = validatedData.map(i => i);
    for (let i = 0; i < validatedRecords.length; i++) {
      const exists = validatedRecords[i].existing_mp ? true : false;
      if (exists) {
        const { existing_mp } = validatedRecords[i];
        const updatedRecord = { ...validatedRecords[i] };
        // updatedRecord["unitqty_valid"] = true;
        if(existing_mp.attributes && existing_mp.attributes.length > 0){
          let unit_qty_found = existing_mp.attributes.find(a=>a.attribute.name === 'Quantity')
          let unit_name_found = existing_mp.attributes.find(a=>a.attribute.name === 'Unit of Measure')
          if(unit_qty_found !== null){
            updatedRecord["unitqty_mapped"] = unit_qty_found['value'] ;
          }
          if(unit_name_found !== null){
            updatedRecord["unitname_mapped"] = unit_name_found['value'] ;
          }
          if(existing_mp.brand && existing_mp.brand.name !== null){
            updatedRecord["mpbrand_mapped"] = existing_mp.brand.name;
          }
        }
        
        validatedRecords[i] = updatedRecord;
      }
    }
    setValidatedData(validatedRecords);
    setAttributesPushed(true);
    disableImportButton();
    // checkUnitQtyValid ();
  };

  const disableImportButton = () => {
    const validatedRecords = validatedData.map(i => i);
    const anyValidRecord = validatedRecords.find(i => i.status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT);
    // console.log("VALIDRECORD", anyValidRecord)
    if (!anyValidRecord) {
      setDisableValidate(false);
      setDisableImport(true);
    } else {
      setDisableValidate(true);
      setDisableImport(false);
    }
    updateSummaryCount();
  };



  const updateSummaryCount = () => {
    const validatedRecords = validatedData.map(i => i);
    let errorCount = 0;
    let successCount = 0;
    let warningCount = 0;
    for (let i = 0; i < validatedRecords.length; i++) {
      const { status_code, warning_msg } = validatedRecords[i];
      if (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
        successCount++;
      } else if (
        status_code === MasterSupplierDataImportStatusCode.EXISTING_IDENTIFIER_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.SUPPLIER_PRODUCT_NOT_FOUND_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.SAME_BRAND_DIFF_COMPANY ||
        status_code === MasterSupplierDataImportStatusCode.EXISTING_INVOICE_ITEM_NAME_ERROR ||
        status_code === MasterSupplierDataImportStatusCode.EXISTING_REORDER_ERROR
        ) {
        errorCount++;
      }
      if (warning_msg != '') {
        warningCount++;
      }
    }
    setErrorRecordsCount(errorRecordsCount + errorCount);
    setSuccessRecordsCount(successRecordsCount + successCount);
    setWarningRecordsCount(warningRecordsCount + warningCount);
  };

  const getSummary = () => {
    return (
      <div >
        <Typography style={{ fontSize: 16, }} >
          {`Total : ${totalCount}. Valid: ${successRecordsCount}. Error: ${errorRecordsCount}. Warning: ${warningRecordsCount}.`}
        </Typography>
      </div>
    );
  };


  const renderTable = () => {
    return (
      <div>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        {/* <ExportStatusBar isVisible={isExporting} /> */}
        <div className="table-wrapper">
          <MaterialTable
            options={{
              debounceInterval: 500,
              ...tableOptions(tableQuery),
              emptyRowsWhenPaging: false,
              thirdSortClick: false,
              draggable: false,
              sorting: true,
              search: false,
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              {
                title: <span>Inv.Item Name</span>,
                field: 'invoice_item_desc'
              },
              {
                title: <span>RoC</span>,
                field: 'reorder_code'
              },
              {
                title: <span>Qty<span className={classes.requiredField}>*</span></span>,
                field: 'attr_qty',
                type: 'numeric'

              },
              {
                title: <span>MP Name<span className={classes.requiredField}>*</span></span>,
                field: 'master_product_name'
              },
              {
                title: <span>MP Unit Qty<span className={classes.requiredField}>*</span></span>,
                field: 'mp_unit_qty',
                type: 'numeric'
              },
              {
                title: <span>MP Unit Name<span className={classes.requiredField}>*</span></span>,
                field: 'mp_unit_name'
              },
              {
                title: <span>Brand<span className={classes.requiredField}>*</span></span>,
                field: 'brand_name',
              },
              {
                title: 'Company',
                field: 'company_name',
              },
              {
                title: 'Retail Barcode',
                field: 'retail_barcode',
              },
              {
                title: 'Status',
                field: 'status',
                render: (rowData: any) => {
                  const { status_code, status, disabled, warning_msg, is_empty, unitqty_valid, unitname_valid, roc_exists, invoice_item_desc_exists, attr_qty_valid } = rowData;
                  if (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && warning_msg === '') {
                    if (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && warning_msg === '') {
                      return <CheckCircleIcon style={{ color: GlobalStyles.green }} />;
                    }
                    return <CheckCircleIcon style={{ color: GlobalStyles.green }} />;
                  }

                  else if (
                    (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && warning_msg != '') ||
                    (!invoice_item_desc_exists) ||
                    (!roc_exists)
                  ) {
                    return <ErrorIcon style={{ color: GlobalStyles.warningText }} />;
                  }
                  else if (status_code && (
                    status_code === MasterSupplierDataImportStatusCode.EXISTING_IDENTIFIER_ERROR ||
                    status_code === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR ||
                    status_code === MasterSupplierDataImportStatusCode.SUPPLIER_PRODUCT_NOT_FOUND_ERROR ||
                    status_code === MasterSupplierDataImportStatusCode.SAME_BRAND_DIFF_COMPANY ||
                    status_code === MasterSupplierDataImportStatusCode.EXISTING_INVOICE_ITEM_NAME_ERROR ||
                    status_code === MasterSupplierDataImportStatusCode.EXISTING_REORDER_ERROR
                  )) {
                    return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                  }
                  else if (disabled === true) {
                    if (is_empty === true) {
                      if (unitqty_valid === false) {
                        if (unitname_valid === false) {
                          return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                        }
                        return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                      }
                      return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                    }
                    return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                  }
                  else if (is_empty === true) {
                    return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                  }

                  else if (unitqty_valid === false) {
                    return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                  }

                  else if (unitname_valid === false) {
                    return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                  }
                  else if (!attr_qty_valid) { 
                    return <ErrorIcon style={{ color: GlobalStyles.red }} />;
                  }

                  return '';
                }
              }
            ]}
            data={validatedData.length > 0 ? validatedData : parsedData}
            title={
              <CustomTableTitle
                history={props.history}
                title={'Master Company Data Import'}
                icon={SVG_ICONS.import}
              />
            }

            detailPanel={rowData => {
              const { status_code, status, disabled, warning_msg, is_empty, unitqty_valid, unitname_valid, companies, master_product_name, attr_qty_valid } = rowData;
              const MP_NAME_DUPLICATE_ERROR = ` This MP ${master_product_name} is duplicated from imported data.`;
              const MISSING_FIELD_ERROR = 'All fields except for retail barcode are required.';
              const UNIT_QTY_ERROR = 'Unit Qty must be greater than 0.';
              const UNIT_NAME_ERROR = 'Unit Name must be a valid Weight.';
              const ATTR_QTY_ERROR = 'Quantity must be greater than 0.'
              if (status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT && !is_empty && !disabled && !companies && warning_msg === '') {
                return (
                  <Typography className={classes.statusText}
                    style={{ color: '#43a047' }} >
                    <span style={{ paddingRight: 8 }}>
                      <CheckCircleIcon style={{ color: GlobalStyles.green }} />
                    </span>
                    {status}
                  </Typography>
                );
              }

              else if (validatedDatachecked && !disabled && !is_empty) {
                if ((warning_msg === '' || !warning_msg) && !companies && status_code !== MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
                  return (
                    <Typography
                      className={classes.statusText}
                      style={{ color: GlobalStyles.red }} >
                      {`Error: ${status}`}
                    </Typography>
                  );
                }
                else if ((warning_msg === '' || !warning_msg) && companies && status_code !== MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
                  return (
                    <Typography
                      className={classes.statusText}
                      style={{ color: GlobalStyles.red }} >
                      {`Error: ${status} ${uniq(companies).join(", ")}. Please add one of these names to the company field and reupload`}
                    </Typography>
                  );
                }
                else if (warning_msg != '') {
                  return (
                    <div >
                      <div style={{ fontSize: 16 }}>
                        {
                          status_code === MasterSupplierDataImportStatusCode.READY_TO_IMPORT &&
                          <Typography
                            className={classes.statusText}
                            style={{ color: '#43a047' }} >
                            <span style={{ paddingRight: 8 }}>
                              <CheckCircleIcon style={{ color: GlobalStyles.green }} />
                            </span>
                            {status}
                          </Typography>
                        }
                        <Typography className={classes.statusText} >
                          <span style={{ paddingRight: 8 }}>
                            <ErrorIcon style={{ color: GlobalStyles.warningText }} />
                          </span>
                          {`This Master Product is already mapped with Unit Qty: ${rowData.unitqty_mapped ? rowData.unitqty_mapped : '' }, Unit Name: ${rowData.unitname_mapped ? rowData.unitname_mapped : ''}, Brand: ${rowData.mpbrand_mapped ? rowData.mpbrand_mapped : ''}.`}
                        </Typography>
                      </div>

                      {!(rowData.mp_unit_qty === rowData.unitqty_mapped && rowData.mp_unit_name === rowData.unitname_mapped && rowData.brand_name === rowData.mpbrand_mapped) &&
                        <div className={classes.statusText}>
                          <span style={{ paddingRight: 8 }}>
                            <ErrorIcon style={{ color: GlobalStyles.warningText }} />
                          </span>
                          {rowData.warning_msg}
                        </div>
                      }
                      {
                        (status_code === MasterSupplierDataImportStatusCode.EXISTING_IDENTIFIER_ERROR ||
                          status_code === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR ||
                          status_code === MasterSupplierDataImportStatusCode.SAME_BRAND_DIFF_COMPANY ||
                          status_code === MasterSupplierDataImportStatusCode.EXISTING_INVOICE_ITEM_NAME_ERROR ||
                          status_code === MasterSupplierDataImportStatusCode.EXISTING_REORDER_ERROR) &&
                        (
                          <Typography className={classes.statusText} >
                            <span style={{ paddingRight: 8 }}>
                              <ErrorIcon style={{ color: GlobalStyles.red }} />
                            </span>
                            {`Error: ${rowData.status}`}
                          </Typography>
                        )
                      }
                    </div>
                  );
                }
              }

              else if (disabled) {
                if (is_empty) {
                  if (!unitqty_valid) {
                    if (!unitname_valid) {
                      if (!attr_qty_valid) {
                        return (
                          <div>
                            <Typography className={classes.statusText}
                              style={{ color: GlobalStyles.red }} >
                              {MP_NAME_DUPLICATE_ERROR}
                            </Typography>
                            <Typography className={classes.statusText}
                              style={{ color: GlobalStyles.red }} >
                              {MISSING_FIELD_ERROR}
                            </Typography>
                            <Typography className={classes.statusText}
                              style={{ color: GlobalStyles.red }} >
                              {UNIT_QTY_ERROR}
                            </Typography>
                            <Typography className={classes.statusText}
                              style={{ color: GlobalStyles.red }} >
                              {UNIT_NAME_ERROR}
                            </Typography>
                            <Typography className={classes.statusText}
                              style={{ color: GlobalStyles.red }} >
                              {ATTR_QTY_ERROR}
                            </Typography>
                          </div>
                        );
                      }
                      return (
                        <div>
                          <Typography className={classes.statusText}
                            style={{ color: GlobalStyles.red }} >
                            {MP_NAME_DUPLICATE_ERROR}
                          </Typography>
                          <Typography className={classes.statusText}
                            style={{ color: GlobalStyles.red }} >
                            {MISSING_FIELD_ERROR}
                          </Typography>
                          <Typography className={classes.statusText}
                            style={{ color: GlobalStyles.red }} >
                            {UNIT_QTY_ERROR}
                          </Typography>
                          <Typography className={classes.statusText}
                            style={{ color: GlobalStyles.red }} >
                            {UNIT_NAME_ERROR}
                          </Typography>
                        </div>
                      );
                    }
                    return (
                      <div>
                        <Typography className={classes.statusText}
                          style={{ color: GlobalStyles.red }} >
                          {MP_NAME_DUPLICATE_ERROR}
                        </Typography>
                        <Typography className={classes.statusText}
                          style={{ color: GlobalStyles.red }} >
                          {MISSING_FIELD_ERROR}
                        </Typography>
                        <Typography className={classes.statusText}
                          style={{ color: GlobalStyles.red }} >
                          {UNIT_QTY_ERROR}
                        </Typography>
                      </div>
                    );
                  }
                  return (
                    <div>
                      <Typography className={classes.statusText}
                        style={{ color: GlobalStyles.red }} >
                        {MP_NAME_DUPLICATE_ERROR}
                      </Typography>
                      <Typography className={classes.statusText}
                        style={{ color: GlobalStyles.red }} >
                        {MISSING_FIELD_ERROR}
                      </Typography>
                    </div>
                  );
                }
                return (
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {MP_NAME_DUPLICATE_ERROR}
                  </Typography>
                );
              }

              else if (is_empty === true) {
                return (
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {MISSING_FIELD_ERROR}
                  </Typography>
                );
              }

              else if (!unitqty_valid) {
                return (
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {UNIT_QTY_ERROR}
                  </Typography>
                );
              }

              else if (!unitname_valid) {
                return (
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {UNIT_NAME_ERROR}
                  </Typography>
                );
              }

              else if (!attr_qty_valid) {
                return (
                  <Typography className={classes.statusText}
                    style={{ color: GlobalStyles.red }} >
                    {ATTR_QTY_ERROR}
                  </Typography>
                );
              }
              return '';
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </div>
      </div>
    );
  };

  const renderFileName = () => {
    if (selectedFile && selectedFile.name) {
      const ext = selectedFile.name.split('.').pop();
      const filename = `File: ${selectedFile.name}`;
      return filename;
    }
    return null;
  };

  const handleImport = () => {
    // console.log(props.userLocalData)
    setUILoading(true);
    const { auth_token } = props.userLocalData.userData.userData
    const records = validatedData.filter(i => i.status === "Ready to Import");
    // const formatedParsedData = `{"records": {${parsedData}},"business_id": ${business_id}}`;
    const payload = { records: records, company_id: selectedSuppCompany , auth_token };
    // console.log("payload", payload);
    // console.log(pa)
    importMasterCompanyData(payload)
      // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setUILoading(false);
        setDisableImport(true);
        setImportedSuccessfully(true);
        props.showToast({
          message: 'Company Data Imported successfully!',
          options: ToastSuccess
        });
      })
      .catch((error: any) => {
        if (error) {
          setImportedSuccessfully(false);
          setUILoading(false);
          setDisableImport(false);
          props.showToast({
            message: 'Failed to Import Company Data!',
            options: ToastError
          });
        }
      });
  };

  const handleValidateData = () => {
    setUILoading(true);
    // console.log("props", props.userLocalData)
    const { business_id } = props.userLocalData.selectedBusiness;
    const toValidateRecords = parsedData.filter(i => !i.is_empty && !i.disabled && i.unitqty_valid && i.unitname_valid && i.attr_qty_valid  );
    // console.log("TO-VALIDATE-RECORDS", toValidateRecords)
    const payload = { records: toValidateRecords, company_id : selectedSuppCompany };
    console.log("payload", payload);
    validateCompanyData(payload)
      .catch(handleError)// handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData: any) => {
        setUILoading(false);
        setValidatedData(jsonData.records);
        setTotalCount(jsonData.records.length);
        setValidatedDataChecked(true);
      })
      .catch((error: any) => {
        setUILoading(false);
        if (error.status === 400) {
          props.showToast({
            message: 'Error. Please check if all fields are present!!',
            options: ToastError
          });
        }
      });
  };

  const handleResetClick = () => {
    setSelectedFile(null);
    setParsedData([]);
    setValidatedData([]);
    setDisableImport(true);
    setDisableValidate(true);
    setImportedSuccessfully(false);
    setUILoading(false);
    setValidatedDataChecked(false);
    setErrorRecordsCount(0);
    setSuccessRecordsCount(0);
    setWarningRecordsCount(0);
  };

  const handleCompanyChange = (event: any) => {
    setSelectedSuppCompany(event.target.value);

  }


  const renderMetaFn = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 8, justifyContent: 'space-between' }}>
        <div style={{ justifyContent: 'flex-start', display: 'flex' }}>
          <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor, paddingTop: 5 }}>
            Choose a Supplier Company:
          </Typography>
          <Select
            value={selectedSuppCompany ? selectedSuppCompany : ''}
            onChange={handleCompanyChange}
            className={classes.select}
          >
            {
              suppCompanies &&
              sortBy(suppCompanies, 'name').map((b: any) =>

              (
                <MenuItem value={b.id} key={String(b.id)}>
                  {b.name}
                </MenuItem>
              )
              )}
          </Select>
        </div>
        <div style={{ alignSelf: 'center' }}>
          {
            fetchingSuppCompanies &&
            <CircularProgress style={{
              width: '32px',
              height: '32px'
            }} />
          }
        </div>
        <div style={{ display:'flex',  justifyContent: 'flex-end', }}>
          {
            <CustomTableTitle
              showHelp={true}
              helpText={
                <div>
                  <Typography variant="body1" style={{ padding: '4px 8px' }}>
                    {`This screen creates Master Products and Company Products. Master Product will be linked to a company product`}
                  </Typography>
                  <Typography variant="subtitle2" style={{ padding: '4px 8px' }}>
                    {`The following fields are required:`}
                  </Typography>
                  <Typography variant="subtitle2" style={{ padding: '4px 8px' }}>
                    {`invoice_item_desc, reorder_code, attr_qty, master_product_name, mp_unit_qty, mp_unit_name, brand_name, retail_barcode, company_name`}
                  </Typography>
                  <Typography variant="subtitle2" style={{ padding: '4px 8px' }}>
                    {`Company Name is needed if a product belongs to same brand. Retail Barcode is optional.`}
                  </Typography>
                </div>
              }
            />
          }
          {
            <Button aria-controls="simple-menu"
              variant="contained"
              onClick={handleUploadClick}
              disabled={!selectedSuppCompany ? true : selectedFile ? true : false}
              startIcon={<SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>}>
              Select file
            </Button>
          }
          <input type="file" name="file"
            onChange={onFileSelectChangeHandler}
            id="import-file"
            style={{ display: 'none' }}
            accept=".tsv,.csv,.txt" />
        </div>
      </div>
    )
  }


  const renderNavFn = () => {
    return (
      <Paper className={classes.paper} >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', padding: 2 }}>

          <div style={{ alignSelf: 'flex-start', display: 'flex', alignItems: 'center' }}>
            {
              selectedFile &&
              <Button
                variant="contained"
                onClick={handleResetClick}
                style={{ marginLeft: 16 }}>
                Reset
              </Button>
            }
          </div>
          <div style={{ alignSelf: 'center' }}>
            {
              uiLoading &&
              <CircularProgress style={{
                width: '32px',
                height: '32px'
              }} />
            }
          </div>

          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
            {importedSuccessfully &&
              <Typography style={{ fontSize: 16, color: GlobalStyles.successText }}>
                MasterData and CompanyData Imported Successfully!!
              </Typography>
            }
            {!disableValidate &&
              <Button aria-controls="simple-menu"
                variant="contained"
                // disabled={isValidateButtonDisabled()}
                color="primary"
                onClick={handleValidateData}
              >
                Validate
              </Button>
            }

            {!disableImport &&
              <Button aria-controls="simple-menu"
                style={{ marginLeft: 16, backgroundColor: '#4caf50' }}
                variant="contained"
                onClick={handleImport}
                startIcon={<SvgIcon><path d={SVG_ICONS.import} /></SvgIcon>}>
                Import
              </Button>
            }
          </div>
        </div>
        {
          <div style={{
            padding: 5,
            display: 'flex',
            justifyContent: 'space-between',
            width: "100%",
            alignItems: 'center'
          }}>
            {validatedDatachecked && getSummary()}
          </div>

        }
      </Paper>
    )
  }

  return (
    <div>
      {renderMetaFn()}
      <Divider className={classes.divider} />
      {selectedFile && renderNavFn()}
      {selectedFile && renderTable()}

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userLocalData: state.userLocalData
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProductImportScreen);