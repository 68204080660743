import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import Container from '@material-ui/core/Container';
import { ProductNoBarcode, ReportType } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';

const selectedReportType: ReportType = {
    id: 1,
    name: 'Products with duplicate name',
    title: 'Products With Duplicate Name',
    columns: [
        { title: 'Number', field: 'product_number' },
        { title: 'Product', field: 'product_name' },
    ]
};

const getProductsByPage = (
    businessId: string | number, 
    page: number | null, 
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean, 
    shouldExport: boolean,
    orderBy?: string | number 
) => {
    let URL = API_URLS.productsDuplicateNameUrl;
    if (shouldExport) {
        URL += `export/?business_ids=${businessId}`;
    } else {
        URL += `?business_ids=${businessId}&tot_recs_found=${totalRecs}`;
    }
    if (page) {
        URL += `&page=${page}`;
    }
    if (page) {
        URL += `&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

const ProductDuplicateNameScreen = (props: any) => {
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isExporting, setExporting] = useState(false);
    const [initialLoad,setInitialLoad]=useState(true);
    const [totalRecords, setTotalRecords] = useState(null as any)

    useEffect(() => {
        if(!initialLoad){
            tableRef && tableRef.current && tableRef.current.onQueryChange();
            setTotalRecords(null)
        }
        console.log("BUSSINESS CHANGE")
    }, [props.userData.selectedBusiness]);

    const handleRefresh= () => {
        setTotalRecords(null)
        tableRef.current && tableRef.current.onQueryChange()
    }

    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = (shouldExport: boolean) => {
        if (shouldExport) {
            exportCSV();
        }
        setExportBtnAnchorEl(null);
    };

    const exportCSV = () => {
        if (props.userData && props.userData.selectedBusiness) {
            setExporting(true);
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            getProductsByPage(sb.business_id, null, null, tableQuery.search, true ? totalRecords : false, shouldExport, sb.orderBy)
                .catch(handleError)
                .then(checkStatus)
                .then(res => res.blob())
                .then(data => {
                    download(data, 'Products_With_Duplicate_Name.csv', 'text/csv');
                    setExporting(false);
                    setInitialLoad(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                        } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        }
                    }
                });
        }
    };

    return (
        <Container maxWidth={false}>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true 
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={selectedReportType.columns}
                    data={query =>
                        new Promise((resolve, reject) => {
                            replaceHistory(query, props.history);
                            let pageData = {
                                data: [] as ProductNoBarcode[],
                                totalCount: 0,
                                page: query.page
                            };
                            if (props.userData && props.userData.selectedBusiness) {
                                const sb = props.userData.selectedBusiness;
                                const shouldExport = false;
                                let orderBy = '';
                                if (query.orderBy) {
                                    orderBy = query.orderBy.field as string;
                                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                }
                                let totalRecsAvailable = totalRecords !== null ?  true : false
                                getProductsByPage(
                                    sb.business_id,
                                    query.page + 1, 
                                    query.pageSize,
                                    query.search, 
                                    totalRecsAvailable, 
                                    false, 
                                    orderBy
                                )
                                    .catch(handleError) // handle network issues
                                    .then(checkStatus)
                                    .then(parseJSON)
                                    .then((data: PaginatedResponse<ProductNoBarcode>) => {
                                        if(!totalRecords){
                                            setTotalRecords(data.count)
                                        }
                                        setErrorStatusVisible(false);
                                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                        pageData = {
                                            data: data.results,
                                            totalCount: !totalRecords ? data.count : totalRecords,
                                            page: query.page
                                        };
                                        setTableQuery({
                                            ...tableQuery,
                                            orderBy,
                                            totalCount: !totalRecords ? data.count : totalRecords,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                        setInitialLoad(false);
                                    })
                                    .catch((error: any) => {
                                        if (error) {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                        }
                                        setTableQuery({
                                            ...tableQuery,
                                            totalCount: 0,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                    });
                            } else {
                                resolve(pageData);
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={selectedReportType.title}
                            icon={SVG_ICONS.product} />
                    }
                    actions={[
                        {
                            icon: 'save_alt',
                            tooltip: 'Export',
                            isFreeAction: true,
                            onClick: handleExportClick,
                            disabled: isExporting
                        },
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => handleRefresh(),
                        },
                    ]}
                />
                <ExportMenu
                    anchorEl={exportBtnAnchorEl}
                    handleClose={handleExportMenuClose} />
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(ProductDuplicateNameScreen);