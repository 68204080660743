import {
    SET_SELECTED_TEMPLATE,
    GET_LABEL_PREVIEW,
    GET_LABEL_PREVIEW_SUCCESS,
    GET_LABEL_PREVIEW_FAILURE,
    GET_TEMPLATES,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILURE,
    CLEAR_LABEL_PREVIEW,
    GET_LITER_TAX_UUID,
    GET_LITER_TAX_UUID_SUCCESS,
    GET_LITER_TAX_UUID_FAILURE,
} from '../actions/actionTypes';
import { combineReducers, Action } from 'redux';
import { LabelerTemplate } from 'components/src/modules/labeler/models';
import {
    GET_LABELER_SUMMARY,
    GET_LABELER_SUMMARY_FAILURE,
    GET_LABELER_SUMMARY_SUCCESS,
} from '../actions/actionTypes';

export interface LabelerTemplateInitialState {
    data: [];
    selectedTemplate: number | string | null;
    selectedTemplateObj: LabelerTemplate | null;
    isFetching: boolean;
    success: boolean;
    error: boolean;
    errorData: any;
}

const initialState: LabelerTemplateInitialState = {
    data: [],
    selectedTemplate: null,
    selectedTemplateObj: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function labelerTemplatesReducer(state = initialState, action: any) {
    const { data } = action;
    switch (action.type) {
        case GET_TEMPLATES:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_TEMPLATES_SUCCESS:
            const newState = {
                ...state,
                isFetching: false,
                data: action.data.results,
                success: true,
                error: false,
                errorData: null,
            };
            if (data && data.results && data.results.length === 1) {
                newState.selectedTemplate = data.results[0].id;
                newState.selectedTemplateObj = data.results[0];
            }
            return newState;
        case GET_TEMPLATES_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case SET_SELECTED_TEMPLATE:
            return {
                ...state,
                selectedTemplate: action.data,
                selectedTemplateObj: state.data
                    ? state.data.find((t: LabelerTemplate) => t.id === action.data)
                    : null,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        default:
            return state;
    }
}

export function literTaxUUIDReducer(state = initialState, action: any) {
    const { data } = action;
    switch (action.type) {
        case GET_LITER_TAX_UUID:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_LITER_TAX_UUID_SUCCESS:
            const newState = {
                ...state,
                isFetching: false,
                data: action.data.liter_tax_product_uuid,
                success: true,
                error: false,
                errorData: null,
            };
            return newState;
        case GET_LITER_TAX_UUID_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        default:
            return state;
    }
}

const zplPreviewState = {
    data: null as any,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function labelerZplPreviewReducer(state = zplPreviewState, action: any) {
    switch (action.type) {
        case GET_LABEL_PREVIEW:
            return {
                data: null,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_LABEL_PREVIEW_SUCCESS:
            return {
                isFetching: false,
                data: action.data,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_LABEL_PREVIEW_FAILURE:
            return {
                data: null,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case CLEAR_LABEL_PREVIEW:
            return {
                data: null,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        default:
            return state;
    }
}

const initialLabelerSummaryState = {
    activeCount: 0,
    totalCount: 0,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function labelerSummaryDataReducer(state = initialLabelerSummaryState, action: any) {
    switch (action.type) {
        case GET_LABELER_SUMMARY:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_LABELER_SUMMARY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ...action.data,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_LABELER_SUMMARY_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        default:
            return state;
    }
}

export const labelerReducer = combineReducers({
    labelerTemplates: labelerTemplatesReducer,
    zplPreviewData: labelerZplPreviewReducer,
    labelerSummaryData: labelerSummaryDataReducer,
    literTaxUUID: literTaxUUIDReducer,
});
