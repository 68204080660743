import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridGap: theme.spacing(3),
    },
    card: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      //   marginBottom: theme.spacing(1),
    },
    cardContent: {
      paddingTop: 8,
      paddingBottom: '12px !important'
    },
    cardTitle: {
      paddingLeft: 12
    },
    cardIconWrapper: {
      width: 28,
      height: 28,
      color: '#fff'
    },
    cardIcon: {
      display: 'flex',
      justifyContent: 'center',
      padding: 8,
      borderRadius: '50%',
    },
    launchIconGrid: {
      paddingBottom: 24,
      marginRight: -8
    },
    cardSvgIcon: {
      width: '100%',
      height: '100%'
    },
    cardLinkBtn: {
      // padding: 6
    },
    iconGrid: {
      display: 'flex',
      alignItems: 'center'
    },
    divider: {
      margin: theme.spacing(1, 0),
    },
    subDivider: {
      margin: theme.spacing(1, 0),
    },
    titleRow: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: -8
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    rowInfo: {
      color: GlobalStyles.primaryColor,
      paddingRight: 8,
      fontWeight: 500
    },
    progress: {
      margin: theme.spacing(2),
      justifySelf: 'center'
    },
  }),
);