
import { 
    GET_STOCK_RECEIPT_SUMMARY, GET_STOCK_RECEIPT_SUMMARY_FAILURE,
    GET_STOCK_RECEIPT_SUMMARY_SUCCESS
} from '../actions/actionTypes';


const initialStockReceiptSummaryState = {
    openCount: 0,
    totalCount: 0,
    oldestOpenDate: null,
    lastCreatedOn: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null
}

export const stockReceiptSummaryDataReducer = (state=initialStockReceiptSummaryState, action: any) => {
    switch(action.type) {
        case GET_STOCK_RECEIPT_SUMMARY: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null

        }
        case GET_STOCK_RECEIPT_SUMMARY_SUCCESS: return {
            ...state,
            isFetching: false,
            ...action.data,
            success: true,
            error: false,
            errorData: null

        }
        case GET_STOCK_RECEIPT_SUMMARY_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        }
        default: return state;
    }
}