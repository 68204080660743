import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';
const BASE_URL = API_CONFIG.API_BASE_PATH;
export const API_URLS={
    coreUserDetails: BASE_URL + 'core/account/core-user-details',
    isPasswordValid:  BASE_URL + 'core/account/is-password-valid',
    resetPassword:  BASE_URL + 'core/account/reset-password',
    isPinValid:BASE_URL + 'core/account/is-pin-valid',
    resetPin:  BASE_URL + 'core/account/reset-pin',
    updateProfileChanges : BASE_URL + 'core/account/update-profile-changes',
  }

  export const getCoreUserDetails = (data:any) => {
    console.log("======>",data)
    let URL = `${API_URLS.coreUserDetails}/`;
    console.log("======>",URL)
    return composeRequest(URL, 'POST', JSON.stringify(data)); 
  };

  export const getIsPasswordValid = (data:any) => {
    let URL = `${API_URLS.isPasswordValid}/`;
    console.log("DATA====>",JSON.stringify(data))
    return composeRequest(URL,'POST',JSON.stringify(data)); 
  };

  export const resetPassword = (data:any) => {
    let URL = `${API_URLS.resetPassword}/`;
    console.log("DATA====>",JSON.stringify(data))
    return composeRequest(URL,'POST',JSON.stringify(data)); 
  };

  export const getIsPinValid = (data:any) => {
    let URL = `${API_URLS.isPinValid}/`;
    console.log("DATA====>",JSON.stringify(data))
    return composeRequest(URL,'POST',JSON.stringify(data)); 
  };

  export const resetPin = (data:any) => {
    let URL = `${API_URLS.resetPin}/`;
    console.log("DATA====>",JSON.stringify(data))
    return composeRequest(URL,'POST',JSON.stringify(data)); 
  };

  export const updateProfileChanges = (data:any) => {
    let URL = `${API_URLS.updateProfileChanges}/`;
    console.log(data)
    console.log("DATA====>",JSON.stringify(data))
    return composeRequest(URL,'POST',JSON.stringify(data)); 
  };