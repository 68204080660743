import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { SupplierReorderCodeMismatch, ReportType } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import { Typography, Divider, SvgIcon, CircularProgress } from '@material-ui/core';
import { API_URLS } from '../../api/';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG,ERROR_404 } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { getRetailBusinessIds,updateProductRetailersSupplier ,updateSuppliersProduct} from '../../api';
import InputLabel from '@material-ui/core/InputLabel';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Paper } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    suppliermsg: {
      color: GlobalStyles.primaryColor,
      fontSize: 16
    },
    errorText: {
      color: theme.palette.error.main,
      fontWeight: 400
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    overwriteDataRadioButton: {
      // margin: auto;
      // width: 60%;
      // padding: 10px;
      marginLeft: '0.9rem'
    }
  }),
);



//api call 
const getProductsByPage = (businessId: string | number, retailBusinessID: string | number, statusFilter: string | number,page: number, pageSize: number,
  searchTerm: string | null, orderBy?: string | number) => {
  let URL = API_URLS.supplierReorderCodeMismatchUrl +
    `?supp_business_id=${businessId}&ret_business_id=${retailBusinessID}&page=${page}&page_size=${pageSize}`;
  if(statusFilter){
     URL += `&reorder_info_status_num=${statusFilter}`;
    //URL=API_URLS.supplierReorderCodeMismatchUrl +
    //`?supp_business_id=${businessId}&ret_business_id=${retailBusinessID}&page=${1}&page_size=${10}&reorder_info_status_num=${statusFilter}`
  }
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL += `&search=${searchTerm}`;
  }
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
};

const exportData = (businessId: string | number, retailBusinessID: string | number, statusFilter: string | number,searchTerm: string | null, orderBy?: string | number) => {
  let URL = API_URLS.supplierReorderCodeMismatchUrl + `export/?supp_business_id=${businessId}&ret_business_id=${retailBusinessID}`;
  if(statusFilter){
    URL += `&reorder_info_status_num=${statusFilter}`;
  }
  if (searchTerm) {
    URL += `&search=${searchTerm}`;
  }
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
};



const SupplierReorderCodeMismatchScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [uiLoading, setUILoading] = useState(false);
  const tableRef = useRef<{ onQueryChange: any }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setExporting] = useState(false);
  const [retailerBusinessIds, setretailerBusinessIds] = useState([]);
  const [selectedRetailerId, setRetailerId] = useState('')
  const [retailerSelected, setRetailerSelected] = useState(false);
  const [selectedinfoNumStatus, setSelectedInfoNumStatus] = useState('');
  const [dialogBoxOpen,setDialogBoxOpen]=useState(false);
  const[selectedValue,setSelectedValue]=useState('');
  const[rowData,setRowData]=useState<any>({});
  const[updatedProductSuccess,setUpdatedProductSuccess] =useState(false);
  const[addButtonClicked,setAddButtonClicked]=useState('');
  const [overwriteButtonClicked,setOverwriteButtonClicked]=useState('');
  
  
  
  useEffect(() => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
    // tableRef && tableRef.current && tableRef.current.onChangePage();
    // console.log("props.userData",props.userData);
  }, [props.userData.selectedBusiness, selectedRetailerId, selectedinfoNumStatus]);

  useEffect(() => {
    getRetailBusinessIds()
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        // console.log("retailerBusinessIds",jsonData);
        setretailerBusinessIds(jsonData);
      })
      .catch((error: any) => {
        console.log('error ', error);
      });
  }, []);

  


  useEffect(()=>{

    tableRef && tableRef.current && tableRef.current.onQueryChange();  // this will make api call again and change the table data if any changes are there  

  },[updatedProductSuccess])


  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      exportData(sb.business_id, selectedRetailerId, selectedinfoNumStatus, tableQuery.search, sb.orderBy)
        .catch(handleError)
        .then(checkStatus)
        .then(res => res.blob())
        .then(data => {
          download(data, 'SupplierReorderCodeMismatch.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } 
            // else if ((error.status === 403 || error.status === 401)) {
            //   setEmptyDataSourceMessage(ERROR_MSG);
            //   setErrorStatusVisible(true);
            // } 
            else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  const handleRetailerId = (event: any) => {
    setRetailerId(event.target.value);
    setRetailerSelected(true);
  }


  const handleReorderInfoStatus = (event: any) => {
    setSelectedInfoNumStatus(event.target.value);
  }



  const handleChange = (event: any) => { // on choosing value from radio button this will be called 
    setSelectedValue(event.target.value);
    console.log(event.target.value);
    //setSelectedValueSelected(true);
  }

  const handleCancelButtonClick=()=>{
    setDialogBoxOpen(false)
    setSelectedValue('');
    setAddButtonClicked('');
    setOverwriteButtonClicked('')
    
    
  }
 
  const handleAddButtonClicked=(event: any)=>{
    setAddButtonClicked(event.target.value)
    
  }

  const handleOverwriteButtonClicked=(event:any)=>{
    setOverwriteButtonClicked(event.target.value)
  }

  const fixErrorDialogBox=()=>{
     console.log("rowData",rowData)
    // console.log("dialogBoxOpen",dialogBoxOpen)
    // console.log("inside function")
    return (
    <div>
  
      {dialogBoxOpen && 
      <Dialog
      open={dialogBoxOpen}
      onClose={()=>{handleCancelButtonClick()}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      {rowData.error_type==="different_quantity_different_reorder_code" ?
        
        <DialogTitle style={{color:GlobalStyles.primaryColor}}>{'Choose Appropiate Action'}</DialogTitle>:

      <DialogTitle style={{color:GlobalStyles.primaryColor}}>{'Select Correct Data To Retain'}</DialogTitle>
      }
      <DialogContent style={{alignSelf:'center'}}>
       <div>
       { 
        rowData.supp_item_code!=null && rowData.supp_item_qty!=null && rowData.ret_item_reorder_code!=null && rowData.ret_item_reorder_qty!=null &&  
          
        <FormControl component="fieldset">
        <RadioGroup aria-label="select" value={overwriteButtonClicked} onChange={handleOverwriteButtonClicked}> 
              <FormControlLabel value='overwriteButtonClicked' control={<Radio />} label={`Overwrite Supplier/Retailer Data : `} />
          </RadioGroup>

        </FormControl>

      }
    </div>
    <div className={classes.overwriteDataRadioButton} >
     {
       
       overwriteButtonClicked && rowData.supp_item_code!=null && rowData.supp_item_qty!=null && rowData.ret_item_reorder_code!=null && rowData.ret_item_reorder_qty!=null &&
          <FormControl component="fieldset">
          <RadioGroup aria-label="select" value={selectedValue} onChange={handleChange}> 
                <FormControlLabel value='Supplier' control={<Radio />} label={`Supplier's Product Item Code : ${rowData.supp_item_code}, Product Quantity : ${rowData.supp_item_qty}`} />
                <FormControlLabel value='Retailer' control={<Radio />} label={`Retailer's Supplier Reorder Code : ${rowData.ret_item_reorder_code}, Product Quantity : ${rowData.ret_item_reorder_qty}`} />
            </RadioGroup>
          </FormControl>
      } 
    </div>
        {// only Add button will be displayed 
        rowData.error_type ==='different_quantity_different_reorder_code' && 
          
          <FormControl component="fieldset">
          <RadioGroup aria-label="select" value={addButtonClicked} onChange={handleAddButtonClicked}> 
                <FormControlLabel value='addButtonClicked' control={<Radio />} label={`Add New Entry : `} />
            </RadioGroup>

          </FormControl>

        }
        
        <div className={classes.overwriteDataRadioButton}>
        
        {
        
          // Supplier is blank Only add retailer 
          addButtonClicked && rowData.error_type ==='different_quantity_different_reorder_code'  && rowData.ret_item_reorder_code!=null && rowData.ret_item_reorder_qty!=null &&
          <FormControl component="fieldset">
          <RadioGroup aria-label="select" value={selectedValue} onChange={handleChange}> 
                <FormControlLabel value='AddRetailer' control={<Radio />} label={`Add Retailer To Supplier :
                 Retailer's Supplier Reorder Code : ${rowData.ret_item_reorder_code}, Product Quantity : ${rowData.ret_item_reorder_qty} `} />
            </RadioGroup>

          </FormControl>
        }      
      
        {
        
          // Retailer is blank Only add Supplier 
          
          addButtonClicked && rowData.error_type ==='different_quantity_different_reorder_code'  && rowData.supp_item_code!=null && rowData.supp_item_qty!=null &&
          <FormControl component="fieldset">
          <RadioGroup aria-label="select" value={selectedValue} onChange={handleChange}> 
                <FormControlLabel value='AddSupplier' control={<Radio />} label={`Add Supplier to Retailer:
                 Supplier's Product Item Code : ${rowData.supp_item_code}, Product Quantity : ${rowData.supp_item_qty} `}  />
            </RadioGroup>

          </FormControl>
        }      
      
        {
        
          // Add Both Supplier and Retailer
          
          addButtonClicked && rowData.error_type ==='different_quantity_different_reorder_code'  && rowData.supp_item_code!=null && rowData.supp_item_qty!=null && rowData.ret_item_reorder_code!=null && rowData.ret_item_reorder_qty!=null &&
          <FormControl component="fieldset">
          <RadioGroup aria-label="select" value={selectedValue} onChange={handleChange}> 
                <FormControlLabel value='AddBothSupplierAndRetailer' control={<Radio />} label={`Add in both direction: `}  />
            </RadioGroup>

          
          </FormControl>
        }
       </div>
        
        
        
        {
          selectedValue && 
        <div>
            <Typography style={{ paddingRight: 10, color: GlobalStyles.red }}>
              NOTE :
            </Typography>
          </div>
            }
          {selectedValue=='Supplier' && rowData.error_type=='different_reorder_code_same_quantity' &&
          <div>
                   
              <p style={{ paddingRight: 10, color: '#cd2737' }}>- Choosing Supplier - <b>Retailer's</b> Reorder Code will be changed from  <span style={{ color: '#0000CD' }}>{rowData.ret_item_reorder_code} </span> to <span style={{ color: '#0000CD' }}> {rowData.supp_item_code}  </span> on db and on korona on clicking OK button.</p>
            
          </div>
          }
          {selectedValue=='Supplier' && rowData.error_type=='different_quantity_same_reorder_code' &&
          <div>
          
              <p style={{ paddingRight: 10, color: '#cd2737' }}>- Choosing Supplier - <b>Retailer's</b> Quantity will be changed from <span style={{ color: '#0000CD' }}>  {rowData.ret_item_reorder_qty} </span> to <span style={{ color: '#0000CD' }}> {rowData.supp_item_qty}</span> on db and on korona on clicking OK button</p>
           
          </div>
          }
          {selectedValue=='Supplier' && rowData.error_type=='different_quantity_different_reorder_code' &&
          <div>
               
              <p style={{ paddingRight: 10, color: '#cd2737' }}>- Choosing Supplier - <b>Retailer's </b> Reorder Code will be changed to <span style={{ color: '#0000CD' }}> {rowData.supp_item_code} </span> and <b> Retailer's </b> Quantity will be changed to  <span style={{ color: '#0000CD' }}> {rowData.supp_item_qty} </span> on db and on korona on clicking OK button</p>
            
          </div>
          }


          {selectedValue=='Retailer' && rowData.error_type=='different_reorder_code_same_quantity' &&
          <div>
           
              <p style={{ paddingRight: 10, color: '#cd2737' }}>- Choosing Retailer - <b> Supplier's  </b> Product Item Code will be changed from <span style={{ color: '#0000CD' }}> {rowData.supp_item_code} </span>  to <span style={{ color: '#0000CD' }}> {rowData.ret_item_reorder_code} </span> on db and on korona on clicking OK button.</p>
           
          </div>
          }
          {selectedValue=='Retailer' && rowData.error_type=='different_quantity_same_reorder_code' &&
          <div>
            
           
              <p style={{ paddingRight: 10, color: '#cd2737' }}>- Choosing Retailer - <b>Supplier's </b> Quantity will be changed from <span style={{ color: '#0000CD' }}> {rowData.supp_item_qty} </span>  to  <span style={{ color: '#0000CD' }}> {rowData.ret_item_reorder_qty} </span> on db and on korona on clicking OK button.</p>
           
          </div>
          }

          {selectedValue=='Retailer' && rowData.error_type=='different_quantity_different_reorder_code' &&
          <div>
            
          
              <p style={{ paddingRight: 10, color: '#cd2737' }}>- Choosing Retailer - <b> Supplier's  </b> Reorder Code will be changed to <span style={{ color: '#0000CD' }}> {rowData.ret_item_reorder_code} </span> and Supplier's Quantity will be changed to <span style={{ color: '#0000CD' }}> {rowData.ret_item_reorder_qty} </span> on db and on korona on clicking OK button</p>
            
          </div>
          }

        {selectedValue=='AddRetailer' && rowData.error_type=='different_quantity_different_reorder_code' &&
          <div>
            
           
              <p style={{ paddingRight: 10, color: '#cd2737' }}>- Choosing Copy Retailer - A New entry will be created on <b> Supplier </b> with Retailer's Reorder Code <span style={{ color: '#0000CD' }}> {rowData.ret_item_reorder_code} </span> and  Retailer's Quantity <span style={{ color: '#0000CD' }}> {rowData.ret_item_reorder_qty} </span> on db and on korona on clicking OK button</p>
          
          </div>
          }


          {selectedValue=='AddSupplier' && rowData.error_type=='different_quantity_different_reorder_code' &&
          <div>
            
            {/* <Typography style={{ paddingRight: 10, color: '#cd2737' }}> */}
             <p style={{ paddingRight: 10, color: '#cd2737' }}> - Choosing Copy Supplier - A New entry will be created on <b> Retailer </b>  with Supplier's Reorder Code <span style={{ color: '#0000CD' }}>  {rowData.supp_item_code} </span> and  Supplier's Quantity <span style={{ color: '#0000CD' }}>  {rowData.supp_item_qty} </span> on db and on korona on clicking OK button</p> 
            {/* </Typography> */}
          </div>
          }

        {selectedValue=='AddBothSupplierAndRetailer' && rowData.error_type=='different_quantity_different_reorder_code' &&
          <div>
            
            {/* <Typography style={{ paddingRight: 10, color: '#cd2737' }}> */}
              <p style={{ paddingRight: 10, color: '#cd2737' }}> -  A New entry will be created on Retailer and also on Supplier with Supplier's Item Code <span style={{ color: '#0000CD' }}> {rowData.supp_item_code}</span> and Supplier's Quantity <span style={{ color: '#0000CD' }}>{rowData.supp_item_qty}</span> <b>on Retailer Side </b> and Retailer's Reorder Code <span style={{ color: '#0000CD' }}>{rowData.ret_item_reorder_code}</span> and Retailer's Quantity <span style={{ color: '#0000CD' }}> {rowData.ret_item_reorder_qty} </span> <b>on Supplier side</b>
               on db and on korona on clicking OK button</p> 
            {/* </Typography> */}
          </div>
          }

          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{handleCancelButtonClick()}} color="primary" autoFocus>
              Cancel
                                </Button>
            <Button onClick={()=>{handleOk()}} style={{ color: "#ff1744" }} >
              OK
                                </Button>
          </DialogActions>

        {
        // on clicking okay call the handle function
        }
        </Dialog>
  }
      </div>
   );

  }
  const handleCodeOrQuantityMismatch=(rowData:any)=>{

    setDialogBoxOpen(true)
    setRowData(rowData)
    

  }

  const handleBothCodeAndQuantityMismatch=(rowData:any)=>{

    setDialogBoxOpen(true)
    setRowData(rowData)
    

  }



  const handleOk=() =>{
    console.log("selected value",selectedValue);
    setUILoading(true)
    if (selectedValue=='Supplier' || selectedValue=='AddSupplier' || selectedValue=='AddBothSupplierAndRetailer'){ // put or condition here to check for AddSupplier Case or AddBothSupplierAndRetailer
      // call the api to update retailer's supplier record here 
      let payload={data:{product:{business:null,uuid:null,name:null,suppliers:false}}}

      payload['korona_supplier_business_id']=rowData.supp_business_id
      payload['data']['product']['business']= rowData.ret_business_id
      payload['data']['product']['uuid']=rowData.retailer_product_id
      payload['data']['product']['name']=rowData.ret_item_name
      payload['data']['product']['suppliers']=false
      payload['data']['reOrderCode']=rowData.supp_item_code
      payload['data']['casesDelivered']=false
      payload['data']['master_supplier_container_qty']=rowData.supp_item_qty
      if (selectedValue ==='AddSupplier'||selectedValue==='AddBothSupplierAndRetailer'){
        payload['create_new_record_for_supplier']=true // check this for Addcase or Add both directions case
      }
      else{  
          payload['create_new_record_for_supplier']=false 
      }
      //check for add case here 
      updateProductRetailersSupplier(payload)
      .catch (handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setDialogBoxOpen(false)
        setUpdatedProductSuccess(true)
        setSelectedValue('')
        setUILoading(false);
        setAddButtonClicked('');
        setOverwriteButtonClicked('');
  })  .catch((error: any) => {
        if(error.status===409){
          alert("Same OrderCode and Quantity already exists on Retailer, Please ignore this item")
        }
       setUILoading(false);
       setDialogBoxOpen(false)
       setSelectedValue('')
       setUpdatedProductSuccess(false)
       setAddButtonClicked(''); 
       setOverwriteButtonClicked('');
  
  });    
  
    } 
    if (selectedValue=='Retailer' || selectedValue=='AddRetailer' || selectedValue=='AddBothSupplierAndRetailer'){// put or condition here to check for AddRetailer Case  or AddBothSupplierAndRetailer
      let payload={}
      payload['supplier_business'] = rowData.supp_business_id
      payload['product_uuid']=rowData.supplier_product_id
      payload['product_name']=rowData.supp_item_name
      payload['retailer_reorder_code']=rowData.ret_item_reorder_code
      payload['retailer_quantity']=rowData.ret_item_reorder_qty

      if (selectedValue ==='AddRetailer' || selectedValue==='AddBothSupplierAndRetailer'){
        payload['create_new_record_of_retailer_on_supplier']=true // check this for Addcase or Add both directions case
      }
      else{  
          payload['create_new_record_of_retailer_on_supplier']=false 
      } 
      
      updateSuppliersProduct(payload)
      .catch (handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setUpdatedProductSuccess(true)
        setSelectedValue('')
        setUILoading(false)
        setDialogBoxOpen(false)
        setAddButtonClicked('');
        setOverwriteButtonClicked('');

  })  .catch((error: any) => {
        if(error.status===409){
          alert("Same OrderCode and Quantity already exists on Supplier, Please ignore this item")
        }
        setUILoading(false)
        setSelectedValue('')
        setUpdatedProductSuccess(false)
        setDialogBoxOpen(false)
        setAddButtonClicked('');
        setOverwriteButtonClicked('');

  
  });   
      }
      else { // if user does not select any option and clicks OK 

        setUILoading(false)
        setSelectedValue('')
        setUpdatedProductSuccess(false)
        setDialogBoxOpen(false)
        setAddButtonClicked('');
        setOverwriteButtonClicked('')
      }
    }





  const renderTable = () => {
    return (
      <Container >
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <ExportStatusBar isVisible={isExporting} />
        <div className="table-wrapper">
        {
        uiLoading &&
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            tableRef={tableRef}
            //onChangePage={handleChangePage}
            //onFilterChange={hackyResetPage}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns ={[
              { title: 'Supp. ItemName', field: 'supp_item_name' },
              { title: 'Supp. ItemCode', field: 'supp_item_code' },
              { title: 'Supp. ItemQty', field: 'supp_item_qty' },
              { title: 'Ret. ItemName', field: 'ret_item_name' },
              { title: 'Ret. ItemReorderCode', field: 'ret_item_reorder_code' },
              { title: 'Ret. ItemReorderQty', field: 'ret_item_reorder_qty' },
              { title: 'Reorder Info Status', field: 'reorder_info_status' },
              {
                title: 'Issue',
                field:'error',
                editable: 'never',
                render: rowData => {
                if (rowData.error) {
                return <Typography variant="subtitle2" style={{ paddingRight: 10, color: '#cd2737' }}>{rowData.error}</Typography>;
                }
                return <Typography variant="subtitle2" >----</Typography>;
                }
                },
                {
                title: 'Action',
                field:'error',
                editable: 'never',
                render: rowData => {
                if (rowData.error_type==='different_reorder_code_same_quantity'|| rowData.error_type==='different_quantity_same_reorder_code') {
                  
                  return (<div><Button variant="contained" color="secondary" style={{ textTransform: 'none' }}
                  onClick={() =>
                    handleCodeOrQuantityMismatch(rowData)
                  
                  }>Fix</Button></div>);             
                
                }
                else if(rowData.error_type ==='different_quantity_different_reorder_code')
                { 
                  return (<div><Button variant="contained" color="secondary" style={{ textTransform: 'none' }}
                  onClick={() =>
                    handleBothCodeAndQuantityMismatch(rowData)
                  
                  }>Fix</Button></div>);
                }
                
              }
            }
          
            ]}
            data={query =>
              
              new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as SupplierReorderCodeMismatch[],
                  totalCount: 0,
                  page: query.page
                
                };
                if (props.userData && props.userData.selectedBusiness && selectedRetailerId) {
                  const sb = props.userData.selectedBusiness;
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }
                  getProductsByPage(sb.business_id, selectedRetailerId, selectedinfoNumStatus, // selectedinfoNumStatus is the number for filter in db view column ReorderStatusinfoNum
                    query.page + 1, query.pageSize, query.search, orderBy)
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<SupplierReorderCodeMismatch>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      

                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize,
                        //page:( query.pageSize > 0 && data.results.length === data.count ) ? query.page : 0 ,
                        search: query.search
                      });
                      //console.log("PageData",pageData)
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } 
                        // else if (error.status === 404 ) {
                        //   console.log("404 found")
                        //   setEmptyDataSourceMessage(ERROR_404);
                        //   setErrorStatusVisible(true);
                        // } 
                        else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                         page: query.page,    
                        pageSize: query.pageSize,
                        search: query.search
                      });
                      resolve(pageData);
                    });
                } else {
                  resolve(pageData);
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={"Supplier Reorder Code Mismatch"}
                icon={SVG_ICONS.product} />
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting
              },
              {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
          <ExportMenu
            anchorEl={exportBtnAnchorEl}
            handleClose={handleExportMenuClose} />
          
        </div>
      </Container>
    );
  }


  return (
    <div>
      {
        props.userData &&
          props.userData.selectedBusiness &&
          props.userData.selectedBusiness.is_supplier === true ?
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>

              <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
                Select Retailer:
              </Typography>
              <FormControl
                style={{
                  marginRight: 16,
                }}>

                <Select
                  labelId="select-label"
                  id="select-business"
                  value={selectedRetailerId}
                  onChange={handleRetailerId}
                  className={classes.select}
                >
                  {retailerBusinessIds.map(item => (
                    <MenuItem
                      value={item.id}
                      key={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
              {retailerSelected &&
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }} >
                <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
                    Filter by Info Status:
                </Typography>
                  <FormControl
                  style={{
                      marginRight: 16,
                  }}>
                    <Select
                      labelId="select-label"
                      id="select-info-status"
                      value={selectedinfoNumStatus}
                      onChange={handleReorderInfoStatus}
                      className={classes.select}
                    >
                      <MenuItem value=''> </MenuItem>
                      <MenuItem value={'1'}>Both Match</MenuItem>
                      <MenuItem value={'2'}>Qty Mismatch</MenuItem>
                      <MenuItem value={'3'}>Code Mismatch</MenuItem>
                      <MenuItem value={'4'}>No Match</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              }
            </div>
            <Divider className={classes.divider} />
            {retailerSelected === true  ?
              renderTable() : null
              
            }
             
            
          </div> :
          <Typography className={classes.suppliermsg}>
            Selected Business is not a supplier, Please choose a Supplier from dropdown
          </Typography>
}


  {fixErrorDialogBox()}            

      
    </div>
  )
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(SupplierReorderCodeMismatchScreen);