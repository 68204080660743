import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MaterialTableProps, MTableToolbar } from 'material-table';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Container, Typography, FormControl, TextField, Select, Divider, Button } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { PaginatedResponse } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { RetailerCodesWithinGroupNotInMasterIdentifier } from '../../models';
import { getRetailerCodesWithinGroupNotInMasterIdentifier, getCompaniesBrandsInRtlCodeWithinGroupReport,addExcludeBusinessCodeRtlWithinGrpMpi } from '../../api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import cloneDeep from 'lodash/cloneDeep';
import GlobalStyles from '../../../../styles/GlobalStyles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
  CustomTableTitle,
  ErrorStatusBar,
  ExportMenu,
  ExportStatusBar,
} from '../../../shared/components';
import download from 'downloadjs';
import sortBy from 'lodash/sortBy';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
    toolbar: {
      width: '80%',
    },
    rootSelectBtn: {
      minWidth: '1rem',
    },
    disableHover: {
      "&:hover": { backgroundColor: 'initial' }
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '21rem',
      minWidth: '21rem',
    },
  }),
);

const RetailerCodesWithinGroupNotInMasterIdentifierScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setExporting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([] as any);
  const [selectedCompany, setSelectedCompany] = useState(null as any);
  const [brands, setBrands] = useState([] as any);
  const [selectedBrand, setSelectedBrand] = useState(null as any);
  const [selectedRows, setSelectedRows] = useState([]);
  const [excludedCodes, setExcludedCodes] = useState(false);
  const [totalRecords, setTotalRecords] = useState(null as any)


  useEffect(() => {
    fetchBrandsandCompanies()
  }, []);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
    setTotalRecords(null)

  }, [selectedCompany, selectedBrand, excludedCodes]);
  


  useEffect(()=>{
    console.log("Selected Rowssss", selectedRows)
  },[selectedRows])

  const handleRefresh= () => {
    setTotalRecords(null)
    tableRef.current && tableRef.current.onQueryChange()
  }

  const fetchBrandsandCompanies = () => {
    const sb = props.userData.selectedBusiness;
    setLoading(true)
    getCompaniesBrandsInRtlCodeWithinGroupReport()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setLoading(false)
        // console.log("B4-updating", data)

        if (data.companies) {
          let updatedData = addNoneToArray(data.companies)
          setCompanies(updatedData)
        }

        if (data.brands) {
          let updatedData = addNoneToArray(data.brands)
          setBrands(updatedData)
        }
        setSelectedRows([])
      })
      .catch((error: any) => {
      });
  };

  const addNoneToArray = (data: any) => {
    let updatedData = cloneDeep(sortBy(data, 'name'))

    // console.log("test", updatedData)
    let obj = { ...data[0] }
    Object.keys(obj).forEach(k => obj[k] = null)
    updatedData.unshift(obj)
    let index = updatedData.findIndex((d: any) => d.id === null)
    updatedData[index]['name'] = 'None'
    // console.log("after", updatedData,)
    return updatedData
  }

  const getFilter = () => {
    let filterString = ''
    if (selectedCompany) {
      filterString = `&company_id=${selectedCompany.id === null ? null : selectedCompany.id}`
    }
    if (selectedBrand) {
      filterString += `&brand_id=${selectedBrand.id === null ? null : selectedBrand.id}`
    }
    return filterString
  };

  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      const filters = getFilter();
      getRetailerCodesWithinGroupNotInMasterIdentifier(
        null,
        null,
        tableQuery.search,
        true ? totalRecords : false,
        null,
        filters,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'retailer_codes_within_group_not_in_master_identifier.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };


  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };


  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  const handleCompanySelectionChange = (event: React.ChangeEvent<{ value: string; }>, value: any) => {
    setSelectedBrand(null);
    setSelectedCompany(value);
  };

  const handleBrandSelectionChange = (event: React.ChangeEvent<{ value: string; }>, value: any) => {
    setSelectedCompany(null)
    setSelectedBrand(value);
  };

  const handleExcludeBusinessCode = () => {
    // setLoading(true)
    let productCodes = [];
    let records = cloneDeep(selectedRows);
    console.log(records)
    for (let i = 0; i < records.length; i++) {
      let hasMultipleProducts = records[i]['product_uuids'].includes(",") ? true : false

      if (hasMultipleProducts){
        let uuids = records[i]['product_uuids'].split(",")
        console.log("result", uuids)
        for (const product_id of uuids) {
          let updatedProductId = product_id.split(' ').join('')
          // console.log("B4", product_id, product_id.length)
          // console.log("Updated", updatedProductId, updatedProductId.length)
          let obj = {};
          obj['business_code'] = records[i]['code']
          obj['product_uuid'] = updatedProductId
          productCodes.push(obj);
        }

      }else{
        let obj = {};
        obj['business_code'] = records[i]['code']
        obj['product_uuid'] = records[i]['product_uuids']
        productCodes.push(obj);
      }
      
    }

    // console.log("exclude-list",identifiers)
    let payload = { product_codes: productCodes }
    console.log("payload", payload)
    addExcludeBusinessCodeRtlWithinGrpMpi(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        // console.log("JSONDATA", jsonData)
        setLoading(false);
        console.log("SelectedRows", selectedRows)
        setSelectedRows([])
        setExcludedCodes(!excludedCodes)
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        props.showToast({
          message: 'Retailer Code(s) has been excluded!',
          options: ToastSuccess,
        });
        

      })
      .catch((error: any) => {
        setLoading(false);
        setSelectedRows([])
        setExcludedCodes(!excludedCodes)
        let msg = 'Failed to exclude code(s).';
        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg, options: ToastError });
        
      });
  }


  const renderTable = () => {
    return (
      <div>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <ExportStatusBar isVisible={isExporting} />

        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true,
              selection: true,
              selectionProps: {
                color: 'secondary'
              },
              showTextRowsSelected: false,
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: (!selectedBrand && !selectedCompany) ? `Choose a filter above to display results` : emptyDataSourceMessage
              }
            }}
            // components={{
            //   Toolbar: renderTableToolbarComponent
            // }}
            isLoading={isLoading}
            columns={[
              {
                title: 'Code',
                field: 'code',
                render: rowData => {
                  if (rowData && rowData.code) {
                    return <Typography style={{ minWidth: 150 }} >{rowData.code}</Typography>
                  }
                  return <div></div>;
                },
              },
              {
                title: 'Company Name',
                field: 'company_name',
                render: rowData => {
                  if (rowData && rowData.company_name) {
                    return <Typography style={{ minWidth: 150 }} >{rowData.company_name}</Typography>
                  }
                  return <div></div>;
                },
              },
              {
                title: 'Brand Name',
                field: 'brand_name',
                render: rowData => {
                  if (rowData && rowData.brand_name) {
                    return <Typography style={{ minWidth: 150 }} >{rowData.brand_name}</Typography>
                  }
                  return <div></div>;
                },
              },
              {
                title: 'Item Names',
                field: 'product_names',
                render: rowData => {
                  if (rowData && rowData.product_names) {
                    return <Typography style={{ minWidth: 410 }} >{rowData.product_names}</Typography>
                  }
                  return <div></div>;
                },
              },

            ]}
            data={query =>
              new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as any[],
                  totalCount: 0,
                  page: query.page
                };
                const filters = getFilter();
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                let totalRecsAvailable = totalRecords !== null ?  true : false
                if (!filters) {
                  resolve(pageData);
                } else {
                  getRetailerCodesWithinGroupNotInMasterIdentifier(
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    totalRecsAvailable,
                    orderBy,
                    filters,
                    false
                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<any>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      if(!totalRecords){
                        setTotalRecords(data.count)
                      }
                      pageData = {
                        data: sortBy(data.results, 'company_name'),
                        totalCount: !totalRecords ? data.count : totalRecords,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        totalCount: !totalRecords ? data.count : totalRecords,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)
                        ) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    });
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Retailer Codes Within Group Not In Master Identifier'}
                icon={SVG_ICONS.product} />
            }
            onSelectionChange={(data) => {
              setSelectedRows(data)
            }}
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => handleRefresh(),
              },
            ]}
          />
          <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

        </div>
      </div>
    );

  }

  return (
    <div style={{ marginTop: -15, maxWidth: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, marginTop: 20 }}>
          <Button
            variant="contained"
            onClick={handleExcludeBusinessCode}
            color="primary"
            disabled={selectedRows.length === 0}
            startIcon={<RemoveCircleIcon />}
          > Exclude
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}> */}
          {/* <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
          Filter by Brand:
        </Typography> */}
          <FormControl
            style={{
              marginRight: 16,
            }}>
            <Autocomplete
              disabled={isLoading}
              className={classes.select}
              style={{ minWidth: '' }}
              id="highlights-demo"
              options={companies}
              getOptionLabel={(s) => s ? s.name : ''}
              size={'small'}
              getOptionSelected={(option: any, value: string | any) => {
                if (typeof value !== 'string') {
                  return value && option ? option.name === value.name : false;
                }
                return false;
              }}
              loading={isLoading}
              value={selectedCompany ? selectedCompany : ''}
              onChange={handleCompanySelectionChange}
              renderInput={(params) => (
                <TextField {...params} label="Company" variant="outlined" margin="normal" />
              )}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);
                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
            />
          </FormControl>

          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }} >
            {/* <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
              Filter by Company:
            </Typography> */}
            <FormControl
              style={{
                marginRight: 16,
              }}>
              <Autocomplete
                disabled={isLoading}
                className={classes.select}
                id="highlights-demo"
                options={brands}
                getOptionLabel={(s) => s ? s.name : ''}
                size={'small'}
                getOptionSelected={(option: any, value: string | any) => {
                  if (typeof value !== 'string') {
                    return value && option ? option.name === value.name : false;
                  }
                  return false;
                }}
                loading={isLoading}
                value={selectedBrand ? selectedBrand : ''}
                onChange={handleBrandSelectionChange}
                renderInput={(params) => (
                  <TextField {...params} label="Brand" variant="outlined" margin="normal" />
                )}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option.name, inputValue);
                  const parts = parse(option.name, matches);
                  return (
                    <div>
                      {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      ))}
                    </div>
                  );
                }}
              />

            </FormControl>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      {renderTable()}

      {/* </div> */}
    </div>
  )

};


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailerCodesWithinGroupNotInMasterIdentifierScreen);