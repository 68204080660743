import { SVG_ICONS } from '../../shared/icons/SvgIcons';
import GlobalStyles from '../../../styles/GlobalStyles.web';
import { ReportCard } from './models';

export const SupplierSummaryFnMapping: { [key: string]: string; } = {
  // deviceSummary: 'getSupplierDeviceSummary',
  // userSummary: 'getSupplierUserSummary',
  // productSummary: 'getSupplierProductSummary',
  // businessSummary: 'getSupplierBusinessSummary',
};

export const SupplierDashboardCards: ReportCard[] = [
  {
    id: '1',
    title: 'Businesses',
    icon: SVG_ICONS.business,
    iconColor: GlobalStyles.green,
    navigationLink: 'businesses',
    isEnabled: true,
    // summaryMapping: 'moduleSummary',
    items: [
      {
        id: '1.1',
        title: 'Connected',
        defaultValue: '1',
        isDateTime: false,
        isVisible: true,
      }
    ]
  },
  {
    id: '2',
    title: 'Products',
    icon: SVG_ICONS.product,
    iconColor: GlobalStyles.primaryColor,
    navigationLink: 'products',
    isEnabled: true,
    // summaryMapping: 'productSummary',
    items: [
      {
        id: '2.1',
        title: 'Total',
        defaultValue: '818',
        itemMapping: 'totalCount',
        isDateTime: false,
        isVisible: true,
      }
    ]
  },
];