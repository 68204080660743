import { API_CONFIG } from 'components/src/modules/shared/api/config';
import composeRequest from 'components/src/modules/shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  webStoreOptionsByBusinessUrl: BASE_URL + 'korona/web-store/settings/options',
  saveWebStoreSettingsUrl : BASE_URL + 'korona/web-store/settings/save',
  toggleKoronaProductsActiveUrl : BASE_URL +'korona/web-store/file-import/toggle-active-products',
  additionalUserDetailForWebStoreUrl : BASE_URL+'core/web-store/settings/get-user-details'
};



export const getWebStoreOptionsByBusiness = (
  business_id: number | string
) => {
  let URL = `${API_URLS.webStoreOptionsByBusinessUrl}?business_id=${business_id}`;
  return composeRequest(URL, 'GET', null);
}


export const saveWebStoreSettings = (data:any)=>{
  const URL = API_URLS.saveWebStoreSettingsUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const toggleKoronaProductsActive = (data:any)=>{
  const URL = API_URLS.toggleKoronaProductsActiveUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const getAdditionalUserDetailsWebStore = (data:any)=>{
  const URL = API_URLS.additionalUserDetailForWebStoreUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}