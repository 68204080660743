import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import MaterialTable, { MTableToolbar, MTableActions } from 'material-table';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

import { Product } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';
import { Button, Paper, Typography, CircularProgress, Divider, SvgIcon, Input } from '@material-ui/core';

import { getProductsByPage, getProductsByBusiness, getOrderFormPdf } from '../../api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { isSuperAdmin } from '../../../shared/lib/PermissionManager';
import {
  initQueryParams,
  replaceHistory,
  tableOptions,
  useQuery,
} from '../../../shared/lib/TableQueryParams';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../components/Filters';

import { Filter, FilterType } from '../../../shared/models/Filter';
import download from 'downloadjs';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GlobalStyles from '../../../../styles/GlobalStyles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExcelFile, ExcelSheet } from "react-export-excel";


const FILTERS: Filter[] = [
  {
    id: 'f0',
    label: 'Commodity Group',
    types: [
      { id: '1', value: 'commodityGroup_id__in', label: 'is equal' },
      { id: '2', value: 'commodityGroup_id__not_in', label: 'is not equal' },
    ],
    selectedType: 'commodityGroup_id__in',
    value: [],
    url: 'korona/commodity-groups',
    labelField: 'name',
    bindValue: 'uuid',
    isResponsePaginated: false,
  },
  {
    id: 'f1',
    label: 'Code / PLU',
    types: [
      { id: '1', value: 'product_article_codes__code__istartswith', label: 'begins with' },
      { id: '2', value: 'product_article_codes__code__icontains', label: 'contains' },
      { id: '3', value: 'product_article_codes__code', label: 'is equal' },
    ],
    selectedType: 'product_article_codes__code__istartswith',
    value: '',
    url: null,
  },
  {
    id: 'f2',
    label: 'Number',
    types: [
      { id: '1', value: 'number__istartswith', label: 'begins with' },
      { id: '2', value: 'number__icontains', label: 'contains' },
      { id: '3', value: 'number', label: 'is equal' },
    ],
    selectedType: 'number',
    value: '',
    url: null,
  },
  {
    id: 'f3',
    label: 'Supplier',
    types: [
      { id: '1', value: 'suppliers__uuid__in', label: 'is equal' },
      { id: '2', value: 'suppliers__uuid__not_in', label: 'is not equal' },
    ],
    selectedType: 'suppliers__uuid__in',
    value: [],
    url: 'korona/supplier-list',
    labelField: 'name',
    bindValue: 'uuid',
    isResponsePaginated: true,
  },
  {
    id: 'f4',
    label: 'Tag',
    types: [
      { id: '1', value: 'tags__uuid__in', label: 'is equal' },
      { id: '2', value: 'tags__uuid__not_in', label: 'is not equal' },
    ],
    selectedType: 'tags__uuid__in',
    value: [],
    url: 'korona/tags',
    labelField: 'name',
    bindValue: 'uuid',
    isResponsePaginated: true,
  },
];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
  })
);

const ProductListScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let history = useHistory();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [isExporting, setExporting] = useState(false);
  const stockSummaryError = 'Please select a location to view stock summary.';
  const [exportButtonClick, setExportButtonClick] = useState(false)
  const [dialogDownloadOpen, setDialogDownloadOpen] = useState(false)
  const [selectedDownloadOption, setSelectedDownloadOption] = useState('')
  const [selectedPdfOption, setSelectedPdfOption] = useState(false)
  const [selectedXlsOption, setSelectedXlsOption] = useState(false)
  const [selectedTsvOption, setSelectedTsvOption] = useState(false)
  const [orderFormData, setOrderFormData] = useState({})
  const [businessProducts, setBusinessProducts] = useState([])
  const [downloadPdf, setDownloadPdf] = useState(false)
  const [dialogLoading, setDialogLoading] = useState(false)
  const [downloadXlsx, setDownloadXlsx] = useState(false)
  const [orderFormXlsxData, setOrderFormXlsxData] = useState([])

  const sb = props.userData.selectedBusiness;
  let d = new Date().toISOString().slice(0, 10)

  useEffect(() => {
    if (Object.keys(orderFormData).length > 0) {
      setDownloadPdf(true)
    }
    else {
      setDownloadPdf(false)
    }

  }, [orderFormData])


  useEffect(() => {
    if (orderFormXlsxData.length > 0) {
      setDownloadXlsx(true)
    }
    else {
      setDownloadXlsx(false)
    }

  }, [orderFormXlsxData])


  // useEffect(()=>{

  //   getProductsByBusiness(sb.business_id)
  //     .catch(handleError) // handle network issues
  //     .then(checkStatus)
  //     .then(parseJSON)
  //     .then((data: any) => {
  //       console.log("RESULTS",data)

  //       setBusinessProducts(data.results);
  //   })
  //   .catch((error: any) => {
  //   });
  // },[props.userData.selectedBusiness])


  useEffect(() => {
    console.log("PROPS", props.userData)
  }, [props.userData])


  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onChangePage(null, 0);
      setExportButtonClick(false)
      setSelectedDownloadOption('')
      setSelectedPdfOption(false)
      setSelectedXlsOption(false)
      setOrderFormData({})
      setBusinessProducts([])
      setDownloadPdf(false)
      setDialogLoading(false)
      setDownloadXlsx(false)
      setOrderFormXlsxData([])
    }

  }, [props.userData.selectedBusiness, filterString]);

  useEffect(() => {
    const { selectedLocations } = props.userData;
    if (selectedLocations && Object.keys(selectedLocations).length === 0) {
      setErrorStatusVisible(true);
    } else {
      setErrorStatusVisible(false);
    }
  }, [props.userData.selectedLocations]);

  useEffect(() => {
    const activeFilters = selectedFilters.filter(
      (f: Filter) => f.selectedType && f.value && f.value.length > 0
    );
    // console.log('activeFilters', activeFilters);
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      } else {
        // value is string
        filterValue = f.value.toString();
      }
      filterString += `&${f.selectedType}=${filterValue}`;
    });
    setFilterString(filterString);
  }, [selectedFilters]);


  const exportCSV = () => {
    setExporting(true);
    if (props.userData && props.userData.selectedBusiness) {
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getProductsByPage(
        null,
        sb.orderBy,
        null,
        tableQuery.search,
        sb.business_id,
        null,
        filterString,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'Products.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          console.log(error);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status && error.status === 400) {
              const { data } = error;
              const defaulMsg = 'Error Could not export data!';
              const msg = data ? (data.detail ? data.detail : defaulMsg) : defaulMsg;
              props.showToast({ message: msg, options: ToastError });
            }
          }
        });
    }
  };

  const getErrorMsg = () => {
    const { selectedLocations } = props.userData;
    if (selectedLocations && Object.keys(selectedLocations).length === 0) {
      return stockSummaryError;
    }
    return null;
  };

  const navigateToProductDetailScreen = (rowData: any) => {
    const productDetailUrl = '/web/products/' + rowData.uuid;
    props.history.push(productDetailUrl, rowData);
  };

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const renderStocksForSelectedLocations = (stocks, props) => {
    if (stocks.length === 0) {
      return '---';
    }
    const { selectedLocations } = props.userData;
    if (selectedLocations) {
      if (Object.keys(selectedLocations).length > 0) {
        const locations = Object.values(selectedLocations);
        const locationIds = locations.map((i: Location) => i.korona_org_unit);
        let sum = 0;
        stocks.forEach((s) => {
          if (locationIds.includes(s.warehouse)) {
            sum += s.goods;
          }
        });
        if (locations.length === 1) {
          return sum;
        }
        return `${sum} (${locations.length})`;
      }
      // return 'Select locations to view summary';
    }
    return '';
  };

  const addFilter = (filter) => {
    const filters = [...selectedFilters];
    filters.push(filter);
    setSelectedFilters(filters);
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    setSelectedFilters(filters);
  };


  const getProducts = (type: string) => {
    // setDialogLoading(true)
    setExporting(true)
    getProductsByBusiness(sb.business_id)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        console.log("RESULTS", data)
        if (type === 'pdf') {
          handlePdfPayload(data.results);
        }
        // if(type ==='xlsx'){
        //   handleXlsxPayload(data.results)
        // }
        // // setDialogLoading(false)
      })
      .catch((error: any) => {
        // setDialogLoading(false)
      });


  }
  const handlePdfPayload = (businessProducts: any) => {

    if (businessProducts.length > 0) {
      let data = []
      let reOrderCode = []
      let description = []
      let caseQty = []

      reOrderCode = businessProducts.map((b) => b.article_codes.length > 0 ? b.article_codes[0]['code'] : '')
      description = businessProducts.map((b) => b.name)
      caseQty = businessProducts.map((b) => b.article_codes.length > 0 ? b.article_codes[0]['quantity'] : '')

      if ((reOrderCode || description || caseQty).length > 0) {
        data.push(reOrderCode, description, caseQty)

        console.log("PAYYYYLOAD", data)
        let arr = []

        for (let i = 0; i < businessProducts.length; i++) {
          let obj = {}
          obj['s_no'] = i + 1
          obj['reorder_code'] = data[0][i]
          obj['description'] = data[1][i]
          obj['case_quantity'] = data[2][i]
          obj['order_quantity'] = ''
          arr.push(obj)
        }
        console.log("ARRAY", arr)

        // const payload ={items: arr}

        console.log("DATAAA", arr)
        let d = new Date().toISOString().slice(0, 10)
        // setOrderFormData(arr)

        exportPdf(sb.business_name, arr)

        // let outputXlsxData = [];

        // for (let i = 0; i < arr.length; i++) {
        //   let input = arr[i];

        //   outputXlsxData.push([input.reorder_code, input.description, input.case_quantity, input.order_quantity]);
        // }

        // console.log("XLSX",outputXlsxData)

      }

    }
  }


  const handleXlsxPayload = (businessProducts: any) => {

    if (businessProducts.length > 0) {
      let data = []
      let reOrderCode = []
      let description = []
      let caseQty = []

      reOrderCode = businessProducts.map((b) => b.article_codes.length > 0 ? b.article_codes[0]['code'] : '')
      description = businessProducts.map((b) => b.name)
      caseQty = businessProducts.map((b) => b.article_codes.length > 0 ? b.article_codes[0]['quantity'] : '')

      if ((reOrderCode || description || caseQty).length > 0) {
        data.push(reOrderCode, description, caseQty)

        console.log("PAYYYYLOAD", data)
        let arr = []

        for (let i = 0; i < businessProducts.length; i++) {
          let obj = {}
          obj['s_no'] = i++
          obj['reorder_code'] = data[0][i]
          obj['description'] = data[1][i]
          obj['case_quantity'] = data[2][i]
          obj['order_quantity'] = ''
          arr.push(obj)
        }
        console.log("ARRAY", arr)

        let outputXlsxData = [];

        for (let i = 0; i < arr.length; i++) {
          let input = arr[i];

          outputXlsxData.push([input.reorder_code, input.description, input.case_quantity, input.order_quantity]);
        }

        exportXlsx(outputXlsxData)

      }

    }
  }


  const exportXlsx = (dataSet: any) => {

    let XlsxData = dataSet

    const multiDataSet = [
      {
        columns: ['Reorder Code', 'Description', 'Case Qty', 'Order Qty'],

        data: XlsxData

      }
    ]

    console.log("EXCEL DATA", multiDataSet)

    setOrderFormXlsxData(multiDataSet)



  }

  const handleDownloadOptionChange = (event: any) => {
    if (event.target.value === "pdf") {
      setSelectedDownloadOption(event.target.value)
      setSelectedPdfOption(true)


    }
    else if (event.target.value === "xls") {
      setSelectedDownloadOption(event.target.value)
      setSelectedXlsOption(true)
      // getProducts('xlsx')
      // exportXlsx()
    }
    else if (event.target.value === "tsv") {
      setSelectedDownloadOption(event.target.value)
      setSelectedTsvOption(true)
    }
  }

  const handlePdfDialogClose = () => {
    setSelectedPdfOption(false)
    handleDownloadDialogCancel()
  }

  const handleDownloadDialogGo = () => {
    if (selectedDownloadOption === 'pdf') {
      getProducts('pdf')
    }
    if (selectedDownloadOption === 'xlsx') {
      getProducts('xlsx')

    }
  }

  const exportPdf = (title: any, orderForm: any) => {
    // , 'rows_per_page': 30
    let payload = { 'data': { 'title': title, 'dataset': orderForm , 'rows_per_page': 30} }
    let d = new Date().toISOString().slice(0,10)
    let updatedTitle= title.split(' ').join('_')
    getOrderFormPdf(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then((res) => res.blob())
      .then((data) => {
        download(data, `${updatedTitle}_${d}.pdf`, 'application/pdf');
        setExporting(false);
        setLoading(false)
      })
      .catch((error: any) => {
        setExporting(false);
        setLoading(false)
        console.log(error);
        if (error) {
          if (error.status && error.status === 500) {
            setEmptyDataSourceMessage(ERROR_MSG);
            setErrorStatusVisible(false);
          } else if (error.status && error.status === 400) {
            const { data } = error;
            const defaulMsg = 'Error Could not export data!';
            const msg = data ? (data.detail ? data.detail : defaulMsg) : defaulMsg;
            props.showToast({ message: msg, options: ToastError });
          }
        }
      });

  }



  const renderDialog = () => {
    return (
      <div>

        {
          exportButtonClick &&
          <Dialog
            open={exportButtonClick}
            onClose={handleDownloadDialogCancel}
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>{`Select any one of the format to download`}</DialogTitle>
            <DialogContent style={{ alignSelf: 'center' }} >
              <div >
                <FormControl
                  style={{
                    marginRight: 16,
                  }}>

                  <Select
                    id="select-download-option"
                    value={selectedDownloadOption}
                    onChange={handleDownloadOptionChange}
                    className={classes.select}

                  >
                    <MenuItem value={"pdf"}>PDF</MenuItem>
                    {/* <MenuItem value={"xls"}>EXCEL</MenuItem>
                      <MenuItem value={"tsv"}>TSV</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              {
                dialogLoading &&
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              }

            </DialogContent>
            <DialogActions>
              <IconButton>
                <Button onClick={handleDownloadDialogGo} color="primary" autoFocus>
                  Go
                </Button>
              </IconButton>
              <Button onClick={handleDownloadDialogCancel} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>

          </Dialog>
        }

        {/* {
            downloadPdf &&
            <>
              <Dialog
                maxWidth={'lg'}
                open={selectedPdfOption}
                onClose={handlePdfDialogClose}
              >
                <DialogContent style={{alignSelf:'center'}} >
                <div>
                  <PDFViewer width="1000" height="600" className="app" >
                    <OrderFormScreen orderForm={orderFormData} title={sb.business_name} />
                  </PDFViewer>
                </div>
                </DialogContent>
              </Dialog>
            </>
          } */}

      </div>
    )
  };



  const handleDownloadDialogCancel = () => {
    setSelectedDownloadOption('')
    setExportButtonClick(false)
  }

  return (
    <div>
      <ErrorStatusBar
        isVisible={isErrorStatusVisible}
        message={getErrorMsg()}
        displayErrorCode={false}
      />
      <ExportStatusBar isVisible={isExporting} type={'order-form'} />
      <div className="table-wrapper">
        <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: true
          }}
          components={{
            Toolbar: (props) => (
              <>
                <MTableToolbar {...props} />
                {filterVisible && (
                  <FilterRow
                    {...props}
                    renderAvailableFilters={
                      <AvailableFilters
                        filters={FILTERS}
                        addFilter={addFilter}
                      />
                    }
                    renderSelectedFilters={
                      <SelectedFilters
                        selectedFilters={selectedFilters}
                        removeFilter={removeFilter}
                        updateFilter={updateFilter}
                        businessId={sb ? sb.business_id : null}
                      />
                    }
                  />
                )}
              </>
            ),
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage,
            },
          }}
          onRowClick={(event, rowData) => navigateToProductDetailScreen(rowData)}
          columns={[
            { title: 'Number', field: 'number' },
            { title: 'Product', field: 'name' },
            {
              title: 'Commodity Group',
              field: 'commodityGroup',
              render: (rowData) => {
                if (
                  rowData &&
                  rowData.commodityGroup &&
                  rowData.commodityGroup.name
                ) {
                  return <div>{rowData.commodityGroup.name}</div>;
                }
                return <div />;
              },
            },
            {
              title: 'Stocks Summary',
              field: 'product_stocks',
              render: (rowData) => {
                if (rowData && rowData.product_stocks) {
                  return (
                    <div>
                      {renderStocksForSelectedLocations(
                        rowData.product_stocks,
                        props
                      )}
                    </div>
                  );
                }
                return <div>---</div>;
              },
            }
          ]}
          data={(query) => {
            return new Promise((resolve, reject) => {
              if (!isLoading) {
                const sb = props.userData.selectedBusiness;
                if (sb && sb.business_id) {
                  replaceHistory(query, props.history);
                  let pageData = {
                    data: [],
                    totalCount: 0,
                    page: query.page,
                  };
                  setLoading(true);
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    if (orderBy === 'commodityGroup') {
                      orderBy = 'commodityGroup__name'
                    }
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }
                  getProductsByPage(
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    sb.business_id,
                    orderBy,
                    null,
                    filterString
                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<Product>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page,
                      };

                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize,
                      });
                      setLoading(false);

                      resolve(pageData);

                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if (
                          error.status === 403 ||
                          error.status === 401
                        ) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize,
                      });
                      setLoading(false);
                      resolve(pageData);
                    });
                }
              }
            });
          }}
          title={
            <CustomTableTitle
              history={props.history}
              title={'Products'}
              icon={SVG_ICONS.product}
              showHelp={true}
              helpText={
                <div>
                  <Typography>{`List of products with stock info.`}</Typography>
                  <Typography>{`
								Stock Summary Column:
								`}</Typography>
                  <Typography>{`
								E.g. 30(2) - Total stock 30 across 2 locations.
								`}</Typography>
                </div>
              }
            />
          }
          actions={[
            {
              icon: 'save_alt',
              tooltip: 'Export',
              isFreeAction: true,
              onClick: () => getProducts('pdf'),
              disabled: isExporting,
              hidden: !isSuperAdmin(props),

            },
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
            {
              icon: 'filter_list',
              tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
              isFreeAction: true,
              iconProps: {
                color: filterVisible ? 'primary' : 'inherit',
              },
              // hidden: !isSuperAdmin(props),
              onClick: toggleFilterPanel,
            },
          ]}
        />
      </div>
      {/* // {renderDialog()} */}
      {/* {
        downloadPdf &&
        <OrderFormScreen orderForm={orderFormData} title={sb.business_name} />
        // <PDFDownloadLink document={<OrderFormScreen orderForm={orderFormData} title={sb.business_name} />} fileName={`OrderForm_${d}`}>
        //   {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
        // </PDFDownloadLink>
      } */}
      {downloadXlsx &&
        <ExcelFile>
          <ExcelSheet dataSet={orderFormXlsxData} name={`OrderForm_${d}`} />
        </ExcelFile>
      }
    </div>
  );
};



const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListScreen);
