import { combineReducers, Action } from 'redux';
import { masterProductListReducer } from './masterProductReducer';
import { masterProductWithoutIdentifierListReducer } from './masterProductWithoutIdentiferReducer';
import { masterSupplierListReducer } from './masterSupplierReducer';
import { customerOrderListReducer } from './customerOrderReducer';
import { stockOrderMobileReducer } from './stockOrderMobileReducer';

export const masterModuleReducer = combineReducers({
    masterProductList: masterProductListReducer,
    masterSupplierList: masterSupplierListReducer,
    masterProductWithoutIdentifierList: masterProductWithoutIdentifierListReducer
});

export const customerOrderModuleReducer = combineReducers({
    customerOrderList : customerOrderListReducer
})

export const stockOrderModuleMobileReducer = combineReducers({
    stockOrderDetails : stockOrderMobileReducer
})
