import {
  SET_STOCK_RECEIPT_ITEMS, UPDATE_STOCK_RECEIPT_ITEMS, 
  DELETE_STOCK_RECEIPT_PRODUCT_IN_LIST, CLEAR_STOCK_RECEIPTS_ITEMS, SET_SR_VENDOR_DATA,
  ADD_NEW_SR_ITEM
} from '../actions/actionTypes';
import cloneDeep from 'lodash/cloneDeep';


const initialState = {
  sr_list: null as any,
};

export function srListReducer(state = initialState, action: any) {
  switch (action.type) {

    case SET_STOCK_RECEIPT_ITEMS:{
      console.log("action---data", action.data)
      return{
        ...state,
        sr_list:action.data
      }
    }

    case UPDATE_STOCK_RECEIPT_ITEMS:
      console.log("state.sr_list",state.sr_list)
      console.log("state.sr_list.srItemsList", state.sr_list.srItemsList)

      let itemList = state.sr_list.srItemsList.slice();
      const newData = action.data;
      const objIndex = itemList.findIndex((i => i.itemIndex == newData.itemIndex));
      itemList[objIndex] = newData;
      return {
        ...state,
        sr_list : {...state.sr_list,srItemsList:itemList}
      };
    
    case  ADD_NEW_SR_ITEM:
      let existing_items = cloneDeep(state.sr_list.srItemsList)
      let newObj = action.data;
      let updatedData =  [...existing_items, newObj]

      return{
        ...state,
        sr_list : {...state.sr_list,srItemsList:updatedData}
      };

    case DELETE_STOCK_RECEIPT_PRODUCT_IN_LIST:
      let list_items = state.sr_list.srItemsList.slice()
      
      const incomingObj = action.data;
      let resultant =  list_items.filter((i => i.itemIndex != incomingObj.itemIndex));
      
      return{
        ...state,
        sr_list : {...state.sr_list,srItemsList:resultant}
      };

    case SET_SR_VENDOR_DATA:{

      return{
        ...state,
        vendorData:action.data
      }
    }
    
    case CLEAR_STOCK_RECEIPTS_ITEMS: return cloneDeep(initialState);
    default:
      return state;
  }
}
