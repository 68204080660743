import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MaterialTable from 'material-table';
import { Typography, Snackbar, SvgIcon } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { StockReceipt } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';

import { getStockReceiptsByPage } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { ErrorStatusBar, CustomTableTitle } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

const StockReceiptListScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);

    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    options={{
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true 
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={[
                        {
                            title: 'Date',
                            field: 'createTime',
                            render: rowData => (
                                rowData.createTime ?
                                    <span>{moment(rowData.createTime).format('YYYY-MM-DD HH:mm')}</span>
                                    : <span></span>
                            ),
                        },
                        { title: 'Number', field: 'number' },
                        {
                            title: 'Items',
                            field: 'items_count',
                            render: rowData => {
                                if (rowData && rowData.items_count) {
                                    return <span>{rowData.items_count}</span>;
                                }
                                return <span></span>;
                            },
                        },
                        {
                            title: 'Warehouse',
                            field: 'target',
                            render: rowData => {
                                if (rowData && rowData.target && rowData.target.name) {
                                    return <span>{rowData.target.name}</span>;
                                }
                                return <span></span>;
                            }
                        },
                        {
                            title: 'Supplier',
                            field: 'supplier',
                            render: rowData => {
                                if (rowData && rowData.supplier && rowData.supplier.name) {
                                    return <span>{rowData.supplier.name}</span>;
                                }
                                return <span></span>;
                            },
                        },
                        {
                            title: 'Description',
                            field: 'description',
                            // render: rowData => {
                            //     if (rowData && rowData.sector && rowData.sector.name) {
                            //         return <span>{rowData.sector.name}</span>
                            //     }
                            //     return <span></span>
                            // },
                        },
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            if (!isLoading) {
                                const sb = props.userData.selectedBusiness;

                                if (sb && sb.business_id) {
                                    replaceHistory(query, props.history);
                                    let pageData = {
                                        data: [] as StockReceipt[],
                                        totalCount: 0,
                                        page: query.page
                                    };
                                    let orderBy = '';
                                    if (query.orderBy) {
                                        orderBy = query.orderBy.field as string;
                                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                        console.log("DIRECTION",orderBy)
                                    }
                                    setLoading(true);
                                    
                                    getStockReceiptsByPage(
                                        sb.business_id,
                                        query.page + 1,
                                        query.pageSize,
                                        query.search,
                                        orderBy
                                        )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<StockReceipt>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if ((error.status === 403 || error.status === 401)) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Stock Receipts'}
                            icon={SVG_ICONS.stockReceipt} />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(StockReceiptListScreen);