import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Typography, Checkbox, CircularProgress, Button, SvgIcon, FormControl, Select, MenuItem, Menu, 
  Tooltip, IconButton,TableContainer, Table, TableRow, TableBody, TableCell, TableHead
} from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Paper, Divider, Link } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError, ToastWarning } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import Papa from 'papaparse';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { initQueryParams, replaceHistory, tableOptions, useQuery, } from '../../../shared/lib/TableQueryParams';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import map from 'lodash/map';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import ClearIcon from '@material-ui/icons/Clear';
import download from 'downloadjs';
import { getAndSaveCCRSReport, getCCRSReport, uploadCCRSReport } from '../../api';



const reportsList = ['Sale',]

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      display: 'inline-block',
      tabSize: 4
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: 16,

    },
    summaryPaper: {
      padding: theme.spacing(1),
      // marginBottom: 16,
    },
    content: {
      display: 'flex',
      alignContent: 'center',
      width: '100%',
      backgroundColor: '#fff',
      paddingLeft: 24,
      paddingRight: 24
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    textField: {
      display: 'flex',
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      width: '100%',
      // paddingTop: 24,
      padding: theme.spacing(30),
    },
    card: {
      padding: theme.spacing(30),
    },
    statusText: {
      fontSize: 16,
      textAlign: 'left',
      marginLeft: 58,
      padding: "6px 0",
      display: 'flex',
      alignItems: 'center'
    },
    helpTooltipRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 8,
    },
    helpTooltipText: {
      padding: '0 8px 0 0',
    },
    requiredField: {
      color: GlobalStyles.red,
      fontSize: 18,

    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    table: {
      minWidth: 650,
    },
  }),
);

const CCRSHomeScreen = (props: any) => {
  const classes = useStyles({});
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);

  const [uiLoading, setUILoading] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [parsedData, setParsedData] = useState([]);
  const [fileData, setFileData] = useState<any>();
  const [disableImport, setDisableImport] = useState(true);
  const [importedSuccessfully, setImportedSuccessfully] = useState(false);
  const [confirmDetails, setConfirmDetails] = useState(false);

  const [selectedReport, setSelectedReport] = useState('')
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = useState(null as any);
  const [isExporting, setExporting] = useState(false);
  const [tableData, setTableData] = useState(null as any);
  const [tableHeaders, setTableHeaders] = useState(null as any);
  const [errorDetails, setErrorDetails] = useState<any>(null);


  let d = new Date()
  let todayDate = d.toLocaleDateString('en-GB').split("/").reverse().join("")
  let currentTime = d.toLocaleTimeString('en-GB').split(":").join('')
  let todayDateTime = `${todayDate}${currentTime}`


  useEffect(() => {
    // if (selectedFile) {
    //   const fileExtension = selectedFile.name.split('.').pop();
    //   const validExtensions = ['txt', 'csv'];
    //   if (validExtensions.includes(fileExtension)) {
    //     // console.log("test");
    //     parseContent();
    //   }
    // }
  }, [selectedFile]);


  useEffect(()=>{
    console.log("Props", props)
  },[props])


  const handleFile = (e: any) => {
    const content = e.target.result;
    setFileData(content);
    console.log("parsed", content.split("\n"), content)
    let converted = content.split("\n")
    let updatedData = regexToMatch(converted)
    setParsedData(updatedData)
    // console.log("Looooooooooooooooooooooooo")
    console.log(updatedData)
  }


  const regexToMatch = (data) => {
    const regex = /\r?\n|\r/g
    let updatedData = []
    for (let i of data) {
      let item = []
      let c = i.replace(regex, "");
      console.log("c ", c)
      c != '' ? updatedData.push(c.split(',')) : null
    }
    console.log("CONVERTEDDDD", updatedData)
    setTableHeaders(cloneDeep(updatedData[3]))
    return updatedData;
  }


  const parseContent = () => {
    if (selectedFile) {

      let fileData = new FileReader();
      fileData.onloadend = handleFile;
      fileData.readAsText(selectedFile);
    }
  };


  const handleResetClick = () => {
    setSelectedFile(null);
    setParsedData([]);
    setDisableImport(true);
    setImportedSuccessfully(false);
    setUILoading(false);
    setConfirmDetails(false)
  };


  const onFileSelectChangeHandler = (e: any) => {
    if (e.target) {
      if (e.target.files && e.target.files[0]) {
        setSelectedFile(e.target.files[0]);
        setDisableImport(true);
        // e.target.value = '';
        handleUploadtoFTP(e.target.files[0]).then((res) => {
          console.log(res);
        })
      }
    }
  };

  const handleUploadClick = () => {
    const el = document.getElementById('import-file');
    el && el.click();
  };


  const handleConfirmDetailsCheckBox = (event: any) => {
    setConfirmDetails(event.target.checked)
    setDisableImport(!event.target.checked)
  }

  const getSummary = () => {
    let dataLength = parsedData.length
    let businessName = props.userLocalData.selectedBusiness.business_name
    return (
      <Paper className={classes.paper} style={{ width: '100%' }}>
        <div style={{ padding: 2 }}>
          <Typography style={{ fontSize: 16, }} >
            No. of rows read : {<span style={{ color: GlobalStyles.primaryColor }}>{dataLength}</span>}
          </Typography>
          <Typography style={{ fontSize: 16, }} >
            Currently selected business : {<span style={{ color: GlobalStyles.primaryColor }}>{businessName}</span>}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography style={{ fontSize: 16, }} >
              Note: The product(s) with these codes will be marked as active and all other product(s) will be marked as inactive.
            </Typography>
            <Checkbox
              checked={confirmDetails}
              onChange={handleConfirmDetailsCheckBox}
              color="primary"
              disabled={importedSuccessfully}
            />
          </div>
        </div>
      </Paper>
    );
  };


  const handleMouseDownImport = () => {
    props.showToast({
      message: 'Please confirm the actions to be performed by checking the box.',
      options: ToastWarning
    });
  }

  // const handleImport = () => {
  //   if (disableImport) {
  //     handleMouseDownImport()
  //   } else {
  //     setDisableImport(true);
  //     // // console.log(props.userLocalData)
  //     setUILoading(true);
  //     const { business_id } = props.userLocalData.selectedBusiness;
  //     const records = uniqBy(parsedData, o => o.order_num.trim().toLowerCase())
  //     const payload = { records: records, business_id:business_id };
  //     console.log("payload", payload);
  //     toggleKoronaProductsActive(payload)
  //       // handle network issues
  //       .then(checkStatus)
  //       .then(parseJSON)
  //       .then(jsonData => {
  //         setUILoading(false);
  //         setDisableImport(false);
  //         setImportedSuccessfully(true);
  //         setConfirmDetails(false)
  //         console.log("jsonData", jsonData)
  //       })
  //       .catch((error: any) => {
  //         console.log("ERROR", error)
  //         if (error) {
  //           setImportedSuccessfully(false);
  //           setUILoading(false);
  //           setDisableImport(false);
  //           props.showToast({
  //             message: 'Failed to update products!',
  //             options: ToastError
  //           });
  //         }
  //       });
  //   }
  // };

  const handleSelectReportChange = (event: any) => {
    setSelectedReport(event.target.value);
    setParsedData([])
    
  }


  const getFileBytesFromBase64 = (base64String: string) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray
  }


  const handleGenerateCSV = () => {
    console.log("props", props)
    if (props.userLocalData && props.userLocalData.selectedBusiness) {
      let selectedLocs = props.userLocalData.selectedLocations;
      setErrorDetails(null);
      setUILoading(true);
      const sb = props.userLocalData.selectedBusiness;
      let authToken = props.userLocalData.userData.userData.auth_token
      const shouldExport = true;
      let payload = { 
        business_id : sb.business_id,
        selected_report : selectedReport,
        selected_loc_ids : selectedLocs.map(l => l.id)
      }
      getCCRSReport(payload)
        .catch(handleError) // handle network issues
        // .then(checkStatus)
        // .then(async (res) => {
        //   console.log(res);
        //   const header = res.headers.get('Content-Disposition');
        //   const parts = header!.split(';');
        //   const filename = parts[1].split('=')[1];
        //   const data = await res.blob();
        //   return {filename, data: data};
        // })
        .then(async (res) => {
          console.log(res);
          
          return await res.json();
        })
        // .then(parseJSON)

        .then((data) => {
          let file_data = data.file_data;
          if (data.file_type == 'application/zip') {
            file_data = getFileBytesFromBase64(file_data);
          } 
          file_data = new Blob([file_data]);
          download(file_data, data.file_name);
          if (data.status == 'error') {
            const d = data.file_details
            setErrorDetails(d);
          }
          setUILoading(false);
        })
        .catch((error: any) => {
          setUILoading(false);
          if (error) {
            const msg = 'Oops! An Error Occured.';
            console.error(error);
            if (error.status === 400) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
              props.showToast({ message:error.error, options: ToastError,  });
            }
            else if (error.status === 404) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
              props.showToast({ message: 'vendor license not found', options: ToastError });
            }
            else {
              props.showToast({ message: msg, options: ToastError });
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
     
    }
  };
  
  const handleGenerateSaveCSV = () => {
    console.log("props", props)
    if (props.userLocalData && props.userLocalData.selectedBusiness) {
      let selectedLocs = props.userLocalData.selectedLocations
      setUILoading(true);
      const sb = props.userLocalData.selectedBusiness;
      let authToken = props.userLocalData.userData.userData.auth_token
      const shouldExport = true;
      let payload = { 
        business_id : sb.business_id,
        selected_report : selectedReport,
        selected_loc_ids : selectedLocs.map(l => l.id)
      }
      getAndSaveCCRSReport(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.json())
        // .then(parseJSON)
        .then((data) => {
          // download(data, `${selectedReport}_D3A82AEE7A_${todayDateTime}.csv`);
          setUILoading(false);
          props.showToast({ message:data.status });
        })
        .catch((error: any) => {
          setUILoading(false);
          if (error) {
            const msg = 'Oops! An Error Occured.';
            if (error.status === 400) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
              props.showToast({ message:error.error, options: ToastError });
            }
            else if (error.status === 404) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
              props.showToast({ message: 'vendor license not found', options: ToastError });
            }
            else {
              props.showToast({ message: msg, options: ToastError });
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
     
    }
  };


  const handleUploadtoFTP = async (selFile) => {    
    console.log("FTPP CLICKed")
    if (props.userLocalData && props.userLocalData.selectedBusiness) {
      const sb = props.userLocalData.selectedBusiness;
      let selectedLocs = props.userLocalData.selectedLocations
      
      let authToken = props.userLocalData.userData.userData.auth_token
      const shouldExport = true;
      let payload = { 
        business_id : sb.business_id,
        selected_report : selectedReport,
        selected_loc_ids : selectedLocs.map(l => l.id)
      }
      let formData = new FormData();
      for (let key in payload){
        formData.append(`${key}`, `${payload[key]}`)
      }
      formData.append("csv_file", selFile);
      setErrorDetails(null);
      setUILoading(true);
      await uploadCCRSReport(formData)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: any) => {
          if (data.status == 'error') {
            const d = data.file_details
            setErrorDetails(d);
          }
          setUILoading(false);
          props.showToast({
            message: 'Upload Completed',
            options: ToastSuccess
          });
        })
        .catch((error: any) => {
          console.log("Errorrrr~~~~~~~~~~~~", error)
          setUILoading(false);
          if (error) {
            let toastMsg= ''
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
          props.showToast({
            message: 'Failed to Upload',
            options: ToastError
          });
        });
    }
  }

  const renderUploadedContent = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {parsedData.map((row, pdIndex) => {
              return <TableRow>
                {
                  row.map((r, rIndex) => {

                    if (pdIndex === 3) {
                      return (
                        <TableCell style={{ fontWeight: 'bold' }}>{r}</TableCell>
                      )
                    } else {
                      return <TableCell>{r}</TableCell>
                    }
                  })
                }
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const navigateToCSVFile = (e, row) => {
    if (row) {
      window.open(`${row.core_file.source}`, "_blank");
    }
  }

  const renderErrorDetails = () => {
    return (
      <>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              {errorDetails.map((row, pdIndex) => {
                return <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>{row.file_name}</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>
                    <Link variant="body1"
                        align="left"
                        component="button"
                        onClick={(e) => navigateToCSVFile(e, row)} >
                        {`View`}
                      </Link>
                  </TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: row.status.indexOf('ERROR') >= 0 ? 'red' : "green" }}>{row.status}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }


  return (
    <div style={{ maxWidth: '100%' }}>
      {/* <Divider className={classes.divider} /> */}
      {<Paper className={classes.paper} >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', padding: 2 }}>

          <div style={{ alignSelf: 'flex-start', display: 'flex', alignItems: 'center' }}>
            <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
              Choose a report:
            </Typography>
            <FormControl
              style={{}}>
              <Select
                labelId="select-label"
                id="select-task"
                value={selectedReport}
                onChange={handleSelectReportChange}
                className={classes.select}
              >
                {/* <MenuItem value=''>None</MenuItem> */}
                <MenuItem value={"sale"}>Sale</MenuItem>
                <MenuItem value={"product"}>Product</MenuItem>
                <MenuItem value={"inventory"}>Inventory</MenuItem>
                <MenuItem value={"inventory_adjustment"} >Inventory Adjustment</MenuItem>
                <MenuItem value={"inventory_transfer"}>Inventory Transfer</MenuItem>
                <MenuItem value={"area"}>Area</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Clear task" placement="bottom">
              <IconButton onClick={() => setSelectedReport('')} style={{ marginRight: 16 }}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ alignSelf: 'center' }}>
            {
              uiLoading &&
              <CircularProgress style={{
                width: '32px',
                height: '32px'
              }} />
            }
          </div>

          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
            {
              <Button
                style={selectedReport === '' ? { opacity: 0.4, marginLeft: 16, } : { marginLeft: 16, }}
                // style={ selec { marginLeft: 16, backgroundColor: '#4caf50'}}
                disabled={selectedReport === '' ? true : false}
                variant="contained"
                onClick={handleGenerateCSV}
                startIcon={<SvgIcon><path d={SVG_ICONS.download} /></SvgIcon>}>
                Generate & Download
              </Button>
            }
            <input type="file" name="file"
              onChange={onFileSelectChangeHandler}
              id="import-file"
              style={{ display: 'none' }}
              accept=".txt,.csv" />
              
            {
              <Button
                style={selectedReport === '' ? { opacity: 0.4, marginLeft: 16, } : { marginLeft: 16, }}
                // style={ selec { marginLeft: 16, backgroundColor: '#4caf50'}}
                disabled={selectedReport === '' ? true : false}
                variant="contained"
                onClick={handleUploadClick}
                startIcon={<SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>}>
                Upload & Submit to CCRS
              </Button>
              
            }
            {/* {
              <Button
                style={parsedData && parsedData.length === 0 ? { opacity: 0.4, marginLeft: 16, } : { marginLeft: 16, }}
                // style={ selec { marginLeft: 16, backgroundColor: '#4caf50'}}
                disabled={parsedData && parsedData.length === 0 ? true : false}
                variant="contained"
                onClick={handleUploadtoFTP}
                startIcon={<SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>}>
                {`Save to FTP`}
              </Button>
            } */}
            {
              <Button
                style={selectedReport === '' ? { opacity: 0.4, marginLeft: 16, } : { marginLeft: 16, }}
                // style={ selec { marginLeft: 16, backgroundColor: '#4caf50'}}
                disabled={selectedReport === '' ? true : false}
                variant="contained"
                onClick={handleGenerateSaveCSV}
                startIcon={<SvgIcon><path d={SVG_ICONS.download} /></SvgIcon>}>
                Generate & Submit to CCRS
              </Button>
            }
          </div>
        </div>
      </Paper>
      }
      {errorDetails && errorDetails.length > 0 && renderErrorDetails()}
      {parsedData && parsedData.length > 0 && renderUploadedContent()}
    </div>
  )

}


const mapStateToProps = (state: any) => {

  return {
    userLocalData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CCRSHomeScreen);