import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Card,
  CardContent, FormControlLabel, FormGroup, FormLabel, Radio,
  RadioGroup, TextField, SvgIcon,
  LinearProgress, CircularProgress, IconButton
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { createDeviceVersion, getCurrentVersion } from '../../api';
import { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import { enqueueSnackbar, Toast, ToastError, ToastSuccess } from '../../../shared/components/Notifier';
import {
  isSuperAdmin
} from '../../../shared/lib/PermissionManager';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { setPageLoading } from '../../../../store/actions';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '0',
      overlay: '.5%'
    },
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItem: 'baseline',
      borderBottom: "1px solid rgb(0, 0, 0, 0.70)",
      boxShadow: 'none',
      backgroundColor: '#fff',
      color: 'rgb(25, 118, 210)',
      padding: '.5rem',
      height: '100%',
      borderTopRightRadius: '.5rem',
      borderTopLeftRadius: '.5rem'
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    title: {
      fontWeight: 800,
      marginBottom: '.25rem'
    },
    cardContent: {
      boxShadow: 'none',
      borderBottomRightRadius: '.5rem',
      borderBottomLeftRadius: '.5rem',
      borderTopRightRadius: '0',
      borderTopLeftRadius: '0'
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    inputType1: {
      width: '100%',
      display: 'flex',
      gap: '1rem',
      marginTop: '.5rem'
    },
    inputType2: {
      width: '100%',
    },
    radio: {
      display: 'flex'
    },

    btn: {
      backgroundColor: "primary",
      marginLeft: "auto",
    },
    submitDiv: {
      marginTop: '1rem',
      width: '100%'
    },
    bottomDiv: {
      display: 'flex',
      marginLeft: 'auto',
      order: 2,
      gap: '.5rem'
    },
    formControl: {
      width: "100%",
    },
    formControl2: {
      width: "80%",
    },
    radiogroup: {
      marginLeft: '-0.6rem',
      width: '100%',
    },
    tooltip: {
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
      display: 'flex',
      marginLeft: 'auto',
      cursor: 'pointer'
    },
    tooltip1: {
      arrow: {
        color: theme.palette.common.black,
      },
      tooltip: {
        backgroundColor: theme.palette.common.black,
      },
      display: 'flex',
      marginLeft: 'auto',
      cursor: 'pointer',
      width: '4rem'
    },
    dnd: {
      marginLeft: 'auto'
    },
    uploadbtn: {
      display: 'flex',
      justifyContent: 'end'
    },
    hidden: {
      display: 'none'
    },
    SecondForm: {
      marginTop: '1rem'
    },
    progressBar: {
      marginTop: '1rem',
      marginBottom: '1rem'
    },
    rowCenter: {
      position: 'relative',
      left: '50%',
      top: '50%'
    },
    backIcon: {
      margin: '0',
      padding: '0.25rem',
    }

  }),
);

const DeviceUpdateScreen = (props: any) => {
  const { height, width } = useWindowDimensions();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const container = window !== undefined ? () => window().document.body : undefined;
  const [isMediaLoading, setMediaLoading] = useState(false);
  const [isVersionLoading, setVersionLoading] = useState(false);
  const [isSubmitDisable, setSubmitDisable] = useState(true);
  const [isNextDisable, setNextDisable] = useState(true);
  var [currentVersion, setCurrentVersion] = useState(null as any);
  const fileInputRef = useRef(null);
  const [input, setInput] = useState({
    version: "",
    device_type: "",
    device_platform: "",
    version_name: "",
    release_channel: "",
    release_notes: "",
    has_playstore: "",
    source: null as any
  });

  const [inputError, setInputError] = useState({
    version: null as any,
    device_type: null as any,
    device_platform: null as any,
    version_name: null as any,
    release_channel: null as any,
    release_notes: null as any,
    has_playstore: null as any,
    source: null as any
  });

  useEffect(() => {
    if (Object.values(inputError).includes(true)) {
      setSubmitDisable(true);
    }
    if (input.version &&
      input.version_name && input.source) {
      setSubmitDisable(false);
    }
  }, [inputError])

  useEffect(() => {
    if (!currentVersion && input.device_type &&
      input.release_channel && input.device_platform) {
      setNextDisable(false);
    }
    if (input.version &&
      input.version_name && input.source) {
      setSubmitDisable(false);
    }
  }, [input])


  const isNotValid = (name: any, value: any, is_required: any) => {
    var pattern = /^\w+$/;
    if (!is_required) {
      return false;
    }
    else if (name == "version") {
      pattern = /^\d+$/;
      if (pattern.test(value) && currentVersion.current_version && value <= currentVersion.current_version) {
        return value + " is lower than the current version: " + currentVersion.current_version;
      }
      else if (!pattern.test(value)) {
        return "Invalid format, Enter decimal more than " + currentVersion.current_version;
      }
    }
    else if (name == "version_name" && currentVersion.current_version_name && currentVersion.current_version_name.includes(value)) {
      return value + " is lower than the current version: " + currentVersion.current_version_name;

    }
    else if (name == "version_name" && !pattern.test(currentVersion.current_version_name)) {
      if (/^-?\d+(.\d+)*$/.test(currentVersion.current_version_name)) {
        if (value.localeCompare(currentVersion.current_version_name, undefined, { numeric: true, sensitivity: 'base' }) == -1) {
          return value + " is lower than the current version: " + currentVersion.current_version_name;
        }
        else if (value.split(".").length > 3) {
          return "More than 3 decimal points are not allowed in version number: " + value;
        }
      }
      pattern = /^\d(\.\d+)*$/;
    }

    return !pattern.test(value) ? "Invalid Format" : "";
  }

  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  const handleFileDrop = (event: any, files: any) => {
    event.preventDefault()
    if (files && files.length > 0) {
      input.source = files[0];
      setInput(input);
    }

    if (input.version &&
      input.version_name && input.source) {
      setSubmitDisable(false);
    }
  }
  const handleInputChange = (event: any) => {
    event.preventDefault();
    setInput({ ...input, [event.target.name]: event.target.value });
    inputError[event.target.name] = isNotValid(event.target.name, event.target.value, event.target.required);
    setInputError(inputError);
  }

  const handleSubmit = (event) => {
    ;
    setMediaLoading(true);
    if (!input.source) {
      props.showToast({
        message: "Please upload an apk file", options: ToastError
      })
    }
    else if (isSuperAdmin(props)) {
      const formData = new FormData();
      for (var key in input) {
        formData.append(key, input[key]);
      }
      createDeviceVersion(formData)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((parseJSON: any) => {
          props.showToast({
            message: "New version created successfully", options: ToastSuccess
          })
          setCurrentVersion(null);
          setNextDisable(false);
          setInput({
            version: "",
            device_type: "",
            device_platform: "",
            version_name: "",
            release_channel: "",
            release_notes: "",
            has_playstore: "",
            source: null as any
          })
          setMediaLoading(false);
        })
        .catch((error: any) => {
          setMediaLoading(false);
          props.showToast({
            message: "New version couldn't be created : " + error, options: ToastError
          })
        })
    }
    return null;
  }

  const handleNext = () => {
    setVersionLoading(true)
    let payload = {
      "device_type": input.device_type,
      "release_channel": input.release_channel,
      "device_platform": input.device_platform
    };
    // setTimeout(() =>
    getCurrentVersion(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((parseJSON: any) => {
        currentVersion = parseJSON;
        setCurrentVersion(currentVersion);
        inputError.version = isNotValid("version", input.version, true);
        inputError.version_name = isNotValid("version_name", input.version_name, true);
        setInputError(inputError);
        setVersionLoading(false);
      }).catch((error: any) => {
        props.showToast({
          message: "Couldn't fetch the current version: " + error, options: ToastError
        })
        setVersionLoading(false);

      })
    // , 1000)
  }

  const handlePrev = () => {
    // setNextDisable(true);
    setCurrentVersion(null);
  }

  const isDirectionRow = width > 400 ? true : false;
  return (
    <div className={classes.root} >
      {
        isMediaLoading &&
        <LinearProgress color="secondary" className={classes.progressBar} />
      }
      <CssBaseline />
      <AppBar position="relative" className={classes.appBar}>
        <Typography variant="h6" noWrap>
          Device Update
        </Typography>
      </AppBar>
      <div className={classes.content}>
        <Card className={classes.cardContent}>
          <CardContent>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Device Information
            </Typography>
            {
              isVersionLoading &&
              <div className={classes.rowCenter}>
                <CircularProgress />
              </div>
            }
            <div className={classes.inputType1} style={{
              flexDirection: width > 1051 ? "row" : "column",
              opacity: (isMediaLoading || isVersionLoading) ? "0.5" : "1"
            }}>
              <div className={classes.formControl2}>
                <FormLabel required htmlFor='device_type'>
                  Choose Device Type</FormLabel>
                <RadioGroup
                  className={classes.radiogroup}
                  row={isDirectionRow}
                  value={input.device_type}
                  onChange={handleInputChange}
                  id='device_type'
                  name='device_type'
                  aria-required
                >
                  <FormControlLabel disabled={(currentVersion || isVersionLoading) ? true : false}
                    value="handheld"
                    control={<Radio color='primary' />}
                    label="Handheld" />
                  <FormControlLabel disabled={(currentVersion || isVersionLoading) ? true : false}
                    value="kiosk"
                    control={<Radio color='primary' />}
                    label="Kiosk" />
                </RadioGroup>
              </div>
              <div className={classes.formControl}>
                <FormLabel required htmlFor='release_channel'>
                  Choose Release Channel</FormLabel>
                <RadioGroup
                  className={classes.radiogroup}
                  row={isDirectionRow}
                  value={input.release_channel}
                  onChange={handleInputChange}
                  id='release_channel'
                  name='release_channel'
                  aria-required
                >
                  <FormControlLabel disabled={(currentVersion || isVersionLoading) ? true : false}
                    value="alpha"
                    control={<Radio color='primary' />}
                    label="Alpha" />
                  <FormControlLabel disabled={(currentVersion || isVersionLoading) ? true : false}
                    value="beta"
                    control={<Radio color='primary' />}
                    label="Beta" />
                  <FormControlLabel disabled={(currentVersion || isVersionLoading) ? true : false}
                    value="stable"
                    control={<Radio color='primary' />}
                    label="Stable" />
                </RadioGroup>
              </div>
              <div className={classes.formControl2}>
                <FormLabel required htmlFor='device'>
                  Choose Device Platform</FormLabel>
                <RadioGroup
                  className={classes.radiogroup}
                  row={isDirectionRow}
                  value={input.device_platform}
                  onChange={handleInputChange}
                  id='device'
                  name='device_platform'
                  aria-required
                >
                  <FormControlLabel disabled={(currentVersion || isVersionLoading) ? true : false}
                    value="android"
                    control={<Radio color='primary' />}
                    label="Android" />
                  <FormControlLabel disabled={(currentVersion || isVersionLoading) ? true : false}
                    value="iOS"
                    control={<Radio color='primary' />}
                    label="IOS" />
                </RadioGroup>
              </div>
            </div>
            {!currentVersion &&

              <Tooltip arrow disableTouchListener={!isNextDisable}
                className={classes.tooltip1} title={isNextDisable ?
                  "Please enter valid responses for all required fields" : ""} placement="left" >
                <span>
                  <Button disabled={isNextDisable} size="small" type="submit"
                    variant="contained" color="primary"
                    className={classes.btn}
                    onClick={() => handleNext()}>Next</Button>
                </span>
              </Tooltip>}

            {currentVersion &&
              (<div className={classes.SecondForm}><div className={classes.inputType1}
                style={{ flexDirection: width > 1051 ? "row" : "column", }}>

                <TextField className={classes.inputType2}
                  disabled={isMediaLoading ? true : false}
                  value={input.version}
                  id="version-name" label="Version Sequence"
                  onChange={handleInputChange}
                  name="version" error={inputError.version ? true : false}
                  helperText={inputError.version} required />

                <TextField className={classes.inputType2} id="version-number"
                  label="Build Number" name="version_name" value={input.version_name}
                  onChange={handleInputChange}
                  disabled={isMediaLoading ? true : false}
                  error={inputError.version_name ? true : false}
                  helperText={inputError.version_name}
                  required />
              </div>

                <TextField id="release-notes" multiline label="Release Notes"
                  className={classes.inputType2}
                  disabled={isMediaLoading ? true : false}
                  value={input.release_notes} onChange={handleInputChange}
                  name="release_notes" rows={6}
                />

                <div className={classes.inputType1} style={{ flexDirection: width > 1051 ? "row" : "column", }}>
                  <div >
                    <FormLabel required htmlFor='legend'>Available Play Store Support</FormLabel>
                    <RadioGroup
                      className={classes.radiogroup} row={isDirectionRow}
                      name="has_playstore" id='play_store_support'
                      value={`${input.has_playstore}`}
                      onChange={handleInputChange} aria-required>
                      <FormControlLabel value="yes"
                        control={<Radio color='primary' />} label="Yes" disabled={isMediaLoading ? true : false} />
                      <FormControlLabel value="no"
                        control={<Radio color='primary' />} label="No" disabled={isMediaLoading ? true : false} />
                    </RadioGroup>
                  </div>
                  <div className={classes.dnd} style={{ width: width < 400 && '100%' }}>
                  {input.source && <Typography variant='subtitle1' color='primary'
                    style={{ opacity: (isMediaLoading || isVersionLoading) ? "0.5" : "1" }}>
                      Successfully Uploaded {input.source.name}
                    </Typography>}
                    <div className={classes.uploadbtn}>

                      <Button disabled={isMediaLoading}
                        variant='contained'
                        color="primary"
                        onClick={onTargetClick}
                      >
                        <SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>
                        Upload
                      </Button>
                    </div>
                  </div>
                  <input
                    onChange={(e) => handleFileDrop(e, e.target.files)}
                    ref={fileInputRef}
                    type="file"
                    className={classes.hidden}
                  />
                </div>
                <FormGroup className={classes.submitDiv}>

                  <div className={classes.bottomDiv}>

                    <Button disabled={isMediaLoading} size="small" type="button"
                      onClick={() => handlePrev()}
                      variant="contained" color="primary"
                      className={classes.btn}><ChevronLeftIcon />Prev</Button>
                    <Tooltip arrow disableTouchListener={!isSubmitDisable} className={classes.tooltip}
                      title={isSubmitDisable ?
                        "Please enter valid responses for all required fields" : ""} placement="top" >
                      <span>
                        <Button disabled={isSubmitDisable || isMediaLoading} size="small" type="button"
                          onClick={handleSubmit}
                          variant="contained" color="primary"
                          className={classes.btn}>Submit</Button>
                      </span>
                    </Tooltip>
                  </div>
                </FormGroup>
              </div>)}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceUpdateScreen);