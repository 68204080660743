import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { PaginatedResponse } from '../../../shared/models';
import { Typography, Link, TextField } from '@material-ui/core'
import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { BusinessRouterPaths, MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getCCRSReportDetailList, saveFileContentDetailErrorMsgs } from '../../api';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError, ToastWarning } from '../../../shared/components/Notifier';
import GlobalStyles from '../../../../styles/GlobalStyles.web'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
    statusText: {
      fontSize: 16,
      textAlign: 'left',
      marginLeft: 58,
      padding: "6px 0",
      display: 'flex',
      alignItems: 'center'
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
  }),
);


const CCRSReportDetailListScreen = (props: any) => {
  const classes = useStyles({});
  const tableRef = useRef<{ onQueryChange: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedFileDetailContent, setSelectedFileDetailContent] = useState([]);
  const [isModalLoading, setModalLoading] = useState(false)
  const [updatedRows, setUpdatedRows]= useState(null as any);
  const [fullData, setFullData] = useState(null)
  const [responseData, setReponseData] = useState(null)

  useEffect(()=>{
    console.log("updatedRows", updatedRows)
  },[updatedRows])

  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
      setFullData(null)
      setReponseData(null)
    }
  }, [props.userData.selectedBusiness]);

  const handleRefresh =()=>{
    tableRef.current && tableRef.current.onQueryChange();
    setFullData(null)
    setReponseData(null)
  }

  const getPagedElements = (data: any, pageNum: any, pageSize: any) => {

    let arr = data
    let totalElements = arr.length
    let page_size = pageSize
    let page = pageNum

    let startIndex = (page - 1) * page_size
    let endIndex = (startIndex + page_size)

    if (startIndex >= totalElements) {
      return []
    }
    else if (endIndex >= totalElements) {
      return arr.slice(startIndex)
    }
    return arr.slice(startIndex, endIndex)

  }



  const exportCSV = (rowData?: any) => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getCCRSReportDetailList(
        props.match.params.id,
        sb.business_id,
        null,
        null,
        tableQuery.search,
        sb.orderBy,
        shouldExport,
        rowData ? rowData.file_name : null
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, `${rowData.file_name}`);
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            let errorMsg = 'Failed to Download'
            if (error.status && error.status === 500) {
              errorMsg = ERROR_MSG
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
            props.showToast({
              message: `${errorMsg}`,
              options: ToastError
            });
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>, rowData: any) => {
    console.log("rowData", rowData)
    // let dlLink = rowData.ftp_core && rowData.ftp_core.source
    // download(dlLink)
    // window.open(dlLink)
    exportCSV(rowData)
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };



  const handleSave = () => {
    if (selectedFileDetailContent && selectedFileDetailContent.length > 0) {
      let selectedData = fullData.filter(o1 => selectedFileDetailContent.some(o2 => o1.id === o2.id));
      console.log("SelectedData", selectedData)

      const sb = props.userData.selectedBusiness
      let payload = {
        business_id: sb.business_id,
        file_contents: selectedData
      }

      saveFileContentDetailErrorMsgs(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data) => {
          handleRefresh()
          setLoading(false)
        })
        .catch((err: any) => {
          setLoading(false)
        });

    }
  }


  const handleChangeErrorDetail =(rowData:any,e:any)=>{
    let data = cloneDeep(fullData)
    let rowIndex = data.findIndex((row: any) => row.id === rowData.id)
    data[rowIndex]['error_details'] =  e.target.value

    setFullData(cloneDeep(data))
    // console.log("changeError", data, e.target.value)

  }


  const getErrorMsg =(rowData) =>{
    let data = cloneDeep(fullData)
    let rowIndex = data.findIndex((row: any) => row.id === rowData.id)
    // console.log("getErrorMsg", data)
    return data[rowIndex]['error_details']

  }

  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />

      <div style={{ maxWidth: '100%' }}>
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true,
              selection: true,
              selectionProps: rowData => ({
                color: 'secondary',
                // disabled: rowData.retl_item_code.includes('z') ? true : rowData.retl_item_code.includes('Z') ? true : false
              }),
              showTextRowsSelected: false,
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            // initialState={{ columnVisibility: { address: false } }}
            columns={[
              // {

              //   title: 'File Name',
              //   field: 'file_name',
              //   editable: 'never',
                
              //   render: rowData => {
              //     if (rowData.file_name) {
              //       return <Typography variant="subtitle2" style={{ minWidth: name != null ? "150px" : null, paddingRight: 10, }}>{rowData.file_name}</Typography>;
              //     }
              //     return <Typography variant="subtitle2" ></Typography>;
              //   }
              // },
              {

                title: 'External Identifier',
                field: 'db_record_id',
                editable: 'never',
                render: rowData => {
                  if (rowData.file_name) {
                    return <Typography variant="subtitle2" style={{ minWidth: name != null ? "250px" : null, paddingRight: 10, }}>{rowData.db_record_id}</Typography>;
                  }
                  return <Typography variant="subtitle2" ></Typography>;
                }
              },

              // {
              //   title: 'Report Type',
              //   field: 'ftp_report_type',
              //   editable: 'never',
              //   render: rowData => {
              //     if (rowData.ftp_report_type) {
              //       return <Typography variant="subtitle2" style={{ paddingRight: 10, }}>{rowData.ftp_report_type.value}</Typography>;
              //     }
              //     return <Typography variant="subtitle2" ></Typography>;
              //   }
              // },
              // {
              //   title: 'Uploaded Time',
              //   field: 'end_time',
              //   editable: 'never',
              //   render: rowData => {
              //     if (rowData.end_time) {
              //       let endTime = new Date(rowData.end_time.slice(0, -1))
              //       // console.log("EndTime", endTime, endTime.getFullYear(),)
              //       return <Typography variant="subtitle2" style={{ paddingRight: 10, }}>{endTime.toLocaleDateString('en-GB').split("/").reverse().join("/") + ` ` + endTime.toLocaleTimeString()}</Typography>;
              //     }
              //     return <Typography variant="subtitle2" ></Typography>;
              //   }
              // },
              {
                title: 'Status',
                field: 'ftp_status',
                editable: 'never',
                render: rowData => {
                  if (rowData.ftp_status) {
                    return <Typography variant="subtitle2" style={{ paddingRight: 10, }}>{rowData.ftp_status.value}</Typography>;
                  }
                  return <Typography variant="subtitle2" ></Typography>;
                }
              },
            ]}
            onSelectionChange={(data: any) => {
              setSelectedFileDetailContent(data)
            }
            }
            data={query =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  // console.log('query', query);
                  let file_id = props.match.params.id
                  const sb = props.userData.selectedBusiness;
                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as any[],
                      totalCount: 0,
                      page: query.page
                    };
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    }
                    setLoading(true)

                    // if (fullData && responseData) {
                    //   let full_data = cloneDeep(fullData)
                    //   let filtered_data = []
                    //   let fin_res = []
                    //   let page = query.page + 1
                    //   let page_size = query.pageSize
    
                    //   // if(query.search){
                    //   //   let userSearchTerm = query.search 
                    //   //   let search_terms =['article_name','article_number','recognitionNumber','cg_number','cg_name']
                        
    
                    //   //   for (let fil_d in full_data){
                    //   //     console.log("item",full_data[fil_d])
                    //   //     console.log("j",Object.values(full_data[fil_d]).find(v => String(v).includes(String(userSearchTerm)))! == undefined ?full_data[fil_d] : null  )
                    //   //     if (Object.values(full_data[fil_d]).find(v => String(v).includes(String(userSearchTerm))) ! == undefined ){
                    //   //       fin_res.push(full_data[fil_d])
                    //   //       break
                    //   //     } 
                    //   //   }
                    //   // }
                    //   // console.log("fin_res_@@",query.search, Object.values(full_data[0]), fin_res)
    
                    //   if (query.orderBy) {
                    //     orderBy = query.orderBy.field as string;
                    //     orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    //     filtered_data = cloneDeep(sortBy(full_data, orderBy)  )
    
                    //     if (query.orderDirection && query.orderDirection === 'desc'){
                    //       filtered_data = cloneDeep(sortBy(filtered_data, query.orderBy.field).reverse())
                    //     }
      
                    //   }
    
                    //   let resultantData = getPagedElements(filtered_data.length > 0 ? filtered_data : full_data, page, page_size)
    
                    
                    //   // console.log("resultantData", resultantData)
    
                    //   pageData = {
                    //     data: resultantData,
                    //     totalCount: responseData.count,
                    //     page: query.page
                    //   };
                    //   setTableQuery({
                    //     ...tableQuery,
                    //     orderBy,
                    //     totalCount: responseData.count,
                    //     page: query.page,
                    //     pageSize: query.pageSize
                    //   });
                    //   resolve(pageData);
    
                    // }
                    // else {
                    
                    getCCRSReportDetailList(
                      file_id,
                      sb.business_id,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      orderBy,
                      false
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: any) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                          setFullData(cloneDeep(data.results))
                          setReponseData(data)
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                        setLoading(false)
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if ((error.status === 403 || error.status === 401)
                          ) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      });

                    }
                  // }

                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Report Detail'}
                icon={SVG_ICONS.reports} />
            }
            actions={[

              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () =>handleRefresh(),
              },
              {
                icon: 'save',
                tooltip: 'Save',
                position: "toolbarOnSelect",
                onClick: () => handleSave(),
              },
            ]}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            detailPanel={rowData => {
              // console.log("RowData", rowData)
              return (
                <div id={String(rowData.id)} style={{ marginLeft: 15 }} >
                  <TextField
                    id={String(rowData.id)}
                    autoFocus
                    margin="dense"
                    size="small"
                    value={getErrorMsg(rowData)}
                    onChange={(e)=>handleChangeErrorDetail(rowData,e)}
                    label="Enter the Error Msg"
                    type="text"
                    style={{ width: '12rem' }}
                  />
                </div>

              )

            }
            }

          />
        </div>
      </div>
      <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CCRSReportDetailListScreen);
