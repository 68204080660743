import { EXPECTED_HEADERS, StockOrderItemDatum, StockOrderRequest, StockOrderItem } from '../../models';
import { Product } from '../../../products/models';
let reorder_code_in_headers = false; // flag to check if reorder code is there in headers, its used when editing the item

/*
  requirements possibility:
  1. supplier product name or the reorder code
  2. (cases delivered & cost per case) 
  or (units delivered & cost per unit)
  or (cases delivered & cost per unit & units per case)
  or (units delivered & cost per case & units per case)
  */
export const validateHeaders = (mappedHeaders) => {
  // debugger;
  const noError = { hasError: false, message: '', message2: '' };
  const error = { hasError: true, message: 'There are insufficient mapped columns.', message2: '' };
  if (mappedHeaders.size === 0) {
    return { hasError: true, message: 'Please select headers for columns.', message2: '' };
  } else {
    const headerValues = Array.from(mappedHeaders.values());
    
    const contains_reorder_code=headerValues.find(element=>element==='Re-Order Code');
    if (contains_reorder_code) {
     
      reorder_code_in_headers= true
    }
    else  {
     
      reorder_code_in_headers= false
    }
    
    console.log('headerValues', headerValues);
    if (headerValues.length < 3) {
      return error;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.SUPPLIER_PRODUCT_NAME) === -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.RE_ORDER_CODE) === -1)
    ) {
      return {
        hasError: true,
        message: 'There are insufficient mapped columns.',
        message2: 'Supplier Product Name or ReOrder Code is required.'
      };
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.CASES_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_CASE) !== -1)) {
      return noError;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.UNITS_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_UNIT) !== -1)) {
      return noError;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.CASES_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_UNIT) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.CASE_QUANTITY) !== -1)) {
      return noError;
    } else if (
      (headerValues.indexOf(EXPECTED_HEADERS.UNITS_DELIVERED) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.COST_PER_CASE) !== -1) &&
      (headerValues.indexOf(EXPECTED_HEADERS.CASE_QUANTITY) !== -1)) {
      return noError;
    } else {
      return error;
    }
  }
};

export const getInvoiceItemsToMatch = (grid, mappedHeaders) => {
  let requestData = [];
  let index = 0;
  let reOrderCodeRetailer=false;
  for (let row = 0; row < grid.length; row++) {
    if (row === 0) {
      continue;
    }
    if (grid[row][0] && !(grid[row][0].isSelected)) {
      index += 1;
    }
    else if (grid[row][0] && grid[row][0].isSelected) {
      continue;
    }
    let data: any = {
      itemIndex: index,
      reOrderCode: '',
      supplierProductName: '',
      serialNumber: '',
      caseQuantity: '',
      master_supplier_container_qty: '',
      casesDelivered: '',
      costPerCase: '',
      unitsDelivered: '',
      costPerUnit: '',
      lineTotal: '',
      calculatedLineTotal: '',
      reOrderCodeOfRetailerToSend: reOrderCodeRetailer,
      product_edited_or_updated:false,
      headerContainsReOrderCode:reorder_code_in_headers,
      supplier_code:'',
      supplier_quantity:'',
      retailer_quantity:'',
      retailer_reorder_code:'',
      product: {} as any
    };

    for (let col = 0; col < grid[row].length; col++) {
      if (grid[row][col].isSelected) {
        continue;
      } else {
        const columnHeader = mappedHeaders.get(col);
        console.log('columnHeader', columnHeader);
        if (columnHeader) {
          switch (columnHeader) {
            case EXPECTED_HEADERS.RE_ORDER_CODE:
              data.reOrderCode = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.SUPPLIER_PRODUCT_NAME:
              data.supplierProductName = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.CASES_DELIVERED:
              const casesDelivered = String(grid[row][col].value).replace(',', '');
              data.casesDelivered = casesDelivered !== '' ?  casesDelivered.includes(".") ? parseFloat(casesDelivered) : parseInt(casesDelivered) : '';
              break;
            case EXPECTED_HEADERS.SERIAL_NUMBER:
              data.serialNumber = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.COST_PER_CASE:
              const costPerCase = String(grid[row][col].value).replace(',', '');
              data.costPerCase = Number(costPerCase).toFixed(2);
              break;
            case EXPECTED_HEADERS.LINE_TOTAL:
              const lineTotal = String(grid[row][col].value).replace(',', '');
              data.lineTotal = Number(lineTotal).toFixed(2);
              break;
            case EXPECTED_HEADERS.CASE_QUANTITY:
              const caseQuantity = String(grid[row][col].value).replace(',', '');
              data.caseQuantity = caseQuantity
              break;
            case EXPECTED_HEADERS.COST_PER_UNIT:
              const costPerUnit = String(grid[row][col].value).replace(',', '');
              data.costPerUnit = Number(costPerUnit).toFixed(2);
              break;
            case EXPECTED_HEADERS.UNITS_DELIVERED:
              const unitsDelivered = String(grid[row][col].value).replace(',', '');
              data.unitsDelivered = unitsDelivered !== '' ? unitsDelivered.includes(".") ? parseFloat(unitsDelivered) : parseInt(unitsDelivered) : '';
              break;
            case EXPECTED_HEADERS.DISCOUNT:
              const discount =  String(grid[row][col].value).replace(',', '');
              data.discount = Number(discount).toFixed(2);
              break;
          }
        }
      }
    }
    requestData.push(data);
  }
  console.log("Get Invoice Item to match called", requestData)
  return requestData;
};

export const getMatchedHeaders = (grid, mappedHeaders) => {
  let displayReOrderCode = false;
  let displayCasesDelivered = false;
  let displaySerialNumber = false;
  let displayCaseQty = false;
  let displayUnitsDelivered = false;
  let displayCostPerCase = false;
  let displayCostPerUnit = false
  for (let row = 0; row < 1; row++) {
    for (let col = 0; col < grid[row].length; col++) {
      if (grid[row][col].isSelected) {
        continue;
      } else {
        const columnHeader = mappedHeaders.get(col);
        if (columnHeader) {
          switch (columnHeader) {
            case EXPECTED_HEADERS.RE_ORDER_CODE:
              displayReOrderCode = true;
              break;
            case EXPECTED_HEADERS.CASES_DELIVERED:
              displayCasesDelivered = true;
              break;
            case EXPECTED_HEADERS.COST_PER_CASE:
              displayCostPerCase = true;
              break;
            case EXPECTED_HEADERS.SERIAL_NUMBER:
              displaySerialNumber = true;
              break;
            case EXPECTED_HEADERS.CASE_QUANTITY:
              displayCaseQty = true;
              break;
            case EXPECTED_HEADERS.UNITS_DELIVERED:
              displayUnitsDelivered = true;
              break;
            case EXPECTED_HEADERS.COST_PER_UNIT:
              displayCostPerUnit = true;
              break;
          }
        }
      }
    }
  }
  return {
    casesDelivered: displayCasesDelivered ? true : false,
    serialNumber: displaySerialNumber ? true : false,
    caseQuantity: displayCaseQty ? true : false,
    reOrderCode: displayReOrderCode ? true : false,
    unitsDelivered: displayUnitsDelivered ? true : false,
    costPerCase : displayCostPerCase ? true : false,
    costPerUnit : displayCostPerUnit ? true : false

  };
};

export const getMatchedItemSummary = (stockOrderProductList, stockOrderSummary) => {
  console.log("getMatchedItemSummary called");
  console.log("stockOrderProductListInside matched Item Summary", stockOrderProductList.data)

  const itemList = [...stockOrderProductList.data];
  const soSummary = { ...stockOrderSummary };
  soSummary.itemCount = itemList.length;

  let expectedTotal = 0;
  let calculatedTotal = 0;
  let unMappedProductCount = 0;

  itemList.forEach(item => {
    console.log(`BEFORE--------------------------Item: ${item.reOrderCode}, CostPerCase: ${item.costPerCase}, CasesDelivered: ${item.casesDelivered}, LineTotal: ${item.costPerCase * item.casesDelivered }`);

    let itemCost = parseFloat(item.costPerCase) || 0;
    let qtyDelivered = parseFloat(item.casesDelivered) || 0;

    const lineTotal = itemCost * qtyDelivered;
    // const lineTotal = parseFloat(item.lineTotal); 

    expectedTotal += lineTotal;

    console.log(`Item: ${item.reOrderCode}, CostPerCase: ${itemCost}, CasesDelivered: ${qtyDelivered}, LineTotal: ${lineTotal}`);

    if (item.error && !['different_quantity_same_reorder_code', 'different_reorder_code_same_quantity', 'different_quantity_different_reorder_code'].includes(item.error_type)) {
      item.calculatedLineTotal = '0.00';
      unMappedProductCount += 1;
    } else {
      // Convert lineTotal to a number and ensure precision
      item.calculatedLineTotal = lineTotal.toFixed(2);
      calculatedTotal += parseFloat(item.calculatedLineTotal);
    }

    // Ensure caseQuantity is set as an empty string if not present
    if (!item.caseQuantity) {
      item.caseQuantity = '';
    }
  });

  console.log(`Raw Expected Total: ${expectedTotal}`);
  console.log(`Raw Calculated Total: ${calculatedTotal}`);

  soSummary.expectedTotal = expectedTotal.toFixed(2);
  soSummary.calculatedTotal = calculatedTotal.toFixed(2);
  soSummary.unMappedProducts = unMappedProductCount;

  console.log('stockOrderProductList matched data', itemList);

  return {
    summary: soSummary,
    items: itemList
  };
};




const getAction =(rowItem:any) =>{
  console.log('iTemmmm', rowItem)
  if (rowItem.warning) {
    if (rowItem.warning_type === 'new_supp_rec') {
      return `Add new supplier record for this item on Korona with ${rowItem.reOrderCode},${rowItem.master_supplier_container_qty}, ${rowItem.costPerUnit}.(Automatic, when Create Stock Order is clicked)`
    }
    else if(rowItem.warning_type === 'cost_diff'){
      return `There is a difference in price, imported price and CODE/PLU will be updated in Korona` 
    }
    else return `Supplier Record is missing on Retailer, New Record will be created on korona`
  }
  return `----`;
}

const getIssue =(rowItem:any)=>{
  if (rowItem.warning) {
    return `${rowItem.warning}`;
  }
  return `----`;
}


export const getStockOrderRequestData = (selectedSupplier, selectedLocation, description, comment=null, stockOrderProducts) => {
  const selectedLocationId = selectedLocation.id;
  const orgUnitId = selectedLocation.korona_org_unit;
  const requestData = {
    supplier: selectedSupplier.korona_supplier.uuid, // supplier id
    target: orgUnitId, // org unit id
    description: description,
    items: [],
    location: selectedLocationId,
    comment : comment
  };
  let isError = false;
  let items = [];
  stockOrderProducts = stockOrderProducts.filter(p => !p.error);
  for (let i = 0; i < stockOrderProducts.length; i++) {
    let currProd = stockOrderProducts[i]
    //console.log("SO",stockOrderProducts);
    const {
      itemIndex,
      supplierProductName,
      reOrderCode,
      product,
      unitsDelivered,
      casesDelivered,
      master_supplier_container_qty,
      costPerUnit,
      costPerCase,
      reOrderCodeOfRetailerToSend,
      warning,
      warning_type,
      lineTotal,
      
      
    } = stockOrderProducts[i];

    const item = {
      itemIndex: Number(itemIndex),
      name: supplierProductName,
      supplierItemNumber: reOrderCode ? reOrderCode : null,
      actualGoods: Number(unitsDelivered), // this code is required = desiredOverallQuantity
      articleNumber: null,
      article: null,
      number: null,
      code: null,
      color: null,
      size: null,
      quantity: Number(unitsDelivered), // this field is required
      bookedQuantity: null,
      purchasePrice: null,
      salesPrice: null,
      desiredOverallQuantity: Number(unitsDelivered), // this field is required
      actualOverallQuantity: null,
      receivedQuantity: null,
      containerQuantity: Number(master_supplier_container_qty), // this field is required
      //containerQuantity:Number(product.suppliers[0].boxSize),
      uuid: null,
      itemPrice: null,
      deleted: false,
      assortment: null as any,
      commodityGroup: null as any,
      prices: null as any,
      sector: null as any,
      suppliers: null as any,
      warning_type: null,
      lineTotal: lineTotal,
      action : '',
      issue:''
    };
    if (costPerUnit !== '') {
      item.itemPrice = Number(costPerUnit);
    }
    if (product.name) {
      item.articleNumber = product.number;
      item.number = product.number;
      item.uuid = product.uuid;
      item.article = product.uuid;
    }
    if (product.assortment){
      item.assortment = product.assortment
    }
    if (product.commodityGroup){
      item.commodityGroup = product.commodityGroup
    }
    if(product.prices && product.prices.length > 0){
      item.prices = product.prices
    }
    if (product.sector){
      item.sector = product.sector
    }
    if(product.suppliers && product.suppliers.length > 0){
      item.suppliers = product.suppliers
    }
    if(reOrderCodeOfRetailerToSend===true){
      item.supplierItemNumber=product.suppliers[0].orderNumber
      item.containerQuantity=Number(product.suppliers[0].boxSize)
    }
    // if (stockOrderItemDatum.caseQuantity === '') {
    //     item.containerQuantity = Number(stockOrderItemDatum.master_supplier_container_qty);
    // }
    if (item.containerQuantity !== 0) {
      if (costPerCase !== '') {
        item.itemPrice = Number(costPerCase) / item.containerQuantity;
      }
      if (Number(casesDelivered) !== 0) {
        if (unitsDelivered === '') {
          item.desiredOverallQuantity = Number(casesDelivered) * item.containerQuantity;
          item.quantity = Number(casesDelivered) * item.containerQuantity;
          item.actualGoods = item.desiredOverallQuantity;
        }
      }
    }
    // item should be validated here
    // console.log(item);
    if(['new_supp_rec','cost_diff'].includes(warning_type) ){
      item.warning_type = warning_type
    }
    item.issue = getIssue(currProd)
    item.action = getAction(currProd)



    items.push(item);
  }
  requestData.items = items;
  return { payload: requestData, hasError: false };
};