

import React from 'react';
import { Typography, Divider, SvgIcon } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import GlobalStyles from '../../../styles/GlobalStyles.web';
import { ReportCard, ReportCardItem } from '../models';
import moment from 'moment';
import { ErrorStatusBar } from '../../shared/components';

const getDateTime = (dateTime: string) => {
  if (dateTime) {
    return moment(dateTime).calendar(null, {
      lastDay: '[Yesterday at] LT',
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      lastWeek: '[Last] dddd [at] LT',
      nextWeek: 'dddd [at] LT',
      sameElse: 'YYYY-MM-DD'
    });
  }
  return '';
};

const get403ErrorMsg = (summaryResponse) => {
  const { errorData } = summaryResponse;
  return (
    <ErrorStatusBar
      isVisible={
        errorData &&
        errorData.status === 403}
      displayMultiline={true}
      textStyle={{ fontSize: '0.875rem' }}
      messageContainerStyle={{ padding: 2 }} />
  );
};

export const renderCardItem = (classes, allItems: ReportCardItem[], item: ReportCardItem, summaryData) => {
  if (summaryData && summaryData.isFetching) {
    return null;
  }

  return (
    <React.Fragment key={item.title}>
      <Grid container className={classes.row}
        style={{
          marginBottom:
            (item.isLastItem && summaryData) &&
              summaryData.errorData &&
              summaryData.errorData.status === 403 ? 16 : 0
        }}
      >
        <Grid item>
          <Typography variant='subtitle2'>{item.title}</Typography>
        </Grid>
        <Grid item >
          {
            summaryData &&
            <Typography variant='body1' className={classes.rowInfo}>
              {
                (summaryData[item.itemMapping] === 0 ||
                  summaryData[item.itemMapping]) ?
                  item.isDateTime ? getDateTime(summaryData[item.itemMapping]) : summaryData[item.itemMapping]
                  : item.itemMapping ? item.isDateTime ? getDateTime(item.defaultValue) :
                    item.defaultValue ? item.defaultValue : ''
                    : ''
              }
            </Typography>
          }
          {
            !summaryData &&
            <Typography variant='body1' className={classes.rowInfo}>
              {item.defaultValue ? item.defaultValue : ''}
            </Typography>
          }
        </Grid>
      </Grid>
      {
        !item.isLastItem && allItems[allItems.length - 1] !== item &&
        <Divider className={classes.subDivider} />
      }
    </React.Fragment>);
};

export const renderCard = (classes, report: ReportCard, summaryData, navigateFn) => {
  const visibleItems = report.items.filter(i => i.isVisible);
  return (
    <Grid item xs={12} sm={6} lg={3} xl={3} key={report.id}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Grid container className={classes.titleRow}>
            <Grid item className={classes.iconGrid}>
              <div
                className={classes.cardIcon}
                style={{ backgroundColor: report.iconColor }}>
                <div className={classes.cardIconWrapper}>
                  <SvgIcon className={classes.cardSvgIcon}>
                    <path d={report.icon} />
                  </SvgIcon>
                </div>
              </div>
              <Typography
                variant='h6'
                className={classes.cardTitle}
              >{report.title}</Typography>
            </Grid>
            <Grid item className={classes.launchIconGrid}>
              <IconButton aria-label="launch"
                className={classes.cardLinkBtn}
                onClick={() => {
                  report.navigationLink ?
                    navigateFn(report.navigationLink)
                    : null;
                }}>
                <LaunchIcon fontSize={'small'} />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          {
            summaryData &&
            summaryData.isFetching &&
            <CircularProgress className={classes.progress} />
          }
          {
            visibleItems.map(item => {
              return renderCardItem(classes, visibleItems, item, summaryData);
            })
          }
          {
            summaryData &&
            get403ErrorMsg(summaryData)
          }
        </CardContent>
      </Card>
    </Grid>
  );
};

