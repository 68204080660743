import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import { MasterProductDuplicate, UnknownIdentifier } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { getUnknownIdentifiersReport, processUnknownIdentifier } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { hasUpdatePermission, isSuperAdmin, hasRequiredPermissions, hasPermission } from '../../../shared/lib/PermissionManager';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import {
  CustomTableTitle,
  ErrorStatusBar,
  ExportMenu,
  ExportStatusBar,
} from '../../../shared/components';
import {
  NO_PERMISSIONS_ERROR_MSG,
  // EMPTY_ROW_MSG,
  ERROR_500,
  ERROR_MSG,
  UNKNOWN_IDENTIFIER_EMPTY_ROW_MSG
} from '../../../shared/lib/Localization';
import {
  initQueryParams,
  replaceHistory,
  useQuery,
  tableOptions,
} from '../../../shared/lib/TableQueryParams';
import isEqual from 'lodash/isEqual';
import download from 'downloadjs';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Button, Paper, Typography, CircularProgress, Divider, SvgIcon, Input } from '@material-ui/core';

import { ClassWithStaticPermission } from '../../../shared/models/models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem',
    },
    progress: {
      margin: theme.spacing(2),
    },
    greenCircle: {
      color: GlobalStyles.green,
    },
    redCircle: {
      color: GlobalStyles.warningText,
    },
    helpTooltipRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 8,
    },
    helpTooltipText: {
      padding: '0 8px 0 0',
    },
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
  })
);
const permissionModel = 'unknownidentifierreport';

const timeDisplayFormat = 'YYYY-MM-DD HH:mm';

interface StatusFilter {
  id: number;
  label: string;
  filterString: string | null | undefined;
}

const filterSet1: StatusFilter[] = [
  { id: 1, label: 'Processed', filterString: '&processed=true' },
  { id: 2, label: 'Unprocessed', filterString: '&processed=false' },
];
const filterSet2: StatusFilter[] = [
  { id: 3, label: 'Potential Match', filterString: '&matched_product_desc__isnull=false' },
  { id: 4, label: 'No Potential Match', filterString: '&matched_product_desc__isnull=true' },
];
const filterSet3: StatusFilter[] = [{ id: 5, label: 'All', filterString: null }];
const filterSet4: StatusFilter[] = [{ id: 6, label: `Either`, filterString: '' }];
const filterSet5: StatusFilter[] = [{ id: 7, label: `Both`, filterString: '' }];

const potentialFilterSet: StatusFilter[] = [
  { id: 8, label: 'None', filterString: '&match_type=None' },
  { id: 9, label: 'Master', filterString: '&match_type=master' },
  { id: 10, label: 'Company', filterString: '&match_type=company' },
  { id: 11, label: 'Brand', filterString: '&match_type=brand' },
  { id: 11, label: 'Other', filterString: '&match_type=other' },
  { id: 11, label: 'All', filterString: '&matched_product_desc__isnull=false' },

];

const statusFilters: StatusFilter[] = filterSet1.concat(filterSet4).concat(filterSet2).concat(filterSet5).concat(filterSet3);

const nonSuperUserIds = [3, 4];
const MenuDividerIds = [2, 4];

const nonSuperUserFilters: StatusFilter[] = statusFilters.filter(statusFilter => nonSuperUserIds.indexOf(statusFilter.id) === -1);


const UnknownIdentifierHistoryScreen = (props: any) => {
  const { userData } = props.userData.userData;
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(UNKNOWN_IDENTIFIER_EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; onChangePage: any }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [tableEditableProp, setTableEditableProp] = useState({});
  const [filterBtnAnchorEl, setFilterBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedFilters, setSelectedFilters] = useState([statusFilters[1]])
  const [isLoading, setLoading] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setExporting] = useState(false);
  const theme = useTheme();
  const [isSuperUser, setSuperUser] = useState(isSuperAdmin(props));
  const permissions = [...userData.user_permissions];
  const isAvailablePermission = hasPermission(permissions, ClassWithStaticPermission.CAN_VIEW_MATCHED_PRODUCT_DESC);
  const [showSuggestedMatchColumn, setShowSuggestedMatchColumn] = useState(isAvailablePermission);
  const [totalRecords, setTotalRecords] = useState(null as any)

  useEffect(() => {
    if (selectedFilters && selectedFilters.length > 0) {
      tableRef.current && tableRef.current.onQueryChange();
      setTotalRecords(null)

    }
  }, [selectedFilters])

  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
      setTotalRecords(null)

    }
  }, [props.userData.selectedBusiness]);

  useEffect(()=>{
    console.log("totalRecords",totalRecords)
  },[totalRecords])

  const handleRefresh= () => {
    setTotalRecords(null)
    tableRef.current && tableRef.current.onQueryChange()
  }

  useEffect(() => {
    const { userData } = props.userData.userData;
    const editable: any = {};
    if (userData) {
      if (
        hasUpdatePermission(
          userData.is_superuser,
          userData.user_permissions,
          permissionModel
        )
      ) {
        
        editable['onRowUpdate'] = getUpdateFn();
      }
      setTableEditableProp(editable);
      setTotalRecords((prevState:any)=> null)
    }
  }, [props.userData]);

  const [columns, setColumns] = useState([
    {
      title: 'Unknown Code',
      field: 'unknown_code',
      editable: 'never',
    },
    {
      title: 'Processed',
      field: 'processed',
      type: 'boolean',
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      // defaultFilter: 'unchecked',
      editable: 'onUpdate',
      // filtering: true,
      // render: (rowData: any) => <Switch checked={rowData.processed} />,
      render: (rowData: any) => (
        <SvgIcon className={rowData.processed ? classes.greenCircle : classes.redCircle}>
          <path d={SVG_ICONS.blankCircle} />
        </SvgIcon>
      ),
      editComponent: (props: any) => {
        return (
          <Switch
            checked={props.value ? true : false}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        );
      },
    },
    {
      title: 'Already Added To Item',
      field: 'already_added_to_item',
      editable: 'never',
      emptyValue: '---',
    },
    {
      title: 'Sugg. Match',
      field: 'matched_product_desc',
      editable: 'never',
      emptyValue: '---',
      hidden: isSuperAdmin(props) ? false : !showSuggestedMatchColumn
    },
    {
      title: 'No. Entries',
      field: 'num_entries',
      editable: 'never',
    },
    {
      title: 'Check Digit Valid',
      field: 'check_digit_valid',
      editable: 'never',
    },

    {
      title: 'Last Scanned',
      field: 'max_created_time',
      editable: 'never',
      render: (rowData: any) =>
        rowData.max_created_time
          ? moment(rowData.max_created_time).format(timeDisplayFormat)
          : null,
    },
  ]);

  const handleFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterBtnAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = (filter: StatusFilter | null) => {
    if (filter === null) {
      setFilterBtnAnchorEl(null)

    } else {

      let updatedSelectedFilters = [filter];
      const isAllSelected = filter.id === 5;
      const isEitherSelected = filter.id === 6;
      const isBothSelected = filter.id === 7;

      if (isSuperUser && !isAllSelected) {
        const selectedFilterIDs = selectedFilters.map(selectedFilter => selectedFilter.id);
        const filterSet1Ids = filterSet1.map(i => i.id);
        const filterSet2Ids = filterSet2.map(i => i.id);
        const filterSet4Ids = filterSet4.map(i => i.id);
        const filterSet5Ids = filterSet5.map(i => i.id);

        let selectedIds = selectedFilterIDs.filter(id => id !== 5);

        if (filterSet1Ids.includes(filter.id)) {
          selectedIds = selectedIds.filter(id => !filterSet1Ids.includes(id) && !filterSet4Ids.includes(id));
          selectedIds.push(filter.id);
        } else if (filterSet2Ids.includes(filter.id)) {
          selectedIds = selectedIds.filter(id => !filterSet2Ids.includes(id) && !filterSet5Ids.includes(id));
          selectedIds.push(filter.id);
        }
        else if (filterSet4Ids.includes(filter.id)) {
          selectedIds = selectedIds.filter(id => !filterSet1Ids.includes(id) && !filterSet4Ids.includes(id) && !filterSet5Ids.includes(id));
          selectedIds.push(filter.id);
        }
        else if (filterSet5Ids.includes(filter.id)) {
          selectedIds = selectedIds.filter(id => !filterSet2Ids.includes(id) && !filterSet5Ids.includes(id) && !filterSet4Ids.includes(id));
          selectedIds.push(filter.id);
        }

        updatedSelectedFilters = statusFilters.filter(i => selectedIds.includes(i.id));

      }

      // console.log("UPDATED", updatedSelectedFilters)
      setSelectedFilters(updatedSelectedFilters)
      setFilterBtnAnchorEl(null)
    }

  };

  const getUpdateFn = () => {
    return (newData: any, oldData: any) =>
      new Promise((resolve, reject) => {
        setTotalRecords((prevState:any)=> null)

        setTimeout(() => {
          {
            processUnknownIdentifier(newData)
              .catch(handleError) // handle network issues
              .then(checkStatus)
              .then(parseJSON)
              .then((jsonData) => {
                props.showToast({
                  message: 'Unknown Identifier updated successfully!',
                  options: ToastSuccess,
                });
                tableRef.current && tableRef.current.onChangePage(null, 0);
                resolve();
              })
              .catch((error: any) => {
                let msg = 'Failed to update identifier';
                if (error && error.status === 403) {
                  msg = NO_PERMISSIONS_ERROR_MSG;
                } else if (error && error.status === 500) {
                  msg = ERROR_500;
                }
                props.showToast({ message: msg, options: ToastError });
              });
          }
          resolve();
        }, 1000);
      });
  };

  

  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getUnknownIdentifiersReport(
        sb.business_id,
        null,
        null,
        tableQuery.search,
        true ? totalRecords : false,
        sb.orderBy,
        selectedFilters,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'Unknown_Identifiers.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  const userBasedStatusFilters = isSuperUser ? statusFilters : nonSuperUserFilters;


  const renderTable = () => {
    return (
      <Container maxWidth={false}>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <ExportStatusBar isVisible={isExporting} />
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{
                    display: 'inline-flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    paddingBottom: 8,
                    textAlign: 'initial'
                  }}>
                    <Typography style={{ paddingTop: 8 }}>{emptyDataSourceMessage}</Typography>
                    {!isErrorStatusVisible && emptyDataSourceMessage !== ERROR_MSG &&
                      <>
                        <Typography style={{ paddingTop: 8 }}>1. Verify the Unknown Product Lookup external system call exists. </Typography>
                        <Typography style={{ paddingTop: 8, paddingBottom: 8 }}>2. Verify the external system call is linked to the POS profile. </Typography>
                      </>}
                  </div>)
              },
            }}
            //@ts-ignore
            columns={columns}
            data={(query) =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  const sb = props.userData.selectedBusiness;
                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as UnknownIdentifier[],
                      totalCount: 0,
                      page: query.page,
                    };
                    setLoading(true);
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    }
                    let totalRecsAvailable = totalRecords !== null ?  true : false
                    getUnknownIdentifiersReport(
                      sb.business_id,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      totalRecsAvailable,
                      orderBy,
                      selectedFilters,
                      false
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: PaginatedResponse<UnknownIdentifier>) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(UNKNOWN_IDENTIFIER_EMPTY_ROW_MSG);
                        if(!totalRecords){
                          setTotalRecords(data.count)
                        }
                        pageData = {
                          data: data.results,
                          totalCount:  !totalRecords ? data.count : totalRecords,
                          page: query.page,
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount:  !totalRecords ? data.count : totalRecords,
                          page: query.page,
                          pageSize: query.pageSize,
                          search: query.search,
                        });
                        setLoading(false);
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if (
                            error.status === 403 ||
                            error.status === 401
                          ) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize,
                          search: query.search,
                        });
                        setLoading(false);
                        resolve(pageData);
                      });
                  }
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Unknown Identifiers'}
                icon={SVG_ICONS.labeler}
                showHelp={true}
                helpText={
                  <div>
                    <Typography variant="body1" style={{ padding: '4px 8px' }}>
                      {`This report lists all codes that have been scanned at the selected location, that did not have a product attached to the code at the time of scanning. 
You may attach these codes to an existing or new product as appropriate, and subsequently mark the record as processed.
Incorrect entries or typos may be processed right away.
Default view only displays Unprocessed records, use the filters to change the view.`}
                    </Typography>
                    <div className={classes.helpTooltipRow}>
                      <Typography className={classes.helpTooltipText}>
                        Processed
                      </Typography>
                      <SvgIcon className={classes.greenCircle}>
                        <path d={SVG_ICONS.blankCircle} />
                      </SvgIcon>
                    </div>
                    <div className={classes.helpTooltipRow}>
                      <Typography className={classes.helpTooltipText}>
                        Unprocessed
                      </Typography>
                      <SvgIcon className={classes.redCircle}>
                        <path d={SVG_ICONS.blankCircle} />
                      </SvgIcon>
                    </div>
                  </div>
                }
              />
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => handleRefresh(),
              },
              {
                icon: 'filter_list',
                tooltip: 'Filter By Status',
                isFreeAction: true,
                onClick: handleFilterBtnClick,
                iconProps: { color: 'primary' },
              },
            ]}
            editable={tableEditableProp}
          />
          <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />
          <Menu
            id="filter-menu"
            anchorEl={filterBtnAnchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(filterBtnAnchorEl)}
            onClose={() => handleFilterMenuClose(null)}
          >
            <MenuItem disabled={true}>Show codes with status:</MenuItem>
            {userBasedStatusFilters.map((f) => {
              // console.log("FF",f)
              const filters = []
              // console.log("SELECTED", selectedFilters)
              const selected = selectedFilters.find(selectedFilter => selectedFilter.id === f.id) ? true : false
              return (
                <MenuItem
                  key={`filter-${f.id}`}
                  style={{ color: selected ? theme.palette.primary.main : 'inherit' }}
                  onClick={() => handleFilterMenuClose(f)}
                  selected={selected}
                >
                {f.label}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </Container>
    );

  }
  return (
    <div>
      {renderTable()}
    </div>
  )

};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnknownIdentifierHistoryScreen);
