import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { PaginatedResponse } from '../../../shared/models';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { API_URLS } from '../../api';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { SupplierEntryMultiplesInSameItem } from '../../models';
import { getSupplierEntryMultiplesInSameItem } from '../../api';

import {
	CustomTableTitle,
	ErrorStatusBar,
	ExportMenu,
	ExportStatusBar,
} from '../../../shared/components';
import download from 'downloadjs';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({

	}),
);

const SupplierEntryMultiplesInSameItemScreen = (props: any) => {
	const classes = useStyles({});
	const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
	const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
	const tableRef = useRef<{ onQueryChange: any; }>();
	let locationQuery = useQuery(useLocation);
	const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
	const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
	const [isExporting, setExporting] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [initialLoad,setInitialLoad]=useState(true);
  const [totalRecords, setTotalRecords] = useState(null as any)


	useEffect(() => {
		if (!isLoading && !initialLoad) {
			tableRef && tableRef.current && tableRef.current.onQueryChange();
			setTotalRecords(null)
		}
	}, [props.userData.selectedBusiness]);

	const handleRefresh= () => {
    setTotalRecords(null)
    tableRef.current && tableRef.current.onQueryChange()
  }

	const exportCSV = () => {
		if (props.userData && props.userData.selectedBusiness) {
			setExporting(true);
			const sb = props.userData.selectedBusiness;
			const shouldExport = true;
			getSupplierEntryMultiplesInSameItem(
				sb.business_id,
				null,
				null,
				tableQuery.search,
				true ? totalRecords : false,
				sb.orderBy,
				null,
				shouldExport
			)
				.catch(handleError) // handle network issues
				.then(checkStatus)
				.then((res) => res.blob())
				.then((data) => {
					download(data, 'Supplier_entry_multiples_in_same_item.csv', 'text/csv');
					setExporting(false);
					setInitialLoad(false);
				})
				.catch((error: any) => {
					setExporting(false);
					if (error) {
						if (error.status && error.status === 500) {
							setEmptyDataSourceMessage(ERROR_MSG);
							setErrorStatusVisible(false);
						} else if (error.status === 403 || error.status === 401) {
							setEmptyDataSourceMessage(ERROR_MSG);
							setErrorStatusVisible(true);
						} else {
							setEmptyDataSourceMessage(ERROR_MSG);
							setErrorStatusVisible(false);
						}
					}
				});
		}
	};


	const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setExportBtnAnchorEl(event.currentTarget);
	};


	const handleExportMenuClose = (shouldExport: boolean) => {
		if (shouldExport) {
			exportCSV();
		}
		setExportBtnAnchorEl(null);
	};


	return (
		<Container maxWidth={false}>
			<ErrorStatusBar isVisible={isErrorStatusVisible} />
			<ExportStatusBar isVisible={isExporting} />

			<div className="table-wrapper">
				<MaterialTable
					options={{
						...tableOptions(tableQuery),
						thirdSortClick: false,
						draggable: false,
						sorting: true
					}}
					tableRef={tableRef}
					localization={{
						body: {
							emptyDataSourceMessage: emptyDataSourceMessage
						}
					}}
					columns={[
						{
						    title: 'Item Number',
						    field: 'item_number',
						    render: rowData => {
						      if (rowData && rowData.item_number) {
						        return <Typography style={{ minWidth: 80 }} >{rowData.item_number}</Typography>
						      }
						      return <div></div>;
						    },
						  },
						{
							title: 'Item Name',
							field: 'item_name',
							render: rowData => {
								if (rowData && rowData.item_name) {
									return <Typography style={{ minWidth: 270 }} >{rowData.item_name}</Typography>
								}
								return <div></div>;
							},
						},
						{
							title: 'Supplier Name',
							field: 'supplier_name',
							render: rowData => {
								if (rowData && rowData.supplier_name) {
									return <Typography style={{ minWidth: 270 }} >{rowData.supplier_name}</Typography>
								}
								return <div></div>;
							},
						},
						{
							title: 'Case Qtys',
							field: 'case_qtys',
							render: rowData => {
								if (rowData && rowData.case_qtys) {
									return <Typography style={{ minWidth: 150 }} >{rowData.case_qtys}</Typography>
								}
								return <div></div>;
							},
						},
						{
							title: 'Duplicate Reorder Codes',
							field: 'dup_reorder_codes',
							render: rowData => {
								if (rowData && rowData.dup_reorder_codes) {
									return <Typography style={{ minWidth: 250 }} >{rowData.dup_reorder_codes}</Typography>
								}
								return <div></div>;
							},
						},

					]}
					data={(query) =>
						new Promise((resolve, reject) => {
							replaceHistory(query, props.history);
							let pageData = {
								data: [] as SupplierEntryMultiplesInSameItem[],
								totalCount: 0,
								page: query.page
							};
							if (props.userData && props.userData.selectedBusiness) {
								const sb = props.userData.selectedBusiness;
								let orderBy = '';
								if (query.orderBy) {
									orderBy = query.orderBy.field as string;
									orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
								}
								let totalRecsAvailable = totalRecords !== null ?  true : false
								getSupplierEntryMultiplesInSameItem(
									sb.business_id,
									query.page + 1,
									query.pageSize,
									query.search,
									totalRecsAvailable,
									orderBy,
									null,
									false
								)
									.catch(handleError) // handle network issues
									.then(checkStatus)
									.then(parseJSON)
									.then((data: PaginatedResponse<SupplierEntryMultiplesInSameItem>) => {
										setErrorStatusVisible(false);
										setEmptyDataSourceMessage(EMPTY_ROW_MSG);
										if(!totalRecords){
                      setTotalRecords(data.count)
                    }
										pageData = {
											data: data.results,
											totalCount: !totalRecords ? data.count : totalRecords,
											page: query.page
										};

										setTableQuery({
											...tableQuery,
											orderBy,
											totalCount: !totalRecords ? data.count : totalRecords,
											page: query.page,
											pageSize: query.pageSize
										});
										// console.log(pageData.data)
										resolve(pageData);
										setInitialLoad(false);
										// console.log(pageData.data)
									})
									.catch((error: any) => {
										if (error) {
											if (error.status && error.status === 500) {
												setEmptyDataSourceMessage(ERROR_500);
												setErrorStatusVisible(false);
											} else if ((error.status === 403 || error.status === 401)
											) {
												setEmptyDataSourceMessage(ERROR_MSG);
												setErrorStatusVisible(true);
											} else {
												setEmptyDataSourceMessage(ERROR_MSG);
												setErrorStatusVisible(false);
											}
										}
										setTableQuery({
											...tableQuery,
											totalCount: 0,
											page: query.page,
											pageSize: query.pageSize
										});
										resolve(pageData);
									});
							}
						})
					}
					title={
						<CustomTableTitle
							history={props.history}
							title={'Supplier Entry Multiples In Same Item'}
							icon={SVG_ICONS.product} />
					}
					actions={[
						{
							icon: 'save_alt',
							tooltip: 'Export',
							isFreeAction: true,
							onClick: handleExportClick,
							disabled: isExporting,
						},
						{
							icon: 'refresh',
							tooltip: 'Refresh Data',
							isFreeAction: true,
							onClick: () => tableRef.current && tableRef.current.onQueryChange(),
						},
					]}
				/>
				<ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

			</div>
		</Container>
	);
};


const mapStateToProps = (state: any) => {
	return {
		userData: state.userLocalData,
	};
};



const mapDispatchToProps = (dispatch: any) => {
	return {
		getBusinessList: () => dispatch(getBusinessListFromAPI())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierEntryMultiplesInSameItemScreen);








