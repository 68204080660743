
import { combineReducers, Action } from 'redux';
import {
    GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS,
    GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_SUCCESS,
    GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_FAILURE,
    SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS,
    SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_FAILURE,
    SEARCH_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_SUCCESS
} from '../actions/actionTypes';
import { MasterProduct, MasterProductWithoutIdentifier } from '../../models';
import { PaginatedResponse } from '../../../shared/models';


export interface InitialState {
    data: PaginatedResponse<MasterProduct>,
    masterProductList: MasterProductWithoutIdentifier[],
    isFetching: boolean,
    success: boolean,
    error: boolean,
    errorData: any;
}

const initialState: InitialState = {
    data: {
        count: 0,
        results: [],
        next: null,
        previous: null
    } as PaginatedResponse<MasterProduct>, // products
    masterProductList: [] as MasterProductWithoutIdentifier[],
    isFetching: false,
    success: false,
    error: false,
    errorData: null
};


export function masterProductWithoutIdentifierListReducer(state = initialState, action: any) {
    let nextProducts = [];
    let oldList = [];
    let masterProductList = [];
    switch (action.type) {
        case GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS: return {
            ...state,
            isFetching: true,
            success: false,
            error: false,
            errorData: null
        };
        case GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_SUCCESS:
            nextProducts = [...action.data.results];
            oldList = [];
            console.log('reducer resetlist ', action.resetList);
            if (!action.resetList) {
                oldList = [...state.masterProductList];
            }
            masterProductList = oldList.concat(nextProducts);
            console.log('masterProductList', masterProductList);
            return {
                ...state,
                isFetching: false,
                data: action.data,
                masterProductList: masterProductList,
                success: true,
                error: false,
                errorData: null
            };
        case GET_MASTER_PRODUCT_WITHOUT_IDENTIFIERS_FAILURE: return {
            data: {
                count: 0,
                results: [],
                next: null,
                previous: null
            },
            masterProductList: [],
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        };
        default: return state;
    }
}

