import React, { useState, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { RetailerRoutes, SupplierRoutes } from '../../Routes/Routes';

const doesRouteExist = (location: Location, user: { isSupplier: boolean; }) => {
  if (location && location.pathname && user) {
    if (user.isSupplier) {
      return SupplierRoutes.find(r => r.path === location.pathname);
    }
    return RetailerRoutes.find(r => r.path === location.pathname);
  }
  return false;
};


const PageRedirect = (props: any) => {
  const routeExists = doesRouteExist(props.location, props.user);
  const [delay, setDelay] = useState(5);
  let timerId: any;
  const theme = useTheme();

  useEffect(() => {
    timerId = setTimeout(() => {
      if (delay === 0) {
        clearTimeout(timerId);
      } else {
        setDelay(delay - 1);
      }
    }, 1000);
    return () => clearTimeout(timerId);
  }, [delay]);

  return (
    <div className="w-100 text-center"
      style={{
        padding: '4rem',
        backgroundColor: props.showBackground ? '#fbfbfb' : undefined,
        height: props.showBackground ? '100%' : 'inherit'
      }}>
      {
        routeExists ?
          <div>
            <Typography variant='h2'>403</Typography>
            <Typography variant='h4'>Unauthorized!</Typography>
          </div>
          : <div>
            <Typography variant='h2'>404</Typography>
            <Typography variant='h4'>Page not found!</Typography>
          </div>
      }
      <Typography variant='h5'
        style={{ marginTop: '2rem' }}>Redirecting in
        <span style={{ color: theme.palette.primary.main }}>{` ${delay} `}</span>
        seconds...</Typography>
      {delay === 0 && <Redirect to="/web/dashboard" />}
    </div>
  );
};

export default PageRedirect;