import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { 
  Button, CircularProgress, Dialog ,DialogActions, DialogContent, DialogTitle, DialogContentText, Typography, 
  Paper, Tooltip, TextField, FormControl, FormControlLabel, RadioGroup, Radio, Snackbar
  
} from '@material-ui/core'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { MappingSuggestion, MasterProduct } from '../../../master-data/models';
import { Product } from '../../../products/models';
import {  LocationBusiness, ToastType } from '../../../shared/models';
import { BusinessCompanySupplierLinkRecord } from '../../../master-data/models';
import composeRequest from '../../../shared/api/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { API_URLS as MP_API_URLS } from '../../../master-data/api';
import { LinkProduct } from '../../../master-data/components';
import { postUpdateProducts } from '../../../stockOrder/api';
import {  API_URLS as SO_API_URLS , getMatchedInvoiceItemList, changeSORocTempRec } from '../../api';
import cloneDeep from 'lodash/cloneDeep';
import { updateStockOrderProductInList } from '../../data/actions';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    root: {
      backgroundColor: '#F3F5F7'
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px 16px',
      alignItems: 'center'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelHeaderText: {
      fontSize: '0.95rem'
    },
  }),
);

const initalDialogData = {
  open: false,
  business: null as null | { business_id: number, business_name: string; },
  onClose: null as any,
  mode: 'add',
  linkedRecord: null as any,
  suggestions: [] as MappingSuggestion[] | undefined,
  invoiceItemDescription: null as string | null,
  supplierProduct: null as | Product | undefined | null,
  
};

const defaultFinishAction = {
  label: 'Next',
  action: (selectedBusinessProduct: any, selectedMasterProduct: any, invoiceItem: any) => { }
};


let value = '' // global value used to store business type i.e supplier or retailer used for reorder code issue.

const FixInvoiceItemModal = (props: any) => {
  console.log(props);
  const classes = useStyles({});
  const [open, setOpen] = useState(props.isOpen);
  const [invoiceItem, setInvoiceItem] = useState(null);
  const [selectedBusinessProduct, setSelectedBusinessProduct] = useState(null);
  const [selectedMasterProduct, setSelectedMasterProduct] = useState(null);
  const [selectedNewMasterProduct, setNewSelectedMasterSupplier] = useState(null);
  const [linkProductData, setLinkProductData] = useState(initalDialogData);
  const [allIssuesResolved, setAllIssuesResolved] = useState(false);
  const [dialogFinishAction, setDialogFinishAction] = useState(defaultFinishAction);

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);
  const theme = useTheme();
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValueSelected, setSelectedValueSelected] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [supplierInfoSend, setSupplierInfoSend] = useState(false);

  useEffect(() => {
    if (props.invoiceItem) {
      setInvoiceItem(props.invoiceItem);
      setModalContent(props.invoiceItem);
      if(props.invoiceItem &&  props.invoiceItem.error !==  null && props.invoiceItem.error_type !== null ){
        setAllIssuesResolved(false)

      }
    }

  }, [props.invoiceItem]);

  useEffect(() => {
    getSelectedBusiness();
    value = selectedValue;

  }, [selectedValue]);

  const getSelectedBusiness = () => {
    setSelectedBusiness(selectedValue);
  }



  const isModalFullWidth = () => {
    if (linkProductData && linkProductData.mode) {
      const { mode } = linkProductData;
      if (mode === 'add' || mode === 'link_business_product') {
        return true;
      }
    }
    return false;
  };
  const getModalWidth = () => {
    if (linkProductData && linkProductData.mode) {
      const { mode } = linkProductData;
      if (mode === 'add') {
        return 'md';
      } else if (mode === 'link_business_product') {
        return 'lg';
      }
    }
    return 'sm';
  };


  const getIssueMesage = () => {
    if (invoiceItem) {
      switch (invoiceItem.error_type) {
        case 'link_business_product':
          return ' Please create a mapping for the Korona Product.';
        
        case 'supplier_product':
          const hasReorderCode = invoiceItem.reorderCode ? true : false;
          if (!hasReorderCode) {
            return ' The Supplier Product could not be found by Reorder Code nor by Invoice Item Text. Click Next to add the new product';
          }
          return ' The Supplier Product could not be found by Invoice Item Text. Click Next to add the new product';
        
        case 'link_supplier_product':
          return ' Please create a mapping for the supplier product.';
        
        case 'supplier_code_or_quantity_missing':
          return 'Supplier Code or Quantity is Missing.Please Check in Korona';
       
        case 'different_quantity_same_reorder_code':
          return 'Quantity on Retailer side is different, Please choose from supplier quantity or retailer quantity to proceed further'
        
        case 'different_reorder_code_same_quantity':
          return 'Please choose from supplier Info or retailer Reorder Info to proceed further'
      
        case 'supplier_record_missing':
          return 'Supplier Record is missing on retailer side, New entry will be created on Korona'
        
        case 'different_quantity_different_reorder_code':
          return 'Different reorder code & different quantity for supplier on retailer side, New entry will be created on Korona'
        
        case 'link_supplier_missing':
          return `Supplier Business has not been linked to Retailer's supplier record`
        
        case `supplier_product_not_approved`:
          return `This is a new product added by your businesss. Its still not verified.Please contact Konnect Support`
        
        case `different_reorder_code_same_quantity_rtl`:
          return `Please choose a record from retailer Reorder Info to proceed further`
        // case `cost_diff`:
        //   return `There is a difference is price,imported price and CODE/PLU will be updated in Korona`
      } 
    }
    return '';
  };

  const multipleRtlSameRocRecs =() =>{
    
    if(invoiceItem.rtl_multi_supp_recs && invoiceItem.rtl_multi_supp_recs.length > 1){
      return invoiceItem.rtl_multi_supp_recs.map((item, index) => {
        return (<FormControlLabel  key={String(index)} value={`${item}`} control={<Radio />} label={`Retailer's Product Reorder Code : ${item}, Product Quantity : ${invoiceItem.retailer_quantity}, Cost : ${invoiceItem.costPerCase}`} /> )
      })
    }
  }

  const getDropDownChanges = () => {

    if (invoiceItem.rtl_multi_supp_recs && invoiceItem.rtl_multi_supp_recs.length > 1) {
      return (
        <div>
          <Paper>
            <div>
              <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
                Select any one of the following :
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="select" value={selectedValue} onChange={handleChange}>
                  {multipleRtlSameRocRecs()}
                </RadioGroup>
              </FormControl>
            </div>
          </Paper>
        </div>
      )
    }
    else {
      return (
        <div>
          <Paper>
            <div>
              <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
                Select any one of the following :
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="select" value={selectedValue} onChange={handleChange}>
                  <FormControlLabel value='Supplier' control={<Radio />} label={`Supplier's Product Reorder Code : ${invoiceItem.supplier_code}, Product Quantity : ${invoiceItem.supplier_quantity}, Cost : ${invoiceItem.costPerCase}`} />

                  <FormControlLabel value='Retailer' control={<Radio />} label={`Retailer's Product Reorder Code : ${invoiceItem.retailer_reorder_code} , Product Quantity : ${invoiceItem.retailer_quantity}, Cost : ${ invoiceItem.costPerCase} `} />

                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <Typography style={{ paddingRight: 10, color: GlobalStyles.red }}>
                NOTE :
              </Typography>
            </div>
            <div>
              {invoiceItem.error_type === 'different_reorder_code_same_quantity' ?
                <Typography style={{ paddingRight: 10, color: '#cd2737' }}>
                - Choosing Supplier - Supplier Info on retailer's product will be changed on korona on hitting the NEXT button. The existing retailer's supplier's info will be retained and additional entry will be created for chosen supplier info.
                </Typography> :
                <Typography style={{ paddingRight: 10, color: '#cd2737' }}>
                - Choosing Supplier - Supplier Info on retailer's product will be changed on korona on hitting the NEXT button.
                </Typography>
              }
            </div>
            <div>
              <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryDark }}>
                - Choosing Retailer - Existing Supplier Info on retailer's product will be used on hitting the NEXT button. No change to retailer's product supplier info is made
              </Typography>
            </div>
          </Paper>
        </div>
      )
    }
  }

  const sendSupplierInfoToRetailer = () => {
    return (
      <div>
        <Paper>

          <div>
            <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
              Verify and select supplier to update on korona :
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup aria-label="select" value={selectedValue} onChange={handleChange}>
                <FormControlLabel value='Supplier' control={<Radio />} label={`Supplier's Product Reorder Code : ${invoiceItem.reOrderCode}, Product Quantity : ${invoiceItem.master_supplier_container_qty}, Cost : ${invoiceItem.costPerCase}`} />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <Typography style={{ paddingRight: 10, color: GlobalStyles.red }}>
              NOTE :
            </Typography>
          </div>
          <div>
            <Typography style={{ paddingRight: 10, color: '#cd2737' }}>
              - Choosing Supplier - Retailer's supplier Info will be changed on db and on korona on hitting the NEXT button.
            </Typography>
          </div>
        </Paper>
      </div>
    )

  }


  const setModalContent = (invoiceItem: any) => {
    if (invoiceItem) {
      console.log('setModalContent', invoiceItem);
      let dialogData = { ...initalDialogData };
      let { id, name } = props.selectedSupplier.master_company;
      if (!invoiceItem.error_type && !invoiceItem.error) {
        setAllIssuesResolved(true);
        setLinkProductData(dialogData)
        setDialogFinishAction({
          label: 'Finish',
          action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => handleCloseFixItem(invoiceItem)
        });
      }
      switch (invoiceItem.error_type) {
        case 'link_business_product':
          dialogData.suggestions = invoiceItem.suggestions;
          dialogData.open = true;
          const sb = props.userData.selectedBusiness;
          dialogData.business = sb;
          dialogData.invoiceItemDescription = invoiceItem.supplierProductName;
          dialogData.mode = 'link_business_product';
          dialogData.linkedRecord = {
            business: id,
            master_product: invoiceItem.supplier_master_product,
            business_product: null
          };
          dialogData.supplierProduct = invoiceItem.supplier_product;
          setLinkProductData(dialogData);
          setDialogFinishAction({
            ...dialogFinishAction,
            action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => createBusinessProductLinkRecord(selectedBusinessProduct, selectedMasterProduct, invoiceItem)
          });
          break;
        case 'supplier_product':
          setLinkProductData(dialogData);
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => createNewProductTempLinkRecord(invoiceItem)
          });
          break;
        case 'link_supplier_product':
          dialogData.open = true;
          dialogData.business = { business_id: id, business_name: name };
          dialogData.invoiceItemDescription = invoiceItem.supplierProductName;
          dialogData.mode = 'link_supplier_product';
          dialogData.linkedRecord = {
            business: id,
            master_product: null,
            business_product: invoiceItem.supplier_product
          };
          dialogData.suggestions = invoiceItem.suggestions;
          dialogData.supplierProduct = null;
          setLinkProductData(dialogData);
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => createBusinessProductLinkRecord(selectedBusinessProduct, selectedMasterProduct, invoiceItem)
          });
          break;
        case 'supplier_code_or_quantity_missing':
          dialogData.open = true;
          dialogData.suggestions = invoiceItem.suggestions;
          break;

        case 'link_supplier_missing':
          dialogData.open = true;
          dialogData.suggestions = invoiceItem.suggestions;
          break;

        case 'different_quantity_same_reorder_code':
          dialogData.open = true;
          dialogData.suggestions = invoiceItem.suggestions;
          setShowDropDown(true)
          setAllIssuesResolved(false);
          //selected_business=
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusiness, selectedValue, invoiceItem) => {
              // console.log("selected value", selectedValue)
              // console.log("selected business", selectedBusiness)
              // console.log("value", value)
              if (value === 'Supplier') {
                const create_new_entry_for_supplier = false;
                UpdateQuantityOnKorona(invoiceItem, create_new_entry_for_supplier, invoiceItem.master_company_exists)

              }
              else if (value == 'Retailer') {
                setLoading(true)
                invoiceItem.reOrderCodeOfRetailerToSend = true;
                invoiceItem.error = '';
                invoiceItem.error_type = '';
                setSelectedValue('')
                props.errorSuccess(true)
                props.setInvoiceItemModaldata(cloneDeep(props.fixDialogData))

              }
              setTimeout(props.onClose(), 4000);
              setLoading(false);
            }
          });
          break;

        case 'different_reorder_code_same_quantity_rtl':
          // Need to finish
            dialogData.open = true;
            dialogData.suggestions = invoiceItem.suggestions;
            setShowDropDown(true)
            setAllIssuesResolved(false);
            //selected_business=
            setDialogFinishAction({
              label: 'Next',
              action: (selectedBusiness, selectedValue, invoiceItem) => {
                if (value != null) {
                  updateSelectedRocTempRec(invoiceItem, value)
                }
              }
            });
            break;

        case 'different_reorder_code_same_quantity':
          dialogData.open = true;
          dialogData.suggestions = invoiceItem.suggestions;
          setShowDropDown(true)
          setAllIssuesResolved(false);
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusiness, selectedValue, invoiceItem) => {
              // console.log("selected value", selectedValue)
              // console.log("selected business", selectedBusiness)
              // console.log("value", value)
              if (value === 'Supplier') {
                const create_new_entry_for_supplier = false;
                UpdateQuantityOnKorona(invoiceItem, create_new_entry_for_supplier, invoiceItem.master_company_exists)

              }
              else if (value == 'Retailer') {
                invoiceItem.reOrderCodeOfRetailerToSend = true;
                invoiceItem.error = '';
                invoiceItem.error_type = '';
                setShowDropDown(false)
                setSelectedValue('');
                props.errorSuccess(true)
                setLoading(false);
                setTimeout(props.onClose(), 4000);
                props.setInvoiceItemModaldata(cloneDeep(props.fixDialogData))

              }
              console.log(invoiceItem)
            }
          });
          break;


        case 'supplier_record_missing':
          dialogData.open = true;
          dialogData.suggestions = invoiceItem.suggestions;
          setSupplierInfoSend(true)
          setAllIssuesResolved(false);
          //selected_business=
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusiness, selectedValue, invoiceItem) => {
              // console.log("selected value", selectedValue)
              // console.log("selected business", selectedBusiness)
              // console.log("value", value)
              if (value === 'Supplier') {
                const create_new_entry_for_supplier = true;
                UpdateQuantityOnKorona(invoiceItem, create_new_entry_for_supplier, invoiceItem.master_company_exists)

              }
            }
          });
          break;


        case 'different_quantity_different_reorder_code':
          dialogData.open = true;
          dialogData.suggestions = invoiceItem.suggestions;
          setSupplierInfoSend(true)
          setAllIssuesResolved(false);
          //selected_business=
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusiness, selectedValue, invoiceItem) => {
              console.log("OK")
              console.log("selected value", selectedValue)
              console.log("selected business", selectedBusiness)
              console.log("value", value)
              const create_new_entry_for_supplier = true;
              if (value === 'Supplier') {
                UpdateQuantityOnKorona(invoiceItem, create_new_entry_for_supplier, invoiceItem.master_company_exists);
                
              }
            }
          });
          break;


        case 'supplier_product_not_approved':
          setLinkProductData(dialogData);
          setDialogFinishAction({
            label: 'Next',
            action: (selectedBusinessProduct, selectedMasterProduct, invoiceItem) => {console.log(invoiceItem)}
          });
          break;


      }
    }
    return null;
  };

  const handleCloseFixItem = (item?: any) => {
    props.onClose && props.onClose();
    setAllIssuesResolved(false);
  }


  const createBusinessProductLinkRecord = (selectedBusinessProduct: any, selectedMasterProduct: any, invoiceItem: any) => {
    console.log('createBusinessProductLinkRecord called');
    if (selectedBusinessProduct && selectedMasterProduct) {
      let URL = null as any;
      let data ={}
      
      if(invoiceItem.master_rtl_supplier_link){
        URL = MP_API_URLS.masterProductLinkUrl;
        data = {
          business: selectedBusinessProduct.business,
          master_product: selectedMasterProduct.id,
          business_product: selectedBusinessProduct.uuid
        };
      }else{
        URL = SO_API_URLS.tempSOCustomerProductLinkUrl ;

        data = {
          business: selectedBusinessProduct.business,
          business_product: selectedBusinessProduct,
          master_company_id : props.selectedSupplier.master_company.id,
          user_auth_token: props.userData.userData.userData.auth_token,
          productDetails :invoiceItem,
        };
      }
      
      setLoading(true);
      composeRequest(URL, 'POST', JSON.stringify(data))
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          setLoading(false);
          setToast({
            open: true,
            message: 'Product Linked successfully!',
            variant: 'success'
          });
          matchInvoiceItem(cloneDeep(invoiceItem));
          setSelectedBusinessProduct(null);
          setSelectedMasterProduct(null);
          setShowDropDown(false);
          setSupplierInfoSend(false);
          setSelectedValue('')
          setLinkProductData(cloneDeep(initalDialogData));
          props.setInvoiceItemModaldata(cloneDeep(props.fixDialogData))

        })
        .catch((error: any) => {
          setLoading(false);
          let msg = 'Failed to map product.';
          if (error && error.status === 403) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error && error.status === 500) {
            msg = ERROR_500;
          }
          setToast({
            open: true,
            message: msg,
            variant: 'error'
          });
        });
    }
  };


  const createNewProductTempLinkRecord = (invoiceItem: any) => {
    console.log('NewProductTempLinkRecord called');
    if (invoiceItem) {
      // const URL = SO_API_URLS.tempDNNewProductLinkUrl;
      const URL = ''
      const data = {
        business: props.userData.selectedBusiness.business_id,
        master_company_id: props.selectedSupplier.master_company.id,
        supplier_business_id : null,
        user_auth_token: props.userData.userData.userData.auth_token,
        productDetails :invoiceItem,
        file_name : props.selectedFile && props.selectedFile.name
      };
      setLoading(true);
      composeRequest(URL, 'POST', JSON.stringify(data))
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          setLoading(false);
          setSelectedBusinessProduct(null);
          setSelectedMasterProduct(null);
          setShowDropDown(false);
          setSupplierInfoSend(false);
          setSelectedValue('')
          setLinkProductData(cloneDeep(initalDialogData));
          props.setInvoiceItemModaldata(cloneDeep(props.fixDialogData))
          setToast({
            open: true,
            message: 'Product Added successfully!',
            variant: 'success'
          });
          matchInvoiceItem(invoiceItem);
          setTimeout(props.onClose(), 2000);
        })
        .catch((error: any) => {
          setLoading(false);
          let msg = 'Failed to map product.';
          if (error && error.status === 403) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error && error.status === 500) {
            msg = ERROR_500;
          }
          setToast({
            open: true,
            message: msg,
            variant: 'error'
          });
        });
    }
  };

  const updateSelectedRocTempRec = (invoiceItem: any, selectedRoc:any) =>{
    setLoading(true)
    console.log("INVOICE ITEM", invoiceItem);
    let payload = { 
      "invoiceItem" : invoiceItem,
      "selectedRoc" : selectedRoc,
      "master_company_id": props.selectedSupplier.master_company.id,
      "korona_supplier_business_id": null,
      "business": props && props.userData && props.userData.selectedBusiness ? props.userData.selectedBusiness.business_id : null
    }

    changeSORocTempRec(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        let updatedItem = cloneDeep(invoiceItem)
        updatedItem.reOrderCodeOfRetailerToSend = false;
        updatedItem.error = '';
        updatedItem.error_type = '';
        setSelectedValue('');
        props.updateMatchedItem(updatedItem)
        setLoading(false);
        setShowDropDown(false)
        props.errorSuccess(true)
        setLinkProductData(cloneDeep(initalDialogData))
        props.setInvoiceItemModaldata(cloneDeep(props.fixDialogData))
        setTimeout(props.onClose(), 2000);


      }).catch((error: any) => {
        setLoading(false);
        setSelectedValue('');
        let msg = 'Failed to update.';
        if (error && error.status === 403) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        } else if (error && error.status === 500) {
          msg = ERROR_500;
        }
        else if (error.status && error.status === 409) {
          msg = `Same Code and Quantity Already Exists`
        }
        setToast({
          open: true,
          message: msg,
          variant: 'error',

        });

      });



  }

  const UpdateQuantityOnKorona = (invoiceItem: any, create_new_entry_for_supplier, master_company_exists) => {// create_new_entry_for_supplier is flag when set to true will create another entry with same product id, used on case different_code_different_quantity
    setLoading(true);
    console.log("INVOICE ITEM", invoiceItem);
    const data = invoiceItem
    console.log("data", data);


    const payload = {
      fromDN: true,
      master_company_id : props.selectedSupplier.master_company.id,
      korona_supplier_business_id: null,
      data: invoiceItem,
      create_new_record_for_supplier: create_new_entry_for_supplier,
      master_company_exists: master_company_exists,
    }
    if (master_company_exists === true) {
      payload['master_company'] = invoiceItem['master_company']
    }


    postUpdateProducts(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        invoiceItem.error = '';
        invoiceItem.error_type = '';
        props.errorSuccess(true)
        setSelectedValue('')
        setShowDropDown(false)
        setSupplierInfoSend(false)
        setTimeout(props.onClose(), 6000);
        setLoading(false);
        props.setInvoiceItemModaldata(cloneDeep(props.fixDialogData))

      }).catch((error: any) => {
        setLoading(false);
        setSelectedValue('');
        // if(error.status===409){
        //   alert("Same OrderCode and Quantity already exists on Retailer")
        // }
        let msg = 'Failed to update.';
        if (error && error.status === 403) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        } else if (error && error.status === 500) {
          msg = ERROR_500;
        }
        else if (error.status && error.status === 409) {
          msg = `Same Code and Quantity Already Exists`
        }
        setToast({
          open: true,
          message: msg,
          variant: 'error',

        });

      });

  };


  const matchInvoiceItem = (invoiceItem: any) => {
    const item = { ...invoiceItem };
    ////    delete these keys for a new response from api;
    delete item.error_type;
    delete item.error;
    delete item.suggestions;
    item.product_edited_or_updated = false
    console.log('matchInvoiceItem', invoiceItem);
    const payload = {
      stockOrderItems: [item],
      business_id: props.selectedSupplier.business.id,
      master_company_id:props.selectedSupplier.master_company.id,
      korona_supplier_business_id: null ,
      user_auth_token:props.userData.userData.userData.auth_token,
      parser_setting_id: props.selectedParserSetting ? props.selectedParserSetting.id : ''

    };
    setLoading(true);

    getMatchedInvoiceItemList(payload)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      if (data.hasOwnProperty("stockOrderItemList")) {
        if (data['stockOrderItemList'].length === 1) {
          let updatedItem = data['stockOrderItemList'][0]
          console.log('matchInvoiceItem', data[0]);
          setInvoiceItem(updatedItem);
          setModalContent(updatedItem);
          props.updateMatchedItem(updatedItem)
          setLoading(false);
          setToast({
            open: false,
            message: '',
            variant: 'success'
          });
          setTimeout(props.onClose(), 2000);
          setLinkProductData(cloneDeep(initalDialogData))
        }
      }
    })
    .catch((err: any) => {
      setLoading(false);
    });

  };

  const addLinkRecord = (suggestion: any, business_product: any) => {
    setSelectedBusinessProduct(business_product);
    if (suggestion.master_product) {
      setSelectedMasterProduct(suggestion.master_product);
    }
  };

  const handleMPSelectionChange = (rows: any) => {
    if (rows.length > 0) {
      let masterSupplier = { ...rows[0] };
      delete masterSupplier['tableData'];
      setSelectedMasterProduct(masterSupplier);
    } else {
      setSelectedMasterProduct(null);
    }
  };

  const handleBPSelectionChange = (rows: any) => {
    if (rows.length > 0) {
      let product = { ...rows[0] };
      delete product['tableData'];
      setSelectedBusinessProduct(product);
    } else {
      setSelectedBusinessProduct(null);
    }
  };

  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setToast({ open: false, message: '', variant: 'success' });
  };

  
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    console.log("Selected Option",event.target.value);
    setSelectedValueSelected(true);
  }


  return (
    <div>
      <Dialog
        fullWidth={isModalFullWidth()}
        maxWidth={getModalWidth()}
        open={props.isOpen}
        onClose={() => props.onClose && props.onClose()} >
        <DialogTitle id="form-dialog-title">
          {  invoiceItem && invoiceItem.error_type && invoiceItem.error_type === 'link_business_product' ?  
            `Incoming Inventory Item not Mapped` :
            `Fix Issues for ${invoiceItem && invoiceItem.supplierProductName ? invoiceItem.supplierProductName : ''}`
          }
        </DialogTitle>
        <DialogContent>
          {
            loading &&
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }
          {
            !allIssuesResolved &&
            <>
              {
                !(invoiceItem && invoiceItem.error_type && invoiceItem.error_type === 'link_business_product') &&
                <div style={{ paddingBottom: 16 }}>
                  <div style={{
                    border: '1px solid #cdcdcd',
                    padding: '8px 16px',
                    borderRadius: 4
                  }}>
                    <Typography variant="body1">{`Current Issue: `}
                      <span style={{ color: theme.palette.error.main }}>{invoiceItem && invoiceItem.error ? invoiceItem.error : ''}</span></Typography>
                  </div>
                </div>
              }
              <div style={{ paddingBottom: 16 }}>
                <div style={{
                  border: '1px solid #cdcdcd',
                  padding: '8px 16px',
                  borderRadius: 4
                }}>
                  <Typography variant="body1">
                    <span>Action: </span>{getIssueMesage()}</Typography>
                </div>
              </div>

              {
                <div>
                  {showDropDown ? getDropDownChanges() : null}
                </div>
              }
              {
                <div>
                  {supplierInfoSend ? sendSupplierInfoToRetailer() : null}
                </div>
              }
            </>
          }
          {
            !showDropDown && !allIssuesResolved && !supplierInfoSend &&
            linkProductData.open &&
            <LinkProduct
              mode={linkProductData.mode}
              business={linkProductData.business}
              linkedRecord={linkProductData.linkedRecord}
              suggestions={linkProductData.suggestions}
              handleMPSelectionChange={handleMPSelectionChange}
              handleBPSelectionChange={handleBPSelectionChange}
              selectedBusinessProduct={selectedBusinessProduct}
              setSelectedBusinessProduct={setSelectedBusinessProduct}
              selectedMasterProduct={selectedMasterProduct}
              setSelectedMasterProduct={setSelectedMasterProduct}
              selectedNewMasterProduct={selectedNewMasterProduct}
              setNewSelectedMasterSupplier={setNewSelectedMasterSupplier}
              addLinkRecord={addLinkRecord}
              supplierProduct={linkProductData.supplierProduct}
              tempLink = {props.invoiceItem && props.invoiceItem.temp_link ? true : false}
              invoiceItem ={invoiceItem}
              selectedSupplier={props.selectedSupplier}
            />
          }
          {
            //!showDropDown && 
            allIssuesResolved &&
            <div style={{ padding: '0 16px', border: '2px solid #cdcdcd', borderRadius: 4 }}>
              <div style={{ display: 'flex', paddingTop: 8, paddingBottom: 8, alignItems: 'center' }}>
                <div style={{ minWidth: 56, display: 'inline-flex' }}>
                  <CheckCircleOutlineIcon
                    style={{
                      color: theme.palette.success.main,
                      fontSize: '2rem'
                    }} />
                </div>
                <Typography style={{ fontSize: '1.4rem' }}>All Issues have been resolved for this item!</Typography>
              </div>
            </div>
          }

          <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
            <Alert onClose={handleCloseToast} severity={toast.variant}>
              {toast.message}
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px' }}>
          {
            !allIssuesResolved ?
              <Button onClick={() => {
                setSelectedBusinessProduct(null);
                setSelectedMasterProduct(null);
                setShowDropDown(false);
                setSupplierInfoSend(false);
                setSelectedValue('')
                props.onClose && props.onClose();

              }}>Cancel</Button> : null
          }
          {
            <Button onClick={(e) => dialogFinishAction.action(selectedBusinessProduct, selectedMasterProduct, invoiceItem)}
              color="primary"
              variant="contained">{dialogFinishAction.label}</Button>
          }

        </DialogActions>
      </Dialog>
    </div >
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateMatchedItem: (invoiceItem: any) => dispatch(updateStockOrderProductInList(invoiceItem)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FixInvoiceItemModal);
