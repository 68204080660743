import { Business } from "../../shared/models";

export interface BusinessQueueTasks{
    accept_incoming:boolean;
    business: Business;
    cron_string: string;
    data_info:string;
    html_template:string;
    id: number;
    mail_bcc_recipient_list:string;
    mail_cc_recipient_list:string;
    mail_recipient_list:string;
    name:string;
    queue: string;
    should_proccess:boolean;
    service_provider:string;
};


export const BusinessQueueTypes =[
    {   
        id: 1,
        type:'korona_db_sync',
        value:'Korona DB Sync'
    },
    {
        id: 2,
        type:'korona_load_older_receipts',
        value:'Korona Load Older Receipts'
    },
    {
        id: 3,
        type:'custom_reports',
        value:'Custom Reports'
    },
    {
        id: 4,
        type:'receipt_index_catch_up',
        value:'Receipt Index Catch Up'
    },
    {
        id: 5,
        type:'es_sync_sales_report',
        value:'ES Sales Data Summary'
    },
    {
        id: 6,
        type:'homesome_sales_report',
        value:'Homesome Sales Report'
    },
    {
        id: 7,
        type:'korona_receipts_only_sync',
        value:'Korona Receipts Only Sync'
    },
    {
        id: 8,
        type:'stock_order_customer_order',
        value:'Stock Order Customer Order'
    },
    {
        id: 9,

        type:'customer_order_stock_receipt',
        value:'Customer Order Stock Receipt'
    },
    {
        id: 10,
        type:'notification_events',
        value:'Notification Events'
    },
]