import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { PaginatedResponse, Location } from '../../../shared/models';
import composeRequest, { checkStatus, parseJSON, handleError } from '../../../shared/api/core';
import {
  Typography, Paper, Stepper, Step, StepLabel, TextField, Button, Dialog, DialogActions,
  DialogTitle, DialogContent, DialogContentText, CircularProgress
} from '@material-ui/core'
import { PageToolbar } from '../../../page/components';
import { ConfirmSupplierAndLocationForDispatchNotification, JsonImport, VerifyImportData, MapInvoiceProduct } from '../../components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { store } from 'web/src/index';
import { uploadInvoicePDf, parsePdf, } from '../../../stockOrder/api';
import { getLinkedKoronaBusinessSuppliers, getLinkedKoronaMasterCompanySuppliers } from '../../../master-data/api';
import { addDN, getDispatchNotificationsInvoiceProductList, parseJsonUrlData, getKoronaSOInfo } from '../../api'
import useWindowDimensions from '../../../shared/hooks/WindowDimensions';
import sortBy from 'lodash/sortBy';
import Papa from 'papaparse';
import { GridElement, stockOrderHeaders, initSpecificHeaders, EXPECTED_HEADERS, initialStockOrderSummary, DNHeaders } from '../../models';
import { setSelectedLocations, setSelectedBusiness, } from '../../../../store/actions/userLocation';
import { validateHeaders, getMatchedHeaders, } from '../../../stockOrder/screens/StockOrderImport/helper';
import cloneDeep from 'lodash/cloneDeep';
import { getDNManifestItemsToMatch, getDNProductsMatchedItemsSummary, getDNRequestData, } from './helper';
import _isEqual from 'lodash/isEqual';
import { clearDnData, saveDnList, saveVendorData } from '../../data/actions'
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import download from 'downloadjs';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, ERROR_404, ERROR_409 } from '../../../shared/lib/Localization';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    secondaryPageTitle: {
      fontWeight: 400,
      fontSize: '1.15rem',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#fff',
      paddingLeft: 24,
      paddingRight: 24
    },

    backButton: {
      marginRight: theme.spacing(1),
    },
  }),
);


const getSteps = () => {
  return ['Import Manifest', 'Confirm Supplier & Location', 'Verify Imported Data', 'Map Items', 'Finish'];
};

const NoError = {
  hasError: false,
  message: ''
};

const NoErrorInHeaders = {
  hasError: false,
  message: '',
  message2: ''
}

const initialSupplierOptions = {
  stockOrder: true,
  preselectedSupplier: false
}


const ImportDispatchNotificationScreen = (props: any) => {
  const classes = useStyles({});
  const steps = getSteps();
  const selectedOrgUnit = useRef(null as any);
  // const activeStep = useRef(0)
  const [activeStep, setActiveStep] = useState(0);

  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [selectedFileError, setSelectedFileError] = useState(NoError);
  const [parsedPdfData, setParsedPdfData] = useState<any>(null);

  const [supplierError, setSupplierError] = useState(NoError);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);
  const [description, setDescription] = useState('');
  const [createDNButtonDisabled, setCreateDNButtonDisabled] = useState(false);
  const [isPDFUploaded, setPDFUploaded] = useState(false);
  const [awsBlockResponseId, setAwsBlockResponseId] = useState<number>(0);
  const [stepOneSuccess, setStepOneSuccess] = useState(false);
  const [stepTwoSuccess, setStepTwoSuccess] = useState(false);
  const [stepThreeSuccess, setStepThreeSuccess] = useState(false);
  const [stepFourSuccess, setStepFourSuccess] = useState(false);
  const [pdfInfoExists, setPDFInfoExists] = useState(false);
  const [supplierLinked, setSupplierLinked] = useState(true);
  const [supplierNameNotLinked, setSupplierNameNotLinked] = useState('');
  const [linkedSuppliers, setLinkedSuppliers] = useState([]);
  const [gridData, setGridData] = useState<GridElement[][]>([]);
  const [mappedHeaders, setMappedHeaders] = useState(new Map<number, string>());
  const [headersError, setHeadersError] = useState(NoErrorInHeaders);
  const [checkRowsInput, setCheckRowsInput] = useState('');
  const [checkColumnsInput, setCheckColumnsInput] = useState('');
  const [definedHeaders, setDefinedHeaders] = useState(stockOrderHeaders);
  const [definedDNHeaders, setdefinedDNHeaders] = useState(DNHeaders)
  const [shouldUpdateRowColSelection, triggerRowColSelection] = useState(false);
  const [isFileExtensionPDF, setIsFileExtensionPDF] = useState(true);
  const [reloadMappingBtnDisabled, setReloadMappingBtnDisabled] = useState(false)
  const [caseQtyDataError, setCaseQtyDataError] = useState(NoError);
  const [specificHeaders, setSpecificHeadersMap] = useState(initSpecificHeaders);
  const [reloadMappingButtonDisabled, setReloadMappingButtonDisabled] = useState(false);
  const [isCaseQtyMapped, setIsCaseQtyMapped] = useState(false);
  const [dNSummary, setDNSummary] = useState(initialStockOrderSummary);
  const [originalDNProducts, setOriginalDNProducts] = useState(null as any)

  const [DNProducts, setDNProducts] = useState(null)
  const { width, height } = useWindowDimensions();

  const [jsonUrl, setJsonUrl] = useState(null as any)
  const [vendorJson, setVendorJson] = useState(null as any)
  const [vendorProdData, setVendorProdData] = useState(null as any)
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [nextbtnDisabled, setNextBtnDisabled] = useState(true)
  const [stockOrderNumber, setStockOrderNumber] = useState(null)
  const [selectedSupplierNone, setSelectedSupplierNone] = useState(false)
  const [showAlertRtlLocNotFound, setShowAlertRtlLocNotFound] = useState(false)
  const [rtlLocErrorMsg, setRtlLocErrorMsg] = useState('')
  const [koronaSOList, setKoronaSOList] = useState(null)
  const [selectedKoronaSOinList, setSelectedKoronaSOinList] = useState(null)
  const [selectedSO, setSelectedSO] = useState(null)
  const [matchedJsonSupplier, setMatchedJsonSupplier] = useState(null)
  const [soDetailsByNum, setSoDetailsByNum] = useState(null)
  const [soNotFoundError, setSONotFoundError] = useState(false)
  const [soNumberLoader, setSONumberLoader] = useState(false)
  const [openSOListDialog, setOpenSOListDialog] = useState(false)
  const [fullData, setFullData] = useState(null)
  const [responseData, setReponseData] = useState(null)
  const [supplierOption, setSupplierOption] = useState(cloneDeep(initialSupplierOptions));
  const [selectedSupplierManually, setSelectedSupplierManually] = useState(null)
  const [suppNotFound, setSuppNotFound] = useState(false)


  useEffect(() => {
    console.log("supplierOption", supplierOption)
    if (supplierOption.preselectedSupplier) {
      if (matchedJsonSupplier) {
        setSelectedSupplier(matchedJsonSupplier)
        setSelectedSupplierManually(matchedJsonSupplier)
      }
    }
    else setSelectedSupplier(null)

  }, [supplierOption])

  useEffect(() => {
    if (activeStep === 0) {
      if (!(jsonUrl === null && vendorJson === null)) {
        setNextBtnDisabled(false)

      }
      else setNextBtnDisabled(true)
    }
    console.log("JSONURL", jsonUrl, typeof (jsonUrl))
    console.log("vendorJson", vendorJson, typeof (vendorJson))
  }, [jsonUrl, vendorJson, activeStep])

  useEffect(() => {
    console.log("showAlertRtlLocNotFound", showAlertRtlLocNotFound)
  }, [showAlertRtlLocNotFound])

  useEffect(() => {
    if (activeStep !== -1) {
      getStepContent(activeStep)
    }
  }, [activeStep])

  useEffect(() => {
    if (stepOneSuccess) {
      setActiveStep(1)
    }
  }, [stepOneSuccess, selectedSupplier]);


  useEffect(() => {
    if (activeStep === steps.length) {
      handleReset();
    }
  }, [activeStep]);

  useEffect(() => {
    if (props.userData && props.userData.selectedBusiness) {
      getLinkedSuppliers()
    }
  }, [props.userData.selectedBusiness]);


  useEffect(() => {
    if (selectedFile) {
      setSelectedFileError(NoError);
    }
  }, [selectedSupplier]);

  useEffect(() => {
    console.log("PROPS------------------")
    console.log(props)
  }, [props])

  useEffect(() => {
    console.log("PROPS", props.userData)
    if (props.userData.selectedLocations && props.userData.selectedLocations.length > 0) {
      let selectedLocs = props.userData.selectedLocations;
      console.log("SELECTED-LOCSSS~~", selectedLocs)
      if (activeStep === 1) {
        setNextBtnDisabled(true)
        if (selectedLocs.length === 1) {
          selectedOrgUnit.current = selectedLocs[0];
          selectedSupplier === null ? setNextBtnDisabled(true) : setNextBtnDisabled(false)
        } else {
          selectedOrgUnit.current = null
        }
      }
    }
    else {
      selectedOrgUnit.current = null
    }
  }, [props.userData, activeStep, selectedSupplier]);


  useEffect(() => {
    const { dnData } = props;
    console.log("Hello-1")

    if (dnData) {
      console.log("hey", dnData)
      console.log("~~~~~~~~~~~~~", dNSummary, initialStockOrderSummary)
      setDescription(props.dnData && props.dnData.vendorData ? `TransferId: ${props.dnData.vendorData.transfer_id ? props.dnData.vendorData.transfer_id : ''}` : '')
      if (!_isEqual(dNSummary, initialStockOrderSummary)) {
        console.log("~~~~~~~~")
        if (dnData.dn_list && dnData.dn_list.dnItemsList && dnData.dn_list.dnItemsList.length > 0) {
          console.log("Hello")
          const matchedItemSummary = getDNProductsMatchedItemsSummary(dnData.dn_list.dnItemsList, dNSummary);
          console.log("Matched items summary", matchedItemSummary);
          // props.saveDnList(cloneDeep(matchedItemSummary.items));
          setDNSummary(matchedItemSummary.summary);
        }
      }
    }
  }, [props.dnData]);


  const getLinkedSuppliers = () => {
    setLoading(true)
    const sb = props.userData.selectedBusiness;
    getLinkedKoronaMasterCompanySuppliers(sb.business_id, 1, 50, null)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PaginatedResponse<any>) => {
        setLoading(false)

        const sortedLinkedSuppliers = sortBy(data.results, 'master_company.name')
        // Change with working with SO list 
        // let updatedData = addNoneToArray(sortedLinkedSuppliers)
        setLinkedSuppliers(sortedLinkedSuppliers);
        if (activeStep == 1) {
          setSupplierLinked(true)
        }
      })
      .catch((error: any) => {
        setLoading(false)
        setLinkedSuppliers([]);
      });
  }


  const addNoneToArray = (data: any) => {
    let updatedData = cloneDeep(sortBy(data, 'master_company.name'))

    // console.log("test", updatedData)
    let obj = { ...data[0] }
    Object.keys(obj).forEach(k => obj[k] = null)
    updatedData.unshift(obj)
    let index = updatedData.findIndex((d: any) => d.id === null)
    updatedData[index]['id'] = 0
    updatedData[index]['master_company'] = { 'name': 'None' }
    console.log("after", updatedData,)
    return updatedData
  }

  // const handleResetDialogOpen = () => {
  //   setResetDialogOpen(true);
  // };

  const handleResetDialogClose = () => {
    setResetDialogOpen(false);
  };


  const handleAlertDialogClose = () => {
    setShowAlertRtlLocNotFound(false);
  };


  const resetDialog = () => {
    return (
      <div>
        <Dialog
          open={resetDialogOpen}
          onClose={handleResetDialogClose}
        >
          <DialogTitle id="alert-dialog-title">{"Reset"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to reset?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResetDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={confirmReset} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }


  const renderAlertDialog = () => {
    return (
      <div>
        <Dialog
          open={showAlertRtlLocNotFound}
          onClose={handleAlertDialogClose}
        >
          <DialogTitle>{"Retailer Location not found"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>{rtlLocErrorMsg}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertDialogClose} style={{ color: GlobalStyles.primaryColor }} autoFocus >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const confirmReset = () => {
    getLinkedSuppliers()
    setVendorJson(null)
    setActiveStep(0)
    setSelectedFile(null);
    setGridData([]);
    setSelectedSupplier(null);
    setMappedHeaders(new Map<number, string>());
    setStepOneSuccess(false);
    setPDFUploaded(false);
    setLoading(false);
    setIsFileExtensionPDF(true);
    setCurrentLocation()
    setBackButtonDisabled(false);
    setStepTwoSuccess(false);
    setStepThreeSuccess(false);
    setStepFourSuccess(false);
    setPDFInfoExists(false);
    setCreateDNButtonDisabled(false)
    setReloadMappingBtnDisabled(false)
    props.clearDnList()
    selectedOrgUnit.current = null
    setVendorJson(null)
    setJsonUrl(null)
    handleResetDialogClose()
    setRtlLocErrorMsg('')
    setShowAlertRtlLocNotFound(false)
    setKoronaSOList(null)
    setSelectedSO(null)
    setSelectedKoronaSOinList(null)
    setSelectedSupplierNone(false)
    setMatchedJsonSupplier(null)
    setSONotFoundError(false)
    setSoDetailsByNum(null)
    setOpenSOListDialog(false)
    setFullData(null)
    setReponseData(null)
    setSupplierOption(cloneDeep(initialSupplierOptions))
    onClearCheckedButtonClick()
    setSelectedSupplierManually(null)
    setSuppNotFound(false)

  }



  const handleReset = () => {
    setResetDialogOpen(true)
  };


  const setCurrentLocation = () => {
    if (props.userData && props.userData.selectedLocations && props.userData.selectedLocations.length > 0) {
      let selectedLocs = props.userData.selectedLocations;
      console.log("SELECTED-LOCSSS~~", selectedLocs)
      if (activeStep === 1) {
        if (selectedLocs.length === 1) {
          selectedOrgUnit.current = selectedLocs[0];
        } else {
          selectedOrgUnit.current = null
        }
      }
    }
    else {
      selectedOrgUnit.current = null
    }
  }


  const renderFileName = () => {
    if (selectedFile && selectedFile.name) {
      const ext = selectedFile.name.split('.').pop();
      const filename = `File: ${selectedFile.name}`;
      if (ext === "csv") {
        return (
          <>
            <span className={classes.secondaryPageTitle}>
              &nbsp;&nbsp;&ndash;&nbsp;&nbsp;{filename}
            </span>
            {/* {
              parsedPdfData &&
              <Tooltip title="Export TSV" onClick={exportToCSV}
                style={{ alignSelf: 'center', marginLeft: 8 }}>
                <IconButton aria-label="export-tsv">
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
            } */}
          </>);
      }
      return filename;
    }
    return null;
  };


  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    console.log("Handle Back", activeStep)
  };

  const handleStepMappingHeaders = () => {
    const headersMappedResult = validateHeaders(mappedHeaders);
    console.log("headersMappedResult~~~", headersMappedResult)

    if (headersMappedResult.hasError) {
      setHeadersError(headersMappedResult);
      setLoading(false)
      return;
    } else {
      props.showToast({ message: 'Mapped Headers Successfully, Moving to Step 4!', options: ToastSuccess });
      setHeadersError(NoErrorInHeaders);
    }

    if (selectedSupplier && !headersMappedResult.hasError) {
      if (activeStep + 1 === 3) {
        getDNProducts();
        setActiveStep(activeStep + 1)
        console.log("ACTIVEEE-STEPPPP", activeStep)
      }
    }
  }

  const handleChangeDescription = (e: any) => {
    setDescription(e.target.value)
  }

  const handleNext = () => {
    setLoading(true)
    console.log("Next clicked")
    if (activeStep === 0) {
      handleJsonUrlSubmit()
    }
    else if (activeStep === 1) {
      if (!isFileExtensionPDF) {
        setActiveStep(2)
        handleStepMappingHeaders()
        // setLoading(false);

      }
      else {
        const sb = props.userData.selectedBusiness.business_id;
        const selected_supplier = selectedSupplier.master_company.id
        if (vendorProdData != null) {
          setParsedPdfData(vendorProdData);
          mapCSVDataToGrid(vendorProdData);
          setDescription(props.dnData && props.dnData.vendorData ? `TransferId: ${props.dnData.vendorData.transfer_id ? props.dnData.vendorData.transfer_id : ''}` : '')
          setActiveStep(2)
          mapHeadersFromConfigJson();
          setLoading(false);
        }
        else {
          parsePdf(sb, awsBlockResponseId, selected_supplier)
            .catch(handleError)
            .then(checkStatus)
            .then(parseJSON)
            .then(res => {
              if (res['parsed_data'].length === 0) {
                const msg = 'Oops! An Error Occured. Data could not be parsed from file.';
                props.showToast({ message: msg, options: ToastError });
              }

              setParsedPdfData(res['parsed_data']);
              mapCSVDataToGrid(res['parsed_data']);
              if (res['headers']) {
                if (res['headers']['description']) {
                  setDescription(res['headers']['description'])
                }
              }
              mapHeadersFromConfig();
              setActiveStep(2)
              setLoading(false);

            })
            .catch((error: any) => {
              if (error.status === 400) {
                props.showToast({ message: error.data.message, options: ToastError });
              }
              else if (error.status === 404) {
                props.showToast({ message: error.data.message, options: ToastError });
              }
              else {
                const msg = 'Oops! An Error Occured.';
                props.showToast({ message: msg, options: ToastError });
              }

            });
        }

      }


    }
    else if (activeStep === 2) {
      // console.log("inside STEP2")
      handleStepMappingHeaders()
      // setLoading(false);

    }
    else if (activeStep === 3) {
      const areCaseQuantitiesValid = validateCaseQuantities();
      console.log("areCaseQuantitiesValid", areCaseQuantitiesValid);
      if (areCaseQuantitiesValid) {
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        console.log("DN-list", props.dnData)

        createDN()
        console.log("create DN called")
      }
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const validateCaseQuantities = () => {
    const caseQtyBlankError = {
      hasError: true,
      message: 'Supplier Case Quantity cannot be blank.'
    };
    let DNData = cloneDeep(props.dnData.dn_list.dnItemsList)
    console.log("DNData" + DNData);
    if (DNData &&
      DNData.length > 0) {
      const list = DNData.slice();
      if (isCaseQtyMapped) {
        for (let i = 0; i < list.length; i++) {
          if (!list[i].master_supplier_container_qty) {
            setCaseQtyDataError(caseQtyBlankError);
            return false;
          }
          if (Number(list[i].caseQuantity) !== Number(list[i].master_supplier_container_qty)) {
            setCaseQtyDataError(
              {
                hasError: true,
                message: 'Please resolve the difference between Case Quantity and Supplier Case Quantity Values.'
              });
            return false;
          }
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (!list[i].master_supplier_container_qty) {
            setCaseQtyDataError(caseQtyBlankError);
            return false;
          }
        }
      }
      return true;
    }
    return false;
  };

  const createDN = () => {
    if (selectedOrgUnit.current != null) {
      setCreateDNButtonDisabled(true)
      setReloadMappingButtonDisabled(true)
      setLoading(true)
      // console.log('selectedOrgUnit', selectedOrgUnit);
      const desc = description;
      let currentLoc = selectedOrgUnit.current
      // console.log(stockOrderProducts);
      const requestData = getDNRequestData(selectedSupplier, currentLoc, desc, props.dnData.dn_list.dnItemsList);
      // console.log(requestData);
      if (!requestData.hasError) {
        let payload = cloneDeep(requestData.payload)
        console.log("propsssssss~~~~~~~~", props)
        console.log("payload-before", payload)
        payload['location'] = selectedOrgUnit.current
        payload['user_details'] = props.userData && props.userData.userData && props.userData.userData.userData.auth_token
        payload['business'] = props.userData && props.userData.selectedBusiness
        payload['description'] = description
        payload['master_company_id'] = selectedSupplier.master_company.id
        payload['vendor_data'] = props.dnData && props.dnData.vendorData ? props.dnData.vendorData : null
        payload['stock_order_selected'] = selectedSO ? true : false
        payload['stock_order_details'] = selectedSO ? selectedSO : null


        console.log("payload-after", payload)

        addDN(payload)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then((res) => res.blob())
          .then((data) => {
            download(data, `${description}.csv`, 'text/csv');
            // setCreateDNButtonDisabled(false)
            // setReloadMappingButtonDisabled(false)
            // console.log('Create DN success', );
            let msg = 'Successfully created'
            props.showToast({ message: msg, options: ToastSuccess });
            setLoading(false)

          })
          .catch((error: any) => {
            console.log(error);
            setCreateDNButtonDisabled(false)
            setReloadMappingButtonDisabled(false)
            setLoading(false)
            if (error.status === 404) {
              props.showToast({ message: error.data.message, options: ToastError });
            }
            else {
              const msg = 'Oops! An Error Occured.';
              // console.log("in error",error)
              props.showToast({ message: msg, options: ToastError });
            }
          });

      }
    }

  };

  const onFileSelectChangeHandler = (event: any) => {
    if (event.target && event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleManualSupplierChange = (event: any) => {
    let val = event.target.value
    let linkedSupplier = linkedSuppliers.find(s => s.id === val);
    setSelectedSupplier(linkedSupplier);
    setSelectedSupplierManually(linkedSupplier);
    // val === 0 ?  setSelectedSupplierNone(true) : setSelectedSupplierNone(false)
  };


  const uploadPDF = () => {
    if (selectedFile) {
      if (!isPDFUploaded) {
        const data = new FormData();
        data.append('file', selectedFile);
        setLoading(true);
        uploadInvoicePDf(data)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(res => {
            props.showToast({ message: 'PDF Uploaded Successfully!', options: ToastSuccess });
            setPDFUploaded(true);
            if (res['pdf_info_exists']) {
              setPDFInfoExists(true)
            }
            if (res['pdf_info_exists'] && !res['linked_supplier']) {
              setSupplierLinked(false);
              setSupplierNameNotLinked(res['supplier_not_linked'])
            }

            if (!res['pdf_info_exists']) {
              const msg = 'Supplier,Business and location could not be retrived for the data parsed from this file, Please choose manually'
              props.showToast({ message: msg, options: ToastError });
            }
            props.setSelectedBusiness(res['retailer_business'])
            if (res['location'] != null) {
              const { selectedLocations } = props.userData
              selectedLocations.forEach(element => {
                if (element.id != res['location']) {
                  const index = selectedLocations.indexOf(element)
                  selectedLocations[index].selected = false;
                  selectedLocations.splice(index, 1);
                }
              })

              props.setSelectedLocations(selectedLocations);
              selectedOrgUnit.current = selectedLocations[0]

            }
            // console.log(selectedLocations[0])
            setAwsBlockResponseId(res['stored_aws_response_id'])
            setSelectedSupplier(res['linked_supplier'])
            setLoading(false);
            setStepOneSuccess(true);
            setActiveStep(1)
          })
          .catch((error: any) => {
            if (error.status === 404) {
              props.showToast({ message: error.data.message, options: ToastError });
            }
            else {
              const msg = 'Oops! An Error Occured.';
              // console.log("in error",error)
              props.showToast({ message: msg, options: ToastError });
            }
            setLoading(false);
          });
      }
    }
  };


  const handleJsonUrlSubmit = () => {
    setLoading(true)
    console.log("JSON-URL", jsonUrl, vendorJson)
    console.log("Called")

    let payload = {
      business: props.userData.selectedBusiness,
      vendorUrl: jsonUrl ? jsonUrl : null,
      vendor_json: vendorJson ? vendorJson : null
    }

    parseJsonUrlData(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        let msg = 'Parsed successfully'
        props.showToast({ message: msg, options: ToastSuccess, });
        props.saveVendorData(jsonData)
        // mapCSVDataToGrid(jsonData.products);
        let fin = convertProducts(jsonData.products)
        setVendorProdData(fin)
        if (linkedSuppliers != null) {
          console.log("props----------", props)

          if ('json_company_id' in jsonData) {
            let linkedSupplier = linkedSuppliers.find(s => s.master_company && s.master_company.id === jsonData['json_company_id']);
            console.log("linkedSupplier~~~~~~~~~~~", linkedSupplier)
            setMatchedJsonSupplier(linkedSupplier);
            // if(linkedSupplier === null){
            //   setSuppNotFound(true)
            // }
          }

          if ('json_rtl_loc_id' in jsonData) {
            const { selectedLocations } = store.getState().userLocalData
            selectedLocations.forEach(element => {
              if (element.master_company_id != jsonData['json_rtl_loc_id']) {
                const index = selectedLocations.indexOf(element)
                selectedLocations[index].selected = false;
                selectedLocations.splice(index, 1);
              }
            })
            props.setSelectedLocations(selectedLocations);
            selectedOrgUnit.current = selectedLocations[0]
          }

        }

        setActiveStep(1)
        mapHeadersFromConfigJson();
        setStepOneSuccess(true);
        setLoading(false);

      })
      .catch((error: any) => {
        console.log("errrrorrrr", error);
        setLoading(false)
        if (error.status === 404) {
          let errorData = error['data']
          if ('error_type' in errorData) {
            if (['supp_license_not_found','rtl_loc_not_found'].includes(errorData['error_type'])) {
              setShowAlertRtlLocNotFound(true)
              setRtlLocErrorMsg(errorData['error'])
            }
          }
        }
        else {
          const msg = 'Oops! An Error Occured.';
          // console.log("in error",error)
          props.showToast({ message: msg, options: ToastError });
        }
      });
  }


  const convertProducts = (prods: any) => {
    let ref = ['product_sku', 'quantity', 'product_name', 'product_price', 'line_total']
    let fin_arr = []
    for (let prod in prods) {
      let p = prods[prod]
      console.log("PPP", p)
      let obj = []
      for (let r in ref) {
        console.log("Ref", r, ref[r])
        let heading = ref[r]
        if (heading in p) {
          console.log("HEY", heading, p[heading])
          let index = ref.findIndex(re => re === heading)
          obj[index] = p[heading]
          console.log("inside", obj)
        }
      }
      fin_arr.push(obj)

    }

    return fin_arr
  }

  const parseCSV = () => {
    if (selectedFile) {
      setLoading(true)
      const CSVParserConfig = {
        complete: function (results: any, file: any) {
          // console.log("Finished:", results.data);
          if (results.data.length > 0) {
            console.log('papa parser', results.data);
            mapCSVDataToGrid(results.data);
            mapHeadersFromConfig();
            setStepOneSuccess(true);
            setLoading(false);
          }

        },
        dynamicTyping: false,
        error: function (error: any, file: any) {
          console.log("Error occurred while parsing csv!");

        },
        skipEmptyLines: true
      };
      Papa.parse(selectedFile, CSVParserConfig);
      setActiveStep(1)

    }
  };

  const mapHeadersFromConfigJson = () => {
    let config: any = null;
    // console.log("selectedSupplier", selectedSupplier);
    if (selectedSupplier) {
      const map = new Map(mappedHeaders);
      DNHeaders.forEach((h: any, index: any,) => {
        map.set(Number(index), h)
      })
      console.log("MAPPED-HEADERS", map)
      setMappedHeaders(map);
      triggerRowColSelection(!shouldUpdateRowColSelection);
    }
  };


  const mapHeadersFromConfig = () => {
    let config: any = null;
    // console.log("selectedSupplier", selectedSupplier);
    if (selectedSupplier) {
      const { parser_settings } = selectedSupplier.master_company;
      // console.log("parser settings", parser_settings);
      if (parser_settings && parser_settings.length > 0) {
        const setting = parser_settings[0];
        if (setting.stock_order_header_config) {
          try {
            config = JSON.parse(setting.stock_order_header_config);
          }
          catch (e) {
            // error parsing header config
            config = null;
          }
        }
      }
      if (config) {
        if (config.columnsToIgnore) {
          setCheckColumnsInput(config.columnsToIgnore);
        }
        if (config.rowsToIgnore) {
          setCheckRowsInput(config.rowsToIgnore);
        }
        if (config.columns) {
          const map = new Map(mappedHeaders);
          Object.keys(config.columns).forEach(k => {
            const header = definedHeaders.find(h => h.toLowerCase() === config.columns[k].toLowerCase());
            if (header) {
              map.set(Number(k), header);
            }
          });
          setMappedHeaders(map);
        }
        triggerRowColSelection(!shouldUpdateRowColSelection);
      }
    }
  };



  const mapCSVDataToGrid = (data: any[][]) => {
    let result: GridElement[][] = [];
    for (let i = -1; i < data.length; i++) {
      let row = null;
      if (i === -1) {
        row = data[0];
      } else {
        row = data[i];
      }
      let newRow: GridElement[] = [];
      for (let j = -1; j < row.length; j++) {
        const gridElement = { value: '', isSelected: false, isIgnored: false, type: 'data' } as GridElement;
        if (i === -1 && j === -1) { // row=0, col=0
          gridElement.type = 'data';
          // gridElement.value = 'Headers'
        } else if (i === -1) { // row=0
          gridElement.type = 'header';
        }
        else if (j === -1) { // col=0
          gridElement.type = 'checkbox';
        } else {
          gridElement.type = 'data';
          if (!row[j]) {
            gridElement.value = '';
          } else {
            gridElement.value = row[j];
          }
        }
        newRow.push(gridElement);
      }
      result.push(newRow);
    }
    console.log("Grid values", result)
    setGridData(result);
  };

  const getDNProducts = () => {
    setCaseQtyDataError(NoError);
    const matchedHeaders = getMatchedHeaders(gridData, mappedHeaders);
    setSpecificHeadersMap(matchedHeaders);
    const sb = props.userData.selectedBusiness;
    const businessId = sb.business_id;
    const requestData = getDNManifestItemsToMatch(gridData, mappedHeaders);
    let config: any = null;
    if (selectedSupplier) {
      const { parser_settings } = selectedSupplier.master_company;
      if (parser_settings && parser_settings.length > 0) {
        const setting = parser_settings[0];
        if (setting.stock_order_header_config) {
          try {
            config = JSON.parse(setting.stock_order_header_config);
          }
          catch (e) {
            config = null;
          }
        }
      }
    }

    const RegexToMatch = (data) => {
      let parser_regex = config.product_regex
      let regex_to_match = new RegExp(parser_regex);
      data.forEach(element => {
        element.supplierProductName = element.supplierProductName.match(regex_to_match)[0];
      })

      return data;
    }

    // To merge duplicate records
    const MergeDuplicateRecords = () => {
      let merged_duplicate_product_lists = []
      for (let i = 0; i < requestData.length; i++) {
        let existing_product = merged_duplicate_product_lists.find(element => element.reOrderCode === requestData[i].reOrderCode)
        if (existing_product) {
          // console.log("inside existing_product")
          if (definedHeaders.indexOf(EXPECTED_HEADERS.CASES_DELIVERED) > -1) {
            if (requestData[i].casesDelivered && requestData[i].casesDelivered > 0) {
              existing_product.casesDelivered += Number(requestData[i].casesDelivered)
            }
          }
          else if (definedHeaders.indexOf(EXPECTED_HEADERS.UNITS_DELIVERED) > -1) {
            if (requestData[i].unitsDelivered > 0) {
              existing_product.unitsDelivered += Number(requestData[i].unitsDelivered)
            }
          }
          if (definedHeaders.indexOf(EXPECTED_HEADERS.COST_PER_CASE) > -1) {
            if (requestData[i].casesDelivered > 0) {
              existing_product.costPerCase = Number(existing_product.lineTotal) / Number(existing_product.casesDelivered)
              existing_product.costPerCase = existing_product.costPerCase.toFixed(2)
            }
          }
          else if (definedHeaders.indexOf(EXPECTED_HEADERS.COST_PER_UNIT) > -1) {
            if (requestData[i].unitsDelivered > 0) {
              existing_product.costPerUnit = Number(existing_product.lineTotal) / Number(existing_product.unitsDelivered)
              existing_product.costPerUnit = existing_product.costPerUnit.toFixed(2)
            }
          }
          if (existing_product.supplierProductName.length < requestData[i].supplierProductName.length) {
            continue
          }
          else {
            existing_product.supplierProductName = requestData[i].supplierProductName
          }
        }
        else {
          merged_duplicate_product_lists.push(requestData[i])
        }
      }
      return merged_duplicate_product_lists
    }


    const checkMultipleProductName = (data: any) => {
      const validatedRecords = data.map(i => i);
      let productName;
      let firstLetter;
      let secondWordStart;
      let replacedInput;
      let newInput;
      for (let i = 0; i < validatedRecords.length; i++) {
        const exists = validatedRecords[i].supplierProductName ? true : false;
        if (exists) {
          let { supplierProductName } = validatedRecords[i];
          // const {input } = supplierProductName;
          productName = supplierProductName
          firstLetter = productName.charAt(0)
          secondWordStart = productName.split(firstLetter, 2).join(firstLetter).length;
          // console.log("secondWordStart",secondWordStart)
          newInput = productName.slice(secondWordStart);
          // console.log("NEW-INPUT",newInput)
          validatedRecords[i]["supplierProductName"] = newInput
        }
      }
      // RegexToMatch(validatedRecords)
      return validatedRecords;
    }

    if (businessId && selectedSupplier && requestData.length > 0) {

      let data = []
      let newData = []
      let array_of_header_values = []
      let values_of_headers_list = Array.from(mappedHeaders.values());
      let payload = {}
      let annapurnaBusiness = "annapurna"
      if (values_of_headers_list.includes(EXPECTED_HEADERS.RE_ORDER_CODE)) {
        data = MergeDuplicateRecords()
        // RegexToMatch(data)
      }
      else if (selectedSupplier.master_company.name.toLowerCase().includes(annapurnaBusiness)) {
        data = checkMultipleProductName(requestData)
      }
      else {
        data = requestData
        // RegexToMatch(data)
      }
      console.log("PropsUSerData", props.userData)
      payload = {
        dnItems: data,
        vendor_data: props.dnData && props.dnData.vendorData && props.dnData.vendorData.products ? props.dnData.vendorData.products : null,
        business_id: businessId,
        master_company_id: selectedSupplier.master_company.id,
        korona_supplier_business_id: null,
        user_auth_token: props && props.userData && props.userData.userData.userData.auth_token,
        document_name: props.dnData && props.dnData.vendorData && `TransferId: ${props.dnData.vendorData.transfer_id ? props.dnData.vendorData.transfer_id : ''}`

      };

      getDispatchNotificationProductList(payload)
    } else {
      props.saveDnList(null)
    }
  };


  const getDispatchNotificationProductList = (payload: any) => {
    if (payload && payload != null) {
      setLoading(true)

      getDispatchNotificationsInvoiceProductList(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data) => {
          console.log('RESULTSSS', data);
          setOriginalDNProducts(cloneDeep(data))
          if (data && data.has_errors) {
            let msg = 'Failed to match some invoice items!';
            props.showToast({ message: msg, options: ToastError });
          }
          if (data.dnItemsList && data.dnItemsList.length > 0) {
            const matchedItemSummary = getDNProductsMatchedItemsSummary(data.dnItemsList, dNSummary);
            data['dnItemsList'] = cloneDeep(matchedItemSummary.items)
            setDNSummary(matchedItemSummary.summary);
            props.saveDnList(cloneDeep(data));
          }
          setLoading(false)
        })
        .catch((err: any) => {
          setLoading(false)
          setActiveStep(2)
        });
    }
  }

  const handleJsonUrlChange = (val: string) => {

    setJsonUrl(val === '' ? null : val)
  }




  // const handleGetSuppInfo = () => {
  //   if (stockOrderNumber) {
  //     setSONumberLoader(true)
  //     setSONotFoundError(false)
  //     const sb = props.userData.selectedBusiness;
  //     let payload = {
  //       "business_id": sb.business_id,
  //       "so_number": stockOrderNumber
  //     }
  //     getKoronaSOInfo(payload)
  //       .catch(handleError) // handle network issues
  //       .then(checkStatus)
  //       .then(parseJSON)
  //       .then((data: any) => {
  //         setSoDetailsByNum(data)
  //         setSONumberLoader(false)
  //         setSONotFoundError(false)
  //         let selectedSupp = linkedSuppliers.find(ls => ls.korona_supplier.uuid === data.so_data.supplier)
  //         console.log("selectedSuppsssssssssss", selectedSupp)
  //         setSelectedSupplier(selectedSupp)
  //       })
  //       .catch((error: any) => {
  //         setSONumberLoader(false)

  //         let msg = 'Failed to fetch Stock order'

  //         if (error && error.status) {
  //           if (error.status === 403 || error.status === 401) {
  //             msg = NO_PERMISSIONS_ERROR_MSG;
  //           } else if (error.status === 500) {
  //             msg = ERROR_500;
  //         }
  //         else if (error.status === 404) {
  //           setSONotFoundError(true)
  //           msg = ERROR_404
  //         }
  //         else if (error.status === 409){
  //           msg = ERROR_409
  //         }
  //       }
  //       // props.showToast({
  //       //   message: msg,
  //       //   options: ToastError
  //       // });



  //     });
  //   }
  // }

  const handleChangeKoronaSO = (soItem: any) => {
    if (soItem) {
      setSelectedSO(soItem);
      let selectedSupp = linkedSuppliers.find(ls => ls.korona_supplier.uuid === soItem.supplier.id)
      console.log("selectedSuppsssssssssss", selectedSupp)
      setSelectedSupplier(selectedSupp)

    }
    else {
      setSelectedSO(null);
      setSelectedSupplier(null)
    }
  }


  const handleSOSelectionChange = (rows: any) => {
    if (rows.length > 0) {
      let product = { ...rows[0] };
      delete product['tableData'];
      setSelectedKoronaSOinList(product);
    } else {
      setSelectedKoronaSOinList(null);
    }
  };


  const onClearCheckedButtonClick = () => {
    if (gridData && gridData.length > 0) {
      const grid = gridData.map((row: any[]) => [...row]);
      for (let x = 0; x < grid.length; x++) {
        for (let y = 0; y < grid[x].length; y++) {
          grid[x][y].isSelected = false;
          grid[x][y].isIgnored = false;
        }
      }
      setGridData(grid);
    }
  };

  const getStepContent = (currentStep: any) => {
    console.log("Active Step", currentStep)

    console.log("CurrentStep", activeStep)

    switch (currentStep) {
      case 0:
        return (
          <JsonImport
            history={props.history}
            uploadPDF={uploadPDF}
            onFileSelectChangeHandler={onFileSelectChangeHandler}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileError={selectedFileError}
            selectedSupplier={selectedSupplier}
            setSelectedSupplier={setSelectedSupplier}
            supplierError={supplierError}
            uiLoading={isLoading}
            setLoading={setLoading}
            handleManualSupplierChange={handleManualSupplierChange}
            linkedSuppliers={linkedSuppliers}
            parseCSV={parseCSV}
            stepOneSuccess={stepOneSuccess}
            setIsFileExtensionPDF={setIsFileExtensionPDF}
            handleJsonUrlChange={handleJsonUrlChange}
            jsonUrl={jsonUrl}
            handleJsonUrlSubmit={handleJsonUrlSubmit}
            setVendorDataJson={(data: any) => setVendorJson(data === '' ? null : data)}
            vendorDataJson={vendorJson}
          />
        );

      case 1:
        return (
          <ConfirmSupplierAndLocationForDispatchNotification
            history={props.history}
            supplierError={supplierError}
            uiLoading={isLoading}
            linkedSuppliers={linkedSuppliers}
            handleManualSupplierChange={handleManualSupplierChange}
            selectedSupplier={selectedSupplier}
            selectedOrgUnit={selectedOrgUnit.current}
            supplierLinked={supplierLinked}
            supplierNameNotLinked={supplierNameNotLinked}
            pdfInfoExists={pdfInfoExists}
            setStepTwoSuccess={setStepTwoSuccess}
            soNumber={stockOrderNumber}
            setSoNumber={setStockOrderNumber}
            selectedSuppNone={selectedSupplierNone}
            soList={koronaSOList}
            selectedKoronaSOinList={selectedKoronaSOinList}
            setSelectedKoronaSOinList={setSelectedKoronaSOinList}
            handleSOSelectionChange={handleSOSelectionChange}
            handleChangeKoronaSO={handleChangeKoronaSO}
            selectedSO={selectedSO}
            // handleGetSuppInfo={handleGetSuppInfo}
            matchedJsonSupplier={matchedJsonSupplier}
            soDetailsByNum={soDetailsByNum}
            soNotFound={soNotFoundError}
            soNumberLoader={soNumberLoader}
            openSOListDialog={openSOListDialog}
            fullData={fullData}
            responseData={responseData}
            setFullData={setFullData}
            setOpenSOListDialog={setOpenSOListDialog}
            setReponseData={setReponseData}
            supplierOption={supplierOption}
            setSupplierOption={setSupplierOption}
            setSelectedSupplier={setSelectedSupplier}
            setSelectedSupplierManually={setSelectedSupplierManually}
            selectedSupplierManually={selectedSupplierManually}
            suppNotFound={suppNotFound}
          />
        );

      case 2:
        return (
          <VerifyImportData
            parsedPdfData={parsedPdfData}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileError={selectedFileError}
            mapCSVDataToGrid={mapCSVDataToGrid}
            mapHeadersFromConfig={mapHeadersFromConfigJson}
            gridData={gridData}
            setGridData={setGridData}
            parseCSV={parseCSV}
            selectedSupplier={selectedSupplier}
            setSelectedSupplier={setSelectedSupplier}
            supplierError={supplierError}
            mappedHeaders={mappedHeaders}
            setMappedHeaders={setMappedHeaders}
            headersError={headersError}
            uiLoading={isLoading}
            setUILoading={setLoading}
            windowWidth={width}
            windowHeight={height}
            checkColumnsInput={checkColumnsInput}
            setCheckColumnsInput={setCheckColumnsInput}
            checkRowsInput={checkRowsInput}
            setCheckRowsInput={setCheckRowsInput}
            definedHeaders={definedHeaders}
            shouldUpdateRowColSelection={shouldUpdateRowColSelection}
            setStepThreeSuccess={setStepThreeSuccess}
          />
        );

      case 3:
        return (
          <MapInvoiceProduct
            selectedSupplier={selectedSupplier}
            dNSummary={dNSummary}
            getDNProducts={getDNProducts}
            specificHeaders={specificHeaders}
            caseQtyDataError={caseQtyDataError}
            isCaseQtyMapped={isCaseQtyMapped}
            uiLoading={isLoading}
            setUILoading={setLoading}
            windowWidth={width}
            windowHeight={height}
            setStepFourSuccess={setStepFourSuccess}
            reloadMappingButtonDisabled={createDNButtonDisabled}
            DNProductList={props.dnData && props.dnData.dn_list && props.dnData.dn_list.dnItemsList}
            selectedFile={selectedFile && selectedFile}
            setDNSummary={setDNSummary}

          />
        );

      default: return props.showToast({ message: 'Unknown Step', options: ToastError });

    }
  }

  const nextButtonDisabled = () => {
    let val = false
    if (isLoading) {
      val = true
    }
    else if (nextbtnDisabled) {
      val = true
    }
    else if (createDNButtonDisabled) {
      val = true
    }
    else if (activeStep === 1) {
      if (!selectedSupplier) {
        val = true
      }
    }

    return val
  }

  return (
    <div style={{ maxWidth: '100%', marginTop: -16, maxHeight: '100%' }}>
      <PageToolbar
        title='Incoming Manifests'
        history={props.history}
        secondaryTitle={renderFileName()}
        actions={
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {
              activeStep === 0 &&
              <div style={{ alignSelf: 'center', marginTop: 8 }}>
                <Button style={{ border: 1 }} variant="contained" disabled={jsonUrl != null ? false : vendorJson != null ? false : true} onClick={handleReset}>Reset</Button>
              </div>
            }
            {
              activeStep > 0 &&
              <>
                <div style={{ alignSelf: 'center', marginRight: 8, marginTop: 8 }}>
                  <Button variant="contained" onClick={handleReset}>Reset</Button>
                </div>
                <div style={{ display: 'flex', minWidth: 200, maxWidth: 290, width: '100%' }}>
                  {
                    <TextField
                      id="supplier"
                      label="Supplier"
                      name="supplier"
                      disabled={true}
                      value={selectedSupplier ? selectedSupplier.korona_supplier.name : ''}
                    />
                  }
                </div>
              </>
            }
          </div>
        } />
      <Paper elevation={activeStep === 2 ? 0 : 4}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }} >
          <div className={classes.content} >
            {
              activeStep !== 0 &&
              <Button
                disabled={backButtonDisabled}
                variant="contained"
                onClick={handleBack}
              >
                Back
              </Button>
            }
            <Stepper
              style={{ padding: 0, paddingTop: 24, flex: 1 }}
              activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {
              activeStep === 3 &&
              <TextField
                id="description-textarea"
                label="Description"
                placeholder="Description"
                style={{ marginRight: 16 }}
                multiline
                margin="normal"
                value={description}
                onChange={handleChangeDescription}
              />
            }
            {
              <Button variant="contained" color="primary" onClick={handleNext} disabled={nextButtonDisabled()}>
                {activeStep === steps.length - 2 ? 'Create Dispatch Notification' : 'Next'}
              </Button>
            }
          </div>
          {
            isLoading ?
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
              :
              <div style={{ marginTop: 0 }}>
                {getStepContent(activeStep && activeStep)}
              </div>
          }

        </div>
      </Paper>
      {resetDialog()}
      {renderAlertDialog()}
    </div>
  )

}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
    dnData: state.dnModuleData.dnData,
    vendorData: state.dnModuleData.dnData.vendorData
  };
};


const mapDispatchToProps = (dispatch: any) => {
  return {
    setSelectedBusiness: (business: any | undefined) => dispatch(setSelectedBusiness(business)),
    setSelectedLocations: (locations: Location[]) => dispatch(setSelectedLocations(locations)),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    clearDnList: () => dispatch(clearDnData()),
    saveDnList: (data: any) => dispatch(saveDnList(data)),
    saveVendorData: (data: any) => dispatch(saveVendorData(data))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ImportDispatchNotificationScreen);