export const GET_STOCK_RECEIPT_SUMMARY = 'GET_STOCK_RECEIPT_SUMMARY';
export const GET_STOCK_RECEIPT_SUMMARY_FAILURE = 'GET_STOCK_RECEIPT_SUMMARY_FAILURE';
export const GET_STOCK_RECEIPT_SUMMARY_SUCCESS = 'GET_STOCK_RECEIPT_SUMMARY_SUCCESS';
export const SET_SELECTED_STOCK_RECEIPT_DATA = 'SET_SELECTED_STOCK_RECEIPT_DATA';
export const CLEAR_SELECTED_STOCK_RECEIPT_DATA = 'CLEAR_SELECTED_STOCK_RECEIPT_DATA';
export const CLEAR_STOCK_RECEIPT = 'CLEAR_STOCK_RECEIPT';
export const SET_ADD_CODE_ACTION_DATA = 'SET_ADD_CODE_ACTION_DATA';
export const CLEAR_ADD_CODE_ACTION_DATA = 'CLEAR_ADD_CODE_ACTION_DATA';

export const SET_STOCK_RECEIPT_ITEMS ='SET_STOCK_RECEIPT_ITEMS';
export const UPDATE_STOCK_RECEIPT_ITEMS = 'UPDATE_STOCK_RECEIPT_ITEMS'
export const DELETE_STOCK_RECEIPT_PRODUCT_IN_LIST='DELETE_STOCK_RECEIPT_PRODUCT_IN_LIST'
export const CLEAR_STOCK_RECEIPTS_ITEMS ='CLEAR_STOCK_RECEIPTS_ITEMS';
export const SET_SR_VENDOR_DATA = 'SET_SR_VENDOR_DATA';
export const ADD_NEW_SR_ITEM= 'ADD_NEW_SR_ITEM';
