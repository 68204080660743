import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";
import { InvoicePDFUpload, StockOrderRequest, MatchInvoicePayload } from "../models";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    masterSuppliersLinkedUrl: BASE_URL + 'korona/master/suppliers/business/link/',
    stockOrdersUrl: BASE_URL + 'korona/stockOrders/',
    stockOrderSummaryDataUrl: BASE_URL + 'korona/stockOrders/summary/',
    createStockOrderUrl: BASE_URL + 'korona/stockOrders/',
    getSOLinkedSupliersUrl: BASE_URL + 'korona/stock-orders/get_linked_suppliers/',
    uploadInvoicePDFUrl: BASE_URL + 'korona/stock-orders/invoice-pdf-parser/upload',
    parsePDFUrl: BASE_URL + 'korona/stock-orders/invoice-pdf-parser/upload',
    matchInvoiceProductsUrl: BASE_URL + 'korona/stock-orders/match-so-invoice-products',
    updateProductRetailerUrl: BASE_URL + 'korona/stock-orders/update-product-retailers-supplier-record',
    tempSOCustomerProductLinkUrl : BASE_URL + 'korona/stock-orders/temp-so-customer-prod-link',
    changeSRRocTempRecUrl : BASE_URL + 'korona/stock-orders/change-roc-temp-rec',
};

export const getStockOrdersByPage = (
    business_id: number | string,
    page: number, 
    pageSize: number, 
    searchTerm: string,
    orderBy: string | number,
) => {
    let URL = `${API_URLS.stockOrdersUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${API_URLS.stockOrdersUrl}?business_id=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getMatchedInvoiceItemList = (payload: any) => {
    const URL = API_URLS.matchInvoiceProductsUrl;
    return composeRequest(URL, 'POST', JSON.stringify(payload));
};

export const addStockOrder = (data: any) => {
    const URL = API_URLS.createStockOrderUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const getStockOrdersSummary = (business_id: number | string) => {
    const URL = `${API_URLS.stockOrderSummaryDataUrl}?business_id=${business_id}`;
    return composeRequest(URL, 'GET', null);
};

export const searchMasterSuppliersLinked = (businessId: number | string, searchTerm: string) => {
    const URL = `${API_URLS.masterSuppliersLinkedUrl}?search=${searchTerm}&business__id=${businessId}`;
    return composeRequest(URL, 'GET', null);
};

export const uploadInvoicePDf = (payload:any) => {
    const URL = `${API_URLS.uploadInvoicePDFUrl}`;
    return composeRequest(URL, 'PUT', payload, "multipart/form-data");
};

export const parsePdf=(
    business_id: number,
    stored_aws_response_id :number,
    supplier_id:number,
    master_company_exists?: boolean,
    parser_setting_id?: number
)=>{
    let URL = `${API_URLS.parsePDFUrl}?business_id=${business_id}&stored_aws_response_id=${stored_aws_response_id}&supplier_id=${supplier_id}`;
    if (master_company_exists){
        URL = URL + `&master_company_exists=${master_company_exists}`
    }
    if (parser_setting_id){
        URL = URL + `&parser_setting_id=${parser_setting_id}`
    }

    console.log("URL",URL)
    return composeRequest(URL, 'GET', null)

};

export const postUpdateProducts = (payload:any) =>{
    const URL =`${API_URLS.updateProductRetailerUrl}`;
    return composeRequest(URL, 'POST', JSON.stringify(payload))

};

export const changeSORocTempRec =(data:any)=>{
    const URL = API_URLS.changeSRRocTempRecUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}
  

export const getSOLinkedSuppliers=(
    business_id: number
)=>{
    let URL = `${API_URLS.getSOLinkedSupliersUrl}?business_id=${business_id}`
    return composeRequest(URL, 'GET', null)
};
