import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { SupplierProduct } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS as MasterDataUrls } from '../../../master-data/api';
import composeRequest, { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableTitle: {
            color: GlobalStyles.primaryColor,
            padding: '0 16px'
        },
        tableTitleIcon: {
            display: 'flex',
            alignSelf: 'center',
            color: GlobalStyles.iconColorLight
        },
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem'
        },
        progress: {
            margin: theme.spacing(2),
        },
    }),
);

const getSupplierProductsByPage = (masterSupplierId: string, page: number,
    pageSize: number, searchTerm: string) => {
    let URL = MasterDataUrls.masterSupplierProductsLinkUrl +
        `?master_supplier__id=${masterSupplierId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = MasterDataUrls.masterSupplierProductsLinkUrl +
            `?master_supplier__id=${masterSupplierId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

const SupplierProductListScreen = (props: any) => {
    // console.log(props.userLocalData.userData.userData.supplier);
    const { master_supplier: masterSupplier } = props.userLocalData.userData.userData.supplier;
    const supplierId = masterSupplier.id;
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();

    const navigateToProductDetailScreen = (rowData: any) => {
        // const productDetailUrl = '/web/products/' + rowData.product.uuid;
        // props.history.push(productDetailUrl, rowData.product)
    };

    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div style={{ maxWidth: '100%' }}>
                <div className="table-wrapper">
                    <MaterialTable
                        options={{
                            debounceInterval: 500,
                            pageSize: 10,
                            pageSizeOptions: [10, 50, 100, 500, 1000],
                            headerStyle: {
                                backgroundColor: '#eee',
                            },
                            // padding: 'dense',
                            emptyRowsWhenPaging: false,
                        }}
                        tableRef={tableRef}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataSourceMessage
                            }
                        }}
                        onRowClick={(event, rowData) => navigateToProductDetailScreen(rowData)}
                        columns={[
                            {
                                title: 'Row',
                                field: 'supplier_reorder_code',
                                render: (rowData: any) => {
                                    // console.log(rowData)
                                    return <div>{rowData['tableData'].id + 1}</div>;
                                }
                            },
                            { title: 'Product', field: 'supplier_product_name' },
                            { title: 'Container Amount', field: 'supplier_container_qty', type: 'numeric' },
                            { title: 'Reorder Code', field: 'supplier_reorder_code' },
                            {
                                title: 'Category',
                                field: 'supplier_product_name',
                                render: rowData => {
                                    return <div></div>;
                                },
                            },
                        ]}
                        data={query =>
                            new Promise((resolve, reject) => {
                                let pageData = {
                                    data: [] as SupplierProduct[],
                                    totalCount: 0,
                                    page: query.page
                                };
                                getSupplierProductsByPage(supplierId,
                                    query.page + 1, query.pageSize, query.search)
                                    .catch(handleError) // handle network issues
                                    .then(checkStatus)
                                    .then(parseJSON)
                                    .then((data: PaginatedResponse<SupplierProduct>) => {
                                        setErrorStatusVisible(false);
                                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                        pageData = {
                                            data: data.results,
                                            totalCount: data.count,
                                            page: query.page
                                        };
                                        resolve(pageData);
                                    })
                                    .catch((error: any) => {
                                        if (error) {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_500);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)
                                            ) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                        }
                                        resolve(pageData);
                                    });
                            })
                        }
                        title={
                            <CustomTableTitle
                                history={props.history}
                                title={'Products'}
                                icon={SVG_ICONS.product} />
                        }
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(SupplierProductListScreen);