import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { AuthLayout } from 'components/src/modules/page/layouts';
import SignInScreen from 'components/src/modules/auth/screens/SignIn/SignInScreen.web';
// import KioskLayout from 'components/src/modules/kiosk/layout/KioskLayout/KioskLayout';
// import KioskSignInScreen from 'components/src/modules/auth/screens/KioskSignIn/KioskSignInScreen';

import {
	RetailerPrimaryMenuItems, RetailerSecondaryMenuItems,
	SupplierPrimaryMenuItems, SupplierSecondaryMenuItems
} from 'components/src/modules/page/components/SideDrawer/Menu/MenuItems';
import { getAuthorizedRoutes, RetailerRoutes, SupplierRoutes } from 'components/src/modules/page/Routes/Routes';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { Notifier } from 'components/src/modules/shared/components/';
import { getAuthorizedMenuItems } from './AuthorizedRoutes';
import { PageRedirect } from 'components/src/modules/page/components';


const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#1976d2',
		},
		secondary: {
			main: '#ffb300',
		},
	},
});

const isUserAuthenticated = () => {
	return window.localStorage.getItem('headers') && window.localStorage.getItem('persist:root');
};

const PrivateRoute = (props: any) => {
	const { component: Component, drawerMenuData, ...rest } = props;
	return (
		<Route
			{...rest}
			render={(props) => isUserAuthenticated()
				? <Component {...props} drawerMenuData={drawerMenuData} />
				: <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />}
		/>
	);
};

const AppNavigator = (props: any) => {
	const [drawerMenuData, setDrawerMenuData] = useState({
		primaryMenuItems: RetailerPrimaryMenuItems,
		secondaryMenuItems: RetailerSecondaryMenuItems,
		routes: RetailerRoutes
	});

	useEffect(() => {
		if (props.userLocalData && props.userLocalData.userData) {
			const { userData: user } = props.userLocalData.userData;
			if (user) {
				const permissions = user.user_permissions;
				if (user.supplier) {
					const data = {
						primaryMenuItems: getAuthorizedMenuItems(SupplierPrimaryMenuItems, permissions, user.is_superuser),
						secondaryMenuItems: getAuthorizedMenuItems(SupplierSecondaryMenuItems, permissions, user.is_superuser),
						routes: getAuthorizedRoutes(SupplierRoutes, permissions, user.is_superuser)
					};
					setDrawerMenuData(data);
				} else {
					const data = {
						primaryMenuItems: getAuthorizedMenuItems(RetailerPrimaryMenuItems, permissions, user.is_superuser),
						secondaryMenuItems: getAuthorizedMenuItems(RetailerSecondaryMenuItems, permissions, user.is_superuser),
						routes: getAuthorizedRoutes(RetailerRoutes, permissions, user.is_superuser)
					};
					setDrawerMenuData(data);
				}
			}
		}

	}, [props.userLocalData]);

	return (
		<MuiThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={3}>
				<Notifier />
				<Router>
					<Switch>
						<Route exact path='/sign-in' component={SignInScreen} />
						<Route exact path='/' component={SignInScreen} />
						{/* <Route path='/ext/:token/:path' component={BlankLayout} /> */}
						{/* <Route path='/kiosk' component={KioskLayout} /> */}
						{/* <Route exact path='/kiosk-sign-in' component={KioskSignInScreen} /> */}

						<PrivateRoute path='/web'
							drawerMenuData={drawerMenuData}
							component={AuthLayout} />
						<Route path="*"
							render={() =>
								<PageRedirect location={null}
									showBackground={true}
									user={props.userLocalData.userData.userData} />} />
					</Switch>
				</Router>
			</SnackbarProvider>
		</MuiThemeProvider>
	);
};


const mapStateToProps = (state: any) => {
	return {
		userLocalData: state.userLocalData
	};
};

export default connect(mapStateToProps, null)(AppNavigator);
