import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { ExpectedStockByCommodityGroup, ExpectedStockBySupplier } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { getExpectedStockByWeek } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { hasUpdatePermission, isSuperAdmin } from '../../../shared/lib/PermissionManager';
import {
    Toast,
    enqueueSnackbar,
    ToastSuccess,
    ToastError,
} from '../../../shared/components/Notifier';
import {
    CustomTableTitle,
    ErrorStatusBar,
    ExportMenu,ExportStatusBar,
} from '../../../shared/components';
import {
    NO_PERMISSIONS_ERROR_MSG,
    EMPTY_ROW_MSG,
    ERROR_500,
    ERROR_MSG,
} from '../../../shared/lib/Localization';
import {
    initQueryParams,
    replaceHistory,
    useQuery,
    tableOptions,
} from '../../../shared/lib/TableQueryParams';
import isEqual from 'lodash/isEqual';
import download from 'downloadjs';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem',
        },
        progress: {
            margin: theme.spacing(2),
        },
        toolbar: {
            width: '80%',
            // marginTop: '2'
        },
        greenCircle: {
            color: GlobalStyles.green,
        },
        redCircle: {
            color: GlobalStyles.warningText,
        },
        helpTooltipRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 8,
        },
        helpTooltipText: {
            padding: '0 8px 0 0',
        },
    })
);
// const permissionModel = 'unknownidentifierreport';

const timeDisplayFormat = 'YYYY-MM-DD HH:mm';

const ExpectedStockReport = {
    BySupplier: 'by_supplier',
    ByCommodityGroup: 'by_cgroup',
};

const initColumns = (reportType: string) => {
    const emptyValue = () => <div style={{ textAlign: 'center' }}>---</div>;
    const rightAlign = { textAlign: 'right' };
    return [
        {
            title: 'Commodity Group',
            field: 'commodity_group_name',
            hidden: reportType === 'by_supplier',
            cellStyle: { textAlign: 'left' },
        },
        {
            title: 'Supplier',
            field: 'supplier_name',
            hidden: reportType === 'by_cgroup',
            cellStyle: { textAlign: 'left' },
        },
        {
            title: 'Past Due Units',
            field: 'past_due_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Past Due Value',
            field: 'past_due_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'This Wk Units',
            field: 'week_0_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'This Wk Value',
            field: 'week_0_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 1 Units',
            field: 'week_1_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 1 Value',
            field: 'week_1_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 2 Units',
            field: 'week_2_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 2 Value',
            field: 'week_2_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 3 Units',
            field: 'week_3_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 3 Value',
            field: 'week_3_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 4 Units',
            field: 'week_4_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 4 Value',
            field: 'week_4_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 5 Units',
            field: 'week_5_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Wk 5 Value',
            field: 'week_5_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Unk Date Units',
            field: 'unknown_pending_units',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
        {
            title: 'Unk Date Value',
            field: 'unknown_pending_value',
            emptyValue: emptyValue,
            cellStyle: rightAlign,
            sorting: false
        },
    ];
};

const ExpectedStockByWeekScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; onChangePage: any }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [reportType, setReportType] = useState<'by_cgroup' | 'by_supplier'>('by_cgroup');
    const [filterBtnAnchorEl, setFilterBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isLoading, setLoading] = useState(false);
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isExporting, setExporting] = useState(false);
    const [initialLoad,setInitialLoad]=useState(true)
    const theme = useTheme();

    const [columns, setColumns] = useState(initColumns('by_cgroup'));

    const handleFilterBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFilterBtnAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (!isLoading) {
            setColumns(initColumns(reportType));
            console.log("API CALL FOR REPORT CHANGE")
            tableRef.current && tableRef.current.onChangePage(null, 0);
        }
    }, [reportType]);

    useEffect(() => {
        if (!isLoading && !initialLoad) {
            console.log("API CALL FOR BUSSINESS CHANGE")
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);

    const handleReportTypChange = (
        event: React.ChangeEvent<{ value: 'by_cgroup' | 'by_supplier' }>
    ) => {
        setReportType(event.target.value);
    };

    const exportCSV = () => {
        if (props.userData && props.userData.selectedBusiness) {
            setExporting(true);
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            getExpectedStockByWeek(
                reportType,
                sb.business_id,
                null,
                null,
                tableQuery.search,
                sb.orderBy,
                null,
                shouldExport
            )
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then((res) => res.blob())
                .then((data) => {
                    download(data, 'Expected_Stock_By_Week.csv', 'text/csv');
                    setExporting(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if (error.status === 403 || error.status === 401) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                        } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        }
                    }
                });
        }
    };

    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = (shouldExport: boolean) => {
        if (shouldExport) {
            exportCSV();
        }
        setExportBtnAnchorEl(null);
    };

    const renderTableToolbarComponent = (props) => (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%'
        }}>
            <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
            <FormControl className={classes.formControl}
                style={{
                    marginRight: 16,
                    marginBottom: 10,
                    marginTop: 8

                }}>
                <Select
                    // labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reportType}
                    onChange={handleReportTypChange}
                >
                    <MenuItem value={'by_cgroup'}>By Commodity Group</MenuItem>
                    <MenuItem value={'by_supplier'}>By Supplier</MenuItem>
                </Select>
            </FormControl>
            <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

        </div>
    );

    return (
        <Container maxWidth={false}>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <ExportStatusBar isVisible={isExporting} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true  
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage,
                        },
                    }}
                    //@ts-ignore
                    columns={columns}
                    components={{
                        Toolbar: renderTableToolbarComponent
                    }}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            if (!isLoading) {
                                // console.log('query', query);
                                const sb = props.userData.selectedBusiness;
                                if (sb && sb.business_id) {
                                    replaceHistory(query, props.history);
                                    let pageData = {
                                        data: [] as any[],
                                        totalCount: 0,
                                        page: query.page,
                                    };
                                    setLoading(true);
                                    let orderBy = '';
                                    if (query.orderBy) {
                                        orderBy = query.orderBy.field as string;
                                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                    }
                                    getExpectedStockByWeek(
                                        reportType,
                                        sb.business_id,
                                        query.page + 1,
                                        query.pageSize,
                                        query.search,
                                        orderBy,
                                        null,
                                        false
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then(
                                            (
                                                data: PaginatedResponse<
                                                    | ExpectedStockByCommodityGroup
                                                    | ExpectedStockBySupplier
                                                >
                                            ) => {
                                                setErrorStatusVisible(false);
                                                setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                                pageData = {
                                                    data: data.results,
                                                    totalCount: data.count,
                                                    page: query.page,
                                                };
                                                setTableQuery({
                                                    ...tableQuery,
                                                    orderBy,
                                                    totalCount: data.count,
                                                    page: query.page,
                                                    pageSize: query.pageSize,
                                                    search: query.search,
                                                });
                                                setLoading(false);
                                                setInitialLoad(false);
                                                resolve(pageData);
                                            }
                                        )
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if (
                                                    error.status === 403 ||
                                                    error.status === 401
                                                ) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                                search: query.search,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={`Expected Stock By Week ${
                                reportType === 'by_supplier' ? 'By Supplier' : 'By Commodity Group'
                            }`}
                            icon={SVG_ICONS.stockOrder}
                        />
                    }
                    // components={{
                    //     Actions: (props) => {
                    //         console.log(props);
                    //         // return null;
                    //         return <MTableActions>{...props}</MTableActions>;
                    //         // <FormControl className={classes.formControl}>
                    //         //     <InputLabel id="demo-simple-select-label">Report</InputLabel>
                    //         //     <Select
                    //         //         labelId="demo-simple-select-label"
                    //         //         id="demo-simple-select"
                    //         //         value={reportType}
                    //         //         onChange={handleReportTypChange}
                    //         //     >
                    //         //         <MenuItem value={'by_cgroup'}>By Commodity Group</MenuItem>
                    //         //         <MenuItem value={'by_supplier'}>By Supplier</MenuItem>
                    //         //     </Select>
                    //         // </FormControl>
                    //     },
                    // }}
                    actions={[
                        {
                            icon: 'save_alt',
                            tooltip: 'Export',
                            isFreeAction: true,
                            onClick: handleExportClick,
                            disabled: isExporting,
                        },
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        // {
                        //     icon: 'filter_list',
                        //     tooltip: 'Filter By Status',
                        //     isFreeAction: true,
                        //     onClick: handleFilterBtnClick,
                        //     iconProps: { color: 'primary' },
                        // },
                        // {
                        //     icon: () => (
                        //         // <FormControl className={classes.formControl}>
                        //         //     <InputLabel id="demo-simple-select-label" />
                        //         <Select
                        //             // labelId="demo-simple-select-label"
                        //             id="demo-simple-select"
                        //             value={reportType}
                        //             onChange={handleReportTypChange}
                        //         >
                        //             <MenuItem value={'by_cgroup'}>By Commodity Group</MenuItem>
                        //             <MenuItem value={'by_supplier'}>By Supplier</MenuItem>
                        //         </Select>
                        //         // </FormControl>
                        //     ),
                        //     isFreeAction: true,
                        //     onClick: (event, rowData) => null,
                        // },
                    ]}
                />
                {/* <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />
                <Menu
                    id="filter-menu"
                    anchorEl={filterBtnAnchorEl}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(filterBtnAnchorEl)}
                    onClose={() => handleFilterMenuClose(null)}
                >
                    <MenuItem disabled={true}>Show codes with status:</MenuItem>
                    {statusFilters.map((f) => {
                        const selected = f.id === processedFilter.id;
                        return (
                            <MenuItem
                                key={`filter-${f.id}`}
                                style={{ color: selected ? theme.palette.primary.main : 'inherit' }}
                                onClick={() => handleFilterMenuClose(f)}
                                selected={selected}
                            >
                                {f.label}
                            </MenuItem>
                        );
                    })}
                </Menu> */}
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExpectedStockByWeekScreen);
