import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import {
    Button, MenuItem, Select,
    Container, Switch,
    Typography, SvgIcon
} from '@material-ui/core';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
// import { Device } from '../../models';
// import { getDevicesByPage, deleteDevice, updateDevice } from '../../api';
// import { addDevice } from '../../data/actions';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { PaginatedResponse, Business } from '../../../shared/models';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import {
    EMPTY_ROW_MSG,
    ERROR_MSG,
    ERROR_500,
    NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import {
    isSuperAdmin,
    hasAddPermission,
    hasUpdatePermission,
    hasDeletePermission,
} from '../../../shared/lib/PermissionManager';
import {
    initQueryParams,
    replaceHistory,
    tableOptions,
    useQuery,
} from '../../../shared/lib/TableQueryParams';
import {
    Toast,
    enqueueSnackbar,
    ToastSuccess,
    ToastError,
} from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { getBusinessByPage } from '../../api';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
// import { RegisterDeviceModal } from '../../components';
// const QRCode = require('qrcode.react');
const useStyles = makeStyles((theme: Theme) => createStyles({}));
const permission_model = 'device';

const registerDeviceModalData = {
    open: false,
    onClose: null,
};

const BusinessListScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const [tableEditableProp, setTableEditableProp] = useState({});
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);
    const [deviceCode, setDeviceCode] = useState('');
    const [newDeviceModalData, setNewDeviceModalData] = useState(registerDeviceModalData);

    useEffect(() => {
        if (isSuperAdmin(props)) {
            props.getBusinessList();
        }
    }, []);

    useEffect(() => {
        if (isSuperAdmin(props) && !isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);



    const SelectBusinessEditComponent = (componentProps: any) => {
        const { value, onChange } = componentProps.colDataProps;
        return (
            <Select
                value={value ? value : ''}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                inputProps={{
                    name: 'business',
                    id: 'select-business',
                }}
            >
                {props.businessList &&
                    props.businessList.data &&
                    props.businessList.data.map((b: Business) => (
                        <MenuItem value={b.id} key={String(b.id)}>
                            {b.name}
                        </MenuItem>
                    ))}
            </Select>
        );
    };

    const isSuperUser = () => { };

    return (
        <Container maxWidth="lg">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true,
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage,
                        },
                    }}
                    columns={[
                        {
                            title: 'ID',
                            field: 'id',
                            align: 'center',
                            hidden: !isSuperAdmin(props),
                            editComponent: (props) => {
                                return <SelectBusinessEditComponent colDataProps={props} />;
                            },
                        },            
                        { title: 'Name', field: 'name' },
                        {
                            title: 'Supplier',
                            field: 'is_supplier',
                            type: 'boolean',
                            align: 'center',
                            tooltip: 'Save User',
                            sorting: false,
                            render: (rowData: any) => (
                                rowData.is_supplier && <FiberManualRecordIcon
                                    style={{ color: rowData.is_supplier ? GlobalStyles.green : null }}
                                /> 
                            ),
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            if (!isLoading && props.userData) {
                                // const sb = props.userData.selectedBusiness;
                                replaceHistory(query, props.history);
                                let pageData = {
                                    data: [] as Business[],
                                    totalCount: 0,
                                    page: query.page,
                                };
                                setLoading(true);
                                let orderBy = '';
                                if (query.orderBy) {
                                    orderBy = query.orderBy.field as string;
                                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                }
                                // if (sb && sb.business_id) {
                                    getBusinessByPage(
                                        query.page + 1,
                                        query.pageSize,
                                        query.search,
                                        orderBy
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<Business>) => {
                                            console.log("======", data)
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page,
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if (
                                                    error.status === 403 ||
                                                    error.status === 401
                                                ) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize,
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                // }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Businesses'}
                            icon={SVG_ICONS.business}
                        />
                    }
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit User',
                            onClick: (event, rowData) => props.history.push(`/web/business/update-business/${rowData.id}`, rowData)
                        },
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        {
                            icon: 'add_box',
                            tooltip: 'Add Business',
                            isFreeAction: true,
                            onClick: (event) =>
                                props.history.push("/web/business/create-business"),
                        },
                    ]}
                />
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        businessList: state.sharedModuleData.businessList,
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        // addDevice: (businessData: any) => dispatch(addDevice(businessData)),
        getBusinessList: () => dispatch(getBusinessListFromAPI()),
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessListScreen);
