import { SET_DISPATCH_NOTIFICATION_ITEMS, ADD_NEW_DISPATCH_ITEM,UPDATE_DISPATCH_NOTIFICATION_ITEMS,DELETE_DISPATCH_NOTIFICATION_PRODUCT_IN_LIST, CLEAR_DISPATCH_NOTIFICATIONS_ITEMS, SET_VENDOR_DATA } from './actionTypes'

export const saveDnList = (data: any) => {
  return {
      type: SET_DISPATCH_NOTIFICATION_ITEMS,
      data
  };
};

export const addNewDnItem = (data: any) => {
  return {
      type: ADD_NEW_DISPATCH_ITEM,
      data
  };
};

export const updateDnProductInList = (data: any) => {
  return {
      type: UPDATE_DISPATCH_NOTIFICATION_ITEMS,
      data
  };
};

export const deleteDnProductInList = (data: any) => {
  return{
      type:DELETE_DISPATCH_NOTIFICATION_PRODUCT_IN_LIST,
      data
  }

}

export const clearDnData = () => {
  return {
      type: CLEAR_DISPATCH_NOTIFICATIONS_ITEMS
  };
};

export const saveVendorData = (data: any) => {
  return {
      type: SET_VENDOR_DATA,
      data
  };
};
