import React, { useEffect, useState, useRef } from 'react';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { MasterProduct, MasterSupplier, MasterSupplierProduct } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { getMasterProductsByPage, API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';

//@ts-nocheck

interface LinkProductSupplierModalModalProps {
  onClose?: (canceled: boolean, success: boolean, errorData?: any) => any,
  isOpen: boolean,
  mode: string,
  onSave?: () => any,
  onUpdate?: () => any,
  master_supplier: MasterSupplier,
  linkedRecord?: MasterSupplierProduct;
}

const noError = {
  hasError: false,
  message: ''
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F3F5F7'
    },
    iconButton: {
      margin: theme.spacing(1),
      alignSelf: 'flex-end'
    }
  }),
);

export default function LinkProductSupplierModal(props: LinkProductSupplierModalModalProps) {
  const classes = useStyles({});
  const [selectedMasterSupplier, setSelectedMasterSupplier] = useState(props.master_supplier);
  const [selectedMasterProduct, setSelectedMasterProduct] = useState(null);
  const [selectedNewMasterProduct, setSelectedNewMasterProduct] = useState(null);
  const [supplierProductName, setSupplierProductName] = useState('');
  const [supplierReorderCode, setSupplierReorderCode] = useState('');
  const [supplierContainerQty, setSupplierContainerQty] = useState('');
  const [isMasterProductField, setMasterProductField] = useState(false);
  const [productNameError, setProductNameError] = useState({ hasError: false, message: '' });
  const [supplierQtyError, setSupplierQtyError] = useState({ hasError: false, message: '' });
  const [masterProductError, setMasterProductError] = useState({ hasError: false, message: '' });

  const masterProductTableRef = useRef<{ onQueryChange: any; }>();

  useEffect(() => {
    if (props.linkedRecord) {
      // setSelectedMasterSupplier({ ...props.master_supplier })
      setSelectedMasterProduct({ ...props.linkedRecord.master_product });
      setSupplierProductName(props.linkedRecord.supplier_product_name);
      setSupplierReorderCode(props.linkedRecord.supplier_reorder_code);
      setSupplierContainerQty(String(props.linkedRecord.supplier_container_qty));
    }
  }, [props.linkedRecord]);

  useEffect(() => {
    if (props.master_supplier && props.master_supplier.name) {
      setSelectedMasterSupplier({ ...props.master_supplier });
    }
  }, [props.master_supplier]);

  const getCurrentMasterProductLabel = () => {
    if (props.mode === 'edit') {
      return 'Current Master Product';
    }
    return 'Master Product';
  };

  const validateFields = () => {
    if (!selectedMasterProduct) {
      setMasterProductError({ hasError: true, message: 'This field is required.' });
      return false;
    } else {
      setMasterProductError(noError);
    }
    if (!supplierProductName || supplierProductName.length < 3) {
      setProductNameError({ hasError: true, message: 'Product name must have at least 3 characters.' });
      return false;
    } else {
      setProductNameError(noError);
    }
    if (!supplierContainerQty || supplierContainerQty === '') {
      setSupplierQtyError({ hasError: true, message: 'This field is required.' });
      return false;
    } else if (!Number(supplierContainerQty)) {
      setSupplierQtyError({ hasError: true, message: 'This field must be number.' });
    } else {
      setSupplierQtyError(noError);
    }
    return true;
  };

  const addRecord = () => {
    if (selectedMasterSupplier) {
      const isValid = validateFields();
      if (isValid) {
        const URL = API_URLS.masterSupplierProductsLinkUrl;
        const data = {
          master_supplier: props.master_supplier.id,
          master_product: selectedMasterProduct.id,
          supplier_product_name: supplierProductName,
          supplier_reorder_code: supplierReorderCode,
          supplier_container_qty: supplierContainerQty
        };
        composeRequest(URL, 'POST', JSON.stringify(data))
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            resetFormData();
            props.onClose(false, true);
          })
          .catch((error: any) => {
            resetFormData();
            props.onClose(false, false, error);
          });
      }
    }
  };

  const updateRecord = () => {
    const isValid = validateFields();
    if (isValid) {
      const URL = API_URLS.masterSupplierProductsLinkUrl + props.linkedRecord.id + '/';
      const data = {
        id: props.linkedRecord.id,
        master_supplier: props.master_supplier.id,
        master_product: selectedMasterProduct.id,
        supplier_product_name: supplierProductName,
        supplier_reorder_code: supplierReorderCode,
        supplier_container_qty: supplierContainerQty
      };
      if (isMasterProductField && selectedNewMasterProduct && selectedNewMasterProduct.id) {
        data.master_product = selectedNewMasterProduct.id;
        // 
      }
      composeRequest(URL, 'PUT', JSON.stringify(data))
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          resetFormData();
          props.onClose(false, true);
        })
        .catch((error: any) => {
          resetFormData();
          props.onClose(false, false, error);
        });
    }

  };

  const resetFormData = () => {
    setSupplierProductName('');
    setSupplierReorderCode('');
    setSupplierContainerQty('');
    setSelectedNewMasterProduct(null);
    setMasterProductField(false);
  };

  const onCancel = () => { resetFormData(); props.onClose(true, false); };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={props.isOpen}
        onClose={() => props.onClose(true, false)}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {
            props.mode === 'add' ? 'New Supplier Product Link Record' : 'Edit Supplier Product Linked Record'
          }
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <MaterialTable
              options={{
                debounceInterval: 650,
                pageSize: 5,
                padding: 'dense',
                headerStyle: {
                  backgroundColor: '#eee',
                  paddingTop: 6,
                  paddingBottom: 6
                },
                emptyRowsWhenPaging: false,
                maxBodyHeight: 300,
                showTitle: false,
                selection: true,
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                searchFieldAlignment: 'left',
                selectionProps: rowData => {
                  const checked = rowData && rowData.tableData && rowData.tableData.checked;
                  const selectionProps = {
                    disabled: selectedMasterProduct && !checked ? true : false,
                    color: 'primary'
                  };
                  if (props.mode !== 'add') {
                    selectionProps.disabled = selectedNewMasterProduct && !checked ? true : false;
                  }
                  return selectionProps;
                }
              }}
              columns={[
                { title: 'ID', field: 'id', editable: 'never' },
                { title: 'Master Product', field: 'name' },
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  if (props.mode === 'add') {
                    setSelectedMasterProduct(null);
                  } else if (props.mode === 'edit') {
                    setSelectedNewMasterProduct(null);
                  }
                  getMasterProductsByPage(query.page + 1,
                    query.pageSize, query.search)
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<MasterProduct>) => {
                      const pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      resolve(pageData);
                    });
                })
              }
              tableRef={masterProductTableRef}
              onSelectionChange={(rows) => {
                if (rows.length > 0) {
                  let masterProduct = { ...rows[0] };
                  delete masterProduct['tableData'];
                  if (props.mode === 'add') {
                    setSelectedMasterProduct(masterProduct);
                    setMasterProductError(noError);
                  } else {
                    setMasterProductField(true);
                    setSelectedNewMasterProduct(masterProduct);
                  }
                } else {
                  if (props.mode === 'add') {
                    setSelectedMasterProduct(null);
                  } else {
                    setSelectedNewMasterProduct(null);
                  }
                }
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                id="selected-master-supplier"
                disabled={true}
                label='Master Supplier'
                margin="normal"
                value={selectedMasterSupplier ? selectedMasterSupplier.name : ''}
              />
              <div style={{ display: 'flex' }}>
                <TextField
                  id="selected-master-product"
                  error={masterProductError.hasError}
                  helperText={masterProductError.hasError ? masterProductError.message : ''}
                  required={true}
                  disabled={props.mode === 'edit' ? true : false}
                  label={getCurrentMasterProductLabel()}
                  margin="normal"
                  value={selectedMasterProduct ? selectedMasterProduct.name : ''}
                />
                {props.mode === 'edit' &&
                  <IconButton className={classes.iconButton} aria-label="edit"
                    onClick={() => setMasterProductField(true)}>
                    <EditIcon />
                  </IconButton>
                }
              </div>
              {
                isMasterProductField &&
                <div style={{ display: 'flex' }}>
                  <TextField
                    id="selected-new-master-product"
                    label="New Master Product"
                    margin="normal"
                    value={selectedNewMasterProduct ? selectedNewMasterProduct.name : ''}
                  />
                  <IconButton className={classes.iconButton} aria-label="clear"
                    onClick={() => {
                      setSelectedNewMasterProduct(null);
                      setMasterProductField(false);
                      masterProductTableRef.current && masterProductTableRef.current.onQueryChange();
                    }}>
                    <ClearIcon />
                  </IconButton>
                </div>
              }
              <TextField
                id="supplier-product-name"
                required={true}
                error={productNameError.hasError}
                helperText={productNameError.hasError ? productNameError.message : ''}
                label="Supplier Product Name"
                margin="normal"
                value={supplierProductName}
                onChange={(e) => {
                  if (e.target.value.length > 3) {
                    setProductNameError(noError);
                  }
                  setSupplierProductName(e.target.value.trim());
                }}
              />
              <TextField
                id="supplier-reorder-code"
                required={false}
                label="Supplier ReOrder Code"
                margin="normal"
                value={supplierReorderCode}
                onChange={(e) => setSupplierReorderCode(e.target.value)}
              />
              <TextField
                id="supplier-container-qty"
                type={'number'}
                required={true}
                error={supplierQtyError.hasError}
                helperText={supplierQtyError.hasError ? supplierQtyError.message : ''}
                label="Supplier Container Qty"
                margin="normal"
                value={supplierContainerQty}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setSupplierQtyError(noError);
                  }
                  setSupplierContainerQty(e.target.value.trim());
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions >
          <Button onClick={onCancel} color="primary">Cancel</Button>
          {
            props.mode === 'add' &&
            <Button onClick={addRecord} color="primary">Save</Button>
          }
          {
            props.mode === 'edit' &&
            <Button onClick={updateRecord} color="primary">Update</Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
