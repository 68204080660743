import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    deviceListUrl: BASE_URL + 'core/device/',
    deviceCountUrl: BASE_URL + 'core/device/count/',
    deviceSummaryDataUrl: BASE_URL + 'core/device/summary/',
    deviceRequestsUrl: BASE_URL + 'core/device-request/incoming/',
     deviceVersionCreateUrl: BASE_URL + 'core/device/business/available-updates/',
    deviceCurrentVersionUrl: BASE_URL + 'core/device/available-updates/current-version/',
};

export const updateDevice = (data: any) => {
    const URL = API_URLS.deviceListUrl + `${data.id}/`;
    data.device_platform = 'android';
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const addDevice = (data: any) => {
    const URL = API_URLS.deviceListUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const deleteDevice = (data: any) => {
    const URL = API_URLS.deviceListUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getDevicesByPage = (
    businessId: string | number, 
    page: number, 
    pageSize: number, 
    searchTerm: string | null,
    orderBy?: string | number,

) => {
    let URL = API_URLS.deviceListUrl + '?business_id=' + businessId + '&page=' + page + '&page_size=' + pageSize;
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getDeviceRequests = (code: string) => {
    let URL = `${API_URLS.deviceRequestsUrl}?code=${code}`;
    return composeRequest(URL, 'GET', null);
};


export const createDeviceVersion = (data: any) => {
    let URL = API_URLS.deviceVersionCreateUrl;
    return composeRequest(URL, 'POST', data, 'multipart/form-data')
};

export const getCurrentVersion = (data: any) => {
    let URL = API_URLS.deviceCurrentVersionUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data))
};
