import React, { useEffect, useState, useRef, } from 'react';
import { connect } from 'react-redux';
import {
  Button, SvgIcon, Tabs, Tab, Paper, Typography, Box, TextField, TextareaAutosize,
  Select, MenuItem
} from '@material-ui/core';
import { Toast, enqueueSnackbar } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TEXTRACT_MODELS } from '../../../stockOrder/models';



export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      // maxWidth: "100%",
      // padding: theme.spacing(1, 1),
      borderRadius: 0,
      paddingTop: 12,
      paddingLeft: 24,
      paddingRight: 24,
      // paddingBottom: 16
    },
    fileInputWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 12,
      paddingBottom: 12,
      alignSelf: 'flex-end'
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
    errorText: {
      color: '#f44336'
    },
    textLabel: {
      paddingLeft: 16,
      marginTop: 6,
      marginBottom: 6
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    instructions: {
      //paddingBottom: 12,
      alignSelf: 'center'
    },
    sectionTitle: {
      paddingTop: 16,
      paddingBottom: 16
    },
    formRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '64px'
    },
    uploadContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px'
    },
    tab: {
      // flexGrow: 1,
      // backgroundColor: 'transparent',
      borderRight: `1px solid ${theme.palette.divider}`

    },
    tabRoot: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      maxHeight: '100%',
      overflow:'overlay',
    },
  }),
);


const JsonImport = (props: any) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //console.log("props.history",props.history);
  const {
    uploadPDF, parseCSV,
    selectedFile, selectedFileError,
    jsonUrl,
    handleJsonUrlChange,
    setVendorDataJson,
    vendorDataJson,
    tabVal,
    onTabChange,
    onFileSelectChangeHandler,
    handleJsonUrlSubmit,
    setTextractModel,
    textractModel
  } = props;


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{ }}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const handleUploadClick = (event: any) => {
    console.log("handle upload click", event.currentTarget);
    setAnchorEl(null);
  };

  const handleSelectedModelChange = (e: any) => {
    console.log("model value - ", e.target.value);
    // setSelectedTags([...value]);
    setTextractModel(e.target.value);
  }

  const renderModelOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.id}>
          {o.label}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    if (props.selectedFile) {
      console.log("IMPORT USEEFFECT")
      // console.log("props.selectedFile",props.selectedFile)
      const fileExtension = props.selectedFile.name.split('.').pop();
      const validExtensions = ['csv', 'tsv', 'txt'];
      if (fileExtension.toLowerCase() === 'pdf') {
        uploadPDF();
      } else if (validExtensions.includes(fileExtension)) {
        props.setIsFileExtensionPDF(false)
        parseCSV();
      }
      else if (fileExtension.toLowerCase() ==='json') {
        console.log("IT CAME UNDER JSON")
        const fileReader = new FileReader();
        fileReader.readAsText(props.selectedFile, "UTF-8");
        fileReader.onload = e => {
          setVendorDataJson(e.target.result);
        };
      }
    }
  }, [props.selectedFile,props.selectedSupplier]);

  const fileImport = () => {
    return (
      <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <div className={classes.formRow} style={{ justifyContent: 'center', width: '100%' }} >
          <Typography variant="body1" className={classes.instructions} style={{}}> Upload JSON, PDF, CSV or TSV file</Typography>
          <div style={{ alignSelf: 'center', marginLeft: 65, }}>
            <input
              type="file"
              name="upload-file"
              onInput={onFileSelectChangeHandler}
              id="contained-button-file"
              style={{ display: 'none' }}
              accept=".pdf,.tsv,.csv,.txt,.json" />
            <label htmlFor="contained-button-file">
              <Button component="span"
                variant="contained"
                color="primary"
                disabled={props.selectedFile ? true : false}
                onClick={handleUploadClick}
                startIcon={<SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>}>
                Upload
              </Button>
            </label>
          </div>
        </div>
        {/* <div className={classes.formRow} style={{ alignItems:null }}>
          <Typography variant="body1" className={classes.instructions}> Select Model &nbsp;</Typography>
          <div style={{ alignSelf: 'center'}}>
            
            <Select
              // label="Template"
              style={{ }}
              labelId="demo-simple-select-outlined-label"
              id={`so-model-select`}
              value={textractModel && textractModel != null ? textractModel : null}
              onChange={(e) => handleSelectedModelChange(e)}
              // variant="outlined"
              label={`Textract Model`}
              fullWidth={true}
              margin="dense"
            >
              {TEXTRACT_MODELS ? renderModelOptions(TEXTRACT_MODELS): <></>}
            </Select>
          </div>
        </div> */}
      </div>
      
    )
  }

  const jsonUrlImport = () => {
    return (
      <>
        <Typography variant="subtitle1" style={{ marginBottom: 8 }} className={classes.instructions}> Enter the URL to retrieve file from:  </Typography>
        <TextField
          style={{ minWidth:'60rem', maxWidth:'78rem', marginBottom: 8 }}
          margin="dense"
          variant="outlined"
          value={jsonUrl != null ? jsonUrl : ''}
          onChange={(event) => handleJsonUrlChange(event.target.value)}
          disabled={vendorDataJson !== null ? true : false}
        />
      </>
    )
  }


  const dataImport = () => {
    return (
      <>
        <Typography variant="subtitle1" style={{ marginBottom: 8 }} className={classes.instructions}> Enter raw data in the field:  </Typography>
        <TextareaAutosize
          disabled={jsonUrl != null ? true : false}
          style={{  width:'45rem' }}
          rowsMin={10}
          value={vendorDataJson != null ? vendorDataJson : ''}
          onChange={(event) => setVendorDataJson(event.target.value)}
        />
      </>
    )
  }

  return (
    <div style={{ display:'flex', flexDirection:'column',  width: '100%', maxHeight: '100%' }}>
      {
      
        <div className={classes.textLabel} style={{ display:'flex', justifyContent:'center'}}>
          <Typography variant="subtitle1" color='primary'>{`Please choose below to upload a file, provide a URL or add raw data`}</Typography>
        </div>
      }
      <div style={{ display: 'flex', justifyContent: 'space-between', maxHeight:'100%' }}>
        
        {
          !selectedFile && selectedFileError.hasError &&
          <div className={classes.textLabel}>
            <Typography variant="subtitle1" className={classes.errorText}>{selectedFileError.message}</Typography>
          </div>
        }
        <div className={classes.tabRoot} >
          <Tabs
            value={tabVal}
            onChange={onTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            orientation="vertical"
            className={classes.tab}
          >
            <Tab label={`Upload File`} value="file" />
            <Tab label={`URL to File`} value="json" />
            <Tab label={`Enter Data`} value="data" />
          </Tabs>

          <TabPanel value={tabVal} index={'file'} >
            {fileImport()}
          </TabPanel>
          <TabPanel value={tabVal} index={'json'}>
            {jsonUrlImport()}
          </TabPanel>
          <TabPanel value={tabVal} index={'data'}>
            {dataImport()}
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
    srData: state.stockReceiptModuleData.srData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JsonImport);
