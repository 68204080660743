import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Button, Paper, Typography, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, TextField
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { EXPECTED_HEADERS_SHORT_NAME } from '../../../models';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../../shared/lib/TableQueryParams';
import { LinkProductModal } from '../../../../master-data/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../../shared/components/Notifier';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, NO_PERMISSIONS_ERROR_MSG } from '../../../../shared/lib/Localization';
import FixInvoiceItemModal from '../../FixInvoiceItemModal/FixInvoiceItemModal';
import { getMatchedInvoiceItems, updateStockOrderProductInList, deleteStockOrderProductInList, addNewSOItem, getStockOrderProductsSuccess } from '../../../data/actions';
import isEqual from 'lodash/isEqual';
import { LocationBusiness } from '../../../../shared/models';
import { CustomTableTitle } from '../../../../shared/components';
import GlobalStyles from '../../../../../styles/GlobalStyles.web';
import { getMatchedInvoiceItemList } from '../../../api';
import { checkStatus, parseJSON, handleError } from '../../../../shared/api/core/common';
//import {getMatchedItemSummary} from '../../../screens/StockOrderImport/helper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import cloneDeep from 'lodash/cloneDeep';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { removeBussProdLinks } from '../../../../master-data/api/index'
import AddCommentIcon from '@material-ui/icons/AddComment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningText: {
      color: '#ff8c00',
      fontWeight: 400
    },
    tableWrapper: {
      padding: theme.spacing(2, 2),
      borderRadius: 0
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    summaryWrapper: {
      border: '1px solid #ddd',
      paddingLeft: 16,
      paddingRight: 16,
      marginBottom: 16
    },
    rowSummary: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // padding: 8

    },
    mainList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    errorText: {
      color: theme.palette.error.main,
      fontWeight: 400
    },
    successText: {
      color: '#43a047',
      fontSize: '0.875rem',
      fontWeight: 400
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    helpBtn: {
      margin: theme.spacing(1),
      padding: 8,

      // color: theme.palette.primary.main
    }
  }),
);
const initalDialogData = {
  open: false,
  business: null,
  onClose: null,
  mode: 'add',
  linkedRecord: null,
  suggestions: [],
  invoiceItemDescription: null
};

const fixDialogData = {
  open: false,
  onClose: null as any,
  invoiceItem: null as any,
  business: null as any
}



const sampAddtlData = {
  type: '',
  amount: ''
}



const MatchInvoiceProduct = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    stockOrderSummary,
    getStockOrderProducts,
    stockOrderProductList,
    specificHeaders,
    caseQtyDataError,
    isCaseQtyMapped,
    uiLoading,
    windowWidth, windowHeight,
    description,
    setDeleteLinkDialogOpen,
    deleteLinkDialogOpen,
    setSelectedItemtoUnlink,
    selectedItemtoUnlink,
    setUILoading,
    setSOComment,
    soComment,
    addtlExpData,
    setAddtlExpData,
    originalDataSummary
  } = props;

  // console.log("getStockOrderProducts",getStockOrderProducts)
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));

  const difference = Number(stockOrderSummary.expectedTotal) - Number(stockOrderSummary.calculatedTotal);
  const differenceString = difference.toFixed(2);
  const [invoiceItemModalData, setInvoiceItemModaldata] = useState(fixDialogData);
  const [invoiceItemList, setInvoiceItemList] = useState({
    data: [],
    isFetching: true,
    success: false,
    error: false,
    errorData: null,
    hasErrors: false,
  });

  const [toggleErrorFilterButton, setToggleErrorFilterButton] = useState(false);
  const [toggleUseRetlInfoForCQ, setToggleUseRetlInfoForCQ] = useState(false);
  const [useRtlCQDialog, setUseRtlCQDialog] = useState(false)
  const [filteredErrorData, setFilteredErrorData] = useState([]);
  const tableRef = useRef();
  const [itemData, setItemData] = useState([]);
  const [errorSuccess, setErrorSuccess] = useState(false);

  // const [hasDiscount, setHasDiscount] = useState(false)
  const [isAddtlDialogOpen, setAddtlDialogOpen] = useState(false)




  useEffect(() => {
    console.log("toggleUseRetlInfoForCQ", toggleUseRetlInfoForCQ)
  }, [toggleUseRetlInfoForCQ])

  useEffect(()=>{
    console.log("MatchedInvoiceProducts")
    console.log("originalDataSummary", originalDataSummary)
  },[props])

  useEffect(() => {
    if (toggleUseRetlInfoForCQ) {
      const items = invoiceItemList.data
      const diffQtySameRocProds = items.filter(data => data.error ? data.error_type === 'different_quantity_same_reorder_code' : null)
      console.log("diffQtySameRocProds", diffQtySameRocProds)

      if (diffQtySameRocProds.length > 0) {
        console.log("diffQtySameRocProds", diffQtySameRocProds)
        let updatedArr = [...items]

        diffQtySameRocProds.forEach((prod) => {
          let objFound = updatedArr.find((itemObj) => {
            return itemObj.itemIndex === prod.itemIndex
          });
          if (objFound) {
            if (objFound.hasOwnProperty('reOrderCodeOfRetailerToSend')) {
              objFound['reOrderCodeOfRetailerToSend'] = true;
            }
            if (objFound.hasOwnProperty('error')) {
              objFound['error'] = '';
            }
            if (prod.hasOwnProperty('error_type')) {
              objFound['error_type'] = '';
            }
          }
        });

        console.log(updatedArr);

        setErrorSuccess(true)
        // props.getStockOrderProductsSuccess(updatedArr)
        setItemData((prevState: any) => updatedArr)
      }
      else {
        setItemData((prevState: any) => items)
      }
      setUseRtlCQDialog(false)
      setToggleUseRetlInfoForCQ(false)
    }
  }, [toggleUseRetlInfoForCQ])



  useEffect(() => {
    if (toggleErrorFilterButton === true) {
      const items = invoiceItemList.data
      const filteredByError = items.filter(data => data.error ? data.error !== '' : null)
      console.log("filteredbyerror", filteredByError)
      setItemData((prevState: any) => filteredByError)
      setErrorSuccess(false)
    }
    else {
      setItemData((prevState: any) => invoiceItemList.data)
    }
  }, [toggleErrorFilterButton, errorSuccess])

  useEffect(() => {
    console.log("IS EQUALL CONDITION", !isEqual(invoiceItemList, props.stockOrderProductList))

    if (!isEqual(invoiceItemList, props.stockOrderProductList)) {
      console.log("IF DIFFERENCE", invoiceItemList, props.stockOrderProductList);
      setInvoiceItemList((prevState: any) => props.stockOrderProductList);
      setAddtlExpData
      if (toggleErrorFilterButton) {
        const items = props.stockOrderProductList.data
        const filteredByError = items.filter(data => data.error ? data.error !== '' : null)
        setItemData((prevState: any) => filteredByError)
      }
      else {
        setItemData((prevState: any) => props.stockOrderProductList.data)
      }
      console.log("NO DIFFERENCE", invoiceItemList.data)
    }
  }, [props.stockOrderProductList]);

  // useEffect(()=>{
  //   if(itemData && itemData.length > 0){
  //     let discountExists = itemData.some(d=>'discount'in d)
  //     setHasDiscount(discountExists)
  //   }
  // },[itemData])


  useEffect(() => {
    tableRef && tableRef.current
    // tableRef && tableRef.current && tableRef.current.onChangePage();
  }, [itemData, invoiceItemList.data]);


  const fixInvoiceItem = (data: any) => {
    let { id, name } = props.selectedSupplier.master_company;
    let business = { business_id: id, business_name: name };
    console.log("data -115--- ", data)
    switch (data.error_type) {
      case 'link_business_product':
        const sb = props.userData.selectedBusiness;
        business = sb;
        break;
      case 'supplier_product':
        break;
      case 'link_supplier_product':
        break;
      case 'supplier_code_or_quantity_missing':
        break;
      case 'different_quantity_same_reorder_code':
        break;
      case 'different_reorder_code_same_quantity':
        break;
      case 'supplier_record_missing':
        break;
      case 'link_supplier_missing':
        break;
      case 'different_quantity_different_reorder_code':
        break;
      case 'supplier_product_not_approved':
        break;
    }
    //console.log("data -135 ",data)
    if (data.error_type === 'different_reorder_code_same_quantity' && data.product.suppliers.length > 1) {
      //find the matching quantity reorder code and set retailer order code and number here
      data.supplier_code = data.confirmed_reorder_code
      data.supplier_quantity = data.master_supplier_container_qty
      data.product.suppliers.forEach((element) => {
        if (element.boxSize === data.master_supplier_container_qty) {
          data.retailer_quantity = element.boxSize
          data.retailer_reorder_code = element.orderNumber
        }
      })
    }
    else if (data.error_type === 'different_quantity_same_reorder_code' && data.product.suppliers.length > 1) {
      data.supplier_code = data.confirmed_reorder_code
      data.supplier_quantity = data.master_supplier_container_qty

      data.product.suppliers.forEach((element) => {
        if (element.orderNumber == data.confirmed_reorder_code) {
          data.retailer_quantity = element.boxSize
          data.retailer_reorder_code = element.orderNumber
        }
      })
    }
    else {
      data.supplier_code = data.confirmed_reorder_code
      data.supplier_quantity = data.master_supplier_container_qty

      if (data.error_type === 'supplier_record_missing' || data.error_type === 'link_business_product' || data.error_type === 'link_supplier_product' || data.error_type === 'supplier_product' || data.error_type === 'link_supplier_missing' || data.error_type === 'supplier_product_not_approved') {
        data.retailer_quantity = null
        data.retailer_reorder_code = null
      }
      else {
        data.retailer_quantity = data.product.suppliers[0].boxSize
        data.retailer_reorder_code = data.product.suppliers[0].orderNumber
      }
    }

    let modalData = {
      open: true,
      business: business,
      invoiceItem: data,

      onClose: (invoiceItem: any) => {
        if (invoiceItem) {
          // props.getStockOrderProducts();
          props.updateMatchedItem(invoiceItem);
        }
        setInvoiceItemModaldata(fixDialogData);
      }
    }

    setInvoiceItemModaldata((prevState: any) => modalData);

  };




  const showHelpText = () => {
    return (
      <div>
        <Typography variant="body1" style={{ padding: '4px 8px' }}>
          {`If calculated total is in green and item has error, still stock order can be created without fixing the error.`}
        </Typography>
        <Typography variant="body1" style={{ padding: '4px 8px' }}>
          {`Its upto the user to fix such errors.`}
        </Typography>
      </div>
    )
  }

  const matchInvoiceItem = (invoiceItem, newRecordToBeAdded, editedRecord = false) => {//used when editing and updating a single record
    let item = { ...invoiceItem };
    ////    delete these keys for a new response from api;
    console.log("item", item)



    delete item.error_type;
    delete item.error;
    delete item.suggestions;
    item.editedRecord = editedRecord
    item.product_edited_or_updated = true
    item.product = {}
    item.master_supplier_container_qty = ''
    item.caseQuantity = Number(item.caseQuantity)
    item.supplier_product = {}
    item.supplier_master_product = {}
    item.casesDelivered = Number(item.casesDelivered)
    item.unitsDelivered = Number(item.unitsDelivered)
    item.retailer_quantity = ""
    item.retailer_reorder_code = ""
    item.supplier_code = ""
    item.supplier_quantity = ""
    if (item.costPerCase !== '') {
      let cpc = item.costPerCase
      let dec = cpc.split('.')[1]
      let len = dec && dec.length > 2 ? dec.length : 2
      item.costPerCase = Number(cpc).toFixed(len)
    }
    if (item.costPerUnit !== '') {
      let cpu = item.costPerUnit
      let dec = cpu.split('.')[1]
      let len = dec && dec.length > 2 ? dec.length : 2
      item.costPerUnit = Number(cpu).toFixed(len)
    }
    console.log('matchInvoiceItem before passing', item);
    let payload = {
      business_id: props.selectedSupplier.business.id,
      korona_supplier_business_id: null,
      master_company_id: props.selectedSupplier.master_company.id,
      user_auth_token: props.userData.userData.userData.auth_token,
      stockOrderItems: [item],
      document_name: description,
      parser_setting_id: props.selectedParserSetting ? props.selectedParserSetting.id : ''

    };

    setUILoading(true)
    getMatchedInvoiceItemList(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        if (newRecordToBeAdded === true) {
          console.log("Addnew", jsonData.stockOrderItemList[0])
          props.addNewItemSOList(jsonData.stockOrderItemList[0])

          // props.stockOrderProductList.data.push(jsonData.stockOrderItemList[0])
        } else {
          props.updateMatchedItem(jsonData.stockOrderItemList[0]);

        }
        setUILoading(false)
      })
      .catch((err: any) => {
        setUILoading(false)

      });
  };

  const handleFilterErrorToggleChange = () => {
    setToggleErrorFilterButton(!toggleErrorFilterButton);
  }

  const handleToggleUseRetlInfoForCQ = () => {
    setToggleUseRetlInfoForCQ(!toggleUseRetlInfoForCQ);
  }
  const handleToggleUseRetlInfoFDialog = () => {
    setUseRtlCQDialog(!useRtlCQDialog);
  }

  const getTableBodyMaxHeight = () => {
    const style = { maxHeight: 0 };
    if (windowWidth) {
      if (windowWidth >= 2260) {
        style.maxHeight = 900;
      } else if (windowWidth >= 1920) {
        style.maxHeight = 462;
        // style.maxHeight = 552;
      } else if (windowWidth >= 1600) {
        style.maxHeight = 400;
      } else if (windowWidth >= 1440) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1366) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1280) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1080) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 1024) {
        style.maxHeight = 372;
        if (windowHeight >= 900) {
          style.maxHeight = 460;
        }
      } else if (windowWidth >= 800) {
        style.maxHeight = 245;
      }
      return style;
    }
    return style;
  };

  const handleCloseFixItem = (item?: any) => {
    if (item) {
      console.log("InMAtchInvoice---", item)
      props.updateMatchedItem(item);
    }
    setInvoiceItemModaldata(cloneDeep(fixDialogData));
  }

  const handleToggleAddAddtlExpensesChange = (e: any) => {
    if (addtlExpData && addtlExpData.length == 0) {
      let attrObj = {}
      attrObj['type'] = ''
      attrObj['amount'] = ''
      setAddtlExpData((prevState: any) => [...prevState, attrObj])
    }
    setAddtlDialogOpen(!isAddtlDialogOpen)
  }



  const renderTable = () => {
    return (
      <div>
        {
          invoiceItemList &&
          !invoiceItemList.isFetching &&
          invoiceItemList.data &&
          <MaterialTable
            tableRef={tableRef}
            options={{
              ...tableOptions(tableQuery),
              padding: 'dense',
              pageSizeOptions: [10, 50, 100, 500],
              pageSize: 100,
              maxBodyHeight: getTableBodyMaxHeight().maxHeight,
            }}
            columns={[
              {
                title: 'Unlink',
                field: 'product',
                editable: 'never',
                render: rowData => {
                  if (rowData) {
                    if (rowData.hasOwnProperty('temp_link') && rowData.temp_link === true) {
                      return (
                        <div>
                          <Tooltip title={'UnLink'}>
                            <IconButton
                              color="primary"
                              onClick={() => handleDeleteLinkDialogOpen(rowData)}
                              disabled={Object.keys(rowData.product).length == 0 && rowData.temp_link === true ? true : false}
                            >
                              <LinkOffIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    }
                    return null
                  }
                },
              },
              {
                title: 'Row',
                field: EXPECTED_HEADERS_SHORT_NAME.itemIndex,
                editable: 'never',
              },
              {
                title: 'Serial Number',
                field: EXPECTED_HEADERS_SHORT_NAME.SERIAL_NUMBER,
                editable: 'never',
                hidden: !specificHeaders.serialNumber
              },
              {
                title: 'ReOrder Code',
                field: EXPECTED_HEADERS_SHORT_NAME.RE_ORDER_CODE,
                editable: 'always',
                hidden: !specificHeaders.reOrderCode
              },
              {
                title: 'Invoice Description',
                field: EXPECTED_HEADERS_SHORT_NAME.SUPPLIER_PRODUCT_NAME,
                editable: 'always',
                render: rowData => {

                  if ('supplierProductName' in rowData && rowData.supplierProductName != null) {
                    return <Typography style={{ minWidth: 251 }}>{rowData.supplierProductName}</Typography>;
                  }
                  return <Typography>{''}</Typography>;
                }

              },
              {
                title: 'Customer Product',
                field: 'product.name',
                editable: 'never',
                render: rowData => {
                  if (rowData.product && rowData.product['name']) {
                    return <Typography variant="subtitle2" style={{ minWidth: 251 }} className={classes.defaultText}>{rowData.product['name']}</Typography>;
                  }
                  return <Typography variant="subtitle2" style={{ minWidth: 251 }} className={classes.errorText}>Product Mapping Not Found</Typography>;
                }
              },
              {
                title: 'Case Quantity',
                field: EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY,
                render: rowData => {
                  if (rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY] && rowData.master_supplier_container_qty) {
                    if (Number(rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]) !== Number(rowData.master_supplier_container_qty))
                      return <Typography variant="subtitle2" className={classes.errorText}>{rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]}</Typography>;
                  }
                  return <Typography variant="subtitle2">{rowData[EXPECTED_HEADERS_SHORT_NAME.CASE_QUANTITY]}</Typography>;
                },
                editable: 'always',
                hidden: !specificHeaders.caseQuantity
              },
              { title: 'Supplier Case Quantity', field: 'master_supplier_container_qty', editable: 'never' },
              {
                title: 'Cases Delivered',
                field: EXPECTED_HEADERS_SHORT_NAME.CASES_DELIVERED,
                editable: 'always',
                hidden: !specificHeaders.casesDelivered
              },
              {
                title: 'Cost Per Case',
                field: EXPECTED_HEADERS_SHORT_NAME.COST_PER_CASE,
                editable: 'always',
                hidden: !specificHeaders.costPerCase,
                render: rowData => {
                  if (rowData[EXPECTED_HEADERS_SHORT_NAME.COST_PER_CASE]) {
                      return <Typography variant="subtitle2">{Number(rowData[EXPECTED_HEADERS_SHORT_NAME.COST_PER_CASE]).toFixed(2)}</Typography>;
                  }
                },
              },
              {
                title: 'Units Delivered',
                field: EXPECTED_HEADERS_SHORT_NAME.UNITS_DELIVERED,
                editable: 'always',
                hidden: !specificHeaders.unitsDelivered,
                render: rowData => {
                  if (rowData[EXPECTED_HEADERS_SHORT_NAME.UNITS_DELIVERED]) {
                      return <Typography variant="subtitle2">{Number(rowData[EXPECTED_HEADERS_SHORT_NAME.UNITS_DELIVERED]).toFixed(2)}</Typography>;
                  }
                },
              },
              {
                title: 'Cost Per Unit',
                field: EXPECTED_HEADERS_SHORT_NAME.COST_PER_UNIT,
                editable: 'always',
                hidden: !specificHeaders.unitsDelivered,
                render: rowData => {
                  if (rowData[EXPECTED_HEADERS_SHORT_NAME.COST_PER_UNIT]) {
                      return <Typography variant="subtitle2">{Number(rowData[EXPECTED_HEADERS_SHORT_NAME.COST_PER_UNIT]).toFixed(2)}</Typography>;
                  }
                },
              },
              // { 
              //   title: 'Discount',
              //   field: EXPECTED_HEADERS_SHORT_NAME.DISCOUNT,
              //   editable: 'never',
              //   hidden: !hasDiscount
              // },
              {
                title: 'Line Total',
                field: EXPECTED_HEADERS_SHORT_NAME.LINE_TOTAL,
                editable: 'always',
                cellStyle: {
                  textAlign: 'right',
                },
                render: rowData => {
                  if (rowData[EXPECTED_HEADERS_SHORT_NAME.LINE_TOTAL]) {
                      return <Typography variant="subtitle2">{Number(rowData[EXPECTED_HEADERS_SHORT_NAME.LINE_TOTAL]).toFixed(2)}</Typography>;
                  }
                },
              },
              {
                title: 'Calculated Total',
                field: EXPECTED_HEADERS_SHORT_NAME.calculatedLineTotal,
                editable: 'never',
                cellStyle: {
                  textAlign: 'right',
                },
                render: rowData => {
                  if (Number(rowData.lineTotal).toFixed(2) === Number(rowData.calculatedLineTotal).toFixed(2)) {
                    return (
                      <Typography variant="subtitle2"
                        className={classes.successText}>
                        {Number(rowData.calculatedLineTotal).toFixed(2)}
                      </Typography>
                    );
                  }
                  return (
                    <Typography variant="subtitle2"
                      className={classes.errorText}>
                      {Number(rowData.calculatedLineTotal).toFixed(2)}
                    </Typography>
                  );
                }
              },
              {
                title: 'Issue',
                field: 'error',
                editable: 'never',
                render: rowData => {
                  if (rowData.error) {
                    return <Typography variant="subtitle2" className={classes.errorText}>{rowData.error}</Typography>;
                  }
                  else if (rowData.warning) {
                    return <Typography variant="subtitle2" className={classes.warningText}>{rowData.warning}</Typography>;
                  }
                  return <Typography variant="subtitle2" className={classes.defaultText}>----</Typography>;
                }
              },
              {
                title: 'Action',
                field: 'error',
                editable: 'never',
                render: rowData => {
                  if (rowData.error) {
                    if (!(['link_supplier_missing', 'supplier_code_or_quantity_missing', 'new_code_already_exists_in_diff_product', 'supplier_product_reorder_code'].includes(rowData.error_type))) {
                      return (
                        <div>
                          <Button
                            variant="contained"
                            style={{ textTransform: 'none' }}
                            onClick={() => fixInvoiceItem(rowData)}>
                            Fix
                          </Button>
                        </div>
                      );
                    }
                  }
                  else if (rowData.warning) {
                    return (
                      <div style={{ color: GlobalStyles.warningText }}>
                        <CustomTableTitle
                          showHelp={true}
                          helpText={
                            <div>
                              <Typography variant="body1" style={{ padding: '4px 8px' }}>
                                {`Supplier Record is missing on Retailer, New Record will be created on Korona`}
                              </Typography>
                            </div>
                          }
                        />
                      </div>
                    )
                  }
                  return <Typography variant="subtitle2" className={classes.defaultText}>----</Typography>;
                }
              },
            ]}
            //data={invoiceItemList.data}
            data={itemData}

            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const newRecordToBeAdded = false;
                    const editedRecord = true
                    resolve(matchInvoiceItem(newData, newRecordToBeAdded, editedRecord));
                  }, 1000)
                }),
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    newData.supplierProductName = newData.supplierProductName
                    newData.caseQuantity = ''
                    newData.costPerUnit = ''
                    newData.master_supplier_container_qty = ''
                    newData.product = {}
                    if (stockOrderProductList.data.slice(-1)[0].headerContainsReOrderCode === true) { //check in the last item if pdf contains reOrderCode
                      newData.headerContainsReOrderCode = true
                    }
                    else {
                      newData.headerContainsReOrderCode = false
                      newData.reOrderCode = ''
                    }
                    newData.reOrderCodeOfRetailerToSend = ''
                    newData.unitsDelivered = ''
                    newData.serialNumber = ''
                    newData.casesDelivered = ''

                    newData.itemIndex = Number(stockOrderProductList.data.slice(-1)[0].itemIndex + 1)
                    const newRecordToBeAdded = true;

                    resolve(matchInvoiceItem(newData, newRecordToBeAdded));
                  }, 1000)
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {

                    const index = invoiceItemList.data.findIndex(element => element.itemIndex === oldData.itemIndex);
                    resolve(props.deleteStockOrderProductInList(index));
                  }, 1000)
                }),

            }}
            title={
              invoiceItemList &&
              !invoiceItemList.isFetching &&
              invoiceItemList.data &&
              <div style={{ width: '100%' }}>
                <div className={classes.rowSummary}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <span style={{ fontSize: 18 }}
                        className={
                          stockOrderSummary.unMappedProducts > 0 ?
                            classes.errorText : classes.successText
                        }
                      >Items: {stockOrderSummary.itemCount}({stockOrderSummary.unMappedProducts})&nbsp;&nbsp;&nbsp;</span>
                      <span style={{ fontSize: 18 }}
                        className={
                          stockOrderSummary.calculatedTotal !== stockOrderSummary.expectedTotal ?
                            classes.errorText : classes.successText
                        }
                      >Total: {stockOrderSummary.expectedTotal}({differenceString})</span>
                    </div>
                    <div style={{ justifySelf: 'flex-end', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Button variant="contained"
                        className={classes.button}
                        onClick={() => handleReloadMapping()}
                        disabled={props.reloadMappingButtonDisabled}>
                        Reload Mapping</Button>
                    </div>
                    <div>
                      <Tooltip

                        title={showHelpText()}
                      >
                        <IconButton
                          color="primary"
                          className={classes.helpBtn}
                        >
                          <HelpOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={toggleErrorFilterButton}
                            onChange={handleFilterErrorToggleChange}
                          />
                        }
                        label="Filter Errors"
                        labelPlacement="bottom"
                      />
                    </div>
                    <div>
                      <Tooltip
                        title={`Add Addtl Expenses`}
                      >
                        <IconButton
                          color="primary"
                          className={classes.helpBtn}
                          onClick={handleToggleAddAddtlExpensesChange}
                        >
                          <AddBoxIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {
                      props && props.userData && props.userData.selectedBusiness && props.userData.selectedBusiness.is_supplier &&
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={useRtlCQDialog}
                              onChange={handleToggleUseRetlInfoFDialog}
                              disabled={itemData && itemData.filter(it => it.error && it.error_type === 'different_quantity_same_reorder_code').length > 0 ? false : true}
                            />
                          }
                          label={`Use Rtl Unit Spec  ${itemData && itemData.filter(it => it.error && it.error_type === 'different_quantity_same_reorder_code').length}/${itemData && itemData.length}`}
                        />
                      </div>
                    }
                  </div>
                </div>
                {
                  isCaseQtyMapped && caseQtyDataError.hasError &&
                  <div style={{ textAlign: 'right' }}>
                    <Typography
                      variant='body1'
                      className={classes.errorText}
                    >
                      {caseQtyDataError.message}
                    </Typography>
                  </div>
                }
              </div>
            }
          />
        }
      </div>
    )
  }

  const handleReloadMapping = () => {
    setAddtlExpData((prevState: any) => [{
      type: '',
      amount: ''
    }])
    getStockOrderProducts()

  }

  const handleDeleteLinkDialogOpen = (item: any) => {
    setSelectedItemtoUnlink(item)
    setDeleteLinkDialogOpen(true);

  }

  const handleDeleteLinkDialogClose = () => {
    setDeleteLinkDialogOpen(false);
    setSelectedItemtoUnlink(null)

  };

  const handleChangeUseRtlCQDialog = () => {
    setUseRtlCQDialog(false);

  };

  const deleteLinkDialog = () => {
    return (
      <div>
        <Dialog
          open={deleteLinkDialogOpen}
          onClose={handleDeleteLinkDialogClose}
        >
          <DialogTitle>{"Unlink"}</DialogTitle>
          <DialogContent>
            <DialogContentText >
              {`Are you sure want to unlink this supplier product?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteLinkDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={() => handleRemoveBussProdLinks()} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const confirmChangeUseRtlInfoForCQDialog = () => {
    return (
      <div>
        <Dialog
          open={useRtlCQDialog}
          onClose={handleChangeUseRtlCQDialog}
        >
          <DialogTitle>{"Use Retailer Unit Spec"}</DialogTitle>
          <DialogContent>
            <DialogContentText >
              {`Confirm override all with Retailer Case Quantity where applicable`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleChangeUseRtlCQDialog} style={{ color: GlobalStyles.red }}>
              No
            </Button>
            <Button onClick={handleToggleUseRetlInfoForCQ} color="primary" >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }


  const handleRemoveBussProdLinks = () => {
    let selected_temp_prods = [selectedItemtoUnlink.temp_link_details]
    let tempProd = { ...selectedItemtoUnlink }
    setUILoading(true)
    let payload = {
      auth_token: props.userData.userData.userData.auth_token,
      invoice_item_desc: selectedItemtoUnlink.supplierProductName,
      temp_prods: selected_temp_prods
    }
    console.log("PAYLOADDD", payload)
    removeBussProdLinks(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Removed Linked Product!',
          options: ToastSuccess
        });

        setUILoading(false);
        // window.close();
        handleDeleteLinkDialogClose()
        handleUpdateProductAfterUnLink(tempProd)
      })
      .catch((error: any) => {
        let msg = 'Failed to unlink';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setUILoading(false)
      });
  }

  const handleUpdateProductAfterUnLink = (tempProd: any) => {
    new Promise((resolve, reject) => {
      const newRecordToBeAdded = false;
      const editedRecord = true
      resolve(matchInvoiceItem(tempProd, newRecordToBeAdded, editedRecord))
    })
  }

  const additionalExpensesDialog = () => {
    return (
      <div>
        <Dialog
          open={isAddtlDialogOpen}
          onClose={handleAddtlExpnDialogClose}
        >
          <DialogTitle>{"Additional Expenses"}</DialogTitle>
          <DialogContent>
            {renderAddtlExpContent()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddtlExpnDialogClose} color="primary" >
              No
            </Button>
            <Button onClick={handleAddtlExpnToSO} style={{ color: GlobalStyles.red }} autoFocus >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  const handleAddtlExpnToSO = () => {
    if (addtlExpData.length === 0) {
      return null;
    }
  
    let newUpdatedTotal = handleUpdatedSO(addtlExpData);
    let soData = cloneDeep(originalDataSummary.items)
    let invTotal = parseFloat( originalDataSummary.summary.expectedTotal);
  
    if (isNaN(invTotal) || invTotal <= 0) {
      console.error("Invalid expectedTotal value:",  originalDataSummary.summary.expectedTotal);
      return;
    }
  
    let totalAdjusted = 0;
  
    soData.forEach(item => {
      const lineTotal = parseFloat(item.lineTotal);
      if (isNaN(lineTotal)) {
        console.error("Invalid lineTotal value:", item.lineTotal);
        return;
      }
  
      const diffTotal = (lineTotal / invTotal) * newUpdatedTotal;
      const diffTotalFixed = diffTotal.toString() 
      console.log("DiffTotal (fixed):", diffTotalFixed);
  
      const updatedLineTotal = lineTotal + parseFloat(diffTotalFixed);
      const updatedLineTotalFixed = updatedLineTotal.toString() ;
  
      item.lineTotal = updatedLineTotalFixed;
      console.log("UpdatedLineTotal:", updatedLineTotalFixed);
  
      const updatedCostPerCase = parseFloat(updatedLineTotalFixed) / (item.casesDelivered || 1);
      item.costPerCase = updatedCostPerCase.toString()
      console.log("UpdatedCostPerCase:", item.costPerCase);
  
      totalAdjusted += parseFloat(updatedLineTotalFixed);
    });
  
    console.log("Total Adjusted:", totalAdjusted.toFixed(2));
  
    let stockOrderComment = addtlExpData.map(item => `${item.type}: ${item.amount}`).join(', ');
    props.setSOComment(stockOrderComment);
  
    console.log("UpdatedSOData", soData);
  
    const sb = props.userData.selectedBusiness;
    const businessId = sb.business_id;
    const parser_setting_id = props.selectedParserSetting ? props.selectedParserSetting.id : '';
    let payload = {
      stockOrderItems: soData,
      business_id: businessId,
      master_company_id: props.selectedSupplier.master_company.id,
      korona_supplier_business_id: null,
      user_auth_token: props.userData?.userData?.userData?.auth_token,
      document_name: description,
      parser_setting_id: parser_setting_id
    };
  
    props.getStockOrderProductList(payload);
    setAddtlDialogOpen(false);
  };
  
  
  


  const handleUpdatedSO = (addData: any) => {
    // do all the calculations here
    let sumOfInvItems = originalDataSummary.summary.expectedTotal;
    
    let updatedNewTotal = sumOfInvItems;
    console.log("sumOfInvItems", sumOfInvItems)
    let addOperationPercentUpdatedValues = 0.00;
    let subOperationPercentUpdatedValues = 0.00;
    let addOperationUpdatedValues = 0.00;
    let subOperationUpdatedValues = 0.00;

    let addOperationPercentData = addData.filter(ad => {
      return String(ad.amount).includes('%') && String(ad.amount).includes('+');
    });

    let subOperationPercentData = addData.filter(ad => {
      return String(ad.amount).includes('%') && String(ad.amount).includes('-');
    });

    let addOperationItems = addData.filter(ad => !String(ad.amount).includes('%') && String(ad.amount).includes('+'));
    let subOperationItems = addData.filter(ad => !String(ad.amount).includes('%') && String(ad.amount).includes('-'));

    subOperationItems.forEach(item => {
      let updatedAmount = parseFloat(item.amount.replace('-', ''));
      subOperationUpdatedValues += updatedAmount;
      updatedNewTotal -= updatedAmount;
    });

    subOperationPercentData.forEach(item => {
      let percentageValue = parseFloat(item.amount.replace('%', '').replace('-', '')) / 100;
      let updatedAmount = updatedNewTotal * percentageValue;
      subOperationPercentUpdatedValues += updatedAmount;
      updatedNewTotal -= updatedAmount;
    });

    addOperationItems.forEach(item => {
      let updatedAmount = parseFloat(item.amount.replace('+', ''));
      addOperationUpdatedValues += updatedAmount;
      updatedNewTotal += updatedAmount;
    });

    addOperationPercentData.forEach(item => {
      let percentageValue = parseFloat(item.amount.replace('%', '').replace('+', '')) / 100;
      let updatedAmount = updatedNewTotal * percentageValue;
      addOperationPercentUpdatedValues += updatedAmount;
      updatedNewTotal += updatedAmount;
    });


    console.log("addOperationPercentUpdatedValues", addOperationPercentUpdatedValues);
    console.log("subOperationPercentUpdatedValues", subOperationPercentUpdatedValues);
    console.log("addOperationUpdatedValues", addOperationUpdatedValues);
    console.log("subOperationUpdatedValues", subOperationUpdatedValues);
    console.log("///NEW-TOTAL/////", updatedNewTotal);
    let finalUpatedTotal = updatedNewTotal - sumOfInvItems

    return finalUpatedTotal;
  }

  const renderAddtlExpContent = () => {
    return addtlExpData.map((ad, index) => (
      <div key={String(index)} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '50%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ alignSelf: 'center', marginRight: 35, marginTop: 10 }}>
            <Typography>
              {`${index + 1}.`}
            </Typography>
          </div>
          <div className={classes.mainList} style={{ borderColor: 'black' }}>
            <TextField
              label={`Expense Desc. ${index + 1}`}
              margin="dense"
              variant="outlined"
              size="small"
              style={{ width: "15rem", marginRight: '5px', marginLeft: 5 }}
              value={ad.type || ''}
              onChange={(e) => handleChangeAddtlExpType(e, ad, index)}
              inputProps={{ style: { textAlign: 'center' } }}
            />
            <TextField
              label="Amount"
              margin="dense"
              variant="outlined"
              size="small"
              style={{ width: "8rem", marginRight: '5px' }}
              value={ad.amount || ''}
              onChange={(e) => handleChangeAddtlExpValue(e, ad, index)}
              inputProps={{ style: { textAlign: 'center' } }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {(addtlExpData.length !== 1) && (
              <IconButton style={{ backgroundColor: 'transparent' }} onClick={() => handleDeleteAddtlExpData(ad, index)}>
                <RemoveCircleIcon />
              </IconButton>
            )}
            {(addtlExpData.length - 1 === index) && (
              <IconButton style={{ backgroundColor: 'transparent' }} onClick={() => handleAddNewAddtlExpData(ad, index)}>
                <AddCircleOutlineIcon />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    ));
  }


  const handleAddtlExpnDialogClose = () => {
    setAddtlDialogOpen(false)
    // setAddtlExpData([{
    //   type: '',
    //   amount: ''
    // }])
  }

  const handleDeleteAddtlExpData = (ad, currentIndex) => {
    let data = cloneDeep(addtlExpData);
    console.log("Before addtlExpData", data);
    console.log("currentIndex", currentIndex);

    if (currentIndex != null) {
      // Remove the object at the current index
      data = data.filter((_, index) => index !== currentIndex);

      console.log("updatedData", data);
      setAddtlExpData(data);
    }
  }


  const handleAddNewAddtlExpData = (currentItem, currentIndex) => {
    console.log("BEFORE", addtlExpData, currentItem['id'], currentIndex);
    if (currentIndex != null) {
      let attrObj = {
        type: '',
        amount: ''
      };
      setAddtlExpData((prevState) => [...prevState, attrObj]);
    }
  }

  const handleChangeAddtlExpType = (e, obj, index) => {
    if (index !== undefined) {
      let updatedData = cloneDeep(addtlExpData);
      updatedData[index]['type'] = e.target.value;
      setAddtlExpData(updatedData);
    }
  }

  const handleChangeAddtlExpValue = (e, obj, index) => {
    if (index !== undefined) {
      let updatedData = cloneDeep(addtlExpData);
      updatedData[index]['amount'] = e.target.value;
      setAddtlExpData(updatedData);
    }
  }


  return (
    <div>
      <Paper className={classes.tableWrapper} elevation={0}>
        {
          (uiLoading || invoiceItemList.isFetching) &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        {renderTable()}
        {
          invoiceItemList &&
          !invoiceItemList.isFetching &&
          invoiceItemList.data && invoiceItemList.hasErrors === false &&
          props.setStepFourSuccess(true)

        }
        <FixInvoiceItemModal
          isOpen={invoiceItemModalData.open}
          business={invoiceItemModalData.business}
          invoiceItem={invoiceItemModalData.invoiceItem}
          selectedSupplier={props.selectedSupplier}
          onClose={(item?) => handleCloseFixItem(item && item)}
          errorSuccess={setErrorSuccess}
          selectedFile={props.selectedFile && props.selectedFile}
          setInvoiceItemModaldata={(data: any) => setInvoiceItemModaldata(data)}
          fixDialogData={fixDialogData}
          selectedParserSetting={props.selectedParserSetting}


        // isOpen={invoiceItemModalData.open}
        // business={invoiceItemModalData.business}
        // invoiceItem={invoiceItemModalData.invoiceItem}
        // selectedSupplier={props.selectedSupplier}
        // onClose={(item?) => handleCloseFixItem(item && item)}
        // errorSuccess={setErrorSuccess}
        />
        {deleteLinkDialog()}
        {confirmChangeUseRtlInfoForCQDialog()}
        {additionalExpensesDialog()}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    stockOrderProductList: state.stockOrderModuleData.stockOrderProductList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    getStockOrderProductList: (payload: any) => dispatch(getMatchedInvoiceItems(payload)),

    updateMatchedItem: (invoiceItem: any) => dispatch(updateStockOrderProductInList(invoiceItem)),
    deleteStockOrderProductInList: (itemIndex: any) => dispatch(deleteStockOrderProductInList(itemIndex)),
    addNewItemSOList: (item: any) => dispatch(addNewSOItem(item)),
    updateStockOrderProducts: (data: any) => getStockOrderProductsSuccess(data)

  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MatchInvoiceProduct);
