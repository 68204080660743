import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Typography,Checkbox, CircularProgress, Button, SvgIcon
} from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError, ToastWarning } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import Papa from 'papaparse';
import { CustomTableTitle, ErrorStatusBar} from '../../../shared/components';
import { initQueryParams, replaceHistory, tableOptions, useQuery, } from '../../../shared/lib/TableQueryParams';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import map from 'lodash/map';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';

import { toggleKoronaProductsActive } from '../../api'


//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      display: 'inline-block',
      tabSize: 4
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: 16,
      
    },
    summaryPaper: {
      padding: theme.spacing(1),
      // marginBottom: 16,
    },
    content: {
      display: 'flex',
      alignContent: 'center',
      width: '100%',
      backgroundColor: '#fff',
      paddingLeft: 24,
      paddingRight: 24
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    textField: {
      display: 'flex',
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      width: '100%',
      // paddingTop: 24,
      padding: theme.spacing(30),
    },
    card: {
      padding: theme.spacing(30),
    },
    statusText: {
      fontSize: 16,
      textAlign: 'left',
      marginLeft: 58,
      padding: "6px 0",
      display: 'flex',
      alignItems: 'center'
    },
    helpTooltipRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 8,
    },
    helpTooltipText: {
      padding: '0 8px 0 0',
    },
    requiredField: {
      color: GlobalStyles.red,
      fontSize: 18,

    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

const ImportFileScreen = (props: any) => {
  const classes = useStyles({});
  const [selectedFile, setSelectedFile] = useState(null as any);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);

  const [uiLoading, setUILoading] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [parsedData, setParsedData] = useState([]);
  const [disableImport, setDisableImport] = useState(true);
  const [importedSuccessfully, setImportedSuccessfully] = useState(false);
  const [confirmDetails, setConfirmDetails] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop();
      const validExtensions = ['txt'];
      if (validExtensions.includes(fileExtension)) {
        // console.log("test");
        parseContent();
      }
    }
  }, [selectedFile]);

  const handleFile = (e:any) => {
    const content = e.target.result;
    console.log("parsed", content.split("\n"), content)
    let converted = content.split("\n")
    let updatedData = regexToMatch(converted)
    setParsedData(updatedData)

  }


  const regexToMatch = (data) => {
    const regex =/\r?\n|\r/g
    let updatedData = []
    for(let i of data){
      let c = i.replace(regex, "");
      console.log("c ", c)
      c != '' ? updatedData.push({'order_num': c}) : null
    }
    console.log("CONVERTEDDDD", updatedData)
    return updatedData;
  }


  const parseContent = () => {
    if (selectedFile) {
      
      let fileData = new FileReader();
      fileData.onloadend = handleFile;
      fileData.readAsText(selectedFile);
    }
  };


  const handleResetClick = () => {
    setSelectedFile(null);
    setParsedData([]);
    setDisableImport(true);
    setImportedSuccessfully(false);
    setUILoading(false);
    setConfirmDetails(false)
  };


  const onFileSelectChangeHandler = (e: any) => {
    if (e.target) {
      if (e.target.files && e.target.files[0]) {
        setSelectedFile(e.target.files[0]);
        setDisableImport(true);
        e.target.value = '';
      }
    }
  };

  const handleUploadClick = () => {
    const el = document.getElementById('import-file');
    el && el.click();
  };


  const handleConfirmDetailsCheckBox =(event: any)=>{
    setConfirmDetails(event.target.checked)
    setDisableImport(!event.target.checked)
  }

  const getSummary = () => {
    let dataLength = parsedData.length
    let businessName = props.userLocalData.selectedBusiness.business_name
    return (
      <Paper className={classes.paper} style={{ width: '100%'}}>
        <div style={{ padding: 2 }}>
          <Typography style={{ fontSize: 16, }} >
            No. of rows read : {<span style={{ color: GlobalStyles.primaryColor }}>{dataLength}</span>}
          </Typography>
          <Typography style={{ fontSize: 16, }} >
            Currently selected business : {<span style={{ color: GlobalStyles.primaryColor }}>{businessName}</span>}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography style={{ fontSize: 16, }} >
              Note: The product(s) with these codes will be marked as active and all other product(s) will be marked as inactive.
            </Typography>
            <Checkbox
              checked={confirmDetails}
              onChange={handleConfirmDetailsCheckBox}
              color="primary"
              disabled={importedSuccessfully}
            />
          </div>
        </div>
      </Paper>
    );
  };


  const handleMouseDownImport =()=>{
    props.showToast({
      message: 'Please confirm the actions to be performed by checking the box.',
      options: ToastWarning
    });
  }

  const handleImport = () => {
    if (disableImport) {
      handleMouseDownImport()
    } else {
      setDisableImport(true);
      // // console.log(props.userLocalData)
      setUILoading(true);
      const { business_id } = props.userLocalData.selectedBusiness;
      const records = uniqBy(parsedData, o => o.order_num.trim().toLowerCase())
      const payload = { records: records, business_id:business_id };
      console.log("payload", payload);
      toggleKoronaProductsActive(payload)
        // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          setUILoading(false);
          setDisableImport(false);
          setImportedSuccessfully(true);
          setConfirmDetails(false)
          console.log("jsonData", jsonData)
        })
        .catch((error: any) => {
          console.log("ERROR", error)
          if (error) {
            setImportedSuccessfully(false);
            setUILoading(false);
            setDisableImport(false);
            props.showToast({
              message: 'Failed to update products!',
              options: ToastError
            });
          }
        });
    }
  };


  return (
    <div style={{ maxWidth: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 8 }}>
        {
          <CustomTableTitle
          showHelp={false}
          helpText={``
            // <div>
            //   <Typography variant="body1" style={{ padding: '4px 8px' }}>
            //     {`This screen creates Master Products and links them to Supplier Products based on the Supplier products' article number.`}
            //   </Typography>
            //   <Typography variant="subtitle2" style={{ padding: '4px 8px' }}>
            //     {`The following fields are required:`}
            //   </Typography>
            //   <Typography variant="subtitle2" style={{ padding: '4px 8px' }}>
            //     {`Article Number, Master Product Name, Master Product Unit Qty, Master Product Unit Name, Brand Name.`}
            //   </Typography>
            //   <Typography variant="subtitle2" style={{ padding: '4px 8px' }}>
            //     {`Retail Barcode is optional.`}
            //   </Typography>
            // </div>
          }
        />
        }
        {
          <Button aria-controls="simple-menu"
            variant="contained"
            onClick={handleUploadClick}
            disabled={selectedFile ? true : false}
            startIcon={<SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>}>
            Select file
          </Button>
        }
        <input type="file" name="file"
          onChange={onFileSelectChangeHandler}
          id="import-file"
          style={{ display: 'none' }}
          accept=".txt" />
      </div>
      <Divider className={classes.divider} />
      {selectedFile &&
        <Paper className={classes.paper} >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', padding: 2 }}>

            <div style={{ alignSelf: 'flex-start', display: 'flex', alignItems: 'center' }}>
              {
                selectedFile &&
                <Button
                  variant="contained"
                  onClick={handleResetClick}
                  style={{ marginLeft: 16 }}>
                  Reset
                </Button>
              }
            </div>
            <div style={{ alignSelf: 'center' }}>
              {
                uiLoading &&
                <CircularProgress style={{
                  width: '32px',
                  height: '32px'
                }} />
              }
            </div>

            <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
              {importedSuccessfully &&
                <Typography style={{ fontSize: 16, color: GlobalStyles.successText }}>
                  All Product(s) Updated Successfully!!
              </Typography>
              }
              {!importedSuccessfully &&
                <Button 
                  style={ disableImport ? { opacity: 0.4 ,marginLeft: 16, backgroundColor: '#bdbdbd'}: { marginLeft: 16, backgroundColor: '#4caf50'}}
                  variant="contained"
                  onClick={handleImport}
                  startIcon={<SvgIcon><path d={SVG_ICONS.import} /></SvgIcon>}>
                  Import
                </Button>
              }
            </div>
          </div>
        </Paper>
      }

      {/* {selectedFile && renderTable()} */}
      {
            <div style={{
              padding: 5,
              display: 'flex',
              justifyContent: 'space-between',
              width: "100%",
              alignItems: 'center'
            }}>
              {selectedFile && getSummary()}
            </div>

          }
    </div>
  )

}


const mapStateToProps = (state: any) => {
  return {
    userLocalData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportFileScreen);