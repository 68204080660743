
import { StockReceipt } from '../../models';
import {
  SET_SELECTED_STOCK_RECEIPT_DATA, CLEAR_SELECTED_STOCK_RECEIPT_DATA,
  CLEAR_STOCK_RECEIPT
} from '../actions/actionTypes';
import cloneDeep from 'lodash/cloneDeep';

export interface StockReceiptData {
  stockReceipt: StockReceipt | null;
  originalStockReceipt: StockReceipt | null;
  method: string | null;
  sourceData: any;
}
const initialState: StockReceiptData = {
  stockReceipt: null,
  originalStockReceipt: null,
  method: null,
  sourceData: null
};

export const createStockReceiptDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_SELECTED_STOCK_RECEIPT_DATA: return {
      ...state,
      ...action.data
    };
    case CLEAR_SELECTED_STOCK_RECEIPT_DATA: return cloneDeep(initialState);
    case CLEAR_STOCK_RECEIPT: return {
      ...state, 
      stockReceipt: null,
      originalStockReceipt: null
    };
    default: return state;
  }
};