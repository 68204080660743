import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import {
  TextField, Button, Input, MenuItem, Typography, Paper,
  SvgIcon, FormControl, Select, InputLabel, CircularProgress
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterSupplier, MasterSupplierLinkedRecord } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { getBusinessListFromAPI } from '../../../shared/data/actions';

import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { LinkMasterCompanySupplierModal } from '../../components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { getUnMappedMasterCompanySuppliersCount, deleteLinkedKoronaMasterCompanySupplierRecord, getLinkedKoronaMasterCompanySuppliers } from '../../api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    }
  }),
);
const permission_model = 'linkretailerssuppliermastercompany';

const initialDialogData = {
  open: false,
  business: null,
  onClose: null,
  mode: 'add',
  linkedRecord: null
};

const LinkBusinessMasterCompanyScreen = (props: any) => {
  const classes = useStyles({});
  const [supplierDialogData, setSupplierDialogData] = useState(initialDialogData);
  const [unMappedSupplierCountData, setUnMappedSupplierCountData] = useState({ unmapped_supplier_count: 0 });
  const tableRef = useRef<{ onQueryChange: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [tableEditableProp, setTableEditableProp] = useState({});
  const [disableEditAction, setDisableEditAction] = useState(false);
  const [disableAddAction, setDisableAddAction] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [unmappedCountLoader, setUnmappedCountLoader]=useState(false)

  useEffect(() => {
    tableRef.current.onQueryChange();
    refreshUnMappedSuppliersCount();

  }, [props.userData.selectedBusiness]);

  const refreshUnMappedSuppliersCount = () => {
    if (props.userData && props.userData.selectedBusiness) {
      const sb = props.userData.selectedBusiness;
      setUnmappedCountLoader(true)
      getUnMappedMasterCompanySuppliersCount(sb.business_id)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          setUnmappedCountLoader(false)
          setUnMappedSupplierCountData(jsonData);
        })
        .catch((error: any) => {
          console.log('error fetching unmapped supplier count', error);
        });
    }
  };

  const openSupplierModalAddMode = () => {
    if (props.userData && props.userData.selectedBusiness) {
      const sb = props.userData.selectedBusiness;
      setSupplierDialogData({
        open: true,
        business: sb,
        onClose: (canceled: boolean, success: boolean, errorData?: any) => {
          setSupplierDialogData(initialDialogData);
          refreshTableData(tableRef);
          refreshUnMappedSuppliersCount();
          if (!canceled) {
            if (success) {
              props.showToast({
                message: 'Record added successfully!',
                options: ToastSuccess
              });
            } else {
              let msg = 'Failed to add record.';
              if (errorData) {
                if ((errorData.status === 403 || errorData.status === 401)) {
                  msg = NO_PERMISSIONS_ERROR_MSG;
                } else if (errorData.status === 500) {
                  msg = ERROR_500;
                }
              }
              props.showToast({ message: msg, options: ToastError });
            }
          }
        },
        mode: 'add',
        linkedRecord: null
      });
    }
  };

  const openModalEditMode = (event, rowData) => {
    const { userData } = props.userData.userData;
    const { selectedBusiness } = props.userData;
    if (hasUpdatePermission(userData.is_superuser,
      userData.user_permissions, permission_model)) {
      setErrorStatusVisible(false);
      if (selectedBusiness && selectedBusiness.business_id) {
        setSupplierDialogData({
          open: true,
          business: selectedBusiness,
          onClose: (canceled: boolean, success: boolean, errorData?: any) => {
            setSupplierDialogData(initialDialogData);
            refreshTableData(tableRef);
            if (!canceled) {
              if (success) {
                props.showToast({
                  message: 'Record updated successfully!',
                  options: ToastSuccess
                });
              } else {
                let msg = 'Failed to upate record.';
                if (errorData) {
                  if ((errorData.status === 403 || errorData.status === 401)) {
                    msg = NO_PERMISSIONS_ERROR_MSG;
                  } else if (errorData.status === 500) {
                    msg = ERROR_500;
                  }
                }
                props.showToast({ message: msg, options: ToastError });
              }
            }
          },
          mode: 'edit',
          linkedRecord: rowData
        });
      }
    } else {
      props.showToast({ message: NO_PERMISSIONS_ERROR_MSG, options: ToastError });
    }
  };

  const getDeleteFn = () => {
    return oldData => new Promise((resolve, reject) => {
      setTimeout(() => {
        {
          deleteLinkedKoronaMasterCompanySupplierRecord(oldData)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(jsonData => {
              props.showToast({
                message: 'Record deleted successfully!',
                options: ToastSuccess
              });
              refreshTableData(tableRef);
              refreshUnMappedSuppliersCount();
            })
            .catch((error: any) => {
              let msg = 'Failed to delete record.';
              if (error) {
                if ((error.status === 403 || error.status === 401)) {
                  msg = NO_PERMISSIONS_ERROR_MSG;
                } else if (error.status === 500) {
                  msg = ERROR_500;
                }
              }
              props.showToast({ message: msg, options: ToastError });
            });
        }
        resolve(oldData);
      }, 1000);
    });
  };

  useEffect(() => {
    const { userData } = props.userData.userData;
    const editable = {};
    if (userData) {
      if (hasDeletePermission(userData.is_superuser,
        userData.user_permissions, permission_model)) {
        editable['onRowDelete'] = getDeleteFn();
      }
      if (hasUpdatePermission(userData.is_superuser,
        userData.user_permissions, permission_model)) {
        setDisableEditAction(false);
      } else {
        setDisableEditAction(true);
      }
      if (hasAddPermission(userData.is_superuser,
        userData.user_permissions, permission_model)) {
        setDisableAddAction(false);
      } else {
        setDisableAddAction(true);
      }
      setTableEditableProp(editable);
    }
  }, [props.userData]);

  const refreshTableData = (tableRef) => {
    console.log(tableRef);
    tableRef.current && tableRef.current.onQueryChange();
    refreshUnMappedSuppliersCount()
  };

  const handleRefreshData=()=>{
    tableRef.current && tableRef.current.onQueryChange()
    refreshUnMappedSuppliersCount()
  }

  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <Paper className={classes.paper}>
        <div style={{ display: 'flex', }}>
          <div style={{ width: '100%' }}>
            {
              props.userData.selectedBusiness &&
              props.userData.selectedBusiness.business_id &&
              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', flexWrap: 'wrap', paddingTop: 12 }}>
                <Typography variant='h6'
                  style={{ alignSelf: 'center', color: GlobalStyles.primaryColor, padding: '0 16px' }}
                >Master Company Suppliers not mapped:&nbsp;</Typography>
                  {
                    unmappedCountLoader ?
                        <CircularProgress /> :
                      <>
                        <Typography variant='h6'
                          style={{ alignSelf: 'center', paddingRight: 24 }}>
                          {unMappedSupplierCountData.unmapped_supplier_count}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={disableAddAction}
                          onClick={() => openSupplierModalAddMode()}>
                          Map Now
                        </Button>
                      </>
                  }
                </div>
            }

          </div>
        </div>
      </Paper>
      <LinkMasterCompanySupplierModal
        isOpen={supplierDialogData.open}
        mode={supplierDialogData.mode}
        business={supplierDialogData.business}
        onClose={supplierDialogData.onClose}
        linkedRecord={supplierDialogData.linkedRecord}
      />
      <div style={{ maxWidth: '100%' }}>
        <div className="table-wrapper">
          <MaterialTable
            options={{ ...tableOptions(tableQuery), sorting: false }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              {
                title: 'Number',
                field: 'korona_supplier',
                editable: 'never',
                render: rowData => {
                  if (rowData && rowData.korona_supplier && rowData.korona_supplier.number) {
                    return <div>{rowData.korona_supplier.number}</div>;
                  }
                  return <div></div>;
                },
              },
              {
                title: 'Korona Supplier',
                field: 'korona_supplier',
                render: rowData => {
                  if (rowData && rowData.korona_supplier && rowData.korona_supplier.name) {
                    return <div>{rowData.korona_supplier.name}</div>;
                  }
                  return <div></div>;
                },
              },
              {
                title: 'Master Company Supplier Name',
                field: 'master_company',
                editable: 'never',
                render: rowData => {
                  if (rowData && rowData.master_company && rowData.master_company.name) {
                    return <div>{rowData.master_company.name}</div>;
                  }
                  return <div></div>;
                },
              },
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [],
                  totalCount: 0,
                  page: query.page
                };
                if (props.userData && props.userData.selectedBusiness) {
                  const sb = props.userData.selectedBusiness;
                  getLinkedKoronaMasterCompanySuppliers(sb.business_id,
                    query.page + 1, query.pageSize, query.search)
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<any>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      if (error.status && error.status === 500) {
                        setEmptyDataSourceMessage(ERROR_500);
                        setErrorStatusVisible(false);
                      } else if ((error.status === 403 || error.status === 401)
                      ) {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(true);
                      } else {
                        if (error.status) {
                          console.log(error.status);
                        }
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(false);
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    });
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Link Supplier Companies'}
                icon={SVG_ICONS.linkPlus} />
            }
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => handleRefreshData(),
              },
              {
                icon: 'edit',
                tooltip: 'Edit',
                disabled: disableEditAction,
                isFreeAction: false,
                onClick: (event, rowData) => openModalEditMode(event, rowData)
              }
            ]}
            editable={tableEditableProp}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    businessList: state.sharedModuleData.businessList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBusinessList: () => dispatch(getBusinessListFromAPI()),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkBusinessMasterCompanyScreen);