import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { PaginatedResponse } from '../../../shared/models';
import { Typography, Link } from '@material-ui/core'
import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { BusinessRouterPaths, MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getCCRSReportList, changeFileAsError } from '../../api';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError, ToastWarning } from '../../../shared/components/Notifier';
import GlobalStyles from '../../../../styles/GlobalStyles.web'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { CCRSWASTATUS } from '../../models/index'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
    statusText: {
      fontSize: 16,
      textAlign: 'left',
      marginLeft: 58,
      padding: "6px 0",
      display: 'flex',
      alignItems: 'center'
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
  }),
);


const CCRSReportListScreen = (props: any) => {
  const classes = useStyles({});
  const tableRef = useRef<{ onQueryChange: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedFiles, setSelectedFiles] = useState([]);



  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);

  const handleRefresh =()=>{
    tableRef.current && tableRef.current.onQueryChange()
  }


  const exportCSV = (rowData?: any) => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getCCRSReportList(
        sb.business_id,
        null,
        null,
        tableQuery.search,
        sb.orderBy,
        shouldExport,
        rowData ? rowData.file_name : null
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, `${rowData.file_name}`);
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            let errorMsg = 'Failed to Download'
            if (error.status && error.status === 500) {
              errorMsg = ERROR_MSG
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
            props.showToast({
              message: `${errorMsg}`,
              options: ToastError
            });
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>, rowData: any) => {
    console.log("rowData", rowData)
    // let dlLink = rowData.ftp_core && rowData.ftp_core.source
    // download(dlLink)
    // window.open(dlLink)
    exportCSV(rowData[0])
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };


  const navigateToCCRSFileContent = (event: React.MouseEvent<HTMLButtonElement>,rowData: any) => {
    if (rowData) {
      const ccrsReportDetailListurl = '/web/ccrs/report-content' + '/' + rowData.ftp_core.id;
      window.open(`${ccrsReportDetailListurl}`)
    }
  }


  const handleMarkAsFailed = () => {
    if (selectedFiles && selectedFiles.length > 0) {
      const sb = props.userData.selectedBusiness
      let payload = {
        business_id: sb.business_id,
        files: selectedFiles
      }

      changeFileAsError(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data) => {
          handleRefresh()
          setLoading(false)
        })
        .catch((err: any) => {
          setLoading(false)
        });

    }  }

  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />

      <div style={{ maxWidth: '100%' }}>
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true,
              selection: true,
              selectionProps: rowData => ({
                color: 'secondary',
                // disabled: rowData.retl_item_code.includes('z') ? true : rowData.retl_item_code.includes('Z') ? true : false
              }),
              showTextRowsSelected: false,
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              {

                title: 'File Name',
                field: 'file_name',
                editable: 'never',
                render: rowData => {
                  if (rowData.file_name) {
                    return <Typography variant="subtitle2" style={{ paddingRight: 10, }}>{rowData.file_name}</Typography>;
                  }
                  return <Typography variant="subtitle2" ></Typography>;
                }
              },
              {
                title: 'Report Type',
                field: 'ftp_report',
                editable: 'never',
                render: rowData => {
                  if (rowData.ftp_report) {
                    return <Typography variant="subtitle2" style={{ paddingRight: 10, }}>{rowData.ftp_report.value}</Typography>;
                  }
                  return <Typography variant="subtitle2" ></Typography>;
                }
              },
              {
                title: 'Uploaded Time',
                field: 'end_time',
                editable: 'never',
                render: rowData => {
                  if (rowData.end_time) {
                    let endTime = new Date(rowData.end_time.slice(0, -1))
                    // console.log("EndTime", endTime, endTime.getFullYear(),)
                    return <Typography variant="subtitle2" style={{ paddingRight: 10, }}>{endTime.toLocaleDateString('en-GB').split("/").reverse().join("/") + ` ` + endTime.toLocaleTimeString()}</Typography>;
                  }
                  return <Typography variant="subtitle2" ></Typography>;
                }
              },
              {
                title: 'Status',
                field: 'ftp_status',
                editable: 'never',
                render: rowData => {
                  if (rowData.ftp_status) {
                    return <Typography variant="subtitle2" style={{ paddingRight: 10, }}>{rowData.ftp_status.value}</Typography>;
                  }
                  return <Typography variant="subtitle2" ></Typography>;
                }
              },
            ]}
            onSelectionChange={(data: any) => {
              setSelectedFiles(data)
            }
            }
            data={query =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  // console.log('query', query);
                  const sb = props.userData.selectedBusiness;
                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as any[],
                      totalCount: 0,
                      page: query.page
                    };
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    }
                    setLoading(true)
                    getCCRSReportList(
                      sb.business_id,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      orderBy,
                      false
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: any) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                        setLoading(false)
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if ((error.status === 403 || error.status === 401)
                          ) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      });
                  }
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Report History'}
                icon={SVG_ICONS.reports} />
            }
            actions={[
              {
                icon: 'file_open',
                tooltip: 'Open',
                isFreeAction: false,
                position: "toolbarOnSelect",
                onClick: (event: any, rowData: any) => navigateToCCRSFileContent(event,rowData[0]),
                disabled: selectedFiles && selectedFiles.length === 1 ? false : true,
              },
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: false,
                position: "toolbarOnSelect",
                onClick: (event: any, rowData: any) => handleExportClick(event, rowData),
                disabled: selectedFiles && selectedFiles.length === 1 ? false : true,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => handleRefresh(),
              },
              {
                icon: 'highlight_off',
                tooltip: 'Mark as Failed',
                position: "toolbarOnSelect",
                onClick: () => handleMarkAsFailed(),
              },
            ]}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            detailPanel={rowData => {
              const { error, errorRows, errorData, success, ftp_status } = rowData
              if (ftp_status) {
                if (ftp_status.key === CCRSWASTATUS.UPLOAD_FAILED) {
                  return (
                    <div style={{ display:'flex', flexDirection:'row', marginLeft: 15, width:"100%"}}>
                      <ErrorIcon style={{ color: GlobalStyles.red, alignSelf:'center' }} />
                      <Link variant="body1"
                        align="left"
                        className={classes.statusText}
                        style={{ color: GlobalStyles.red, marginLeft: 5 }}
                        component="button"
                        onClick={(e) => navigateToCCRSFileContent(e,rowData)} >
                        {`There are row level errors`}
                      </Link>
                    </div>
                  )
                }
                else {
                  return (
                    <Typography className={classes.statusText}
                      style={{ color: '#43a047' }} >
                      <span style={{ paddingRight: 8 }}>
                        <CheckCircleIcon style={{ color: GlobalStyles.green }} />
                      </span>
                      {'Success'}
                    </Typography>
                  )
                }
              }
            }
            }

          />
        </div>
      </div>
      <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(CCRSReportListScreen);
