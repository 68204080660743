import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLocation } from 'react-router-dom';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { PaginatedResponse } from '../../../shared/models';
import { Typography } from '@material-ui/core';

import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { API_URLS } from '../../api';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { SalesDataSummary } from '../../models';
import { getSalesReportByBusiness } from '../../api';
import moment from 'moment';
import {
  CustomTableTitle,
  ErrorStatusBar,
  ExportMenu,
  ExportStatusBar,
} from '../../../shared/components';
import download from 'downloadjs';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);

const SalesReportScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setExporting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [prevData, setPrevData] = React.useState<null | any>(null);
  const [fullData, setFullData] = useState(null)
  const [responseData, setReponseData] = useState(null)
  
  useEffect(() => {
    if (!isLoading && !initialLoad) {
      setFullData(null)
      setPrevData(null)
      setReponseData(null)
      tableRef && tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);

  const handleRefresh =()=>{
    setFullData(null)
    setPrevData(null)
    setReponseData(null)
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  }

  const getPagedElements = (data: any, pageNum: any, pageSize: any) => {

    let arr = data
    let totalElements = arr.length
    let page_size = pageSize
    let page = pageNum

    let startIndex = (page - 1) * page_size
    let endIndex = (startIndex + page_size)

    if (startIndex >= totalElements) {
      return []
    }
    else if (endIndex >= totalElements) {
      return arr.slice(startIndex)
    }
    return arr.slice(startIndex, endIndex)

  }


  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getSalesReportByBusiness(
        sb.business_id,
        null,
        null,
        tableQuery.search,
        sb.orderBy,
        null,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'SalesDataSummary.csv', 'text/csv');
          setExporting(false);
          setInitialLoad(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };


  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };


  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  const convertDataToLocalTime = (utc_date_time) => {
    // console.log("utc_date_time",utc_date_time)
    var LocalDateTime = new Date(utc_date_time);
    // console.log("local date",LocalDateTime);
    const formatted_date = moment(LocalDateTime).format('DD/MM/YYYY');
    // console.log("format date",formatted_date);
    return formatted_date
  }

  return (
    <Container maxWidth={false}>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />

      <div className="table-wrapper">
        <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: true,
            search:false,
            columnResizable:false,
            headerStyle: {position: "sticky" , backgroundColor: "rgb(238, 238, 238)", borderTop: "1px solid #eee", borderBottom: "1px solid #eee"},
            maxBodyHeight:"407.4px"
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage
            }
          }}
          columns={[
            { title: 'Article Number', field: 'article_number' },
            {
              title: 'Article Name', field: 'article_name',
              render: (rowData: any) => {
                return (<div style={{ minWidth: rowData.article_name != null ? "250px" : null }}> {rowData.article_name} </div>)
              }
            },
            { title: 'Article Code', field: 'recognitionNumber' },
            { title: 'Commodity Group Number', field: 'cg_number' },
            { title: 'Commodity Group Name', field: 'cg_name' },
            { title: 'Sector Name', field: 'sector_name' },
            {
              title: 'Actual Sale Price', field: 'actual_sale_price',
              render: rowData => {
                if (rowData.actual_sale_price && rowData.actual_sale_price !== null) {
                  return <Typography variant="subtitle2">{rowData.actual_sale_price.toFixed(2)}</Typography>;
                }
                else {
                  return <Typography variant="subtitle2">{"---"}</Typography>;
                }
              }

            },
            {
              title: 'Date Last Sold', field: 'LastSold',
              render: rowData => {
                if (rowData.last_sold_date !== null) {
                  var LocalDateTime = new Date(rowData.LastSold);
                  const formatted_date = moment(LocalDateTime).format('YYYY-MM-DD');

                  return <Typography variant="subtitle2">{formatted_date}</Typography>;
                }
                else {
                  return <Typography variant="subtitle2">{"---"}</Typography>;
                }
              }
            },
            {
              title: 'Quantity Sold in 60 Days', field: 'last_60_days_sale',
              render: rowData => {
                if (rowData.last_60_days_sale && rowData.last_60_days_sale !== null) {

                  return <Typography variant="subtitle2">{rowData.last_60_days_sale.toFixed(2)}</Typography>;
                }
                else {
                  return <Typography variant="subtitle2">{"---"}</Typography>;
                }
              }

            },
            {
              title: 'Quantity Sold in 45 Days', field: 'last_45_days_sale',
              render: rowData => {
                if (rowData.last_45_days_sale && rowData.last_45_days_sale !== null) {

                  return <Typography variant="subtitle2">{rowData.last_45_days_sale.toFixed(2)}</Typography>;
                }
                else {
                  return <Typography variant="subtitle2">{"---"}</Typography>;
                }
              }

            },
            {
              title: 'Quantity Sold in 30 Days', field: 'last_30_days_sale',
              render: rowData => {
                if (rowData.last_30_days_sale && rowData.last_30_days_sale !== null) {

                  return <Typography variant="subtitle2">{rowData.last_30_days_sale.toFixed(2)}</Typography>;
                }
                else {
                  return <Typography variant="subtitle2">{"---"}</Typography>;
                }
              }
            },
            {
              title: 'Quantity Sold in 7 Days', field: 'last_7_days_sale',
              render: rowData => {
                if (rowData.last_7_days_sale && rowData.last_7_days_sale !== null) {

                  return <Typography variant="subtitle2">{rowData.last_7_days_sale.toFixed(2)}</Typography>;
                }
                else {
                  return <Typography variant="subtitle2">{"---"}</Typography>;
                }
              }
            },
            {
              title: 'Quantity Sold in 1 Day', field: 'last_1_days_sale',
              render: rowData => {
                if (rowData.last_1_days_sale && rowData.last_1_days_sale !== null) {

                  return <Typography variant="subtitle2">{rowData.last_1_days_sale.toFixed(2)}</Typography>;
                }
                else {
                  return <Typography variant="subtitle2">{"---"}</Typography>;
                }
              }
            }

          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              replaceHistory(query, props.history);
              let pageData = {
                data: [] as any[],
                totalCount: 0,
                page: query.page
              };
              if (props.userData && props.userData.selectedBusiness) {
                const sb = props.userData.selectedBusiness;
                let orderBy = '';
               
                console.log("query.page",query.page)
                console.log("query.page_size",query.pageSize )
                
                if (fullData && responseData) {
                  let full_data = cloneDeep(fullData)
                  let filtered_data = []
                  let fin_res = []
                  let page = query.page + 1
                  let page_size = query.pageSize

                  // if(query.search){
                  //   let userSearchTerm = query.search 
                  //   let search_terms =['article_name','article_number','recognitionNumber','cg_number','cg_name']
                    

                  //   for (let fil_d in full_data){
                  //     console.log("item",full_data[fil_d])
                  //     console.log("j",Object.values(full_data[fil_d]).find(v => String(v).includes(String(userSearchTerm)))! == undefined ?full_data[fil_d] : null  )
                  //     if (Object.values(full_data[fil_d]).find(v => String(v).includes(String(userSearchTerm))) ! == undefined ){
                  //       fin_res.push(full_data[fil_d])
                  //       break
                  //     } 
                  //   }
                  // }
                  // console.log("fin_res_@@",query.search, Object.values(full_data[0]), fin_res)

                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    filtered_data = cloneDeep(sortBy(full_data, orderBy)  )

                    if (query.orderDirection && query.orderDirection === 'desc'){
                      filtered_data = cloneDeep(sortBy(filtered_data, query.orderBy.field).reverse())
                    }
  
                  }

                  let resultantData = getPagedElements(filtered_data.length > 0 ? filtered_data : full_data, page, page_size)

                
                  // console.log("resultantData", resultantData)

                  pageData = {
                    data: resultantData,
                    totalCount: responseData.count,
                    page: query.page
                  };
                  setTableQuery({
                    ...tableQuery,
                    orderBy,
                    totalCount: responseData.count,
                    page: query.page,
                    pageSize: query.pageSize
                  });
                  resolve(pageData);

                }
                else {
                  getSalesReportByBusiness(
                    sb.business_id,
                    query.page,
                    query.pageSize,
                    query.search,
                    orderBy,
                    null,
                    false,
                    prevData || null
                  )
                    .catch(handleError)
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<SalesDataSummary>) => {
                      let sortedResult = sortBy(data.results, 'article_name')
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      if (!fullData && !responseData) {
                        setFullData(cloneDeep(sortedResult))
                        setReponseData(data)
                      }
                      pageData = {
                        data: sortedResult,
                        totalCount: data.count,
                        page: query.page
                      };
                      setPrevData(data.final_sold)

                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });

                      resolve(pageData);
                      setInitialLoad(false);

                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)
                        ) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    });
                }
              }
            })
          }
          title={
            <CustomTableTitle
              history={props.history}
              title={'Sales Data Summary'}
              icon={SVG_ICONS.product} />
          }
          actions={[
            {
              icon: 'save_alt',
              tooltip: 'Export',
              isFreeAction: true,
              onClick: handleExportClick,
              disabled: isExporting,
            },
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => handleRefresh(),
            },
          ]}
        />
        <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

      </div>
    </Container>
  );
};


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};



const mapDispatchToProps = (dispatch: any) => {
  return {
    getBusinessList: () => dispatch(getBusinessListFromAPI())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesReportScreen);
