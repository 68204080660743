import { combineReducers, Action } from 'redux';
import {
    stockOrderProductListReducer, createStockOrderReducer,
    stockOrderSummaryDataReducer
} from './stockOrderReducer';

export const stockOrderModuleReducer = combineReducers({
    stockOrderProductList: stockOrderProductListReducer,
    createStockOrderData: createStockOrderReducer,
    stockOrderSummaryData: stockOrderSummaryDataReducer,
});