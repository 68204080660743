import { Product } from "../../products/models";
import { Business } from '../../shared/models';

export enum MasterSupplierDataImportStatusCode {
    NOT_VALIDATED = 0,
    READY_TO_IMPORT = 1,
    EXISTING_MASTER_PRODUCT_WARNING = 2,
    EXISTING_IDENTIFIER_ERROR = 3,
    EXISTING_LINK_RECORD_ERROR = 4,
    SUPPLIER_PRODUCT_NOT_FOUND_ERROR = 5,
    SAME_BRAND_DIFF_COMPANY = 6,
    EXISTING_INVOICE_ITEM_NAME_ERROR = 7,
    EXISTING_REORDER_ERROR = 8,

}

export interface MasterSupplierDataImport {
    article_number: string;
    master_product_name: string;
    mp_unit_qty: number;
    mp_unit_name: string;
    brand_name: string;
    retail_barcode: string;
    status?: string;
}

export interface MasterSupplier {
    id: number;
    name: string;
    isSelected?: boolean;
}
export const AttributeTypes = {
    QUANTITY: 'Quantity',
    UNIT_OF_MEASURE: 'Unit of Measure',
};
export interface Attribute {
    id: number;
    name: string;
    checked?: boolean;
    disabled?: boolean;
}
export interface AttributeValue {
    id?: number;
    value: string;
    attribute: Attribute;
}
export interface MasterProductIdentifier {
    id?: number;
    identifier: string;
    quantity: number;
    description: string;
    is_deleted: boolean;
    effective_date: string | null;
    expiry_date: string | null;
}
export interface MasterProduct {
    id: number;
    name: string;
    requires_review?: boolean;
    review_note: string;
    attributes: AttributeValue[];
    identifiers: MasterProductIdentifier[];
    suppliers?: MasterSupplierProduct[];
    business_products?: MasterProductLinkedRecord[];
    master_company_instances?: [];
}
export interface MasterSupplierProductIdentifier {
    uuid: string;
    mp: number;
    name: string;
    supplier_product_name: string;
    supplier_reorder_code: string;
    supplier_container_qty: number;
    master_supplier_id: number;
    identifier: string;
    master_supplier?: MasterSupplier;
}
export interface MasterProductWithoutIdentifier {
    uuid: string;
    mp_id: number;
    mp_name: string;
    missing_code_type: string;
}
export interface MasterSupplierLinkedRecord {
    id: number;
    business: number;
    master_supplier: MasterSupplier;
    business_supplier: BusinessSupplier;
}
export interface BusinessSupplierLinkRecord {
    id: number;
    business: Business;
    korona_supplier: BusinessSupplier;
    korona_supplier_business: Business;
}
export interface MasterProductLinkedRecord {
    id: number;
    business: Business;
    master_product: MasterProduct;
    business_product: Product;
}
export interface MasterSupplierProduct {
    id: number;
    master_product: MasterProduct;
    master_supplier: MasterSupplier;
    supplier_reorder_code: string;
    supplier_container_qty: number;
    supplier_product_name: string;
}

export interface MappingSuggestion {
    match_type: string;
    master_product: MasterProduct;
    business_products: Product[];
}

export interface BusinessSupplier {
    uuid: string;
    deleted: boolean;
    number: string;
    name: string;
    revision: number;
    customerNumber: string;
    additionalInformation: string;
    contactPersonSalutation: string;
    contactPersonFirstname: string;
    contactPersonSurname: string;
    contactPersonEmail: string;
    contactPersonPhone: string;
    contactPersonMobile: string;
    contactPhone: string;
    contactTelefax: string;
    contactEmail: string;
    contactWebsite: string;
    orderPhone: string;
    orderEmail: string;
    orderWeekdays: string;
    orderTimeFrom: string;
    orderTimeTo: string;
    deliveryWeekdays: string;
    deliveryTime: string;
    shipper: string;
    business: number;
}


export interface MatchedIdentifier{
    id: number, 
    identifier: string,
    quantity: number,
    description: string,
    effective_date: string,
    expiry_date: string,
    is_deleted: boolean,
    created_by: string,

}

export interface ProductMin{
    uuid: string,
    number: number,
    name: string,
}

export interface RetailerProduct{
    id:number,
    code:string,
    quantity:number,
    business:number,
    product:ProductMin

}

export interface LinkBulkProducts{
    id:number,
    business:number,
    master_product:MasterProduct,
    business_product:Product,
    matched_identifier:MatchedIdentifier,
    retailer_product:RetailerProduct,

}

export const Code_Filters = {
    NO_CASE_CODE: 'no_case_code',
    NO_RETAIL_CODE: 'no_retail_code',
    NO_CODES: 'no_codes',
    ONLY_RETAIL_CODE: 'only_retail_code',
    ONLY_CASE_CODE: 'only_case_code',
    EITHER_CODE: 'either_code',
};

export interface BaseFilter {
    id: number;
    name: string;
    displayText: string;
    active: boolean;
    constraint: string;
}



export const CodeFilters: BaseFilter[] = [
    {
        id: 1,
        name: Code_Filters.NO_CODES,
        displayText: 'No Codes',
        constraint: '&master_product_identifier_values__isnull=true',
        active: false,
    },
    // {
    //     id: 2,
    //     name: Code_Filters.ONLY_RETAIL_CODE,
    //     displayText: 'Only Retail Code',
    //     constraint: '&master_product_identifier_values__quantity__exact=1',
    //     active: false
    // },
    // {
    //     id: 3,
    //     name: Code_Filters.ONLY_CASE_CODE,
    //     displayText: 'Only Case Code',
    //     constraint: '&master_product_identifier_values__isnull=false&master_product_identifier_values__quantity__gt=1',
    //     active: false
    // },
    {
        id: 4,
        name: Code_Filters.NO_RETAIL_CODE, // can have case code or no code
        displayText: 'No Retail Code',
        constraint: '&no_retail_code=true',
        // constraint: '&master_product_identifier_values__isnull=true&master_product_identifier_values__quantity__gt=1',
        active: false,
    },
    {
        id: 5,
        name: Code_Filters.NO_CASE_CODE, // can have retail code or no code
        displayText: 'No Case Code',
        constraint: '&no_case_code=true',
        // constraint: '&master_product_identifier_values__isnull=true&master_product_identifier_values__quantity__exact=1',
        active: false,
    },
    {
        id: 6,
        name: Code_Filters.EITHER_CODE, // can have retail code or no code
        displayText: 'Either Code',
        constraint: '&either_code=true',
        active: false,
    },
];
export const brandFilter = {
    id: 1,
    name: 'multi_select_brand', // can have retail code or no code
    displayText: 'Brand',
    constraint: '&master_brand_id__in=',
    active: false,
};
export const supplierFilter = {
    id: 1,
    name: 'multi_select_supplier', // can have retail code or no code
    displayText: 'Supplier',
    constraint: '&master_supplier_id__in=',
    active: false,
};

export interface MasterBrand {
    id:any;
    name: string;
    active: number;
    company: number;
};


export enum AddressPartyType {
    BUSINESS = 1,
    PERSON = 2

}

export enum AddressSubPartyType{
    MasterCompany=1,
    MasterBrand=2

}

export interface MasterCompanyList {
    id : any;
    name: string;
    phone: any | null;
    domain: any | null;
    active: boolean;
}
export interface MasterBrandList {
    id : any;
    name: string;
    active : boolean;
    company : MasterCompanyList;
}



export interface Timezones {
	zoneName: string;
	gmtOffset: number;
	gmtOffsetName: string;
	abbreviation: string;
	tzName: string;
}
export interface ICountry {
	name: string;
	phonecode: string;
	isoCode: string;
	flag: string;
	currency: string;
	latitude: string;
	longitude: string;
	timezones?: Timezones[];
	getAllCountries?(): ICountry[];
	getCountryByCode?(): ICountry;
}

export interface IState {
	name: string;
	isoCode: string;
	countryCode: string;
	latitude?: string | null;
	longitude?: string | null;
	getStatesOfCountry?(): IState[];
	getStateByCodeAndCountry?(): IState;
	getStateByCode?(): IState;
}
export interface ICity {
	name: string;
	countryCode: string;
	stateCode: string;
	latitude?: string | null;
	longitude?: string | null;
	getAllCities?(): ICity[];
	getCitiesOfState?(): ICity[];
	getCitiesOfCountry?(): ICity[];
}



export enum ItemType {
    PRODUCT = 1,
    BRAND = 2,
    COMPANY = 3,
    WEB_STORE_LOGO = 4
}

export enum ItemCategory {
    IMAGE = 1,
    VIDEO = 2,
    AUDIO = 3,
}

export interface BusinessMasterCompanySupplierLinkRecord {
    id: number;
    business: Business;
    korona_supplier: BusinessSupplier;
    master_company: any;
}

export interface MasterCompanySupplier {
    id: number;
    name: string;
    isSelected?: boolean;
}
export interface BusinessCompanySupplierLinkRecord {
    id: number;
    business: Business;
    korona_supplier: BusinessSupplier;
    master_company: any;
}