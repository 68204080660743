import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';

import { Typography, Snackbar, SvgIcon, Paper, Icon, IconButton, Divider } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { StockReceipt } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';
import { Filter, FilterType } from '../../../shared/models/Filter';

import { getCgByMarginList, updateMarginByCg } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { getProductPriceForPos } from 'components/src/modules/products/lib/price';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import { getMasterCompaniesFullList } from '../../../master-data/api'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style_1.css';
import {
  isSuperAdmin,
  hasAddPermission,
  hasUpdatePermission,
  hasDeletePermission,
} from '../../../shared/lib/PermissionManager';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

const MarginByCgScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();

  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
        tableRef.current && tableRef.current.onQueryChange();
    }
}, [props.userData.selectedBusiness]);


  const renderDetailTable = (details: any) => {
    return (
      <div style={{ marginLeft: 30, marginRight: 30 }}>

        <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: false,
            search: false,
            header: false,
            toolbar: false,
            paging: false,
          }}
          title={``}
          columns={[
            { title: 'Number', field: 'number', editable: 'never', },
            {
              title: 'Name',
              field: 'name',
              editable: 'never',
              render: rowData => {
                if (rowData && rowData.name) {
                  return (
                    <div style={{ minWidth: 200 }}>
                      {rowData.name}
                    </div>
                  )

                }
                return null
              },
            },
            {
              title: 'Margin',
              field: 'margin_details.margin',
              editable: 'onUpdate',
              cellStyle: {
                minWidth: 50
              }

            },
          ]}
          parentChildData={(row, rows) => rows.find(a => a.uuid === row.parentCommodityGroup)}
          data={details}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                  const newRecordToBeAdded = false;
                  const editedRecord = true
                  resolve(changeCgMargin(newData, oldData));
              }),
          }}
          detailPanel={[
            rowData => ({
              disabled: !rowData.children,
              // visible: !rowData.children,
              render: rowData => {
                const { hasChildren, children } = rowData;

                if (children != null) {
                  return (
                    <Paper style={{}}>
                      {renderDetailTable(rowData.children)}
                    </Paper>
                  )

                }
              }
            })
          ]}
        />
      </div>

    )
  }

  const changeCgMargin = (newData, oldData = null) => {
    setLoading(true)
    console.log("UpdatedrowData", newData)
    console.log("OldData", oldData)
    const { userData } = props.userData.userData;
    if (userData) {

      console.log("HAVING PERMISSION", props)
      const sb = props.userData.selectedBusiness
      newData['business_details'] = sb
      newData['user_details']= { auth_token: userData.auth_token}
      updateMarginByCg(newData)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((jsonData) => {
          setLoading(false)
          
          setErrorStatusVisible(false);
          setEmptyDataSourceMessage(EMPTY_ROW_MSG);
          props.showToast({
            message: 'Margin updated successfully!',
            options: ToastSuccess,
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error: any) => {
          let msg = 'Failed to update Margin.';
          if (error && error.status) {
            if (error.status === 403 || error.status === 401) {
              msg = NO_PERMISSIONS_ERROR_MSG;
            } else if (error.status === 500) {
              msg = ERROR_500;
            }
          }
          props.showToast({ message: msg, options: ToastError });
          setLoading(false)

        });
    }
  }


  const renderTable = () => {
    return (
      <div>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <div style={{ maxWidth: '100%' }}>
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true,
              // actionsCellStyle:{
              //   alignSelf:'flex-start'
              // },
              // editCellStyle:{
              //   width: 0
              // },

            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              },

            }}
            columns={[
              { title: 'Number', field: 'number', editable: 'never', },
              {
                title: 'Name',
                field: 'name',
                editable: 'never',
                render: rowData => {
                  if (rowData && rowData.name) {
                    return (
                      <div style={{ minWidth: 200 }}>
                        {rowData.name}
                      </div>
                    )

                  }
                  return null
                },
              },
              {
                title: 'Margin',
                field: 'margin_details.margin',
                editable: 'always',
                cellStyle: {
                  minWidth: 50
                }
              },
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  const sb = props.userData.selectedBusiness;

                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as any[],
                      totalCount: 0,
                      page: query.page
                    };
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      // if (orderBy == 'target') {
                      //   orderBy = 'target__name';
                      // }
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                      console.log("DIRECTION", orderBy)
                    }
                    setLoading(true);

                    getCgByMarginList(
                      sb.business_id,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      orderBy
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: PaginatedResponse<StockReceipt>) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        setLoading(false);
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if ((error.status === 403 || error.status === 401)) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        setLoading(false);
                        resolve(pageData);
                      });
                  }
                }
              })
            }
            parentChildData={(row, rows) => rows.find(a => a.uuid === row.parentCommodityGroup)}
            title={
              <CustomTableTitle
                history={props.history}
                title={'Margin By Commodity Group'}
                icon={SVG_ICONS.reports} />
            }
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                    const newRecordToBeAdded = false;
                    const editedRecord = true
                    changeCgMargin(newData, oldData)
                }),
            }}

            detailPanel={[
              rowData => ({
                disabled: !rowData.child_data,
                // visible: !rowData.child_data,
                render: rowData => {
                  const { hasChildren, child_data } = rowData;

                  if (child_data != null) {
                    return (
                      <Paper style={{}}>
                        {renderDetailTable(rowData.child_data)}
                      </Paper>
                    )

                  }
                }
              })
            ]}
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              }
            ]}
          />
        </div>
      </div>
    )
  }




  return (
    <div>
      {renderTable()}

    </div>
  )
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(MarginByCgScreen);