import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import {
  Button, MenuItem, Grid, Switch,
  Paper, Typography, Tooltip, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, Checkbox, CircularProgress,
  FormControlLabel, FormHelperText, ListItemIcon, ListItemText,
  FormControl, Select, InputLabel, SvgIcon
} from '@material-ui/core';
import AssignmentReturnedOutlinedIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { useStyles } from './styles';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { getStockOrdersByPage } from '../../../stockOrder/api'
// import { getDNStockOrdersByPage } from '../../api'
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ErrorStatusBar, CustomTableTitle } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, getErrorMsg } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery, dNTableOptions } from '../../../shared/lib/TableQueryParams';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles';
import ClearIcon from '@material-ui/icons/Clear';
import cloneDeep from 'lodash/cloneDeep';


const StyleMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />

));

const ConfirmSupplierAndLocationForStockReceipt = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [parserModel, setParserModel] = useState(null)
  // const srSOTableRef = useRef<{ onQueryChange: any; }>();
  
  const {
    selectedSupplier,
    stepOneSuccess,
    pdfInfoExists,
    expressMode,
    supplierNameNotLinked,
    supplierLinked,
    selectedSuppNone,
    linkedSuppliers,
    handleManualSupplierChange,
    supplierError,
    uiLoading,
    selectedOrgUnit,
    soNumber,
    setSoNumber,
    setSelectedKoronaSOinList,
    selectedKoronaSOinList,
    handleChangeKoronaSO,
    selectedSO,
    handleSOSelectionChange,
    // handleGetSuppInfo,
    matchedJsonSupplier,
    soDetailsByNum,
    soNotFound,
    soNumberLoader,
    openSOListDialog,
    fullData,
    responseData,
    setOpenSOListDialog,
    setFullData,
    setReponseData,
    supplierOption,
    setSupplierOption,
    setSelectedSupplier,
    selectedSupplierManually,
    suppNotFound,
    setSelectedSupplierManually,
    tabVal,
    shouldSelectParserSetting,
    setShouldSelectedParserSetting,
    setTextractModel,
    textractModel,
    selectedParserSetting,
    setSelectedParserSetting

  } = props;

  useEffect(() => {
    console.log("matchedJsonSupplier", matchedJsonSupplier)
  }, [matchedJsonSupplier])


 


  // const getPagedElements = (data: any, pageNum: any, pageSize: any) => {

  //   let arr = data
  //   let totalElements = arr.length
  //   let page_size = pageSize
  //   let page = pageNum

  //   let startIndex = (page - 1) * page_size
  //   let endIndex = (startIndex + page_size)

  //   if (startIndex >= totalElements) {
  //     return []
  //   }
  //   else if (endIndex >= totalElements) {
  //     return arr.slice(startIndex)
  //   }
  //   return arr.slice(startIndex, endIndex)

  // }


  const handleClearButton = () => {
    setOpenSOListDialog(false)
    handleChangeKoronaSO(null)
    handleSOSelectionChange([])
    setSoNumber(null)

  }

  const renderClearButtton = () => {
    return (
      <Tooltip title="Clear SO" placement="bottom">
        <IconButton style={{ marginBottom: 10 }} onClick={handleClearButton}>
          <ClearIcon />
        </IconButton>
      </Tooltip>
    )
  }

  const handleSelectedParserSetting = (e: any) => {
    console.log("parser value - ", e.target.value);
    // setSelectedTags([...value]);
    const { parser_settings } = selectedSupplierManually.master_company;
    const parser_setting = parser_settings.find(ps => ps.text_extractor_module == e.target.value);
    setSelectedParserSetting(parser_setting);
    setParserModel(e.target.value);
  }

  useEffect(() => {
    if (props.selectedParserSetting) {
      setParserModel(props.selectedParserSetting.text_extractor_module);
    }
  }, [props.selectedParserSetting])

  const renderModelOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.text_extractor_module}>
          {o.parser_label}
        </MenuItem>
      );
    });
  };

  function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const hasMultipleParserSettings = () => {
    if (selectedSupplierManually) {
      const { parser_settings } = selectedSupplierManually.master_company;
      return selectedSupplierManually && parser_settings.length > 1;
    }
    return false;
  }
  const getParserSettings = () => {
    const { parser_settings } = selectedSupplierManually.master_company;
    return parser_settings ? parser_settings.map(p => {
      let labels = p.text_extractor_module.split("_")
      labels = labels.map(l => {
        return l.length > 0 ? capitalizeFirstLetter(l) : l;
      })
      p.parser_label = labels.join(" ");
      return p
    }) : [];
  }

  // const handleSOListOK = () => {
  //   setOpenSOListDialog(false)
  //   console.log("Selectedddddd SUpp", selectedKoronaSOinList)
  //   handleChangeKoronaSO(selectedKoronaSOinList)
  // }

  // const renderGetSupplierDetailsButton = () => {
  //   if (soNumberLoader) {
  //     return (
  //       <div style={{ textAlign: 'center' }}>
  //         <CircularProgress />
  //       </div>
  //     )
  //   }
  //   else {
  //     return (
  //       <Button style={{ border: 1, marginLeft: 10 }} size="small" variant='contained' color='primary' disabled={selectedSO ? true : false} onClick={handleGetSuppInfo} >
  //         Go
  //       </Button>
  //     )
  //   }

  // }


  // const handleChangeSONumber = (val: string) => {
  //   setSoNumber(val === '' ? null : val)
  //   if(soNumber != val){
  //     handleSOSelectionChange([])
  //   }
  //   if (val === '') {
  //     handleSOSelectionChange([])
  //   }
  //   srSOTableRef.current.onQueryChange()
  // }

  // const renderSOListDialog = () => {
  //   return (
  //     <div>
  //       <Dialog
  //         open={openSOListDialog}
  //         onClose={() => setOpenSOListDialog(false)}
  //         maxWidth='md'
  //         fullWidth={true}
  //         disableBackdropClick={true}
  //       >
  //         <DialogTitle style={{ color: GlobalStyles.primaryColor }}>{`Select a Stock Order`}</DialogTitle>
  //         <DialogContent style={{ alignSelf: 'center', width: '100%' }}>
  //           <MaterialTable
  //             options={{
  //               ...dNTableOptions(tableQuery),
  //               debounceInterval: 650,
  //               padding: 'dense',
  //               headerStyle: {
  //                 backgroundColor: '#eee',
  //                 paddingTop: 6,
  //                 paddingBottom: 6
  //               },
  //               emptyRowsWhenPaging: false,
  //               maxBodyHeight: 340,
  //               showTitle: false,
  //               selection: true,
  //               showSelectAllCheckbox: false,
  //               showTextRowsSelected: false,
  //               searchFieldAlignment: 'left',
  //               search: false,
  //               sorting: false,
  //               toolbar: false,
  //               selectionProps: (rowData: any) => {
  //                 const checked = rowData && rowData.tableData && rowData.tableData.checked;
  //                 return {
  //                   disabled: selectedKoronaSOinList && !checked ? true : false,
  //                   color: 'primary'
  //                 };
  //               }
  //             }}
  //             columns={[
  //               {
  //                 title: 'Date',
  //                 field: 'createTime',
  //                 render: rowData => (
  //                   rowData.createTime ?
  //                     <span>{moment(rowData.createTime).format('YYYY-MM-DD HH:mm')}</span>
  //                     : <span></span>
  //                 ),
  //               },
  //               { title: 'Number', field: 'number' },
  //               {
  //                 title: 'Warehouse',
  //                 field: 'organizationalUnit',
  //                 render: rowData => {
  //                   if (rowData && rowData.organizationalUnit && rowData.organizationalUnit.name) {
  //                     return <span>{rowData.organizationalUnit.name}</span>;
  //                   }
  //                   return <span></span>;
  //                 },
  //               },
  //               {
  //                 title: 'Supplier',
  //                 field: 'supplier',
  //                 render: rowData => {
  //                   if (rowData && rowData.supplier && rowData.supplier.name) {
  //                     return <span>{rowData.supplier.name}</span>;
  //                   }
  //                   return <span></span>;
  //                 },
  //               },

  //             ]}
  //             data={query =>
  //               new Promise((resolve, reject) => {
  //                 if (!uiLoading) {
  //                   const sb = props.userData.selectedBusiness;
  //                   if (sb && sb.business_id) {
  //                     let pageData = {
  //                       data: [] as any[],
  //                       totalCount: 0,
  //                       page: query.page
  //                     };
  //                     let orderBy = '';
  //                     if (query.orderBy) {
  //                       orderBy = query.orderBy.field as string;
  //                       orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
  //                       console.log("DIRECTION", orderBy)
  //                     }

  //                     if (fullData && responseData) {
  //                       let enteredSONumber = soNumber !== '' ? soNumber : null
  //                       let full_data = cloneDeep(fullData)
  //                       let matched_korona_supplier_id = matchedJsonSupplier.korona_supplier.uuid
  //                       let filtered_data = []
  //                       let page = query.page + 1
  //                       let page_size = query.pageSize


  //                       if (full_data.length > 0) {
  //                         console.log("FullData", full_data)
  //                         for (let d in full_data) {
  //                           if (full_data[d]['supplier']['id'] === matched_korona_supplier_id) {
  //                             if (enteredSONumber !== null) {
  //                               if (String(full_data[d]['number']).includes(enteredSONumber)) {
  //                                 filtered_data.push(full_data[d])
  //                               }
  //                             }
  //                             else filtered_data.push(full_data[d])
  //                           }
  //                         }
  //                       }
  //                       let resultantData = getPagedElements(filtered_data, page, page_size)


  //                       pageData = {
  //                         data: resultantData,
  //                         totalCount: filtered_data.length,
  //                         page: query.page
  //                       };
  //                       setTableQuery({
  //                         ...tableQuery,
  //                         orderBy,
  //                         totalCount: filtered_data.length,
  //                         page: query.page,
  //                         pageSize: query.pageSize
  //                       });
  //                       resolve(pageData);

  //                     } else {

  //                       let payload = {
  //                         response_data: responseData,
  //                         business_id: sb.business_id,
  //                         page_num: query.page + 1,
  //                         page_size: query.pageSize,
  //                         full_data: fullData,
  //                         matched_korona_supplier_id: matchedJsonSupplier.korona_supplier.uuid
  //                       }

  //                       getDNStockOrdersByPage(payload)
  //                         .catch(handleError) // handle network issues
  //                         .then(checkStatus)
  //                         .then(parseJSON)
  //                         .then((data: any) => {

  //                           setErrorStatusVisible(false);
  //                           setEmptyDataSourceMessage(EMPTY_ROW_MSG);
  //                           // let currData = cloneDeep(data.results)
  //                           // let filteredResults = currData.filter(res=>res.supplier.id === matchedJsonSupplier.korona_supplier.uuid)
  //                           if (!fullData && !responseData) {
  //                             setFullData(cloneDeep(data.response.results))
  //                             setReponseData(data)
  //                           }
  //                           pageData = {
  //                             data: data.results,
  //                             totalCount: data.count,
  //                             page: query.page
  //                           };
  //                           setTableQuery({
  //                             ...tableQuery,
  //                             orderBy,
  //                             totalCount: data.count,
  //                             page: query.page,
  //                             pageSize: query.pageSize
  //                           });
  //                           resolve(pageData);
  //                         })
  //                         .catch((error: any) => {
  //                           if (error) {
  //                             if (error.status && error.status === 500) {
  //                               setEmptyDataSourceMessage(ERROR_500);
  //                               setErrorStatusVisible(false);
  //                             } else if ((error.status === 403 || error.status === 401)
  //                             ) {
  //                               setEmptyDataSourceMessage(ERROR_MSG);
  //                               setErrorStatusVisible(true);
  //                             } else {
  //                               setEmptyDataSourceMessage(ERROR_MSG);
  //                               setErrorStatusVisible(false);
  //                             }
  //                           }
  //                           setTableQuery({
  //                             ...tableQuery,
  //                             totalCount: 0,
  //                             page: query.page,
  //                             pageSize: query.pageSize
  //                           });
  //                           resolve(pageData);
  //                         });
  //                     }

  //                   }
  //                 }
  //               })
  //             }
  //             tableRef={srSOTableRef}
  //             onSelectionChange={handleSOSelectionChange}
  //           />
  //           <TextField
  //             label="Enter a Stock Order Number"
  //             style={{ width: '15rem', marginBottom: 8 }}
  //             margin="dense"
  //             variant="outlined"
  //             value={soNumber != null ? soNumber : ''}
  //             onChange={(event) => handleChangeSONumber(event.target.value)}
  //             disabled={selectedSO ? true : false}
  //           />
  //           {
  //             <TextField
  //               id="Selected-so"
  //               style={{ width: 'inherit' }}
  //               label="Selected Stock Order"
  //               margin="normal"
  //               value={selectedKoronaSOinList ? selectedKoronaSOinList.number : ''}
  //             />
  //           }
  //         </DialogContent>
  //         <DialogActions>
  //           <Button onClick={handleClearButton} style={{ color: GlobalStyles.red }} >
  //             Cancel
  //           </Button>
  //           <Button onClick={handleSOListOK} style={{ color: GlobalStyles.primaryColor }} >
  //             OK
  //           </Button>
  //         </DialogActions>
  //       </Dialog>

  //     </div>

  //   )
  // }



  const renderPrimaryDetails = () => {
    return (
      <>
        <div className={classes.formRow}>
          <Typography
            variant="body1"
            className={classes.instructions}
            style={{ marginTop: 8 }}
          >
            Location:
          </Typography>
          <Typography
            variant="body1"
            className={!selectedOrgUnit ? classes.errorText : classes.displayValue}
            style={{ marginTop: 8 }}
          >
            {selectedOrgUnit ? selectedOrgUnit.name : 'Please select a single location.'}
          </Typography>
        </div>
      </>
    )
  }

  const renderSupplierDetails = () => {
    return (
      <>
        {renderPreselectedSupplier()}
        {/* {renderSupplierOptions()}
        {
          supplierOption.stockOrder ? renderSelectStockOrderDetails() : ()
        }
        {renderSelectedSO()} */}
      </>
    )
  }

  const renderSupplierOptions = () => {
    return (
      <div className={classes.formRow}>
        <Typography
          variant='body1'
          style={{ fontWeight: 'bold', marginRight: 10 }}
        >
          Do you have a stock order to map?
        </Typography>
        <Switch
          //@ts-ignore
          checked={supplierOption.stockOrder}
          onChange={handleChangeSupplierOption}
          name="stockOrder"
          color='primary'
/>
        {/* <FormControlLabel
          control={
            }
          label=""
          style={{ fontSize:'1.08rem', fontWeight:500 }}
          labelPlacement="start"
        /> */}
      </div>
      // <div>
      //   <Grid component="label" container alignItems="center" spacing={1}>
      //     <Grid item>Do you have a stock order to map?</Grid>
      //     <Grid item>
      //       <Switch
      //         label
      //         checked={}
      //         onChange={handleChange("checkedA")}
      //         value="checkedA"
      //       />
      //     </Grid>
      //     <Grid item>On</Grid>
      //   </Grid>
      // </div>
    );
  }

  const handleChangeSupplierOption = (event: any) => {
    let name = event.target.name
    if(name === 'stockOrder'){
      setSupplierOption({ ...supplierOption, [name]: event.target.checked, ['preselectedSupplier'] : !event.target.checked });
    }
    else{
      setSupplierOption({ ...supplierOption, [name]: event.target.checked, ['stockOrder'] : !event.target.checked });
    }
  }


  const renderSelectStockOrderDetails = () => {
    return (
      // border:'1px solid black'
      <div style={{}} className={classes.formRow}>
        <Typography
          variant="body1"
          className={classes.instructions}
          style={{ marginTop: 8 }}
        >
          Select an existing Stock Order
        </Typography>
        <div style={{ display: 'flex', width: '50%', justifyContent: 'end' }}>
          <Tooltip title="Get Stock Orders" placement="bottom">
            <SvgIcon
              htmlColor={GlobalStyles.primaryColor}
              onClick={() => setOpenSOListDialog(true)}
              className={classes.svgIcon}
            >
              <path d={SVG_ICONS.clipboardSearch} />
            </SvgIcon>
          </Tooltip>
        </div>
      </div>
    )
  }


  const renderPreselectedSupplier = () => {
    return (
      <>
        {/* <div style={{}} className={classes.formRow}>
          <Typography
            variant="body1"
            className={classes.instructions}
            style={{ marginTop: 8 }}>
            Note: New Stock Order will be created on booking this Stock Receipt
          </Typography>
        </div> */}
        <div style={{ }} className={classes.formRow}>
          <Typography
            variant="body1"
            className={classes.instructions}
            style={{ marginTop: 8 }}>
            Supplier:
          </Typography>
          <div style={{ display: 'flex', width: '50%', justifyContent: 'end' }}>
            <FormControl
              style={{ width: '100%' }}
              error={supplierError.hasError}
              disabled={soNumber !== null ? true : false}
            >
              <Select
                id="select-supplier"
                disabled={props.stepOneSuccess || uiLoading}
                value={selectedSupplierManually ? selectedSupplierManually.id : ''}
                onChange={handleManualSupplierChange}
              >
                {
                  linkedSuppliers.map((s: any) => (
                    <MenuItem
                      key={s.id}
                      value={s.id}>
                      {s.korona_supplier.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          {
                hasMultipleParserSettings() && 
                <>
                  <div className={classes.formRow} style={{ alignItems:null }}>
                    <Typography variant="body1" className={classes.instructions}> Select Parser &nbsp;</Typography>
                    <div style={{ alignSelf: 'center'}}>
                      
                      <Select
                        // label="Template"
                        style={{}}
                        labelId="demo-simple-select-outlined-label"
                        id={`so-model-select`}
                        value={parserModel && parserModel != null ? parserModel : null}
                        onChange={(e) => handleSelectedParserSetting(e)}
                        // variant="outlined"
                        label={`Model Parser`}
                        fullWidth={true}
                        margin="dense"
                      >
                        {renderModelOptions(getParserSettings())}
                      </Select>
                    </div>
                  </div>
                </>
              }
        </div>
      </>
    )
  }


  const renderSelectedSO = () => {
    // console.log("CAlled~~", selectedSO)

    if (selectedSO != null) {
      return (

        <div style={{  }} className={classes.formRow}>
          <Typography variant="body1" style={{ marginBottom: 8 }} className={classes.instructions}>{`Selected Stock Order:`}</Typography>
          <div style={{ display: 'flex', width: '50%',justifyContent: 'end' }}>
            <Typography variant="body1" style={{ marginBottom: 8 }} className={classes.instructions}>{selectedSO.number}</Typography>
            {renderClearButtton()}
          </div>

        </div>
      )
    }

  }

  const getErrorMsg = () => {
    if (selectedSupplierManually) {
      if(tabVal === 'json'){
      let errorMsg = `This Supplier does not match the supplier mapped by licence number`
      if (selectedSupplierManually.korona_supplier.uuid !== matchedJsonSupplier.korona_supplier.uuid) {
        setSelectedSupplier(null)
        return errorMsg
      }
    }

    }
    return ''
  }


  const renderErrorMsg = ()=>{
    return(
      <div className={classes.formRow}>
      <Typography variant="body1" style={{ marginBottom: 8, color: GlobalStyles.error }} className={classes.instructions}>
        {getErrorMsg()}
      </Typography>
    </div>
    )
  }

  const renderSuppNotFoundError =() =>{
    return(
      <div className={classes.formRow}>
      <Typography variant="body1" style={{ marginBottom: 8, color: GlobalStyles.error }} className={classes.instructions}>
        {/* {``} */}
        {``}
      </Typography>
    </div>
    )
  }

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        {
          !uiLoading &&
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8 }}>
            <Container maxWidth="sm">
              {renderPrimaryDetails()}
              {suppNotFound ? renderSuppNotFoundError() : renderSupplierDetails()}
              { supplierOption.preselectedSupplier && renderErrorMsg()}
            </Container>
          </div>
        }
      </Paper>
      {/* {renderSOListDialog()} */}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
    srData: state.stockReceiptModuleData.srData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSupplierAndLocationForStockReceipt);