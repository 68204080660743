import { GET_BUSINESSES, GET_BUSINESSES_SUCCESS, GET_BUSINESSES_FAILURE } from './actionTypes';
import { API_CONFIG } from '../../api/config';
import composeRequest, { checkStatus, handleError, parseJSON } from '../../api/core';
import { Business } from '../../models';
import { PaginatedResponse } from '../../models';


const BASE_URL = API_CONFIG.API_BASE_PATH;

const API_END_POINTS = {
    businessListUrl: BASE_URL + 'korona/master/businesses/',
}


export const getBusinessListFromAPI = () => {
    return (dispatch: any) => {
        dispatch(getBusinessList());
        const URL = API_END_POINTS.businessListUrl;
        composeRequest(URL, 'GET', null)
            .catch(handleError)
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<Business>) => {
                dispatch(getBusinessListSuccess(data));
            })
            .catch((err: any) => {
                console.log(err);
                dispatch(getBusinessListFailure(err));
            });
    }
}

const getBusinessList = () => {
    return {
        type: GET_BUSINESSES
    }
}

const getBusinessListSuccess = (data: PaginatedResponse<Business>) => {
    return {
        type: GET_BUSINESSES_SUCCESS,
        data
    }
}

const getBusinessListFailure = (err: any) => {
    return {
        type: GET_BUSINESSES_FAILURE,
        err
    }
}