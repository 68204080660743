import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { History } from 'history';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
// import TextField from '@material-ui/core/TextField';
import { FormControl, Input, ListSubheader, LinearProgress, Button, SvgIcon } from '@material-ui/core/';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';

import { logOutUser } from '../../../auth/data/actions/auth';
import {
    saveUserLocation,
    setSelectedLocations,
    setSelectedBusiness,
} from '../../../../store/actions/userLocation';
import { Location, Business, LocationBusiness } from '../../../shared/models';
import { BusinessRouterPaths } from '../../Routes/RouterPaths';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { UserSettings } from '../../../../store/reducers/userDataReducer';

interface Props {
    userLocalData: UserSettings;
    signOutUser: () => void;
    toggleDrawer: () => void;
    isMobileScreen: boolean;
    setSelectedLocations: (location: Location[]) => void;
    setSelectedBusiness: (business: LocationBusiness | undefined) => void;
    history: History;
    isUILoading: boolean;
    // classes: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        toolbar: theme.mixins.toolbar,
        multiSelect: {
            color: GlobalStyles.lightTheme,
            marginRight: 4,
            '&:before': {
                borderColor: GlobalStyles.lightTheme,
            },
            '&:after': {
                borderColor: GlobalStyles.lightTheme,
            },
            maxWidth: '12rem',
            minWidth: '12rem',
        },
        select: {
            color: GlobalStyles.lightTheme,
            marginRight: 4,
            '&:before': {
                borderColor: GlobalStyles.lightTheme,
            },
            '&:after': {
                borderColor: GlobalStyles.lightTheme,
            },
            maxWidth: '12rem',
            minWidth: '9rem',
        },
        icon: {
            fill: GlobalStyles.lightTheme,
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        username: {
            fontSize: '1rem',
            color: '#5f6368',
            width: '100%',
            padding: '0 12px',
            paddingBottom: 24,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    })
);

const Navbar = (props: Props) => {
    const classes = useStyles({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [userBusinesses, setUserBusinesses] = useState<LocationBusiness[]>([]);
    const [selectedBusiness, setSelectedBusiness] = useState<string | number | undefined>(-1);
    const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
    const [locationsGroupedByBusiness, setLocationsGroupedByBusiness] = useState<any>(null);

    const { userData } = props.userLocalData.userData;
    let allLocations: Location[] = [];
    if (userData) {
        allLocations = userData.locations;
    }
    // console.log(props.userLocation)
    // console.log("User-Data",userData);
    let userLocation = null;
    if (props.userLocalData.userLocation) {
        userLocation = props.userLocalData.userLocation;
    }

    useEffect(()=>{
        console.log("User-Business", userBusinesses)
    },[userBusinesses])

    useEffect(() => {
        if (props.userLocalData.userLocation) {
            userLocation = props.userLocalData.userLocation;
        }
    }, [props.userLocalData.userLocation]);

    useEffect(() => {
        if (allLocations) {
            // console.log("all locations",allLocations);
            const { selectedBusiness: savedBusiness } = props.userLocalData;
            allLocations.forEach((l) => (l.selected = true));
            const groupedLocations = groupBy(allLocations, 'business_name');
            // console.log("groupedLocations",groupedLocations);
            if (groupedLocations) {
                // console.log("group locations",groupedLocations)
                let businesses: LocationBusiness[] = [];
                Object.keys(groupedLocations).forEach((businessName) => {
                    // console.log("inside for loop",groupedLocations[businessName][0])
                    const { business_id, business_name,is_supplier} = groupedLocations[businessName][0];
                    // console.log("supplier",is_supplier)
                    businesses.push({
                        business_id: Number(business_id),
                        business_name: business_name ? business_name : '',
                        is_supplier: is_supplier
                    });
                });
                businesses = sortBy(businesses, 'business_name');
                const checkedLocations = allLocations.filter((l) => l.selected === true);
                // console.log("checked locations",checkedLocations);
                setUserBusinesses(businesses);
                setSelectedLocations(checkedLocations);
                setLocationsGroupedByBusiness(groupedLocations);
                // console.log('groupedLocations', groupedLocations);
                // console.log('userBusinesses', businesses);
                if (savedBusiness) {
                    setSelectedBusiness(savedBusiness.business_id);
                    props.setSelectedBusiness(savedBusiness);
                } else {
                    if (businesses && businesses[0]) {
                        setSelectedBusiness(businesses[0].business_id);
                        props.setSelectedBusiness(businesses[0]);
                    }
                }
            }
        }
    }, []);
    // const business=props.userLocalData.selectedBusiness.business_id
    // setSelectedBusiness(business)
    // console.log("props.userLocalData.selected business",props.userLocalData.selectedBusiness)
    // useEffect(() => {
    //    const sb=props.userLocalData.selectedBusiness
    //    const b = userBusinesses.find((b) => b.business_id == sb.business_id);
    //     console.log("b--business",b)
    //     props.setSelectedBusiness(b);
    //     setSelectedBusiness(b)

    // },[props.userLocalData.selectedBusiness]);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action: string) => {
        setAnchorEl(null);
        switch (action) {
            case 'sign-out':
                _signOutAsync();
                break;
            case 'account':
                props.history.push(BusinessRouterPaths.account);
                break;
        }
    };

    const _signOutAsync = async () => {
        try {
            await localStorage.removeItem('headers');
            props.signOutUser();
        } catch {
            // console.log('error in localStorage');
        }
        setTimeout(() => (window.location.href = '/sign-in'), 200);
    };

    const handleLocationSelectionChange = (event: any) => {
        // console.log("event.target",event.target);
        if (event && event.target && event.target.value) {
            const checkedId = event.target.value.find(id => typeof id === 'number');
            allLocations.forEach((l) => {
                if (l.id === checkedId) {
                    l.selected = !l.selected;
                }
            });
            const selectedLocationsLocal = allLocations.filter((l) => l.selected && l.business_id === selectedBusiness);
            // console.log("selectedLocationsLocal",selectedLocationsLocal);
            setSelectedLocations(selectedLocationsLocal);
            props.setSelectedLocations(selectedLocationsLocal);
        }
    };
    // console.log("business",selectedBusiness);
    const handleBusinessSelectionChange = (event: any) => {
        setSelectedBusiness(event.target.value);
        const b = userBusinesses.find((b) => b.business_id == event.target.value);
        // console.log("b--business",b)
        props.setSelectedBusiness(b);
    };

    useEffect(() => {
        if (selectedBusiness) {
            let newLocations = allLocations.filter((l) => l.business_id === selectedBusiness);
            newLocations.forEach((l) => (l.selected = true));
            newLocations = sortBy(newLocations, 'name');
            const groupedLocations = groupBy(newLocations, 'business_name');
            const checkedLocations = newLocations.filter((l) => l.selected === true);
            // console.log("checked locations",checkedLocations)
            setSelectedLocations(checkedLocations);
            setLocationsGroupedByBusiness(groupedLocations);
            props.setSelectedLocations(checkedLocations);
        }
    }, [selectedBusiness]);

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <IconButton
                    edge="start"
                    onClick={(e) => props.toggleDrawer()}
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    Konnect
                </Typography>
                {!props.isMobileScreen &&
                    userData &&
                    !userData.supplier &&
                    userBusinesses &&
                    userBusinesses.length > 1 && (
                        <FormControl className={classes.formControl}>
                            <InputLabel
                                id="select-label"
                                style={{ color: GlobalStyles.lightTheme }}
                            >
                                Business
                            </InputLabel>
                            <Select
                                labelId="select-label"
                                id="select-business"
                                value={selectedBusiness}
                                onChange={handleBusinessSelectionChange}
                                className={classes.select}
                                renderValue={(selected: unknown) => {
                                    const b = userBusinesses.find((b) => b.business_id == props.userLocalData.selectedBusiness.business_id);
                                    if (b) {
                                        // console.log("   Business id: " + b.business_id)
                                        setSelectedBusiness(b.business_id); // if business is modified on props.userData from any other component, set the business id here as well

                                        return b.business_name;
                                    }
                                    return -1;
                                }}
                                inputProps={{
                                    name: 'business',
                                    id: 'business_id',
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                                input={<Input id="select-input" />}
                            >
                                <MenuItem value={-1} key={'null'} />
                                {userBusinesses &&
                                    userBusinesses.length > 0 &&
                                    userBusinesses.map((b: any) => (
                                        <MenuItem value={b.business_id} key={String(b.business_id)}>
                                            {userData.is_superuser ?
                                                <>
                                                    <ListItemIcon>
                                                        <SvgIcon htmlColor={GlobalStyles.primaryColor} >
                                                            <path d={ b.is_supplier ? SVG_ICONS.letterS :SVG_ICONS.letterR } />
                                                        </SvgIcon>
                                                    </ListItemIcon>
                                                    <ListItemText>{b.business_name}</ListItemText>
                                                </>
                                                : b.business_name
                                            }
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                {!props.isMobileScreen && userData && (
                    <FormControl className={classes.formControl}>
                        <InputLabel
                            id="mutiple-checkbox-label"
                            style={{ color: GlobalStyles.lightTheme }}
                        >
                            Locations
                        </InputLabel>
                        <Select
                            labelId="mutiple-checkbox-label"
                            id="mutiple-checkbox"
                            multiple
                            value={selectedLocations}
                            onChange={handleLocationSelectionChange}
                            className={classes.multiSelect}
                            renderValue={(locs: any) => {
                                if (locs.length > 0) {
                                    const selected = locs.filter((l: Location) => l.selected && l.business_id === selectedBusiness);
                                    if (selected.length > 0) {
                                        if (selected.length > 1) {
                                            const total = selected.length - 1;
                                            return (
                                                selected[0].name.substring(0, 12) + ' + ' + total
                                            );
                                        } else if (selected.length === 1) {
                                            return selected[0].name;
                                        }
                                    }
                                }
                                return [];
                            }}
                            inputProps={{
                                name: 'location',
                                id: 'locationId',
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                            input={<Input id="multi-grouped-select" />}
                        >
                            {userData &&
                                locationsGroupedByBusiness &&
                                Object.keys(locationsGroupedByBusiness).map((key: string) => {
                                    let items = locationsGroupedByBusiness[key].map(
                                        (l: Location) => (
                                            <MenuItem
                                                // disabled={
                                                //     locationsGroupedByBusiness[key].length === 1
                                                // }
                                                value={l.id}
                                                key={l.id.toString()}
                                            >
                                                <Checkbox checked={l.selected} />
                                                <ListItemText primary={l.name} />
                                            </MenuItem>
                                        )
                                    );
                                    return [
                                        <ListSubheader
                                            key={key}
                                            style={{
                                                color: GlobalStyles.primaryColor,
                                            }}
                                        >
                                            {key}
                                        </ListSubheader>,
                                        items,
                                    ];
                                })}
                        </Select>
                    </FormControl>
                )}
                <div>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircleIcon fontSize={'large'} />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        // style={{ height: 'calc(100vh - 62px - 100px)' }}
                        open={open}
                        onClose={handleClose}
                    >
                        <div
                            style={{
                                // height: '180px',
                                width: '286.3px',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    height: 72,
                                    width: 72,
                                    margin: 'auto',
                                    backgroundColor: GlobalStyles.primaryColor,
                                    borderRadius: '50%',
                                    border: `1px solid ${GlobalStyles.primaryColor}`,
                                    marginTop: 12,
                                    marginBottom: 12,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: '2.6rem',
                                        fontWeight: 300,
                                        alignSelf: 'center',
                                        color: 'white',
                                    }}
                                >
                                    {userData && userData.first_name
                                        ? userData.first_name.charAt(0)
                                        : 'U'}
                                </Typography>
                            </div>
                            {userData && (
                                <div style={{ width: '100%', padding: '0 12px 4px' }}>
                                    <Typography
                                        variant="body1"
                                        align="center"
                                        style={{ fontSize: '1.2rem', fontWeight: 500 }}
                                    >
                                        {userData.first_name + ' ' + userData.last_name}
                                    </Typography>
                                </div>
                            )}
                            {userData && (
                                <div className={classes.username}>
                                    <Typography variant="body1" align="center">
                                        {userData.username}
                                    </Typography>
                                </div>
                            )}
                        </div>
                        <Divider />
                        <MenuItem
                            onClick={() => handleClose('account')}
                            style={{ paddingLeft: 42, paddingRight: 32 }}
                        >
                            <ListItemIcon>
                                <AccountCircleIcon />
                            </ListItemIcon>
                            <ListItemText>My Account</ListItemText>
                        </MenuItem>
                        <Divider />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: 24,
                                paddingBottom: 16,
                            }}
                        >
                            <Button
                                variant="outlined"
                                style={{ textTransform: 'none' }}
                                onClick={() => handleClose('sign-out')}
                            >
                                Sign Out
                            </Button>
                        </div>
                    </Menu>
                </div>
            </Toolbar>
            {/* {props.isUILoading && <LinearProgress color="secondary" />} */}
        </AppBar>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setSelectedLocations: (locations: Location[]) => dispatch(setSelectedLocations(locations)),
        setSelectedBusiness: (business: LocationBusiness | undefined) =>
            dispatch(setSelectedBusiness(business)),
        signOutUser: () => dispatch(logOutUser()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);
