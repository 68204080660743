import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MaterialTable, { MaterialTableProps } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { MasterSupplier } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { addMasterSupplier, deleteMasterSupplier, getMasterSuppliersByPage, updateMasterSupplier } from '../../api';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG, getErrorMsg } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);
const permission_model = 'mastersupplier';

const MasterSupplierScreen = (props: any) => {
    const tableRef = useRef<{ onQueryChange: any; }>();
    const classes = useStyles({});
    const [tableEditableProp, setTableEditableProp] = useState({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));

    const getAddFn = () => {
        return (newData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    addMasterSupplier(newData)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'Record added successfully!',
                                options: ToastSuccess
                            });
                            tableRef.current && tableRef.current.onQueryChange();
                        })
                        .catch((error: any) => {
                            const msg = getErrorMsg(error, 'Failed to add record.');
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });
    };

    const getDeleteFn = () => {
        return (oldData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    deleteMasterSupplier(oldData)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'Record deleted successfully!',
                                options: ToastSuccess
                            });
                            tableRef.current && tableRef.current.onQueryChange();
                        })
                        .catch((error: any) => {
                            const msg = getErrorMsg(error, 'Failed to delete record.');
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });

    };
    const getUpdateFn = () => {
        return (newData: any, oldData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    const data = { id: newData.id, name: newData.name };
                    updateMasterSupplier(data)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then(jsonData => {
                            props.showToast({
                                message: 'Record updated successfully!',
                                options: ToastSuccess
                            });
                            tableRef.current && tableRef.current.onQueryChange();
                        })
                        .catch((error: any) => {
                            const msg = getErrorMsg(error, 'Failed to update record.');
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });
    };

    useEffect(() => {
        const { userData } = props.userData.userData;
        const editable: any = {};
        if (userData) {
            if (hasAddPermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                editable['onRowAdd'] = getAddFn();
            }
            if (hasUpdatePermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                editable['onRowUpdate'] = getUpdateFn();
            }
            if (hasDeletePermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                editable['onRowDelete'] = getDeleteFn();
            }
            setTableEditableProp(editable);
        }
    }, [props.userData]);

    return (
        <Container maxWidth="md">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{ ...tableOptions(tableQuery) }}
                    columns={[
                        { title: 'ID', field: 'id', editable: 'never' },
                        { title: 'Supplier', field: 'name' },
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let pageData = {
                                data: [] as MasterSupplier[],
                                totalCount: 0,
                                page: query.page
                            };
                            replaceHistory(query, props.history);
                            getMasterSuppliersByPage(query.page + 1, query.pageSize, query.search)
                                .catch(handleError) // handle network issues
                                .then(checkStatus)
                                .then(parseJSON)
                                .then((data: PaginatedResponse<MasterSupplier>) => {
                                    setErrorStatusVisible(false);
                                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                    pageData = {
                                        data: data.results,
                                        totalCount: data.count,
                                        page: query.page
                                    };
                                    setTableQuery({
                                        ...tableQuery,
                                        totalCount: data.count,
                                        page: query.page,
                                        pageSize: query.pageSize,
                                    });
                                    resolve(pageData);
                                })
                                .catch((error: any) => {
                                    if (error.status && error.status === 500) {
                                        setEmptyDataSourceMessage(ERROR_500);
                                        setErrorStatusVisible(false);
                                    } else if ((error.status === 403 || error.status === 401)
                                    ) {
                                        setEmptyDataSourceMessage(ERROR_MSG);
                                        setErrorStatusVisible(true);
                                    } else {
                                        setEmptyDataSourceMessage(ERROR_MSG);
                                        setErrorStatusVisible(false);
                                    }
                                    setTableQuery({
                                        ...tableQuery,
                                        totalCount: 0,
                                        page: query.page,
                                        pageSize: query.pageSize,
                                    });
                                    resolve(pageData);
                                });
                        })
                    }
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        }
                    ]}
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Master Suppliers'}
                            icon={SVG_ICONS.suppliers} />
                    }
                    editable={tableEditableProp}
                />
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        masterSupplierList: state.masterModuleData.masterSupplierList,
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterSupplierScreen);