import React, { useEffect, useState } from 'react';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MasterProduct, Attribute, AttributeTypes } from '../../models';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { getMasterProductAttributes } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';

interface AttributeModalProps {
    onClose: (masterProduct: MasterProduct | null) => any;
    isOpen: boolean;
    onSave?: () => any;
    onUpdate?: () => any;
    masterProduct?: MasterProduct;
    allMasterAttributes: Attribute[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            width: 200,
            height: 230,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
    }),
);

function not(a: Attribute[], b: Attribute[]) {
    return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a: Attribute[], b: Attribute[]) {
    return a.filter(value => b.indexOf(value) !== -1);
}

export default function AttributeModal(props: AttributeModalProps) {
    // console.log(props)
    const classes = useStyles({});
    const [open, setOpen] = useState(props.isOpen);
    const [selectedMasterProduct, setSelectedMasterProduct] = useState<MasterProduct | null>(null);
    const [masterAttributes, setMasterAttributes] = useState<Attribute[]>([]);
    const [left, setLeft] = React.useState<Attribute[]>([]);
    const [right, setRight] = React.useState<Attribute[]>([]);
    const [checked, setChecked] = React.useState<any[]>([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        if (props.masterProduct) {
            if (props.masterProduct.attributes) {
                getMasterProductAttributes()
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then(jsonData => {
                        const attributes: Attribute[] = jsonData.map((a: Attribute) => {
                            return { ...a, checked: false, disabled: false };
                        });
                        const productAttributes = [];
                        let hasQuantityAttribute = false;
                        let hasUnitAttribute = false;
                        for (let a of attributes) {
                            //@ts-ignore
                            for (let attributeValue of props.masterProduct.attributes) {
                                if (a.id === attributeValue.attribute.id) {
                                    if (a.name === AttributeTypes.QUANTITY) {
                                        hasQuantityAttribute = true;
                                        productAttributes.push({ ...a, disabled: true });
                                    }
                                    else if (a.name === AttributeTypes.UNIT_OF_MEASURE) {
                                        hasUnitAttribute = true;
                                        productAttributes.push({ ...a, disabled: true });
                                    } else {
                                        productAttributes.push({ ...a });
                                    }
                                    a.disabled = true;
                                    a.checked = true;
                                }
                            }
                        }
                        // if product does not have qty attribute add it
                        // as a disabled list item
                        if (!hasQuantityAttribute) {
                            const qtyAttribute = attributes.filter(a => a.name === AttributeTypes.QUANTITY)[0];
                            qtyAttribute.disabled = true;
                            productAttributes.push(qtyAttribute);

                        }
                        // if product does not have unit attribute add it 
                        // as a disabled list item
                        if (!hasUnitAttribute) {
                            const unitAttribute = attributes.filter(a => a.name === AttributeTypes.UNIT_OF_MEASURE)[0];
                            unitAttribute.disabled = true;
                            productAttributes.push(unitAttribute);
                        }
                        setMasterAttributes(attributes);
                        setLeft(attributes);
                        setRight(productAttributes);
                    })
                    .catch((error: any) => {
                        console.log('error', error);
                    });
            }
            setSelectedMasterProduct({ ...props.masterProduct });
        }
    }, [props.masterProduct && props.allMasterAttributes]);

    const handleToggleLeft = (a: Attribute) => () => {
        a.checked = !a.checked;
        const currentIndex = checked.indexOf(a);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(a);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleRight = (a: Attribute) => () => {
        if (a.name !== AttributeTypes.QUANTITY &&
            a.name !== AttributeTypes.UNIT_OF_MEASURE) {
            a.checked = !a.checked;
            const currentIndex = checked.indexOf(a);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(a);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);
        }
    };

    const handleCheckedRight = () => {
        const newRightChecked = JSON.parse(JSON.stringify(leftChecked));
        newRightChecked.forEach((c: { checked: boolean; disabled: boolean; }) => {
            c.checked = false;
            c.disabled = false;
        });
        const newRight = [...right];
        right.forEach(r => r.checked = false);
        setRight(right.concat(newRightChecked));
        const newLeft = [...left];
        for (let i = 0; i < newLeft.length; i++) {
            for (let j = 0; j < leftChecked.length; j++) {
                if (newLeft[i].id === leftChecked[j].id) {
                    newLeft[i].disabled = true;
                }
            }
        }
        setLeft(newLeft);
        setChecked([]);
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const removeChecked = () => {
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        const newLeft = [...left];
        for (let i = 0; i < newLeft.length; i++) {
            for (let j = 0; j < rightChecked.length; j++) {
                if (newLeft[i].id === rightChecked[j].id) {
                    newLeft[i].disabled = false;
                    newLeft[i].checked = false;
                }
            }
        }
        setLeft(newLeft);
    };

    const customListLeft = (items: Attribute[]) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((a: Attribute) => {
                    const labelId = `transfer-list-item-${a.id}-label`;

                    return (
                        <ListItem key={String(a.id)} role="listitem" button
                            onClick={handleToggleLeft(a)}
                            disabled={a.disabled}>
                            <ListItemIcon>
                                <Checkbox
                                    //   checked={checked.indexOf(a) !== -1}
                                    checked={a.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={a.name} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );
    const customListRight = (items: Attribute[]) => (
        <Paper className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((a: Attribute) => {
                    const labelId = `transfer-list-item-${a.id}-label`;

                    return (
                        <ListItem key={String(a.id)} role="listitem" button
                            onClick={handleToggleRight(a)}
                            disabled={a.disabled}>
                            <ListItemIcon>
                                <Checkbox
                                    //   checked={checked.indexOf(a) !== -1}
                                    checked={a.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={a.name} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const onUpdate = () => {
        const mp: MasterProduct | null = selectedMasterProduct ? { ...selectedMasterProduct } : null;
        if (mp) {
            for (let i = 0; i < left.length; i++) {
                if (left[i].disabled) { // add attribute if it doesn't exist on product
                    const existingAttribute = mp.attributes.filter(a => a.attribute.id === left[i].id)[0];
                    if (!existingAttribute) {
                        const newAttributeValue = {
                            value: '',
                            attribute: {
                                id: left[i].id,
                                name: left[i].name
                            }
                        };
                        mp.attributes.push(newAttributeValue);
                    }
                } else {
                    mp.attributes = mp.attributes.filter(a => a.attribute.id !== left[i].id);
                }
            }
            props.onClose(mp);
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={props.isOpen}
                onClose={() => props.onClose(null)}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Update Master Product Attributes
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Grid container spacing={2} justify="center" alignItems="center"
                            className={classes.root}>
                            <Grid item>
                                <Typography variant='body1' style={{ padding: 12 }}>All Attributes</Typography>
                                {customListLeft(left)}
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        Add
                                </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        onClick={removeChecked}
                                        disabled={rightChecked.length === 0}
                                        aria-label="Remove"
                                    >
                                        Remove
                                </Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='body1' style={{ padding: 12 }}>Current Attributes</Typography>
                                </div>
                                {customListRight(right)}
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <Button onClick={() => props.onClose(null)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onUpdate()} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
