import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { ProduceCode } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getRocBySupplier } from '../../api';

import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import MaterialTable, { MTableToolbar } from 'material-table';
import { getMasterCompaniesFullList } from '../../../master-data/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },

    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.primaryColor,
      },
      '&:after': {
        borderColor: GlobalStyles.primaryColor,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

const FILTERS: Filter[] = [
  {
    id: 'f1',
    label: 'Company',
    name: 'COMPANY',
    types: [
      { id: '1', value: 'master_company_id__in', label: 'is equal' },
      { id: '2', value: 'master_company_id__not_in', label: 'is not equal' },
      { id: '3', value: 'company_supp_ids', label: 'is supplier', showSuppliers: true },
      { id: '4', value: 'master_company_id__isnull', label: 'is none', hasSelect: false },
    ],
    selectedType: 'master_company_id__in',
    value: [],
    url: 'korona/master/company-list/',
    labelField: 'name',
    bindValue: 'id',
    isResponsePaginated: true,
    data: []

  },
];


const RocBySupplierCompanyScreen = (props: any) => {
  const classes = useStyles({});
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');

  const [filterCompaniesList, setFilterCompaniesList] = useState(null)
  const [updatedFilters, setUpdatedFilters] = useState(null as any)


  useEffect(() => {
    getMasterCompaniesFullList()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        // console.log("results",data)
        setFilterCompaniesList(data);
      })
      .catch((error: any) => {
      });
  }, [])

  useEffect(() => {
    if (filterCompaniesList) {
      if (filterCompaniesList.length > 0) {
        let currentFilters = [...FILTERS]
        let companyFilterIndexFound = currentFilters.findIndex(cf => cf.name === 'COMPANY')
        if (companyFilterIndexFound > -1) {
          currentFilters[companyFilterIndexFound].data = filterCompaniesList
        }
        setUpdatedFilters([...currentFilters])
      }
    }

  }, [filterCompaniesList])




  useEffect(() => {

    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue)) || (f.types.find(ele => ele.value == f.selectedType).hasSelect === false)
    );
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }

      else if (!isRangeFilter) {

        // value is string
        filterValue = f.value.toString();

      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else if (f.types.find(ele => ele.value == f.selectedType).hasSelect === false) {
        filterString += `&${f.selectedType}=none`;
      }
      else {
        filterString += `&${f.selectedType}=${filterValue}`;
      }
    });
    console.log("FILTER-STRING", filterString, activeFilters)
    setFilterString(filterString);
  }, [selectedFilters]);

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    // console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };





  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onChangePage(null, 0);
    }
  }, [filterString]);


  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getRocBySupplier(
        null,
        null,
        tableQuery.search,
        filterString,
        sb.orderBy,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'roc_by_supp_company.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };


  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />

      <div style={{ maxWidth: '100%' }}>
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            components={{
              Toolbar: (props) => (
                <>
                  <MTableToolbar {...props} />
                  {filterVisible && (
                    <>
                      <FilterRow
                        {...props}
                        renderAvailableFilters={
                          <AvailableFilters
                            filters={updatedFilters ? updatedFilters : FILTERS}
                            addFilter={addFilter}
                          />
                        }
                        renderSelectedFilters={
                          <SelectedFilters
                            selectedFilters={selectedFilters}
                            removeFilter={removeFilter}
                            updateFilter={updateFilter}
                            businessId={null}
                          />
                        }
                      />
                    </>
                  )}
                </>
              ),

            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              { title: 'MP.ID', field: 'mp_id' },
              { title: 'Reorder Code', field: 'reorder_code' },
              { title: 'Invoice Description', field: 'invoice_description' },
              { title: 'Quantity', field: 'quantity' },

            ]}
            data={query =>
              new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as any[],
                  totalCount: 0,
                  page: query.page
                };
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                let filters = filterString != '' ? filterString : null
                console.log("filters", filters)
                if (!filters) {
                  resolve(pageData);
                } else {
                  setLoading(true);

                  getRocBySupplier(
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    filters,
                    orderBy

                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<any>) => {
                      setLoading(false);
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)
                        ) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      setLoading(false);

                      resolve(pageData);
                    });
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'ROC Supplier Company'}
                icon={SVG_ICONS.product} />
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting ? true : filterString === '' ? true : false,
              },
              {
                icon: 'filter_list',
                tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
                isFreeAction: true,
                iconProps: {
                  color: filterVisible ? 'primary' : 'inherit',
                },
                // hidden: !isSuperAdmin(props),
                onClick: toggleFilterPanel,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
        </div>
      </div>
      <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(RocBySupplierCompanyScreen);
