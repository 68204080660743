import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import {
  Button, MenuItem, Select,
  Container, Switch, IconButton,
  Typography, SvgIcon, CircularProgress, DialogContent, Dialog
} from '@material-ui/core';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { checkStatus, parseJSON, handleError } from '../../shared/api/core/common';
// import { Device } from '../../models';
// import { getDevicesByPage, deleteDevice, updateDevice } from '../../api';
// import { addDevice } from '../../data/actions';
import { getBusinessListFromAPI } from '../../shared/data/actions';
import { PaginatedResponse, Business } from '../../shared/models';
import { CustomTableTitle, ErrorStatusBar } from '../../shared/components';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../shared/lib/Localization';
import {
  isSuperAdmin,
  hasAddPermission,
  hasUpdatePermission,
  hasDeletePermission,
} from '../../shared/lib/PermissionManager';
import {
  initQueryParams,
  replaceHistory,
  tableOptions,
  useQuery,
} from '../../shared/lib/TableQueryParams';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../shared/components/Notifier';
import { SVG_ICONS } from '../../shared/icons/SvgIcons';
import { getExceptionsByPage, retryAll, updateException, RetryException } from '../api';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CloudDownload, SyncAlt, SyncProblem } from '@material-ui/icons';
import GlobalStyles from '../../../styles/GlobalStyles.web';
// import { RegisterDeviceModal } from '../../components';
// const QRCode = require('qrcode.react');
const useStyles = makeStyles((theme: Theme) => createStyles({}));
const permission_model = 'device';

const registerDeviceModalData = {
  open: false,
  onClose: null,
};

const BusinessSyncScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [tableEditableProp, setTableEditableProp] = useState({});
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [newDeviceModalData, setNewDeviceModalData] = useState(registerDeviceModalData);
  const [rowEdited, setRowEdited] = useState(false);
  

  useEffect(() => {
    if (isSuperAdmin(props) && !isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);



  const SelectBusinessEditComponent = (componentProps: any) => {
    const { value, onChange } = componentProps.colDataProps;
    return (
      <Select
        value={value ? value : ''}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        inputProps={{
          name: 'business sync Exception',
          id: 'choose-exception',
        }}
      >
        {props.businessList &&
          props.businessList.data &&
          props.businessList.data.map((b: Business) => (
            <MenuItem value={b.id} key={String(b.id)}>
              {b.name}
            </MenuItem>
          ))}
      </Select>
    );
  };

  // const isSuperUser = () => { };

  // const getUpdateFn = (newData: any) => {
  //   let updated = newData
  //   updated.metadata = JSON.parse(updated.metadata);
  //   setRowEdited(true)
  //   updateException(updated)
  //     .catch(handleError) // handle network issues
  //     .then(checkStatus)
  //     .then(parseJSON)
  //     .then((jsonData) => {
  //       setErrorStatusVisible(false);
  //       setEmptyDataSourceMessage(EMPTY_ROW_MSG);
  //       props.showToast({
  //         message: 'Task updated successfully!',
  //         options: ToastSuccess,
  //       });
  //       setRowEdited(false);
  //     })
  //     .catch((error: any) => {
  //       let msg = 'Failed to update Exception.';
  //       setRowEdited(false);
  //       if (error && error.status) {

  //         if (error.status === 403 || error.status === 401) {
  //           msg = NO_PERMISSIONS_ERROR_MSG;
  //         } else if (error.status === 500) {
  //           msg = ERROR_500;
  //         }
  //       }
  //       props.showToast({ message: msg, options: ToastError });
  //     });

  // };

  const RetryExceptionNow = (newData: any) => {
    setLoading(true);
    let updated = newData
    RetryException(updated)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        setLoading(false);
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        // setErrorStatusVisible(false);
        // props.showToast({
        //   message: 'Task succeeded!',
        //   options: ToastSuccess,
        // });
        props.showToast({
          message: 'Task(s) added to the queue, should run shortly. ',
          options: ToastSuccess,
        })
        tableRef.current.onQueryChange()
      })
      .catch((error: any) => {
        let msg = 'Task failed.';
        // setLoading(false);
        if (error && error.status) {

          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg ? msg : error, options: ToastError });
      });

  };

  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <div className="table-wrapper">
        <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: false,
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage,
            },
          }}
          columns={[
            {
              title: 'Queue Type',
              field: 'queue_type',
              type: 'string',
             editable: 'never'
            },
            {
              title: 'Model Type',
              field: 'model',
              align: 'center',
              editable: 'never',
            },
            { title: 'Exception', field: 'exception', editable: 'never' },
            { title: 'Business ID', field: 'business', editable: 'never' },
            { title: 'Try count', field: 'times_tried', editable: 'never' },
            {
              title: "Entry", field: 'metadata'
            }
              ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              if (!isLoading && props.userData) {
                // const sb = props.userData.selectedBusiness;
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as any,
                  totalCount: 0,
                  page: query.page,
                };
                setLoading(true);
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                // if (sb && sb.business_id) {
                getExceptionsByPage(
                  query.page,
                  query.pageSize,
                  query.search,
                  orderBy
                )
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((data) => {
                    setErrorStatusVisible(false);
                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                    
                    pageData = {
                      data: data.results.filter((item: any)=> item.metadata = JSON.stringify(item.metadata)),
                      totalCount: data.count,
                      page: query.page,
                    };
                    setTableQuery({
                      ...tableQuery,
                      orderBy,
                      totalCount: data.count,
                      page: query.page,
                      pageSize: query.pageSize,
                    });
                    setLoading(false);
                    resolve(pageData);
                  })
                  .catch((error: any) => {
                    if (error) {
                      if (error.status && error.status === 500) {
                        setEmptyDataSourceMessage(ERROR_500);
                        setErrorStatusVisible(false);
                      } else if (
                        error.status === 403 ||
                        error.status === 401
                      ) {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(true);
                      } else {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(false);
                      }
                    }
                    setTableQuery({
                      ...tableQuery,
                      totalCount: 0,
                      page: query.page,
                      pageSize: query.pageSize,
                    });
                    setLoading(false);
                    resolve(pageData);
                  });
                // }
              }
            })
          }
          title={
            <CustomTableTitle
              history={props.history}
              title={'Business sync Exception'}
              icon={SVG_ICONS.businessQueue}
            />
          }
          actions={[
            {
              icon: 'sync',
              tooltip: 'Try now',
              onClick: (event, rowData) => RetryExceptionNow(rowData)
            },
            {
              icon: ()=> <SyncProblem />,
              //  <CloudDownload />,
              tooltip: 'Retry all',
              isFreeAction: true,
              onClick: (event) => {
                setLoading(true);
                retryAll()
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((res: any)=>{
                    setLoading(false);
                    props.showToast({ message: res, options: ToastSuccess })
                  }).catch((
                    error:any
                  ) => {
                    setLoading(false);
                    let msg = 'Task failed.';
                  setLoading(false);
                  if (error && error.status) {

                    if (error.status === 403 || error.status === 401) {
                      msg = NO_PERMISSIONS_ERROR_MSG;
                    } else if (error.status === 500) {
                      msg = ERROR_500;
                    }
                  }
                  props.showToast({ message: msg?msg:error, options: ToastError });
                  })
            }
          },
          ]}
          // editable={tableEditableProp ? {
          //   onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //         resolve(getUpdateFn(newData));
          //       }, 1000)
          //     }),
          // } : null}
        />
        <Dialog
          open={isLoading}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
        <DialogContent style={{ alignSelf: 'center' }}>
          {
            isLoading &&
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          }
          </DialogContent>
          </Dialog>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    businessList: state.sharedModuleData.businessList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSyncScreen);
