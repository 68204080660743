import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Inventory } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';

import { API_URLS } from '../../api';
import composeRequest, { checkStatus, parseJSON, handleError } from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500 } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

const getInventoriesByPage = (
    businessId: number | string, 
    page: number,
    pageSize: number, 
    searchTerm: string | null,
    orderBy: string | number,
) => {
    // let URL = API_URLS.inventoriesUrl + '?org_unit=' + selectedLocation.korona_org_unit +
    let URL = API_URLS.inventoriesUrl +
        `?business_ids=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        // URL = API_URLS.inventoriesUrl + '?org_unit=' + selectedLocation.korona_org_unit +
        URL = API_URLS.inventoriesUrl +
            `?business_ids=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
      } 
    return composeRequest(URL, 'GET', null);
};

// const deleteInventory = (data: any) => {
//     const URL = API_URLS.inventoriesUrl + data.id + '/';
//     return composeRequest(URL , 'DELETE', null)
// }
const createTimeDisplayFormat = 'YYYY-MM-DD HH:mm';

const InventoryListScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);

    return (
        <Container maxWidth="lg">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{ 
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true                     
                    }}
                    //     options={{
                    //     pageSize: 10,
                    //     pageSizeOptions: [10, 50, 100, 500, 1000],
                    //     headerStyle: {
                    //         backgroundColor: '#eee',
                    //     }
                    // }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={[
                        {
                            title: 'Created On',
                            field: 'createTime',
                            render: rowData => (rowData.createTime ?
                                <div>{moment(rowData.createTime).format(createTimeDisplayFormat)}</div>
                                : <div></div>
                            )
                        },
                        { title: 'Number', field: 'number' },
                        { title: 'Type', field: 'inventoryProcedure' },
                        {
                            title: 'Inventory Start',
                            field: 'processTime',
                            render: rowData => (rowData.processTime ?
                                <div>{moment(rowData.processTime).format(createTimeDisplayFormat)}</div>
                                : <div>---</div>
                            )
                        },
                        {
                            title: 'Description',
                            field: 'description',
                            sorting: false,
                            cellStyle: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: 100
                            }
                        },
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            if (!isLoading) {
                                const sb = props.userData.selectedBusiness;
                                if (sb && sb.business_id) {
                                    replaceHistory(query, props.history);
                                    let pageData = {
                                        data: [] as Inventory[],
                                        totalCount: 0,
                                        page: query.page
                                    };
                                    setLoading(true);
                                    let orderBy = '';
                                    if (query.orderBy) {
                                        orderBy = query.orderBy.field as string;
                                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                    }
                                    getInventoriesByPage(
                                        sb.business_id,
                                        query.page + 1, 
                                        query.pageSize, 
                                        query.search,
                                        orderBy,
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<Inventory>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if ((error.status === 403 || error.status === 401)) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Inventories'}
                            icon={SVG_ICONS.inventory} />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                    ]}
                />
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(InventoryListScreen);