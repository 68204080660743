import React from 'react';

import { History } from 'history';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

interface Props {
  title: string;
  titleIcon?: string;
  secondaryTitle?: string | any;
  showBackButton?: boolean;
  actions?: JSX.Element;
  history: History;
  titleColor?: string;
  backButtonOnClick?:() => void;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageToolbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    pageTitle: {
      color: theme.palette.primary.main,
      padding: '0 4px',
      fontSize: '1.5rem'
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    titleIcon: {
      display: 'flex',
      alignSelf: 'center',
      color: '#6c6c6c',
      padding: '0 8px'
    },
    secondaryTitle: {
      fontWeight: 400,
      fontSize: '1.15rem',
      color: 'rgba(0, 0, 0, 0.87)',
    }
  }),
);

const PageToolbar = (props: Props) => {
  const classes = useStyles({});

  const navigateBack = () => {
    props.history.goBack();
  };

  const renderSecondaryTitle = () => {
    if (props.secondaryTitle) {
      const { secondaryTitle } = props;
      if (typeof (secondaryTitle) === "string") {
        return (
          <span className={classes.secondaryTitle}>
            &nbsp;&nbsp;&ndash;&nbsp;&nbsp;{secondaryTitle}
          </span>
        );
      } else {
        return <>{secondaryTitle}</>;
      }
    }
    return null;
  };

  return (
    <div>
      <div className={classes.pageToolbar}>
        <div className={classes.titleWrapper}>
          {
            props.showBackButton &&
            <IconButton onClick={props.backButtonOnClick ? props.backButtonOnClick : navigateBack} >
              <ArrowBackIcon />
            </IconButton>
          }
          {
            props.titleIcon &&
            <div className={classes.titleIcon}>
              <SvgIcon>
                <path d={props.titleIcon} />
              </SvgIcon>
            </div>
          }
          <Typography variant='h5'
            className={classes.pageTitle} style={{ color: props.titleColor ? props.titleColor : GlobalStyles.primaryColor}}>{props.title}
            {
              renderSecondaryTitle()
            }
          </Typography>
        </div>
        <div>
          {props.actions ? props.actions : null}
        </div>
      </div>
      <Divider className={classes.divider} />
    </div>
  );
};

export default PageToolbar;