import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    stockReceiptsUrl: BASE_URL + 'korona/stockReceipts/',
    stockReceiptSummaryDataUrl: BASE_URL + 'korona/stockReceipts/summary/',
    recentUnfinishedSOUrl: BASE_URL + 'korona/stock-orders/recent-orders',
    stockReceiptMatchInvoiceProductListUrl: BASE_URL + 'korona/stock-receipts/match-sr-invoice-products',
    createStockReceipt: BASE_URL + 'korona/stock-receipts/create',
    parseUrlData: BASE_URL + 'korona/stock-receipts/parse-url-data',
    tempSRCustomerProductLinkUrl: BASE_URL + 'korona/stock-receipts/temp-sr-customer-prod-link',
    tempSRNewProductLinkUrl: BASE_URL + 'korona/stock-receipts/temp-sr-new-prod-link',
    changeSRRocTempRecUrl: BASE_URL + 'korona/stock-receipts/change-roc-temp-rec',
    uploadSRInvoicePDFUrl: BASE_URL + 'korona/stock-receipts/invoice-pdf-parser/upload',
    parseSRPDFUrl: BASE_URL + 'korona/stock-receipts/invoice-pdf-parser/upload',
    stockReceiptItemsByBusiness: BASE_URL + 'korona/stock-receipts-items/',
    getAllKoronaSuppliersListUrl: BASE_URL + 'korona/stock-receipts/get-all-korona-supps',
    getCgByMarginListUrl : BASE_URL + 'korona/cg-by-margin-list/',
    updateMarginByCgUrl : BASE_URL + 'korona/margin-by-cg/update-margin',
    getPriceRoundUrl : BASE_URL + 'korona/price-round-details/',
    updatePriceRoundUrl : BASE_URL + 'korona/price-round-details/update-price-round',

}

export const updateMarginByCg = (data: any) => {
    const URL = API_URLS.updateMarginByCgUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getCgByMarginList = (
    business_id: number | string,
    page: number,
    pageSize: number,
    searchTerm: string,
    orderBy: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.getCgByMarginListUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${API_URLS.getCgByMarginListUrl}?business_id=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null)

}


export const addPriceRound = (data: any) => {
    const URL = API_URLS.getPriceRoundUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const updatePriceRound = (data: any) => {
    const URL = API_URLS.getPriceRoundUrl + `${data.id}/`;
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};


export const deletePriceRound = (data: any) => {
    const URL = API_URLS.getPriceRoundUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getPriceRoundList = (
    business_id: number | string,
    page: number,
    pageSize: number,
    searchTerm: string,
    orderBy: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.getPriceRoundUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${API_URLS.getPriceRoundUrl}?business_id=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null)

}




export const getStockReceiptsByPage = (
    business_id: number | string,
    page: number,
    pageSize: number,
    searchTerm: string,
    orderBy: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.stockReceiptsUrl}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${API_URLS.stockReceiptsUrl}?business_id=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null)
}

export const getStockReceiptsItemMarginByPage = (
    sr_uuid: string,
    page: number,
    pageSize: number,
    searchTerm: string,
    orderBy: string | number,
    filters?: string

) => {
    let URL = `${API_URLS.stockReceiptItemsByBusiness}?sr_uuid=${sr_uuid}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${API_URLS.stockReceiptItemsByBusiness}?sr_uuid=${sr_uuid}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null)
}

export const getStockReceiptsDataSummary = (business_id: number | string) => {
    const URL = `${API_URLS.stockReceiptSummaryDataUrl}?business_id=${business_id}`;
    return composeRequest(URL, 'GET', null)
}


export const getRecentUnfinishedStockOrders = (data: any) => {
    const URL = API_URLS.recentUnfinishedSOUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const addStockReceipt = (data: any) => {
    const URL = API_URLS.createStockReceipt;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}

export const getStockReceiptsInvoiceProductList = (data: any) => {
    const URL = API_URLS.stockReceiptMatchInvoiceProductListUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const parseSRJsonUrlData = (data: any) => {
    const URL = API_URLS.parseUrlData;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const changeSRRocTempRec = (data: any) => {
    const URL = API_URLS.changeSRRocTempRecUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}



export const uploadSRInvoicePDf = (payload) => {
    const URL = `${API_URLS.uploadSRInvoicePDFUrl}`;
    return composeRequest(URL, 'PUT', payload, "multipart/form-data");
};


export const parseSRPdf = (
    business_id: number,
    stored_aws_response_id: number,
    supplier_id: number,
    master_company_exists?: boolean
) => {
    let URL = `${API_URLS.parseSRPDFUrl}?business_id=${business_id}&stored_aws_response_id=${stored_aws_response_id}&supplier_id=${supplier_id}`;
    if (master_company_exists) {
        URL = URL + `&master_company_exists=${master_company_exists}`
    }
    console.log("URL", URL)
    return composeRequest(URL, 'GET', null)

};


export const getKoronaSuppliersList = (data: any) => {
    const URL = API_URLS.getAllKoronaSuppliersListUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}