import {
    GET_STOCK_RECEIPT_SUMMARY, GET_STOCK_RECEIPT_SUMMARY_FAILURE,
    GET_STOCK_RECEIPT_SUMMARY_SUCCESS, SET_SELECTED_STOCK_RECEIPT_DATA,
    CLEAR_SELECTED_STOCK_RECEIPT_DATA, CLEAR_STOCK_RECEIPT, 
    CLEAR_ADD_CODE_ACTION_DATA, SET_ADD_CODE_ACTION_DATA
} from './actionTypes';
import { getStockReceiptsDataSummary } from '../../api';

import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { StockReceipt } from '../../models';
import { StockReceiptData } from '../reducers/createStockReceiptReducer';
import { ProductCodeType } from '../../../products/models';
import { CodeActionData } from '../reducers/codeActionReducer';

export interface StockReceiptSummaryResponse {
    lastCreatedOn: string;
    oldestOpenDate: string;
    openCount: number;
    totalCount: number;
}

export function getStockReceiptDataSummary(business_id: number | string) {
    return (dispatch: any) => {
        dispatch(getStockReceiptSummary());
        getStockReceiptsDataSummary(business_id)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: StockReceiptSummaryResponse) => {
                dispatch(getStockReceiptSummarySuccess(data));
            })
            .catch((error: any) => {
                dispatch(getStockReceiptSummaryFailure(error));
            });
    };
}

const getStockReceiptSummary = () => {
    return {
        type: GET_STOCK_RECEIPT_SUMMARY
    };
};

const getStockReceiptSummarySuccess = (data: StockReceiptSummaryResponse) => {
    return {
        type: GET_STOCK_RECEIPT_SUMMARY_SUCCESS,
        data
    };
};

const getStockReceiptSummaryFailure = (err: any) => {
    return {
        type: GET_STOCK_RECEIPT_SUMMARY_FAILURE,
        err
    };
};

export const setSelectedStockReceiptData = (data: StockReceiptData) => {
    return {
        type: SET_SELECTED_STOCK_RECEIPT_DATA,
        data
    };
};

export const clearSelectedStockReceiptData = () => {
    return {
        type: CLEAR_SELECTED_STOCK_RECEIPT_DATA
    };
};
export const clearStockReceiptForCurrentWorkflow = () => {
    return {
        type: CLEAR_STOCK_RECEIPT
    };
};
export const setAddCodeToProductActionData = (data: CodeActionData) => {
    return {
        type: SET_ADD_CODE_ACTION_DATA,
        data
    };
};
export const clearAddCodeToProductActionData = () => {
    return {
        type: CLEAR_ADD_CODE_ACTION_DATA
    };
};