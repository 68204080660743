import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Divider, SvgIcon } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useStyles } from './styles';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

import { SelectTileModal, renderCard } from '../../components';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { getDeviceDataSummary } from '../../../devices/data/actions';
import { getUserDataSummary } from '../../../users/data/actions';
import { getProductDataSummary } from '../../../products/data/actions';
import { getLabelerDataSummary } from '../../../labeler/data/actions';
import { getStockOrderDataSummary } from '../../../stockOrder/data/actions';
import { getStockReceiptDataSummary } from '../../../stockReceipt/data/actions';
import { getInventoryDataSummary } from '../../../inventory/data/actions';
import { setSelectedDashboardTiles } from '../../../../store/actions';
import { saveUserWebSettings } from '../../api';
import { BusinessRouterPaths } from '../../../page/Routes/RouterPaths';
import { RetailerPrimaryMenuItems } from '../../../page/components/SideDrawer/Menu/MenuItems';
import { RetailDashboardCards, RetailSummaryFnMapping, Report, SelectedReport, ReportCard } from '../../models';
import { getAuthorizedCards } from './AuthorizedCards';
import { PageToolbar } from '../../../page/components';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';

const DashboardScreen = (props: any) => {
    console.log(props);
    const classes = useStyles({});
    const { userData } = props.userLocalData.userData;
    const { selectedDashboardTiles } = props.userLocalData;
    const [reportCards, setReportCards] = useState<ReportCard[]>([]);
    const [isTileModalOpen, setTileModalOpen] = useState(false);
    const [reportItems, setReportItems] = useState([] as Report[]);

    let userLocation = null;
    if (props.userLocalData.userLocation) {
        userLocation = props.userLocalData.userLocation;
    }

    useEffect(() => { // load selectedCards from settings
        const parentChildMap = new Map();
        const enabledTileIds: string[] = [];
        selectedDashboardTiles.forEach((t: SelectedReport) => {
            parentChildMap.set(t.id, t.items);
            t.isEnabled ? enabledTileIds.push(t.id) : null;
        });
        const { userData } = props.userLocalData.userData;
        if (userData) {
            const permissions = userData.user_permissions;
            const authorizedCards = getAuthorizedCards(RetailDashboardCards, permissions, userData.is_superuser);
            console.log(authorizedCards);
            const cards = authorizedCards.filter(c => enabledTileIds.includes(c.id));
            cards.forEach(c => c.items.forEach(child => {
                child.isVisible = parentChildMap.get(c.id).includes(child.id);
            }));
            setReportCards(cards);

            const allReportItems = authorizedCards.map(i => {
                const t: Report = {
                    id: i.id,
                    title: i.title,
                    items: i.items,
                    isEnabled:
                        parentChildMap.has(i.id) && enabledTileIds.includes(i.id) ||
                        !parentChildMap.has(i.id) && !enabledTileIds.includes(i.id)
                };
                return t;
            });
            setReportItems(allReportItems);
        }
    }, [props.userLocalData.selectedDashboardTiles]);

    const persistDashboardTiles = (selectedReports: SelectedReport[]) => {
        const { userData } = props.userLocalData.userData;
        const { web_settings } = userData;
        const payload = {
            ...web_settings,
            selectedDashboardTiles: selectedReports
        };
        saveUserWebSettings(payload)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: any) => {
                console.log('settings saved');
            })
            .catch((error: any) => {
                console.log('error saveUserWebSettings');
            });;
    };

    useEffect(() => {
        if (props.userLocalData && props.userLocalData.selectedBusiness) {
            setTimeout(() => {
                const sb = props.userLocalData.selectedBusiness;
                if (sb.business_id) {
                    getSummaryData(sb.business_id);
                }
            }, 500);
        }
    }, [props.userLocalData && props.userLocalData.selectedBusiness, reportCards]);

    const getSummaryData = (businessId: number) => {
        const summaryMappings = reportCards.map(c => c.summaryMapping);
        summaryMappings.forEach((mapping) => {
            if (mapping) {
                props[RetailSummaryFnMapping[mapping]](businessId);
            }
        });
    };

    const toggleCards = (checked: boolean, reportId: string) => {
        const allReportItems = [...reportItems];
        allReportItems.forEach(c => c.id === reportId ? c.isEnabled = checked : null);
        setReportItems(allReportItems);
    };

    const getInventorySummary = () => {
        return '' + props.inventorySummary.totalInventoryCountList +
            ' inventory count lists in ' +
            props.inventorySummary.totalInventoryCounts +
            ' inventory counts';
    };

    const navigateTo = (location: string) => {
        const { history } = props;
        switch (location) {
            case 'devices': history.push(BusinessRouterPaths.deviceManager);
                break;
            case 'inventories': history.push(BusinessRouterPaths.inventories);
                break;
            case 'products': history.push(BusinessRouterPaths.productList);
                break;
            case 'stockOrders': history.push({
                pathname: BusinessRouterPaths.stockOrderList,
                state: { menuItemName: RetailerPrimaryMenuItems[3].name }
            });
                break;
            case 'stockReceipts': history.push(BusinessRouterPaths.stockReceiptList);
                break;
            case 'templates': history.push({
                pathname: BusinessRouterPaths.labelerTemplates,
                state: { menuItemName: RetailerPrimaryMenuItems[5].name }
            });
                break;
            case 'users': history.push(BusinessRouterPaths.userManager);
                break;
            default: history.push(BusinessRouterPaths.dashboard);
                break;
        }
    };

    const renderBlankCard = () => {
        return (
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            style={{ fontSize: 16 }}
                            color="textSecondary" gutterBottom>
                            Getting Started
                        </Typography>
                        <IconButton onClick={() => setTileModalOpen(true)}>
                            <AddIcon />
                        </IconButton>
                    </CardContent>
                </Card>
            </Grid>
        );
    };


    return (
        <div>
            <PageToolbar
                title='Dashboard'
                history={props.history}
                actions={
                    <Tooltip title="Configure Tiles" placement="bottom">
                        <IconButton onClick={() => setTileModalOpen(true)}>
                            <SvgIcon>
                                <path d={SVG_ICONS.gridOutlinePlus} />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                } />
            <SelectTileModal
                isOpen={isTileModalOpen}
                onCancel={() => setTileModalOpen(false)}
                onConfirm={(selected) => {
                    persistDashboardTiles(selected);
                    props.saveSelectedDashboardTiles(selected);
                    setTileModalOpen(false);
                }}
                reports={reportItems}
                selectedReportItems={selectedDashboardTiles}
                toggleCards={toggleCards}
            />
            <Grid container spacing={3} style={{ paddingTop: 16 }}>
                {
                    reportCards.map(card => {
                        const summaryData = card.summaryMapping ? props[card.summaryMapping] : null;
                        return renderCard(classes, card, summaryData, navigateTo);
                    })
                }
            </Grid>
            {
                reportCards && reportCards.length === 0 &&
                <Grid container spacing={3} style={{ paddingTop: 16 }}>
                    {renderBlankCard()}
                </Grid>
            }
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData,
        userSummary: state.userModuleData.userSummaryData,
        deviceSummary: state.deviceModuleData.deviceSummaryData,
        productSummary: state.productData.productSummaryData,
        labelerSummary: state.labelerData.labelerSummaryData,
        stockOrderSummary: state.stockOrderModuleData.stockOrderSummaryData,
        stockReceiptSummary: state.stockReceiptModuleData.stockReceiptSummaryData,
        inventorySummary: state.inventoryData.inventorySummaryData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getDeviceSummary: (business_id: number | string) => dispatch(getDeviceDataSummary(business_id)),
        getUserSummary: (business_id: number | string) => dispatch(getUserDataSummary(business_id)),
        getProductSummary: (business_id: number | string) => dispatch(getProductDataSummary(business_id)),
        getLabelerSummary: (business_id: number | string) => dispatch(getLabelerDataSummary(business_id)),
        getStockOrderSummary: (business_id: number | string) => dispatch(getStockOrderDataSummary(business_id)),
        getStockReceiptSummary: (business_id: number | string) => dispatch(getStockReceiptDataSummary(business_id)),
        getInventorySummary: (business_id: number | string) => dispatch(getInventoryDataSummary(business_id)),
        saveSelectedDashboardTiles: (selectedReports: SelectedReport[]) => dispatch(setSelectedDashboardTiles(selectedReports))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);