import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography, Container, Select, Menu, MenuItem, FormControl, InputLabel, Input, Link, SvgIcon,
  Card, CardContent, CircularProgress, Divider, FormControlLabel, Grid, TextField,
  IconButton, Checkbox, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, ListSubheader, LinearProgress, Button, Snackbar, Icon, Paper

} from '@material-ui/core';

import { MasterBrandList, MasterBrand, ItemType } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import {
  getAllMasterBrandList, getMasterCompaniesFullList, getAllMasterCompanies, addNewBrandOrMaster
} from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, ERROR_404, ERROR_409 } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { blue, orange, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    qtyRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 16px'
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

    },

    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '20rem',
      minWidth: '12rem',
    },
  }),
);

const permission_model = 'masterproduct';


const FILTERS: Filter[] = [
  {
    id: 'f1',
    label: 'ID',
    types: [
      { id: '1', value: 'id', label: 'is equal' },
      { id: '2', value: 'brand_id_range', label: 'between' }
    ],
    selectedType: 'id',
    value: '',
    url: null,
    minValue: '',
    maxValue: ''
  },
  {
    id: 'f2',
    label: 'Name',
    types: [
      { id: '1', value: 'name__istartswith', label: 'begins with' },
      { id: '2', value: 'name__icontains', label: 'contains' },
      { id: '3', value: 'name', label: 'is equal' },
    ],
    selectedType: 'name',
    value: '',
    url: null,
  },
  {
    id: 'f3',
    label: 'Company',
    name:'COMPANY',
    types: [
      { id: '1', value: 'company__id__in', label: 'is equal' },
      { id: '2', value: 'company__id__not_in', label: 'is not equal' },
      { id: '3', value: 'company__id__isnull', label: 'is none', hasSelect: false },
    ],
    selectedType: 'company__id__in',
    value: [],
    url: 'korona/master/company-list/',
    labelField: 'name',
    bindValue: 'id',
    isResponsePaginated: true,
    data:[]
  },
  {
    id: 'f4',
    label: 'Images',
    types: [
      { id: '1', value: 'master_brand_having', label: 'is equal' },
    ],
    url: null,
    selectedType: 'master_brand_having',
    value: '',
    choices: [
      { id: '1', value: 'zero_image', label: 'has no image' },
      { id: '2', value: 'one_image', label: 'has exactly one image' },
      { id: '3', value: 'more_than_one_image', label: 'has more than one image' },
      { id: '4', value: 'inactive_images', label: 'has inactive image(s)' }
    ],
  },
];



const MasterBrandScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [tableEditableProp, setTableEditableProp] = useState({});
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([])
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false)
  const [newBrandName, setNewBrandName] = useState('');
  const [newBrandCompanyName, setNewBrandCompanyName] = useState('');
  const [newBrandCompany, setNewBrandCompany] = useState(null);
  const [newBrandActive, setNewBrandActive] = useState("1");
  const [newBrandNameExists, setNewBrandNameExists] = useState(false)
  const [companies, setCompanies] = useState([]);
  const [dialogLoading, setDialogLoading] = useState(false)
  const [fromCompanyFilter, setFromCompanyFilter] = useState(null)

  const [intialLoad, setInitialLoad] = useState(true)
  const [filterCompaniesList, setFilterCompaniesList] = useState(null)
  const [updatedFilters, setUpdatedFilters] = useState(null as any)

  useEffect(()=>{
    getMasterCompaniesFullList()
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then((data:any) => {
      // console.log("results",data)
      setFilterCompaniesList(data);
    })
    .catch((error: any) => {
    });
},[])        

useEffect(()=>{
  if(filterCompaniesList ){
      if(filterCompaniesList.length > 0){
          let currentFilters = [...FILTERS]
          let companyFilterIndexFound = currentFilters.findIndex(cf=>cf.name === 'COMPANY')
          if(companyFilterIndexFound > -1){
              currentFilters[companyFilterIndexFound].data = filterCompaniesList
          }
          setUpdatedFilters([...currentFilters])
      }
  }
  
},[filterCompaniesList])


  useEffect(() => {
    if (props && props.location && props.location.state && props.location.state.id && props.location.state.from === 'company') {
      console.log("propsss", props)
      setFromCompanyFilter(`&company__id__in=${props.location.state.id}`)
      tableRef.current && tableRef.current.onChangePage(null, 0);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (!isLoading && !intialLoad) {
      tableRef.current && tableRef.current.onChangePage(null, 0);
      setNewBrandName("");
      setNewBrandNameExists(false);
      setNewBrandActive("1");
      setNewBrandCompanyName("")
    }
  }, [filterString]);


  useEffect(() => {
    if (dialogBoxOpen) {
      setDialogLoading(true)
      getAllMasterCompanies()
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: PaginatedResponse<any>) => {
          // console.log("results",data)
          setDialogLoading(false)

          setCompanies(data.results);
        })
        .catch((error: any) => {
        });
    }

  }, [dialogBoxOpen])


  useEffect(() => {
    console.log("selected filter", selectedFilters)
    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue)) || (f.types.find(ele => ele.value == f.selectedType).hasSelect === false)
    );
    console.log("active filter", activeFilters)
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      //console.log("has Select",f.types.find(ele=>ele.value==f.selectedType).hasSelect) 
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }
      else if (!isRangeFilter) {
        filterValue = f.value.toString();
      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else if (f.types.find(ele => ele.value == f.selectedType).hasSelect === false) {
        filterString += `&${f.selectedType}=none`;
        console.log("company is none")
      }
      else {
        filterString += `&${f.selectedType}=${filterValue}`;
      }
    });

    setFilterString(filterString);
    console.log("filter string", filterString)
  }, [selectedFilters]);

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    // console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };




  const navigateToMasterBrandDetailScreen = (rowData: any) => {
    const masterBrandDetailUrl = MasterDataRouterPaths.masterBrands + '/' + rowData.id;
    props.history.push(masterBrandDetailUrl, rowData);
  };

  const handleNewBrandCompanyChange = (event: any) => {
    // console.log("FILTERED",event.target)
    let val = event.target.value
    let filtered = filterCompaniesList ? filterCompaniesList.filter(p => p.id === val) : null
    setNewBrandCompany(filtered[0].id)
    setNewBrandCompanyName(val)
    setNewBrandNameExists(false)
  }

  const handleNewBrandNameChange = (event: any) => {
    // console.log("NEW-BRAND-NAME",event.target.value)
    let val = event.target.value
    setNewBrandName(val)
    setNewBrandNameExists(false)
  }

  // const validateBrandName = (bName: any, companyId: any) => {
  //   let filtered = brand ? brand.filter(p => (p.name.toLowerCase().split(' ').join('') === bName.toLowerCase().split(' ').join('') && (p.company && p.company.id === companyId))) : null
  //   // console.log("filtered", filtered)
  //   // console.log("DATA",data.toLowerCase().split(' ').join(''))
  //   if (filtered.length === 0) {
  //     return true;
  //   }
  //   return false
  // }

  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
    setNewBrandName("");
    setNewBrandNameExists(false);
    setNewBrandActive("1");
    setNewBrandCompanyName("")
  }

  const handleNewBrandActiveChange = (event: any) => {
    setNewBrandActive(event.target.value)
  }


  const renderAddBrandDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogBoxOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add a new brand</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              {
                dialogLoading &&
                <div style={{ textAlign: 'center', marginTop: 5 }}>
                  <CircularProgress />
                </div>
              }
              {
                !dialogLoading &&
                <div className={classes.dialogContent} >
                  <TextField
                    autoFocus
                    margin="dense"
                    size="small"
                    value={newBrandName && newBrandName != null ? newBrandName : null}
                    onChange={handleNewBrandNameChange}
                    label="Brand name"
                    type="text"
                    style={{ width: '12rem' }}
                    disabled={dialogLoading}
                    error={newBrandNameExists}
                    helperText={newBrandNameExists ? "Already exists for this company" : null}
                  />
                  <FormControl style={{ marginBottom: 15 }}>
                    <InputLabel
                      id="select-label"
                    // style={{ color: GlobalStyles.primaryDark }}
                    >
                      Company
                    </InputLabel>
                    <Select
                      value={newBrandCompanyName ? newBrandCompanyName : null}
                      onChange={handleNewBrandCompanyChange}
                      className={classes.select}
                      disabled={dialogLoading}

                    >
                      {filterCompaniesList &&
                        filterCompaniesList.map((b: any) => (
                          <MenuItem value={b.id} key={String(b.id)}>
                            {b.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl >
                    <InputLabel
                      id="select-label"
                    // style={{ color: GlobalStyles.primaryDark }}
                    >
                      Active
                    </InputLabel>
                    <Select
                      value={newBrandActive ? newBrandActive : null}
                      onChange={handleNewBrandActiveChange}
                      className={classes.select}
                      disabled={dialogLoading}

                    >
                      <MenuItem value={'1'}>Yes</MenuItem>
                      <MenuItem value={'0'}>No</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              }

            </DialogContent>
            {
              !dialogLoading &&
              <DialogActions>
                <Button onClick={handleDialogBoxCancel} color="primary" autoFocus>
                  Cancel
                </Button>
                <Button onClick={() => handleSave()} style={{ color: newBrandNameExists ? GlobalStyles.chipBackground : newBrandName === '' ? GlobalStyles.chipBackground : "#ff1744" }} disabled={newBrandNameExists ? newBrandNameExists : newBrandName === ''} >
                  OK
                </Button>
              </DialogActions>
            }

          </Dialog>
        }
      </div>
    )
  }

  const handleSave = () => {
    setDialogLoading(true)
    let data = {
      item_type: ItemType.BRAND,
      item_details: { 'name': newBrandName, 'company': newBrandCompany, 'active': newBrandActive, 'abbrev': null }
    }
    let payload = data

    addNewBrandOrMaster(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setDialogLoading(false)
        handleDialogBoxCancel()
        tableRef.current && tableRef.current.onQueryChange()
      })
      .catch((error: any) => {
        let msg = 'Failed to add Brand.';

        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
          else if (error.status === 404) {
            msg = ERROR_404
          }
          else if (error.status === 409) {
            setNewBrandNameExists(true)
            msg = ERROR_409
          }

        }
        setDialogLoading(false)
        props.showToast({
          message: msg,
          options: ToastError
        });
      });
  }

  const handleTableRefresh = () => {
    setFromCompanyFilter(null)
    tableRef.current && tableRef.current.onQueryChange()
  }

  const renderTable = () => {
    return (
      <Container maxWidth="md">
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            components={{
              Toolbar: (props) => (
                <>
                  <MTableToolbar {...props} />
                  {filterVisible && (
                    <>
                      <FilterRow
                        {...props}
                        renderAvailableFilters={
                          <AvailableFilters
                            filters={updatedFilters ? updatedFilters : FILTERS}
                            addFilter={addFilter}
                          />
                        }
                        renderSelectedFilters={
                          <SelectedFilters
                            selectedFilters={selectedFilters}
                            removeFilter={removeFilter}
                            updateFilter={updateFilter}
                            businessId={null}
                          />
                        }
                      />
                    </>
                  )}
                </>
              ),
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              { title: 'ID', field: 'id', editable: 'never' },
              {
                title: 'Brand',
                field: 'name',
                editable: 'never',
                render: (rowData: any) => {

                  if (rowData.name != null) {
                    return (<div style={{ minWidth: rowData.name != null ? "200px" : null }}>  {rowData.name}  </div>)
                  }
                  return ' ';
                }
              },
              {
                title: 'Company',
                field: 'company',
                editable: 'never',
                render: (rowData: any) => {

                  if (rowData.company != null) {
                    return (<div style={{ minWidth: rowData.company.name != null ? "350px" : null }}>  {rowData.company.name}  </div>)
                  }
                  return ' ';
                }
              },
              {
                title: 'Images',
                field: 'contains_image',
                type: 'boolean',
                sorting: false,
                render: (rowData: any) => (
                  rowData.contains_image ?

                    <SvgIcon
                      htmlColor={GlobalStyles.green}
                    >
                      <path d={SVG_ICONS.imageMultiple} />
                    </SvgIcon> :
                    <SvgIcon
                      htmlColor={GlobalStyles.red}
                    >
                      <path d={SVG_ICONS.imageOff} />
                    </SvgIcon>
                ),
              },
            ]}
            data={
              query => new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as MasterBrandList[],
                  totalCount: 0,
                  page: query.page
                };
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                setLoading(true);
                let filters = fromCompanyFilter ? fromCompanyFilter : filterString
                getAllMasterBrandList(
                  query.page + 1,
                  query.pageSize,
                  query.search,
                  orderBy,
                  filters
                )
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((data: PaginatedResponse<MasterBrandList>) => {
                    setLoading(false);
                    setErrorStatusVisible(false);
                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                    pageData = {
                      data: data.results,
                      totalCount: data.count,
                      page: query.page
                    };
                    setTableQuery({
                      ...tableQuery,
                      orderBy,
                      totalCount: data.count,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    resolve(pageData);
                    setInitialLoad(false)
                  })
                  .catch((error: any) => {
                    if (error) {
                      if (error.status && error.status === 500) {
                        setEmptyDataSourceMessage(ERROR_500);
                        setErrorStatusVisible(false);
                      } else if ((error.status === 403 || error.status === 401)
                      ) {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(true);
                      } else {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(false);
                      }
                    }
                    setTableQuery({
                      ...tableQuery,
                      totalCount: 0,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    setLoading(false);
                    resolve(pageData);
                  });
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Master Brands'}
                icon={SVG_ICONS.product} />
            }
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => handleTableRefresh(),
              },
              {
                icon: 'edit',
                tooltip: 'Edit',
                hidden: hasUpdatePermission(props.userData.userData.is_superuser, props.userData.userData.user_permissions, permission_model),
                onClick: (event, rowData) => navigateToMasterBrandDetailScreen(rowData),
              },
              {
                icon: 'filter_list',
                tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
                isFreeAction: true,
                iconProps: {
                  color: filterVisible ? 'primary' : 'inherit',
                },
                // hidden: !isSuperAdmin(props),
                onClick: toggleFilterPanel,
              },
              {
                icon: 'add',
                tooltip: 'Add Brand',
                isFreeAction: true,
                onClick: () => setDialogBoxOpen(true),
              },
            ]}
          // editable={tableEditableProp}
          />
        </div>
      </Container>
    );
  }

  return (
    <div>
      {renderTable()}
      {renderAddBrandDialogBox()}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterBrandScreen);