import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Theme, createMuiTheme, MuiThemeProvider, createStyles, makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { CustomTableTitle, ErrorStatusBar, ExportMenu } from '../../../shared/components';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { getLinkedKoronaBusinessSuppliers } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { getLinkBulkProducts } from '../../api';
import { PaginatedResponse } from '../../../shared/models';
import { LinkBulkProducts } from '../../models';
import { getRetailerBusiness, postRetailerSupplierMappedBusiness, addExcludeBusinessCodeLinkedMpi } from '../../api';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue, orange, red } from '@material-ui/core/colors';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import download from 'downloadjs';
import Papa from 'papaparse';
import { FormControl, Input, ListSubheader, LinearProgress, Button, Typography ,Divider } from '@material-ui/core/';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      // minWidth: 150,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    multiSelect: {
      color: GlobalStyles.lightTheme,
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.lightTheme,
      },
      '&:after': {
        borderColor: GlobalStyles.lightTheme,
      },
      maxWidth: '12rem',
      minWidth: '12rem',
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },
    root: {
      color: '#1976d2',
      '&$checked': {
        color: '#1976d2',
      },
    },
    selectedBusinessMessage: {
      color: '#1976d2'
    },
    username: {
      fontSize: '1rem',
      color: '#5f6368',
      width: '100%',
      padding: '0 12px',
      paddingBottom: 24,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    suppliermsg: {
      color: GlobalStyles.primaryColor,
      fontSize: 16
    },
  }),
);


const LinkBulkProductsScreen = (props: any) => {
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [unMappedSupplierCountData, setUnMappedSupplierCountData] = useState({ unmapped_supplier_count: 0 });
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const tableRef = useRef<{ onQueryChange: any; }>();
  const classes = useStyles();
  const [retailer_business, setRetailerBusinessId] = useState('');
  const [selectedBusiness, setSelectedBusiness] = useState<string | number | undefined>(-1);
  const [allRetailerBusinessList, setRetailerBusiness] = useState([]);
  const [retailerBusinessRecords, setRetailerBusinessRecords] = useState([]);
  const [retailerSelected, setBooleanRetailerSelected] = useState<boolean>(false);
  const [selectedData, setSelectedRows] = useState<any>([]);
  const [isExporting, setExporting] = useState(false);
  const [formatedData, setFormatedData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mappedResponse, setMappedResponse] = useState<any>([]);
  const [showMappedMessage, setShowMappedMessage] = useState(false);
  const [mergedDuplicateRecords, setMergedDuplicateRecords] = useState([]);
  const [retailerProductSelected, setRetailerProductSelected] = useState<any>([]);
  const [excludedIdentifier, setExcludedIdentifier] = useState(false);
  


  useEffect(() => {
    getRetailerBusiness()
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {

        setRetailerBusiness(jsonData);
      })
      .catch((error: any) => {
        console.log('error fetching retailer business', error);
      });
  }, []);//empty [] means run this only once to make api call

  let retailer_business_id

  useEffect(() => {
    retailer_business_id = retailer_business;

    refreshUnMappedSuppliersCount();
    // console.log("USER DATA", props.userData)

  }, [retailer_business, props.userData.selectedBusiness, excludedIdentifier]);


  // useEffect(() => {
  //   //console.log(retailerBusinessRecords)
  //   // if (isLoading){
  //   setLoading(false)
  //   // } 

    
  //   // MergeDuplicateRecordsApi()
  //   //console.log("formated data", formatedData)
  // }, [retailerBusinessRecords])


  // useEffect(() => {
  //   //setSelectedDataFunction(selectedData)
  //   //console.log("selected data",selectedData)
  //   //checkSelectedDataExists()
  //   //let duplicate_records = retailerBusinessRecords.filter(data => data.id in selectedData);
  //   console.log("records in selected", selectedData);

  // }, [selectedData])



  useEffect(() => {

    let retailer_product = retailerProductSelected
    let searched_row_found = mergedDuplicateRecords.find(element => element.retailer_items.find(product => product.retailer_item_code === retailer_product.retailer_item_code))
    if (searched_row_found) {
      searched_row_found.retailer_items.forEach(product => product.selected = false)
      let product = searched_row_found.retailer_items.find(element => element.retailer_item_code === retailer_product.retailer_item_code)

      if (product.selected === false) {
        product.selected = true

      }
    }

  }, [retailerProductSelected])


  const handleRetailBusinessSelectionChange = (event: any) => {

    setRetailerBusinessId(event.target.value);
    setBooleanRetailerSelected(true);
  };

  const refreshUnMappedSuppliersCount = () => {

    if (props.userData && props.userData.selectedBusiness && retailerSelected == true) {
      const sb = props.userData.selectedBusiness;
      if (sb.is_supplier) {
        // console.log("selected business", sb)
        // console.log("selected-retailer", retailer_business)
        setLoading(true)
        getLinkBulkProducts(sb.business_id, retailer_business,)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            setRetailerBusinessRecords(jsonData.results);
            setMergedDuplicateRecords([])
            MergeDuplicateRecords(jsonData.results)
            setLoading(false)

          })
          .catch((error: any) => {
            console.log('error fetching unmapped supplier count', error);
            setLoading(false)
          });
      }
    }

  };

  const MergeDuplicateRecords = (data : any)=>{
    if (data.length > 0) {
      const MergedDuplicates = data.reduce((acc, current) => {
        const x = acc.find(item =>
          item.supplier_id === current.supplier_id &&
          item.supplier_name === current.supplier_name &&
          item.supplier_item_uuid === current.supplier_item_uuid &&
          item.supplier_item_name === current.supplier_item_name &&
          item.mp_id === current.mp_id
          // item.master_product[0].id === current.master_product[0].id
        );
        if (!x) {
          const newCurr = {
            supplier_id: current.supplier_id,
            supplier_name: current.supplier_name,
            supplier_item_uuid: current.supplier_item_uuid,
            supplier_item_name: current.supplier_item_name,
            mp_id: current.mp_id,
            master_product: current.master_product[0],
            retailer_items: [{
              retailer_id: current.retailer_id,
              retailer_item_uuid: current.retailer_item_uuid,
              retailer_item_name: current.retailer_item_name,
              retailer_item_code: current.retailer_item_code,
              selected: false
            }]

          }
          return acc.concat([newCurr]);
        } else {
          x.retailer_items.push({
            retailer_id: current.retailer_id,
            retailer_item_uuid: current.retailer_item_uuid,
            retailer_item_name: current.retailer_item_name,
            retailer_item_code: current.retailer_item_code,
            selected: false
          })
          return acc
        }
      }, [])
      // console.log("MERGED-DUPLICATES", MergedDuplicates)
      setMergedDuplicateRecords(MergedDuplicates)
    }

  }

  


  // const MergeDuplicateRecords = () => {
  //   let merged_duplicate_product_lists = []
  //   for (let i = 0; i < retailerBusinessRecords.length; i++) {
  //     let existing_product = merged_duplicate_product_lists.find(element => element.supplier_item_uuid === retailerBusinessRecords[i].supplier_item_uuid)

  //     if (existing_product) {
  //       let retailer_product = {
  //         retailer_name: retailerBusinessRecords[i].retailer_item_name,
  //         retailer_item_uuid : retailerBusinessRecords[i].retailer_item_uuid,
  //         retailer_item_code : retailerBusinessRecords[i].retailer_item_code,
  //       }
  //       existing_product.retailer_products.push({
  //         retailer_product: retailer_product,
  //         matched_identifier: retailerBusinessRecords[i].mp_identifier,
  //         //business_product: retailerBusinessRecords[i].business_product,
  //         selected: false
  //       })

  //     }

  //     else {
  //       let retailer_product = {
  //         retailer_name: retailerBusinessRecords[i].retailer_item_name,
  //         retailer_item_uuid : retailerBusinessRecords[i].retailer_item_uuid,
  //         retailer_item_code : retailerBusinessRecords[i].retailer_item_code,
  //       }
  //       const obj = {
  //         supplier_item_uuid: retailerBusinessRecords[i].supplier_item_uuid,
  //         retailer_products: [{ 
  //           retailer_product: retailer_product, 
  //           matched_identifier: retailerBusinessRecords[i].mp_identifier, 
  //           selected: false 
  //         }],
  //         master_product: retailerBusinessRecords[i].master_product[0],
  //         business_product: retailerBusinessRecords[i].business_product[0]
  //       }
  //       merged_duplicate_product_lists.push(obj)
  //     }

  //   }
  //   console.log("MERGEDDDDDD", merged_duplicate_product_lists)
  //   setMergedDuplicateRecords(merged_duplicate_product_lists)
  //   //console.log("merged duplicate records", merged_duplicate_product_lists)
    
  // }



  const handleRetailerSelectionChange = (event: any) => {
    setRetailerProductSelected(event.target.value)
  }



  // const convertNestedDataToStructuredArray = () => {
  //   let data = retailerBusinessRecords
  //   let array_of_formated_data = []
  //   data.map(item => {
  //     let new_obj = {}
  //     new_obj['Supplier Product'] = item.business_product.name
  //     new_obj['Master Product'] = item.master_product.name
  //     new_obj['Retailer Product'] = item.retailer_product.name
  //     new_obj['Matched Identifier'] = item.matched_identifier.identifier
  //     array_of_formated_data.push(new_obj)
  //   })
  //   setFormatedData(array_of_formated_data)
  // }

  const setSelectedDataFunction = (data) => {
    setSelectedRows(data)
  }

  const openSupplierModalAddModel = () => {

    let selectedProducts = []
    for (let i = 0; i < selectedData.length; i++) {
      let obj = {}
      obj['master_product'] = selectedData[i].mp_id;
      if (selectedData[i].retailer_items.length > 1) {
        let selected_retailer_product = selectedData[i].retailer_items.find(product => product.selected === true)
        if (selected_retailer_product) {
          obj['rtl_business_id'] = selected_retailer_product.retailer_id;
          obj['rtl_product_uuid'] = selected_retailer_product.retailer_item_uuid;
        }
        else {
          alert("You have selected a record where retailer product is not selected from dropdown, Please select to proceed further")
        }
      }
      else if (selectedData[i].retailer_items.length === 1) {
        obj['rtl_business_id'] = selectedData[i].retailer_items[0].retailer_id;
        obj['rtl_product_uuid'] = selectedData[i].retailer_items[0].retailer_item_uuid;
      }

      selectedProducts.push(obj);
    }
    console.log("selected", selectedProducts);
    let length_of_array = selectedProducts.length;

    const payload ={ mp_link_records : selectedProducts }

    postRetailerSupplierMappedBusiness(payload)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setMappedResponse(jsonData)
        renderMessage()
        
      })
      .catch((error: any) => {
        let msg=''
        console.log(error)
        if(error){
          if (error.status === 409){
            msg='This Product already linked.'
          }
        }
        props.showToast({
          message: msg,
          options: ToastError
        });

      })
    refreshUnMappedSuppliersCount()
  }


  const exportCSV = () => {
    setExporting(true)
    if (props.userData && props.userData.selectedBusiness && retailerSelected) {
      const sb = props.userData.selectedBusiness;
      setLoading(true)
      const shouldExport = true;
      getLinkBulkProducts(sb.business_id, retailer_business,shouldExport)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
            download(data, 'MasterLinkBulkProducts.csv', 'text/csv');
            setExporting(false);
            setLoading(false);
        })
        .catch((error: any) => {
            setExporting(false);
            setLoading(false);
            if (error) {
                if (error.status && error.status === 500) {
                    setEmptyDataSourceMessage(ERROR_MSG);
                    setErrorStatusVisible(false);
                } else if (error.status === 403 || error.status === 401) {
                    setEmptyDataSourceMessage(ERROR_MSG);
                    setErrorStatusVisible(true);
                } else {
                    setEmptyDataSourceMessage(ERROR_MSG);
                    setErrorStatusVisible(false);
                }
            }
        });
    }
  }

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: '#1976d2',
      },
    },

  });

  const renderMessage = () => {
    setShowMappedMessage(true);
  }



  const handleExcludeBusinessCodeLinkedMpi = () => {
    setLoading(true)
    let identifiers = [];
    let records = selectedData.map(i => i);
    // console.log(records)
    for (let i = 0; i < records.length; i++) {
      let selectedRetailerProduct = records[i].retailer_items ? records[i].retailer_items.length === 1 ? records[i].retailer_items[0] : records[i].retailer_items.find(r => r.selected) : null 
      console.log("selectedRetailerProduct", selectedRetailerProduct)

      let obj = {};
      obj['master_product'] = records[i]['master_product']
      obj['identifier'] = selectedRetailerProduct['retailer_item_code']
      obj['retl_id'] = selectedRetailerProduct['retailer_id']
      identifiers.push(obj);
    }

    // console.log("exclude-list",identifiers)
    let payload = { identifiers: identifiers }
    // console.log("payload", payload)
    addExcludeBusinessCodeLinkedMpi(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        // console.log("JSONDATA", jsonData)
        setLoading(false);
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        props.showToast({
          message: 'Retailer Code(s) has been excluded!',
          options: ToastSuccess,
        });
        setExcludedIdentifier(!excludedIdentifier)
        setSelectedRows([])

      })
      .catch((error: any) => {
        setLoading(false);
        let msg = 'Failed to exclude code(s).';
        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg, options: ToastError });
        setExcludedIdentifier(!excludedIdentifier)
        setSelectedRows([])
      });


  }


  const renderTable = () => {
    return (
      <div>
        <div className="table-wrapper">
          <MaterialTable
            options={
              {
                ...tableOptions(tableQuery),
                selection: true,
                //exportButton: true
                search: false,
                selectionProps: {
                  color: 'secondary'
                },
                showTextRowsSelected: false,
              }
            }
            tableRef={tableRef}
            isLoading={isLoading}
            columns={[
              {
                title: "Supplier Product",
                field: "supplier_item_name",
                render: rowData => {
                  if (rowData && rowData.supplier_item_name) {
                    return <div> {rowData.supplier_item_name}</div>
                  }
                }
              },
              {
                title: "Master Product",
                field: "master_product",
                render: rowData => {
                  if (rowData && rowData.master_product) {
                    return <div> {rowData.master_product.name}</div>
                  }
                }
              },
              {
                title: "Retailer Product",
                field: "retailer_items",
                render: rowData => {

                  if (rowData && rowData.retailer_items.length == 1) {

                    return <div> {rowData.retailer_items.map(item => item.retailer_item_name)}</div>
                  }
                  else {
                    return <Select
                      id="select-retailer-product"
                      className={classes.select}
                      required={true}
                      onChange={handleRetailerSelectionChange}  
                    >
                      {
                        rowData.retailer_items.map(item => (
                          <MenuItem
                            value={item}
                            key={item.retailer_item_code}
                          >
                            {item.retailer_item_name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  }
                }
              },
              {
                title: "Matched Identifier",
                field: "retailer_items",
                render: rowData => {
                  if (rowData && rowData.retailer_items) {
                    if (rowData && rowData.retailer_items.length == 1) {
                      return <div> {rowData.retailer_items.map(item => item.retailer_item_code)}</div>
                    }
                    else {
                      let selected_retailer_product = rowData.retailer_items.find(product => product.selected === true)
                      if (selected_retailer_product) {
                        return <div>
                          {selected_retailer_product.retailer_item_code}
                        </div>
                      }
                      // return  null
                    }
                  }
                }
              }
            ]}

            data={mergedDuplicateRecords}

            title={
              <CustomTableTitle
                history={props.history}
                title={'Link Supplier To Retailer Products - using Master'}
                icon={SVG_ICONS.linkPlus} 
              />
            }
            onSelectionChange={(rows) =>
              setSelectedRows(rows)
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting,
              }
            ]}
          />
          <ExportMenu
            anchorEl={exportBtnAnchorEl}
            handleClose={handleExportMenuClose} />
        </div>
      </div>
    )
  }


  return (
    <div>

      {
        props.userData &&
          props.userData.selectedBusiness &&
          props.userData.selectedBusiness.is_supplier === true ?
          <div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexWrap: 'wrap', padding: 2 }}>
              <div style={{ alignSelf: 'flex-start', display: 'flex', alignItems: 'center' }}>
                <FormControl className={classes.formControl}>
                  <InputLabel
                    id="select-label"
                    style={{ color: GlobalStyles.primaryDark }}
                  >
                    Select a Business To Map
                  </InputLabel>
                  <Select
                    id="select-retailer-business"
                    value={retailer_business}
                    onChange={handleRetailBusinessSelectionChange}
                    className={classes.select}
                    style={{ minWidth: "210px" }}
                    inputProps={{
                      name: 'business',
                      id: 'rtl_business_id',
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {
                      allRetailerBusinessList.map(item => (
                        <MenuItem
                          value={item.id}
                          key={item.id}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </div>
              <div style={{ alignSelf: 'center' }}>
                {showMappedMessage &&
                  <Typography>
                    {`${mappedResponse.length} Record(s) Mapped Successfuly`}
                  </Typography>
                }
              </div>

              <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
                {retailerSelected === true ?
                  <>
                    <Button
                      variant="contained"
                      onClick={handleExcludeBusinessCodeLinkedMpi}
                      color="primary"
                      disabled={selectedData.length === 0}
                      style={{ marginRight:'10px' }}
                      startIcon={<RemoveCircleIcon />}
                    >
                      Exclude
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={selectedData.length === 0}
                      onClick={() => openSupplierModalAddModel()}>
                      Map Now
                    </Button>
                  </>
                  : null}
              </div>
            </div>
            <Divider className={classes.divider} />
            {retailerSelected ? renderTable(): null}

          </div> : 
          <Typography className={classes.suppliermsg}>
            Selected Business is not a supplier, Please select a Supplier to map the products
          </Typography>
      }

    </div>
  )
}


const mapStateToProps = (state: any) => {
  return {
    businessList: state.sharedModuleData.businessList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(LinkBulkProductsScreen)