import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { SupplierProductList } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getSupplierProductList } from '../../api';
import { getSuppliersByBusiness } from '../../../products/api/Api';
import { Supplier } from '../../../products/models';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem'
        },
        progress: {
            margin: theme.spacing(2),
        },
        toolbar: {
            width: '80%',
        },
        rootSelectBtn: {
            minWidth: '1rem',
        },
        disableHover: {
            "&:hover": { backgroundColor: 'initial' }
        }
    }),
);


const SupplierProductListReportScreen = (props: any) => {
    const classes = useStyles({});
    const tableRef = useRef<{ onQueryChange: any; }>();
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);
    const [isExporting, setExporting] = useState(false);
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null as Supplier);


    useEffect(() => {
        if (!isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
        fetchSuppliersForBusiness();
    }, [props.userData.selectedBusiness]);

    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
    }, [selectedSupplier]);

    const fetchSuppliersForBusiness = () => {
        const sb = props.userData.selectedBusiness;
        getSuppliersByBusiness(sb.business_id, null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<Supplier>) => {
                console.log("SUPPLIERS",data.results)
                setSuppliers(data.results);
            })
            .catch((error: any) => {
            });
    };

    const getSupplierFilter = () => {
        return selectedSupplier ? `&supplier_uuid=${selectedSupplier.uuid}` : null;
    };

    const exportCSV = () => {
        if (props.userData && props.userData.selectedBusiness) {
            setExporting(true);
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            const filters = getSupplierFilter();
            getSupplierProductList(
                sb.business_id,
                null,
                null,
                tableQuery.search,
                sb.orderBy,
                filters,
                shouldExport
            )
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then((res) => res.blob())
                .then((data) => {
                    download(data, 'SupplierProductsReport.csv', 'text/csv');
                    setExporting(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if (error.status === 403 || error.status === 401) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                        } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        }
                    }
                });
        }
    };

    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = (shouldExport: boolean) => {
        if (shouldExport) {
            exportCSV();
        }
        setExportBtnAnchorEl(null);
    };

    const handleSupplierSelectionChange = (event: React.ChangeEvent<{ value: string; }>, value: Supplier) => {
        console.log("supplierevent",event)
        setSelectedSupplier(value);
    };

    const renderTableToolbarComponent = (props) => (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%'
        }}>
            <MTableToolbar {...props} classes={{ root: classes.toolbar }} />
            <FormControl className={classes.formControl}
                style={{
                    marginRight: 16,
                    marginBottom: 24,
                    marginTop: 8

                }}>
                <Autocomplete
                    id="highlights-demo"
                    options={suppliers}
                    getOptionLabel={(s) => s ? s.name : ''}
                    size={'small'}
                    getOptionSelected={(option: Supplier, value: string | Supplier) => {
                        if (typeof value !== 'string') {
                            return value && option ? option.uuid === value.uuid : false;
                        }
                        return false;
                    }}
                    value={selectedSupplier ? selectedSupplier : ''}
                    onChange={handleSupplierSelectionChange}
                    renderInput={(params) => (
                        <TextField {...params} label="Supplier" variant="outlined" margin="normal" />
                    )}
                    renderOption={(option, { inputValue }) => {
                        const matches = match(option.name, inputValue);
                        const parts = parse(option.name, matches);
                        return (
                            <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        );
                    }}
                />
            </FormControl>
            <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

        </div>
    );

    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <ExportStatusBar isVisible={isExporting} />

            <div style={{ maxWidth: '100%' }}>
                <div className="table-wrapper">
                    <MaterialTable
                        options={{
                            ...tableOptions(tableQuery),
                            thirdSortClick: false,
                            draggable: false,
                            sorting: true
                        }}
                        tableRef={tableRef}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataSourceMessage
                            }
                        }}
                        columns={[
                            { title: 'Product Number', field: 'product_number' },
                            { title: 'Product Name', field: 'product_name', defaultSort: 'asc' },
                            { title: 'Order Code', field: 'reorder_code', },
                            { title: 'Container Amount', field: 'case_qty', },
                            { title: 'Description', field: 'case_desc', },
                            { title: 'Item Price', field: 'product_cost', },
                        ]}
                        components={{
                            Toolbar: renderTableToolbarComponent
                        }}
                        data={query =>
                            new Promise((resolve, reject) => {
                                if (!isLoading) {
                                    const sb = props.userData.selectedBusiness;
                                    if (sb && sb.business_id) {
                                        replaceHistory(query, props.history);
                                        let pageData = {
                                            data: [] as SupplierProductList[],
                                            totalCount: 0,
                                            page: query.page,
                                        };
                                        const filters = getSupplierFilter();

                                        let orderBy = '';
                                        if (query.orderBy) {
                                            orderBy = query.orderBy.field as string;
                                            orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                        }
                                        if (!filters) {
                                            resolve(pageData);
                                        } else {
                                            getSupplierProductList(
                                                sb.business_id,
                                                query.page + 1,
                                                query.pageSize,
                                                query.search,
                                                orderBy,
                                                filters,
                                                false
                                            )
                                                .catch(handleError) // handle network issues
                                                .then(checkStatus)
                                                .then(parseJSON)
                                                .then((data: PaginatedResponse<SupplierProductList>) => {
                                                    setErrorStatusVisible(false);
                                                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                                    pageData = {
                                                        data: data.results,
                                                        totalCount: data.count,
                                                        page: query.page
                                                    };
                                                    setTableQuery({
                                                        ...tableQuery,
                                                        orderBy,
                                                        totalCount: data.count,
                                                        page: query.page,
                                                        pageSize: query.pageSize
                                                    });
                                                    resolve(pageData);
                                                })
                                                .catch((error: any) => {
                                                    if (error) {
                                                        if (error.status && error.status === 500) {
                                                            setEmptyDataSourceMessage(ERROR_500);
                                                            setErrorStatusVisible(false);
                                                        } else if ((error.status === 403 || error.status === 401)
                                                        ) {
                                                            setEmptyDataSourceMessage(ERROR_MSG);
                                                            setErrorStatusVisible(true);
                                                        } else {
                                                            setEmptyDataSourceMessage(ERROR_MSG);
                                                            setErrorStatusVisible(false);
                                                        }
                                                    }
                                                    setTableQuery({
                                                        ...tableQuery,
                                                        totalCount: 0,
                                                        page: query.page,
                                                        pageSize: query.pageSize
                                                    });
                                                    resolve(pageData);
                                                });
                                        }
                                    }
                                }
                            })
                        }
                        title={
                            <CustomTableTitle
                                history={props.history}
                                title={'Supplier Products'}
                                icon={SVG_ICONS.product} />
                        }
                        actions={[
                            {
                                icon: 'save_alt',
                                tooltip: 'Export',
                                isFreeAction: true,
                                onClick: handleExportClick,
                                disabled: isExporting,
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            },
                        ]}

                    />
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(SupplierProductListReportScreen);
