import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Divider, Tooltip, Typography, IconButton, SvgIcon } from '@material-ui/core';
import MaterialTable from 'material-table';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { getInvoicePreview, getInvoicesByPage } from '../../api';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, formatDate } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery, } from '../../../shared/lib/TableQueryParams';
import { PaginatedResponse } from '../../../shared/models';
import { Invoice } from '../../models';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCenter: {
      padding: theme.spacing(1, 1),
      textAlign: 'center'
    },
    subDivider: {
      margin: theme.spacing(1, 0),
    },
    pageTitle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
  }),
);



const InvoiceListScreen = (props: any) => {
  // console.log(props);
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));

  const navigateToInvoiceDetailScreen = (rowData: any) => {
    const invoiceDetailUrl = '/web/invoices/' + rowData.id;
    props.history.push(invoiceDetailUrl, rowData);
  };

  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <div className="table-wrapper">
        <MaterialTable
          options={{
            debounceInterval: 500,
            ...tableOptions(tableQuery),
            emptyRowsWhenPaging: false,
            thirdSortClick: false,
            draggable: false,
            sorting: true
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage
            }
          }}
          onRowClick={(event, rowData) => navigateToInvoiceDetailScreen(rowData)}
          columns={[
            {
              title: 'Order #',
              field: 'number'
            },
            { title: 'Description', field: 'description' },
            {
              title: 'Due By',
              field: 'due_date',
              render: rowData => {
                return <div>{formatDate(rowData.due_date)}</div>;
              }
            },
            {
              title: 'Created On',
              field: 'created_date',
              render: rowData => {
                return <div>{formatDate(rowData.created_date)}</div>;
              },
            },
            {
              title: 'Last Modified',
              field: 'modified_date',
              render: rowData => {
                return <div>{formatDate(rowData.modified_date)}</div>;
              },
            },
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              const { userData } = props.userData.userData;
              const business_id = userData.business.id;
              let pageData = {
                data: [] as Invoice[],
                totalCount: 0,
                page: query.page
              };
              let orderBy = '';
              if (query.orderBy) {
                orderBy = query.orderBy.field as string;
                orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
              }
              getInvoicesByPage(query.page + 1, query.pageSize, query.search, business_id, orderBy)
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then((data: PaginatedResponse<Invoice>) => {
                  setErrorStatusVisible(false);
                  setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                  pageData = {
                    data: data.results,
                    totalCount: data.count,
                    page: query.page
                  };
                  setTableQuery({
                    ...tableQuery,
                    orderBy,
                    totalCount: data.count,
                    page: query.page,
                    pageSize: query.pageSize,
                  });
                  resolve(pageData);
                })
                .catch((error: any) => {
                  if (error) {
                    if (error.status && error.status === 500) {
                      setEmptyDataSourceMessage(ERROR_500);
                      setErrorStatusVisible(false);
                    } else if ((error.status === 403 || error.status === 401)
                    ) {
                      setEmptyDataSourceMessage(ERROR_MSG);
                      setErrorStatusVisible(true);
                    } else {
                      setEmptyDataSourceMessage(ERROR_MSG);
                      setErrorStatusVisible(false);
                    }
                  }
                  setTableQuery({
                    ...tableQuery,
                    totalCount: 0,
                    page: query.page,
                    pageSize: query.pageSize,
                  });
                  resolve(pageData);
                });
            })
          }
          title={
            <CustomTableTitle
              history={props.history}
              title={'Invoices'}
              icon={SVG_ICONS.invoice} />
          }
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(InvoiceListScreen);