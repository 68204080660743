import {
    GET_INVENTORIES, GET_INVENTORIES_SUCCESS, GET_INVENTORIES_FAILURE,
    GET_INVENTORY_DETAIL, GET_INVENTORY_DETAIL_SUCCESS, GET_INVENTORY_DETAIL_FAILURE,
    SET_SELECTED_INVENTORY, CLEAR_SELECTED_INVENTORY,
    GET_INVENTORY_SUMMARY, GET_INVENTORY_SUMMARY_FAILURE, GET_INVENTORY_SUMMARY_SUCCESS,
} from './actionTypes';
import { API_URLS } from '../../api';
import composeRequest, { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import { Inventory, InventoryReceipt } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';

import GlobalStyles from '../../../../styles/GlobalStyles';
import { ERROR_CODES } from 'components/src/modules/shared/models';
import { ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';

export function getInventoriesFromAPI(selectedLocation: Location,
    reset?: boolean, page?: number, pageSize?: number) {
    return (dispatch: any) => {
        dispatch(getInventories());
        let URL = API_URLS.inventoriesUrl;
        const pg = pageSize || 20;
        const p = page || 1;
        URL = URL + `?org_unit=${selectedLocation.korona_org_unit}&page=${p}&page_size=${pg}`;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<Inventory>) => {
                dispatch(getInventoriesSuccess(data, Boolean(reset)));
            })
            .catch((error: any) => {
                dispatch(getInventoriesFailure(error));
            });
    };
}

export function getInventoryDetailById(uuid: string) {
    return (dispatch: any) => {
        dispatch(getInventoryDetail());
        const URL = API_URLS.inventoryDetailUrl + `${uuid}/`;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: Inventory) => {
                dispatch(getInventoryDetailSuccess(data));
            })
            .catch((error: any) => {
                dispatch(getInventoryDetailFailure(error));
            });
    };
}

export interface InventorySummaryResponse {
    // lastCreatedOn: string
    openInventoryListCount: number;
    totalInventoryCounts: number;
    totalInventoryCountList: number;
}

export function getInventoryDataSummary(businessId: number | string) {
    return (dispatch: any) => {
        dispatch(getInventorySummary());
        const URL = API_URLS.inventorySummaryDataUrl + `?business_id=${businessId}`;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: InventorySummaryResponse) => {
                dispatch(getInventorySummarySuccess(data));
            })
            .catch((error: any) => {
                dispatch(getInventorySummaryFailure(error));
            });
    };
}

function getInventoryDetail() {
    return {
        type: GET_INVENTORY_DETAIL
    };
}

function getInventoryDetailSuccess(data: Inventory) {
    return {
        type: GET_INVENTORY_DETAIL_SUCCESS,
        data
    };
}

function getInventoryDetailFailure(err: any) {
    return {
        type: GET_INVENTORY_DETAIL_FAILURE,
        err
    };
}

function getInventories() {
    return {
        type: GET_INVENTORIES
    };
}

function getInventoriesSuccess(data: PaginatedResponse<Inventory>, resetList: boolean) {
    return {
        type: GET_INVENTORIES_SUCCESS,
        resetList: resetList,
        data
    };
}

function getInventoriesFailure(err: any) {
    return {
        type: GET_INVENTORIES_FAILURE,
        err
    };
}

export const saveSelectedInventory = (data: Inventory) => {
    return {
        type: SET_SELECTED_INVENTORY,
        data
    };
};

export const clearSelectedInventory = () => {
    return {
        type: CLEAR_SELECTED_INVENTORY
    };
};

const getInventorySummary = () => {
    return {
        type: GET_INVENTORY_SUMMARY
    };
};

const getInventorySummarySuccess = (data: InventorySummaryResponse) => {
    return {
        type: GET_INVENTORY_SUMMARY_SUCCESS,
        data
    };
};

const getInventorySummaryFailure = (err: any) => {
    return {
        type: GET_INVENTORY_SUMMARY_FAILURE,
        err
    };
};