import { Product, Supplier } from '../../products/models';
import { BusinessSupplier } from '../../master-data/models';
import { Business, Location } from '../../shared/models';
import ReactDataSheet from 'react-datasheet';

export class StockReceiptItem {
    order?: string; // stock order id
    receipt: string;
    business: number;

    name: string;

    uuid: string;
    deleted: boolean;
    revision: number;
    receivedGoods: number;
    expectedGoods: number;
    orderedGoods?: number;
    itemPrice: number;
    supplierItemNumber: string | null;
    articleNumber: string | null;
    code: string | null;
    color: string | null;
    size: string | null;
    article: string | null;
    clearOrderedGoods: boolean;
    itemIndex: number;
    shelfLife: any;
    confirmed?: boolean;
    isRemovable?: boolean;
    selectedSupplierId?: string;
    product?: Product;
    // localReceipt?: string;
}

export class StockReceipt {

    items: StockReceiptItem[];
    items_count: number;
    location: number;

    uuid: string;
    deleted: boolean;
    number: number;
    revision: number;
    cashier: any;
    dispatchNotification: any;
    arrival: string;
    orderNumber: string;
    transportDocumentType: any;
    transportDocumentNumber: any;
    pos: any;
    createTime: string;
    bookingTime: string;
    bookedBy: any;
    createdBy: string;
    customer: any;
    source: any;
    buyerInformation: any;
    deliveryAddressInformation: any;
    finalRecipientInformation: any;
    invoiceRecipientInformation: any;
    supplierInformation: any;
    transportServiceProviderInformation: any;
    description: string;
    comment: string;
    positions: number;
    order: any;
    supplier: BusinessSupplier | string;
    target: string | { name: string; number: string, uuid: string; };
    business: Business;
    selected?: boolean;
}

export interface GridElement extends ReactDataSheet.Cell<GridElement, any> {
  value: number | string | null;
  isSelected: boolean;
  type?: 'checkbox' | 'data' | 'header';
  isIgnored: boolean;
}

export class MyReactDataSheet extends ReactDataSheet<GridElement, any> { }

export const EXPECTED_HEADERS = {
  SERIAL_NUMBER: 'Serial Number',
  RE_ORDER_CODE: 'Re-Order Code',
  SUPPLIER_PRODUCT_NAME: 'Description',
  CASE_QUANTITY: 'Case Quantity',
  CASES_DELIVERED: 'Cases Delivered',
  COST_PER_CASE: 'Cost Per Case',
  UNITS_DELIVERED: 'Units Delivered',
  COST_PER_UNIT: 'Cost Per Unit',
  LINE_TOTAL: 'Line Total',
  DISCOUNT: 'Discount',
  TX: 'TX',
  UPC_CODE:'UPC CODE',
  calculatedLineTotal: 'Calculated Line Total',
  PRODUCT_SKU : 'Product SKU'
};

export const srHeaders = [
  '',
  EXPECTED_HEADERS.CASE_QUANTITY,
  EXPECTED_HEADERS.CASES_DELIVERED,
  EXPECTED_HEADERS.COST_PER_CASE,
  EXPECTED_HEADERS.COST_PER_UNIT,
  EXPECTED_HEADERS.DISCOUNT,
  EXPECTED_HEADERS.LINE_TOTAL,
  EXPECTED_HEADERS.RE_ORDER_CODE,
  EXPECTED_HEADERS.SERIAL_NUMBER,
  EXPECTED_HEADERS.SUPPLIER_PRODUCT_NAME,
  EXPECTED_HEADERS.TX,
  EXPECTED_HEADERS.UNITS_DELIVERED,
  EXPECTED_HEADERS.UPC_CODE,
  
];

export const SRHeaders = [
  '',
  EXPECTED_HEADERS.PRODUCT_SKU,
  EXPECTED_HEADERS.UNITS_DELIVERED,
  EXPECTED_HEADERS.SUPPLIER_PRODUCT_NAME,
  EXPECTED_HEADERS.COST_PER_UNIT,
  EXPECTED_HEADERS.LINE_TOTAL
]


export const initSpecificHeaders = {
  caseQuantity: false,
  casesDelivered: false,
  unitsDelivered: false,
  reOrderCode: false,
  serialNumber: false
};



export const EXPECTED_HEADERS_SHORT_NAME = {
  SERIAL_NUMBER: 'serialNumber',
  RE_ORDER_CODE: 'reOrderCode',
  SUPPLIER_PRODUCT_NAME: 'supplierProductName',
  CASE_QUANTITY: 'caseQuantity',
  CASES_DELIVERED: 'casesDelivered',
  COST_PER_CASE: 'costPerCase',
  UNITS_DELIVERED: 'unitsDelivered',
  COST_PER_UNIT: 'costPerUnit',
  LINE_TOTAL: 'lineTotal',
  DISCOUNT: 'discount',
  TX: 'tx',
  calculatedLineTotal: 'calculatedLineTotal',
  itemIndex: 'itemIndex',
  UPC_CODE:'upcCode'
};


export const initialStockOrderSummary = {
  itemCount: 0,
  unMappedProducts: 0,
  expectedTotal: '0.00',
  calculatedTotal: '0.00'
};