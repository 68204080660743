import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Paper, Typography,
  MenuItem, CircularProgress, TextField, Checkbox,
  FormControlLabel, FormHelperText, ListItemIcon, ListItemText,
  FormControl, Select, InputLabel, SvgIcon, Switch
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Papa from 'papaparse';
import 'react-datasheet/lib/react-datasheet.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.css';
import { PaginatedResponse } from '../../../../shared/models';
import { BusinessSupplierLinkRecord } from '../../../../master-data/models';
import { getLinkedKoronaBusinessSuppliers } from '../../../../master-data/api';
import { checkStatus, parseJSON, handleError } from '../../../../shared/api/core';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../../shared/lib/Localization';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import { useStyles } from './styles';
import { GridElement, stockOrderHeaders } from '../../../models';
import moment from 'moment';
import { MasterDataRouterPaths } from '../../../../page/Routes/RouterPaths';




const StyledMenu = ((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const FileImport = (props: any) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //console.log("props.history",props.history);
  const {
    onFileSelectChangeHandler,
    linkedSuppliers,
    uploadPDF, parseCSV,
    handleLinkedSupplierChange,
    selectedFile, selectedFileError,
    selectedSupplier, supplierError,
    uiLoading, setUILoading,
    stepOneSuccess,
    selectedOrgUnit,
    setFileExtension,
    setTextractModel,
    textractModel
  } = props;

  const handleUploadClick = (event: any) => {
    // console.log("handle upload click", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleUploadMenuClose = (type: string) => {
    setAnchorEl(null);
    if (type === 'pdf') {
      const el = document.getElementById('pdf-file-input');
      el && el.click();
    } else if (type === 'tsv') {
      const el = document.getElementById('tsv-file-input');
      el && el.click();
    }
  };

  useEffect(() => {
    if (props.selectedFile) {
      // console.log("props.selectedFile",props.selectedFile)
      const fileExtension = props.selectedFile.name.split('.').pop();
      const validExtensions = ['csv', 'tsv', 'txt'];
      if (fileExtension.toLowerCase() === 'pdf') {
        uploadPDF();
      } else if (validExtensions.includes(fileExtension)) {
        props.setIsFileExtensionPDF(false)
        parseCSV();
      }
    }
  }, [props.selectedFile, props.selectedSupplier]);

  // const handleExpressButtonClick = () => {
  //   if (props.expressMode === true) {
  //     props.setExpressMode(false)
  //   }
  //   else {
  //     props.setExpressMode(true)

  //   }
  // };

  const handleSelectedModelChange = (e: any) => {
    console.log("model value - ", e.target.value);
    // setSelectedTags([...value]);
    setTextractModel(e.target.value);
  }

  const renderModelOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.id}>
          {o.label}
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        {
          uiLoading &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8 }}>
          {
            !selectedFile && selectedFileError.hasError &&
            <div className={classes.textLabel}>
              <Typography variant="body1" className={classes.errorText}>{selectedFileError.message}</Typography>
            </div>
          }
          <Container maxWidth="sm">
            <div className={classes.textLabel}>
              <Typography variant="h5" className={classes.sectionTitle} style={{textAlign:'center'}}>Let's Get Started!</Typography>
              {/* <div className={classes.formRow}>
                <div style={{ alignSelf: 'center', marginRight: 25, marginTop: 8 }}>
                  <FormControlLabel
                    control={<Switch checked={props.expressMode} onChange={handleExpressButtonClick} name="checkedA" />}
                    label="Express Mode"
                  />
                </div>
              </div> */}
              <div className={classes.formRow} style={{ alignItems:null }}>
                <Typography variant="body1" className={classes.instructions}> Upload Invoice PDF or TSV file.</Typography>
                <div style={{ alignSelf: 'center'}}>
                  <input type="file" name="file"
                    onChange={onFileSelectChangeHandler}
                    id="contained-button-file"
                    style={{ display: 'none' }}
                    accept=".pdf,.tsv,.csv,.txt" />
                  <label htmlFor="contained-button-file">
                    <Button
                      style={{ marginLeft: 38}} 
                      component="span"
                      variant="contained"
                      color="primary"
                      disabled={props.stepOneSuccess || uiLoading}
                      onClick={handleUploadClick}
                      startIcon={<SvgIcon><path d={SVG_ICONS.upload} /></SvgIcon>}>
                      Upload
                    </Button>
                  </label>
                </div>
              </div>
              {/* <div className={classes.formRow} style={{ alignItems:null }}>
                <Typography variant="body1" className={classes.instructions}> Select Model &nbsp;</Typography>
                <div style={{ alignSelf: 'center'}}>
                  
                  <Select
                    // label="Template"
                    style={{ marginLeft: 38}}
                    labelId="demo-simple-select-outlined-label"
                    id={`so-model-select`}
                    value={textractModel && textractModel != null ? textractModel : null}
                    onChange={(e) => handleSelectedModelChange(e)}
                    // variant="outlined"
                    label={`Textract Model`}
                    fullWidth={true}
                    margin="dense"
                  >
                    {TEXTRACT_MODELS ? renderModelOptions(TEXTRACT_MODELS): <></>}
                  </Select>
                </div>
              </div> */}
            </div>
          </Container>
        </div>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: any) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileImport);
