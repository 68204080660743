import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
	Container, SvgIcon, Dialog, DialogActions, DialogContent,
	DialogTitle, CircularProgress, InputLabel, Select, MenuItem, Snackbar
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { MasterProduct } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import {
	addMasterProduct,
	deleteMasterProduct,
	getMasterProductsByPage,
	updateMasterProduct,
	getAllMasterProductsList,
	getMasterBrands,
	getMasterCompaniesFullList,
	validateMP,
	approveMasterProduct
} from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import { Button, Paper, Tooltip, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { blue, orange, red } from '@material-ui/core/colors';
import cloneDeep from 'lodash/cloneDeep';
import { MasterSupplierDataImportStatusCode } from '../../models';
import SaveIcon from '@material-ui/icons/Save';
import {  LocationBusiness, ToastType } from '../../../shared/models';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		qtyRow: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: '8px 16px'
		},
		dialogContent: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',

		},
		select: {
			color: 'black',
			marginRight: 4,
			'&:before': {
				borderColor: blue,
			},
			'&:after': {
				borderColor: blue,
			},
			maxWidth: '20rem',
			minWidth: '12rem',
		},
		textFieldView: {
			marginRight: theme.spacing(1),
			marginBottom: 6,
			width: '100%'
		},
		textFieldEdit: {
			marginRight: theme.spacing(1),
			marginBottom: 8,
			width: 'calc(100% - 64px)'
		},
	}),
);

const permission_model = 'masterproduct';


const FILTERS: Filter[] = [
	{
		id: 'f1',
		label: 'ID',
		types: [
			{ id: '1', value: 'id', label: 'is equal' },
			{ id: '2', value: 'mp_id_range', label: 'between' }
		],
		selectedType: 'id',
		value: '',
		url: null,
		minValue: '',
		maxValue: ''
	},
	{
		id: 'f2',
		label: 'Name',
		types: [
			{ id: '1', value: 'name__istartswith', label: 'begins with' },
			{ id: '2', value: 'name__icontains', label: 'contains' },
			{ id: '3', value: 'name', label: 'is equal' },
		],
		selectedType: 'name',
		value: '',
		url: null

	},
	{
		id: 'f3',
		label: 'Quantity',
		types: [
			{ id: '1', value: 'master_product_quantity', label: 'is equal' },
			{ id: '2', value: 'master_product_quantity_range', label: 'between' }

		],
		selectedType: 'master_product_quantity',
		value: '',
		url: null,
		minValue: '',
		maxValue: ''

	},
	{
		id: 'f4',
		label: 'Unit of Measure',
		types: [
			{ id: '1', value: 'master_product_unit_of_measure_id__in', label: 'is equal' },
			{ id: '2', value: 'master_product_unit_of_measure_id__not_in', label: 'is not equal' },
		],
		selectedType: 'master_product_unit_of_measure_id__in',
		value: [],
		url: 'korona/master/uom-ref-list/',
		labelField: 'name',
		bindValue: 'abbrev',
		isResponsePaginated: true

	},
	{
		id: 'f5',
		label: 'Images',
		types: [
			{ id: '1', value: 'master_product_having', label: 'is equal' },
		],
		url: null,
		selectedType: 'master_product_having',
		value: '',
		choices: [
			{ id: '1', value: 'zero_image', label: 'has no image' },
			{ id: '2', value: 'one_image', label: 'has exactly one image' },
			{ id: '3', value: 'more_than_one_image', label: 'has more than one image' },
			{ id: '4', value: 'inactive_images', label: 'has inactive image(s)' }

		],
	},
	{
		id: 'f6',
		label: 'Retailers',
		types: [
			{ id: '1', value: 'master_product_retailers__master_retailer_id__in', label: 'is equal' },
			{ id: '2', value: 'master_product_retailers__master_retailer_id__not_in', label: 'is not equal' },
		],
		selectedType: 'master_product_retailers__master_retailer_id__in',
		value: [],
		url: 'korona/master/retailers-list/',
		labelField: 'name',
		bindValue: 'id',
		isResponsePaginated: true
	},

	{
		id: 'f7',
		label: 'Brand',
		name: 'BRAND',
		types: [
			{ id: '1', value: 'brand__id__in', label: 'is equal' },
			{ id: '2', value: 'brand__id__not_in', label: 'is not equal' },
		],
		selectedType: 'brand__id__in',
		value: [],
		url: 'korona/master/products-brand-list/',
		labelField: 'name',
		bindValue: 'id',
		isResponsePaginated: true,
		data: []

	},
	{
		id: 'f8',
		label: 'Company',
		name: 'COMPANY',
		types: [
			{ id: '1', value: 'master_company_id__in', label: 'is equal' },
			{ id: '2', value: 'master_company_id__not_in', label: 'is not equal' },
			{ id: '3', value: 'company_supp_ids', label: 'is supplier', showSuppliers: true },

		],
		selectedType: 'master_company_id__in',
		value: [],
		url: 'korona/master/company-list/',
		labelField: 'name',
		bindValue: 'id',
		isResponsePaginated: true
	},
	{
		id: 'f9',
		label: 'Barcode',
		types: [
			{ id: '1', value: 'master_product_identifier__identifier', label: 'is equal' },
			{ id: '2', value: 'master_product_identifier_range', label: 'between' },
			{ id: '3', value: 'master_product_identifier__icontains', label: 'contains' },
		],
		selectedType: 'master_product_identifier__identifier',
		value: '',
		url: null,
		minValue: '',
		maxValue: ''

	},
	{
		id: 'f10',
		label: 'Barcode Quantity',
		types: [
			{ id: '1', value: 'master_product_identifier__quantity', label: 'is equal' },
			{ id: '2', value: 'master_product_identifier__quantity_range', label: 'between' },

		],
		selectedType: 'master_product_identifier__quantity',
		value: '',
		url: null,
		minValue: '',
		maxValue: ''

	},

	{
		id: 'f11',
		label: 'No. of Rtl Buss Links',
		types: [
			{ id: '1', value: 'selected_retailer_choice', label: 'is equal' },
		],
		url: null,
		selectedType: 'selected_retailer_choice',
		value: '',
		choices: [
			{ id: '1', value: 'zero_retailer', label: 'have no links' },
			{ id: '2', value: 'less_than_one_retailer_and_one_retailer', label: 'have one or no links' },
			{ id: '3', value: 'one_retailer', label: 'have exactly one link' },
			{ id: '4', value: 'more_than_one_retailer', label: 'have more than one link' }

		],
	},

	{
		id: 'f12',
		label: 'No. of All Buss Links',
		types: [
			{ id: '1', value: 'selected_bp_choice', label: 'is equal' },
		],
		url: null,
		selectedType: 'selected_bp_choice',
		value: '',
		choices: [
			{ id: '1', value: 'zero_bp_link', label: 'have no links' },
			{ id: '2', value: 'less_than_one_bp_and_one_bp_link', label: 'have one or no links' },
			{ id: '3', value: 'one_bp_link', label: 'have exactly one link' },
			{ id: '4', value: 'more_than_one_bp_link', label: 'have more than one link' }

		],
	},
	{
		id: 'f13',
		label: 'No. of Supp Co Links',
		types: [
			{ id: '1', value: 'selected_supplier_choice', label: 'is equal' },
		],
		url: null,
		selectedType: 'selected_supplier_choice',
		value: '',
		choices: [
			{ id: '1', value: 'zero_supplier', label: 'have no links' },
			{ id: '2', value: 'less_than_one_supplier_and_one_supplier', label: 'have one or no links' },
			{ id: '3', value: 'one_supplier', label: 'have exactly one link' },
			{ id: '4', value: 'more_than_one_supplier', label: 'have more than one link' }

		],
	},

];



const MasterProductScreen = (props: any) => {
	const classes = useStyles({});
	const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
	const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
	const [tableEditableProp, setTableEditableProp] = useState({});
	let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
	let locationQuery = useQuery(useLocation);
	const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
	const [filterVisible, showFilters] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [filterString, setFilterString] = useState('');
	const [isLoading, setLoading] = useState(false);
	const [intialLoad, setInitialLoad] = useState(true)

	const [filterCompaniesList, setFilterCompaniesList] = useState(null)
	const [filterBrandsList, setFilterBrandsList] = useState(null)

	const [updatedFilters, setUpdatedFilters] = useState(null as any)

	const [isNewMasterProductDialogBoxOpen, setNewMasterProductDialogBoxOpen] = useState(false)
	const [dialogLoading, setDialogLoading] = useState(false)

	const [newMpName, setNewMpName] = useState("")
	const [newMpBrand, setNewMpBrand] = useState(null)
	const [newMpBrandName, setNewMpBrandName] = useState('')

	const [newMpBrandCompany, setNewMpBrandCompany] = useState(null)
	const [newMpBrandCompanyName, setNewMpBrandCompanyName] = useState('')

	const [newQuantity, setNewQuantity] = useState(null)
	const [fieldErrors, setFieldErrors] = useState({});

	const [newUOM, setNewUOM] = useState(null)
	const [newMpnameExists, setNewMpNameExists] = useState(false)
	const [newRetailBarcode, setNewRetailBarcode ] = useState('') 

	const [mpValidationSuccess, setMpValidationSuccess] = useState(false)
	const [masterData, setMasterData] = useState(null)
	const [disableValidate, setDisableValidate] = useState(true)
	const [firstValidationSuccess, setFirstValidationSuccess] = useState(false)
	const [disableImport, setDisableImport] = useState(true);
	const [importSuccess, setImportSuccess] = useState(false)
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);

	const [existingMp, setExistingMp] = useState(false)

	useEffect(() => {
		getMasterBrands()
			.catch(handleError) // handle network issues
			.then(checkStatus)
			.then(parseJSON)
			.then((data: any) => {
				// console.log("results",data)
				setFilterBrandsList(data);
			})
			.catch((error: any) => {
			});
	}, [])

	useEffect(() => {
		getMasterCompaniesFullList()
			.catch(handleError) // handle network issues
			.then(checkStatus)
			.then(parseJSON)
			.then((data: any) => {
				// console.log("results",data)
				setFilterCompaniesList(data);
			})
			.catch((error: any) => {
			});
	}, [])


	useEffect(() => {
		if (filterBrandsList && filterCompaniesList) {
			if (filterBrandsList.length > 0 && filterCompaniesList.length > 0) {
				let currentFilters = [...FILTERS]
				let brandFilterIndexFound = currentFilters.findIndex(cf => cf.name === 'BRAND')
				let companyFilterIndexFound = currentFilters.findIndex(cf => cf.name === 'COMPANY')
				if (brandFilterIndexFound > -1) {
					currentFilters[brandFilterIndexFound].data = filterBrandsList
				}
				if (companyFilterIndexFound > -1) {
					currentFilters[companyFilterIndexFound].data = filterCompaniesList
				}
				setUpdatedFilters([...currentFilters])
			}
		}

	}, [filterBrandsList, filterCompaniesList])

	useEffect(() => {
		if (!isLoading && !intialLoad) {
			tableRef.current && tableRef.current.onChangePage(null, 0);
		}
	}, [filterString]);


	useEffect(() => {
		const activeFilters = selectedFilters.filter(
			(f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue))
		);
		let filterString = '';
		activeFilters.forEach((f: Filter) => {
			let filterValue = '';
			let isRangeFilter = f.selectedType.includes('range');
			// let isChoiceFilter = !f.url && !Array.isArray(f.value) && !f.selectedType.includes('range')&& Array.isArray(f.choices)
			if (f.url && Array.isArray(f.value)) {
				const mappedValues = f.value.map((v) => v[f.bindValue]);
				filterValue = mappedValues.toString();
			}
			// if (isChoiceFilter) {
			//     filterString += `&${f.selectedType}=${f.value}`;
			//     console.log("CHOICE-FILTER-STRING",filterString)
			// }
			else if (!isRangeFilter) {

				// value is string
				filterValue = f.value.toString();

			}
			if (isRangeFilter) {
				if (f.minValue) {
					filterString += `&${f.selectedType}_min=${f.minValue}`;
				}
				if (f.maxValue) {
					filterString += `&${f.selectedType}_max=${f.maxValue}`;
				}
			}
			else {
				filterString += `&${f.selectedType}=${filterValue}`;
			}
		});
		setFilterString(filterString);
	}, [selectedFilters]);


	useEffect(() => {
		if (mpValidationSuccess === true) {
			let callGetAttribute = "";
			if (masterData) {
				if (masterData['status_code'] != MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
					callGetAttribute = "true";
				}
			}
			if (callGetAttribute === "true") {
				getAttributes();
			}
			getQtyAndName(masterData);


		}
	}, [mpValidationSuccess]);



	const getQtyAndName = (validatedRecord: any) => {

		let validatedRec = cloneDeep(validatedRecord)

		const exists = validatedRec['existing_mp'] ? true : false;

		if (exists) {
			let existing_mp = validatedRec['existing_mp']
			let updatedRecord = validatedRec
			// updatedRecord["unitqty_valid"] = true;
			if (existing_mp.attributes && existing_mp.attributes.length > 0) {
				let unit_qty_found = existing_mp.attributes.find(a => a.attribute.name === 'Quantity')
				let unit_name_found = existing_mp.attributes.find(a => a.attribute.name === 'Unit of Measure')
				if (unit_qty_found !== null) {
					updatedRecord["unitqty_mapped"] = unit_qty_found['value'];
				}
				if (unit_name_found !== null) {
					updatedRecord["unitname_mapped"] = unit_name_found['value'];
				}
				if (existing_mp.brand && existing_mp.brand.name !== null) {
					updatedRecord["mpbrand_mapped"] = existing_mp.brand.name;
				}
				validatedRec = updatedRecord
			}
		};
		setMasterData(validatedRec)
		disableImportButton(validatedRec);
	}



	const getAttributes = () => {

		let validatedRecord = cloneDeep(masterData)
		let linkBusinessExists = false;

		let exisitingMpexists = validatedRecord['existing_mp'] ? true : false;

		if (exisitingMpexists) {
			let existing_mp = validatedRecord['existing_mp']
			linkBusinessExists = existing_mp.business_products.values(0) ? true : false;
		}
		if (linkBusinessExists) {
			validatedRecord['status_code'] === MasterSupplierDataImportStatusCode.EXISTING_LINK_RECORD_ERROR;
		}
		if (!linkBusinessExists) {
			validatedRecord['status_code'] === MasterSupplierDataImportStatusCode.READY_TO_IMPORT;
		}

		setMasterData(validatedRecord)
	};


	const toggleFilterPanel = () => {
		showFilters(!filterVisible);
	};

	const addFilter = (filter) => {
		if (!selectedFilters.find(f => f.id === filter.id)) {
			const filters = [...selectedFilters];
			filters.push(filter);
			setSelectedFilters(filters);
		}
	};

	const removeFilter = (index) => {
		const filters = [...selectedFilters];
		filters.splice(index, 1);
		setSelectedFilters(filters);
	};

	const updateFilter = (filter, index) => {
		const filters = [...selectedFilters];
		filters.splice(index, 1, filter);
		// console.log("Updated-Filter",filters)
		setSelectedFilters(filters);
	};


	const handleMinValidate = () => {

		let mp_prod = cloneDeep(masterData)
		let mp_data = getDataToBeValidated(mp_prod)

		if (mp_data != null) {

			let emptyRecs = mp_data['is_empty']
			let unitqtyValidRecs = mp_data['unitqty_valid']
			let unitNameValidRecs = mp_data['unitname_valid']

			console.log("emptyRecs", emptyRecs)

			if (!unitqtyValidRecs || !unitNameValidRecs) {
				setDisableValidate(true)
			}
			else {
				setDisableValidate(false)
			}
			// props.showToast({
			// 	message: 'Validated Successfully!',
			// 	options: ToastSuccess
			// });
			
			setToast({
				open: true,
				message: 'Validated Successfully!',
				variant: 'success'
			});
			setFirstValidationSuccess(true)

		}

	}

	const checkUnitQtyValid = (mpData: any) => {

		let letters = /^[a-z]+$/;

		let mp_unit_qty = mpData['mp_unit_qty'];
		let mp_unit_name = mpData['mp_unit_name'];

		let updatedRecord = mpData
		const unitQtyNotValid = isNaN(mp_unit_qty) ? true : false;

		if (mp_unit_name.match(letters)) {
			updatedRecord['unitname_valid'] = true;
			mpData = updatedRecord

		}

		if (!unitQtyNotValid) {
			if (mp_unit_qty != 0 && mp_unit_qty > 0) {
				updatedRecord['unitqty_valid'] = true;
				mpData = updatedRecord
			}
		}


		return mpData
	};

	const disableImportButton = (updatedRecord: any) => {

		let anyValidRecord = updatedRecord['status_code'] === MasterSupplierDataImportStatusCode.READY_TO_IMPORT ? true : false
		console.log("VALIDRECORD", anyValidRecord)
		if (!anyValidRecord) {
			setDisableValidate(false);
			setDisableImport(true);
		} else {
			setDisableValidate(true);
			setDisableImport(false);
		}
	};

	const getDataToBeValidated = (validationData: any) => {
		let obj = validationData
		if (!obj) {
			obj = {};
		}
		obj['brand_name'] = newMpBrandName ? newMpBrandName : ''
		obj['selected_brand'] = newMpBrand ? newMpBrand : ''
		obj['selected_company'] = newMpBrandCompany ? newMpBrandCompany : ''
		obj['master_product_name'] = newMpName ? newMpName.trim() : ''
		obj['mp_unit_qty'] = newQuantity ? newQuantity.trim() : ''
		obj['mp_unit_name'] = newUOM ? newUOM.trim() : ''
		obj['company_name'] = newMpBrandCompanyName ? newMpBrandCompanyName : ''
		obj['warning_msg'] = '';
		obj['identifiers_mp_mapped'] = '';
		obj['disabled'] = false;
		obj['unitqty_valid'] = false;
		obj['unitname_valid'] = false;
		obj['invoice_item_desc_exists'] = false;
		obj['status'] = '';
		obj['master_company'] = newMpBrandCompany ? filterCompaniesList.find((e) => e.id == newMpBrandCompany) : null
		obj['master_brand'] = newMpBrand ? filterBrandsList.find((e) => e.id == newMpBrand) : null
		obj['retail_barcode'] = newRetailBarcode ? newRetailBarcode.replace(/\s+/g, '') : ''

		const requiredKeys = ['master_product_name', 'mp_unit_qty', 'mp_unit_name', 'master_brand'];
		obj['is_empty'] = false;
		for (let key of requiredKeys) {
			if (key in obj && obj[key] === "") {
				obj['is_empty'] = true;
				break;
			}
		}


		let validRecord = checkUnitQtyValid(obj);
		// const uniqueRecords = getUnique(validRecords);
		setMasterData(validRecord)

		return validRecord

	}

	const handleNewMPDialogBoxCancel = () => {
		setNewMasterProductDialogBoxOpen(false);
		setNewMpName('');
		setNewMpBrand(null)
		setNewMpBrandCompany(null)
		setNewQuantity(null)
		setNewUOM(null)
		setNewMpBrandCompanyName('')
		setNewRetailBarcode('')
		setMpValidationSuccess(false)

	}
	const resetNewMPDialogBoxCancel = () => {
		setNewMpName('');
		setNewRetailBarcode('')
		setNewMpBrand(null)
		setNewMpBrandCompany(null)
		setNewQuantity(null)
		setNewUOM(null)
		setNewMpBrandCompanyName('')
		setMpValidationSuccess(false)

	}

	const handleNewMpNameChange = (event: any) => {
		let val = event.target.value
		setNewMpName(val)
		setNewMpNameExists(false)
	}

	const handleNewMpQtyChange = (event: any) => {
		let val = event.target.value
		setNewQuantity(val)
	}
	const handleNewMpUOMChange = (event: any) => {
		let val = event.target.value
		setNewUOM(val)
	}
	const handleNewRetailBarcodeChange = (event: any) => {
		let val = event.target.value
		setNewRetailBarcode(val)
	}

	const hasError = (key: string) => {
		if (fieldErrors && fieldErrors.hasOwnProperty(key)) {
			const error = fieldErrors[key].isBlankError;
			return error ? true : false;
		}
		return false;
	};

	const handleNewMpBrandChange = (event: any) => {
		// console.log("FILTERED",event.target)
		let val = event.target.value
		let filtered = filterBrandsList ? filterBrandsList.filter(p => p.id === val) : null
		setNewMpBrand(filtered[0].id)
		setNewMpBrandName(filtered[0].name)
		setNewMpNameExists(false)
	}


	const handleNewMpBrandCompanyChange = (event: any) => {
		// console.log("FILTERED",event.target)
		let val = event.target.value
		let filtered = filterCompaniesList ? filterCompaniesList.filter(p => p.id === val) : null
		setNewMpBrandCompany(filtered[0].id)
		setNewMpBrandCompanyName(filtered[0].name)
		setNewMpNameExists(false)
	}

	const handleNewMPSave = () => {
		console.log("SAVEEEE")
	}

	const handleNewMPValidate = () => {
		console.log("Validate")

		let obj = {

		}
	}

	const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    
    setToast({ open: false, message: '', variant: 'success' });
  };


	const renderAddMPDialogBox = () => {
		return (
			<div>
				{
					<Dialog
						open={isNewMasterProductDialogBoxOpen}
						onClose={handleNewMPDialogBoxCancel}
						fullWidth={true}
						maxWidth="sm"
					>
						<DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add Master Product</DialogTitle>
						<DialogContent style={{ padding: "10px", }}>
							{
								dialogLoading &&
								<div style={{ textAlign: 'center', marginTop: 5 }}>
									<CircularProgress />
								</div>
							}
							{
								!dialogLoading &&
								<div className={classes.dialogContent} >
									<TextField
										autoFocus
										margin="dense"
										size="small"
										value={newMpName && newMpName != null ? newMpName : null}
										onChange={handleNewMpNameChange}
										label="Master Product Name"
										type="text"
										style={{ width: '20rem' }}
										disabled={dialogLoading}
										error={newMpnameExists}
										helperText={newMpnameExists ? "Already exists" : null}
										required= {true}
									/>
									<TextField
										id={`new-mp-qty`}
										className={classes.textFieldView}
										label={'Quantity'}
										margin="dense"
										style={{ width: '15rem' }}
										type='numeric'
										onChange={handleNewMpQtyChange}
										helperText={newQuantity === '' ? 'This field cannot be blank' : null}
										value={newQuantity && newQuantity !== '' ? newQuantity : ''}
										required= {true}

									/>
									<TextField
										id={`new-mp-uom`}
										className={classes.textFieldView}
										label={'Unit of Measure'}
										margin="dense"
										style={{ width: '15rem' }}
										type="text"
										onChange={handleNewMpUOMChange}
										helperText={newUOM === '' ? 'This field cannot be blank' : null}
										value={newUOM && newUOM !== '' ? newUOM : ''}
										required= {true}

									/>
									<TextField
										id={`new-mp-barcode`}
										className={classes.textFieldView}
										label={'Barcode'}
										margin="dense"
										style={{ width: '15rem' }}
										type="number"
										onChange={handleNewRetailBarcodeChange}
										value={newRetailBarcode && newRetailBarcode !== '' ? newRetailBarcode : ''}

									/>
									<FormControl style={{ marginBottom: 15 }} required>
										<InputLabel id="select-label" >
											Brand
										</InputLabel>
										<Select
											value={newMpBrand ? newMpBrand : ''}
											onChange={handleNewMpBrandChange}
											className={classes.select}
											disabled={dialogLoading}
											style={{ width: '15rem' }}
										>
											{filterBrandsList &&
												filterBrandsList.map((b: any) => (
													<MenuItem value={b.id} key={String(b.id)}>
														{b.name}
													</MenuItem>
												))}
										</Select>
									</FormControl>
									{/* <FormControl style={{ marginBottom: 15 }} required>
										<InputLabel id="select-label">
											Company
										</InputLabel>
										<Select
											value={newMpBrandCompany ? newMpBrandCompany : ''}
											onChange={handleNewMpBrandCompanyChange}
											className={classes.select}
											disabled={dialogLoading}
											style={{ width: '15rem' }}

										>
											{filterCompaniesList &&
												filterCompaniesList.map((b: any) => (
													<MenuItem value={b.id} key={String(b.id)}>
														{b.name}
													</MenuItem>
												))}
										</Select>
									</FormControl> */}
								</div>
							}
							<Snackbar open={toast.open} autoHideDuration={6000} onClose={handleCloseToast}>
            <Alert onClose={handleCloseToast} severity={toast.variant}>
              {toast.message}
            </Alert>
          </Snackbar>
					{existingMp ? masterData.warning_msg ? masterData.warning_msg : '' : ''}
						</DialogContent>
						{
							!dialogLoading &&
							<DialogActions>
								<Button onClick={handleNewMPDialogBoxCancel} color="primary" autoFocus>
									Cancel
								</Button>
								
									{/* <Button onClick={() => handleNewMPValidate()}  >
										Validate
									</Button>  */}
								{!mpValidationSuccess ?
									<>
										<Button
											variant="contained"
											color="primary"
											size="small"
											style={{ }} 
											disabled={newMpnameExists ? newMpnameExists : newMpName === ''}
											onClick={() => handleMinValidate()}
										>Validate</Button>
										<Button
											variant="contained"
											color="primary"
											size="small"
											style={{ marginRight: 0 }}
											disabled={disableValidate ? true : false}
											onClick={() => handleValidate()}
										>
											Validate MP
										</Button>
									</>
									:
									<Button
										variant="contained"
										color="primary"
										size="small"
										style={{ marginRight: 0 }}
										startIcon={<SaveIcon />}
										disabled={disableImport}
										onClick={() => handleSave()}
									>
										Approve
									</Button>
								}
							</DialogActions>
						}

					</Dialog>
				}
			</div>
		)
	};


	const handleValidate = () => {
		if (masterData) {

			setLoading(true)
			let payload = {
				auth_token: props.userData.userData.userData.auth_token,
				master_product_details: masterData,
			}
			console.log("PAYLOADDD", payload)
			validateMP(payload)
				.catch(handleError) // handle network issues
				.then(checkStatus)
				.then(parseJSON)
				.then(jsonData => {
					setMasterData(jsonData['records'][0])
					if(jsonData['records'][0].hasOwnProperty('existing_mp') ){
						setExistingMp(jsonData['records'][0]['existing_mp'])

					}
					setMpValidationSuccess(true)
					// props.showToast({
					// 	message: 'Validated Successfully!',
					// 	options: ToastSuccess
					// });
					setToast({
            open: true,
            message: 'Validated Successfully!',
            variant: 'success'
          });
					setLoading(false);
				})
				.catch((error: any) => {
					setMpValidationSuccess(false)
					setDisableValidate(true)
					let msg = 'Failed to validate';
					if ((error.status === 403 || error.status === 401)
						&& error.body.detail.length > 0) {
						msg = NO_PERMISSIONS_ERROR_MSG;
					}
					props.showToast({ message: msg, options: ToastError });
					setToast({
            open: true,
            message: msg,
            variant: 'error'
          });
					setLoading(false)
				});
		}

	}


	const handleSave = () => {

		let mp_data = null
		if (masterData.hasOwnProperty("status")) {
			if (masterData['status_code'] === MasterSupplierDataImportStatusCode.READY_TO_IMPORT) {
				mp_data = masterData
			}
		}

		if (mp_data) {
			// let selectedCompanyId = mp_data['master_company']['id']
			setLoading(true)
			let payload = {
				auth_token: props.userData.userData.userData.auth_token,
				// company_id: selectedCompanyId,
				records: mp_data,
			}
			console.log("PAYLOADDD", payload)
			approveMasterProduct(payload)
				.catch(handleError) // handle network issues
				.then(checkStatus)
				.then(parseJSON)
				.then(jsonData => {
					setImportSuccess(true)
					setDisableValidate(true)
					setDisableImport(true)
					// setNewMasterProductDialogBoxOpen(false)
					resetNewMPDialogBoxCancel()
					props.showToast({
						message: 'Master Product Created Successfully!',
						options: ToastSuccess
					});
					// confirmReset()
					setLoading(false);
					// tableRef.current && tableRef.current.onQueryChange();

				})
				.catch((error: any) => {
					setImportSuccess(false)
					setDisableImport(false)
					let msg = 'Failed to Import';
					if ((error.status === 403 || error.status === 401)
						&& error.body.detail.length > 0) {
						msg = NO_PERMISSIONS_ERROR_MSG;
					}
					props.showToast({ message: msg, options: ToastError });
					setLoading(false)
				});
		} else {
			let msg = 'There are no records is ready to import';
			props.showToast({ message: msg, options: ToastError });
		}



	}



	const navigateToMasterProductDetailScreen = (rowData: any) => {
		const masterProductDetailUrl = MasterDataRouterPaths.masterProducts + '/' + rowData.id;
		props.history.push(masterProductDetailUrl, rowData);
	};


	const renderTable = () => {
		return (
			<Container maxWidth="md">
				<ErrorStatusBar isVisible={isErrorStatusVisible} />
				<div className="table-wrapper">
					<MaterialTable
						options={{
							...tableOptions(tableQuery),
							thirdSortClick: false,
							draggable: false,
							sorting: true,
							// headerStyle:{ width:""}
						}}
						components={{
							Toolbar: (props) => (
								<>
									<MTableToolbar {...props} />
									{filterVisible && (
										<>
											<FilterRow
												{...props}
												renderAvailableFilters={
													<AvailableFilters
														filters={updatedFilters ? updatedFilters : FILTERS}
														addFilter={addFilter}
													/>
												}
												renderSelectedFilters={
													<SelectedFilters
														selectedFilters={selectedFilters}
														removeFilter={removeFilter}
														updateFilter={updateFilter}
														businessId={null}
													/>
												}
											/>
										</>
									)}
								</>
							),
						}}
						tableRef={tableRef}
						localization={{
							body: {
								emptyDataSourceMessage: emptyDataSourceMessage
							}
						}}
						columns={[
							{ title: 'ID', field: 'id', editable: 'never' },
							{
								title: 'Product',
								field: 'name',
								editable: 'never',
								render: (rowData: any) => {
									const { name } = rowData
									if (name != null) {
										return (<div style={{ minWidth: name != null ? "450px" : null }}>  {name}  </div>)
									} return null
								}

							},
							{
								title: 'Links(BP/CP)',
								field: 'business_products_count',
								type: 'numeric',
								sorting: false,
								render: (rowData: any) => {
									const { business_products_count, master_company_instance_count } = rowData
									if (business_products_count != null) {
										return (<div style={{ marginRight: business_products_count != null ? 30 : null }}>  {`${business_products_count}/${master_company_instance_count ? master_company_instance_count : 0}`}  </div>)
									} return null
								}
							},
							{
								title: 'Images',
								field: 'contains_image',
								type: 'boolean',
								sorting: false,
								render: (rowData: any) => (
									rowData.contains_image ?
										<div style={{}} >
											<SvgIcon
												htmlColor={GlobalStyles.green}
											>
												<path d={SVG_ICONS.imageMultiple} />
											</SvgIcon>
										</div> :
										<div style={{}}>
											<SvgIcon
												htmlColor={GlobalStyles.red}
											>
												<path d={SVG_ICONS.imageOff} />
											</SvgIcon>
										</div>
								),
							},
						]}
						data={query => new Promise((resolve, reject) => {
							replaceHistory(query, props.history);

							console.log("query", query)

							let pageData = {
								data: [] as MasterProduct[],
								totalCount: 0,
								page: query.page
							};
							let orderBy = '';
							if (query.orderBy) {
								orderBy = query.orderBy.field as string;
								orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
							}
							setLoading(true);
							console.log("SEARCH-TERM", query.search)
							let modifiedSearchTerm = query.search
							let filterTerm = filterString
							if (modifiedSearchTerm) {
								if (modifiedSearchTerm.length >= 8 && !isNaN(Number(modifiedSearchTerm)) === true) {
									let barcodeSearchTerm = modifiedSearchTerm
									filterTerm = `&master_product_identifier__icontains=${barcodeSearchTerm}`
									modifiedSearchTerm = ''
								}
							}

							getAllMasterProductsList(
								query.page + 1,
								query.pageSize,
								modifiedSearchTerm,
								orderBy,
								filterTerm
							)
								.catch(handleError) // handle network issues
								.then(checkStatus)
								.then(parseJSON)
								.then((data: PaginatedResponse<MasterProduct>) => {
									setLoading(false);
									setErrorStatusVisible(false);
									setEmptyDataSourceMessage(EMPTY_ROW_MSG);
									pageData = {
										data: data.results,
										totalCount: data.count,
										page: query.page
									};
									setTableQuery({
										...tableQuery,
										orderBy,
										totalCount: data.count,
										page: query.page,
										pageSize: query.pageSize
									});
									resolve(pageData);
									setInitialLoad(false)
								})
								.catch((error: any) => {
									if (error) {
										if (error.status && error.status === 500) {
											setEmptyDataSourceMessage(ERROR_500);
											setErrorStatusVisible(false);
										} else if ((error.status === 403 || error.status === 401)
										) {
											setEmptyDataSourceMessage(ERROR_MSG);
											setErrorStatusVisible(true);
										} else {
											setEmptyDataSourceMessage(ERROR_MSG);
											setErrorStatusVisible(false);
										}
									}
									setTableQuery({
										...tableQuery,
										totalCount: 0,
										page: query.page,
										pageSize: query.pageSize
									});
									setLoading(false);
									resolve(pageData);
								});
						})
						}
						title={
							<CustomTableTitle
								history={props.history}
								title={'Master Products'}
								icon={SVG_ICONS.product}
							/>
						}
						actions={[
							{
								icon: 'refresh',
								tooltip: 'Refresh Data',
								isFreeAction: true,
								onClick: () => tableRef.current && tableRef.current.onQueryChange(),
							},
							{
								icon: 'edit',
								tooltip: 'Edit',
								hidden: hasUpdatePermission(props.userData.userData.is_superuser, props.userData.userData.user_permissions, permission_model),
								onClick: (event, rowData) => navigateToMasterProductDetailScreen(rowData),
							},
							{
								icon: 'filter_list',
								tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
								isFreeAction: true,
								iconProps: {
									color: filterVisible ? 'primary' : 'inherit',
								},
								// hidden: !isSuperAdmin(props),
								onClick: toggleFilterPanel,
							},
							{
								icon: 'add',
								tooltip: 'Add MP',
								isFreeAction: true,
								onClick: () => setNewMasterProductDialogBoxOpen(true),
							},
						]}
					/>
				</div>
			</Container>
		);
	}

	return (
		<div>
			{renderTable()}
			{renderAddMPDialogBox()}

		</div>
	);
};

const mapStateToProps = (state: any) => {
	return {
		masterProductList: state.masterModuleData.masterProductList,
		userData: state.userLocalData,
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterProductScreen);