import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography, Snackbar, FormControl, InputLabel, IconButton, CircularProgress, Select, MenuItem,
  RadioGroup, FormGroup, FormControlLabel, Radio, FormLabel
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterProduct, MasterProductIdentifier } from '../../models';
import { Toast, enqueueSnackbar, ToastError } from '../../../shared/components/Notifier';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    mainList: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: GlobalStyles.lightBlue,
      },
      '&:after': {
        borderColor: GlobalStyles.lightBlue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },

    formControl: {
      margin: theme.spacing(2),
    },
    formContent: {
      display: 'flex',
      flexDirection: 'row'
    },
  }),
);

//@ts-nocheck
const CompanyProductAttributeModal = (props: any) => {
  const classes = useStyles({});
  const [attributeData, setAttributeData] = useState(null)
  const [invalidQty, setInvalidQty] = useState(false)
  const {
    onClose, isOpen, onSave, onUpdate, attrData, toast,
    handleCloseToast, instanceData, isLoading, handlResetToast,
    newAttributeCreated
  } = props

  useEffect(() => {
    setAttributeData({ ...attrData });
    console.log("instanceData", instanceData, attrData)
  }, [props.attrData]);

  const handleChangeInstanceType = (e: any) => {
    console.log("type:", e, instanceData.attributes_types.find(t => t.key === e.target.value))
    setAttributeData({ ...attributeData, type: instanceData.attributes_types.find(t => t.key === e.target.value) })
  }

  const handleChangeValue = (e: any) => {
    setAttributeData({ ...attributeData, value: e.target.value })
  }

  const handleChangeQuantity = (e: any) => {
    let str = e.target.value
    const regExp = /^\d*(\.)?(\d{0,3})?$/

    if (regExp.test(str)) {
      setAttributeData({ ...attributeData, quantity: str })
      setInvalidQty(false)

    }
    else {
      setInvalidQty(true)
    }

  }

  const handleChangePrimary = (e: any) => {
    setAttributeData({ ...attributeData, primary: e.target.value === 'true' ? true : e.target.value === 'false' ? false : null })
  }

  const renderPrimary = () => {
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormLabel component="legend">Primary: </FormLabel>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={attributeData.primary === true ? 'true' : attributeData.primary === false ? 'false' : null} onChange={(e) => handleChangePrimary(e)} className={classes.formContent} >
            <FormGroup>
              <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
              <FormControlLabel value={'false'} control={<Radio />} label="No" />
            </FormGroup>
          </RadioGroup>
        </FormControl>
      </div>
    )
  }


  const renderDialog = () => {
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll={'paper'}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={toast.variant === 'error'}
        onClick={handlResetToast}
      >
        <DialogTitle >
          {
            props.mode ? (props.mode === 'add' ?
              'Add Company Product Attributes' : 'Edit Company Product Attributes') : ''
          }
        </DialogTitle>
        <DialogContent dividers >
          <React.Fragment>
            <div className={classes.mainList}>

              <FormControl style={{ marginBottom: 15 }}>
                <InputLabel>
                  Type
                </InputLabel>
                <Select
                  value={attributeData ? attributeData.type && attributeData.type.key : ""}
                  onChange={handleChangeInstanceType}
                  className={classes.select}
                  style={{ minWidth: '100%' }}
                >
                  {instanceData &&
                    instanceData.attributes_types &&
                    instanceData.attributes_types.map((b: any) => (
                      <MenuItem value={b.key} key={String(b.id)}>
                        {b.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <TextField
                key="val"
                label="Value"
                margin="dense"
                variant="outlined"
                size="small"
                style={{ minWidth: "100%", marginRight: '5px' }}
                value={attributeData ? attributeData.value && attributeData.value : ''}
                onChange={(e) => handleChangeValue(e)}
                inputProps={{ style: { textAlign: 'center' } }}
              />
              <TextField
                key="qty"
                label="Quantity"
                margin="dense"
                variant="outlined"
                size="small"
                style={{ minWidth: "100%", marginRight: '5px' }}
                value={attributeData ? attributeData.quantity && attributeData.quantity : ''}
                onChange={(e) => handleChangeQuantity(e)}
                error={invalidQty}
                helperText={invalidQty  ? `No more than 3 decimal places allowed` : '' }
                inputProps={{ style: { textAlign: 'center' } }}
              />
              {renderPrimary()}

            </div>
            {
              invalidQty &&
              <div style={{ textAlign: 'center', marginTop: 10 }}>
                <Typography style={{ color: GlobalStyles.error }}>{`No more than 3 decimal places allowed`} </Typography>
              </div>
            }
            {
              isLoading &&
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            }
          </React.Fragment>
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={toast.open} autoHideDuration={4000} onClose={handleCloseToast} >
            <Alert onClose={handleCloseToast} severity={toast.variant}>
              {toast.message}
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          {
            props.mode ? props.mode === 'add' &&
              <Button
                onClick={() => onSave(attributeData)}
                color="primary"
                disabled={invalidQty ? true : newAttributeCreated ? true : false}
              >
                Add
              </Button>
              : null
          }
          {
            props.mode ? props.mode === 'edit' &&
              <Button
                onClick={() => onUpdate(attributeData)}
                color="primary"
                disabled={invalidQty ? true : newAttributeCreated ? true : false}
              >
                Update
              </Button>
              : null
          }
        </DialogActions>
      </Dialog>

    )
  }

  return (
    <div>
      {attributeData && renderDialog()}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};
export default connect(null, mapDispatchToProps)(CompanyProductAttributeModal);
