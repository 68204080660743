import { API_CONFIG } from '../../shared/api/config';
const BASE_URL = API_CONFIG.API_BASE_PATH;

import composeRequest, { getPaginatedResponse } from '../../shared/api/core';

export const API_URLS = {
  getCCRSReportUrl : BASE_URL + 'ccrs/generate-report/',
  uploadCCRSReportUrl : BASE_URL + 'ccrs/upload-report',
  generateUploadCCRSReportUrl : BASE_URL + 'ccrs/generate-upload-report',
  getCCRSReportListUrl: BASE_URL + 'ccrs/report-list/',
  getCCRSReportDetailListUrl : BASE_URL + 'ccrs/report-detail-list/',
  saveFileContentDetailErrorMsgsUrl: BASE_URL + 'ccrs/file-detail/save-error-msgs',
  changeFileAsErrorUrl: BASE_URL + 'ccrs/files/change-error'
}

export const getCCRSReport = (data: any) => {
  const URL = API_URLS.getCCRSReportUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const getAndSaveCCRSReport = (data: any) => {
  const URL = API_URLS.generateUploadCCRSReportUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const uploadCCRSReport = (data: any) => {
  const URL = API_URLS.uploadCCRSReportUrl;
  return composeRequest(URL, 'POST', data, 'multipart/form-data');
};


export const getCCRSReportList = (
  business_id: number | string,
  page: number | null,
  pageSize: number | null,
  searchTerm: string | null,
  orderBy?: string | number,
  shouldExport?: boolean,
  file_name?:string | null
) => {
  let URL = `${API_URLS.getCCRSReportListUrl}`;
  if (shouldExport && file_name) {
    URL = `${URL}export/?business_id=${business_id}&file_name=${file_name}`;
  } else {
    URL = `${URL}?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
  }
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  }
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
}


export const getCCRSReportDetailList = (
  file_id : number | string,
  business_id: number | string,
  page: number | null,
  pageSize: number | null,
  searchTerm: string | null,
  orderBy?: string | number ,
  shouldExport?: boolean,
  file_name?:string | null
) => {
  let URL = `${API_URLS.getCCRSReportDetailListUrl}`;
  if (shouldExport && file_name) {
    URL = `${URL}export/?business_id=${business_id}&file_id=${file_id}&file_name=${file_name}`;
  } else {
    URL = `${URL}?business_id=${business_id}&file_id=${file_id}&page=${page}&page_size=${pageSize}`;
  }
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  }
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
}

export const changeFileAsError = (data: any) => {
  const URL = API_URLS.changeFileAsErrorUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
};



export const saveFileContentDetailErrorMsgs = (data: any) => {
  const URL = API_URLS.saveFileContentDetailErrorMsgsUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
};