import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths, SupplierRouterPaths } from '../../../Routes/RouterPaths';
import { SideMenuItem } from './models';

export const SupplierPrimaryMenuItems: SideMenuItem[] = [
    {
        id: '0',
        name: 'dashboard',
        title: 'Dashboard',
        route: SupplierRouterPaths.dashboard,
        icon: SVG_ICONS.dashboard
    },
    {
        id: '1',
        name: 'supplier-businesses',
        title: 'Businesses',
        route: SupplierRouterPaths.businesses,
        icon: SVG_ICONS.business,
        requiredPermissions: []
    },
    {
        id: '2',
        name: 'products',
        title: 'Products',
        route: SupplierRouterPaths.productList,
        icon: SVG_ICONS.product,
        requiredPermissions: ["view_mastersupplierproduct"]
    },
    {
        id: '3',
        name: 'invoices',
        title: 'Invoices',
        route: SupplierRouterPaths.invoices,
        icon: SVG_ICONS.invoice,
        requiredPermissions: ["view_invoice"]
    },
    {
        id: '7',
        name: 'reports',
        title: 'Reports',
        route: '/web/reports/',
        icon: SVG_ICONS.reports,
        subItems: [
            {
                id: '7.1',
                name: 'reports',
                route: MasterDataRouterPaths.masterSupplierProductsWithoutBarcodeReport,
                activeRoute: MasterDataRouterPaths.masterSupplierProductsWithoutBarcodeReport.substring(4),
                title: 'Products Without Barcode',
                icon: SVG_ICONS.product,
                requiredPermissions: ["view_mastersupplierproduct"]
            },
            {
                id: '7.2',
                name: 'reports',
                route: MasterDataRouterPaths.masterSupplierProductsWithoutCaseCodeReport,
                activeRoute: MasterDataRouterPaths.masterSupplierProductsWithoutCaseCodeReport.substring(4),
                title: 'Products Without Case Code',
                icon: SVG_ICONS.product,
                requiredPermissions: ["view_mastersupplierproduct"]
            },
            {
                id: '7.3',
                name: 'reports',
                title: 'Products Without Case Quantity',
                route: MasterDataRouterPaths.masterSupplierProductsWithoutCaseQtyReport,
                activeRoute: MasterDataRouterPaths.masterSupplierProductsWithoutCaseQtyReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ["view_mastersupplierproduct"]
            },
            {
                id: '7.4',
                name: 'reports',
                title: 'Products Without Unit Measure',
                route: MasterDataRouterPaths.masterSupplierProductsWithoutUnitMeasureReport,
                activeRoute: MasterDataRouterPaths.masterSupplierProductsWithoutUnitMeasureReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ["view_mastersupplierproduct"]
            },

        ]
    },
];

export const SupplierSecondaryMenuItems: SideMenuItem[] = [
    {
        id: '2',
        name: 'account',
        title: 'Account',
        route: SupplierRouterPaths.account,
        icon: SVG_ICONS.account,
        requiredPermissions: []
    },
    {
        id: '3',
        name: 'settings',
        title: 'Settings',
        route: SupplierRouterPaths.settings,
        icon: SVG_ICONS.settings,
        requiredPermissions: []
    }
];