import _difference from 'lodash/difference';
import { ReportCard } from '../../models';
import { Permission } from '../../../shared/models/Permission';

export const getAuthorizedCards = (reportCards: ReportCard[], userPermissions: Permission[], is_superuser: boolean) => {
  if (is_superuser) {
    return reportCards;
  }
  const permissions = userPermissions.map(p => p.codename);
  let cards: ReportCard[] = [];
  const allCards = reportCards.map(a => Object.assign({}, a));
  allCards.forEach(c => {
    if (isReportCardAuthorized(c, permissions)) {
      cards.push(c);
    }
  });
  return cards;
};

const isReportCardAuthorized = (card: ReportCard, userPermissions: string[]) => {
  if (card.requiredPermissions && card.requiredPermissions.length > 0) {
    return _difference(card.requiredPermissions, userPermissions).length === 0;
  }
  return true;
};