import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MaterialTableProps, MTableToolbar } from 'material-table';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLocation } from 'react-router-dom';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { PaginatedResponse } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, ERROR_404, ERROR_409 } from '../../../shared/lib/Localization';
import { getProductMargins } from '../../api';
import { Typography } from '@material-ui/core'
import {
  CustomTableTitle,
  ErrorStatusBar,
  ExportMenu,
  ExportStatusBar,
} from '../../../shared/components';
import download from 'downloadjs';
import { BusinessRouterPaths } from '../../../page/Routes/RouterPaths';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { blue, orange, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);


const ProductMarginsScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setExporting] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [initialLoad,setInitialLoad]=useState(true);
  const [totalRecords, setTotalRecords] = useState(null as any)


  const FILTERS: Filter[] = [
    {
      id: 'f1',
      label: 'Margins',
      types: [
        { id: '1', value: 'margin_range_between', label: 'in between' },
        { id: '2', value: 'margin_range_not_in_between', label: 'not in between' }
      ],
      selectedType: 'margin_range_between',
      value: '',
      url: null,
      minValue: '',
      maxValue: ''
    },
    {
      id: 'f2',
      label: 'Commodity Group',
      types: [
        { id: '1', value: 'cg_number__in', label: 'is equal' },
        { id: '2', value: 'cg_number__not_in', label: 'is not equal' },
      ],
      selectedType: 'cg_number__in',
      value: [],
      url: `korona/cg-list/?business_id=${props.userData && props.userData.selectedBusiness.business_id}`,
      labelField: 'name',
      bindValue: 'number',
      isResponsePaginated: true
    },
  ];

  // console.log("props.userData && props.userData.selectedBusiness.business_id", props.userData && props.userData)


  useEffect(() => {

    if (!isLoading && !initialLoad) {
      console.log("FILTER STRING CALLED")
      tableRef && tableRef.current && tableRef.current.onQueryChange();
      setTotalRecords(null)
    }
  }, [props.userData.selectedBusiness, filterString]);



  useEffect(() => {

    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue))
    );
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }

      else if (!isRangeFilter) {

        // value is string
        filterValue = f.value.toString();

      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else {
        filterString += `&${f.selectedType}=${filterValue}`;
      }
    });
    setFilterString(filterString);
  }, [selectedFilters]);


  const handleRefresh= () => {
    setTotalRecords(null)
    tableRef.current && tableRef.current.onQueryChange()
  }

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    // console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };


  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getProductMargins(
        sb.business_id,
        null,
        null,
        tableQuery.search,
        true ? totalRecords : false,
        sb.orderBy,
        filterString,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'Product_margins.csv', 'text/csv');
          setExporting(false);
          setInitialLoad(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };


  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };


  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };


  return (
    <Container maxWidth={false}>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />

      <div className="table-wrapper">
        <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: true
          }}
          components={{
            Toolbar: (props) => (
              <>
                <MTableToolbar {...props} />
                {filterVisible && (
                  <>
                    <FilterRow
                      {...props}
                      renderAvailableFilters={
                        <AvailableFilters
                          filters={FILTERS}
                          addFilter={addFilter}
                        />
                      }
                      renderSelectedFilters={
                        <SelectedFilters
                          selectedFilters={selectedFilters}
                          removeFilter={removeFilter}
                          updateFilter={updateFilter}
                          businessId={null}
                        />
                      }
                    />
                  </>
                )}
              </>
            ),
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage
            }
          }}
          columns={[
            { title: 'Product No.', field: 'product_number' },
            {
              title: 'Product Name',
              field: 'product_name',
              render: rowData => {
                if (rowData && rowData.product_name) {
                  return <Typography style={{ minWidth: 270 }} >{rowData.product_name}</Typography>
                }
                return <div></div>;
              },
            },
            { title: 'Price', field: 'price' },
            { title: 'Last Cost', field: 'last_cost' },
            { title: 'Margin', field: 'margin' },
            { title: 'CG. Name', field: 'commodity_group_name' },
            { title: 'Rec. Margin', field: 'recommended_margin' },


          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              replaceHistory(query, props.history);
              let pageData = {
                data: [] as any[],
                totalCount: 0,
                page: query.page
              };
              if (props.userData && props.userData.selectedBusiness) {
                const sb = props.userData.selectedBusiness;
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                let totalRecsAvailable = totalRecords !== null ?  true : false

                getProductMargins(
                  sb.business_id,
                  query.page + 1,
                  query.pageSize,
                  query.search,
                  totalRecsAvailable,
                  orderBy,
                  filterString,
                  false
                )
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((data: PaginatedResponse<any>) => {
                    setErrorStatusVisible(false);
                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                    if(!totalRecords){
                      setTotalRecords(data.count)
                    }
                    pageData = {
                      data: data.results,
                      totalCount: !totalRecords ? data.count : totalRecords,
                      page: query.page
                    };

                    setTableQuery({
                      ...tableQuery,
                      orderBy,
                      totalCount: !totalRecords ? data.count : totalRecords,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    // console.log(pageData.data)
                    resolve(pageData);
                    setInitialLoad(false);
                    // console.log(pageData.data)
                  })
                  .catch((error: any) => {
                    if (error) {
                      if (error.status && error.status === 500) {
                        setEmptyDataSourceMessage(ERROR_500);
                        setErrorStatusVisible(false);
                      } else if ((error.status === 403 || error.status === 401)
                      ) {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(true);
                      } else {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(false);
                      }
                    }
                    setTableQuery({
                      ...tableQuery,
                      totalCount: 0,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    resolve(pageData);
                  });
              }
            })
          }
          title={
            <CustomTableTitle
              history={props.history}
              title={'Product Margins'}
              icon={SVG_ICONS.product} />
          }
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => handleRefresh(),
            },
            {
              icon: 'save_alt',
              tooltip: 'Export',
              isFreeAction: true,
              onClick: handleExportClick,
              disabled: isExporting,
            },
            {
              icon: 'filter_list',
              tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
              isFreeAction: true,
              iconProps: {
                color: filterVisible ? 'primary' : 'inherit',
              },
              // hidden: !isSuperAdmin(props),
              onClick: toggleFilterPanel,
            },
          ]}
        />
        <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

      </div>
    </Container>
  );
};


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};


export default connect(mapStateToProps, null)(ProductMarginsScreen);








