import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  FormControl, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, DialogTitle,
  LinearProgress, Button, Container, Paper, List, ListItemText, ListItem, CardMedia, Divider,
  Card, CardContent, CircularProgress, Typography, Grid, Slide, Link, FormControlLabel, TextField,
  SvgIcon, Icon, MenuItem, Select, InputLabel, Toolbar, AppBar, Input, ListSubheader, Tooltip,
  Checkbox, Switch, Box, Radio, RadioGroup, Menu, Snackbar
} from '@material-ui/core/';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Theme, createStyles, makeStyles, withStyles, fade } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';

import { PaginatedResponse,ToastType } from '../../../shared/models';
import {
  AddressPartyType,AddressSubPartyType
} from '../../models';

import {AddAddress} from '../../components'
import {AddressDetails} from '../../components'

import {
  getMasterBrandDetail, getMasterCompanyData, updateMasterBrand, uploadMasterItemImage,
  getMasterItemImages, updateMediaBySelectedAction, createMediaLinkItem , createOrUpdateMasterCodeGroup
} from '../../api';

import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';


import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError, ToastInfo } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import _isEqual from 'lodash/isEqual';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MasterBrandList, MasterCompanyList, ICountry, IState, ICity, ItemType, ItemCategory } from '../../models';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { blue, orange, red } from '@material-ui/core/colors';
import _filter from 'lodash/filter';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FileDrop } from 'react-file-drop';
import { Image, MasterCodeGroupModal } from '../../components';
import { TransitionProps } from '@material-ui/core/transitions';
import './style.css';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, ERROR_404, ERROR_409 } from '../../../shared/lib/Localization';





function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },

    button: {
      margin: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textFieldCardRow: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },

    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },

    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    rowComponents: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },

    imageList: {
      position: 'relative',
      padding: '5px'
    },

    inActiveIcon: {
      color: 'black',
      fontSize: '2rem',
      cursor: 'pointer',
    },
    dnd: {
      border: '1px solid black',
      // display: 'flex',
      // flexWrap: 'wrap',
      // color: 'black',
      padding: '15px',
      borderStyle: 'dashed',
      // justifyContent: 'center',
      marginBottom: '30px'

    },
    // dnd:{
    //   '&:hover': {
    //     backgroundColor: fade(theme.palette.common.white, 0.25),
    //     border: '1px solid blue'
    //   },
    // }
    panelHeading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'
      
    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
    },
    rowActionBtn: {
      padding: 8
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },
  }),
);


const MasterBrandDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [isLoading, setLoading] = useState(false)
  const [brandData, setBrandData] = useState(null)
  const [originalMasterBrand, setOriginalMasterBrand] = useState(null);
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });
  const [nameError, setNameError] = useState({ isError: false, message: '' });
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false)
  const [newCompanyName, setNewCompanyName] = useState('');
  const [newCompanySelected, setNewCompanySelected] = useState(false);
  const [newCompanyNameExists, setNewCompanyNameExists] = useState(false)
  const [newCompanyActive, setNewCompanyActive] = useState("1");
  const [urlChecked, setUrlChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);
  const [addressChecked, setAddressChecked] = useState(true);
  const [disableCompanyDetailsButton, setDisableCompanyDetailsButton] = useState(true);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [newAddressAdded, setNewAddressAdded] = useState(false);

  const [brandUrl, setBrandUrl] = useState('')
  const [userUploadedMedia, setUserUploadedMedia] = useState(null as any)

  const [mediaUrls, setMediaUrls] = useState([])

  const [userSelectedMedia, setUserSelectedMedia] = useState(null)
  const [mediaData, setMediaData] = useState([])
  const [defaultMediaData, setDefaultMediaData] = useState(null as any)
  const [isMediaLoading, setMediaLoading] = useState(false)
  const [inActiveMedia, setInActiveMedia] = useState([])
  const [showDefaultMedia, setShowDefaultMedia] = useState(false);
  const [selectedMediaForAction, setSelectedMediaForAction] = useState([])
  // const [selectedActionIndex, setSelectedActionIndex] = useState(null)
  const [optionsBtnAnchorEl, setOptionsBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const fileInputRef = useRef(null);

  const [disableAddAddress, setDisableAddAddress] = useState(true);
  
  const [codeGroupChecked, setCodeGroupChecked] = useState(false);
  const [openCodeGroupModal, setOpenCodeGroupModal] = useState(false);
  const [newCodeGroup, setNewCodeGroup] = useState(false);
  const [codeGroupData, setCodeGroupData] = useState([])
  const [codeGroupObj, setCodeGroupObj] = useState(null as any)
  const [isModalLoading, setModalLoading] = useState(false)
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);
  const [changeLocationCodeGroup, setChangeLocationProductCodes] = useState(false)
  const [codeGroupModalMode,setCodeGroupModalMode]= useState('')
  const [newCodeGroupConflicts, setNewCodeGroupConflicts] = useState(false)
  const [newGroupCreated,setNewGroupCreated] = useState(false)

  
  // const [deleteForeverDialogOpen, setDeleteForeverDialogOpen] = useState(false)
  // const [deletedImageUrl, setDeletedImageUrl] = useState('')

  // const [markAsDefaultImage, setMarkAsDefaultImage] = useState(null as any)
  // const [markAsInActive, setMarkAsInActive] = useState(null as any)

  const [expanded, setExpanded] = useState({ codeGroupPanel: true });

  const handleChange = (e, panel: 'codeGroupPanel') => {
    const xpanel = { ...expanded };
    xpanel[panel] = !xpanel[panel];
    setExpanded(xpanel);
  };


  useEffect(() => {
    console.log("codeGroupData", codeGroupData)
  }, [codeGroupData])

  // useEffect(() => {
  //   console.log("PROPS", props)
  // }, [props]);


  const isEditMode = () => {
    return pageMode && pageMode.type === 'edit_mode';
  };

  useEffect(() => {
    if (!isModalLoading) {
      if (props.match && props.match.params) {
        if (props.match.params.id) {
          setLoading(true);
          getMasterBrandDetail(props.match.params.id)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(data => {
              setLoading(false);
              setBrandData(data)
              setSelectedCompany(data.mp_brand_company)
              if (data.mp_brand_company && isEditMode()) {
                setDisableCompanyDetailsButton(false)
              }
              setOriginalMasterBrand(JSON.parse(JSON.stringify(data)));
              getItemImages(data)
              if (data.code_group_data) {
                setCodeGroupData(data.code_group_data)
              }
            })
            .catch((error: any) => {
              setLoading(false);
              props.showToast({
                message: 'Failed to fetch Master Brand.',
                options: ToastError
              });
            });
        }
      }
    }
  }, [isModalLoading]);

  useEffect(() => {
    if (pageMode.type === 'edit_mode' && selectedCompany != null) {
      setDisableCompanyDetailsButton(false)
    }
    if (pageMode.type === 'view_mode') {
      setDisableCompanyDetailsButton(true)
    }

  }, [pageMode])

  useEffect(() => {
    getMasterCompanyData()
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PaginatedResponse<MasterCompanyList>) => {
        // console.log("results",data)
        setCompanies(data.results);
      })
      .catch((error: any) => {
      });
  }, []);


  // useEffect(() => {
  //   console.log("User-uploaded-imge", userUploadedMedia)
  // }, [userUploadedMedia])


  useEffect(() => {
    if(addressChecked==false) 
    checkEditModeAndCheckbox()
  }, [addressChecked])


  useEffect(() => {
    if (userUploadedMedia) {
      uploadItemImage()
    }
  }, [userUploadedMedia])



  useEffect(() => {
    let defaultImage;
    let inactiveImageUrls;
    if (mediaData.length > 0) {

      let urls = mediaData.map(i => i.media_core ? i.media_core.source : '');
      setMediaUrls(urls)
      // console.log("IMAGE-URLS", urls)

      defaultImage = mediaData.filter(i => i.default === true)
      setDefaultMediaData(defaultImage)
      setUserSelectedMedia(mediaData ? mediaData[0].media_core.source : null)

      // console.log("Default Image", defaultImage)

      inactiveImageUrls = mediaData.filter(i => i.is_active === true)
      setInActiveMedia(inactiveImageUrls)
      // console.log("INACTIVE-IMAGES", inactiveImageUrls)

    }
  }, [mediaData])


  const uploadItemImage = () => {

    setMediaLoading(true)
    const fd = new FormData()


    for (let i = 0; i < userUploadedMedia.length; i++) {

      fd.append('files', userUploadedMedia[i], userUploadedMedia[i].name)
      fd.append('item_category', String(ItemCategory.IMAGE))
    }

    //console.log("form-data", fd)

    uploadMasterItemImage(fd)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        createItemMediaLinks(res)
      })
      .catch((error: any) => {
        setUserUploadedMedia(null)

      });
  }

  const createItemMediaLinks = (mediaCoreData: any) => {

    let data = []

    for (let i = 0; i < userUploadedMedia.length; i++) {

      let obj = {}

      obj['item_id'] = brandData && brandData.id
      obj['default'] = mediaData && mediaData.length > 0 ? false : mediaCoreData && mediaCoreData.length == 1 ? true : mediaCoreData && mediaCoreData.length > 1 && i == 0 ? true : false
      obj['item_type'] = ItemType.BRAND
      obj['item_category'] = ItemCategory.IMAGE
      obj['is_active'] = true
      obj['is_deleted'] = false
      obj['media_core_id'] = mediaCoreData[i].id && mediaCoreData[i].id
      data.push(obj)
    }

    let payload = { 'item_data': data }

    //console.log("payload", payload)

    createMediaLinkItem(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        setUserUploadedMedia(null)
        setMediaLoading(false)
        getItemImages()
        data.failure.length > 0 ?
          props.showToast({
            message: 'Failed to store media for this item.',
            options: ToastError
          }) :
          props.showToast({
            message: 'Media stored successfully',
            options: ToastSuccess
          });

      })
      .catch((error: any) => {
        setMediaLoading(false);
        setUserUploadedMedia(null)
      });

  }



  const getItemImages = (data?: any) => {
    setMediaLoading(true)
    let payload = {}
    if (data) {
      payload = { item_id: data.id, item_type: ItemType.BRAND }
    } else {
      payload = { item_id: brandData.id, item_type: ItemType.BRAND }
    }
    getMasterItemImages(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        let result = res.map(r => ({ ...r, checked: false }))
        setMediaData(result)
        setMediaLoading(false)
        setShowDefaultMedia(false)
        setChangeLocationProductCodes(true)

      })
      .catch((error: any) => {
        // let msg = ""
        setShowDefaultMedia(true)
        // setShowDefaultMedia(true)
        setMediaLoading(false)
        setMediaData([])
        setMediaUrls([])
        setDefaultMediaData(null)
        setUserSelectedMedia(null)
        setSelectedMediaForAction([])
        setChangeLocationProductCodes(false)

      });
  }

  const handleSelectedActionIndex = (option: any) => {
    setOptionsBtnAnchorEl(null);
    let selectionAction = option ? option.toLowerCase() : option
    // setMediaLoading(true)
    let selectedMediaActionData = selectedMediaForAction.filter(i => i.checked === true)

    // console.log("selectedMediaActionData", selectedMediaActionData)
    // console.log("Action", selectedMediaForAction)

    let payload = { data: selectedMediaActionData, selected_action: selectionAction }
    // console.log(payload)

    updateMediaBySelectedAction(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master brand media updated successfully!',
          options: ToastSuccess
        });
        setMediaLoading(false);
        setOptionsBtnAnchorEl(null);
        setSelectedMediaForAction([])
        removeSelectedImagesForAction()
        getItemImages()

        // setTimeout(() => navigateBack(), 1200);
      })
      .catch((error: any) => {
        let msg = 'Failed to update brand media.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setMediaLoading(false)
        setOptionsBtnAnchorEl(null);
      });

  }




  const isDataEdited = () => {
    return !_isEqual(originalMasterBrand, brandData);
  };

  const handleDiscardChanges = () => {
    const data = JSON.parse(JSON.stringify(originalMasterBrand));
    setSelectedCompany(data.mp_brand_company)
    setBrandData(data);
  };

  const confirmBackNavigation = () => {
    navigateBack();
  }

  const navigateBack = () => {
    props.history.goBack();
  };

  const handleAddButton = () => {
    setDialogBoxOpen(true);
  }

  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
  }

  const togglePageMode = () => {
    if (pageMode.type === 'view_mode') {
      setPageMode({ type: 'edit_mode' });
    } else {
      setPageMode({ type: 'view_mode' });
    }
  };

  const handleNameChange = (name: string) => {
    setBrandData({ ...brandData, name: name });
  };

  const handleAbbrevChange = (abbrev: string) => {
    setBrandData({ ...brandData, abbrev: abbrev });
  };

  const handleCompanyselectionChange = (value: MasterCompanyList, reason: any) => {
    console.log("selected company name", value)
    setSelectedCompany(value);
    setDisableCompanyDetailsButton(false)
    if (reason == 'clear') {
      setSelectedCompany({});
      setDisableCompanyDetailsButton(true)



    }
  };

  const handleNewCompanyActiveChange = (event: any) => {
    setNewCompanyActive(event.target.value)
  }

  const handleNewCompanyNameChange = (event: any) => {
    // console.log("NEW-BRAND-NAME",event.target.value)
    setNewCompanySelected(true);
    setNewCompanyName(event.target.value)
    let validation = validateCompanyName(event.target.value)
    // console.log("validation", validation)
    if (validation) {
      setNewCompanyNameExists(false)
    }
    else {
      setNewCompanyNameExists(true)
    }

  }



  const handleActiveChange = (event: any) => {
    setBrandData({ ...brandData, active: event.target.checked })
  }


  const validateCompanyName = (data: any) => {
    let filtered = companies ? companies.filter(p => p.name.toLowerCase().split(' ').join('') === data.toLowerCase().split(' ').join('')) : null

    return filtered.length === 0 ? true : false
  }

  const handleUrlCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlChecked(event.target.checked);
  };

  const handleAddressCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressChecked(event.target.checked)
  }

  const handleEmailCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailChecked(event.target.checked)
  }

  const handleCodeGroupCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodeGroupChecked(event.target.checked)
  }



  
  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: any) => {

    // console.log("Option", option)

    // setSelectedActionIndex(index);
    setOptionsBtnAnchorEl(null);

    handleSelectedActionIndex(option)

  };


  const handleAddAddressClick=()=>{
    setOpenAddressDialog(true)
  }


  const handleUploadClick = (e: any) => {
    // console.log(e)
    if (!userUploadedMedia) {
      const el = document.getElementById('import-media');
      el && el.click();

    }
  };


  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  const onFileSelectChangeHandler = (e: any) => {
    if (!userUploadedMedia) {
      if (e.target) {
        if (e.target.files && e.target.files.length > 0) {
          console.log("InputFiles-", e.target.files)
          let files = e.target.files
          setUserUploadedMedia(files);
        }
      }
    }
  };

  const handleSelectedMedia = (data: any) => {
    setUserSelectedMedia(data)
  }

  const handleFileDrop = (files: any, event: any) => {
    event.preventDefault()
    console.log("Dnd-Files---", files)
    files && files.length > 0 ? setUserUploadedMedia(files) : null
  }


  const navigateToMasterCompanyDetailScreen = () => {
    const masterCompanyDetailUrl = MasterDataRouterPaths.masterCompany + '/' + selectedCompany.id;
    props.history.push(masterCompanyDetailUrl, selectedCompany);
  };

  const checkEditModeAndCheckbox=()=>{
    if(addressChecked && isEditMode){
      setDisableAddAddress(true)
    }
    else{
      setDisableAddAddress(false)

    }

  }

  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    setToast({ ...toast, open: false, });
  };

  const handleSave = () => {
    setLoading(true)
    const payload = { ...brandData } as any;

    payload.company = newCompanySelected ? getNewCompany() : selectedCompany ? selectedCompany['id'] : null
    console.log("PAYLOADDD",payload)
    updateMasterBrand(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master Brand updated successfully!',
          options: ToastSuccess
        });
        setLoading(false);
        setPageMode({ type: 'view_mode' });
        setDialogBoxOpen(false)
        setTimeout(() => navigateBack(), 1000);
        setNewCompanyActive("");
        setNewCompanyName("");
        setNewCompanySelected(false);
        setNewCompanyNameExists(false);
        setOpenCodeGroupModal(false)
        handleCloseToast()
      })
      .catch((error: any) => {
        let msg = 'Failed to update master brand.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setLoading(false)
        setDialogBoxOpen(false)
      });

  }

  const getNewCompany = () => {
    let obj = {}
    obj["name"] = !newCompanyNameExists ? newCompanyName : null
    obj["active"] = newCompanyActive && newCompanyActive === '1' ? true : newCompanyActive === '0' ? false : null
    obj["domain"] = null
    obj["phone"] = null
    obj["address_anchor"] = null
    // console.log("obj", obj)
    return obj;
  }

  const getImagePath = () => {
    const imagePath = '/assets/img-default.png';
    return window.location.origin + imagePath;
  };


  const renderAddCompanyDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogBoxOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add a new company</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              <div className={classes.dialogContent} >
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  value={newCompanyName != null ? newCompanyName : null}
                  onChange={handleNewCompanyNameChange}
                  label="Company name"
                  type="text"
                  style={{ width: '12rem' }}
                  error={newCompanyNameExists}
                  helperText={newCompanyNameExists ? "Already exists" : null}
                />
                <FormControl >
                  <InputLabel
                    id="select-label"
                  >
                    Active
                  </InputLabel>
                  <Select
                    value={newCompanyActive ? newCompanyActive : null}
                    onChange={handleNewCompanyActiveChange}
                    className={classes.select}
                  >
                    <MenuItem value={'1'}>Yes</MenuItem>
                    <MenuItem value={'0'}>No</MenuItem>
                  </Select>
                </FormControl>
                {
                  isLoading &&
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogBoxCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleSave()} style={{ color: "#ff1744" }} disabled={newCompanyNameExists} >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }



  const showMasterCompany = () => {
    return (
      <Grid container className={classes.rowComponents}>
        <FormControl className={classes.formControl} style={{ maxWidth: '26rem' }} >
          <Autocomplete
            id='company-list'
            options={companies}
            getOptionLabel={(s) => s.name != null ? s.name : ''}
            size={'small'}
            getOptionSelected={(option: MasterCompanyList, value: string | MasterCompanyList) => {
              if (typeof value !== 'string') {
                return value && option ? option.name === value.name : false;
              }
              return false;
            }}
            value={selectedCompany ? selectedCompany : ''}
            onChange={(event, value, reason) => { handleCompanyselectionChange(value, reason) }}
            renderInput={(params) => (
              <TextField {...params} label="Company" variant="outlined" margin="normal" />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            disabled={!isEditMode()}
          />

        </FormControl>
        <Tooltip title="Edit Company">
          <IconButton
            size="small"
            onClick={navigateToMasterCompanyDetailScreen}
            disabled={disableCompanyDetailsButton}
            style={{ opacity: disableCompanyDetailsButton ? 0.5 : 1.5 }}
          >
            <SvgIcon htmlColor={GlobalStyles.primaryColor} ><path d={SVG_ICONS.bulletedList} /></SvgIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Company">
          <IconButton
            size="small"
            onClick={handleAddButton}
            disabled={!isEditMode()}
            style={{ opacity: !isEditMode() ? 0.5 : 1.5 }}
          >
            <AddBoxIcon color={'primary'} />
          </IconButton>
        </Tooltip>
      </Grid>
    )
  }

  




  const renderPageActions = () => {
    return (
      <>
        {
          isEditMode() &&
          isDataEdited() &&
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            // startIcon={<SaveIcon />}
            onClick={() => handleDiscardChanges()}
          >Discard Changes</Button>
        }
        {
          isEditMode() &&
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            style={{ marginRight: 0 }}
            startIcon={<SaveIcon />}
            onClick={() => handleSave()}
          >Save</Button>
        }
      </>
    )
  }


  const showMetaData = () => {
    return (
      <>
        <div style={{ justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
            <TextField
              id="id"
              className={classes.textFieldCardRow}
              style={{ marginTop: 16, flex: 1 }}
              label="ID"
              margin="dense"
              variant="outlined"
              value={brandData ? brandData.id : ''}
              InputProps={{
                disabled: true,
              }}
            />
            <div style={{ marginLeft: '30px' }}>
              <FormControlLabel
                style={{ flex: 1, marginTop: 8 }}
                control={
                  <Switch
                    checked={brandData ? brandData.active : false}
                    onChange={handleActiveChange}
                    color="primary"
                    style={{ paddingLeft: "10px" }}
                    disabled={!isEditMode()}
                  />
                }
                label="Active"
              // disabled={!isEditMode()}
              />
            </div>
          </div>
        </div>
        <TextField
          id="name"
          className={classes.textFieldCardRow}
          label="Name"
          margin="dense"
          variant="outlined"
          value={brandData ? brandData.name : ''}
          onChange={(event) => handleNameChange(event.target.value)}
          onBlur={() => {
            if (isEditMode()) {
              if (brandData && brandData.name.length === 0) {
                setNameError({
                  isError: true,
                  message: 'Name cannot be blank.'
                });
              } else {
                setNameError({
                  isError: false,
                  message: ''
                });
              }
            }
          }}
          error={nameError.isError}
          helperText={nameError.message}
          InputProps={{
            readOnly: !isEditMode(),
            disabled: !isEditMode()
          }}
        />

        <TextField
          id="abb"
          className={classes.textFieldCardRow}
          label="Abbreviation"
          margin="dense"
          variant="outlined"
          value={brandData ? brandData.abbrev : ''}
          onChange={(event) => handleAbbrevChange(event.target.value)}
          InputProps={{
            readOnly: !isEditMode(),
            disabled: !isEditMode()
          }}
        />
      </>
    )
  }


  const showBrandUrl = () => {
    return (
      <Grid container className={classes.rowComponents}>
        <div style={{ width: '100%', display: 'flex' }} >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'column' }}>
            {/* <Box style={{ border: '1px solid rgba(0, 0, 0, .125)' }}> */}
            <TextField
              id="brand-url"
              className={classes.textFieldCardRow}
              style={{ marginTop: 16, flex: 1 }}
              label="URL"
              margin="dense"
              variant="outlined"
              value={brandUrl ? brandUrl : ''}
              onChange={(e) => {
                setBrandUrl(e.target.value)
              }}
              InputProps={{
                disabled: urlChecked ? true : !isEditMode() ? true : false

              }}
            />
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }} >
              <Checkbox
                checked={urlChecked}
                onChange={handleUrlCheckBoxChange}
                color="primary"
                disabled={!isEditMode()}
                style={{ marginLeft: '10px' }}
              // inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Typography style={{ fontSize: '0.8rem', marginLeft: '3px', marginTop: '6px', opacity: !isEditMode() ? 0.5 : 1.5 }}>{`(Checked = Use data from parent company)`} </Typography>
            </div>
            {/* </Box> */}
          </div>

        </div>
      </Grid>
    )
  }

  const showBrandEmail = () => {
    return (
      <Grid container className={classes.rowComponents}>
        <div style={{ width: '100%', display: 'flex' }} >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
            <TextField
              id="brand-url"
              className={classes.textFieldCardRow}
              style={{ marginTop: 16, flex: 1 }}
              label="Email"
              margin="dense"
              variant="outlined"
              value={''}
              InputProps={{
                disabled: emailChecked ? true : !isEditMode() ? true : false
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '10px' }} >
              <Checkbox
                checked={emailChecked}
                onChange={handleEmailCheckBoxChange}
                color="primary"
                disabled={!isEditMode()}
                style={{ marginLeft: '10px' }}
              // inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <Typography style={{ fontSize: '0.8rem', marginLeft: '3px', marginTop: '6px', opacity: !isEditMode() ? 0.5 : 1.5 }} >{`(Checked = Use data from parent company)`} </Typography>
            </div>
          </div>
        </div>

      </Grid>
    )
  }



  const showBrandAddresses = () => {
    return (
      <Grid container className={classes.rowComponents}>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '5px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="caption" display="block" style={{ opacity: !isEditMode() ? 0.5 : 1.5, fontSize: '0.95rem', paddingTop: '3px' }}>Address</Typography>
              <div style={{ marginLeft: '10px' }}>
                <Checkbox
                  checked={addressChecked}
                  onChange={handleAddressCheckBoxChange}
                  color="primary"
                  disabled={!isEditMode()}
                // inputProps={{ 'aria-label': 'secondary checkbox' }}
                />

              </div>
              <Typography style={{ fontSize: '0.8rem', marginLeft: '3px', marginTop: '6px', opacity: !isEditMode() ? 0.5 : 1.5 }} >{`(Checked = Use data from parent company)`} </Typography>

            </div>
            <div style={{ position: 'relative', bottom: '-10px', right: '2px' }}>
              <Button variant="contained" color="primary"
                disabled={disableAddAddress}
                onClick={handleAddAddressClick}
              >
                Add Address
              </Button>

            </div>
          </div>

        </div>
      </Grid>
    )
  }

  const handleSelectedMediaForAction = (data: any) => {
    let selectedImgArr = []

    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex((obj => obj.id == data.id));
    // console.log("ID-FOUND", imageDataArr[checked])
    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : imageDataArr[checked].checked = true
    // console.log("CONVERTED-CHECKED", imageDataArr)
    setMediaData(imageDataArr)
    setSelectedMediaForAction(imageDataArr)
  }

  const removeSelectedImagesForAction = () => {
    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex(i => i.checked ? i.checked === true : null)

    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : null
    // console.log("filteredArr", imageDataArr)
    setMediaData(imageDataArr)

  }




  // const getHighlightedThumbnail= (url)=>{
  //   if (defaultMediaData){
  //     if (defaultMediaData[0].source ){
  //       let result = defaultMediaData[0].source === url ? url : null
  //       return result
  //     }
  //   }
  //   else if (userSelectedMedia){
  //     let result = userSelectedMedia === url ? url : null
  //     return result
  //   }

  // }
  // defaultMediaData ? defaultMediaData.source ?  defaultMediaData.source === mediaUrls[i] ? '1px solid #007bff' : userSelectedMedia ? userSelectedMedia === mediaUrls[i] ? '1px solid #007bff' : '': '':'':''

  // const handleClickDeleteIcon = (data: any) => {
  //   setDeleteForeverDialogOpen(true)
  //   setDeletedImageUrl(data)
  // }

  // const handleClickHideIcon = (data: any) => {
  //   // console.log("HIDE-ICON-URL", data)
  // }

  // const handleChangeMarkAsDefault = (defaultImage: any) => {
  //   setMarkAsDefaultImage(defaultImage)
  // }




  // const getInactiveIcon =(data: any)=>{
  //   return(
  //     <IconButton id={`hide-icon-${data.source}`} style={{ color: GlobalStyles.primaryColor }} size='small' onClick={() => handleClickHideIcon(data.source)}>
  //       <VisibilityOffIcon />
  //     </IconButton>
  //   )
  // }

  // const handleMoreOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setOptionsBtnAnchorEl(event.currentTarget);
  // };

  // const handleDeleteDialogCancel = () => {
  //   setDeleteForeverDialogOpen(false)
  // }
  

  const handleCloseCodeGroupDialog = () => {
    setOpenCodeGroupModal(false);
    setCodeGroupObj(null)
    handleCloseToast()
  };


  const handleCodeModalOpen= (mode: string, codeObj: any) =>{
    let obj = {}
    if (mode === 'add' && !codeObj) {
      // obj['new_id'] = 
      obj['brand'] = brandData && brandData.id
      obj['code_block_end'] = ""
      obj['code_block_start'] = ""
      obj['company'] = null
      setNewCodeGroup(true)
    } else {
      obj = codeObj 
    }

    setCodeGroupObj(obj)
    setOpenCodeGroupModal(true)
    setCodeGroupModalMode(mode)
    

  }


  const handleStartCodeBlock = (e: any) => {
    
    let val = e.target ? e.target.value  : '';
    console.log("startcode", val )
    let obj = { ...codeGroupObj , code_block_start: val}
    setCodeGroupObj(obj)

    let codeData  =  codeGroupData.map(i=>i)
    let index = codeData.findIndex(c=> c.id === codeGroupObj.id)
    console.log("index-Start", index) 
    if (index != -1 ){
      let editedObj = codeData[index]
      editedObj['code_block_end'] = val
      codeData[index] = editedObj
    } 
    // codeData.push(obj)
    setCodeGroupData(codeData)
  }

  const handleEndCodeBlock = (e: any) => {
    let val = e.target ? e.target.value  : '';
    let obj = { ...codeGroupObj , code_block_end: val}
    setCodeGroupObj(obj)

    let codeData  =  codeGroupData.map(i=>i)
    let index = codeData.findIndex(c=> c.id === codeGroupObj.id)
    if (index != -1){
      let editedObj = codeData[index]
      editedObj['code_block_end'] = val
      codeData[index] = editedObj
    } 
    // codeData.push(obj)
    setCodeGroupData(codeData)

  }

  

  const getCodeGroupExpansion = () => {
    return (
      <div style={{ marginTop: '30px' }}>
        <ExpansionPanel
          expanded={expanded.codeGroupPanel}
        >
          <ExpansionPanelSummary
            expandIcon={
              <IconButton onClick={(event) => handleChange(event, 'codeGroupPanel')}>
                <ExpandMoreIcon />
              </IconButton>
            }
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <Typography className={classes.panelHeading}>
                Code Group&nbsp;&nbsp;-&nbsp;&nbsp;
                {`(${brandData && brandData.code_group_data ? brandData.code_group_data.length : 0})`}
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} >
                {
                  isEditMode() &&
                  <>
                    <Typography style={{ fontSize: '0.8rem', marginTop: '10px' }} >{`(Checked = Use data from parent company)`} </Typography>
                    <Checkbox
                      checked={codeGroupChecked}
                      onChange={handleCodeGroupCheckBoxChange}
                      color="primary"
                      disabled={!isEditMode()}
                    />

                    <Tooltip title="Add Code">
                      <IconButton style={{ padding: 8 }}
                        onClick={() => handleCodeModalOpen('add', null)}
                        disabled={codeGroupChecked ? true : !isEditMode() ? true : false}
                      >
                        <AddBoxIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.panelCol}>
              <div className={classes.panelRowHeader}>
                {
                isEditMode() &&
                <Typography className={classes.panelHeaderText}
                  style={{ textAlign: 'center', minWidth: '80px' }}
                  >
                  Actions
                </Typography>
              }
                {isEditMode() && <Typography style={{ textAlign: 'center', minWidth: '80px' }} > </Typography>}
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 2 }} >Start</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 2 }}>End</Typography>
              </div>
              <div className={classes.panelCol}>
                {
                  brandData &&
                    brandData.code_group_data &&
                    brandData.code_group_data.length > 0 ?
                    brandData.code_group_data.map(codeData =>
                      <div key={String(codeData.id)}>
                        <Divider />
                        <div className={classes.panelRow}>
                          {
                          isEditMode() &&
                          <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <Tooltip title="Edit">
                              <IconButton
                                className={classes.rowActionBtn}
                                disabled={codeGroupChecked ? true : !isEditMode() ? true : false}
                                onClick={() => handleCodeModalOpen('edit', codeData)}
                                >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                           
                          </div>
                        }
                          <Typography className={classes.panelRowText}
                            style={{ flex: 2, marginLeft: isEditMode() ? '35px' : null }}>
                            {codeData.code_block_start}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            align="left"
                            style={{ flex: 2 }}>
                            {codeData.code_block_end}
                          </Typography>
                        </div>
                      </div>
                    ) : null
                }
                {
                  brandData &&
                  !brandData.code_group_data &&
                  <div className={classes.noDataRow}>
                    <Typography align='center'>No Codes</Typography>
                  </div>
                }
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }


  const handleUpdateCodeGroup = (codeData: any) => {

    console.log("Code-Object-UPDATE", codeData)

    setModalLoading(true)
    let data = {
      item_type: ItemType.BRAND,
      code_group_data: codeData,
      mode: 'update'
    }
    let payload = data
    handleAddOrUpdateCodeGroup( payload , 'update' )

  }



  const handleSaveCodeGroup = (codeData: any) => {

    console.log("Code-Object-SAVE", codeData)
    setNewGroupCreated(true)
    setModalLoading(true)
    let data = {
      item_type: ItemType.BRAND,
      code_group_data: codeData,
      mode: 'save'
    }
    let payload = data
    handleAddOrUpdateCodeGroup( payload , 'save' )
    
  }

  const handleAddOrUpdateCodeGroup = (payload: any, mode: string) => {

    createOrUpdateMasterCodeGroup(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setModalLoading(false)
        setToast({ open: true, message: `Code Group was successfully ${mode === 'save' ? 'created' : 'updated'}`, variant: 'success' })
        setTimeout(() => {
          setNewGroupCreated(false)
          handleCloseCodeGroupDialog();
        }, 2000)

        // tableRef.current && tableRef.current.onQueryChange()
      })
      .catch((error: any) => {

        if (error && error.status) {
          if (error.status === 409) {
            setNewCodeGroupConflicts(true)
            setToast({ open: true, message: 'Code Group conflicts. Please change the values', variant: 'error' })
          }

        }
        setNewGroupCreated(false)
        setModalLoading(false)

      });
  }




  return (
    <div>
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div>
          <Card className={classes.card}>
            <CardContent>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '8px',
                borderBottom: '2px solid #adb5bd'
              }}>
                <div style={{ display: 'flex' }}>
                  <IconButton aria-label="back"
                    onClick={confirmBackNavigation}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant='h6'
                    className={classes.title}>Master Brand Detail</Typography>
                </div>
                <div style={{ alignSelf: 'center' }}>
                  <Tooltip title="Toggle Page Mode">
                    <IconButton
                      style={{
                        color: isEditMode() ? '#ffb300' : GlobalStyles.primaryColor,
                        border: '2px solid currentColor',
                        borderRadius: '4px',
                        padding: '4px'
                      }}
                      onClick={() => togglePageMode()}>
                      {isEditMode() ? <EditIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '8px' }}>
                {renderPageActions()}
              </div>
              <Grid container spacing={2} style={{ paddingTop: 4 }}>
                <Grid item xs={6}>
                  {/* <Grid item xs={12} sm={12} md={4} lg={6} xl={6}> */}
                  {
                    <>
                      <Grid container className={classes.row}>
                        {
                          brandData ?
                            <>
                              {showMetaData()}
                              {showMasterCompany()}
                              {showBrandUrl()}
                              {showBrandEmail()}
                              {showBrandAddresses()}
                            </> : null
                        }
                      </Grid>
                      {changeLocationCodeGroup && getCodeGroupExpansion()}
                    </>
                  }
                </Grid>
                <Grid item xs={6}>
                  {
                    isMediaLoading &&
                    <div className={classes.rowCenter}>
                      <CircularProgress />
                    </div>
                  }
                  {!isMediaLoading &&
                    <>

                      <div className={classes.dnd}>
                        <FileDrop
                          onDrop={(files, event) => handleFileDrop(files, event)}
                          onTargetClick={onTargetClick}
                        >
                          Drop files here to upload
                        </FileDrop>
                      </div>
                      <Image
                        itemData={brandData ? brandData : null}
                        mediaUrls={mediaUrls ? mediaUrls.length > 0 ? mediaUrls : null : null}
                        mediaData={mediaData ? mediaData.length > 0 ? mediaData : null : null}
                        handleUploadClick={handleUploadClick}
                        showDefaultMedia={showDefaultMedia ? showDefaultMedia : null}
                        defaultMediaData={defaultMediaData ? defaultMediaData : null}
                        userUploadedMedia={userUploadedMedia ? userUploadedMedia : null}
                        handleSelectedMediaForAction={handleSelectedMediaForAction}
                        handleSelectedMedia={handleSelectedMedia}
                        userSelectedMedia={userSelectedMedia ? userSelectedMedia : null}
                        onFileSelectChangeHandler={onFileSelectChangeHandler}
                        selectedMediaForAction={selectedMediaForAction ? selectedMediaForAction : null}
                        handleMenuItemClick={handleMenuItemClick}
                        optionsBtnAnchorEl={optionsBtnAnchorEl}
                        fileInputRef={fileInputRef}
                      />
                    </>
                  }
                  {!changeLocationCodeGroup &&
                    getCodeGroupExpansion()
                  }
                  {/* {!isMediaLoading &&
                    <>
                      
                    <FileDrop
                      onDrop={(files, event) => handleFileDrop(files, event)}
                      onTargetClick={onTargetClick}
                    >
                      <div className={classes.dnd}>
                        <Image
                          itemData={brandData ? brandData : null}
                          mediaUrls={mediaUrls ? mediaUrls.length > 0 ? mediaUrls : null : null}
                          mediaData={mediaData ? mediaData.length > 0 ? mediaData : null : null}
                          handleUploadClick={handleUploadClick}
                          showDefaultMedia={showDefaultMedia ? showDefaultMedia : null}
                          defaultMediaData={defaultMediaData ? defaultMediaData : null}
                          userUploadedMedia={userUploadedMedia ? userUploadedMedia : null}
                          handleSelectedMediaForAction={handleSelectedMediaForAction}
                          handleSelectedMedia={handleSelectedMedia}
                          userSelectedMedia={userSelectedMedia ? userSelectedMedia : null}
                          onFileSelectChangeHandler={onFileSelectChangeHandler}
                          selectedMediaForAction={selectedMediaForAction ? selectedMediaForAction : null}
                          handleMenuItemClick={handleMenuItemClick}
                          optionsBtnAnchorEl={optionsBtnAnchorEl}
                          fileInputRef={fileInputRef}
                        />
                      </div>
                    </FileDrop>
                      
                    </>
                  } */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      }
      {
        dialogBoxOpen &&
        renderAddCompanyDialogBox()
      }
      {/* {renderDialog()} */}
      <AddAddress
        party_id={AddressPartyType.BUSINESS}
        isOpen={openAddressDialog}
        entity_id={props.match.params.id}
        setDialogBoxState={setOpenAddressDialog}
        setNewAddressAdded={setNewAddressAdded}
        sub_party_type={AddressSubPartyType.MasterBrand}
      />
      <div>
        <AddressDetails
          entity_id={props.match.params.id}
          party_id={AddressPartyType.BUSINESS}
          newAddressAdded={newAddressAdded}
          setNewAddressAdded={setNewAddressAdded}
          isEditMode={isEditMode}
          sub_party_type={AddressSubPartyType.MasterBrand}
        />
      </div>

      <MasterCodeGroupModal
        isOpen={openCodeGroupModal }
        onClose={handleCloseCodeGroupDialog}
        mode={codeGroupModalMode ? codeGroupModalMode : null}
        codeData={ codeGroupObj ? codeGroupObj : null}
        onUpdate={(codeData) => handleUpdateCodeGroup(codeData)}
        onSave={(codeData) => handleSaveCodeGroup(codeData)}
        itemData={brandData ? brandData : null}
        toast= {toast ? toast : null}
        handleEndCodeBlock={handleEndCodeBlock}
        handleStartCodeBlock={handleStartCodeBlock}
        codeGroupConflicts={ newCodeGroupConflicts }
        isLoading={isModalLoading}
        blockError={codeGroupObj && codeGroupObj.code_block_end.length > 0 && ((codeGroupObj.code_block_start.length > codeGroupObj.code_block_end.length) || (codeGroupObj.code_block_start.length < codeGroupObj.code_block_end.length) || (Number(codeGroupObj.code_block_start) > Number(codeGroupObj.code_block_end))) === true ? true : false }
        handlResetToast={handleCloseToast}
        newGroupCreated={newGroupCreated}
      />

    </div>
  )
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterBrandDetailScreen);
