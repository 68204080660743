import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MaterialTable, { MTableToolbar } from 'material-table';
import { Typography, Snackbar, SvgIcon } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { StockReceipt } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';
import { Filter, FilterType } from '../../../shared/models/Filter';

import { getStockReceiptsByPage, getKoronaSuppliersList } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { getProductPriceForPos } from 'components/src/modules/products/lib/price';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import { getMasterCompaniesFullList } from '../../../master-data/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  }),
);

const FILTERS: Filter[] = [
  {
    id: 'f1',
    label: 'Suppliers',
    name: 'KSUPPLIERS',
    types: [
      { id: '1', value: 'korona_supp_uuids_in', label: 'is equal' },
      { id: '2', value: 'korona_supp_uuids_not_in', label: 'is not equal' },
    ],
    selectedType: 'korona_supp_uuids_in',
    value: [],
    url: 'korona/supplier-list',
    labelField: 'name',
    bindValue: 'uuid',
    isResponsePaginated: true,
    data: null
  },

]

interface LocationState {
  redirected?: boolean;
}



const SuggestedPriceByMarginScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();

  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [updatedFilters, setUpdatedFilters] = useState(null as any)
  const [koronaSuppliersList, setFilterKoronaSuppliersList] = useState(null)
  const location = useLocation<LocationState>();

  useEffect(() => {
    if (location.state && location.state?.redirected) {
      console.log('This page was redirected');
      window.location.reload();
    }
  }, [location]);

  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);

  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onChangePage(null, 0);
    }
  }, [filterString]);

  useEffect(() => {
    const sb = props.userData.selectedBusiness;
    let payload = { buss_id: sb.business_id }
    getKoronaSuppliersList(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        // console.log("results",data)
        setFilterKoronaSuppliersList(data);
      })
      .catch((error: any) => {
      });
  }, [props.userData.selectedBusiness])


  useEffect(() => {
    if (koronaSuppliersList) {
      if (koronaSuppliersList.length > 0) {
        let currentFilters = [...FILTERS]
        let suppFilterIndexFound = currentFilters.findIndex(cf => cf.name === 'KSUPPLIERS')
        if (suppFilterIndexFound > -1) {
          currentFilters[suppFilterIndexFound].data = koronaSuppliersList
        }
        setUpdatedFilters([...currentFilters])
      }
    }

  }, [koronaSuppliersList])




  useEffect(() => {
    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue))
    );
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      // let isChoiceFilter = !f.url && !Array.isArray(f.value) && !f.selectedType.includes('range')&& Array.isArray(f.choices)
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }
      // if (isChoiceFilter) {
      //     filterString += `&${f.selectedType}=${f.value}`;
      //     console.log("CHOICE-FILTER-STRING",filterString)
      // }
      else if (!isRangeFilter) {

        // value is string
        filterValue = f.value.toString();

      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else {
        filterString += `&${f.selectedType}=${filterValue}`;
      }
    });
    setFilterString(filterString);
  }, [selectedFilters]);

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    // console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };


  const navigatetoSuggestedPriceByMarginSRDetails = (rowData: any) => {
    const stockReceiptDetailUrl = '/web/stock-receipts/sugg-price-by-margin/detail/' + rowData.uuid;

    props.history.push(stockReceiptDetailUrl, rowData);
  };



  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <div style={{ maxWidth: '100%' }}>
        <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: true
          }}
          components={{
            Toolbar: (props) => (
              <>
                <MTableToolbar {...props} />
                {filterVisible && (
                  <>
                    <FilterRow
                      {...props}
                      renderAvailableFilters={
                        <AvailableFilters
                          filters={updatedFilters ? updatedFilters : FILTERS}
                          addFilter={addFilter}
                        />
                      }
                      renderSelectedFilters={
                        <SelectedFilters
                          selectedFilters={selectedFilters}
                          removeFilter={removeFilter}
                          updateFilter={updateFilter}
                          businessId={null}
                        />
                      }
                    />
                  </>
                )}
              </>
            ),
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage
            }
          }}
          columns={[
            {
              title: 'Date',
              field: 'createTime',
              render: rowData => (
                rowData.createTime ?
                  <span>{moment(rowData.createTime).format('YYYY-MM-DD HH:mm')}</span>
                  : <span></span>
              ),
            },
            { title: 'Number', field: 'number' },
            {
              title: 'Items',
              field: 'items_count',
              render: rowData => {
                if (rowData && rowData.items_count) {
                  return <span>{rowData.items_count}</span>;
                }
                return <span></span>;
              },
            },
            {
              title: 'Warehouse',
              field: 'target',
              render: rowData => {
                if (rowData && rowData.target && rowData.target.name) {
                  return <span>{rowData.target.name}</span>;
                }
                return <span></span>;
              }
            },
            {
              title: 'Supplier',
              field: 'supplier',
              render: rowData => {
                if (rowData && rowData.supplier && rowData.supplier.name) {
                  return <span>{rowData.supplier.name}</span>;
                }
                return <span></span>;
              },
            }
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              if (!isLoading) {
                const sb = props.userData.selectedBusiness;

                if (sb && sb.business_id) {
                  replaceHistory(query, props.history);
                  let pageData = {
                    data: [] as StockReceipt[],
                    totalCount: 0,
                    page: query.page
                  };
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    if (orderBy == 'supplier') {
                      orderBy = 'supplier__name';
                    }
                    if (orderBy == 'target') {
                      orderBy = 'target__name';
                    }
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    console.log("DIRECTION", orderBy)
                  }
                  setLoading(true);

                  getStockReceiptsByPage(
                    sb.business_id,
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    orderBy,
                    filterString
                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<StockReceipt>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      setLoading(false);
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      setLoading(false);
                      resolve(pageData);
                    });
                }
              }
            })
          }
          onRowClick={(event, rowData) => navigatetoSuggestedPriceByMarginSRDetails(rowData)}

          title={
            <CustomTableTitle
              history={props.history}
              title={'Suggested Price By Margin SR List'}
              icon={SVG_ICONS.stockReceipt} />
          }
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
            {
              icon: 'filter_list',
              tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
              isFreeAction: true,
              iconProps: {
                color: filterVisible ? 'primary' : 'inherit',
              },
              // hidden: !isSuperAdmin(props),
              onClick: toggleFilterPanel,
            },
          ]}
        />
      </div>
    </div>
  );
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(SuggestedPriceByMarginScreen);