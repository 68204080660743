import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Typography, Select, Menu, MenuItem, FormControl, InputLabel, Input, Link, SvgIcon,
  Card, CardContent, CircularProgress, Divider, FormControlLabel, Grid, TextField,
  IconButton, Checkbox, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, ListSubheader, LinearProgress, Button, Snackbar, Icon, Switch, Collapse,
  List, ListItem, ListItemText, ListItemIcon

} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { ItemType, MasterCompanyList } from '../../models';
import {
  createOrUpdateCompanyProductAttributeValues, getCompanyProductDetail, getMasterCompanyData, updateCompanyProduct
} from '../../api';
import { CompanyProductAttributeModal } from '../../components'
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { PaginatedResponse, ToastType } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ConfirmModal } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import _isEqual from 'lodash/isEqual';
import { blue, orange, red } from '@material-ui/core/colors';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import Autocomplete from '@material-ui/lab/Autocomplete';
import unionBy from 'lodash/unionBy'
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy';
import StarRateIcon from '@material-ui/icons/StarRate';
import DeleteIcon from '@material-ui/icons/Delete';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },

    button: {
      margin: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textFieldCardRow: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },

    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },

    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    rowComponents: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },

    inActiveIcon: {
      color: 'black',
      fontSize: '2rem',
      cursor: 'pointer',
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'

    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
    },
    rowActionBtn: {
      padding: 8
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },

    switchTrack: {
      backgroundColor: "#f50057",
    },
    switchBase: {
      color: "#f50057",
      "&.Mui-disabled": {
        color: "#e886a9"
      },
      "&.Mui-checked": {
        color: GlobalStyles.green
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#4CAF50",
      }
    },
    switchPrimary: {
      "&.Mui-checked": {
        color: GlobalStyles.green,
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#4CAF50",
      },
    },

    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },

    nested: {
      paddingLeft: theme.spacing(4),
    },

  }),
);




const linkedProductAtributesPanel = 'linkedProductAtributesPanel';
const multipleAtributesInvoiceItemName = 'multipleAtributesInvoiceItemName';
const multipleAtributesReorderCode = 'multipleAtributesReorderCode';
const multipleAtributesOrderText = 'multipleAtributesOrdertext'


const ConfirmModalData = {
  isOpen: false,
  message1: 'You will lose all unsaved changes.',
  message2: ' Are you sure you want to leave this page?',
  title: 'Confirm Navigation',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};


const CompanyProductDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [confirmModalData, setConfirmModalData] = useState(ConfirmModalData);
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [originalCompanyProductData, setOriginalCompanyProductData] = useState(null);
  const [companyProductData, setCompanyProductData] = useState(null);
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);
  const [disableCompanyDetailsButton, setDisableCompanyDetailsButton] = useState(true);
  const [atrributesData, setAttributesData] = useState(null)
  const [openAttributeGroupModal, setOpenAttributeGroupModal] = useState(false);

  const [attributeGroupModalMode, setAttributeGroupModalMode] = useState('')
  const [attributeGroupObj, setAttributeGroupObj] = useState(null as any)
  const [isModalLoading, setModalLoading] = useState(false)
  const [newAttributeCreated, setNewAttributeCreated] = useState(false)

  const [expanded, setExpanded] = useState({ codeGroupPanel: true, linkedProductAtributesPanel: true } as any);
  const handleChange = (panel: 'linkedProductAtributesPanel') => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    const xpanel = { ...expanded };
    xpanel[panel] = isExpanded;
    setExpanded(xpanel);
  }

  const isEditMode = () => {
    return pageMode && pageMode.type === 'edit_mode';
  };

  useEffect(() => {
    console.log("PROPSSSSSSSSS", props)
  }, [props])

  useEffect(() => {
    if (!isModalLoading) {

      if (props.match && props.match.params) {
        if (props.match.params.id) {
          setLoading(true)
          // props.setPageLoader(true);
          getCompanyProductDetail(props.match.params.id)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(data => {
              setCompanyProductData(data);
              setSelectedCompany(data.master_company_data)
              if (data.master_company_data && isEditMode()) {
                setDisableCompanyDetailsButton(false)
              }
              setOriginalCompanyProductData(JSON.parse(JSON.stringify(data)))
              setLoading(false)
            })
            .catch((error: any) => {
              setLoading(false)
              // props.setPageLoader(false);
              props.showToast({
                message: 'Failed to fetch Company Product.',
                options: ToastError
              });
            });
        }
      }
    }
  }, [isModalLoading]);


  // useEffect(() => {
  //   getMasterCompanyData()
  //     .catch(handleError) // handle network issues
  //     .then(checkStatus)
  //     .then(parseJSON)
  //     .then((data: PaginatedResponse<MasterCompanyList>) => {
  //       // console.log("results",data)
  //       setCompanies(data.results);
  //     })
  //     .catch((error: any) => {
  //     });
  // }, []);

  useEffect(() => {
    if (pageMode.type === 'edit_mode' && selectedCompany != null) {
      setDisableCompanyDetailsButton(false)
    }
    if (pageMode.type === 'view_mode') {
      setDisableCompanyDetailsButton(true)
    }

  }, [pageMode])


  const isDataEdited = () => {
    return !_isEqual(originalCompanyProductData, companyProductData);
  };

  const togglePageMode = () => {
    if (pageMode.type === 'view_mode') {
      setPageMode({ type: 'edit_mode' });
    } else {
      setPageMode({ type: 'view_mode' });
    }
  };

  const confirmBackNavigation = () => {
    if (isDataEdited()) {
      setConfirmModalData({
        ...confirmModalData,
        isOpen: true,
        onCancel: () => setConfirmModalData({ ...confirmModalData, isOpen: false }),
        onConfirm: () => {
          setConfirmModalData({ ...confirmModalData, isOpen: false });
          navigateBack();
        }
      });
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  const navigateToMasterCompanyDetailScreen = () => {
    const masterCompanyDetailUrl = MasterDataRouterPaths.masterCompany + '/' + selectedCompany.id;
    props.history.push(masterCompanyDetailUrl, selectedCompany);
  };


  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    setToast({ ...toast, open: false, });
  };



  const renderPageActions = () => {
    return (
      <>
        {
          isEditMode() &&
          isDataEdited() &&
          <Button
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            // startIcon={<SaveIcon />}
            onClick={() => handleDiscardChanges()}
          >Discard Changes</Button>
        }
        {
          isEditMode() &&
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            style={{ marginRight: 0 }}
            startIcon={<SaveIcon />}
            onClick={() => handleSave()}
          >Save</Button>
        }
      </>
    )
  }

  const handleDiscardChanges = () => {
    const data = JSON.parse(JSON.stringify(originalCompanyProductData));
    setSelectedCompany(data.master_company_data);
    setCompanyProductData(data);
  };


  const handleActiveChange = (event: any) => {
    setCompanyProductData({ ...companyProductData, is_active: event.target.checked })
  }

  const handleSave = () => {
    setLoading(true)
    let payload = { ...companyProductData } as any;
    payload['user_details'] = props.userData.userData.userData;

    console.log("PAYLOADDD",payload)
    updateCompanyProduct(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setCompanyProductData(jsonData)
        setOriginalCompanyProductData(JSON.parse(JSON.stringify(jsonData)))
        props.showToast({
          message: 'Company product updated successfully!',
          options: ToastSuccess
        });
        setLoading(false);
        setPageMode({ type: 'view_mode' });
        handleCloseCodeGroupDialog()
      })
      .catch((error: any) => {
        let msg = 'Failed to update company product.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setLoading(false)
        handleCloseToast()

      });
  }

  const showMetaData = () => {
    return (
      <>
        <div style={{ justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
            <TextField
              id="id"
              className={classes.textFieldCardRow}
              style={{ marginTop: 16, flex: 1 }}
              label="ID"
              margin="dense"
              variant="outlined"
              value={companyProductData ? companyProductData.id : ''}
              InputProps={{
                disabled: true,
              }}
            />
            <div style={{ marginLeft: '30px' }}>
              <FormControlLabel
                style={{ flex: 1, marginTop: 8 }}
                control={
                  <Switch
                    checked={companyProductData ? companyProductData.is_active : false}
                    onChange={handleActiveChange}
                    classes={{
                      track: classes.switchTrack,
                      switchBase: classes.switchBase,
                      colorPrimary: classes.switchPrimary,
                    }}
                    style={{ paddingLeft: "10px" }}
                    disabled={!isEditMode()}
                  />
                }
                label="Active"
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  const handleCompanyselectionChange = (value: MasterCompanyList, reason: any) => {
    setSelectedCompany(value);
    setCompanyProductData({ ...companyProductData, master_company_data: value })
    setDisableCompanyDetailsButton(false)
    if (reason == 'clear') {
      setSelectedCompany({});
      setDisableCompanyDetailsButton(true)
    }
  };


  const showMasterCompany = () => {
    return (
      <Grid container className={classes.rowComponents}>
        <FormControl className={classes.formControl} style={{ maxWidth: '26rem' }} >
          <Autocomplete
            id='company-list'
            options={companies}
            getOptionLabel={(s) => s.name != null ? s.name : ''}
            size={'small'}
            getOptionSelected={(option: MasterCompanyList, value: string | MasterCompanyList) => {
              if (typeof value !== 'string') {
                return value && option ? option.name === value.name : false;
              }
              return false;
            }}
            value={selectedCompany ? selectedCompany : ''}
            onChange={(event, value, reason) => { handleCompanyselectionChange(value, reason) }}
            renderInput={(params) => (
              <TextField {...params} label="Company" variant="outlined" margin="normal" />
            )}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name, inputValue);
              const parts = parse(option.name, matches);
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              );
            }}
            disabled={!isEditMode()}
          />

        </FormControl>
        <Tooltip title="Edit Company">
          <IconButton
            size="small"
            onClick={navigateToMasterCompanyDetailScreen}
            disabled={disableCompanyDetailsButton}
            style={{ opacity: disableCompanyDetailsButton ? 0.5 : 1.5 }}
          >
            <SvgIcon htmlColor={GlobalStyles.primaryColor} ><path d={SVG_ICONS.bulletedList} /></SvgIcon>
          </IconButton>
        </Tooltip>
      </Grid>
    )
  }

  const toggleMarkAttributeValuesAsDeleted = (ad) => {
    if (!ad.date_deleted) {
      let curDate = new Date();
      ad.date_deleted = curDate.toISOString().slice(0, 10);
    } else {
      ad.date_deleted = null;
    }
    ad.is_deleted = !ad.is_deleted;
    const prodData = { ...companyProductData };
    const oldAttributesValues = [...prodData.product_attributes_data];
    const newAttributesValues = oldAttributesValues.filter(b => b.id !== ad.id);
    newAttributesValues.push(ad);
    prodData.product_attributes_data = sortBy(newAttributesValues, 'type.value');

    console.log("New-data", prodData)

    setCompanyProductData(cloneDeep(prodData));
  };


  const getReorderCodesAndDescriptionsPanel = () => {
    return (
      <ExpansionPanel
        style={{ marginTop: 5 }}
        expanded={true}
      >

        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          id="linkedProductAtributesPanel"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography className={classes.heading}>Linked Company Product Attributes</Typography>
          </div>
          {
            isEditMode() &&
            <Tooltip title="Add Code">
              <IconButton style={{ padding: 8 }}
                onClick={(event) => handleAttributeModalOpen('add', null)}>
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          }
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.panelCol}>
            <div className={classes.panelRowHeader}>
              {
                isEditMode() &&
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1, textAlign: 'center', minWidth: '80px' }}>Actions</Typography>
              }
              <Typography className={classes.panelHeaderText}
                style={{ flex: 2 }}>Company</Typography>
              <Typography className={classes.panelHeaderText}
                style={{ flex: 2 }}>Type</Typography>
              <Typography className={classes.panelHeaderText}
                style={{ flex: 3 }}>Value</Typography>
              <Typography className={classes.panelHeaderText}
                style={{ flex: 1 }}>Quantity</Typography>
                <Typography className={classes.panelHeaderText}
                style={{ flex: 1 }}>Active</Typography>
            </div>
            <div className={classes.panelCol}>
              {
                companyProductData &&
                companyProductData.product_attributes_data &&
                companyProductData.product_attributes_data.length > 0 &&
                sortBy(companyProductData.product_attributes_data, 'type.value').map((ad: any, index: any) =>
                  <div key={`${String(ad)}-${String(index)}`}>
                    <Divider />
                    <div className={classes.panelRow}>
                      {
                        isEditMode() &&
                        <div style={{ display: 'flex', flex: 1 }}>
                          <Tooltip title="Edit">
                            <IconButton
                              className={classes.rowActionBtn}
                              disabled={!isEditMode() ? true : false}
                              onClick={() => handleAttributeModalOpen('edit', ad)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={ad.is_deleted ? 'Renew' : 'Delete'}>
                            <IconButton style={{
                              color: ad.is_deleted ? GlobalStyles.primaryColor : 'rgba(0, 0, 0, 0.54)'
                            }}
                              className={classes.rowActionBtn}
                              onClick={() => toggleMarkAttributeValuesAsDeleted(ad)}>
                              {
                                !ad.is_deleted &&
                                <DeleteIcon />
                              }
                              {
                                ad.is_deleted &&
                                <AutorenewIcon />
                              }
                            </IconButton>
                          </Tooltip>
                        </div>
                      }
                      <Typography className={classes.panelRowText}
                        style={{ flex: 2 }}>
                        {companyProductData && companyProductData.master_company_data.name}
                      </Typography>
                      <Typography className={classes.panelRowText}
                        style={{ flex: 2 }}>
                        {ad.type.value}
                      </Typography>
                      {
                        ad.primary ? (
                          <Typography className={classes.panelRowText}
                            style={{ flex: 3 }}>
                            <StarRateIcon color='primary' />{ad.value}
                          </Typography>
                        ) :

                          <Typography className={classes.panelRowText}
                            style={{ flex: 3 }}>
                            {ad.value}
                          </Typography>

                      }
                      <Typography className={classes.panelRowText}
                        style={{ flex: 1, marginLeft: 6 }}>
                        {ad.quantity}
                      </Typography>
                      <FiberManualRecordIcon
                        style={{ color: ad.is_deleted ? GlobalStyles.red : GlobalStyles.green }}
                      />
                    </div>
                  </div>
                )
              }
              {
               companyProductData &&
               companyProductData.product_attributes_data &&
               companyProductData.product_attributes_data.length === 0 &&
                <div className={classes.noDataRow}>
                  <Typography align='center'>No Linked Company Product Attributes</Typography>
                </div>
              }
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

    )
  }

  const handleAttributeModalOpen = (mode: string, attrObj: any,) => {
    let obj = {}
    if (mode === 'add' && !attrObj) {
      // obj['new_id'] = 
      obj['master_product_company_instance'] = companyProductData && companyProductData.id
      obj['value'] = ""
      obj['quantity'] = ""
      obj['primary'] = null
      obj['type'] = null


    } else {
      obj = attrObj
    }

    setAttributeGroupObj(obj)
    setOpenAttributeGroupModal(true)
    setAttributeGroupModalMode(mode)

  }


  const handleCloseCodeGroupDialog = () => {
    setOpenAttributeGroupModal(false);
    setAttributeGroupObj(null)
    handleCloseToast()
    setAttributeGroupModalMode('')
  };

  const handleUpdateAttribute = (attrData: any) => {
    console.log("Code-Object-UPDATE", attrData)
    setModalLoading(true)
    attrData['user_details'] = props.userData.userData.userData;
    let data = {
      attr_data: attrData,
      mode: 'update'
    }
    let payload = data
    handleAddOrUpdateAttribute(payload, 'update')

  }


  const handleSaveAttribute = (attrData: any) => {

    console.log("Code-Object-SAVE", attrData)
    setNewAttributeCreated(true)
    setModalLoading(true)
    attrData['user_details'] = props.userData.userData.userData;
    let data = {
      attr_data: attrData,
      mode: 'save'
    }

    let payload = data
    handleAddOrUpdateAttribute(payload, 'save')

  }


  const handleAddOrUpdateAttribute = (payload: any, mode: string) => {

    createOrUpdateCompanyProductAttributeValues(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setModalLoading(false)
        setToast({ open: true, message: `Product attribute was successfully ${mode === 'save' ? 'created' : 'updated'}`, variant: 'success' })
        setTimeout(() => {
          setNewAttributeCreated(false)
          handleCloseCodeGroupDialog();
        }, 1000)

        // tableRef.current && tableRef.current.onQueryChange()
      })
      .catch((error: any) => {

        if (error && error.status) {
          if (error.status === 409) {
            setToast({ open: true, message: 'Product attribute conflicts. Please change the value', variant: 'error' })
          }

        }
        setNewAttributeCreated(false)
        setModalLoading(false)

      });
  }

  return (
    <div>
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div>
          <Card className={classes.card}>
            <CardContent>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '8px',
                borderBottom: '2px solid #adb5bd'
              }}>
                <div style={{ display: 'flex' }}>
                  <IconButton aria-label="back"
                    onClick={confirmBackNavigation}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant='h6'
                    className={classes.title}>Company Product Detail</Typography>
                </div>
                <div style={{ alignSelf: 'center' }}>
                  <Tooltip title="Toggle Page Mode">
                    <IconButton
                      style={{
                        color: isEditMode() ? '#ffb300' : GlobalStyles.primaryColor,
                        border: '2px solid currentColor',
                        borderRadius: '4px',
                        padding: '4px'
                      }}
                      onClick={() => togglePageMode()}>
                      {isEditMode() ? <EditIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '8px' }}>
                {renderPageActions()}
              </div>
              <Grid container spacing={2} style={{ paddingTop: 4 }}>
                <Grid item xs={6}>
                  {
                    <>
                      <Grid container className={classes.row}>
                        {
                          companyProductData ?
                            <>
                              {showMetaData()}
                              {/* {showMasterProduct()} */}
                              {/* {showMasterCompany()} */}
                            </> : null
                        }
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              {getReorderCodesAndDescriptionsPanel()}
            </CardContent>
          </Card>
        </div>
      }

      <CompanyProductAttributeModal
        isOpen={openAttributeGroupModal}
        onClose={handleCloseCodeGroupDialog}
        mode={attributeGroupModalMode ? attributeGroupModalMode : null}
        attrData={attributeGroupObj ? attributeGroupObj : null}
        onUpdate={(attrData) => handleUpdateAttribute(attrData)}
        onSave={(attrData) => handleSaveAttribute(attrData)}
        instanceData={companyProductData ? companyProductData : null}
        toast={toast ? toast : null}
        isLoading={isModalLoading}
        handlResetToast={handleCloseToast}
        newAttributeCreated={newAttributeCreated}
      />

    </div>
  )
};


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProductDetailScreen);