import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MaterialTable from 'material-table';
import {
  Typography, Snackbar, SvgIcon, FormLabel, FormControl, RadioGroup, FormGroup, FormControlLabel,
  Radio, Paper, CircularProgress, TextField
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { StockReceipt } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';

import { getRecentUnfinishedStockOrders } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { ErrorStatusBar, CustomTableTitle } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG , NO_PERMISSIONS_ERROR_MSG} from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
    },
    formContent: {
      display: 'flex',
      flexDirection: 'row'
    },
    listbox: {
      maxHeight: 180
    }
  }),
);

const CreateStockReceiptScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const tableRef = useRef<{ onQueryChange: any; }>();
  const [isLoading, setLoading] = useState(false);
  const [receiptMethod, setReceiptMethod] = useState({
    from_stockOrder: false,
    empty_stockOrder: false
  })
  const [selectedReceipt, setSelectedReceipt] = useState('')
  const [soData, setSoData] = useState(null)
  const [dialogLoading, setDialogLoading] = useState(false)
  const [selectedSO, setSelectedSO] = useState(null)
  const searchTerm = useRef(null);



  useEffect(()=>{
    console.log("props", props)
  }, [props])

  useEffect(()=>{
    console.log("ReceiptMethod", receiptMethod)
  },[receiptMethod.from_stockOrder])

  useEffect(() => {
    if (receiptMethod.from_stockOrder) {
      getRecentSO()
    }
  }, [receiptMethod.from_stockOrder, props.userData.selectedBusiness]);

  const getRecentSO = () => {
    let businessId = props.userData.selectedBusiness && props.userData.selectedBusiness.business_id
    if (businessId) {
      setDialogLoading(true)
      let payload = { business_id: props.userData.selectedBusiness.business_id }

      if (searchTerm.current) {
        payload['search_term'] = searchTerm.current
      }
      getRecentUnfinishedStockOrders(payload)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((data: PaginatedResponse<any>) => {
          console.log("results", data)
          setDialogLoading(false)
          setSoData(data);
        })
        .catch((error: any) => {
          let msg = 'Failed to fetch stock orders';
          if ((error.status === 403 || error.status === 401)) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
          props.showToast({ message: msg, options: ToastError });
          setDialogLoading(false)
        });
    }

  }

  const selectReceiptMethod = () => {
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormLabel component="legend">Create Stock Receipt </FormLabel>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={selectedReceipt != '' ? selectedReceipt : null} onChange={handleChangeSRMethod} className={classes.formContent} >
            <FormGroup style={{ flexDirection: 'row' }}>
              <FormControlLabel value={'from_stockOrder'} control={<Radio />} label="From Stock Order" />
              <FormControlLabel value={'empty_stockOrder'} control={<Radio />} label="Empty Stock Receipt" />
            </FormGroup>
          </RadioGroup>
        </FormControl>
        {/* {
          dialogLoading &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        } */}
      </div>
    )
  }

  const handleChangeSRMethod = (e: any) => {
    e.preventDefault();
    let val = e.target.value
    if (val === 'from_stockOrder') {
      setReceiptMethod({ ...receiptMethod, from_stockOrder: !receiptMethod.from_stockOrder })
    } else if( val === 'empty_stockOrder' ) {
      setReceiptMethod({ ...receiptMethod, empty_stockOrder: !receiptMethod.empty_stockOrder })
    }
    setSelectedReceipt(val);
  }


  const renderMethodSettings =()=>{
    if(receiptMethod && receiptMethod.from_stockOrder && soData ){
      return (
        <Autocomplete
          size="small"
          classes={{ listbox: classes.listbox }}
          style={{ width: '32%' }}
          filterSelectedOptions={true}
          value={selectedSO}
          getOptionSelected={(option, value) => {
            if (typeof value !== 'string') {
              return value && option ? option.number === value.number : false;
            }
            return false;
          }}
          getOptionLabel={(option) =>  `${option.number} | ${option.korona_supplier_name ? option.korona_supplier_name : ''} > ${option.korona_org_name ? option.korona_org_name : ''}`}
          onChange ={(event, value, reason) => { handleSOChange(value, reason) }}
         
          options={soData}  
          loading={dialogLoading}
          renderInput={(params) => (
            <TextField {...params} label="Stock Order" variant="outlined" margin="normal" />
          )}
        />
      )
    }
  }


  const handleSOChange=(value,reason)=>{
    console.log("VALLLL", value)
    if(reason === 'clear'){
      setSelectedSO(null)
    }
    else if(value != null){
      setSelectedSO(value)
    }
    
  }


  return (
    <Paper style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center' }}>
        {selectReceiptMethod()}
        {renderMethodSettings()}
      </div>
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateStockReceiptScreen);