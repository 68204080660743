

export const GET_LABELER_SUMMARY = 'GET_LABELER_SUMMARY';
export const GET_LABELER_SUMMARY_SUCCESS = 'GET_LABELER_SUMMARY_SUCCESS';
export const GET_LABELER_SUMMARY_FAILURE = 'GET_LABELER_SUMMARY_FAILURE';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';
export const GET_LITER_TAX_UUID = 'GET_LITER_TAX_UUID';
export const GET_LITER_TAX_UUID_SUCCESS = 'GET_LITER_TAX_UUID_SUCCESS';
export const GET_LITER_TAX_UUID_FAILURE = 'GET_LITER_TAX_UUID_FAILURE';
export const CLEAR_LABEL_PREVIEW = 'CLEAR_LABEL_PREVIEW';
export const GET_LABEL_PREVIEW = 'GET_LABEL_PREVIEW';
export const GET_LABEL_PREVIEW_SUCCESS = 'GET_LABEL_PREVIEW_SUCCESS';
export const GET_LABEL_PREVIEW_FAILURE = 'GET_LABEL_PREVIEW_FAILURE';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_NETWORK_PRINTER_IP = 'SET_NETWORK_PRINTER_IP';
export const SET_NETWORK_PRINTER_PORT = 'SET_NETWORK_PRINTER_PORT';
export const ENABLE_AUTO_ADJUST_LABEL = 'ENABLE_AUTO_ADJUST_LABEL';
export const SET_PRINT_WIDTH = 'SET_PRINT_WIDTH';
export const SET_REPORT_EMAIL = 'SET_REPORT_EMAIL';
export const SET_MULTI_PRINT = 'SET_MULTI_PRINT';
export const SET_RESET_PRINT_QTY = 'SET_RESET_PRINT_QTY';


