import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Snackbar, FormControl, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterProduct, MasterProductIdentifier } from '../../models';
import { Toast, enqueueSnackbar, ToastError } from '../../../shared/components/Notifier';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';

interface ProductCodeModalProps {
    onClose: (masterProduct: MasterProduct | null) => void;
    isOpen: boolean;
    onSave?: (barcodeData: MasterProductIdentifier) => void;
    onUpdate?: (barcodeData: MasterProductIdentifier) => void;
    barcodeData?: MasterProductIdentifier;
    allProductCodes: MasterProductIdentifier[];
    masterProduct: MasterProduct;
    mode: string;
    showToast: (toast: Toast) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
        },
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            width: 200,
            height: 230,
            overflow: 'auto',
        },
        button: {
            margin: theme.spacing(0.5, 0),
        },
        textField: {
            width: '100%'
        }
    }),
);

const minDate = moment('1970-01-01');
const dateFormat = 'dd-MM-yyyy';
const momentDateFormat = 'DD-MM-YYYY';
const dateSaveFormat = 'YYYY-MM-DD';
type CodeError = {
    isError: boolean;
    message: string;
    message2: string;
};
const InputErrorMsg = 'Invalid input! Please see errors below.';
//@ts-nocheck
const ProductCodeModal = (props: ProductCodeModalProps) => {
    const classes = useStyles({});
    const [barcodeData, setBarcodeData] = useState(null);
    const [formError, setFormError] = useState({ isError: false, message: '', message2: '' } as CodeError);

    useEffect(() => {
        setBarcodeData({ ...props.barcodeData });
    }, [props.barcodeData]);

    const addCode = async () => {
        const error = validateCode();
        if (error) {
            setFormError(error);
            props.showToast({ message: InputErrorMsg, options: ToastError });
            return;
        } else {
            setFormError({ isError: false, message: '', message2: '' });
        }
        const remoteError = await validateCodeAsync();
        if (remoteError) {
            setFormError(remoteError);
            props.showToast({ message: InputErrorMsg, options: ToastError });
            return;
        } else {
            setFormError({ isError: false, message: '', message2: '' });
        }
        const effective_date = barcodeData.effective_date ?
            moment(barcodeData.effective_date).format(dateSaveFormat) : '1970-01-01';
        const expiry_date = barcodeData.expiry_date ?
            moment(barcodeData.expiry_date).format(dateSaveFormat) : null;
        const description = barcodeData.description ? barcodeData.description : barcodeData.description=''; 
        props.onSave({
            ...barcodeData,
            effective_date: effective_date,
            expiry_date: expiry_date,
            description: description
        });
    };

    const updateCode = async () => {
        const error = validateCode();
        if (error) {
            setFormError(error);
            props.showToast({ message: InputErrorMsg, options: ToastError });
            return;
        } else {
            setFormError({ isError: false, message: '', message2: '' });
        }
        const remoteError = await validateCodeAsync();
        if (remoteError) {
            setFormError(remoteError);
            props.showToast({ message: InputErrorMsg, options: ToastError });
            return;
        } else {
            setFormError({ isError: false, message: '', message2: '' });
        }

        const effective_date = barcodeData.effective_date ?
            moment(barcodeData.effective_date).format(dateSaveFormat) : '1970-01-01';
        const expiry_date = barcodeData.expiry_date ?
            moment(barcodeData.expiry_date).format(dateSaveFormat) : null;

        const description = barcodeData.description ? barcodeData.description : barcodeData.description='';
        props.onUpdate({
            ...barcodeData,
            effective_date: effective_date,
            expiry_date: expiry_date,
            description: description
        });
    };

    const validateCodeAsync = async (): Promise<CodeError | null> => {
        try {
            const URL = API_URLS.masterProductWithSameCodeDiffIdUrl
                + '?master_product_id=' + props.masterProduct.id
                + '&identifier_code=' + barcodeData.identifier;
            let response = await composeRequest(URL, 'GET', null);
            const products = await response.json();
            for (let mp of products) {
                const error = validateRemoteCodes(mp);
                if (error) {
                    return error;
                }
            }

        } catch (err) {
            return {
                isError: true,
                message: 'Oops, something went wrong!',
                message2: ''
            };
        }
    };

    const validateRemoteCodes = (mp: MasterProduct): CodeError | null => {
        const barcodes = mp.identifiers;
        for (let codeData of barcodes) {
            const currentEffectiveDate = moment(barcodeData.effective_date);
            const start = moment(codeData.effective_date);
            if (codeData.expiry_date) {
                const end = moment(codeData.expiry_date);
                const defaultError = {
                    isError: true,
                    message: "Product with name: " + mp.name +
                        " has a code with conflicting date range: ",
                    message2:
                        moment(codeData.effective_date).format(momentDateFormat)
                        + '  to ' +
                        moment(codeData.expiry_date).format(momentDateFormat)
                };
                if (currentEffectiveDate.isBetween(start, end, null, "[]")) {
                    return defaultError;
                }
                if (barcodeData.expiry_date) {
                    const currentExpiryDate = moment(barcodeData.expiry_date);
                    if (currentExpiryDate.isBetween(start, end, null, "[]")) {
                        return defaultError;
                    } else if (start.isBetween(currentEffectiveDate, currentExpiryDate, null, "[]") ||
                        end.isBetween(currentEffectiveDate, currentExpiryDate, null, "[]")) {
                        return defaultError;
                    }
                } else {
                    if (currentEffectiveDate.isSameOrBefore(start)) {
                        return defaultError;
                    }
                }
            } else {
                if (currentEffectiveDate.isSameOrAfter(start)) {
                    return {
                        isError: true,
                        message: "Product with name: " + mp.name +
                            " has a code with conflicting date range: ",
                        message2: moment(codeData.effective_date).format(momentDateFormat)
                            + ' and no expiry date.'
                    };
                }
            }
        }
        return null;
    };

    const validateCode = (): CodeError | null => {
        const barcodes = [...props.allProductCodes];
        // console.log("BARCODES",barcodes)
        // console.log("BARCODEDATA",barcodeData)
        const filteredCodes = barcodes.filter(b =>
            (b.id !== barcodeData.id) &&
            (!b.is_deleted) &&
            (b.identifier === barcodeData.identifier)
        );
        // console.log("filtered--codes",filteredCodes)
        if (filteredCodes.length > 0) {
            // check if current code is not overlapping with existing code date ranges
            for (let codeData of filteredCodes) {
                const currentEffectiveDate = moment(barcodeData.effective_date);
                const start = moment(codeData.effective_date);
                if (codeData.expiry_date) {
                    const end = moment(codeData.expiry_date);
                    const defaultError = {
                        isError: true,
                        message: "This conflicts with an existing code's date range: ",
                        message2: moment(codeData.effective_date).format(momentDateFormat) + '  to ' +
                            moment(codeData.expiry_date).format(momentDateFormat)
                    };
                    if (currentEffectiveDate.isBetween(start, end, null, "[]")) {
                        return defaultError;
                    }
                    if (barcodeData.expiry_date) {
                        const currentExpiryDate = moment(barcodeData.expiry_date);
                        if (currentExpiryDate.isBetween(start, end, null, "[]")) {
                            return defaultError;
                        } else if (start.isBetween(currentEffectiveDate, currentExpiryDate, null, "[]") ||
                            end.isBetween(currentEffectiveDate, currentExpiryDate, null, "[]")) {
                            return defaultError;
                        }
                    } else {
                        if (currentEffectiveDate.isSameOrBefore(start)) {
                            return defaultError;
                        }
                    }
                } else {
                    if (currentEffectiveDate.isSameOrAfter(start)) {
                        return {
                            isError: true,
                            message: "This conflicts with an existing code's date range: ",
                            message2: moment(codeData.effective_date).format(momentDateFormat) + ' and no expiry date.'
                        };
                    }
                }
            }
        }
        return null;
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                open={props.isOpen ? props.isOpen : false}
                onClose={() => props.onClose(null)}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {
                        props.mode ? (props.mode === 'add' ?
                            'Add Product Code' : 'Edit Product Code') : ''
                    }
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            id="code"
                            className={classes.textField}
                            label="Code"
                            margin="dense"
                            variant="outlined"
                            value={barcodeData ? barcodeData.identifier : ''}
                            onChange={(event) => setBarcodeData({
                                ...barcodeData,
                                identifier: event.target.value
                            })}
                            InputProps={{
                                // readOnly: true,
                            }}
                        />
                        <TextField
                            id="qty"
                            className={classes.textField}
                            label="Quantity"
                            margin="dense"
                            variant="outlined"
                            onChange={(event) => {

                                setBarcodeData({ ...barcodeData, quantity: event.target.value });
                            }}
                            onBlur={(event) => {
                                let qty = 1;
                                const value = Number(event.target.value);
                                if (value) {
                                    if (value > 0) {
                                        qty = value;
                                    }
                                }
                                setBarcodeData({ ...barcodeData, quantity: qty });
                            }}
                            value={barcodeData ? String(barcodeData.quantity) : ''}
                            InputProps={{
                                // readOnly: true,
                            }}
                        />
                        <TextField
                            id="description"
                            className={classes.textField}
                            label="Description"
                            margin="dense"
                            variant="outlined"
                            onChange={(event) => setBarcodeData({
                                ...barcodeData,
                                description: event.target.value
                            })}
                            value={barcodeData ? barcodeData.description : ''}
                            InputProps={{
                                // readOnly: true,
                            }}
                        />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format={dateFormat}
                                margin="normal"
                                inputVariant="outlined"
                                id="effective-date-picker"
                                label="Effective Date"
                                value={barcodeData ? barcodeData.effective_date ? barcodeData.effective_date : null : null}
                                onChange={(date: any, value?: string) => {
                                    if (!date) {
                                        date = minDate;
                                    }
                                    setBarcodeData({ ...barcodeData, effective_date: date });
                                }}
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                minDate={barcodeData ? barcodeData.effective_date : null}
                                variant="inline"
                                format={dateFormat}
                                margin="normal"
                                id="expiry-date-picker"
                                label="Expiry Date"
                                inputVariant="outlined"
                                value={barcodeData ?
                                    barcodeData.expiry_date ?
                                        barcodeData.expiry_date : null : null}
                                onChange={(date: any, value?: string) => {
                                    setBarcodeData({
                                        ...barcodeData,
                                        expiry_date: date,
                                        is_deleted: false
                                    });
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        {
                            formError.isError &&
                            <div>
                                <Typography variant="body1" style={{ color: GlobalStyles.red }}>
                                    Error!
                            </Typography>
                                <Typography variant="body1" style={{ color: GlobalStyles.red }}>
                                    {formError.message}
                                </Typography>
                                <Typography variant="body1" style={{ color: GlobalStyles.red }}>
                                    {formError.message2}
                                </Typography>
                            </div>
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <Button onClick={() => {
                        setFormError({ isError: false, message: '', message2: '' });
                        props.onClose(null);
                    }}>
                        Cancel
                    </Button>
                    {
                        props.mode ? props.mode === 'add' &&
                            <Button onClick={() => addCode()} color="primary">
                                Add
                        </Button>
                            : null
                    }
                    {
                        props.mode ? props.mode === 'edit' &&
                            <Button onClick={() => updateCode()} color="primary">
                                Update
                        </Button>
                            : null
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};
export default connect(null, mapDispatchToProps)(ProductCodeModal);
