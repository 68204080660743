import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { RetailersSupplierItemsNotLinkedToMasterItem, ReportType } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import { Typography, Divider, SvgIcon, CircularProgress } from '@material-ui/core';
import { API_URLS } from '../../api/';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../../shared/data/actions';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { getRetailBusinessIds } from '../../api';
import InputLabel from '@material-ui/core/InputLabel';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    suppliermsg: {
      color: GlobalStyles.primaryColor,
      fontSize: 16
    }
  }),
);

const selectedReportType: ReportType = {
  id: 0,
  name: 'Retailers Supplier Items Unlinked',
  title: 'Retailers Supplier Items Unlinked',
  columns: [
    { title: 'Ret. Item Number', field: 'retailer_item_number' },
    { title: 'Ret. Item Name', field: 'retailer_item_name' },
    { title: 'Reorder Code', field: 'reorder_code' },
    { title: 'Case Quantity', field: 'case_quantity' },

  ]
};

const getProductsByPage = (businessId: string | number, retailBusinessID: string | number,page: number, pageSize: number,
  searchTerm: string | null, orderBy?: string | number) => {
  let URL = API_URLS.retailersSupplierItemsNotLinkedToMasterItem +
    `?supp_business_id=${businessId}&ret_business_id=${retailBusinessID}&page=${page}&page_size=${pageSize}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL += `&search=${searchTerm}`;
  }
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
};

const exportData = (businessId: string | number, retailBusinessID: string | number, searchTerm: string | null, orderBy?: string | number) => {
  let URL = API_URLS.retailersSupplierItemsNotLinkedToMasterItem + `export/?supp_business_id=${businessId}&ret_business_id=${retailBusinessID}`;
  if (searchTerm) {
    URL += `&search=${searchTerm}`;
  }
  if (orderBy) {
    URL = `${URL}&orderBy=${orderBy}`;
  }
  return composeRequest(URL, 'GET', null);
};

const RetailersSupplierItemsNotLinkedToMasterItemScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [uiLoading, setUILoading] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExporting, setExporting] = useState(false);
  const [retailerBusinessIds, setretailerBusinessIds] = useState([]);
  const [selectedRetailerId, setRetailerId] = useState('')
  const [retailerSelected, setRetailerSelected] = useState(false);

  useEffect(() => {
    tableRef && tableRef.current && tableRef.current.onQueryChange();
  }, [props.userData.selectedBusiness, selectedRetailerId, ]);

  useEffect(() => {
    getRetailBusinessIds()
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setretailerBusinessIds(jsonData);
      })
      .catch((error: any) => {
        console.log('error ', error);
      });
  }, []);

  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      exportData(sb.business_id, selectedRetailerId, tableQuery.search, sb.orderBy)
        .catch(handleError)
        .then(checkStatus)
        .then(res => res.blob())
        .then(data => {
          download(data, 'RetailersSupplierItemsNotLinkedToMasterItem.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if ((error.status === 403 || error.status === 401)) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  const handleRetailerId = (event: any) => {
    setRetailerId(event.target.value);
    setRetailerSelected(true);
  }


  const renderTable = () => {
    return (
      <Container >
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <ExportStatusBar isVisible={isExporting} />
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={selectedReportType.columns}
            data={query =>
              new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as RetailersSupplierItemsNotLinkedToMasterItem[],
                  totalCount: 0,
                  page: query.page
                };
                if (props.userData && props.userData.selectedBusiness && selectedRetailerId) {
                  const sb = props.userData.selectedBusiness;
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }
                  getProductsByPage(sb.business_id, selectedRetailerId,
                    query.page + 1, query.pageSize, query.search, orderBy)
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<RetailersSupplierItemsNotLinkedToMasterItem>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize,
                        search: query.search
                      });
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize,
                        search: query.search
                      });
                      resolve(pageData);
                    });
                } else {
                  resolve(pageData);
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={selectedReportType.title}
                icon={SVG_ICONS.product} />
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting
              },
              {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
          <ExportMenu
            anchorEl={exportBtnAnchorEl}
            handleClose={handleExportMenuClose} />
        </div>
      </Container>
    );
  }


  return (
    <div>
      {
        props.userData &&
          props.userData.selectedBusiness &&
          props.userData.selectedBusiness.is_supplier === true ?
          <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>

              <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
                Select Retailer:
              </Typography>
              <FormControl
                style={{
                  marginRight: 16,
                }}>

                <Select
                  labelId="select-label"
                  id="select-business"
                  value={selectedRetailerId}
                  onChange={handleRetailerId}
                  className={classes.select}
                >
                  {retailerBusinessIds.map(item => (
                    <MenuItem
                      value={item.id}
                      key={item.id}
                    >
                      {item.name}
                    </MenuItem>
                  ))
                  }
                </Select>
              </FormControl>
            </div>
            <Divider className={classes.divider} />
            {retailerSelected === true  ?
              renderTable() : null
            }
          </div> :
          <Typography className={classes.suppliermsg}>
            Selected Business is not a supplier, Please choose a Supplier from dropdown
          </Typography>
      }
    </div>
  )
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(RetailersSupplierItemsNotLinkedToMasterItemScreen);