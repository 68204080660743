import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { PaginatedResponse, Location } from '../../../shared/models';

import { getDispatchNotificationsListByPage } from '../../api';
import composeRequest, { checkStatus, parseJSON, handleError } from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500 } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import moment from 'moment';
import { BusinessRouterPaths } from '../../../page/Routes/RouterPaths';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  }),
);

const createTimeDisplayFormat = 'YYYY-MM-DD HH:mm';

const DispatchNotificationsListScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);


  return (
    <div style={{ width: '100%' }}>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <div className="table-wrapper">
        <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: true
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage
            }
          }}
          columns={[
            {
              title: 'Created On',
              field: 'createTime',
              render: (rowData: any) => {
                if (rowData.createTime != null) {
                  return (<div style={{ minWidth: rowData.createTime != null ? "150px" : null }}>  {moment(rowData.createTime).format(createTimeDisplayFormat)}  </div>)
                }
                return ' ';
              },
            },
            { title: 'Number', field: 'number' },
            { title: 'Items', field: 'items_count' },
            {
              title: 'Warehouse',
              field: 'warehouse',
              render: (rowData: any) => {
                if (rowData.warehouse != null) {
                  return (<div style={{ minWidth: rowData.warehouse.name != null ? "250px" : null }}>  {rowData.warehouse.name}  </div>)
                }
                return ' ';
              },
            },
            {
              title: 'Supplier',
              field: 'korona_supplier',
              render: (rowData: any) => {
                if (rowData.korona_supplier != null) {
                  return (<div style={{ minWidth: rowData.korona_supplier.name != null ? "250px" : null }}>  {rowData.korona_supplier.name}  </div>)
                }
                return ' ';
              },
            },
            {
              title: 'Source Warehouse',
              field: 'sourceOrganizationalUnit',
              render: (rowData: any) => {
                if (rowData.sourceOrganizationalUnit != null) {
                  return (<div style={{ minWidth: rowData.sourceOrganizationalUnit.name != null ? "250px" : null }}>  {rowData.sourceOrganizationalUnit.name}  </div>)
                }
                return ' ';
              },
            },

            {
              title: 'Booked On',
              field: 'bookingTime',
              render: (rowData: any) => {
                if (rowData.bookingTime != null) {
                  return (<div style={{ minWidth: rowData.bookingTime != null ? "150px" : null }}>  {moment(rowData.bookingTime).format(createTimeDisplayFormat)}  </div>)
                }
                return ' ';
              },
            },
            {
              title: 'Booked by',
              field: 'bookingUser',
              render: (rowData: any) => {
                if (rowData.bookingUser != null) {
                  return (<div style={{ minWidth: rowData.bookingUser.first_name != null ? "250px" : rowData.bookingUser.last_name != null ? "250px" : null }}>  {`${rowData.bookingUser.first_name} ${rowData.bookingUser.last_name}`}</div>)
                }
                return ' ';
              },
            },
            {
              title: 'Description',
              field: 'description',
              sorting: false,
              cellStyle: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: 100
              }
            },
          ]}
          data={query =>
            new Promise((resolve, reject) => {
              if (!isLoading) {
                const sb = props.userData.selectedBusiness;
                if (sb && sb.business_id) {
                  replaceHistory(query, props.history);
                  let pageData = {
                    data: [] as any,
                    totalCount: 0,
                    page: query.page
                  };
                  setLoading(true);
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }
                  getDispatchNotificationsListByPage(
                    sb.business_id,
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    orderBy,
                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<any>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      setLoading(false);
                      resolve(pageData);
                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      setLoading(false);
                      resolve(pageData);
                    });
                }
              }
            })
          }
          title={
            <CustomTableTitle
              history={props.history}
              title={'Dispatch Notifications'}
              icon={SVG_ICONS.clipboardEdit} />
          }
          actions={[
            {
              icon: 'refresh',
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(DispatchNotificationsListScreen);