import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography, Container, Select, Menu, MenuItem, FormControl, InputLabel, Input, Link, SvgIcon,
  Card, CardContent, CircularProgress, Divider, FormControlLabel, Grid, TextField,Switch,
  IconButton, Checkbox, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, ListSubheader, LinearProgress, Button, Snackbar, Icon, Paper

} from '@material-ui/core';

import {
  addNewBrandOrMaster,
  getAllMasterCompanies,
  getMasterCompany
} from '../../api';
import { ItemType, MasterCompanyList } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, ERROR_404, ERROR_409 } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { blue, orange, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    qtyRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 16px'
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

    },

    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '20rem',
      minWidth: '12rem',
    },
  }),
);

const permission_model = 'masterproduct';


const FILTERS: Filter[] = [
  {
    id: 'f1',
    label: 'ID',
    types: [
      { id: '1', value: 'id', label: 'is equal' },
      { id: '2', value: 'id_range', label: 'between' }
    ],
    selectedType: 'id',
    value: '',
    url: null,
    minValue: '',
    maxValue: ''
  },
  {
    id: 'f2',
    label: 'Name',
    types: [
      { id: '1', value: 'name__istartswith', label: 'begins with' },
      { id: '2', value: 'name__icontains', label: 'contains' },
      { id: '3', value: 'name', label: 'is equal' },
    ],
    selectedType: 'name',
    value: '',
    url: null

  },
  {
    id: 'f3',
    label: 'Images',
    types: [
      { id: '1', value: 'master_company_having', label: 'is equal' },
    ],
    url: null,
    selectedType: 'master_company_having',
    value: '',
    choices: [
      { id: '1', value: 'zero_image', label: 'has no image' },
      { id: '2', value: 'one_image', label: 'has exactly one image' },
      { id: '3', value: 'more_than_one_image', label: 'has more than one image' },
      { id: '4', value: 'inactive_images', label: 'has inactive image(s)' }

    ],
  },
  {
    id: 'f4',
    label: 'Supplier',
    types: [
      { id: '1', value: 'master_company_is', label: 'is equal' },
    ],
    url: null,
    selectedType: 'master_company_is',
    value: '',
    choices: [
      { id: '1', value: 'suppliers', label: 'Show suppliers' },
      { id: '2', value: 'not_suppliers', label: 'Show other than suppliers ' },
    ],
  },
];



const MasterProductScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [tableEditableProp, setTableEditableProp] = useState({});
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [isLoading, setLoading] = useState(false);
  // const [companies, setCompanies] = useState([]);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false)
  const [newCompanyName, setNewCompanyName] = useState('');
  // const [newCompanySelected, setNewCompanySelected] = useState(false);
  const [newCompanyNameExists, setNewCompanyNameExists] = useState(false)
  const [newCompanyActive, setNewCompanyActive] = useState("1");
  const [newCompanyIsSupplier, setNewCompanyIsSupplier] = useState(false);
  const [companyActive, setCompanyActive] =useState(true)
  const [dialogLoading, setDialogLoading] = useState(false)
  const [initialLoad,setInitialLoad]= useState(true)

  useEffect(() => {
    // console.log("isLoading~~INi")
    if (!isLoading && !initialLoad) {
      // console.log("HEllo", filterString)
      tableRef.current && tableRef.current.onChangePage(null, 0);
      setNewCompanyActive("1");
      setCompanyActive(true)
      setNewCompanyIsSupplier(false)
      setNewCompanyName("");
      // setNewCompanySelected(false);
      setNewCompanyNameExists(false);
    }
  }, [filterString]);


  // useEffect(() => {
  //   getAllMasterCompanies()
  //     .catch(handleError) // handle network issues
  //     .then(checkStatus)
  //     .then(parseJSON)
  //     .then((data: PaginatedResponse<MasterCompanyList>) => {
  //       // console.log("results",data)
  //       setCompanies(data.results);
  //     })
  //     .catch((error: any) => {
  //     });
  // }, []);



  useEffect(() => {

    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue))
    );
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }

      else if (!isRangeFilter) {

        // value is string
        filterValue = f.value.toString();

      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else {
        filterString += `&${f.selectedType}=${filterValue}`;
      }
    });
    console.log("filterString", filterString)
    setFilterString(filterString);
  }, [selectedFilters]);

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };




  const navigateToMasterCompanyDetailScreen = (rowData: any) => {
    const masterCompanyDetailUrl = MasterDataRouterPaths.masterCompany + '/' + rowData.id;
    props.history.push(masterCompanyDetailUrl, rowData);
  };

  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
    setNewCompanyActive("1");
    setCompanyActive(true)
    setNewCompanyIsSupplier(false)
    setNewCompanyName("");
    setNewCompanyNameExists(false);
  }

  const handleNewCompanyNameChange = (event: any) => {
   
    setNewCompanyName(event.target.value)
    setNewCompanyNameExists(false);
  }

  const handleNewCompanyActiveChange = (event: any) => {
    let val = event.target.value

    setNewCompanyActive(event.target.value)
    setCompanyActive( val === '1' ? true : false )
  }

  const handleCompanySupplierChange = (event: any) => {
    setNewCompanyIsSupplier(event.target.checked)
  }



  const renderAddCompanyDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogBoxOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Add a new company</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              <div className={classes.dialogContent} >
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  value={newCompanyName != null ? newCompanyName : null}
                  onChange={handleNewCompanyNameChange}
                  label="Company name"
                  type="text"
                  style={{ width: '12rem', marginBottom: 10 }}
                  error={newCompanyNameExists}
                  helperText={newCompanyNameExists ? "Already exists" : null}
                />
                <FormControlLabel
                  style={{ flex: 1, marginTop: 8 }}
                  control={
                    <Switch
                      checked={newCompanyIsSupplier}
                      onChange={handleCompanySupplierChange}
                      color="primary"
                      style={{ paddingLeft: "10px" }}
                    />
                  }
                  labelPlacement='start'
                  label="Supplier"
                />
                <FormControl >
                  <InputLabel
                    id="select-label"
                  >
                    Active
                  </InputLabel>
                  <Select
                    value={newCompanyActive ? newCompanyActive : null}
                    onChange={handleNewCompanyActiveChange}
                    className={classes.select}
                  >
                    <MenuItem value={'1'}>Yes</MenuItem>
                    <MenuItem value={'0'}>No</MenuItem>
                  </Select>
                </FormControl>
                {
                  dialogLoading &&
                  <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogBoxCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleSave()} style={{ color: newCompanyNameExists ? GlobalStyles.chipBackground : newCompanyName === "" ? GlobalStyles.chipBackground : "#ff1744"  }} disabled={newCompanyNameExists ? newCompanyNameExists : newCompanyName === "" } >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }


  const handleSave = () => {
    setDialogLoading(true)
    let data = {
      item_type: ItemType.COMPANY,
      item_details: { 'name': newCompanyName, 'active': companyActive, 'is_supplier': newCompanyIsSupplier }
    }
    let payload = data

    addNewBrandOrMaster(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setDialogLoading(false)
        handleDialogBoxCancel()
        props.showToast({
          message: 'Added Successfully',
          options: ToastSuccess
        });
        tableRef.current && tableRef.current.onQueryChange()
      })
      .catch((error: any) => {
        let msg = 'Failed to add Company.';
        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
          else if (error.status === 404) {
            msg = ERROR_404
          }
          else if (error.status === 409){
            setNewCompanyNameExists(true)
            msg = ERROR_409
          }
        }
        setDialogLoading(false)
        props.showToast({
          message: msg,
          options: ToastError
        });
      });
  }



  const renderTable = () => {
    return (
      <Container maxWidth="md">
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            components={{
              Toolbar: (props) => (
                <>
                  <MTableToolbar {...props} />
                  {filterVisible && (
                    <>
                      <FilterRow
                        {...props}
                        renderAvailableFilters={
                          <AvailableFilters
                            filters={FILTERS}
                            addFilter={addFilter}
                          />
                        }
                        renderSelectedFilters={
                          <SelectedFilters
                            selectedFilters={selectedFilters}
                            removeFilter={removeFilter}
                            updateFilter={updateFilter}
                            businessId={null}
                          />
                        }
                      />
                    </>
                  )}
                </>
              ),
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              { title: 'ID', field: 'id', editable: 'never' },
              { 
                title: 'Company Name', 
                field: 'name', 
                editable: 'never',
                render: (rowData: any) => {

                  if (rowData.name != null) {
                    return (<div style={{ minWidth: rowData.name != null ? "380px" : null }}>  {rowData.name}  </div>)
                  }
                  return ' ';
                }
              },
              {
                title: 'Supplier',
                field: 'is_supplier',
                type: 'boolean',
                sorting: false,
                render: (rowData: any) => {
                  if (rowData.is_supplier) {
                    return (
                      <SvgIcon htmlColor={GlobalStyles.primaryColor} >
                        <path d={SVG_ICONS.letterS} />
                      </SvgIcon>
                    )

                  }
                  return ''
                },
              },
              {
                title: 'Images',
                field: 'contains_image',
                type: 'boolean',
                sorting: false,
                render: (rowData: any) => (
                  rowData.contains_image ?

                    <SvgIcon
                      htmlColor={GlobalStyles.green}
                    >
                      <path d={SVG_ICONS.imageMultiple} />
                    </SvgIcon> :
                    <SvgIcon
                      htmlColor={GlobalStyles.red}
                    >
                      <path d={SVG_ICONS.imageOff} />
                    </SvgIcon>
                ),
              },
            ]}
            data={
              query => new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [],
                  totalCount: 0,
                  page: query.page
                };
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                setLoading(true);
                getMasterCompany(
                  query.page + 1,
                  query.pageSize,
                  query.search,
                  orderBy,
                  filterString
                )
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((data) => {
                    setLoading(false);
                    setErrorStatusVisible(false);
                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                    pageData = {
                      data: data.results,
                      totalCount: data.count,
                      page: query.page
                    };
                    setTableQuery({
                      ...tableQuery,
                      orderBy,
                      totalCount: data.count,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    resolve(pageData);
                    setInitialLoad(false)
                  })
                  .catch((error: any) => {
                    if (error) {
                      if (error.status && error.status === 500) {
                        setEmptyDataSourceMessage(ERROR_500);
                        setErrorStatusVisible(false);
                      } else if ((error.status === 403 || error.status === 401)
                      ) {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(true);
                      } else {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(false);
                      }
                    }
                    setTableQuery({
                      ...tableQuery,
                      totalCount: 0,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    setLoading(false);
                    setInitialLoad(false)
                    resolve(pageData);
                  });
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Master Companies'}
                icon={SVG_ICONS.product} />
            }
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'edit',
                tooltip: 'Edit',
                hidden: hasUpdatePermission(props.userData.userData.is_superuser, props.userData.userData.user_permissions, permission_model),
                onClick: (event, rowData) => navigateToMasterCompanyDetailScreen(rowData),
              },
              {
                icon: 'filter_list',
                tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
                isFreeAction: true,
                iconProps: {
                  color: filterVisible ? 'primary' : 'inherit',
                },
                // hidden: !isSuperAdmin(props),
                onClick: toggleFilterPanel,
              },

              {
                icon: 'add',
                tooltip: 'Add Company',
                isFreeAction: true,
                onClick: () => setDialogBoxOpen(true),
              },

            ]}
          />
        </div>
      </Container>
    );
  }

  return (
    <div>
      {renderTable()}
      {dialogBoxOpen && renderAddCompanyDialogBox()}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterProductScreen);