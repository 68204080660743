import Grid ,{GridSpacing} from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

import { Button } from '@material-ui/core/';
import {deleteAddress} from '../../api'
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {getAllAddressByEntityId} from '../../api'
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { connect } from 'react-redux';
import { AddAddress } from '..';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    // card: {
    //   padding: theme.spacing(1),
    //   height: 120,
    //   width: 200
    // },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
     
    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent:'space-between'
    },
    paper: {
        height: 100,
        width: 200,
      },
   
      root: {
        flexGrow: 1,
        //padding:'18px'
        paddingTop:'18px',
        paddingRight:'18px',
        paddingBottom:'18px'

      },
    card:{
        flexGrow: 1,
        padding:'4px',
        height: '270px',
        width: '300px',
    },
    button:{
        position: 'relative',
    bottom: '-41px',
    right: '10px'

    },
    cardAction:{
        display: 'flex',
        justifyContent:'flex-start',
        marginTop:'55px',
        marginLeft : '-5px'
    },
    cardContent:{
        flexGrow: 1,
        padding:'18px',
        height: '156px',
        width: '176px',
    }
   
  }),
);


const AddressDetails=(props:any)=>{
    const [addressDetailArray,setAddressDetailArray]=useState([])
    const classes = useStyles({});
    const [editButtonClicked,setEditButtonClicked]=useState(false);
    const [openAddressDialog,setOpenAddressDialog]=useState(false);
    const [addressLinkId,setAddressLinkId]=useState();

    const getAddressDetails=()=>{
        getAllAddressByEntityId(props.entity_id,props.party_id,props.sub_party_type,null,null)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData=>{
            setAddressDetailArray(jsonData)
            props.setNewAddressAdded(false)

        })
        .catch((error: any) => {
            props.setNewAddressAdded(false)

            props.showToast({
              message: 'Failed to fetch Address.',
              options: ToastError
            });
          });
    }

    useEffect(() => {
        getAddressDetails()
    }, [])



    useEffect(() => {
        if(props.newAddressAdded){
        getAddressDetails()
        }
    }, [props.newAddressAdded])


    const handleEditClick=(value)=>{
       setEditButtonClicked(true)
       setOpenAddressDialog(true)
       setAddressLinkId(value.id)
    }

    const handleDeleteAddress=(value)=>{
        deleteAddress(value.id)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData=>{
            props.showToast({
                message: 'Address Deleted Successfully',
                options: ToastSuccess
              });
        getAddressDetails()

        })
        .catch((error: any) => {
         
            props.showToast({
              message: 'Failed to Delete Address',
              options: ToastError
            });
          });
    }

    return (
        <div>
            <Grid container className={classes.root} spacing={3}>
                <Grid item xs={12}>
                    <Grid container  spacing={3}  >
                        {addressDetailArray.map((value) => (
                            
                            <Grid key={value.id} item>
                                    <Card className={classes.card}>
                                        <CardActionArea>
                                            <CardContent className={classes.cardContent}>
                                                <Typography gutterBottom variant="body2" component="p">
                                                    {value.address_type}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {value.address_line_1}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {value.address_line_2}
                                                </Typography>
                                                
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    
                                                    {value.city} {value.state} {value.pincode}
                                               
                                                    </Typography>
                                              
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {value.country}
                                                </Typography>
                                              
                                                
                                                {
                                                    value.attention_person &&
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                    Attention: {value.attention_person}
                                                </Typography>
                                                    }
                                               
                                                {value.address_type=='Shipping' && value.truck_accessible &&
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                   
                                                   Truck Accessible: 
                                                    {value.truck_accessible ?  'True' : 'False' 
                                                     
                                                   }
                                                </Typography>
                                                }
                                            
                                            </CardContent>
                                                </CardActionArea>
                                                <CardActions
                                                    className={classes.cardAction}
                                                >
                                                    {/* <div style={{position: 'relative',bottom:'-41px',right:'10px'}}> */}
                                                    <Button size="small" color="primary" disabled={!props.isEditMode()} onClick={() => { handleEditClick(value) }}>

                                                        Edit
                                                    </Button>
                                                    <Button size="small" color="primary" disabled={!props.isEditMode()} onClick={() => { handleDeleteAddress(value) }}>

                                                        Delete
                                                    </Button>
                                                    {/* </div> */}
                                                </CardActions>
                                    </Card>
                                
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

            </Grid>
            {editButtonClicked &&
            <AddAddress
            party_id={props.party_id}
            isOpen={openAddressDialog}
            entity_id={props.entity_id}
            setDialogBoxState={setOpenAddressDialog}
            edit_mode={editButtonClicked}
            addressLinkId={addressLinkId}
            setEditMode={setEditButtonClicked}
             setNewAddressAdded={props.setNewAddressAdded}

          />

}
        </div>
    )

}
const mapDispatchToProps = (dispatch: any) => {
    return {
      showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
      
    };
  };

  

export default connect(null,mapDispatchToProps)(AddressDetails);