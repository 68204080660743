import { combineReducers, Action } from 'redux';
import {
    GET_INVENTORIES,
    GET_INVENTORIES_SUCCESS,
    GET_INVENTORIES_FAILURE,
    SET_SELECTED_INVENTORY,
    SET_SELECTED_INVENTORY_RECEIPT,
    CLEAR_SELECTED_INVENTORY,
    CLEAR_SELECTED_INVENTORY_RECEIPT,
    GET_INVENTORY_DETAIL,
    GET_INVENTORY_DETAIL_SUCCESS,
    GET_INVENTORY_DETAIL_FAILURE,
    GET_RECEIPTS_BY_INVENTORY_ID,
    GET_RECEIPTS_BY_INVENTORY_ID_FAILURE,
    GET_RECEIPTS_BY_INVENTORY_ID_SUCCESS,
    GET_INVENTORY_SUMMARY,
    GET_INVENTORY_SUMMARY_FAILURE,
    GET_INVENTORY_SUMMARY_SUCCESS,
} from '../actions/actionTypes';
import inventoryReceiptItemsReducer from './inventoryReceiptItemsReducer';
import { Inventory, InventoryReceipt } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

export interface InitialState {
    data: PaginatedResponse<Inventory>;
    inventoryList: Inventory[];
    selectedInventory: Inventory | null;
    selectedInventoryReceipt: InventoryReceipt | null;
    isFetching: boolean;
    success: boolean;
    error: boolean;
    errorData: any;
}

const initialState: InitialState = {
    data: {
        count: 0,
        results: [],
        next: null,
        previous: null,
    } as PaginatedResponse<Inventory>, // products
    inventoryList: [],
    selectedInventory: null,
    selectedInventoryReceipt: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function inventoriesReducer(state = initialState, action: any) {
    // console.log(action)
    let nextInventories = [];
    let oldList = [];
    let inventoryList = [];
    switch (action.type) {
        case GET_INVENTORIES:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_INVENTORIES_SUCCESS:
            nextInventories = [...action.data.results];
            oldList = [];
            if (!action.resetList) {
                oldList = [...state.inventoryList];
            }
            inventoryList = oldList.concat(nextInventories);
            return {
                ...state,
                isFetching: false,
                data: action.data,
                inventoryList: inventoryList,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_INVENTORIES_FAILURE:
            return {
                ...state,
                inventoryList: [],
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        default:
            return state;
    }
}

const initialSelectedInventoryState = {
    selectedInventory: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function selectedInventoryReducer(state = initialSelectedInventoryState, action: any) {
    switch (action.type) {
        case SET_SELECTED_INVENTORY:
            return {
                ...state,
                selectedInventory: action.data,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case CLEAR_SELECTED_INVENTORY:
            return {
                ...state,
                selectedInventory: null,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_INVENTORY_DETAIL:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_INVENTORY_DETAIL_SUCCESS:
            return {
                ...state,
                selectedInventory: action.data,
                isFetching: false,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_INVENTORY_DETAIL_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case GET_RECEIPTS_BY_INVENTORY_ID:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_RECEIPTS_BY_INVENTORY_ID_SUCCESS:
            const { selectedInventory } = { ...state };
            selectedInventory.inventory_receipts = action.data;
            return {
                ...state,
                selectedInventory: selectedInventory,
                isFetching: false,
                success: true,
                error: false,
            };
        case GET_RECEIPTS_BY_INVENTORY_ID_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        default:
            return state;
    }
}

const initialSelectedInventoryReceiptState = {
    selectedInventoryReceipt: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function selectedInventoryReceiptReducer(
    state = initialSelectedInventoryReceiptState,
    action: any
) {
    switch (action.type) {
        case SET_SELECTED_INVENTORY_RECEIPT:
            return {
                ...state,
                selectedInventoryReceipt: action.data,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case CLEAR_SELECTED_INVENTORY_RECEIPT:
            return {
                ...state,
                selectedInventoryReceipt: null,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        default:
            return state;
    }
}

const initialInventorySummaryState = {
    openInventoryListCount: 0,
    totalInventoryCounts: 0,
    totalInventoryCountList: 0,
    // lastCreatedOn: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export const inventorySummaryDataReducer = (state = initialInventorySummaryState, action: any) => {
    switch (action.type) {
        case GET_INVENTORY_SUMMARY:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_INVENTORY_SUMMARY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ...action.data,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_INVENTORY_SUMMARY_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        default:
            return state;
    }
};

export const inventoryReducer = combineReducers({
    inventories: inventoriesReducer,
    inventoryReceipt: inventoryReceiptItemsReducer,
    selectedInventory: selectedInventoryReducer,
    selectedInventoryReceipt: selectedInventoryReceiptReducer,
    inventorySummaryData: inventorySummaryDataReducer,
});
