import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Typography, Checkbox, FormControlLabel, Switch, Link } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SyncIcon from '@material-ui/icons/Sync';
import PublishIcon from '@material-ui/icons/Publish';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Divider } from '@material-ui/core/';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import CollectionsIcon from '@material-ui/icons/Collections';
import {
  Playlist, PlaylistFile
} from '../../models';
import {
  getPlaylistsByPage, getPlaylistDetail, syncPlaylistToSignVue, getProductTags, createUpdatePlaylist, getSignageTemplates, playlistGenerateFiles
} from '../../api';
import { PaginatedResponse, ToastType } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ConfirmModal } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import _isEqual from 'lodash/isEqual';
import { Button } from '@material-ui/core/';
import { blue, orange, red } from '@material-ui/core/colors';
import _filter from 'lodash/filter';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FileDrop } from 'react-file-drop';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import './style.css';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    textFieldEdit: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: 'calc(100% - 64px)'
    },
    textFieldView: {
      marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%'
    },
    textFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },
    button: {
      margin: theme.spacing(1),
    },
    progress: {
      margin: theme.spacing(2),
      justifySelf: 'center'
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'
    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },
    rowActionBtn: {
      padding: 8
    },
    greenCircle: {
      color: GlobalStyles.green
    },
    redCircle: {
      color: GlobalStyles.red
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    dnd: {
      border: '1px solid black',
      // display: 'flex',
      // flexWrap: 'wrap',
      // color: 'black',
      padding: '15px',
      borderStyle: 'dashed',
      // justifyContent: 'center',
      marginBottom: '30px'
    },
    panelHeading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    selectOutline: {
      padding: '10.5px 14px',
    },
  }),
);







const ConfirmModalData = {
  isOpen: false,
  message1: 'You will lose all unsaved changes.',
  message2: ' Are you sure you want to leave this page?',
  title: 'Confirm Navigation',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};

const PlaylistDetail = (props: any) => {
  const classes = useStyles({});
  
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(ConfirmModalData);

  const [fieldErrors, setFieldErrors] = useState({});
  const [nameError, setNameError] = useState({ isError: false, message: '' });
  
  const [listingTemplates, setListingTemplates] = useState([]);
  const [playlistDetails, setPlaylistDetails] = useState(null);
  const [playlistId, setPlaylistId] = useState(null);
  const [originalPlaylistData, setOriginalPlaylistData] = useState(null);
  const [codeGroupData, setCodeGroupData] = useState([])
  const [codeGroupObj, setCodeGroupObj] = useState(null as any)
  const [searchTerm, setSearchTerm] = useState('')
  const [productTags, setProductTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);
  const [expanded, setExpanded] = useState({ codeGroupPanel: true, tagsPanel: true });

  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
  const [durationPerFile, setDurationPerFile] = useState(null);
  const [selectedBusinessId, setSelectedBusinessId] = useState(props?.userData?.selectedBusiness?.business_id);

  console.log("user data");
  console.log(props.userData);

  const handleChange = (e, panel: string) => {
    const xpanel = { ...expanded };
    xpanel[panel] = !xpanel[panel];
    setExpanded(xpanel);
  };

  useEffect(() => {
    console.log("codeGroupData", codeGroupData)
  }, [codeGroupData])

  const loadPlaylistDetails = (playlist_id) => {
    setLoading(true);
    return getPlaylistDetail(props.match.params.id)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setPlaylistDetails(jsonData);
        setOriginalPlaylistData(JSON.parse(JSON.stringify(jsonData)))
        setLoading(false)
        // props.setPageLoader(false);
      })
      .catch((error: any) => {
        setLoading(false)
        // props.setPageLoader(false);
        props.showToast({
          message: 'Failed to fetch Playlist details.',
          options: ToastError
        });
      });
  }

  useEffect(() => {
    if (props.match && props.match.params) {
      if (props.match.params.id) {
        
        setPlaylistId(props.match.params.id);
        // props.setPageLoader(true);
        loadPlaylistDetails(props.match.params.id);

        // get korona Tags
        const pageData = {
          page: 1,
          pageSize: 500,
          businessId: props?.userData?.selectedBusiness?.business_id
        }
        getProductTags(pageData.page, pageData.pageSize, searchTerm, pageData.businessId)
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            setProductTags(jsonData.results);
          })
          .catch((error: any) => {
            setLoading(false)
            // props.setPageLoader(false);
            props.showToast({
              message: 'Failed to fetch Product Tags.',
              options: ToastError
            });
          });

        getSignageTemplates()
        .catch(handleError) // handle network issues
          .then(checkStatus)
          .then(parseJSON)
          .then(jsonData => {
            setListingTemplates(jsonData);
          })
          .catch((error: any) => {
            setLoading(false)
            // props.setPageLoader(false);
            props.showToast({
              message: 'Failed to fetch Listing templates.',
              options: ToastError
            });
          });
      }
    }
  }, []);

  const isDataEdited = () => {
    return !_isEqual(originalPlaylistData, playlistDetails);
  };

  const isEditMode = () => {
    return pageMode && pageMode.type === 'edit_mode';
  };

  const handleDiscardChanges = () => {
    setFieldErrors({});
    const data = JSON.parse(JSON.stringify(originalPlaylistData));
    setPlaylistDetails(data);
  };

  const handleNameChange = (event: any) => {
    setPlaylistDetails({ ...playlistDetails, name: event.target.value });
  };


  const togglePageMode = () => {
    if (pageMode.type === 'view_mode') {
      setPageMode({ type: 'edit_mode' });
    } else {
      setPageMode({ type: 'view_mode' });
    }
  };
  
  
  const syncPlaylistSignVue = () => {
    setLoading(true);
    syncPlaylistToSignVue(playlistDetails?.id)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Synced successfully!',
          options: ToastSuccess
        });
        setLoading(false);
      })
      .catch((error: any) => {
        let msg = 'Failed to Sync.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setLoading(false)
      });
  };



  const handleSave = () => {
    const payload = { ...playlistDetails } as any;
    let hasFieldError = false;

    if (hasFieldError) {
      const msg = 'Invalid input! Please see errors below.';
      props.showToast({ message: msg, options: ToastError });
      return;
    }
    for (let key of Object.keys(fieldErrors)) {
      if (fieldErrors[key].isBlankError) {
        return;
      }
    }

    payload.business = payload.business.id
    payload.filter_tags = payload.filter_tags.map(t => t.uuid)
    // payload.signage_template = payload.signage_template.id

    setLoading(true);
    createUpdatePlaylist(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Playlist updated successfully!',
          options: ToastSuccess
        });
        setLoading(false);
        setPageMode({ type: 'view_mode' });
        // setTimeout(() => navigateBack(), 1200);
      })
      .catch((error: any) => {
        let msg = 'Failed to update Playlist.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setLoading(false)
      });
  };


  // const countryToFlag=(isoCode: string)=>{
  //     return typeof String.fromCodePoint !== 'undefined'
  //       ? isoCode
  //           .toUpperCase()
  //           .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
  //       : isoCode;
  // }






  const hasError = (key: string) => {
    if (fieldErrors && fieldErrors.hasOwnProperty(key)) {
      const error = fieldErrors[key].isBlankError;
      return error ? true : false;
    }
    return false;
  };


  const confirmBackNavigation = () => {
    if (isDataEdited()) {
      setConfirmModalData({
        ...confirmModalData,
        isOpen: true,
        onCancel: () => setConfirmModalData({ ...confirmModalData, isOpen: false }),
        onConfirm: () => {
          setConfirmModalData({ ...confirmModalData, isOpen: false });
          navigateBack();
        }
      });
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  const handleSelectedTemplateChange = (e: any) => {
    console.log("template value - ", e.target.value);
    // setSelectedTags([...value]);
    setPlaylistDetails({...playlistDetails, signage_template: e.target.value})
  }
  const onHandleSelectedTagChange = (event: object, value: any, reason: string) => {
    console.log("tag value - ", value);
    // setSelectedTags([...value]);
    setPlaylistDetails({...playlistDetails, filter_tags: value})
  }
  const onTagInputChange = (e) => {
    const val = e.target ? (e.target.value ? e.target.value.trim() : '') : '';
    setSearchTerm(val);
  }

  
  // const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: any) => {
  //   setOptionsBtnAnchorEl(null);
  //   handleSelectedActionIndex(option)
  // };

  const renderFilterOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.id}>
          {o.name}
        </MenuItem>
      );
    });
  };


 
  const readableFileSize = (size) => {
      var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var i = 0;
      while(size >= 1024) {
          size /= 1024;
          ++i;
      }
      return size.toFixed(1) + ' ' + units[i];
  }


  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    setToast({ ...toast, open: false, });
  };

  const navigateToHTMLFile = (event: React.MouseEvent<HTMLButtonElement>,rowData: any) => {
    if (rowData) {
      window.open(`${rowData.source}`, "_blank");
    }
  }

  const getPlaylistFilesExpansion = () => {
    return (
      <div style={{ marginTop: '30px' }}>
        <ExpansionPanel
          expanded={expanded.codeGroupPanel}
        >
          <ExpansionPanelSummary
            expandIcon={
              <IconButton onClick={(event) => handleChange(event, 'codeGroupPanel')}>
                <ExpandMoreIcon />
              </IconButton>}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <Typography className={classes.panelHeading}>
                Files&nbsp;&nbsp;-&nbsp;&nbsp;
                {`(${playlistDetails && playlistDetails.playlist_files ? playlistDetails.playlist_files.length : 0})`}
              </Typography>
              
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.panelCol}>
              <div className={classes.panelRowHeader}>
                
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 2 }} >Filename</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1 }}>Order</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1 }}>Duration</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1 }}>File Size</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1 }}>View</Typography>
              </div>
              <div className={classes.panelCol}>
                {
                  playlistDetails &&
                    playlistDetails.playlist_files &&
                    playlistDetails.playlist_files.length > 0 ?
                    playlistDetails.playlist_files.map(playlist_file =>
                      <div key={String(playlist_file.id)}>
                        <Divider />
                        <div className={classes.panelRow}>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 2}}>
                            {playlist_file.filename}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 1}}>
                            {playlist_file.playlist_order}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 1}}>
                            {playlist_file.duration}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            style={{ flex: 1}}>
                            {readableFileSize(playlist_file.file_size)}
                          </Typography>
                          
                          <Typography className={classes.panelRowText}
                            style={{ flex: 1}}>
                              <Link variant="body1"
                                align="left"
                                className={classes.panelRowText}
                                style={{ color: GlobalStyles.red, marginLeft: 5 }}
                                component="button"
                                onClick={(e) => navigateToHTMLFile(e,playlist_file)} >
                                {`View`}
                              </Link>
                            
                          </Typography>
                          
                        </div>
                      </div>
                    ) : null
                }
                {
                  playlistDetails &&
                  !playlistDetails.playlist_files &&
                  <div className={classes.noDataRow}>
                    <Typography align='center'>No Codes</Typography>
                  </div>
                }
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }

  const handleDialogBoxCancel = () => {
    setDialogBoxOpen(false);
  }
  const handleDurationChange = (event: any) => {
    // console.log("NEW-BRAND-NAME",event.target.value)
    let val = event.target.value
    setDurationPerFile(val);
  }

  const handleGenFiles = () => {
    
    const location_ids = props.userData?.selectedLocations.map(l => l.id).join(",");
    const tag_ids = playlistDetails.filter_tags.map(t => t.uuid).join(",")
    setDialogLoading(true);
    playlistGenerateFiles(playlistDetails.id, durationPerFile, selectedBusinessId, location_ids, tag_ids)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then(jsonData => {
      props.showToast({
        message: 'Playlist updated successfully!',
        options: ToastSuccess
      });
      
      loadPlaylistDetails(playlistId).then(() => {
        setDialogLoading(false);
        setDialogBoxOpen(false);
      });
      // setTimeout(() => navigateBack(), 1200);
    })
    .catch((error: any) => {
      let msg = 'Failed to generate files!';
      if ((error.status === 403 || error.status === 401)
        && error.body.detail.length > 0) {
        msg = NO_PERMISSIONS_ERROR_MSG;
      }
      props.showToast({ message: msg, options: ToastError });
      setDialogLoading(false)
    });
  }

  const renderGenFilesDialogBox = () => {
    return (
      <div>
        {
          <Dialog
            open={dialogBoxOpen}
            onClose={handleDialogBoxCancel}
            fullWidth={true}
            maxWidth="xs"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>Generate HTML Files</DialogTitle>
            <DialogContent style={{ padding: "10px", }}>
              <div className={classes.dialogContent} >
                <TextField
                  autoFocus
                  margin="dense"
                  size="small"
                  value={durationPerFile && durationPerFile != null ? durationPerFile : ''}
                  onChange={handleDurationChange}
                  label="Duration per file (Sec)"
                  type="text"
                  style={{ width: '12rem' }}
                  disabled={dialogLoading}
                  variant='outlined'
                />
                {
                  dialogLoading &&
                  <div style={{ textAlign: 'center', marginTop: 5 }}>
                    <CircularProgress />
                  </div>
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogBoxCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleGenFiles()} style={{ color: "#ff1744" }} >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }


  return (
    <div>
      {
        isLoading &&
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div style={{ maxWidth: '100%' }}>


          <Card className={classes.card}>
            <CardContent>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '8px',
                borderBottom: '2px solid #adb5bd'
              }}>
                <div style={{ display: 'flex' }}>
                  <IconButton aria-label="back"
                    onClick={confirmBackNavigation}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant='h5'
                    className={classes.title}>Playlist Detail</Typography>
                </div>
                <div style={{ alignSelf: 'right', display: "flex", gap: "0.5rem" }}>
                  <div>
                  <Tooltip title="Sync to SignVue Now">
                    <IconButton
                      style={{
                        color: GlobalStyles.primaryColor,
                        border: '2px solid currentColor',
                        borderRadius: '4px',
                        padding: '4px'
                      }}
                      onClick={() => syncPlaylistSignVue()}>
                      {<SyncIcon />}
                    </IconButton>
                  </Tooltip>
                  </div>
                  <div>
                  <Tooltip title="Generate HTML Files">
                    <IconButton
                      style={{
                        color: GlobalStyles.primaryColor,
                        border: '2px solid currentColor',
                        borderRadius: '4px',
                        padding: '4px'
                      }}
                      disabled={playlistDetails && playlistDetails.filter_tags.length > 0 && playlistDetails.signage_tempate}
                      onClick={() => setDialogBoxOpen(true)}>
                      {<CollectionsIcon />}
                    </IconButton>
                  </Tooltip>
                  </div>
                  <div>
                  <Tooltip title="Toggle Page Mode">
                    <IconButton
                      style={{
                        color: isEditMode() ? '#ffb300' : GlobalStyles.primaryColor,
                        border: '2px solid currentColor',
                        borderRadius: '4px',
                        padding: '4px'
                      }}
                      onClick={() => togglePageMode()}>
                      {isEditMode() ? <EditIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </Tooltip>
                  </div>
                </div>
                
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '8px'
              }}>

                {
                  isEditMode() &&
                  isDataEdited() &&
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button}
                    // startIcon={<SaveIcon />}
                    onClick={() => handleDiscardChanges()}
                  >Discard Changes</Button>
                }
                {
                  isEditMode() &&
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    style={{ marginRight: 0 }}
                    startIcon={<SaveIcon />}
                    onClick={() => handleSave()}
                  >Save</Button>
                }
              </div>
              <Grid container spacing={2} style={{ paddingTop: 4 }}>
                <Grid item xs={6}>
                  {
                    <React.Fragment>
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '75%', marginTop: 16 }}>
                          <div style={{ width: '20%' }}>
                            <TextField
                              id="id"
                              className={classes.textFieldCardRow}
                              style={{}}
                              label="ID"
                              margin="dense"
                              variant="outlined"
                              value={playlistDetails ? playlistDetails.id : ''}
                              InputProps={{
                                disabled: true,
                              }}
                            />
                          </div>
                          

                        </div>

                      </Grid>
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '75%' }}>

                          <TextField
                            id="name"
                            className={classes.textFieldCardRow}
                            label="Name"
                            margin="dense"
                            variant="outlined"
                            value={playlistDetails ? playlistDetails.name : ''}
                            onChange={handleNameChange}
                            onBlur={() => {
                              if (isEditMode()) {
                                if (playlistDetails && playlistDetails.name.length === 0) {
                                  setNameError({
                                    isError: true,
                                    message: 'Name cannot be blank.'
                                  });
                                } else {
                                  setNameError({
                                    isError: false,
                                    message: ''
                                  });
                                }
                              }
                            }}
                            error={nameError.isError}
                            helperText={nameError.message}
                            InputProps={{
                              readOnly: !isEditMode(),
                              disabled: !isEditMode(),
                            }}
                          />

                        </div>
                      </Grid>
                      
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '75%' }}>

                          <TextField
                            id="business_name"
                            className={classes.textFieldCardRow}
                            label="Business"
                            margin="dense"
                            variant="outlined"
                            // disabled={true}
                            value={playlistDetails ? playlistDetails.business.name && playlistDetails.business.name : ''}
                            InputProps={{
                              readOnly: true,
                              disabled: true
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '75%' }}>

                          <TextField
                            id="total_duration"
                            className={classes.textFieldCardRow}
                            label="Total Duration (Sec)"
                            margin="dense"
                            variant="outlined"
                            // disabled={true}
                            value={playlistDetails ? playlistDetails.total_duration : ''}
                            InputProps={{
                              readOnly: true,
                              disabled: true
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '75%' }}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl} style={{width: '100%'}}>
                          <InputLabel id="demo-simple-select-outlined-label">Template</InputLabel>
                          <Select
                            id={`listing-templates`}
                            value={playlistDetails ? playlistDetails.signage_template : ''}
                            onChange={(e) => handleSelectedTemplateChange(e)}
                            variant="outlined"
                            style={{ width: '100%' }}
                            classes={{ outlined: classes.selectOutline }}
                            label={`Template`}
                            readOnly={!isEditMode()}
                            disabled={!isEditMode()}
                            fullWidth={true}
                            margin="dense"
                          >
                            {renderFilterOptions(listingTemplates)}
                          </Select>
                        </FormControl>
                        
                        </div>
                      </Grid>

                      {
                    <React.Fragment>
                      <Grid container className={classes.row}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: '30px' }}>
                      <ExpansionPanel
                        style={{display: 'block', width: '100%'}}
                        expanded={expanded.tagsPanel}
                      >
                        <ExpansionPanelSummary
                          expandIcon={
                            <IconButton onClick={(event) => handleChange(event, 'tagsPanel')}>
                              <ExpandMoreIcon />
                            </IconButton>}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                            <Typography className={classes.panelHeading}>
                              Tags&nbsp;&nbsp;-&nbsp;&nbsp;
                              {`(${playlistDetails && playlistDetails.filter_tags ? playlistDetails.filter_tags.length : 0})`}
                            </Typography>
                            
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <div className={classes.panelCol}>
                            <div style={{padding: "1rem"}}>
                              <Autocomplete
                                multiple
                                size="small"
                                style={{ width: '100%' }}
                                open={isOpen}
                                onOpen={() => {
                                  setIsOpen(true);
                                }}
                                disabled={!isEditMode()}
                                onClose={() => {
                                  setIsOpen(false);
                                }}
                                filterSelectedOptions={true}
                                value={playlistDetails ? playlistDetails.filter_tags : []}
                                getOptionSelected={(option, value) => {
                                  return option["uuid"] === value["uuid"];
                                }}
                                getOptionLabel={(option) => option["name"]}
                                onChange={onHandleSelectedTagChange}
                                onInputChange={onTagInputChange}
                                options={productTags}
                                loading={isLoading}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )
                                }
                              />
                            </div>
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                      </div>
                      </Grid>
                    </React.Fragment>
                }
                      
                      
                    </React.Fragment>
                  }
                </Grid>
                <Grid item xs={6}>
                {getPlaylistFilesExpansion()}
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </div>
      }

      <ConfirmModal
        isOpen={confirmModalData.isOpen}
        onCancel={confirmModalData.onCancel}
        onConfirm={confirmModalData.onConfirm}
        confirmBtnText={confirmModalData.confirmBtnText}
        dialogTitle={confirmModalData.title}
        dialogMessage1={confirmModalData.message1}
        dialogMessage1Color={GlobalStyles.red}
        dialogMessage2={confirmModalData.message2}
      />


    {renderGenFilesDialogBox()}
      
    </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistDetail);