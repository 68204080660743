import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import Container from '@material-ui/core/Container';

import { Product, ProductCode } from '../../../products/models';
import { PaginatedResponse } from '../../../shared/models';
import { Button, Paper, Tooltip, TextField } from '@material-ui/core';
import { getAdminProducts } from '../../../products/api/Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, getErrorMsg } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { Filter, FilterType } from '../../../shared/models/Filter';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

const FILTERS: Filter[] = [
	{
		id: 'f1',
		label: 'Code / PLU',
		types: [
			// { id: '1', value: 'product_article_codes__code__istartswith', label: 'begins with' },
			// { id: '2', value: 'product_article_codes__code__icontains', label: 'contains' },
			{ id: '3', value: 'product_article_codes__code', label: 'is equal' },
		],
		selectedType: 'product_article_codes__code',
		value: '',
		url: null,
	},
	{
		id: 'f2',
		label: 'Product Texts: All',
		types: [
			{ id: '1', value: 'product_article_texts__text__istartswith', label: 'begins with' },
			{ id: '2', value: 'product_article_texts__text__icontains', label: 'contains' },
			{ id: '3', value: 'product_article_texts__text', label: 'is equal' },
		],
		selectedType: 'product_article_texts__text',
		value: '',
		url: null,
	},
	{
		id: 'f3',
		label: 'Show Name',
		types: [
			{ id: '1', value: 'name__istartswith', label: 'begins with' },
			{ id: '2', value: 'name__icontains', label: 'contains' },
			{ id: '3', value: 'name', label: 'is equal' },
		],
		selectedType: 'name',
		value: '',
		url: null,
	},
];
const GlobalProductLookupScreen = (props: any) => {
	const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
	const [errorStatus, setErrorStatus] = useState({
		visible: false,
		msg: ''
	});
	let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
	let locationQuery = useQuery(useLocation);
	const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
	const [isLoading, setLoading] = useState(false);
	const [filterVisible, showFilters] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [filterString, setFilterString] = useState('');


	useEffect(() => {
		if (!isLoading) {
			tableRef.current && tableRef.current.onChangePage(null, 0);
		}
	}, [filterString]);

	useEffect(() => {
		const activeFilters = selectedFilters.filter(
			(f: Filter) => f.selectedType && f.value && f.value.length > 0
		);
		// console.log('activeFilters', activeFilters);
		let filterString = '';
		activeFilters.forEach((f: Filter) => {
			let filterValue = '';
			if (f.url && Array.isArray(f.value)) {
				const mappedValues = f.value.map((v) => v[f.bindValue]);
				filterValue = mappedValues.toString();
			} else {
				// value is string
				filterValue = f.value.toString();
			}
			filterString += `&${f.selectedType}=${filterValue}`;
		});
		setFilterString(filterString);
	}, [selectedFilters]);

	const toggleFilterPanel = () => {
		showFilters(!filterVisible);
	};

	const addFilter = (filter) => {
		if (!selectedFilters.find(f => f.id === filter.id)) {
			const filters = [...selectedFilters];
			filters.push(filter);
			setSelectedFilters(filters);
		}
	};

	const removeFilter = (index) => {
		const filters = [...selectedFilters];
		filters.splice(index, 1);
		setSelectedFilters(filters);
	};

	const updateFilter = (filter, index) => {
		const filters = [...selectedFilters];
		filters.splice(index, 1, filter);
		setSelectedFilters(filters);
	};

	const navigateToMasterProductDetailScreen = (rowData: any) => {
		const masterProductDetailUrl = MasterDataRouterPaths.masterProducts + '/' + rowData.master_product.id;
		window.open(`${masterProductDetailUrl}`)
	};

	const navigateToProductDetailScreen = (rowData: any) => {
		const productDetailUrl = '/web/products/' + rowData.uuid;
		window.open(`${productDetailUrl}`)
	};


	return (
		<Container maxWidth="lg">
			<ErrorStatusBar isVisible={errorStatus.visible} message={errorStatus.msg} displayErrorCode={false} />
			<div style={{ maxWidth: '100%' }}>
				<div className="table-wrapper">

					<MaterialTable
						options={{ ...tableOptions(tableQuery), debounceInterval: 2000,
							search: false,
						}}
						components={{
							Toolbar: (props) => (
								<>
									<MTableToolbar {...props} />
									{filterVisible && (
										<FilterRow
											{...props}
											renderAvailableFilters={
												<AvailableFilters
													filters={FILTERS}
													addFilter={addFilter}
												/>
											}
											renderSelectedFilters={
												<SelectedFilters
													selectedFilters={selectedFilters}
													removeFilter={removeFilter}
													updateFilter={updateFilter}
													businessId={null}
												/>
											}
										/>
									)}
								</>
							),
						}}
						tableRef={tableRef}
						localization={{
							body: {
								emptyDataSourceMessage: emptyDataSourceMessage
							}
						}}
						// onRowClick={(event, rowData) => navigateToProductDetailScreen(rowData)}
						columns={[
							{ title: 'Number', field: 'number' },
							{
								title: 'Business',
								field: 'business',
								render: rowData => {
									if (rowData && rowData.business && rowData.business.name) {
										return <div>{rowData.business.name}</div>;
									}
									return <div></div>;
								},
							},
							{ title: 'Product', field: 'name' },
							{
								title: 'Codes',
								field: 'article_codes',
								render: rowData => {
									if (rowData && rowData.article_codes && rowData.article_codes.length > 0) {
										const codes: string[] = rowData.article_codes.map((c: ProductCode) => c.code);
										const codeString = codes.length > 1 ? codes.join(', ') : codes.toString();
										return <div style={{ fontSize: '0.875rem' }}>{codeString}</div>;
									}
									return <div>---</div>;
								},
							},
							{ 
								title: 'Go to Product',
								field : 'master_product_link',
								render : rowData => {
									if (rowData && rowData.master_product_link && rowData.master_product_link.master_product){
										return(
											<div>
												<Button
													size="small"
													variant="contained"
													color="primary"
													onClick={()=>navigateToMasterProductDetailScreen(rowData)}
													startIcon={<FolderOpenIcon />}
												>
													Master
                				</Button>
											</div>	
										)
									}
								} 
							},
							{ 
								title: 'Go to Product',
								field : 'uuid',
								render : rowData => {
									if (rowData && rowData.uuid){
										return(
											<div>
												<Button
													size="small"
													variant="contained"
													color="primary"
													onClick={()=>navigateToProductDetailScreen(rowData)}
													startIcon={<FolderOpenIcon />}
												>
													Business
                				</Button>
											</div>	
										)
									}
								} 
							},

						]}
						data={query => {
							return new Promise((resolve, reject) => {
								let pageData = {
									data: [] as Product[],
									totalCount: 0,
									page: query.page
								};
								if (!isLoading && (query.search || filterString)) {
									const sb = props.userData.selectedBusiness;
									if (sb && sb.business_id) {
										replaceHistory(query, props.history);

										setLoading(true);
										getAdminProducts(
											query.page + 1,
											query.pageSize,
											query.search,
											filterString
										)
											.catch(handleError) // handle network issues
											.then(checkStatus)
											.then(parseJSON)
											.then((data: PaginatedResponse<Product>) => {
												setErrorStatus({ visible: false, msg: '' });
												setEmptyDataSourceMessage(EMPTY_ROW_MSG);
												pageData = {
													data: data.results,
													totalCount: data.count,
													page: query.page
												};
												setTableQuery({
													...tableQuery,
													totalCount: data.count,
													page: query.page,
													pageSize: query.pageSize
												});
												setLoading(false);
												resolve(pageData);
											})
											.catch((error: any) => {
												if (error) {
													if (error.status && error.status === 500) {
														setEmptyDataSourceMessage(ERROR_500);
														setErrorStatus({ visible: false, msg: '' });
													} else if ((error.status === 403 || error.status === 401)
													) {
														setEmptyDataSourceMessage(ERROR_MSG);
														setErrorStatus({ visible: true, msg: getErrorMsg(error, '') });
													} else {
														setEmptyDataSourceMessage(ERROR_MSG);
														setErrorStatus({ visible: false, msg: '' });
													}
												}
												setTableQuery({
													...tableQuery,
													totalCount: 0,
													page: query.page,
													pageSize: query.pageSize
												});
												setLoading(false);
												resolve(pageData);
											});
									}
								}
								else { resolve(pageData); }
							});
						}}
						title={
							<CustomTableTitle
								history={props.history}
								title={'Products - Global Lookup'}
								icon={SVG_ICONS.product} />
						}
						actions={[
							{
								icon: 'refresh',
								tooltip: 'Refresh Data',
								isFreeAction: true,
								onClick: () => tableRef.current && tableRef.current.onQueryChange(),
							},
							{
								icon: 'filter_list',
								tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
								isFreeAction: true,
								iconProps: {
									color: filterVisible ? 'primary' : 'inherit',
								},
								// hidden: !isSuperAdmin(props),
								onClick: toggleFilterPanel,
							},
						]}
					/>
				</div>
			</div>
		</Container>
	);
};

const mapStateToProps = (state: any) => {
	return {
		userData: state.userLocalData,
	};
};

export default connect(mapStateToProps, null)(GlobalProductLookupScreen);