import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Divider, Tooltip, Typography, IconButton, SvgIcon, List, ListItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

import { getInvoicePreview } from '../../api';
import { Invoice } from '../../models';
import { PageToolbar } from '../../../page/components';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { formatDate } from '../../../shared/lib/Localization';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCenter: {
      padding: theme.spacing(1, 1),
      textAlign: 'center'
    },
    subDivider: {
      margin: theme.spacing(1, 0),
    },
    pageTitle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    tabRoot: {
      flexGrow: 1
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    textField: {
      // marginLeft: theme.spacing(1),
      // marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%'
    },
  }),
);
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const InvoiceDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [pdfPreview, setPdfPreview] = useState<string | null>(null);
  const [invoiceData, setInvoiceData] = useState({ ...props.location.state } as Invoice);
  const [selectedTab, setSelectedTab] = React.useState(0);

  useEffect(() => {
    console.log(invoiceData);
  }, [invoiceData]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const generateInvoicePreview = () => {
    setPdfPreview(null);
    getInvoicePreview()
      // .catch(handleError) // handle network issues
      // .then(checkStatus)
      .then(response => {
        console.log(response);
        // console.log(response.blob());
        if (response) {
          const blob = response.blob()
            .then(data => {
              const file = new Blob([data], { type: 'application/pdf' });
              //Build a URL from the file
              const fileURL = URL.createObjectURL(file);
              console.log(file);
              console.log(fileURL);
              setPdfPreview(fileURL);
            })
            .catch(err => console.log('error in blob file'));
        }
        console.log('error in blob file');
      })
      .catch((error: any) => {
        console.log('generateInvoicePreview error', error);
      });

  };
  return (
    <div>
      <PageToolbar
        // titleIcon={SVG_ICONS.invoice}
        title={`Invoice`}
        secondaryTitle={`Order #${invoiceData.number}`}
        showBackButton={true}
        history={props.history}
        actions={
          <div>
            <Tooltip title="New Invoice" placement="bottom">
              <IconButton>
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh" placement="bottom">
              <IconButton onClick={generateInvoicePreview}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
      />
      <Paper className={classes.tabRoot} style={{ marginTop: '1rem', backgroundColor: '#f5f5f5' }}>
        <Paper className={classes.tabRoot} square
          style={{
            marginTop: '16px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'
          }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Form" />
            <Tab label="Preview" />
          </Tabs>
        </Paper>
        <div className={classes.tabRoot}>
          <TabPanel value={selectedTab} index={0}>
            <Paper
              style={{ padding: '16px' }}>
              {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6}> */}
              <Grid container className={classes.row}>
                <TextField
                  id="number"
                  className={classes.textField}
                  label="Number"
                  margin="dense"
                  variant="outlined"
                  value={invoiceData.number}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid container className={classes.row}>
                <TextField
                  id="description"
                  className={classes.textField}
                  margin="dense"
                  label="Description"
                  variant="outlined"
                  value={invoiceData.description}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid container className={classes.row}>
                <TextField
                  id="created_date"
                  className={classes.textField}
                  margin="dense"
                  variant="outlined"
                  label="Created On"
                  value={formatDate(invoiceData.created_date)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Paper>
            <div>
              {/* <List
                // className={classes.root}
                aria-label="invoice-items">
                {
                  invoiceData && invoiceData.invoice_items.map(i => {
                    return (
                      <ListItem>
                        <div>{i.item_name}</div>
                      </ListItem>
                    );
                  })
                }
              </List> */}
              <MaterialTable
                style={{ marginTop: '1rem' }}
                options={{
                  debounceInterval: 500,
                  pageSize: 10,
                  pageSizeOptions: [10, 50, 100, 500, 1000],
                  headerStyle: {
                    backgroundColor: '#eee',
                  },
                  emptyRowsWhenPaging: false,
                }}
                // onRowClick={(event, rowData) => navigateToInvoiceDetailScreen(rowData)}
                columns={[
                  {
                    title: 'SR',
                    field: 'item_index',
                    editable: 'never'
                  },
                  {
                    title: 'Name',
                    field: 'item_name',
                    editable: 'never'
                  },
                  {
                    title: 'Reorder Code',
                    field: 'reorder_code',
                    editable: 'never'
                  },
                  {
                    title: 'Container Qty',
                    field: 'container_qty',
                    editable: 'never'
                  },
                  {
                    title: 'Ordered Qty',
                    field: 'ordered_qty',
                  },
                  {
                    title: 'Shipped Qty',
                    field: 'shipped_qty'
                  },
                ]}
                data={invoiceData ? invoiceData.invoice_items : []}
                title={'Items'}
                editable={{
                  onRowUpdate: (newData: any, oldData: any) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          console.log('oldData', oldData);
                          console.log('newData', newData);
                          const data = { ...invoiceData };
                          let items = [...invoiceData.invoice_items];
                          for (let i of items) {
                            console.log(i);
                            if (i.item_name === oldData.item_name) {
                              i = {
                                ...newData,
                                ordered_qty: Number(newData.ordered_qty),
                                shipped_qty: Number(newData.shipped_qty),
                              };
                              console.log('new i', i);
                              break;
                            }
                          }
                          console.log('new items', items);
                          data.invoice_items = items;
                          console.log('data', data);
                          setInvoiceData(data);
                          resolve();
                        }
                        resolve();
                      }, 1000);
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        {
                          const items = invoiceData.invoice_items.filter(i => i.item_name !== oldData.item_name);
                          const data = { ...invoiceData };
                          data.invoice_items = items;
                          setInvoiceData(data);
                          resolve();
                        }
                        resolve();
                      }, 1000);
                    }),
                }}
              // actions={[
              //   {
              //     icon: 'refresh',
              //     tooltip: 'Refresh Data',
              //     isFreeAction: true,
              //     onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              //   },
              // ]}
              />
            </div>
            {/* <Grid container className={classes.row}>
              <TextField
                id="assortment"
                className={classes.textField}
                margin="dense"
                label="Assortment"
                variant="outlined"
                value={''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid container className={classes.row}>
              <TextField
                id="sector"
                className={classes.textField}
                margin="dense"
                label="Sector"
                variant="outlined"
                value={''}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid> */}
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <div className={classes.containerCenter}>
              {
                pdfPreview &&
                // <embed src={pdfPreview} width="800px" height="2100px" />
                <embed src={pdfPreview} width="900px" height="1200px" />
              }
            </div>
          </TabPanel>
        </div>
      </Paper>
    </div>
  );
};

export default InvoiceDetailScreen;