import { API_CONFIG } from '../../shared/api/config';
import composeRequest from '../../shared/api/core';
import { LOGIN_HEADERS } from '../../auth/api';
import { enqueueSnackbar, Toast, ToastError, ToastSuccess } from '../../shared/components/Notifier';
import { BAD_REQUEST } from '../../shared/lib/Localization';
export const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    masterProductsUrl: BASE_URL + 'korona/master/products/',
    masterProductsToLinkUrl: BASE_URL + 'korona/master/products/to_link/',
    masterSuppliersUrl: BASE_URL + 'korona/master/suppliers/',
    masterProductLinkUrl: BASE_URL + 'korona/master/products/businesses/link/',
    unMappedBusinessProductsUrl: BASE_URL + 'korona/unmapped-products/',
    unMappedBusinessProductsCountUrl: BASE_URL + 'korona/unmapped-products/count/',
    unMappedBusinessSuppliersUrl: BASE_URL + 'korona/unmapped-suppliers/',
    unMappedBusinessesUrl: BASE_URL + 'korona/unmapped-businesses/',
    unMappedBusinessSuppliersCountUrl: BASE_URL + 'korona/unmapped-suppliers/count/',
    masterSupplierLinkUrl: BASE_URL + 'korona/master/suppliers/business/link/',
    masterKoronaSupplierBusinessLinkUrl: BASE_URL + 'korona/master/link-korona-suppliers/',
    masterSupplierProductsLinkUrl: BASE_URL + 'korona/master/products/suppliers/link/',
    masterProductDetailUrl: BASE_URL + 'korona/master/product-detail/',
    masterProductWithSameCodeDiffIdUrl: BASE_URL + 'korona/master/products/same_code_different_id/',
    masterProductAttributesUrl: BASE_URL + 'korona/master/product-attributes/',
    masterProductWithoutIdentifiersUrl: BASE_URL + 'korona/master/products-missing-identifiers/',
    masterBrandsUrl: BASE_URL + 'korona/master/brands/',
    masterProductMissingIdentifiersUrl: BASE_URL + 'korona/master/products-missing-identifiers/',
    businessListUrl: BASE_URL + 'korona/master/businesses/',
    linkBulkProductsUrl : BASE_URL + 'korona/reports/supplier-items-not-linked-to-retailer-items/',
    getAllRetailerBusinessUrl:BASE_URL + 'korona/master/retailer/business/',
    masterProductDataUrl : BASE_URL + 'korona/master-supplier-data/import',
    validateImportDataUrl : BASE_URL + 'korona/master-data/validate_import_data',
    masterProductValuesUrl : BASE_URL + 'korona/master-data/get_master_data_values',
    masterProductBrandUrl : BASE_URL + 'korona/master/products-brand-list/',
    getValidateImportDataUrl : BASE_URL + 'korona/master-data/get_validated_import_data',
    masterProductListUrl : BASE_URL + 'korona/admin/master_products/',
    masterCompanyUrl: BASE_URL + 'korona/master/company-list/',
    masterRetailerUrl : BASE_URL + 'korona/master/retailers-list/',
    uomReferenceUrl : BASE_URL + 'korona/master/uom-ref-list/',
    addBusinessProductLink : BASE_URL + 'korona/reports/supplier-items-not-linked-to-retailer-items/add_master_business_product_link',
    getAllCountryList:BASE_URL + 'core/country/',
    getCities:BASE_URL + 'core/city/',
    getStates:BASE_URL + 'core/state/',
    getAddressTypes:BASE_URL + 'core/address/get_address_types',
    addAddress:BASE_URL+'core/address/add-address',
    getAllAddressByEntityId:BASE_URL+'core/address/get-address-list/',
    deleteAddressByLinkId:BASE_URL+'core/address/delete-address',
    masterBrandListUrl: BASE_URL + 'korona/admin/master_brands_list/',
    masterBrandDetailUrl: BASE_URL + 'korona/master/brand-detail/',
    uploadMasterItemImageUrl: BASE_URL + 'korona/master-data/items/upload-images',
    masterItemImagesUrl: BASE_URL + 'korona/master-data/items/images/',
    updateMediaSelectedActionUrl: BASE_URL + 'korona/master-data/update-media-action-selected',
    createMediaLinkItemUrl: BASE_URL + 'korona/master-data/create-media-links',
    addExcludeBusinessCodeLinkedMpi : BASE_URL + 'korona/master-data/supplier-items-not-linked-to-retailer-items/add_exclude_bussiness_code_linked_mpi',
    createNewBrandOrCompany : BASE_URL + 'korona/new-brand-or-company/create',
    createOrUpdateMasterCodeGroupUrl : BASE_URL + 'korona/master-data/create-or-update-code-group',
    fetchAllBrands : BASE_URL + 'korona/master/brand-list',
    fetchAllCompanies : BASE_URL + 'korona/master/full-companies-list',

    updateMasterProductTextUrl : BASE_URL + 'korona/master-data/update-master-invoice-text',
    companyProductsListUrl: BASE_URL + 'korona/admin/master-product-company-instance-list/',
    companyProductDetailUrl: BASE_URL + 'korona/master/company-product-detail/',
    createOrUpdateCompanyProductAttributeValues: BASE_URL + 'korona/master/create-or-update-attribute-rec',
    supplierCompaniesUrl: BASE_URL + 'korona/master/company-list/get_supplier_companies/',
    fetchAllCompanyProductAttributesTypes : BASE_URL + 'korona/company-product-instance-attribute/types',
    createNewCompanyProductUrl : BASE_URL + 'korona/company-product-instance-attribute/create',
    validateCompanyDataUrl : BASE_URL + 'korona/master-data/validate-company-data',
    companyProductDataImportUrl : BASE_URL + 'korona/master-data/company-data/import',
    masterCompanyKoronaSupplierlinkUrl: BASE_URL + 'korona/master/link-korona-suppliers-company/',
    unMappedMasterCompanySuppliersCountUrl: BASE_URL + 'korona/unmapped-master-company-suppliers/count/',
    unMappedSupplierMasterCompanyUrl: BASE_URL + 'korona/unmapped-master-companies/',
    masterCompanyDetailUrl: BASE_URL + 'korona/master/company-detail/',
    updateMasterCompanyDetailUrl: BASE_URL + 'korona/master/company-list/update_company_details/',
    getRtlAddedSuppProdsUrl: BASE_URL + 'korona/master-data/temp-recs/distinct-rtl-added-supp-prod-links/',
    rtlAddedSuppProdDetailUrl: BASE_URL + 'korona/master-data/temp-recs/distinct-rtl-added-supp-link/detail',
    validateTempLinksUrl: BASE_URL + 'korona/master-data/temp-recs/validate-temp-link-products',
    getSupplierMasterCompaniesUrl : BASE_URL + 'korona/master-data/companies/get-supplier-companies/',
    removeBussProdLinkUrl : BASE_URL + 'korona/master-data/temp-recs/remove-linked-buss-prod',
    deleteTempRecsUrl: BASE_URL + 'korona/master-data/temp-recs/delete-temp-recs',
    approveTempRecsUrl: BASE_URL + 'korona/master-data/temp-recs/approve-temp-recs',
    approveTemporaryLinksUrl : BASE_URL + 'korona/master-data/approve-temp-recs/same-invoice-desc',
    validateMPUrl : BASE_URL + 'korona/master-data/validate-master-product',
    approveMasterProductUrl : BASE_URL + 'korona/master-data/approve-master-product'
};

export const getAllMasterProductsList = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.masterProductListUrl}?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (filters) {
        URL += filters;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getBusinessList = (page: number, pageSize: number, searchTerm: string | null) => {
    let URL = API_URLS.businessListUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.businessListUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

const showToast = (toast: Toast) => enqueueSnackbar(toast);

export const getMasterProductsByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number
) => {
    let URL = API_URLS.masterProductsUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterProductsUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getMasterProductsToLinkByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number
) => {
    let URL = API_URLS.masterProductsToLinkUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterProductsToLinkUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterProduct = (data: any) => {
    const URL = API_URLS.masterProductsUrl + data.id + '/';
    return composeRequest(URL, 'DELETE', null);
};

export const addMasterProduct = (data: any) => {
    const URL = API_URLS.masterProductsUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const importMasterData = (data: any) => {
    const URL = API_URLS.masterProductDataUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const validateImportData = (data: any) => {
    const URL = API_URLS.validateImportDataUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const getValidatedImportData = (data: any)=>{
    const URL = API_URLS.getValidateImportDataUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));

}

export const masterProductValues = (data: any) => {
    const URL = API_URLS.masterProductValuesUrl;
    return composeRequest(URL, 'GET', JSON.stringify(data));
};

export const updateMasterProduct = (data: any) => {
    const URL = API_URLS.masterProductsUrl + data.id + '/';
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const getMasterProductAttributes = () => {
    const URL = API_URLS.masterProductAttributesUrl;
    return composeRequest(URL, 'GET', null);
};

export const getMasterProductBrand = () => {
    const URL = API_URLS.masterProductBrandUrl;
    return composeRequest(URL, 'GET', null);
};


export const getMasterProductDetail = (id: number) => {
    let URL = API_URLS.masterProductDetailUrl + id + '/';
    return composeRequest(URL, 'GET', null);
};

export const getLinkedMasterProductsByPage = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL =
        API_URLS.masterProductLinkUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterProductLinkUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterProductLinkRecord = (data: any) => {
    const URL = API_URLS.masterProductLinkUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getUnMappedBusinessProductsCount = (businessId: string | number) => {
    const URL = API_URLS.unMappedBusinessProductsCountUrl + `?business_id=${businessId}`;
    return composeRequest(URL, 'GET', null);
};

export const getMasterSuppliersByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL = API_URLS.masterSuppliersUrl + '?page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${API_URLS.masterSuppliersUrl}?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getMasterCompanies =(page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.masterCompanyUrl}?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (filters) {
        URL += filters;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null)
}


export const deleteMasterSupplier = (data: any) => {
    const URL = API_URLS.masterSuppliersUrl + data.id + '/';
    return composeRequest(URL, 'DELETE', null);
};

export const addMasterSupplier = (data: any) => {
    const URL = API_URLS.masterSuppliersUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const updateMasterSupplier = (data: any) => {
    const URL = API_URLS.masterSuppliersUrl + data.id + '/';
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const getLinkedMasterSuppliersByPage = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    // let URL = API_URLS.masterSupplierLinkUrl + '?page=' + page + '&page_size=' + pageSize;
    // let URL = API_URLS.masterSupplierLinkUrl;
    let URL =
        API_URLS.masterSupplierLinkUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterSupplierLinkUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getLinkedKoronaBusinessSuppliers = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL =
        API_URLS.masterKoronaSupplierBusinessLinkUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};
export const deleteLinkedBusinessSupplierRecord = (data: any) => {
    const URL = API_URLS.masterKoronaSupplierBusinessLinkUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getMasterBrandsByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL = API_URLS.masterProductBrandUrl + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.masterProductBrandUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterSupplierLinkRecord = (data: any) => {
    const URL = API_URLS.masterSupplierLinkUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};

export const getUnMappedBusinessSuppliersCount = (businessId: string | number) => {
    const URL = API_URLS.unMappedBusinessSuppliersCountUrl + `?business_id=${businessId}`;
    return composeRequest(URL, 'GET', null);
};

export const searchMasterSuppliers = (searchTerm: string | null): Promise<any> => {
    const URL = API_URLS.masterSuppliersUrl + '?search=' + searchTerm;
    return composeRequest(URL, 'GET', null);
};

export const getLinkedMasterSupplierProductByPage = (
    masterSupplierId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    // let URL = API_URLS.masterSuppliersLinkedUrl + '?page=' + page + '&page_size=' + pageSize;
    let URL =
        API_URLS.masterSupplierProductsLinkUrl +
        '?master_supplier__id=' +
        masterSupplierId +
        '&page=' +
        page +
        '&page_size=' +
        pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.masterSupplierProductsLinkUrl +
            '?master_supplier__id=' +
            masterSupplierId +
            '&search=' +
            searchTerm +
            '&page=' +
            page +
            '&page_size=' +
            pageSize;
    }
    return composeRequest(URL, 'GET', null);
};

export const deleteMasterSupplierProductLinkRecord = (data: any) => {
    const URL = API_URLS.masterSupplierProductsLinkUrl + data.id + '/';
    return composeRequest(URL, 'DELETE', null);
};



export const getUnMappedBusinesses = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL = API_URLS.unMappedBusinessesUrl + '?page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getUnMappedProductsByPage = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    searchTerm = searchTerm ? searchTerm.trim() : '';
    let URL =
        API_URLS.unMappedBusinessProductsUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL =
            API_URLS.unMappedBusinessProductsUrl +
            `?business__id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getLinkBulkProducts =(
    businessId: string | number,
    retailer_businessId: string | number,
    shouldExport?: boolean
)=>{
    let URL;
    if (shouldExport) {
        URL = `${API_URLS.linkBulkProductsUrl}export/?supp_business_id=${businessId}&ret_business_id=${retailer_businessId}`;
    } else {
        URL = `${API_URLS.linkBulkProductsUrl}?supp_business_id=${businessId}&ret_business_id=${retailer_businessId}`;
    }
    return composeRequest(URL, 'GET', null);
}


export const getRetailerBusiness=()=>{
    let URL=
        API_URLS.getAllRetailerBusinessUrl
    return composeRequest(URL, 'GET', null)
}


export const postRetailerSupplierMappedBusiness=(data: any)=>{
    let URL= API_URLS.addBusinessProductLink
    return composeRequest(URL, 'POST',  JSON.stringify(data))
}

export const getMasterCompany=(    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.masterCompanyUrl}?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (filters) {
        URL += filters;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null)
}


export const getMasterCompanyDetail = (id: number) => {
    let URL = API_URLS.masterCompanyDetailUrl + id + '/';
    return composeRequest(URL, 'GET', null);
};


export const updateMasterCompany = (data: any) => {
    const URL = API_URLS.updateMasterCompanyDetailUrl;
    return composeRequest(URL, 'POST',  JSON.stringify(data));
};

export const getAllCountries = () => {
    let URL = `${API_URLS.getAllCountryList}?ordering=country_name`;
    return composeRequest(URL, 'GET', null);
};


export const getAllStates = (country_id:number) => {
    let URL = `${API_URLS.getStates}?country=${country_id}&ordering=state_name`;
    return composeRequest(URL, 'GET', null);
};

export const getAllCities = (state_id:number) => {
    let URL = `${API_URLS.getCities}?state=${state_id}&ordering=city_name`;

    return composeRequest(URL, 'GET', null);
};

export const getAllAddressType = (party_id:number) => {
    let URL = `${API_URLS.getAddressTypes}?party_type=${party_id}`;
    return composeRequest(URL, 'GET', null);
};


export const addNewAddress=(data:any)=>{
    let URL = API_URLS.addAddress;
    return fetch(URL, {
        // headers:LOGIN_HEADERS,
        method: 'POST',
        body: JSON.stringify(data),
    });
};


export const getAllAddressByEntityId=(party_id:number,party_type:number,sub_party_type_id:number,edit_mode,addressLinkId:number)=>{
    let URL=`${API_URLS.getAllAddressByEntityId}?party_type_id=${party_id}&party_type=${party_type}&sub_party_type_id=${sub_party_type_id}`;
    if (edit_mode===true){
        URL=`${URL}&edit_mode=${1}&link_address_reference_id=${addressLinkId}`
    }
    return composeRequest(URL, 'GET', null);
}


export const deleteAddress=(addressLinkId:number)=>{
    let URL=`${API_URLS.deleteAddressByLinkId}?link_address_reference_id=${addressLinkId}`;
    return composeRequest(URL, 'GET', null);
}

export const getAllMasterBrandList = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.masterBrandListUrl}?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (filters) {
        URL += filters;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getMasterBrandDetail = (id: number) => {
    let URL = API_URLS.masterBrandDetailUrl + id + '/';
    return composeRequest(URL, 'GET', null);
};

export const getMasterCompanyData = () => {
    const URL = API_URLS.masterCompanyUrl;
    return composeRequest(URL, 'GET', null);
};
    
export const updateMasterBrand = (data: any) => {
    const URL = API_URLS.masterProductBrandUrl + data.id + '/';
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};


export const uploadMasterItemImage = (data: any) => {
    const URL = API_URLS.uploadMasterItemImageUrl;
    return composeRequest(URL, 'POST', data,'multipart/form-data');
};
// 'multipart/form-data'

export const createMediaLinkItem = (data: any) => {
    const URL = API_URLS.createMediaLinkItemUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};



export const getMasterItemImages = (data: any) => {
    const URL = `${API_URLS.masterItemImagesUrl}?item_id=${data.item_id}&item_type=${data.item_type}`;
    return composeRequest(URL, 'GET', null);
};


export const updateMediaBySelectedAction= (data : any)=>{
    let URL= API_URLS.updateMediaSelectedActionUrl
    return composeRequest(URL, 'POST',  JSON.stringify(data)) 
}

export const addExcludeBusinessCodeLinkedMpi = (data: any) => {
    const URL = API_URLS.addExcludeBusinessCodeLinkedMpi;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getAllMasterCompanies = () => {
    const URL = API_URLS.masterCompanyUrl;
    return composeRequest(URL, 'GET', null);
};


export const addNewBrandOrMaster = (data: any) => {
    const URL = API_URLS.createNewBrandOrCompany;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const createOrUpdateMasterCodeGroup = (data: any) => {
    const URL = API_URLS.createOrUpdateMasterCodeGroupUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const getMasterBrands = () => {
    const URL = API_URLS.fetchAllBrands;
    return composeRequest(URL, 'GET', null);
};

export const getMasterCompaniesFullList = () => {
    const URL = API_URLS.fetchAllCompanies;
    return composeRequest(URL, 'GET', null);
};

export const saveMasterProductText = (data: any) => {
    const URL = API_URLS.updateMasterProductTextUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getAllCompanyProductsList = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number,
    filters?: string
) => {
    let URL = `${API_URLS.companyProductsListUrl}?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (filters) {
        URL += filters;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    
    return composeRequest(URL, 'GET', null);
}


export const getCompanyProductDetail = (id: number) => {
    let URL = API_URLS.companyProductDetailUrl + id + '/';
    return composeRequest(URL, 'GET', null);
};

export const updateCompanyProduct = (data: any) => {
    const URL = API_URLS.companyProductsListUrl + data.id + '/';
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const createOrUpdateCompanyProductAttributeValues = (data: any) => {
    const URL = API_URLS.createOrUpdateCompanyProductAttributeValues;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getSupplierCompanies =(
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy?: string | number, 
)=>{
    let URL = `${API_URLS.supplierCompaniesUrl}`;
    URL+=`?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    } 
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getCompanyProductAttributeTypes = () => {
    const URL = API_URLS.fetchAllCompanyProductAttributesTypes;
    return composeRequest(URL, 'GET', null);
};


export const createNewCompanyProduct = (data: any) => {
    const URL = API_URLS.createNewCompanyProductUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const validateCompanyData = (data: any) => {
    const URL = API_URLS.validateCompanyDataUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getAllSuppCompanies = () => {
    const URL = API_URLS.supplierCompaniesUrl;
    return composeRequest(URL, 'GET', null);
};


export const importMasterCompanyData = (data: any) => {
    const URL = API_URLS.companyProductDataImportUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getUnMappedMasterCompanySuppliersCount = (businessId: string | number) => {
    const URL = API_URLS.unMappedMasterCompanySuppliersCountUrl + `?business_id=${businessId}`;
    return composeRequest(URL, 'GET', null);
};

export const getLinkedKoronaMasterCompanySuppliers = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL =
        API_URLS.masterCompanyKoronaSupplierlinkUrl +
        `?business__id=${businessId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const deleteLinkedKoronaMasterCompanySupplierRecord = (data: any) => {
    const URL = API_URLS.masterCompanyKoronaSupplierlinkUrl + `${data.id}/`;
    return composeRequest(URL, 'DELETE', null);
};


export const getUnMappedSuppliers = (
    businessId: string | number,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL =
        API_URLS.unMappedBusinessSuppliersUrl +
        '?business_id=' +
        businessId +
        '&page=' +
        page +
        '&page_size=' +
        pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL =
            API_URLS.unMappedBusinessSuppliersUrl +
            `?business_id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};



export const getUnMappedMasterCompanies = (
    business_id : any,
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    let URL = API_URLS.unMappedSupplierMasterCompanyUrl + '?business_id=' + business_id + '&page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getRetailerAddedSuppProds = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    business_id?:any,
    masterCompanyId?: any,
    orderBy?: string | number, 
) => {
    let URL = `${API_URLS.getRtlAddedSuppProdsUrl}?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if(business_id){
        URL = URL + `&business_ids=${business_id}`;
    }
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    if(masterCompanyId){
        URL = URL + `&master_company_id=${masterCompanyId}`
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getTempLinkDetail = (data: any) => {
    const URL = API_URLS.rtlAddedSuppProdDetailUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};



export const validateTempLinks = (data: any) => {
    const URL = API_URLS.validateTempLinksUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const getSupplierMasterCompanies =() =>{
    const URL = API_URLS.getSupplierMasterCompaniesUrl;
    return composeRequest(URL,'GET', null);
}
    

export const removeBussProdLinks =(data: any) => {
    const URL = API_URLS.removeBussProdLinkUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const deleteTempRecs =(data: any) => {
    const URL = API_URLS.deleteTempRecsUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const approveTempRecords =(data: any) => {
    const URL = API_URLS.approveTempRecsUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const approveTemporaryLinks =(data: any) => {
    const URL = API_URLS.approveTemporaryLinksUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const validateMP =(data: any) => {
    const URL = API_URLS.validateMPUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}

export const approveMasterProduct =(data: any) => {
    const URL = API_URLS.approveMasterProductUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}