import {
    GET_USERS, GET_USERS_SUCCESS, GET_USERS_FAILURE, UPDATE_USER,
    UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE, GET_USER_ACTIVE_COUNT,
    GET_USER_ACTIVE_COUNT_SUCCESS, GET_USER_ACTIVE_COUNT_FAILURE,
    GET_USER_SUMMARY, GET_USER_SUMMARY_FAILURE, GET_USER_SUMMARY_SUCCESS
} from './actionTypes';
import { API_URLS } from '../../api';
import composeRequest, { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import { User } from '../../models';
import { PaginatedResponse } from '../../../shared/models';


export function getUsersFromAPI() {
    return (dispatch: any) => {
        dispatch(getUsers());
        const URL = API_URLS.userListUrl;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<User>) => {
                dispatch(getUsersSuccess(data));
            })
            .catch((err: any) => {
                dispatch(getUsersFailure(err));
            });

    };
}

export function updateUserAPI(data: User) {
    return (dispatch: any) => {
        dispatch(updateUser(data));
        let URL = API_URLS.userListUrl + data.id + '/';
        composeRequest(URL, 'PUT', JSON.stringify(data))
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: User) => {
                dispatch(updateUserSuccess(data));
            })
            .catch((err: any) => {
                dispatch(updateUserFailure(err));
            });
    };
}

export interface UserCountResponse {
    activeUserCount: number;
}

export interface UserSummaryResponse {
    activeUserCount: number;
    deviceAccessCount: number,
    webAccessCount: number;
}

export function getUserDataSummary(business_id: number | string) {
    return (dispatch: any) => {
        dispatch(getUserSummary());
        const URL = API_URLS.userSummaryDataUrl + '?business_id=' + business_id;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: UserSummaryResponse) => {
                dispatch(getUserSummarySuccess(data));
            })
            .catch((err: any) => {
                dispatch(getUserSummaryFailure(err));
            });
    };
}


function getUsers() {
    return {
        type: GET_USERS
    };
}

function getUsersSuccess(data: PaginatedResponse<User>) {
    return {
        type: GET_USERS_SUCCESS,
        data
    };
}

function getUsersFailure(err: any) {
    return {
        type: GET_USERS_FAILURE,
        err
    };
}


export function updateUser(data: any) {
    return {
        type: UPDATE_USER,
        data
    };
}

function updateUserSuccess(data: User) {
    return {
        type: UPDATE_USER_SUCCESS,
        data
    };
}

function updateUserFailure(err: any) {
    return {
        type: UPDATE_USER_FAILURE,
        err
    };
}

function getActiveUserCount() {
    return {
        type: GET_USER_ACTIVE_COUNT
    };
}

function getActiveUserCountSuccess(data: UserCountResponse) {
    return {
        type: GET_USER_ACTIVE_COUNT_SUCCESS,
        data
    };
}

function getActiveUserCountFailure(err: any) {
    return {
        type: GET_USER_ACTIVE_COUNT_FAILURE,
        err
    };
}
function getUserSummary() {
    return {
        type: GET_USER_SUMMARY
    };
}

function getUserSummarySuccess(data: UserSummaryResponse) {
    return {
        type: GET_USER_SUMMARY_SUCCESS,
        data
    };
}

function getUserSummaryFailure(err: any) {
    return {
        type: GET_USER_SUMMARY_FAILURE,
        err
    };
}