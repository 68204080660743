import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

interface Props {
  anchorEl: Element | null;
  handleClose: (shouldExport: boolean) => void;
}

export const ExportMenu = (props: Props) => {
  const { anchorEl, handleClose } = props;
  return (
    <Menu
      id="export-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleClose(false)}
    >
      {/* <MenuItem disabled={true}>Export To CSV</MenuItem> */}
      <MenuItem onClick={() => handleClose(true)}>Export To CSV</MenuItem>
      {/* <MenuItem onClick={handleClose}>Search Results</MenuItem> */}
    </Menu>);
};