import { API_CONFIG } from '../../shared/api/config';
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    productByCodeUrl: BASE_URL + 'korona/products/get_by_code/',
    productsUrl: BASE_URL + 'korona/products/',
    adminProductsUrl: BASE_URL + 'korona/admin/products/',
    commodityGroupsUrl: BASE_URL + 'korona/commodity-groups/',
    priceListsUrl: BASE_URL + 'korona/price-lists/',
    kioskProductsUrl: BASE_URL + 'korona/kiosk-products/',
    supplierProductsUrl: BASE_URL + 'korona/supplier-products/',
    productCountUrl: BASE_URL + 'korona/products/count/',
    productSummaryDataUrl: BASE_URL + 'korona/products/summary/',
    currentOrderSummary: BASE_URL + 'korona/products/current_order_summary/',
    pastOrderSummary: BASE_URL + 'korona/products/past_order_summary/',
    stocksForAllLocations: BASE_URL + 'korona/products/getStocksForAllLocations/',
    supplierListUrl: BASE_URL + 'korona/supplier-list/',
    productsNotLinkedUrl: BASE_URL + 'korona/products/getProductsToLink/',
    exportOrderFormPdf : BASE_URL + 'korona/products/export-order-form'
};

export const getCurrentOrderSummary = (
    business_id: number | string,
    product_uuid: number | string
) => {
    let URL =
        API_URLS.currentOrderSummary + `?business_id=${business_id}&product_uuid=${product_uuid}`;
    return composeRequest(URL, 'GET', null);
};
export const getPastOrderSummary = (
    business_id: number | string,
    product_uuid: number | string
) => {
    let URL =
        API_URLS.pastOrderSummary + `?business_id=${business_id}&product_uuid=${product_uuid}`;
    return composeRequest(URL, 'GET', null);
};

export const getProduct = (uuid: string) => {
    const URL = API_URLS.productsUrl + `${uuid}/`;
    return composeRequest(URL, 'GET', null);
};

export const getStocksForAllLocations = (business: number | string, uuid: string) => {
    const URL = API_URLS.stocksForAllLocations + `?business_id=${business}&product_uuid=${uuid}`;
    return composeRequest(URL, 'GET', null);
};

export const getProductsByPage = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    business_id: number | string,
    orderBy?: string | number,
    queryTerm?: string,
    filters?: string,
    shouldExport?: boolean
) => {
    let URL = `${API_URLS.productsUrl}`;
    if (shouldExport && shouldExport === true) {
        URL += `export/?web=true&business_ids=${business_id}`;
    } else {
        URL += `?web=true&business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    } else if (queryTerm) {
        URL = `${URL}&query=${queryTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getProductsNotLinked =(

    page: number,
    pageSize: number,
    searchTerm: string | null,
    business_id: number | string,
    fromSupp?: boolean | null,
    supplier_id?: number | string,
    orderBy?: string | number,
    unlinkedFilter?: string | null
    
    
)=>{
    let URL = `${API_URLS.productsNotLinkedUrl}`;
    URL+=`?web=true&business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(fromSupp){
        if(supplier_id){
            URL = `${URL}&from_supp=${fromSupp}&supplier_id=${supplier_id}`;
        }
    } 
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    if (unlinkedFilter) {
        URL = `${URL}&unlinkedFilter=${unlinkedFilter}`;
    }
    return composeRequest(URL, 'GET', null);
};
    



export const getProductsByPageKiosk = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    business_id: number | string,
    shouldExport?: boolean
) => {
    let URL = `${API_URLS.productsUrl}`;
    if (shouldExport && shouldExport === true) {
        URL += `export/?web=true&business_ids=${business_id}`;
    } else {
        URL += `?web=true&business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    // URL += `&business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getAdminProducts = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    filters?: string
) => {
    let URL = `${API_URLS.adminProductsUrl}?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null);
};

export const getCommodityGroups = (
    searchTerm: string | null,
    business_id: number | string,
    filters?: string
) => {
    let URL = `${API_URLS.commodityGroupsUrl}?business_id=${business_id}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null);
};

export const getPriceLists = (
    searchTerm: string | null,
    business_id: number | string,
    filters?: string
) => {
    let URL = `${API_URLS.priceListsUrl}?business_id=${business_id}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null);
};

export const getSuppliersByBusiness = (
    business_id: number | string,
    searchTerm: string | null,
    filters?: string
) => {
    let URL = `${API_URLS.supplierListUrl}?business_id=${business_id}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null);
};

export const getKioskProducts = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    business_id: number | string,
    commodityGroupId: number | string
) => {
    let URL = `${API_URLS.kioskProductsUrl
        }?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (commodityGroupId) {
        URL = `${URL}&commodityGroup__uuid=${commodityGroupId}`;
    }
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getProductsByBusiness = (
    // page: number,
    // pageSize: number,
    // searchTerm: string | null,
    business_id: number | string,
    orderBy?: string | number,
    queryTerm?: string,
    filters?: string,
    shouldExport?: boolean
) => {
    let URL = `${API_URLS.productsUrl}`;
    if (business_id && business_id != 0) {
        URL += `?web=true&business_ids=${business_id}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getOrderFormPdf = (
    data: any
)=>{
    const URL = API_URLS.exportOrderFormPdf;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}