import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';
import Switch from '@material-ui/core/Switch';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { LabelerTemplate } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { getTemplatesByPage, deleteTemplate } from '../../api/Api';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { hasRequiredPermissions } from '../../../shared/lib/PermissionManager';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500 } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    }),
);

const screenPermissions = {
    'add': false,
    'change': false,
    'delete': false,
    'view': false
};

const TemplateListScreen = (props: any) => {
    const { userData } = props.userData.userData;
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState(screenPermissions as any);

    useEffect(() => {
        if (userData && userData.user_permissions) {
            const permissions = [...userData.user_permissions];
            const availablePermissions = hasRequiredPermissions(permissions,
                'labelertemplate', screenPermissions);
            setPermissions(availablePermissions);
        }
    }, [userData && userData.user_permissions]);

    useEffect(() => {
        if (!isLoading) {
            tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedBusiness]);

    return (
        <Container maxWidth="xl">
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div className="table-wrapper">
                <MaterialTable
                    options={{ 
                        ...tableOptions(tableQuery),
                        thirdSortClick: false,
                        draggable: false,
                        sorting: true 
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: 'Name', field: 'name' },
                        {
                            title: 'Description',
                            field: 'description',
                        },
                        {
                            title: 'Active',
                            field: 'active',
                            type: 'boolean',
                            sorting: false,
                            render: rowData => <Switch
                                checked={rowData.active}
                                // onClick={() => handleSwitchChange('active',rowData)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        },
                        {
                            title: 'Last Modified',
                            field: 'modified_date',
                            render: rowData => (
                                rowData.modified_date ?
                                    <div>{moment(rowData.modified_date).format('YYYY-MM-DD HH:mm')}</div>
                                    : <div>Never</div>
                            ),
                        },
                        {
                            title: 'Test',
                            field: 'is_test',
                            type: 'boolean',
                            sorting: false,
                            render: rowData => <Switch
                                checked={rowData.is_test}
                                // onClick={() => handleSwitchChange('active',rowData)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        },
                        // { 
                        //     title: 'Created On',
                        //     field: 'created_date',
                        //     type: 'string',
                        //     render: rowData => (
                        //         rowData.modified_date ? 
                        //         <div>{moment(rowData.modified_date).format('YYYY-MM-DD HH:mm')}</div>
                        //         : <div></div>
                        //     ),
                        // },
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            if (!isLoading) {
                                const sb = props.userData.selectedBusiness;
                                if (sb && sb.business_id) {
                                    let pageData = {
                                        data: [] as LabelerTemplate[],
                                        totalCount: 0,
                                        page: query.page
                                    };
                                    setLoading(true);
                                    let orderBy = '';
                                    if (query.orderBy) {
                                        orderBy = query.orderBy.field as string;
                                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                    }
                                    getTemplatesByPage(
                                        query.page + 1, 
                                        query.pageSize,
                                        query.search, 
                                        sb.business_id,
                                        orderBy,
                                    )
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<LabelerTemplate>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                orderBy,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if ((error.status === 403 || error.status === 401)) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            }
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Label Templates'}
                            icon={SVG_ICONS.template} />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                        {
                            icon: 'add_box',
                            tooltip: 'Add Template',
                            isFreeAction: true,
                            onClick: () => { },
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Edit',
                            onClick: (event, rowData) => { }
                        },
                        {
                            icon: () => <SvgIcon><path d={SVG_ICONS.copy} /></SvgIcon>,
                            tooltip: 'Copy',
                            onClick: (event, rowData) => { },
                        }
                    ]}
                    editable={{
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    deleteTemplate(oldData)
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then(jsonData => {
                                            props.showToast({
                                                message: 'Template deleted successfully!',
                                                options: ToastSuccess
                                            });
                                        })
                                        .catch((error: any) => {
                                            props.showToast({
                                                message: 'Failed to delete template.',
                                                options: ToastError
                                            });
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_500);
                                                    setErrorStatusVisible(false);
                                                } else if ((error.status === 403 || error.status === 401)
                                                ) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                        });
                                    resolve();
                                }, 1000);
                            }),
                    }}
                />
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateListScreen);