import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { RMSCostPrice } from '../../models';
import { BaseFilter } from '../../../master-data/models';
import { PaginatedResponse } from '../../../shared/models';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import Checkbox from '@material-ui/core/Checkbox';

import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import moment from 'moment';

const getProductsByPage = (filterString: string | null, page: number, pageSize: number, searchTerm: string | null) => {
    let URL = API_URLS.rmsCostPriceReport + `?page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = URL + `&search=${searchTerm}`;
    }
    if (filterString) {
        URL = URL + filterString;
    }
    return composeRequest(URL, 'GET', null);
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
    }),
);
const Itemfilters: BaseFilter[] = [
    {
        id: 1,
        name: 'priceC1',
        displayText: 'Price C is not NULL',
        constraint: '&PriceC__isnull=false',
        active: false
    },
    {
        id: 2,
        name: 'CostGt',
        displayText: 'Cost greater than 0.',
        constraint: '&Cost__gt=0',
        active: false
    },
    {
        id: 3,
        name: 'SubDesc3',
        displayText: 'SubDesc3 is not NULL',
        constraint: '&SubDesc3__isnull=false',
        active: false
    },
    {
        id: 4,
        name: 'DefPriceNotDefPriceExp',
        displayText: 'Default Price != Default Expected Price',
        constraint: '&defprice_mismatch=true',
        active: false
    },
    {
        id: 5,
        name: 'DefPriceCNotDefPriceCExp',
        displayText: 'PriceC != Expected PriceC',
        constraint: '&price_c_mismatch=true',
        active: false
    },
];
const RmsCostPriceReport = (props: any) => {
    const classes = useStyles();
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isLoading, setLoading] = useState(false);
    const [filters, setFilters] = useState(Itemfilters);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    useEffect(() => {
        if (!isLoading) {
            tableRef && tableRef.current && tableRef.current.onQueryChange();
        }
    }, [props.userData.selectedLocations]);

    useEffect(() => {
        if (!isLoading) {
            tableRef && tableRef.current && tableRef.current.onChangePage(null, 0);
        }
    }, [filters]);

    const handleToggle = (id: number) => () => {
        const newFilters = [...filters];
        for (let f of newFilters) {
            if (f.id === id) {
                f.active = !f.active;
            }
        }
        setFilters(newFilters);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const getActiveFilterColor = () => {
        return filters.some(f => f.active === true) ? 'primary' : 'inherit';
    };

    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div style={{ maxWidth: '100%' }}>
                <div>
                    {/* 
                        1.priceC is not null,
                        2.cost greater than 0
                        3.SubDesc3 - not null
                        defprice != defexpected
                        priceC!= priceC	expected 
                    */}

                </div>
                <div className="table-wrapper">
                    <MaterialTable
                        options={{
                            ...tableOptions(tableQuery),
                            exportAllData: true,
                            exportButton: true,
                            exportFileName: `RMS_Cost_Price_Data_${moment().format('YYY-MM-DD')}`,
                            exportDelimiter: "\t",
                        }}
                        tableRef={tableRef}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataSourceMessage
                            }
                        }}
                        columns={[
                            { title: 'HQ Item ID', field: 'HQItemID' },
                            { title: 'HQ Item Name', field: 'HQItemName', emptyValue: "NULL" },
                            { title: 'Cost', field: 'Cost', emptyValue: "NULL" },
                            { title: 'Sub Description 3', field: 'SubDesc3', emptyValue: "NULL" },
                            { title: 'Default Price', field: 'DefPrice', emptyValue: "NULL" },
                            { title: 'Expected Default Price', field: 'DefPriceExp', emptyValue: "NULL" },
                            { title: 'Price C', field: 'PriceC', emptyValue: "NULL" },
                            { title: 'Expected Price C', field: 'PriceCExp', emptyValue: "NULL" },
                        ]}
                        data={query =>
                            new Promise((resolve, reject) => {
                                if (props.userData && props.userData.selectedLocations && !isLoading) {
                                    setLoading(true);
                                    replaceHistory(query, props.history);
                                    let pageData = {
                                        data: [] as RMSCostPrice[],
                                        totalCount: 0,
                                        page: query.page
                                    };
                                    const { selectedLocations } = props.userData;
                                    let filterString: string | null = null;
                                    filters.forEach(f => f.active ?
                                        filterString ? filterString += f.constraint : filterString = f.constraint : null);
                                    getProductsByPage(filterString, query.page + 1, query.pageSize,
                                        query.search)
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<RMSCostPrice>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error) {
                                                if (error.status && error.status === 500) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                } else if ((error.status === 403 || error.status === 401)) {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(true);
                                                } else {
                                                    setEmptyDataSourceMessage(ERROR_MSG);
                                                    setErrorStatusVisible(false);
                                                }
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            setLoading(false);
                                            resolve(pageData);
                                        });
                                }
                            })
                        }
                        title={
                            <CustomTableTitle
                                history={props.history}
                                title={'RMS Cost Price'}
                                icon={SVG_ICONS.product} />
                        }
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            },
                            {
                                icon: 'filter_list',
                                tooltip: 'Filters',
                                isFreeAction: true,
                                onClick: handleClick,
                                iconProps: { color: getActiveFilterColor() }
                            }
                        ]}
                    />
                    <Menu
                        elevation={2}
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>
                        {Itemfilters.map((filter, i) => {
                            const labelId = `checkbox-list-secondary-label-${filter.name}`;
                            return (
                                <ListItem key={filter.id} button
                                    onClick={handleToggle(filter.id)}>
                                    <ListItemText id={labelId} primary={filter.displayText} />
                                    <ListItemSecondaryAction>
                                        <Checkbox
                                            edge="end"
                                            onChange={handleToggle(filter.id)}
                                            checked={filter.active}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </Menu>
                    {/* {...props} */}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(RmsCostPriceReport);