import {
    ADD_INVENTORY_RECEIPT_ITEM, REMOVE_INVENTORY_RECEIPT_ITEM,
    CLEAR_INVENTORY_RECEIPT, ADD_INVENTORY_RECEIPT_ITEM_LIST,
    UPDATE_INVENTORY_RECEIPT_ITEM
} from '../actions/actionTypes';
import { InventoryReceiptItem } from '../../models';

export interface InitialState {
    inventoryReceiptItems: InventoryReceiptItem[]
}

const initialState: InitialState = {
    inventoryReceiptItems: []
}

const inventoryReceiptItemsReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case ADD_INVENTORY_RECEIPT_ITEM: 
            if (action.inventoryReceiptItem) {
                // const items = state.inventoryReceiptItems.filter(i => {
                //     return action.inventoryReceiptItem.article === i.article
                // })
                // const newItems = items.length > 0 ? 
                //     state.inventoryReceiptItems : 
                //     state.inventoryReceiptItems.concat(action.inventoryReceiptItem)
                return {
                    ...state,
                    inventoryReceiptItems: state.inventoryReceiptItems.concat(action.inventoryReceiptItem)
                }
            } else {
                return {...state} 
            }
        case UPDATE_INVENTORY_RECEIPT_ITEM:
                if (action.inventoryReceiptItem) {
                    const newState = {...state};
                    const receiptItems = [...newState.inventoryReceiptItems];
                    for (let i = 0; i < receiptItems.length; i++) {
                        if (receiptItems[i].product.uuid === action.inventoryReceiptItem.article) {
                            receiptItems[i] = action.inventoryReceiptItem
                        }
                    }
                    return {
                        ...state,
                        inventoryReceiptItems: receiptItems
                    }
                }
        case REMOVE_INVENTORY_RECEIPT_ITEM:
            if (action.inventoryReceiptItem) {
                return {
                    ...state,
                    inventoryReceiptItems: state.inventoryReceiptItems.filter(i => {
                        return action.inventoryReceiptItem.article !== i.article
                    })
                }
            }
        case ADD_INVENTORY_RECEIPT_ITEM_LIST: 
            if (action.inventoryReceiptItems) {
                const newItems = action.inventoryReceiptItems.filter(
                    (newItem: InventoryReceiptItem) => state.inventoryReceiptItems.indexOf(newItem) === -1)
                return {
                    ...state,
                    inventoryReceiptItems: state.inventoryReceiptItems.concat(newItems)
                }
            } else {
                return {...state} 
            }
        case CLEAR_INVENTORY_RECEIPT:
            return { inventoryReceiptItems: []}
        
        default: return state;
    }
}

export default inventoryReceiptItemsReducer;