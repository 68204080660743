import { API_CONFIG } from "../../shared/api/config";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    inventoriesUrl: BASE_URL + 'korona/inventories/',
    inventoryDetailUrl: BASE_URL + 'korona/inventoryDetail/',
    inventoryReceiptsUrl: BASE_URL + 'korona/inventoryReceipts/',
    inventoryReceiptsByInventoryUrl: BASE_URL + 'korona/inventoryReceipts/byInventoryId/',
    inventoryReceiptItemsByReceiptIdUrl: BASE_URL + 'korona/inventoryReceiptItems/byInventoryReceiptId/',
    deleteInventoryReceiptItem: BASE_URL + 'korona/inventories/delete_inventory_list_item/?inventory_id=',
    inventorySummaryDataUrl: BASE_URL + 'korona/inventories/summary/',
}
