export const ADD_INVENTORY_RECEIPT_ITEM = 'ADD_INVENTORY_RECEIPT_ITEM';
export const UPDATE_INVENTORY_RECEIPT_ITEM = 'UPDATE_INVENTORY_RECEIPT_ITEM';
export const REMOVE_INVENTORY_RECEIPT_ITEM = 'REMOVE_INVENTORY_RECEIPT_ITEM';
export const ADD_INVENTORY_RECEIPT_ITEM_LIST = 'ADD_INVENTORY_RECEIPT_ITEM_LIST';
export const CLEAR_INVENTORY_RECEIPT = 'CLEAR_INVENTORY_RECEIPT';

export const GET_INVENTORIES = 'GET_INVENTORIES';
export const GET_INVENTORIES_SUCCESS = 'GET_INVENTORIES_SUCCESS';
export const GET_INVENTORIES_FAILURE = 'GET_INVENTORIES_FAILURE';

export const GET_INVENTORY_DETAIL = 'GET_INVENTORY_DETAIL';
export const GET_INVENTORY_DETAIL_SUCCESS = 'GET_INVENTORY_DETAIL_SUCCESS';
export const GET_INVENTORY_DETAIL_FAILURE = 'GET_INVENTORY_DETAIL_FAILURE';

export const GET_RECEIPTS_BY_INVENTORY_ID = 'GET_RECEIPTS_BY_INVENTORY_ID';
export const GET_RECEIPTS_BY_INVENTORY_ID_SUCCESS = 'GET_RECEIPTS_BY_INVENTORY_ID_SUCCESS';
export const GET_RECEIPTS_BY_INVENTORY_ID_FAILURE = 'GET_RECEIPTS_BY_INVENTORY_ID_FAILURE';

export const GET_RECEIPT_ITEMS_BY_RECEIPT_ID = 'GET_RECEIPT_ITEMS_BY_RECEIPT_ID';
export const GET_RECEIPT_ITEMS_BY_RECEIPT_ID_SUCCESS = 'GET_RECEIPT_ITEMS_BY_RECEIPT_ID_SUCCESS';
export const GET_RECEIPT_ITEMS_BY_RECEIPT_ID_FAILURE = 'GET_RECEIPT_ITEMS_BY_RECEIPT_ID_FAILURE';

export const SET_SELECTED_INVENTORY = 'SET_SELECTED_INVENTORY';
export const CLEAR_SELECTED_INVENTORY = 'CLEAR_SELECTED_INVENTORY';
export const CLEAR_INVENTORIES = 'CLEAR_INVENTORIES';

export const SET_SELECTED_INVENTORY_RECEIPT = 'SET_SELECTED_INVENTORY_RECEIPT';
export const CLEAR_SELECTED_INVENTORY_RECEIPT = 'CLEAR_SELECTED_INVENTORY_RECEIPT';

export const GET_INVENTORY_RECEIPT = 'GET_INVENTORY_RECEIPT';
export const GET_INVENTORY_RECEIPT_SUCCESS = 'GET_INVENTORY_RECEIPT_SUCCESS';
export const GET_INVENTORY_RECEIPT_FAILURE = 'GET_INVENTORY_RECEIPT_FAILURE';

export const GET_INVENTORY_SUMMARY = 'GET_INVENTORY_SUMMARY';
export const GET_INVENTORY_SUMMARY_FAILURE = 'GET_INVENTORY_SUMMARY_FAILURE';
export const GET_INVENTORY_SUMMARY_SUCCESS = 'GET_INVENTORY_SUMMARY_SUCCESS';