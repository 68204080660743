import { API_CONFIG } from "../../shared/api/config";
import composeRequest from "../../shared/api/core";


const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    businessQueueListUrl:  BASE_URL + 'core/queue-settings/',
};

export const updateException = (data: any) => {
    const URL = API_URLS.businessQueueListUrl + 'korona_db_sync_exceptions/' + `${data.id}/`;
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const RetryException = (data: any) => {
    const URL = API_URLS.businessQueueListUrl + 'korona_db_sync_exceptions/retry/' + `${data.id}/`;
    return composeRequest(URL, 'PUT', data.metadata);
};

export const updateQueue = (data: any) => {
    const URL = API_URLS.businessQueueListUrl + `${data.id}/`;
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

export const updateQueueCron = (data: any) => {
    const URL = API_URLS.businessQueueListUrl + `${data[0].id}/`;
    return composeRequest(URL, 'PUT', JSON.stringify(data[0]));
};


export const scheduleQueues = (data:any)=>{
    let URL = API_URLS.businessQueueListUrl + `schedule/`;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}

export const runQueues = (data:any)=>{
    const URL = API_URLS.businessQueueListUrl + `run_now/`;
    return composeRequest(URL, 'POST', JSON.stringify(data));
}

export const getExceptionsByPage = (page: number, pageSize: number, searchTerm: string | null, orderBy: string | null) => {
    let URL = API_URLS.businessQueueListUrl + `korona_db_sync_exceptions/?page=${page}&page_size=${pageSize}`;
    // searchTerm = searchTerm ? searchTerm.trim() : '';
    // if (searchTerm) {
    //     URL = BASE_URL + `search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    // }
    // if (orderBy) {
    //     URL = `${URL}&orderBy=${orderBy}`;
    // }
    return composeRequest(URL, 'GET', JSON.stringify({}));
};

export const retryAll = () => {
    let URL = API_URLS.businessQueueListUrl + 'korona_db_sync_retry_all_exceptions/';
    // searchTerm = searchTerm ? searchTerm.trim() : '';
    // if (searchTerm) {
    //     URL = BASE_URL + `search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    // }
    // if (orderBy) {
    //     URL = `${URL}&orderBy=${orderBy}`;
    // }
    return composeRequest(URL, 'POST', null);
};

export const getBusinessQueues = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    selectedBusines: string| number,
    selectedTask: string| null,
    orderBy?: string | number

) => {
    let URL = API_URLS.businessQueueListUrl + `?page=${page}&page_size=${pageSize}`;
    if (selectedBusines) {
        URL = `${URL}&business=${selectedBusines}`;
    }
    if (selectedTask) {
        URL = `${URL}&queue=${selectedTask}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.businessQueueListUrl + `?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};