import { API_CONFIG } from '../config';

class WebSocketService {
  static instance: WebSocketService | null = null;
  callbacks: { [key: string]: any; } = {};
  onOpenCallback = () => { };

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  socketRef: WebSocket | null = null;
  deviceId: string | null = null;
  requestCode: string | null = null;
  timeoutId: any = null;
  shouldReOpenConnection = true;

  constructor() {
    this.socketRef = null;
    this.deviceId = null;
  }

  connect() {
    if (this.requestCode) {
      const path = API_CONFIG.WS_BASE_PATH + `device-request/${this.requestCode}`;
      this.socketRef = new WebSocket(path);
      this.socketRef.onopen = () => {
        // console.log('WebSocket open');
        this.onOpenCallback();
      };
      this.socketRef.onmessage = e => {
        this.socketNewMessage(e.data);
      };

      this.socketRef.onerror = (e: any) => {
        console.log(e.message);
      };
      this.socketRef.onclose = () => {
        if (this.requestCode) {
          // console.log("WebSocket closed let's reopen");
          this.connect();
        }
      };
    }

  }

  disconnect() {
    try {
      if (this.socketRef) {
        this.socketRef.close();
        this.socketRef = null;
        this.timeoutId && clearTimeout(this.timeoutId);
      }
    }
    catch (err) {
      // console.log(err.message);
    }
  }

  socketNewMessage(data: any) {
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === 'requests') {
      this.callbacks[command](parsedData.requests, this.deviceId, this.requestCode);
    }
    // if (command === 'new_request') {
    //   this.callbacks[command](parsedData.request);
    // }
    if (command === 'approved_device') {
      this.callbacks[command](parsedData.approved_device, this.deviceId);
    }
  }

  // initDeviceRequest(code) {
  //   this.sendMessage({ command: 'init_device_request', code: code });
  // }

  fetchIncomingRequests(code: string) {
    this.sendMessage({ command: 'fetch_incoming_requests', code: code });
  }

  fetchApprovedDevice(device_id: string) {
    this.sendMessage({ command: 'fetch_approved_device', device_id: device_id });
  }

  sendDeviceRequest(request: any) {
    this.sendMessage({ command: 'new_request', device_request: request });
  }

  approveDeviceRequest(request: any) {
    this.sendMessage({ command: 'approve_request', device_request: request });
  }

  addCallbacks(requestsCallback: any, approveDeviceCallback: any) {
    this.callbacks['requests'] = requestsCallback;
    // this.callbacks['new_request'] = newRequestCallback;
    this.callbacks['approved_device'] = approveDeviceCallback;
  }

  addCallbackOnConnectionOpen(callback: any) {
    this.onOpenCallback = callback;
  }

  sendMessage(data: any) {
    if (this.socketRef) {
      try {
        this.socketRef.send(JSON.stringify({ ...data }));
      }
      catch (err) {
        console.log(err.message);
      }
    }
  }

  state() {
    if (this.socketRef) {
      return this.socketRef.readyState;
    }
  }

  setDeviceId(id: string) {
    this.deviceId = id;
  }

  setRequestCode(code: string | null) {
    this.requestCode = code;
  }

  setReopenConnection(reopen: boolean) {
    this.shouldReOpenConnection = reopen;
  }

  waitForSocketConnection(callback: any) {
    if (this.socketRef) {
      const socket = this.socketRef;
      const recursion = this.waitForSocketConnection;
      this.timeoutId = setTimeout(
        function () {
          if (socket.readyState === 1) {
            console.log("Connection is made");
            if (callback != null) {
              callback();
            }
            return;

          } else {
            console.log("wait for connection...");
            recursion(callback);
          }
        }, 1); // wait 5 milisecond for the connection...
    }
  }

}

const WebSocketInstance = WebSocketService.getInstance();

export default WebSocketInstance;