import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Paper, Typography,
  MenuItem, CircularProgress, TextField, Checkbox,
  FormControlLabel, FormHelperText, ListItemIcon, ListItemText,
  FormControl, Select, InputLabel, SvgIcon
} from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import { useStyles } from './styles';
import { MasterDataRouterPaths } from '../../../../page/Routes/RouterPaths';
import { BusinessSupplierLinkRecord } from '../../../../master-data/models';
import { TEXTRACT_MODELS } from '../../../models';

const StyleMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />

));

const ConfirmSupplierAndLocation = (props: any) => {
  const classes = useStyles({});
  const [currentLoc, setCurrentLoc] = useState(null)
  const {
    onFileSelectChangeHandler,
    linkedSuppliers,
    parseCSV,
    handleLinkedSupplierChange,
    selectedFile, selectedFileError,
    selectedSupplier, supplierError,
    uiLoading, setUILoading,
    stepOneSuccess,
    selectedOrgUnit,
    supplierNameNotLinked,
    supplierLinked,
    pdfInfoExists,
    expressMode,
    selectedSupplierManually,
    documentName,
    setDocumentName,
    shouldSelectParserSetting,
    setShouldSelectedParserSetting,
    setTextractModel,
    textractModel,
    selectedParserSetting,
    setSelectedParserSetting,
    parserModel,
    setParserModel

  } = props;
  // console.log("Selected supplier",selectedSupplier, )
  console.log("SELECTED-LOCATION~~~", selectedOrgUnit)

  useEffect(() => {
    if (selectedSupplierManually) {
      const { parser_settings } = selectedSupplierManually.master_company;
      console.log("parser_settings",parser_settings)
      const parser_setting = parser_settings.find(ps => ps.preferred);
      if (parser_setting && !selectedParserSetting ) {
        console.log("-----CHANGED-----")
        setSelectedParserSetting((prevState:any)=>parser_setting);
        setParserModel((prevState:any)=>parser_setting.text_extractor_module);
      }
    }
    
  }, [selectedSupplierManually])


  const handleSelectedParserSetting = (e: any) => {
    console.log("parser value - ", e.target.value);
    // setSelectedTags([...value]);
    const { parser_settings } = selectedSupplierManually.master_company;
    const parser_setting = parser_settings.find(ps => ps.text_extractor_module == e.target.value);
    setSelectedParserSetting((prevState:any)=>parser_setting);
    setParserModel((prevState:any)=>e.target.value);
  }

  useEffect(() => {
    if (props.selectedParserSetting) {
      setParserModel(props.selectedParserSetting.text_extractor_module);
    }
  }, [props.selectedParserSetting])

  const renderModelOptions = (options: any[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.text_extractor_module}>
          {o.parser_label}
        </MenuItem>
      );
    });
  };

  function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const hasMultipleParserSettings = () => {
    if (selectedSupplierManually) {
      const { parser_settings } = selectedSupplierManually.master_company;
      console.log("parserSettings", parser_settings, selectedSupplierManually)
      return selectedSupplierManually && parser_settings && parser_settings.length > 1;
    }
    return false;
  }
  const getParserSettings = () => {
    const { parser_settings } = selectedSupplierManually.master_company;
    return parser_settings ? parser_settings.map(p => {
      let labels = p.text_extractor_module.split("_")
      labels = labels.map(l => {
        return l.length > 0 ? capitalizeFirstLetter(l) : l;
      })
      p.parser_label = labels.join(" ");
      return p
    }) : [];
  }

  return (
    <div>
      <Paper className={classes.paper} elevation={0}>
        {
          !uiLoading &&
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8 }}>
            <Container maxWidth="sm">
              {supplierLinked &&
                <div>
                  <Typography
                    variant="body1"
                    className={classes.messageText}
                    style={{ marginTop: 8 }}
                  >
                    Please confirm Business,Location and Supplier
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.messageText}
                    style={{ marginTop: 8 }}
                  >
                    Location cannot be changed on the following steps
                  </Typography>
                </div>
              }
              {!supplierLinked &&
                <div className={classes.formRow}>
                  <Typography variant="body1" className={classes.instructions}>Mapping Doesn't Exist? Create a mapping.</Typography>
                  {
                    <Button
                      variant="contained"
                      onClick={() => props.history.push(MasterDataRouterPaths.linkBusinessMasterCompany)}
                      startIcon={<SvgIcon><path d={SVG_ICONS.linkPlus} /></SvgIcon>}>
                      Map Supplier
                    </Button>
                  }
                </div>
              }
              <div className={classes.formRow}>
                <Typography
                  variant="body1"
                  className={classes.instructions}
                  style={{ marginTop: 8 }}
                >
                  1. Business:
                </Typography>

                <Typography
                  variant="body1"
                  className={classes.displayValue}
                  style={{ marginTop: 8 }}
                >
                  {props.userLocalData.selectedBusiness.business_name}
                </Typography>
              </div>
              <div className={classes.formRow}>
                <Typography
                  variant="body1"
                  className={classes.instructions}
                  style={{ marginTop: 8 }}
                >
                  2. Location:
                </Typography>

                <Typography
                  variant="body1"
                  className={!selectedOrgUnit ? classes.errorText : classes.displayValue}
                  style={{ marginTop: 8 }}
                >
                  {selectedOrgUnit ? selectedOrgUnit.name : 'Please select a single location.'}
                </Typography>
              </div>
              {!supplierLinked &&
                <div className={classes.formRow}>
                  <Typography
                    variant="body1"
                    className={classes.messageText}
                    style={{ marginTop: 8 }}
                  >
                    {`${supplierNameNotLinked} Supplier  is not linked to ${props.userLocalData.selectedBusiness.business_name}`}
                  </Typography>

                </div>
              }
              <div className={classes.formRow}>
                <Typography
                  variant="body1"
                  className={classes.instructions}
                  style={{ marginTop: 8 }}
                >
                  3. Supplier:
                </Typography>
                <div style={{ display: 'flex', width: '50%' }}>
                  <FormControl
                    style={{ width: '100%' }}
                    error={supplierError.hasError}>
                    <Select
                      id="select-supplier"
                      disabled={props.stepOneSuccess || uiLoading}
                      value={selectedSupplierManually ? selectedSupplierManually.id : ''}
                      onChange={handleLinkedSupplierChange}
                      required={true}
                    >
                      {
                        linkedSuppliers.map((s: any) => (
                          <MenuItem
                            key={s.id}
                            value={s.id}>
                            {s.korona_supplier.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                    {
                      pdfInfoExists && expressMode &&
                      props.setStepTwoSuccess(true)
                    }
                    {/* {//TODO have removed expressMode=false 
                    
                      pdfInfoExists==false && expressMode &&
                      props.setExpressMode(false)
                    
                    } */}
                    {
                      supplierError && supplierError.hasError &&
                      <FormHelperText>
                        {supplierError.message}
                      </FormHelperText>
                    }
                  </FormControl>
                </div>
              </div>
              {
                hasMultipleParserSettings() && 
                <>
                
                {console.log("hasMultipleParserSettings", hasMultipleParserSettings())}
                  <div className={classes.formRow} style={{ alignItems:null }}>
                    <Typography variant="body1" className={classes.instructions}> Select Parser &nbsp;</Typography>
                    <div style={{ alignSelf: 'center'}}>
                      
                      <Select
                        // label="Template"
                        style={{}}
                        labelId="demo-simple-select-outlined-label"
                        id={`so-model-select`}
                        value={parserModel && parserModel != null ? parserModel : null}
                        onChange={(e) => handleSelectedParserSetting(e)}
                        // variant="outlined"
                        label={`Model Parser`}
                        fullWidth={true}
                        margin="dense"
                      >
                        {renderModelOptions(getParserSettings())}
                      </Select>
                    </div>
                  </div>
                </>
              }
              <div className={classes.formRow}>
                <Typography
                  variant="body1"
                  className={classes.instructions}
                  style={{ marginTop: 8 }}
                >
                  4. Description:
                </Typography>

                <TextField
                  id="description-textarea"
                  // label="Description"
                  // placeholder="Description"
                  style={{ width:'50%'}}
                  multiline
                  margin="normal"
                  size="medium"
                  value={documentName}
                  onChange={(e) => props.setDocumentName(e.target.value)}
                />
              </div>
            </Container>
          </div>
        }
      </Paper>

    </div>
  )


}

const mapStateToProps = (state: any) => {
  return {
    userLocalData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: any) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmSupplierAndLocation);