import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Typography, Checkbox, FormControlLabel, Switch } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress, Divider } from '@material-ui/core/';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import {
  AddressPartyType, AddressSubPartyType, ItemCategory, ItemType
} from '../../models';
import {
  updateMasterCompany, getMasterCompanyDetail,
  getMasterItemImages, updateMediaBySelectedAction,
  uploadMasterItemImage, createMediaLinkItem, createOrUpdateMasterCodeGroup
} from '../../api';
import { PaginatedResponse, ToastType } from '../../../shared/models';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ConfirmModal } from '../../../shared/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import _isEqual from 'lodash/isEqual';
import { Button } from '@material-ui/core/';
import { blue, orange, red } from '@material-ui/core/colors';
import _filter from 'lodash/filter';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FileDrop } from 'react-file-drop';
import { Image, MasterCodeGroupModal } from '../../components';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import './style.css';

import { AddAddress } from '../../components'
import { AddressDetails } from '../../components'
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    textFieldEdit: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: 'calc(100% - 64px)'
    },
    textFieldView: {
      marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%'
    },
    textFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '100%'
    },
    button: {
      margin: theme.spacing(1),
    },
    progress: {
      margin: theme.spacing(2),
      justifySelf: 'center'
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'
    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },
    rowActionBtn: {
      padding: 8
    },
    greenCircle: {
      color: GlobalStyles.green
    },
    redCircle: {
      color: GlobalStyles.red
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },
    dialogContent: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    dnd: {
      border: '1px solid black',
      // display: 'flex',
      // flexWrap: 'wrap',
      // color: 'black',
      padding: '15px',
      borderStyle: 'dashed',
      // justifyContent: 'center',
      marginBottom: '30px'
    },
    panelHeading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
  }),
);







const ConfirmModalData = {
  isOpen: false,
  message1: 'You will lose all unsaved changes.',
  message2: ' Are you sure you want to leave this page?',
  title: 'Confirm Navigation',
  confirmBtnText: 'Yes',
  onCancel: () => { },
  onConfirm: () => { }
};

const MasterCompanyDetail = (props: any) => {
  const classes = useStyles({});
  const [confirmModalData, setConfirmModalData] = useState(ConfirmModalData);
  const [pageMode, setPageMode] = useState({ type: 'view_mode' });
  const [fieldErrors, setFieldErrors] = useState({});
  const [nameError, setNameError] = useState({ isError: false, message: '' });

  const [isLoading, setLoading] = useState(false);
  const [originalMasterCompanyData, setOriginalMasterCompanyData] = useState(null);
  const [masterCompanyDetails, setMasterCompanyDetails] = useState(null);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [newAddressAdded, setNewAddressAdded] = useState(false);


  const [mediaUrls, setMediaUrls] = useState([])
  const [userUploadedMedia, setUserUploadedMedia] = useState(null as any)
  const [userSelectedMedia, setUserSelectedMedia] = useState(null)
  const [defaultMediaData, setDefaultMediaData] = useState(null as any)
  const [mediaData, setMediaData] = useState([])
  const [isMediaLoading, setMediaLoading] = useState(false)
  const [showDefaultMedia, setShowDefaultMedia] = useState(false);
  const [selectedMediaForAction, setSelectedMediaForAction] = useState([])
  const [optionsBtnAnchorEl, setOptionsBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [selectedActionIndex, setSelectedActionIndex] = useState(null)
  const [inActiveMedia, setInActiveMedia] = useState([])
  const fileInputRef = useRef(null);

  const [openCodeGroupModal, setOpenCodeGroupModal] = useState(false);
  const [newCodeGroup, setNewCodeGroup] = useState(false);
  const [codeGroupData, setCodeGroupData] = useState([])
  const [codeGroupObj, setCodeGroupObj] = useState(null as any)
  const [isModalLoading, setModalLoading] = useState(false)
  const [toast, setToast] = useState({ open: false, message: '', variant: 'success' } as ToastType);
  const [changeLocationCodeGroup, setChangeLocationProductCodes] = useState(false)
  const [codeGroupModalMode, setCodeGroupModalMode] = useState('')
  const [newCodeGroupConflicts, setNewCodeGroupConflicts] = useState(false)
  const [newGroupCreated, setNewGroupCreated] = useState(false)
  const [getImageData, setGetImageData] = useState(false)
  const [expanded, setExpanded] = useState({ codeGroupPanel: true });

  const handleChange = (e, panel: 'codeGroupPanel') => {
    const xpanel = { ...expanded };
    xpanel[panel] = !xpanel[panel];
    setExpanded(xpanel);
  };

  useEffect(() => {
    console.log("codeGroupData", codeGroupData)
  }, [codeGroupData])

  useEffect(() => {
    if (!isModalLoading) {
      if (props.match && props.match.params) {
        if (props.match.params.id) {
          setLoading(true)
          // props.setPageLoader(true);
          getMasterCompanyDetail(props.match.params.id)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then(jsonData => {
              setMasterCompanyDetails(jsonData);
              setOriginalMasterCompanyData(JSON.parse(JSON.stringify(jsonData)))
              setLoading(false)
              if (jsonData.code_group_data) {
                setCodeGroupData(jsonData.code_group_data)
              }
              // props.setPageLoader(false);
            })
            .catch((error: any) => {
              setLoading(false)
              // props.setPageLoader(false);
              props.showToast({
                message: 'Failed to fetch Master Company.',
                options: ToastError
              });
            });
        }
      }
    }
  }, [isModalLoading]);

  useEffect(() => {
    if (userUploadedMedia) {
      uploadItemImage()
    }
  }, [userUploadedMedia])



  useEffect(() => {
    if (masterCompanyDetails && getImageData=== false) {
      setGetImageData(true)
      getItemImages()
    }
  }, [masterCompanyDetails, getImageData])



  useEffect(() => {
    let defaultImage;
    let inactiveImageUrls;
    if (mediaData && mediaData.length > 0) {

      let urls = mediaData.map(i => i.media_core ? i.media_core.source : '');
      setMediaUrls(urls)
      // console.log("IMAGE-URLS", urls)

      defaultImage = mediaData.filter(i => i.default === true)
      setDefaultMediaData(defaultImage)
      setUserSelectedMedia(mediaData ? mediaData[0].media_core.source : null)

      // console.log("Default Image", defaultImage)

      inactiveImageUrls = mediaData.filter(i => i.is_active === true)
      setInActiveMedia(inactiveImageUrls)
      // console.log("INACTIVE-IMAGES", inactiveImageUrls)

    }
  }, [mediaData])



  const uploadItemImage = () => {

    setMediaLoading(true)
    const fd = new FormData()


    for (let i = 0; i < userUploadedMedia.length; i++) {

      fd.append('files', userUploadedMedia[i], userUploadedMedia[i].name)
      fd.append('item_category', String(ItemCategory.IMAGE))
    }

    // console.log("form-data", fd)

    uploadMasterItemImage(fd)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        createItemMediaLinks(res)
      })
      .catch((error: any) => {
        setUserUploadedMedia(null)

      });
  }


  const createItemMediaLinks = (mediaCoreData: any) => {
    let data = []
    for (let i = 0; i < userUploadedMedia.length; i++) {
      let obj = {}
      obj['item_id'] = masterCompanyDetails && masterCompanyDetails.id
      obj['default'] = mediaData && mediaData.length > 0 ? false : mediaCoreData && mediaCoreData.length == 1 ? true : mediaCoreData && mediaCoreData.length > 1 && i == 0 ? true : false
      obj['item_type'] = ItemType.COMPANY
      obj['item_category'] = ItemCategory.IMAGE
      obj['is_active'] = true
      obj['is_deleted'] = false
      obj['media_core_id'] = mediaCoreData[i].id && mediaCoreData[i].id
      data.push(obj)
    }
    let payload = { 'item_data': data }
    console.log("payload", payload)
    createMediaLinkItem(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        setUserUploadedMedia(null)
        setMediaLoading(false)
        getItemImages()
        data.failure.length > 0 ?
          props.showToast({
            message: 'Failed to store media for this item.',
            options: ToastError
          }) :
          props.showToast({
            message: 'Media stored successfully',
            options: ToastSuccess
          });

      })
      .catch((error: any) => {
        setMediaLoading(false);

      });

  }




  const getItemImages = () => {
    setMediaLoading(true)
    let payload = { item_id: masterCompanyDetails.id, item_type: ItemType.COMPANY }
    getMasterItemImages(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        let result = res.map(r => ({ ...r, checked: false }))
        setMediaData(result)
        setMediaLoading(false)
        setShowDefaultMedia(false)
        setChangeLocationProductCodes(true)
      })
      .catch((error: any) => {
        // let msg = ""
        // if (error.status === 404) {
        // msg = 'There is No Image for this Item.';
        setShowDefaultMedia(true)
        // setShowDefaultMedia(true)
        setMediaLoading(false)
        setMediaData([])
        setMediaUrls([])
        setDefaultMediaData(null)
        setUserSelectedMedia(null)
        setSelectedMediaForAction([])
        setChangeLocationProductCodes(false)
        // }
        // setMediaLoading(false)

      });
  }






  const isDataEdited = () => {
    return !_isEqual(originalMasterCompanyData, masterCompanyDetails);
  };

  const isEditMode = () => {
    return pageMode && pageMode.type === 'edit_mode';
  };

  const handleDiscardChanges = () => {
    setFieldErrors({});
    const data = JSON.parse(JSON.stringify(originalMasterCompanyData));
    setMasterCompanyDetails(data);
  };

  const handleNameChange = (event: any) => {
    setMasterCompanyDetails({ ...masterCompanyDetails, name: event.target.value });
  };


  const togglePageMode = () => {
    if (pageMode.type === 'view_mode') {
      setPageMode({ type: 'edit_mode' });
    } else {
      setPageMode({ type: 'view_mode' });
    }
  };



  const handleSave = () => {
    const payload = { ...masterCompanyDetails } as any;
    // let hasFieldError = false;

    // if (hasFieldError) {
    //   const msg = 'Invalid input! Please see errors below.';
    //   props.showToast({ message: msg, options: ToastError });
    //   return;
    // }
    // for (let key of Object.keys(fieldErrors)) {
    //   if (fieldErrors[key].isBlankError) {
    //     return;
    //   }
    // }

    setLoading(true)
    updateMasterCompany(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master Company updated successfully!',
          options: ToastSuccess
        });
        setLoading(false);
        setTimeout(() => navigateBack(), 1200);
        setOpenCodeGroupModal(false)
        setGetImageData(false)
      })
      .catch((error: any) => {
        let msg = 'Failed to update master product.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setLoading(false)
      });
  };


  // const countryToFlag=(isoCode: string)=>{
  //     return typeof String.fromCodePoint !== 'undefined'
  //       ? isoCode
  //           .toUpperCase()
  //           .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
  //       : isoCode;
  // }






  const hasError = (key: string) => {
    if (fieldErrors && fieldErrors.hasOwnProperty(key)) {
      const error = fieldErrors[key].isBlankError;
      return error ? true : false;
    }
    return false;
  };


  const confirmBackNavigation = () => {
    if (isDataEdited()) {
      setConfirmModalData({
        ...confirmModalData,
        isOpen: true,
        onCancel: () => setConfirmModalData({ ...confirmModalData, isOpen: false }),
        onConfirm: () => {
          setConfirmModalData({ ...confirmModalData, isOpen: false });
          navigateBack();
        }
      });
    } else {
      navigateBack();
    }
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  const handleFileDrop = (files: any, event: any) => {
    event.preventDefault()
    console.log("FIles---", files)
    files && files.length > 0 ? setUserUploadedMedia(files) : null
  }

  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  const handleUploadClick = (e: any) => {
    // console.log(e)
    if (!userUploadedMedia) {
      const el = document.getElementById('import-media');
      el && el.click();

    }
  };

  const handleSelectedMediaForAction = (data: any) => {
    let selectedImgArr = []

    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex((obj => obj.id == data.id));
    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : imageDataArr[checked].checked = true
    setMediaData(imageDataArr)
    setSelectedMediaForAction(imageDataArr)
  }

  const handleSelectedMedia = (data: any) => {
    setUserSelectedMedia(data)
  }


  const onFileSelectChangeHandler = (e: any) => {
    if (!userUploadedMedia) {
      if (e.target) {
        if (e.target.files && e.target.files.length > 0) {
          console.log("Fileeees-", e.target.files)
          let files = e.target.files
          setUserUploadedMedia(files);
        }
      }
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: any) => {
    setOptionsBtnAnchorEl(null);
    handleSelectedActionIndex(option)
  };


  const handleSelectedActionIndex = (option: any) => {
    setOptionsBtnAnchorEl(null);
    let selectionAction = option ? option.toLowerCase() : option
    // setMediaLoading(true)

    let selectedMediaActionData = selectedMediaForAction.filter(i => i.checked === true)

    let payload = { data: selectedMediaActionData, selected_action: selectionAction }
    // console.log(payload)

    updateMediaBySelectedAction(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master company media updated successfully!',
          options: ToastSuccess
        });
        setMediaLoading(false);
        setOptionsBtnAnchorEl(null);
        setSelectedMediaForAction([])
        removeSelectedImagesForAction()
        getItemImages()

        // setTimeout(() => navigateBack(), 1200);
      })
      .catch((error: any) => {
        let msg = 'Failed to update company media.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setMediaLoading(false)
        setOptionsBtnAnchorEl(null);
      });

  }

  const removeSelectedImagesForAction = () => {
    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex(i => i.checked ? i.checked === true : null)

    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : null
    // console.log("filteredArr", imageDataArr)
    setMediaData(imageDataArr)

  }



  const handleAddAddressClick = () => {
    setOpenAddressDialog(true)
  }


  const handleCloseToast = (event?: React.SyntheticEvent, reason?: string) => {
    setToast({ ...toast, open: false, });
  };


  const handleCloseCodeGroupDialog = () => {
    setOpenCodeGroupModal(false);
    setCodeGroupObj(null)
    handleCloseToast()
  };


  const handleCodeModalOpen = (mode: string, codeObj: any) => {
    let obj = {}
    if (mode === 'add' && !codeObj) {
      // obj['new_id'] = 
      obj['company'] = masterCompanyDetails && masterCompanyDetails.id
      obj['code_block_end'] = ""
      obj['code_block_start'] = ""
      obj['brand'] = null
      setNewCodeGroup(true)
    } else {
      obj = codeObj
    }

    setCodeGroupObj(obj)
    setOpenCodeGroupModal(true)
    setCodeGroupModalMode(mode)


  }



  const handleStartCodeBlock = (e: any) => {

    let val = e.target ? e.target.value : '';
    console.log("startcode", val)
    let obj = { ...codeGroupObj, code_block_start: val }
    setCodeGroupObj(obj)

    let codeData = codeGroupData.map(i => i)
    let index = codeData.findIndex(c => c.id === codeGroupObj.id)
    console.log("index-Start", index)
    if (index != -1) {
      let editedObj = codeData[index]
      editedObj['code_block_end'] = val
      codeData[index] = editedObj
    }
    // codeData.push(obj)
    setCodeGroupData(codeData)
  }

  const handleEndCodeBlock = (e: any) => {
    let val = e.target ? e.target.value : '';
    let obj = { ...codeGroupObj, code_block_end: val }
    setCodeGroupObj(obj)

    let codeData = codeGroupData.map(i => i)
    let index = codeData.findIndex(c => c.id === codeGroupObj.id)
    if (index != -1) {
      let editedObj = codeData[index]
      editedObj['code_block_end'] = val
      codeData[index] = editedObj
    }
    // codeData.push(obj)
    setCodeGroupData(codeData)

  }


  const getCodeGroupExpansion = () => {
    return (
      <div style={{ marginTop: '30px' }}>
        <ExpansionPanel
          expanded={expanded.codeGroupPanel}
        >
          <ExpansionPanelSummary
            expandIcon={
              <IconButton onClick={(event) => handleChange(event, 'codeGroupPanel')}>
                <ExpandMoreIcon />
              </IconButton>}
          >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <Typography className={classes.panelHeading}>
                Code Group&nbsp;&nbsp;-&nbsp;&nbsp;
                {`(${masterCompanyDetails && masterCompanyDetails.code_group_data ? masterCompanyDetails.code_group_data.length : 0})`}
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} >
                {
                  isEditMode() &&
                  <>
                    <Tooltip title="Add Code">
                      <IconButton style={{ padding: 8 }}
                        onClick={() => handleCodeModalOpen('add', null)}
                        disabled={!isEditMode() ? true : false}
                      >
                        <AddBoxIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.panelCol}>
              <div className={classes.panelRowHeader}>
                {
                  isEditMode() &&
                  <Typography className={classes.panelHeaderText}
                    style={{ textAlign: 'center', minWidth: '80px' }}
                  >
                    Actions
                  </Typography>
                }
                {isEditMode() && <Typography style={{ textAlign: 'center', minWidth: '80px' }} > </Typography>}

                <Typography className={classes.panelHeaderText}
                  style={{ flex: 2 }} >Start</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 2 }}>End</Typography>
              </div>
              <div className={classes.panelCol}>
                {
                  masterCompanyDetails &&
                    masterCompanyDetails.code_group_data &&
                    masterCompanyDetails.code_group_data.length > 0 ?
                    masterCompanyDetails.code_group_data.map(codeData =>
                      <div key={String(codeData.id)}>
                        <Divider />
                        <div className={classes.panelRow}>
                          {
                            isEditMode() &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title="Edit">
                                <IconButton
                                  className={classes.rowActionBtn}
                                  disabled={!isEditMode() ? true : false}
                                  onClick={() => handleCodeModalOpen('edit', codeData)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                            </div>
                          }
                          {
                            isEditMode() &&
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            </div>
                          }
                          <Typography className={classes.panelRowText}
                            style={{ flex: 2, marginLeft: isEditMode() ? '35px' : null }}>
                            {codeData.code_block_start}
                          </Typography>
                          <Typography className={classes.panelRowText}
                            align="left"
                            style={{ flex: 2 }}>
                            {codeData.code_block_end}
                          </Typography>
                        </div>
                      </div>
                    ) : null
                }
                {
                  masterCompanyDetails &&
                  !masterCompanyDetails.code_group_data &&
                  <div className={classes.noDataRow}>
                    <Typography align='center'>No Codes</Typography>
                  </div>
                }
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }

  const handleUpdateCodeGroup = (codeData: any) => {

    console.log("Code-Object", codeData)
    setNewGroupCreated(true)
    setModalLoading(true)
    let data = {
      item_type: ItemType.COMPANY,
      code_group_data: codeData,
      mode: 'update'
    }
    let payload = data

    handleAddOrUpdateCodeGroup(payload, 'update')
  }



  const handleSaveCodeGroup = (codeData: any) => {

    console.log("Code-Object", codeData)
    setNewGroupCreated(true)
    setModalLoading(true)
    let data = {
      item_type: ItemType.COMPANY,
      code_group_data: codeData,
      mode: 'save'
    }
    let payload = data

    handleAddOrUpdateCodeGroup(payload, 'save')
  }



  const handleAddOrUpdateCodeGroup = (payload: any, mode: string) => {

    createOrUpdateMasterCodeGroup(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setModalLoading(false)
        setToast({ open: true, message: `Code Group was successfully ${mode === 'save' ? 'created' : 'updated'}`, variant: 'success' })
        setTimeout(() => {
          setNewGroupCreated(false)
          handleCloseCodeGroupDialog();
        }, 2000)

        // tableRef.current && tableRef.current.onQueryChange()
      })
      .catch((error: any) => {

        if (error && error.status) {
          if (error.status === 409) {
            setNewCodeGroupConflicts(true)
            setToast({ open: true, message: 'Code Group conflicts. Please change the values', variant: 'error' })
          }

        }
        setNewGroupCreated(false)
        setModalLoading(false)

      });
  }


  const renderBrandsAndCompaniesItems = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 20 }}>

        {/* <Button variant="contained" color="primary" size='small'
          onClick={()=>handleNavigatetoProductsAndBrands(masterCompanyDetails.id, "product")}
        >
         Products
        </Button> */}
        {/* </div> */}
        <Button variant="contained" color="primary" size='small'
          onClick={() => handleNavigatetoProductsAndBrands(masterCompanyDetails.id, "brand")}
        >
          Brands
        </Button>
      </div>
    )
  }

  const handleNavigatetoProductsAndBrands = (companyId: any, type: any) => {
    if (companyId) {
      if (type === "product") {
        let companyDetails = { id: companyId, from: 'company' }
        let mpciUrl = MasterDataRouterPaths.companyProductsListScreen
        console.log("company_details", companyDetails)
        props.history.push(mpciUrl, companyDetails)
      }
      if (type === "brand") {
        let companyDetails = { id: companyId, from: 'company' }
        let mpciUrl = MasterDataRouterPaths.masterBrands
        console.log("company_details", companyDetails)
        props.history.push(mpciUrl, companyDetails)
      }
    }
  }


  const handleCompanySupplierChange = (event: any) => {
    setMasterCompanyDetails({ ...masterCompanyDetails, is_supplier: event.target.checked })
  }

  const handleCompanyRocInvoiceChange = (event: any) => {
    let val = event.target.checked
    setMasterCompanyDetails({ ...masterCompanyDetails, parser_setting: { ...masterCompanyDetails.parser_setting, roc_invoice: val } })
  }

  const handleCompanyRocSupplierChange = (event: any) => {
    let val = event.target.checked
    setMasterCompanyDetails({ ...masterCompanyDetails, parser_setting: { ...masterCompanyDetails.parser_setting, roc_supplier: val } })
  }
  const handleDomainChange = (event:any)=>{
    setMasterCompanyDetails({ ...masterCompanyDetails, domain: event.target.value})
  }

  const renderSupplierItems =()=>{
    return(
      <Grid container className={classes.row}>
      <div style={{ marginLeft: 10 }}>
        <FormControlLabel
          style={{ flex: 1, marginTop: 8 }}
          control={
            <Switch

              checked={masterCompanyDetails ? masterCompanyDetails.parser_setting && masterCompanyDetails.parser_setting.roc_invoice : false}
              onChange={handleCompanyRocInvoiceChange}
              color="primary"
              style={{ paddingLeft: "10px" }}
              disabled={!isEditMode()}
            />
          }
          labelPlacement='start'
          label="Roc Invoice"
        />
        <FormControlLabel
          style={{ flex: 1, marginTop: 8 }}
          control={
            <Switch

              checked={masterCompanyDetails ? masterCompanyDetails.parser_setting && masterCompanyDetails.parser_setting.roc_supplier : false}
              onChange={handleCompanyRocSupplierChange}
              color="primary"
              style={{ paddingLeft: "10px" }}
              disabled={!isEditMode()}
            />
          }
          labelPlacement='start'
          label="Roc Supplier"
        />
      </div>
    </Grid>

    )
  }
  return (
    <div>
      {
        isLoading &&
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div style={{ maxWidth: '100%' }}>


          <Card className={classes.card}>
            <CardContent>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '8px',
                borderBottom: '2px solid #adb5bd'
              }}>
                <div style={{ display: 'flex' }}>
                  <IconButton aria-label="back"
                    onClick={confirmBackNavigation}>
                    <ArrowBackIcon />
                  </IconButton>
                  <Typography variant='h5'
                    className={classes.title}>Master Company Detail</Typography>
                </div>
                <div style={{ alignSelf: 'center' }}>
                  <Tooltip title="Toggle Page Mode">
                    <IconButton
                      style={{
                        color: isEditMode() ? '#ffb300' : GlobalStyles.primaryColor,
                        border: '2px solid currentColor',
                        borderRadius: '4px',
                        padding: '4px'
                      }}
                      onClick={() => togglePageMode()}>
                      {isEditMode() ? <EditIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '8px'
              }}>

                {
                  isEditMode() &&
                  isDataEdited() &&
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button}
                    // startIcon={<SaveIcon />}
                    onClick={() => handleDiscardChanges()}
                  >Discard Changes</Button>
                }
                {
                  isEditMode() &&
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                    style={{ marginRight: 0 }}
                    startIcon={<SaveIcon />}
                    onClick={() => handleSave()}
                  >Save</Button>
                }
              </div>
              <Grid container spacing={2} style={{ paddingTop: 4 }}>
                <Grid item xs={6}>
                  {
                    <React.Fragment>
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '75%', marginTop: 16 }}>
                          <div style={{ width: '20%' }}>
                            <TextField
                              id="id"
                              className={classes.textFieldCardRow}
                              style={{}}
                              label="ID"
                              margin="dense"
                              variant="outlined"
                              value={masterCompanyDetails ? masterCompanyDetails.id : ''}
                              InputProps={{
                                disabled: true,
                              }}
                            />
                          </div>
                          {renderBrandsAndCompaniesItems()}

                        </div>

                      </Grid>
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '75%' }}>

                          <TextField
                            id="name"
                            className={classes.textFieldCardRow}
                            label="Name"
                            margin="dense"
                            variant="outlined"
                            value={masterCompanyDetails ? masterCompanyDetails.name : ''}
                            onChange={handleNameChange}
                            onBlur={() => {
                              if (isEditMode()) {
                                if (masterCompanyDetails && masterCompanyDetails.name.length === 0) {
                                  setNameError({
                                    isError: true,
                                    message: 'Name cannot be blank.'
                                  });
                                } else {
                                  setNameError({
                                    isError: false,
                                    message: ''
                                  });
                                }
                              }
                            }}
                            error={nameError.isError}
                            helperText={nameError.message}
                            InputProps={{
                              readOnly: !isEditMode(),
                              disabled: !isEditMode(),
                            }}
                          />

                        </div>
                      </Grid>
                      {/* <Grid container className={classes.row}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '75%' }}>
                          <TextField
                            id="email"
                            className={classes.textFieldCardRow}
                            label="Email"
                            disabled={true}
                            margin="dense"
                            variant="outlined"
                            value={masterCompanyDetails ? masterCompanyDetails.email : ''}
                            onChange={(event) => handleNameChange(event.target.value)}
                            onBlur={() => {
                              if (isEditMode()) {
                                if (masterCompanyDetails && masterCompanyDetails.email.length === 0) {
                                  setNameError({
                                    isError: true,
                                    message: 'Email cannot be blank.'
                                  });
                                } else {
                                  setNameError({
                                    isError: false,
                                    message: ''
                                  });
                                }
                              }
                            }}
                            error={nameError.isError}
                            helperText={nameError.message}
                            InputProps={{
                              readOnly: !isEditMode(),
                            }}
                          />


                        </div>
                      </Grid> */}
                      <Grid container className={classes.row}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '75%' }}>

                          <TextField
                            id="url"
                            className={classes.textFieldCardRow}
                            label="Domain"
                            margin="dense"
                            variant="outlined"
                            // disabled={true}
                            value={masterCompanyDetails ? masterCompanyDetails.domain && masterCompanyDetails.domain : ''}
                            onChange={handleDomainChange}
                            // onBlur={() => {
                            //   if (isEditMode()) {
                            //     if (masterCompanyDetails && masterCompanyDetails.domain.length === 0) {
                            //       setNameError({
                            //         isError: true,
                            //         message: 'Domain cannot be blank.'
                            //       });
                            //     } else {
                            //       setNameError({
                            //         isError: false,
                            //         message: ''
                            //       });
                            //     }
                            //   }
                            // }}
                            error={nameError.isError}
                            helperText={nameError.message}
                            InputProps={{
                              readOnly: !isEditMode(),
                            }}
                          />
                        </div>
                      </Grid>

                      <Grid container className={classes.row}>
                        <div style={{ marginLeft: 10 }}>
                          <FormControlLabel
                            style={{ flex: 1, marginTop: 8 }}
                            control={
                              <Switch

                                checked={masterCompanyDetails ? masterCompanyDetails.is_supplier : false}
                                onChange={handleCompanySupplierChange}
                                color="primary"
                                style={{ paddingLeft: "10px" }}
                                disabled={!isEditMode()}
                              />
                            }
                            labelPlacement='start'
                            label="Supplier"
                          />
                        </div>
                      </Grid>
                      {masterCompanyDetails ? masterCompanyDetails.is_supplier && renderSupplierItems() : null }
                      <div style={{ position: 'relative', bottom: '-10px', right: '2px' }}>
                        <Button variant="contained" color="primary"
                          disabled={!isEditMode()}
                          onClick={handleAddAddressClick}
                        >
                          Add Address
                        </Button>
                      </div>
                      {changeLocationCodeGroup && getCodeGroupExpansion()}
                    </React.Fragment>
                  }
                </Grid>
                <Grid item xs={6}>
                  {
                    isMediaLoading &&
                    <div className={classes.rowCenter}>
                      <CircularProgress />
                    </div>
                  }
                  {!isMediaLoading &&
                    <>

                      <div className={classes.dnd}>
                        <FileDrop
                          onDrop={(files, event) => handleFileDrop(files, event)}
                          onTargetClick={onTargetClick}
                        >
                          Drop files here to upload
                        </FileDrop>
                      </div>
                      <Image
                        itemData={masterCompanyDetails ? masterCompanyDetails : null}
                        mediaUrls={mediaUrls ? mediaUrls.length > 0 ? mediaUrls : null : null}
                        mediaData={mediaData ? mediaData.length > 0 ? mediaData : null : null}
                        handleUploadClick={handleUploadClick}
                        showDefaultMedia={showDefaultMedia ? showDefaultMedia : null}
                        defaultMediaData={defaultMediaData ? defaultMediaData : null}
                        userUploadedMedia={userUploadedMedia ? userUploadedMedia : null}
                        handleSelectedMediaForAction={handleSelectedMediaForAction}
                        handleSelectedMedia={handleSelectedMedia}
                        userSelectedMedia={userSelectedMedia ? userSelectedMedia : null}
                        onFileSelectChangeHandler={onFileSelectChangeHandler}
                        selectedMediaForAction={selectedMediaForAction ? selectedMediaForAction : null}
                        handleMenuItemClick={handleMenuItemClick}
                        optionsBtnAnchorEl={optionsBtnAnchorEl}
                        fileInputRef={fileInputRef}
                      />
                    </>
                  }
                  {!changeLocationCodeGroup && getCodeGroupExpansion()}
                </Grid>
              </Grid>

            </CardContent>
          </Card>
        </div>
      }

      <ConfirmModal
        isOpen={confirmModalData.isOpen}
        onCancel={confirmModalData.onCancel}
        onConfirm={confirmModalData.onConfirm}
        confirmBtnText={confirmModalData.confirmBtnText}
        dialogTitle={confirmModalData.title}
        dialogMessage1={confirmModalData.message1}
        dialogMessage1Color={GlobalStyles.red}
        dialogMessage2={confirmModalData.message2}
      />



      <AddAddress
        party_id={AddressPartyType.BUSINESS}
        isOpen={openAddressDialog}
        entity_id={props.match.params.id}
        setDialogBoxState={setOpenAddressDialog}
        setNewAddressAdded={setNewAddressAdded}
        sub_party_type={AddressSubPartyType.MasterCompany}

      />
      {masterCompanyDetails != null &&
        <div>
          <AddressDetails
            entity_id={props.match.params.id}
            party_id={AddressPartyType.BUSINESS}
            newAddressAdded={newAddressAdded}
            setNewAddressAdded={setNewAddressAdded}
            isEditMode={isEditMode}
            sub_party_type={AddressSubPartyType.MasterCompany}

          />
        </div>
      }

      <MasterCodeGroupModal
        isOpen={openCodeGroupModal}
        onClose={handleCloseCodeGroupDialog}
        mode={codeGroupModalMode ? codeGroupModalMode : null}
        codeData={codeGroupObj ? codeGroupObj : null}
        onUpdate={(codeData) => handleUpdateCodeGroup(codeData)}
        onSave={(codeData) => handleSaveCodeGroup(codeData)}
        itemData={masterCompanyDetails ? masterCompanyDetails : null}
        toast={toast ? toast : null}
        handleEndCodeBlock={handleEndCodeBlock}
        handleStartCodeBlock={handleStartCodeBlock}
        codeGroupConflicts={newCodeGroupConflicts}
        isLoading={isModalLoading}
        blockError={codeGroupObj && codeGroupObj.code_block_end.length > 0 && ((codeGroupObj.code_block_start.length > codeGroupObj.code_block_end.length) || (codeGroupObj.code_block_start.length < codeGroupObj.code_block_end.length) || (Number(codeGroupObj.code_block_start) > Number(codeGroupObj.code_block_end))) === true ? true : false}
        handlResetToast={handleCloseToast}
        newGroupCreated={newGroupCreated}
      />
    </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterCompanyDetail);