
import { combineReducers, Action } from 'redux';
import { 
    GET_MASTER_SUPPLIERS, GET_MASTER_SUPPLIERS_SUCCESS, GET_MASTER_SUPPLIERS_FAILURE,
    ADD_MASTER_SUPPLIER, ADD_MASTER_SUPPLIER_SUCCESS, ADD_MASTER_SUPPLIER_FAILURE,
    UPDATE_MASTER_SUPPLIER, UPDATE_MASTER_SUPPLIER_SUCCESS, UPDATE_MASTER_SUPPLIER_FAILURE,
    DELETE_MASTER_SUPPLIER, DELETE_MASTER_SUPPLIER_SUCCESS, DELETE_MASTER_SUPPLIER_FAILURE
} from '../actions/actionTypes';
import { MasterSupplier } from '../../models';


export interface InitialState {
    data: MasterSupplier[]
    isFetching: boolean
    success: boolean
    error: boolean
    errorData: any
}

const initialState: InitialState = {
    data: [] as MasterSupplier[],
    isFetching: false, 
    success: false,
    error: false,
    errorData: null
}


export function masterSupplierListReducer(state = initialState, action: any) {
    switch(action.type) {
        case GET_MASTER_SUPPLIERS: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case GET_MASTER_SUPPLIERS_SUCCESS: return {
            ...state,
            isFetching: false,
            data: action.data.results,
            success: true,
            error: false,
            errorData: null
        }
        case GET_MASTER_SUPPLIERS_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err: null
        }
        case DELETE_MASTER_SUPPLIER: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case DELETE_MASTER_SUPPLIER_SUCCESS: return {
            ...state,
            isFetching: false,
            // data: action.data.results,
            success: true,
            error: false,
            errorData: null
        }
        case DELETE_MASTER_SUPPLIER_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        }            
        case UPDATE_MASTER_SUPPLIER: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case UPDATE_MASTER_SUPPLIER_SUCCESS: return {
            ...state,
            isFetching: false,
            // data: action.data.results,
            success: true,
            error: false,
            errorData: null
        }
        case UPDATE_MASTER_SUPPLIER_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err: null
        }            
        case ADD_MASTER_SUPPLIER: return {
            ...state, 
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case ADD_MASTER_SUPPLIER_SUCCESS: return {
            ...state,
            isFetching: false,
            // data: action.data.results,
            success: true,
            error: false,
            errorData: null
        }
        case ADD_MASTER_SUPPLIER_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        }            
        default: return state;
    }
}

