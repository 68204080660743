import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  Checkbox,
  LinearProgress,
  SvgIcon,
  IconButton
} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import {
  isSuperAdmin,
} from '../../../shared/lib/PermissionManager';
import { enqueueSnackbar, Toast, ToastError, ToastSuccess } from '../../../shared/components/Notifier';
import { createBusiness, updateBusiness, deleteBusiness, getBusinessByID } from '../../api';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
  getErrorMsg
} from '../../../shared/lib/Localization';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '0',
    },
    appBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: "1px solid rgb(0, 0, 0, 0.70)",
      boxShadow: 'none',
      padding: '.5rem',
      height: '100%',
      borderTopRightRadius: '.5rem',
      borderTopLeftRadius: '.5rem',
      backgroundColor: '#fff',
      color: 'rgb(25, 118, 210)'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
    },
    title: {
      fontWeight: 800,
      // marginBottom: '1rem'
    },
    card_content: {
      boxShadow: 'none',
      borderBottomRightRadius: '.5rem',
      borderBottomLeftRadius: '.5rem',
      borderTopRightRadius: '0',
      borderTopLeftRadius: '0'
    },
    form: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    inputType1: {
      width: '100%',
      display: 'flex',
      gap: '1rem',
      marginTop: '.5rem'
    },
    inputType2: {
      width: '100%',
    },
    radio: {
      display: 'flex'
    },

    btn: {
      backgroundColor: "primary",
      marginLeft: "auto",
      order: 2,
    },
    btn0: {
      backgroundColor: "red",
      marginLeft: "auto",
      order: 2,
    },
    submit_div: {
      width: '100%'
    },
    bottomDiv: {
      display: 'flex',
      marginLeft: 'auto',
      flexDirection: 'row',
      order: 2,
      gap: '.5rem'
    },
    h6: {
      fontWeight: 800
    },
    progressBar: {
      marginBottom: '0.5rem',
      width: '100%'
    },
  }),
);

const CreateBusinessScreen = (props: any) => {
  const { height, width } = useWindowDimensions();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const container = window !== undefined ? () => window().document.body : undefined;
  const [input, setInput] = React.useState({
    id: "",
    name: "",
    korona_uuid: "",
    company: "",
    domain: "",
    pos_key: "",
    korona_api_key: "",
    korona_username: "",
    korona_password: "",
    korona_api_v3_base_url: "https://167.koronacloud.com",
    db_sync: { korona_db_sync: false, db_sync_initial: false } as any,
    is_deleted: false,
    korona_api_v1_base_url: "https://167.koronacloud.com",
    is_supplier: false
  });

  const [inputError, setInputError] = React.useState({
    name: false,
    korona_uuid: false,
    company: false,
    domain: false,
    pos_key: false,
    korona_api_key: false,
    korona_username: false,
    korona_password: false,
    korona_api_v3_base_url: false,
    db_sync: { korona_db_sync: false, db_sync_initial: false },
    is_deleted: false,
    korona_api_v1_base_url: false
  });

  const [isSubmitDisable, setSubmitDisable] = React.useState(false);
  const [isUpdateDisable, setUpdateDisable] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isUpdating, setUpdating] = React.useState(false);

  useEffect(() => {
    if (Object.values(inputError).includes(true)) {
      setSubmitDisable(true)
    }
  }, [inputError])


  useEffect(() => {
    if (props.location.state) {
      setUpdating(true);
      getBusiness(props.location.state.id);
    }
  }, [props.location.state])



  const isValid = (name: any, value: any, is_required) => {
    // var pattern: any;
    var pattern = /\w+(.)*$/;
    if (!is_required || !value) {
      return true;
    }
    else if (name == "korona_api_v3_base_url" || name == "korona_api_v1_base_url") {
      pattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
    }
    else if (name == "korona_uuid" || name == "korona_api_key") {
      pattern = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{12}$/;
    }
    return pattern.test(value);
  }

  const handleInputChange = (event: any) => {
    console.log(event.target.value)
    if (event.target.name === "is_deleted") {
      setInput({ ...input, [event.target.name]: event.target.value === "true" ? true : false });
      inputError[event.target.name] = !isValid(event.target.name, event.target.value, event.target.required);
      setInputError(inputError);
    }
    else if (event.target.name === "korona_db_sync" || event.target.name === "db_sync_initial") {
      // input.db_sync[event.target.name] = event.target.checked;
      if (!event.target.checked && event.target.name === "db_sync_initial" && input.db_sync.korona_db_sync) {
        setInput({ ...input, db_sync: { "korona_db_sync": false, [event.target.name]: event.target.checked } });
        return;
      }
      setInput({ ...input, db_sync: { ...input.db_sync, [event.target.name]: event.target.checked } });
      inputError[event.target.name] = !isValid(event.target.name, event.target.checked, event.target.required);
      setInputError(inputError);
    }
    else if (event.target.name === "is_supplier") {
      setInput({ ...input, [event.target.name]: event.target.checked });
      inputError[event.target.name] = !isValid(event.target.name, event.target.checked, event.target.required);
      setInputError(inputError);
    }
    else {
      setInput({ ...input, [event.target.name]: event.target.value });
      inputError[event.target.name] = !isValid(event.target.name, event.target.value, event.target.required);
      setInputError(inputError);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("-----------create response---->", input);
    input.company = null;
    setInput(input);
    setLoading(true);
    if (isSuperAdmin(props)) {
      createBusiness(input)
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then((parseJSON: any) => {
          props.showToast({
            message: "Business has been created successfully", options: ToastSuccess
          })
          setLoading(false);
          setSubmitDisable(false);
          setInput({
            id: "",
            name: "",
            korona_uuid: "",
            company: "",
            domain: "",
            pos_key: "",
            korona_api_key: "",
            korona_username: "",
            korona_password: "",
            korona_api_v3_base_url: "",
            db_sync: { korona_db_sync: false, db_sync_initial: false },
            is_deleted: false,
            korona_api_v1_base_url: "",
            is_supplier: false
          })
        })
        .catch((error: any) => {
          console.log(error)
          setLoading(false);
          setSubmitDisable(false);
          let msg = "Failed to create business";
          props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
        })
    }
    return null;
  }

  const getBusiness = (id) => {
    getBusinessByID(id)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((parseJSON: any) => {
        setInput(parseJSON);
      })
      .catch((error: any) => {
        console.log(error)
        setLoading(false);
        setUpdateDisable(true);
        let msg = 'Failed to fetch business.';
        props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
      })
  }

  const handleUpdate = () => {
    console.log("hi")
    updateBusiness(input)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        props.showToast({
          message: 'Business updated successfully!',
          options: ToastSuccess,
        });
      })
      .catch((error: any) => {
        let msg = 'Failed to update business.';
        props.showToast({ message: getErrorMsg(error, msg), options: ToastError });
      });
    return null;
  }

  return (
    <div className={classes.root} >
      {
        isLoading &&
        <LinearProgress color="secondary" className={classes.progressBar} />
      }
      <CssBaseline />
      <AppBar position="relative" className={classes.appBar}>
        <IconButton onClick={() => props.history.push("/web/business/list-businesses")}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" noWrap >
          {isUpdating ?
            // "Business & Connection Details"
            "Update Business"
            : "Create Business"}
        </Typography>
      </AppBar>
      <div className={classes.content}>

        <Card className={classes.card_content}>
          <CardContent>
            <Typography variant="h6" className={classes.title} gutterBottom>
              Business Information
            </Typography>
            {/* <form className={classes.form} autoComplete="off" onSubmit={(event) => handleSubmit(event)}  > */}
            {/* <fieldset disabled={isUpdating && true} style={{ opacity: isLoading ? "0.5" : "1" }}> */}
            {isUpdating && <TextField className={classes.inputType2} value={input.id} id="business-id" label="ID" name="ID" disabled />}
            <TextField className={classes.inputType2} value={input.name} id="business-name" label="Business Name"
              onChange={handleInputChange}
              name="name" error={inputError.name} helperText={inputError.name ? "Invalid Format" : ""} required disabled={isLoading} />
            {/* <TextField className={classes.inputType2} id="company" label="Company" value={input.name} 
              onChange={handleInputChange}
               name="company" error={inputError.company} helperText={inputError.company ? "Invalid Format" : ""} disabled/> */}
            <div className={classes.inputType1} style={{ flexDirection: width > 1051 ? "row" : "column", }} >
              <TextField id="domain" label="Domain" className={classes.inputType2} value={input.domain}
                onChange={handleInputChange}
                name="domain" error={inputError.domain} helperText={inputError.domain ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />

              <TextField id="pos_key" label="POS Key" className={classes.inputType2} value={input.pos_key}
                onChange={handleInputChange}
                name="pos_key" error={inputError.pos_key} helperText={inputError.pos_key ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />
            </div>
            <div className={classes.inputType1} style={{ flexDirection: width > 1051 ? "row" : "column", }}>

              <TextField className={classes.inputType2} id="korona_v1_key" label="Korona API V1 Key" value={input.korona_api_key}
                onChange={handleInputChange}
                name="korona_api_key" required error={inputError.korona_api_key} helperText={inputError.korona_api_key ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />

              <TextField className={classes.inputType2} id="accound_id" name="korona_uuid" label="Account ID" value={input.korona_uuid}
                onChange={handleInputChange}
                required error={inputError.korona_uuid} helperText={inputError.korona_uuid ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />
            </div>
            <div className={classes.inputType1} style={{ flexDirection: width > 1051 ? "row" : "column", }}>
              <TextField className={classes.inputType2} id="korona_username" label="Korona Username"
                onChange={handleInputChange}
                value={input.korona_username} name="korona_username" required error={inputError.korona_username} helperText={inputError.korona_username ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />
              <TextField className={classes.inputType2} id="korona_paswd" label="Korona Password"
                onChange={handleInputChange}
                value={input.korona_password} name="korona_password" required error={inputError.korona_password} helperText={inputError.korona_password ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />
            </div>
            <div className={classes.inputType1} style={{ flexDirection: width > 1051 ? "row" : "column", }}>
              <TextField className={classes.inputType2} id="korona_v1_baseurl" label="Korona API V1 Base URL"
                onChange={handleInputChange}
                value={input.korona_api_v1_base_url} name="korona_api_v1_base_url" required error={inputError.korona_api_v1_base_url} helperText={inputError.korona_api_v1_base_url ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />

              <TextField className={classes.inputType2} id="korona_v3_baseurl" label="Korona API V3 Base URL"
                onChange={handleInputChange}
                value={input.korona_api_v3_base_url} name="korona_api_v3_base_url" required error={inputError.korona_api_v3_base_url} helperText={inputError.korona_api_v3_base_url ? "Invalid Format" : ""} disabled={isLoading || isUpdating} />
            </div>
            {isUpdating && <RadioGroup row name="is_deleted" value={`${input.is_deleted}`}
              onChange={handleInputChange}
              style={{ margin: 0 }}>
              <FormControlLabel value="false" control={<Radio color='primary' disabled={isLoading} />} label="Active" disabled={isLoading} />
              <FormControlLabel value="true" control={<Radio color='primary' disabled={isLoading} />} label="Inactive" disabled={isLoading} />
            </RadioGroup>}
            <FormGroup row>
              <div>
                {!isUpdating && <FormControlLabel
                  control={
                    <Checkbox
                      checked={input.db_sync ? input.db_sync.db_sync_initial : false}
                      onChange={handleInputChange}
                      name="db_sync_initial"
                      color="primary"
                      disabled={isLoading || isUpdating} />
                  }
                  label="Korona Initial DB Sync"
                />}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={input.db_sync ? input.db_sync.korona_db_sync : false}

                      onChange={handleInputChange}

                      name="korona_db_sync"
                      color="primary"
                      disabled={isLoading || !(isUpdating || (input.db_sync && input.db_sync.db_sync_initial))} />
                  }
                  label="Korona DB Sync"
                />
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={input.is_supplier ? true : false}

                    onChange={handleInputChange}

                    name="is_supplier"
                    color="primary"
                    disabled={isLoading || isUpdating} />
                }
                label="Supplier"
              />

              {isUpdating ? <FormGroup className={classes.submit_div}>
                <Button disabled={isUpdateDisable || isLoading} size="small" variant="contained" color="primary" className={classes.btn} onClick={() => handleUpdate()}>Update</Button>
              </FormGroup>
                :
                <FormGroup className={classes.submit_div}>
                  <Button disabled={isSubmitDisable || isLoading} size="small" variant="contained" color="primary" className={classes.btn} onClick={(event) => handleSubmit(event)}>Submit</Button>
                </FormGroup>}
            </FormGroup>
            {/* </fieldset> */}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBusinessScreen);