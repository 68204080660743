import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar } from 'material-table';
import Container from '@material-ui/core/Container';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { PaginatedResponse } from '../../../shared/models';
import { Typography, Divider, SvgIcon, CircularProgress } from '@material-ui/core';
import { API_URLS } from '../../api/';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_MSG,ERROR_404 } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { MasterProductOrphans } from '../../models';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { getMasterProductOrphans} from '../../api';
import InputLabel from '@material-ui/core/InputLabel';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Paper } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    suppliermsg: {
      color: GlobalStyles.primaryColor,
      fontSize: 16
    },
    errorText: {
      color: theme.palette.error.main,
      fontWeight: 400
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    overwriteDataRadioButton: {
      // margin: auto;
      // width: 60%;
      // padding: 10px;
      marginLeft: '0.9rem'
    }
  }),
);


const  MasterProductOrphansScreen = (props:any) =>{

    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const [uiLoading, setUILoading] = useState(false);
    const tableRef = useRef<{ onQueryChange: any }>();
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isExporting, setExporting] = useState(false);
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    
    
    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
      };
    
    
  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      //exportCSV();
    }
    setExportBtnAnchorEl(null);
  };
  return (
  
    <Container>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <ExportStatusBar isVisible={isExporting} />
          <div style={{width:'100%'}}>
          <MaterialTable
          options={{
            ...tableOptions(tableQuery),
            thirdSortClick: false,
            draggable: false,
            sorting: true
          }}
          tableRef={tableRef}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataSourceMessage
            }
          }}
          columns={[
            {title:'Row Number',field:'rnum'},
            {title:'Master Product Id', field:'mp_id'},
            {title:'Master Product Name',field:'mp_name'},
            {title:'Number of Identifiers',field:'num_identifiers'}

          ]}
          data={query=>
            new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData={
                    data:[] as MasterProductOrphans[],
                    totalCount:0,
                    page:query.page

                };
                let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }

                  getMasterProductOrphans(query.page+1,query.pageSize,query.search,orderBy,null)
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((data:PaginatedResponse<MasterProductOrphans>)=>{
                    setErrorStatusVisible(false);
                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                    pageData={
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                    };
                    
                    setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize,
                        //page:( query.pageSize > 0 && data.results.length === data.count ) ? query.page : 0 ,
                        search: query.search
                      });
                      resolve(pageData);
                  })
                  .catch((error:any) => {
                    if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } 
                        // else if (error.status === 404 ) {
                        //   console.log("404 found")
                        //   setEmptyDataSourceMessage(ERROR_404);
                        //   setErrorStatusVisible(true);
                        // } 
                        else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                         page: query.page,    
                        pageSize: query.pageSize,
                        search: query.search
                      });
                      resolve(pageData);

                  });
                  

            })

          }
          title={
            <CustomTableTitle
              history={props.history}
              title={"Master Product Orphans"}
              icon={SVG_ICONS.product} />
          }
          actions={[
            {
              icon: 'save_alt',
              tooltip: 'Export',
              isFreeAction: true,
              onClick: handleExportClick,
              disabled: isExporting
            },
            {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
            },
          ]}
        
          
          />
             <ExportMenu
            anchorEl={exportBtnAnchorEl}
            handleClose={handleExportMenuClose} />
         
          </div>
        </Container>
       
       

  )

    
}

export default MasterProductOrphansScreen