import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Divider, SvgIcon } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useStyles } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

import { SelectTileModal, renderCard } from '../../components';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { ErrorStatusBar } from '../../../shared/components';
import { setSelectedDashboardTiles } from '../../../../store/actions';
import { saveUserSettings } from '../../api';
import { SupplierRouterPaths } from '../../../page/Routes/RouterPaths';
import { SupplierDashboardCards, Report, SelectedReport, SupplierSummaryFnMapping, ReportCard } from '../../models';
import moment from 'moment';

const SupplierDashboardScreen = (props: any) => {
    console.log(props);
    const classes = useStyles({});
    const { userData } = props.userLocalData.userData;
    const { selectedDashboardTiles } = props.userLocalData;
    const [reportCards, setReportCards] = useState<ReportCard[]>([]);
    const [isTileModalOpen, setTileModalOpen] = useState(false);
    const [reportItems, setReportItems] = useState<Report[]>([]);
    // console.log(props.userLocation)
    // console.log(userData)
    let userLocation = null;
    if (props.userLocalData.userLocation) {
        userLocation = props.userLocalData.userLocation;
    }

    useEffect(() => { // load selectedCards from settings
        const parentChildMap = new Map();
        const enabledTileIds: string[] = [];
        selectedDashboardTiles.forEach((t: SelectedReport) => {
            parentChildMap.set(t.id, t.items);
            t.isEnabled ? enabledTileIds.push(t.id) : null;
        });
        const cards = [...SupplierDashboardCards].filter(c => enabledTileIds.includes(c.id));
        cards.forEach(c => c.items.forEach(child => {
            child.isVisible = parentChildMap.get(c.id).includes(child.id);
        }));
        setReportCards(cards);

        const allReportItems = SupplierDashboardCards.map(i => {
            const t: Report = {
                id: i.id,
                title: i.title,
                items: i.items,
                isEnabled:
                    parentChildMap.has(i.id) && enabledTileIds.includes(i.id) ||
                    !parentChildMap.has(i.id) && !enabledTileIds.includes(i.id)
            };
            return t;
        });
        setReportItems(allReportItems);
    }, [props.userLocalData.selectedDashboardTiles]);


    const persistDashboardTiles = (selectedReports: SelectedReport[]) => {
        if (userData && userData.settings) {
            const payload = { ...userData.settings };
            payload.selectedDashboardTiles = selectedReports;
            saveUserSettings(payload);
        }
    };

    useEffect(() => {
        if (props.userLocalData && props.userLocalData.selectedBusiness) {
            setTimeout(() => {
                const sb = props.userLocalData.selectedBusiness;
                if (sb.business_id) {
                    getSummaryData(sb.business_id);
                }
            }, 500);
        }
    }, [props.userLocalData && props.userLocalData.selectedBusiness, reportCards]);

    const getSummaryData = (businessId: number) => {
        const summaryMappings = reportCards.map(c => c.summaryMapping);
        summaryMappings.forEach(mapping => {
            if (mapping) {
                props[SupplierSummaryFnMapping[mapping]](businessId);
            }
        });
    };

    const getInventorySummary = () => {
        return '' + props.inventorySummary.totalInventoryCountList +
            ' inventory count lists in ' +
            props.inventorySummary.totalInventoryCounts +
            ' inventory counts';
    };

    const navigateTo = (location: string) => {
        const { history } = props;
        switch (location) {
            case 'products': history.push(SupplierRouterPaths.productList);
                break;
            case 'businesses': history.push(SupplierRouterPaths.businesses);
                break;
        }
    };

    const toggleCards = (checked: boolean, reportId: string) => {
        const allReportItems = [...reportItems];
        allReportItems.forEach(c => c.id === reportId ? c.isEnabled = checked : null);
        setReportItems(allReportItems);
    };

    const renderBlankCard = () => {
        return (
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            style={{ fontSize: 16 }}
                            color="textSecondary" gutterBottom>
                            Getting Started
                        </Typography>
                        <IconButton onClick={() => setTileModalOpen(true)}>
                            <AddIcon />
                        </IconButton>
                    </CardContent>
                </Card>
            </Grid>
        );
    };

    return (
        <div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h5'
                    style={{ color: GlobalStyles.primaryColor }}>Dashboard</Typography>
                <div>
                    <Tooltip title="Configure Tiles" placement="bottom">
                        <IconButton onClick={() => setTileModalOpen(true)}>
                            {/* <SettingsIcon /> */}
                            <SvgIcon>
                                <path d={SVG_ICONS.gridOutlinePlus} />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Divider className={classes.subDivider} />
            <SelectTileModal
                isOpen={isTileModalOpen}
                onCancel={() => setTileModalOpen(false)}
                onConfirm={(selected) => {
                    persistDashboardTiles(selected);
                    props.saveSelectedDashboardTiles(selected);
                    setTileModalOpen(false);
                }}
                reports={reportItems}
                selectedReportItems={selectedDashboardTiles}
                toggleCards={toggleCards}
            />
            <Grid container spacing={3} style={{ paddingTop: 16 }}>
                {
                    reportCards.map(card => {
                        const summaryData = card.summaryMapping ? props[card.summaryMapping] : null;
                        return renderCard(classes, card, summaryData, navigateTo);
                    })
                }
            </Grid>
            {
                reportCards && reportCards.length === 0 &&
                <Grid container spacing={3} style={{ paddingTop: 16 }}>
                    {renderBlankCard()}
                </Grid>
            }
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        saveSelectedDashboardTiles: (selectedReports: SelectedReport[]) => dispatch(setSelectedDashboardTiles(selectedReports))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDashboardScreen);