import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography,Dialog, DialogActions, 
  DialogContent, DialogTitle } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Theme, createStyles, makeStyles, } from '@material-ui/core/styles';
import { Toast, enqueueSnackbar, ToastError,ToastSuccess} from '../../../shared/components/Notifier';
import Button from '@material-ui/core/Button';
import {getCoreUserDetails, getIsPasswordValid, getIsPinValid, resetPassword, resetPin, updateProfileChanges} from '../../api/index';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { logOutUser } from '../../../auth/data/actions/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      paper:{
          minHeight:'90%',
      },
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center',
      padding:15,
      paddingBottom:40,
    },
    innerdiv:{
        display:'flex',
        flexDirection:'row',
        paddingLeft:15,
        paddingBottom:20,
        justifyContent:'start'
    },
    labelStyle:{
        paddingRight:10
    },
    textFieldStyle:{
        marginTop:-7,
        width:'30%',
    },
    saveBtn:{
        padding:5,
        margin:12,
        backgroundColor:GlobalStyles.primaryColor,
        color:GlobalStyles.textWhite ,
    },
    checkPasswordBtn:{
      padding:5,
      marginTop:-7,
      marginBottom:7,
      marginLeft:35,
      color:GlobalStyles.textWhite ,
      textDecorationColor:GlobalStyles.primaryColor,  
      backgroundColor:GlobalStyles.primaryColor,  
    },
    checkSpinner:{
      padding:5,
      marginTop:-7,
      marginBottom:7,
      marginLeft:35,
    },
    changePasswordBtn:{
      padding:5,
      marginTop:-7,
      marginBottom:7,
      marginLeft:35,
      color:GlobalStyles.textWhite ,
      textDecorationColor:GlobalStyles.primaryColor,  
      backgroundColor:GlobalStyles.red,  
    },
    spinner:{
      width:'50%',
      display:'flex',
      flexDirection:'row-reverse',
    },
    textfield:{
      width:'50%',
      display:'flex',
      flexDirection:'row-reverse',
    },
  })
)

const AccountScreen = (props: any) => {
    const classes = useStyles({});
    const [authToken,setAuthToken]=useState(props.userLocalData.userData.userData.auth_token);
    const [userDetails,setUserDetails]=useState(null);
    const [userName,setUserName]=useState(null);
    const [fname,setFname]=useState(null);
    const [newFname,setNewFname]=useState(null)
    const [lname,setLname]=useState(null);
    const [newLname,setNewLname]=useState(null)
    const [userEmail,setUserEmail]=useState(null);
    const [newUserEmail,setNewUserEmail]=useState(null)
    const [newUserName,setNewUserName]=useState(null);
    const [initialState,setIntialState]=useState(null);
    const [isDisable,setIsDisable]=useState(true);
    const [isPasswordValid,setIsPasswordValid]=useState(false);
    const [oldPassword,setOldPassword]=useState(null);
    const [newPassword,setNewPassword]=useState(null);
    const [enablePasswordBtn,setEnablePasswordBtn]=useState(false);
    const [oldPin,setOldPin]=useState(null);
    const [newPin,setNewPin]=useState(null);
    const [enablePinBtn,setEnablePinBtn]=useState(false);
    const [isPinValid,setIsPinValid]=useState(false);
    const [incorrectAttempts,setIncorrectAttempts]=useState(0);
    const [isLoading,setIsLoading]=useState(true);
    const [checkingPassword,setCheckingPassword]=useState(false);
    const [checkingPin,setCheckingPin]=useState(false);
    const [bussinessLocations,setBussinessLocations]=useState(props.userLocalData.selectedLocations);
    const [selectedLocation,setSelectedLocation]=useState(null);
    const [locationId,setlocationId]=useState(null);        
    const [location,setLocation]=useState(null);
    const [confirmPopup,setConfirmPopup]=useState(false);
    useEffect(()=>{
      let requestData={
        auth_token:authToken
      }
      getCoreUserDetails(requestData)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
       setUserDetails(data)
        setUserName(data.username)  
        setNewUserName(data.username)      
        setFname(data.first_name)
        setNewFname(data.first_name)
        setLname(data.last_name)
        setNewLname(data.last_name)
        setUserEmail(data.email)
        setNewUserEmail(data.email)
        setIsLoading(false)
      })
      .catch((error: any) => {  
        console.log("inside error", error)
      });

    },[])
 
    // useEffect(() => {
    //     console.log("useState Called=======>");  
    //     setUserName(props.userLocalData.userData.userData.username);
    //     setBussinessLocations(props.userLocalData.selectedLocations);
    //   }, [props.userLocalData]);

    useEffect(() => {
        if(userName!=newUserName || fname!=newFname || lname!=newLname || userEmail!=newUserEmail)
        setIsDisable(false)
        else
        setIsDisable(true)
      }, [newUserName,newFname,newLname,newUserEmail,userName,fname,lname,userEmail]);  

      useEffect(() => {
        oldPassword?setEnablePasswordBtn(false):setEnablePasswordBtn(true)
        oldPin?setEnablePinBtn(false):setEnablePinBtn(true)
      }, [oldPassword,oldPin]);  
      
    // console.log("Location",bussinessLocations)
    const handleUserNameChange = (username: string) => {
        setNewUserName(username);
      };
      const handleFnameChange = (firstname: string) => {
        setNewFname(firstname);
      };
      const handleLnameChange = (lastname: string) => {
        setNewLname(lastname);
      };
      const handleEmailChange = (email: string) => {
        setNewUserEmail(email);
      };  
      
    const handleOldPasswordInput = (password: string) => {
      setOldPassword(password);
      }; 
      
    const handleNewPasswordInput = (password: string) => {
        setNewPassword(password);
        };   

    const checkPasswordClick = ()=>{
      const password=oldPassword
      setCheckingPassword(true)
      let requestData={
        auth_token:authToken,
        password:password
      }
      getIsPasswordValid(requestData)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        setIsPasswordValid(data.status)
        setCheckingPassword(false)
        data.status==true?setIncorrectAttempts(0):setIncorrectAttempts(incorrectAttempts+1)
        if(incorrectAttempts==3){
          kickOutUser()
        }
        else{
        data.status?props.showToast({ message: "Correct Password", options: ToastSuccess }) :props.showToast({ message: "Incorrect Password You Have " + String(3 - Number(incorrectAttempts)) +" Attempts", options: ToastError })  
        }
      })
      .catch((error: any) => {
         console.log("inside error", error)
      });

      // console.log("incorrect atttepmst======>",incorrectAttempts)
      // if(incorrectAttempts==3)
      //   kickOutUser()
    } 
    
  const changePasswordClick=()=>{
    if(newPassword==oldPassword){
      props.showToast({ message: "New Password can't be your old password", options: ToastError })
    }
    else{
      const password=newPassword
      let requestData={
        auth_token:authToken,
        password:password
      }
      resetPassword(requestData)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then((data: any) => {
        // console.log("data in status=====>",data.status)
        props.showToast({ message: "Password Reset Successfully", options: ToastSuccess })
        setIsPasswordValid(false)
        setOldPassword("")
      })
      .catch((error: any) => {
        console.log("inside error", error)
      });
     
    }
  }
    
  const checkPinClick=()=>{
    setCheckingPin(true)
    const pin=oldPin
    let requestData={
      auth_token:authToken,
      pin:pin
    }
    getIsPinValid(requestData)
    .catch(handleError)
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      setIsPinValid(data.status)
      data.status==true?setIncorrectAttempts(0):setIncorrectAttempts(incorrectAttempts+1)
      setCheckingPin(false)
      if(incorrectAttempts==3){
        kickOutUser()
      }
      else{
      data.status?props.showToast({ message: "Correct Pin", options: ToastSuccess }) :props.showToast({ message: "Incorrect Pin You Have " + String(3 - Number(incorrectAttempts)) +" Attempts", options: ToastError })  
    }
    })
    .catch((error: any) => {
      console.log("inside error", error)
    });
    
  }
  const handleOldPinInput =(pin:string)=>{
    setOldPin(pin)
  }

  const handleNewPinInput=(newPin:string)=>{
    setNewPin(newPin)
  }


  const changePinClick=()=>{
    if(newPin==oldPin){
      props.showToast({ message: "New Pin can't be your old Pin", options: ToastError })
    }
    else{
      const pin = newPin
      let requestData = {
        auth_token: authToken,
        pin: pin
      }
      resetPin(requestData)
        .catch(handleError)
        .then(checkStatus)
        .then(parseJSON)
        .then((data: any) => {
          console.log("data in status=====>", data.status)
          props.showToast({ message: "Pin Reset Successfully", options: ToastSuccess })
          setIsPinValid(false)
          setOldPin(initialState)
          setNewPin(initialState)
        })
      .catch((error: any) => {
        console.log("inside error", error)
      });
      
    }
  }

  const kickOutUser=()=>{
    let requestData ={}
    requestData['auth_token']=authToken
    requestData['is_active']=0
    updateProfileChanges(requestData)
    .catch(handleError)
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
    console.log("data clred")
    })
    .catch((error: any) => {
      console.log("inside error", error)
    });
    signOut();
    props.showToast({ message: "Maximum Attempts reach Please Contact Support Team", options: ToastError })
    // props.signOutUser();
    // props.showToast({ message: "Maximum Attempts reach Please Contact Support Team", options: ToastError })
    // setTimeout(() => (window.location.href = '/sign-in'), 300);
  }

  const signOut = async () => {
    try {
        await localStorage.removeItem('headers');
        props.signOutUser();
    } catch {
         console.log('error in localStorage');
    }
};


    const saveProfileUpdate = ()=>{
    let requestData ={ }
    requestData['auth_token']=authToken    
    userName!=newUserName ? requestData['username']=newUserName :''
    fname!=newFname ? requestData['first_name']=newFname:''
    lname!=newLname ? requestData['last_name']=newLname:''
    userEmail!= newUserEmail ? requestData['email']= newUserEmail:''
    updateProfileChanges(requestData)
    .catch(handleError)
    .then(checkStatus)
    .then(parseJSON)
    .then((data: any) => {
      console.log("data in status=====>",data.status)
      setUserName(newUserName)
      setFname(newFname)
      setLname(newLname)
      setUserEmail(newUserEmail)
      setConfirmPopup(false)
    })
    .catch((error: any) => {
      console.log("inside error", error)
    });
    
    props.showToast({ message: "Profile Details Updated", options: ToastSuccess })
  } 

    return (
      <div>
      <Dialog open={confirmPopup} onClose={()=>setConfirmPopup(false)} >
      <form>
        <DialogTitle>Do you really want to save changes?</DialogTitle>
        <DialogActions>
          <Button size="small" variant="contained" onClick={()=>setConfirmPopup(false)} autoFocus >
            Cancel
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={() => saveProfileUpdate()}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    

        <Paper elevation={3} className={classes.paper}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        <Typography variant='h5' className={classes.title}>Account Details</Typography>
        <div>
        <Button disabled={isDisable} variant="contained" className={classes.saveBtn} onClick={()=>setConfirmPopup(true)}>
        Save Changes
      </Button>
      </div>
        </div>
{        isLoading ?(<div  className={classes.spinner}><CircularProgress /></div> ): (<div>
        <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>User Name:&nbsp;&ensp;&ensp;&ensp;&ensp; 
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              margin="dense"
              variant="outlined"
              value={newUserName}
              onChange={(event) => handleUserNameChange(event.target.value)}
            />
        </div>
        <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>First Name:&ensp;&ensp;&ensp;&ensp;&ensp; 
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              margin="dense"
              variant="outlined"
              value={newFname}
              onChange={(event) => handleFnameChange(event.target.value)}
            />
        </div>
        <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>Last Name:&ensp;&ensp;&ensp;&ensp;&ensp; 
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              margin="dense"
              variant="outlined"
              value={newLname}
              onChange={(event) => handleLnameChange(event.target.value)}
            />
        </div>
        <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>Email:&nbsp;&nbsp;&nbsp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp; 
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              margin="dense"
              variant="outlined"
              value={newUserEmail}
              onChange={(event) => handleEmailChange(event.target.value)}
            />
        </div>
        <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>Password:&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;     
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              label="Old Password"
              margin="dense"
              variant="outlined"
              defaultValue={oldPassword}
              onChange={(event) => handleOldPasswordInput(event.target.value)}
            />
        
        {checkingPassword ?( <CircularProgress className={classes.checkSpinner}  /> ):
        (<Button variant="contained" disabled={enablePasswordBtn} className={classes.checkPasswordBtn} onClick={()=>checkPasswordClick()}>
        Check Password
      </Button>)
      }
      </div>
      {isPasswordValid ?
      (
        <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>New Password:&nbsp;&ensp;     
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              label="New Password"
              margin="dense"
              variant="outlined"
              disabled={!isPasswordValid}
              defaultValue={newPassword}
              onChange={(event) => handleNewPasswordInput(event.target.value)}
        />
        
        <Button variant="contained" disabled={newPassword==null?true:false && !isPasswordValid  } className={classes.changePasswordBtn} onClick={()=>changePasswordClick()}>
        Change Password
      </Button>
        </div>)
        :null }

<div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>Device Pin:&nbsp;&nbsp;&ensp;&ensp;&ensp;&ensp;     
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              label="Old Device Pin"
              margin="dense"
              variant="outlined"
              defaultValue={oldPin}
              onChange={(event) => handleOldPinInput(event.target.value)}
            />

        {checkingPin ?( <CircularProgress className={classes.checkSpinner}  /> ):        
        (<Button variant="contained" disabled={enablePinBtn} className={classes.checkPasswordBtn} onClick={()=>checkPinClick()}>
        Check Pin
      </Button>)}
      </div>
      {isPinValid ?
      (
        <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>New Pin:&nbsp;&nbsp;&nbsp;&ensp;&ensp;&ensp;&ensp;&nbsp;&ensp;     
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              label="New Pin"
              margin="dense"
              variant="outlined"
              disabled={!isPinValid}
              defaultValue={newPin}
              onChange={(event) => handleNewPinInput(event.target.value)}
        />
        <Button variant="contained" disabled={newPin==null?true:false && !isPinValid  } className={classes.changePasswordBtn} onClick={()=>changePinClick()}>
        Change Pin
      </Button>
        </div>)
        :null } 


        {/* <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>Location Access:&nbsp; 
        </Typography>    
     
     <Select
     label="select bussiness"
    
      value={selectedLocation==null ? '' : selectedLocation.name }
    >
    {bussinessLocations.map((bussinessLocation)=>{
      return <MenuItem onClick={()=>setSelectedLocation(bussinessLocation)}>{bussinessLocation.name}</MenuItem>
    })}
  </Select>
        </div> */}
        {/* <div className={classes.innerdiv}>
        <Typography className={classes.labelStyle}>Permissions:&ensp;&ensp;&ensp;&nbsp;&nbsp; 
        </Typography>    
        <TextField
              className={classes.textFieldStyle}
              margin="dense"
              variant="outlined"
            />
        </div> */}
        </div>)}
        </Paper >
        </div>  
    );
};

const mapStateToProps = (state: any) => {

    return {
        userLocalData: state.userLocalData,
    };
};

const mapDispatchToProps =(dispatch:any)=>{
  return{
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    signOutUser: () => dispatch(logOutUser()),
  }
};
export default connect(mapStateToProps,mapDispatchToProps)(AccountScreen);