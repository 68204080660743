

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const SupplierBusinessScreen = (props: any) => {
    return (
        <div>
            <Typography variant='body1'>Supplier Businesses</Typography>
        </div>
    );
};

export default SupplierBusinessScreen;