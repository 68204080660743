
import {
    GET_STOCK_ORDER_PRODUCTS, GET_STOCK_ORDER_PRODUCTS_FAILURE,ADD_NEW_SO_ITEM,
    GET_STOCK_ORDER_PRODUCTS_SUCCESS, CREATE_STOCK_ORDER, CREATE_STOCK_ORDER_FAILURE,
    CREATE_STOCK_ORDER_SUCCESS, CLEAR_STOCK_ORDER_PRODUCTS,
    UPDATE_STOCK_ORDER_PRODUCT_IN_LIST, GET_STOCK_ORDER_SUMMARY,
    GET_STOCK_ORDER_SUMMARY_FAILURE, GET_STOCK_ORDER_SUMMARY_SUCCESS,
    DELETE_STOCK_ORDER_PRODUCT_IN_LIST
} from '../actions/actionTypes';
import { StockOrderItemDatum } from '../../models';

import cloneDeep from 'lodash/cloneDeep';

export interface InitialState {
    data: StockOrderItemDatum[];
}

const initialState = {
    data: [] as any[],
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
    hasErrors:false
};


export const stockOrderProductListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_STOCK_ORDER_PRODUCTS: return {
            // ...state, 
            data: [],
            isFetching: true,
            success: false,
            error: false,
            errorData: null
        };
        case GET_STOCK_ORDER_PRODUCTS_SUCCESS: 
        console.log("action.data",action.data);
        return {
            ...state,
            isFetching: false,
            data: action.data.stockOrderItemList,
            success: true,
            error: false,
            errorData: null,
            hasErrors:action.data.has_errors,
        };
        case GET_STOCK_ORDER_PRODUCTS_FAILURE: return {
            data: [],
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        };
        case CLEAR_STOCK_ORDER_PRODUCTS: return {
            isFetching: false,
            data: [],
            success: false,
            error: false,
            errorData: null
        };
        case ADD_NEW_SO_ITEM:
            let existing_items = cloneDeep(state.data)
            let newObj = action.data;
            let updatedData =  [...existing_items, newObj]

            return{
                isFetching: false,
                data: updatedData,
                success: false,
                error: false,
                errorData: null
            };
        case UPDATE_STOCK_ORDER_PRODUCT_IN_LIST:
            let itemList = state.data.slice();
            const newData = action.data;
            const objIndex = itemList.findIndex((i => i.itemIndex == newData.itemIndex));
            itemList[objIndex] = newData;
            return {
                isFetching: false,
                data: itemList,
                success: false,
                error: false,
                errorData: null
            };
        case DELETE_STOCK_ORDER_PRODUCT_IN_LIST:{
            let itemList = state.data.slice();
            const indexToDelete = action.data;
            console.log(indexToDelete);
            itemList.splice(indexToDelete, 1); //itemIndex-1
            return{
                isFetching: false,
                data: itemList,
                success: false,
                error: false,
                errorData: null
            };
        } 
        default: return state;
    }
};
const initialCreateStockOrderState = {
    isFetching: false,
    success: false,
    error: false,
    errorData: null
};

export const createStockOrderReducer = (state = initialCreateStockOrderState, action: any) => {
    switch (action.type) {
        case CREATE_STOCK_ORDER: return {
            isFetching: true,
            success: false,
            error: false,
            errorData: null
        };
        case CREATE_STOCK_ORDER_SUCCESS: return {
            isFetching: false,
            data: action.data,
            success: true,
            error: false,
            errorData: null
        };
        case CREATE_STOCK_ORDER_FAILURE: return {
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        };
        default: return initialCreateStockOrderState;

    }
};

const initialStockOrderSummaryState = {
    openCount: 0,
    totalCount: 0,
    oldestOpenDate: null,
    lastCreatedOn: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null
};

export const stockOrderSummaryDataReducer = (state = initialStockOrderSummaryState, action: any) => {
    switch (action.type) {
        case GET_STOCK_ORDER_SUMMARY: return {
            ...state,
            isFetching: true,
            success: false,
            error: false,
            errorData: null
        };
        case GET_STOCK_ORDER_SUMMARY_SUCCESS: return {
            ...state,
            isFetching: false,
            ...action.data,
            success: true,
            error: false,
            errorData: null
        };
        case GET_STOCK_ORDER_SUMMARY_FAILURE: return {
            ...state,
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        };
        default: return state;
    }
};