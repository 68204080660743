import { API_CONFIG } from "../../shared/api/config";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    productByCodeUrl: BASE_URL + 'korona/products/get_by_code/',
    productsUrl: BASE_URL + 'korona/products/',
    mobileProductsUrl: BASE_URL + 'korona/mobile/products/',
    supplierProductsUrl: BASE_URL + 'korona/supplier-products/',
    productCountUrl: BASE_URL + 'korona/products/count/',
    productSummaryDataUrl: BASE_URL + 'korona/products/summary/',

};