
import { GET_BUSINESSES, GET_BUSINESSES_FAILURE, GET_BUSINESSES_SUCCESS } from '../actions/actionTypes';
import { Business } from '../../models';

export interface InitialState {
    data: Business[]
    selectedBusiness: Business | null
    isFetching: boolean
    success: boolean
    error: boolean
    errorData: any
}

const initialState: InitialState = {
    data: [] as Business[],
    selectedBusiness: null,
    isFetching: false,
    success: false,
    error: false,
    errorData: null
}


export const businessListReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case GET_BUSINESSES: return {
            ...state,
            data: [],
            isFetching: true, 
            success: false,
            error: false,
            errorData: null
        }
        case GET_BUSINESSES_SUCCESS: return {
            ...state,
            isFetching: false,
            data: action.data.results,
            success: true,
            error: false,
            errorData: null
        }
        case GET_BUSINESSES_FAILURE: return {
            ...state,
            data: [],
            isFetching: false,
            success: false,
            error: true,
            errorData: action.err ? action.err : null
        }
        default: return state;

    }
}
