import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';

import { Typography, Snackbar, SvgIcon } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { StockReceipt } from '../../models';
import { PaginatedResponse, Location } from '../../../shared/models';
import { Filter, FilterType } from '../../../shared/models/Filter';

import { getStockReceiptsItemMarginByPage } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { getProductPriceForPos } from 'components/src/modules/products/lib/price';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { setPageLoading } from '../../../../store/actions';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';
import { getMasterCompaniesFullList } from '../../../master-data/api'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  }),
);

const FILTERS: Filter[] = [
  {
    id: 'f1',
    label: 'Cost & Price',
    types: [
      { id: '1', value: 'selected_cp_choice', label: 'is equal' },
    ],
    url: null,
    selectedType: 'selected_cp_choice',
    value: '',
    choices: [
      { id: '1', value: 'curr_cost_zero', label: 'current cost is zero' },
      { id: '2', value: 'curr_price_not_found_or_zero', label: 'current price is not found or zero' },

    ],
},
{
  id: 'f2',
  label: 'Curr Margin to Rec Margin',
  types: [
    { id: '1', value: 'selected_cr_checkbox', label: 'is equal' },
  ],
  url: null,
  selectedType: 'selected_cr_checkbox',
  value: '',
  checkboxes: [
    { id: '1', value: 'curr_margin_less_than_90', label: 'curr margin is less than 90% rec margin', checked: false },
    { id: '2', value: 'curr_margin_more_than_110', label: 'curr margin is more than 110% rec margin',  checked: false },
    { id: '3', value: 'curr_margin_is_equal_to_rec_margin', label: 'curr margin is equal to rec margin',  checked: false },


  ],
},
{
  id: 'f3',
  label: 'Cost Change',
  types: [
    { id: '1', value: 'selected_cc_checkbox', label: 'is equal' },
  ],
  url: null,
  selectedType: 'selected_cc_checkbox',
  value: '',
  checkboxes: [
    { id: '1', value: 'curr_cost_less_than_90', label: 'curr cost is less than 90% prev cost', checked: false },
    { id: '2', value: 'curr_cost_more_than_110', label: 'curr cost is more than 110% prev cost',  checked: false },
  ],
},
{
  id: 'f4',
  label: 'Price',
  types: [
    { id: '1', value: 'selected_price_checkbox', label: 'is equal' },
  ],
  url: null,
  selectedType: 'selected_price_checkbox',
  value: '',
  checkboxes: [
    { id: '1', value: 'curr_price_higher_than_rec_price', label: 'Current Price is higher than Rec Price', checked: false },
    { id: '2', value: 'curr_price_lower_than_rec_price', label: 'Current Price is lower than Rec Price',  checked: false },
    { id: '3', value: 'curr_price_is_equal_to_rec_price', label: 'Current Price is equal to Rec Price',  checked: false },

  ],
},

]

const SuggestedPriceByMarginDetailScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();

  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [selectedSR, setSelectedSR] = useState(null as any)
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [updatedFilters, setUpdatedFilters] = useState(null as any)
  const [filterCompaniesList, setFilterCompaniesList] = useState(null)

  const [filteredData, setFilteredData] = useState(null as any)

  useEffect(() => {
    console.log("props", props)
  }, [props])

  useEffect(() => {
    //this api call is asynchrs 
    props.setPageLoader(true);

    if (props.match && props.match.params) {
      if (props.match.params.id) {
        setSelectedSR(props.match.params.id)
      }
    }
    props.setPageLoader(false);

  }, []);



  useEffect(() => {
    if (filterCompaniesList) {
      if (filterCompaniesList.length > 0) {
        let currentFilters = [...FILTERS]
        let companyFilterIndexFound = currentFilters.findIndex(cf => cf.name === 'COMPANY')
        if (companyFilterIndexFound > -1) {
          currentFilters[companyFilterIndexFound].data = filterCompaniesList
        }
        setUpdatedFilters([...currentFilters])
      }
    }

  }, [filterCompaniesList])

  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onChangePage(null, 0);
    }
  }, [filterString]);


  useEffect(() => {
    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue))
    );
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      let isCheckBoxFilter = f.selectedType.includes('checkbox');

      // let isChoiceFilter = !f.url && !Array.isArray(f.value) && !f.selectedType.includes('range')&& Array.isArray(f.choices)
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }
      // if (isChoiceFilter) {
      //     filterString += `&${f.selectedType}=${f.value}`;
      //     console.log("CHOICE-FILTER-STRING",filterString)
      // }
      else if (isCheckBoxFilter){
        
        const mappedValues = f.checkboxes.map((v) => {
          return v.checked? v.value : null
        })
        console.log("mappedValues---CHekcbox", mappedValues) 

        const allNull = mappedValues.every(element => element === null);
        if (!allNull){
          filterValue = mappedValues.toString();
          filterString += `&${f.selectedType}=${filterValue}`;

        }
      }
      else if (!isRangeFilter) {

        // value is string
        filterValue = f.value.toString();

      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else {
        if(!isCheckBoxFilter){
          filterString += `&${f.selectedType}=${filterValue}`;

        }
      }
    });
    console.log("FIlterString", filterString)
    setFilterString(filterString);
  }, [selectedFilters]);

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    console.log("index", index, selectedFilters)
    let checkboxExists = filters.some(o=>o.hasOwnProperty('checkboxes')) 
    if (checkboxExists === true){
      filters[index].checkboxes.forEach((checkbox) => {
        checkbox.checked = false
      })
    }
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    // console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };

  // const adjustPrice = (price) => {
  //   let priceString = price.toFixed(4);
  //   if (priceString.charAt(priceString.length - 3) === '9') {
  //     return parseFloat(price.toFixed(3));
  //   }

  //   let adjustedPrice = Math.ceil(price * 10) / 10 + 0.09;
  //   return parseFloat(adjustedPrice.toFixed(2));
  // }

  const renderSRDetail = () => {
    if (selectedSR) {
      return (
        <div>
          <ErrorStatusBar isVisible={isErrorStatusVisible} />
          <div style={{ maxWidth: '100%' }}>
            <MaterialTable
              options={{
                ...tableOptions(tableQuery),
                thirdSortClick: false,
                draggable: false,
                sorting: true,
                pageSize: 50
              }}
              components={{
                Toolbar: (props) => (
                  <>
                    <MTableToolbar {...props} />
                    {filterVisible && (
                      <>
                        <FilterRow
                          {...props}
                          renderAvailableFilters={
                            <AvailableFilters
                              filters={updatedFilters ? updatedFilters : FILTERS}
                              addFilter={addFilter}
                            />
                          }
                          renderSelectedFilters={
                            <SelectedFilters
                              selectedFilters={selectedFilters}
                              removeFilter={removeFilter}
                              updateFilter={updateFilter}
                              businessId={null}
                            />
                          }
                        />
                      </>
                    )}
                  </>
                ),
              }}
              tableRef={tableRef}
              localization={{
                body: {
                  emptyDataSourceMessage: emptyDataSourceMessage
                }
              }}
              columns={[
                {
                  title: 'Prod Num',
                  field: 'buyerItemNumber',
                  render: rowData => {
                    if (rowData.buyerItemNumber) {
                      return <div style={{ minWidth: 50 }} >{rowData.buyerItemNumber}</div>
                    }
                    return null;
                  }
                },
                {
                  title: 'Prod Name',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.product && rowData.product.name) {
                      return <div style={{ minWidth: 350 }} >{rowData.product.name}</div>;
                    }
                    return null;
                  },
                },
                {
                  title: 'Reorder Code',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.supplierItemNumber) {
                      return <div style={{ minWidth: 30 }}>{rowData.supplierItemNumber}</div>;
                    }
                    return null;
                  },
                },
                {
                  title: 'Previous Cost',
                  field: 'product',
                  render: rowData => {
                      if (rowData.product.purchasePrice != null) {
                        return <div style={{ minWidth: 20 }} >{String(rowData.product.purchasePrice.toFixed(3))}</div>;
                      }
                      return null;
                  },
                  sorting: false
                },
                {
                  title: 'Current Cost',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.itemPrice) {
                      return <div style={{ minWidth: 20 }} >{String(rowData.itemPrice.toFixed(3))}</div>;
                    }
                    return null;
                  },
                },
               
                {
                  title: 'Current Margin%',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.current_price) {
                      let curprice = rowData.current_price.toFixed(3)
                      let currcost = rowData.itemPrice.toFixed(3)
                      // console.log("-------------Current Margin--------------")
                      // console.log(curprice, currcost)
                      // console.log((curprice - currcost))
                      // console.log((curprice - currcost)/curprice)
                      let current_margin = ((curprice - currcost) / curprice) * 100
                      return <div style={{ minWidth: 30 }} >{`${Math.round(current_margin)}`}</div>; //round off
                    }
                    return null;
                  },
                  sorting: false
                },
                {
                  title: 'Rec. Margin%',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.sugg_margin) {
                      return <div style={{ minWidth: 30 }} >{`${rowData.sugg_margin}`}</div>;
                    }
                    return <div>-</div>;
                  },
                  sorting: false
                },
                {
                  title: 'Current Price',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.current_price) {
                      return <div style={{ minWidth: 20 }} >{String(rowData.current_price.toFixed(2))}</div>;
                    }
                    return null;
                  },
                  sorting: false
                },
                {
                  title: 'Rec. Price',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.rec_margin_price) {
                      return (
                        <div 
                          style={{ 
                            minWidth: 20, 
                            color: rowData.current_price == rowData.rec_margin_price ? GlobalStyles.green : rowData.current_price < rowData.rec_margin_price ? GlobalStyles.red : rowData.current_price > rowData.rec_margin_price ? GlobalStyles.orange : null, 
                            fontWeight: 'bold'
                          }} 
                        >
                          {rowData.rec_margin_price ? String(rowData.rec_margin_price) : '-' }
                        </div>
                      )
                    }
                    return null;
                  },
                  sorting: false
                },
                {
                  title: 'Diff Price',
                  field: 'product',
                  render: rowData => {
                    if (rowData && rowData.current_price && rowData.rec_margin_price) {
                      return <div style={{ minWidth: 20 }} >{ (rowData.rec_margin_price - rowData.current_price.toFixed(2)).toFixed(2) }</div>;
                    }
                    return null;
                  },
                },


              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  if (!isLoading) {
                    const sb = props.userData.selectedBusiness;

                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as StockReceipt[],
                      totalCount: 0,
                      page: query.page
                    };
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      if (orderBy === 'article') {
                        orderBy = 'article__name'
                      }
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                      console.log("DIRECTION", orderBy)
                    }
                    setLoading(true);

                    getStockReceiptsItemMarginByPage(
                      selectedSR,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      orderBy,
                      filterString
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: any) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        // data.results.map(obj => obj.product.current_price = Number(getProductPriceForPos(obj.product)))
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        setLoading(false);
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if ((error.status === 403 || error.status === 401)) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        setLoading(false);
                        resolve(pageData);
                      });
                  }
                })
              }
              title={
                <CustomTableTitle
                  history={props.history}
                  title={'Suggested Price by Margin Detail List'}
                  icon={SVG_ICONS.stockReceipt}
                
                />
              }
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
                {
                  icon: 'filter_list',
                  tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
                  isFreeAction: true,
                  iconProps: {
                      color: filterVisible ? 'primary' : 'inherit',
                  },
                  // hidden: !isSuperAdmin(props),
                  onClick: toggleFilterPanel,
              },
              ]}
            />
          </div>
        </div>
      );

    }
  }
  return (
    <div>
      {selectedSR && renderSRDetail()}
    </div>
  )

}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    setPageLoader: (isLoading: boolean) => dispatch(setPageLoading(isLoading))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SuggestedPriceByMarginDetailScreen);