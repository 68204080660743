import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { PaginatedResponse } from '../../../shared/models';

import { API_URLS } from '../../api';
import { API_URLS as SupplierUrls } from '../../../master-data/api';
import composeRequest, { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { SupplierProduct } from '../../../products/models';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem'
        },
        progress: {
            margin: theme.spacing(2),
        },
    }),
);

const getSupplierProductsByPage = (masterSupplierId: string, page: number, pageSize: number, searchTerm: string | null) => {
    let URL = API_URLS.masterSupplierProductsWithoutUnitMeasureUrl +
        `?master_supplier__id=${masterSupplierId}&page=${page}&page_size=${pageSize}`;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.masterSupplierProductsWithoutUnitMeasureUrl +
            '?master_supplier__id=' + masterSupplierId +
            `?master_supplier__id=${masterSupplierId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
    }
    return composeRequest(URL, 'GET', null);
};

const SupplierProductsWithoutUOMScreen = (props: any) => {
    const { master_supplier: masterSupplier } = props.userLocalData.userData.userData.supplier;
    const supplierId = masterSupplier.id;
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();

    const navigateToMasterProductDetailScreen = (rowData: any) => {
        const masterProductDetailUrl = MasterDataRouterPaths.masterProducts + '/' + rowData.master_product.id;
        props.history.push(masterProductDetailUrl, rowData);
    };

    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    options={{
                        debounceInterval: 650,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100, 500, 1000],
                        headerStyle: {
                            backgroundColor: '#eee',
                        },
                        padding: 'dense',
                        emptyRowsWhenPaging: false
                    }}
                    tableRef={tableRef}
                    localization={{
                        body: {
                            emptyDataSourceMessage: emptyDataSourceMessage
                        }
                    }}
                    onRowClick={(event, rowData) => navigateToMasterProductDetailScreen(rowData)}
                    columns={[
                        { title: 'Product', field: 'supplier_product_name' },
                        { title: 'Container Amount', field: 'supplier_container_qty', type: 'numeric' },
                        { title: 'Reorder Code', field: 'supplier_reorder_code' },
                        {
                            title: 'Category',
                            field: 'supplier_product_name',
                            render: rowData => {
                                return <div></div>;
                            },
                        },
                    ]}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let pageData = {
                                data: [] as any[],
                                totalCount: 0,
                                page: query.page
                            };
                            getSupplierProductsByPage(supplierId,
                                query.page + 1, query.pageSize, query.search)
                                .catch(handleError) // handle network issues
                                .then(checkStatus)
                                .then(parseJSON)
                                .then((data: PaginatedResponse<any>) => {
                                    setErrorStatusVisible(false);
                                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                    pageData = {
                                        data: data.results,
                                        totalCount: data.count,
                                        page: query.page
                                    };
                                    resolve(pageData);
                                })
                                .catch((error: any) => {
                                    if (error) {
                                        if (error.status && error.status === 500) {
                                            setEmptyDataSourceMessage(ERROR_500);
                                            setErrorStatusVisible(false);
                                        } else if ((error.status === 403 || error.status === 401)
                                        ) {
                                            setEmptyDataSourceMessage(ERROR_MSG);
                                            setErrorStatusVisible(true);
                                        } else {
                                            setEmptyDataSourceMessage(ERROR_MSG);
                                            setErrorStatusVisible(false);
                                        }
                                    }
                                    resolve(pageData);
                                });
                        })
                    }
                    title={
                        <CustomTableTitle
                            history={props.history}
                            title={'Products'}
                            icon={SVG_ICONS.product} />
                    }
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh Data',
                            isFreeAction: true,
                            onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                        },
                    ]}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(SupplierProductsWithoutUOMScreen);