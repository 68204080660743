import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { blue, orange, red } from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl,  Button } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getAllCountries,getAllStates,getAllCities,getAllAddressType,addNewAddress} from '../../api'
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { API_CONFIG } from '../../../shared/api/config';
import composeRequest from '../../../shared/api/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { connect } from 'react-redux';
import {getAllAddressByEntityId} from '../../api'
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: GlobalStyles.primaryColor,
      alignSelf: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      width:'350px'
    },
    brandRow: {
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent:'space-between'
    },
    textFieldEdit: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: 'calc(100% - 64px)'
    },
    textFieldView: {
      marginRight: theme.spacing(1),
      marginBottom: 6,
      width: '100%'
    },
    textFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      // marginBottom: 8,
      width: '100%'
    },
    addressLineFieldCardRow: {
      // marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      marginBottom: 8,
      width: '350%'
    },
    button: {
      margin: theme.spacing(1),
    },
    progress: {
      margin: theme.spacing(2),
      justifySelf: 'center'
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 16px'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      alignSelf: 'center'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px'
    },
    panelHeaderText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    panelRowText: {
      fontSize: '0.9375rem',
      alignSelf: 'center',
      // padding: '0 8px'
    },
    noDataRow: {
      width: '100%',
      padding: 12
    },
    rowActionBtn: {
      padding: 8
    },
    greenCircle: {
      color: GlobalStyles.green
    },
    redCircle: {
      color: GlobalStyles.red
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '120%',
      maxWidth: '16rem',
      justifyContent:'space-between'
    },
    select: {
      color: 'black',
      marginRight: 4,
      '&:before': {
        borderColor: blue,
      },
      '&:after': {
        borderColor: blue,
      },
      maxWidth: '15rem',
      minWidth: '12rem',
    },
    icon: {
      fill: GlobalStyles.lightTheme,
    },
    dialogContent:{
      overflow:'hidden',
      // display: 'flex', 
      // flexDirection: 'column', 
      // width: '100%' ,
      // justifyContent:'space-between'
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
      
    }

  }),
);
const AddAddress=(props:any)=>{
  const classes = useStyles({});
  const [addressLineOne,setAddressLineOne]=useState('')
  const [addressLineTwo,setAddressLineTwo]=useState('')
  const [selectedCity,setSelectedCity]=useState(null)
  const [selectedCountry,setSelectedCountry]=useState(null)
  const [selectedState,setSelectedState]=useState(null)
  const [stateDropDownDisabled,setStateDropDownDisabled]=useState(true);
  const [cityDropDownDisabled,setCityDropDownDisabled]=useState(true);
  const [selectedAddressType,setSelectedAddressType]=useState(null)
  const [addressTypes,setAddressTypes]=useState([])
  const [citiesDropDownOpen,setCitiesDropDownOpen]=useState(false);
  const [statesDropDownOpen,setStatesDropDownOpen]=useState(false);
  const [countriesDropDownOpen,setCountriesDropDownOpen]=useState(false);
  const [cities,setCities]=useState([]);
  const [states,setStates]=useState([]);
  const [countries,setCountries]=useState([]);
  const [pincode,setPinCode]=useState('');
  const [attentionPerson,setAttentionPerson]=useState('');
  const [truckAccessible,setTruckAccessible]=useState(null);
  const [handleDeliveryTiming,setHandleDeliveryTiming]=useState('');
  const[addAddressButtonDisabled,setAddAddressButtonDisabled]=useState(true);
  const [existingAddress,setExistingAddress]=useState(null);
  const [valueChangedInEditMode,setValueChangedInEditMode]=useState(false);
  // const [textInput = useRef(null);

  console.log("entity_id",props.entity_id)
   useEffect(()=>{
     if (props.isOpen){
    getAllCountries()
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then(jsonData=>{
        console.log("countries",jsonData.results)
        setCountries(jsonData.results);
    })

    

    

    getAllAddressType(props.party_id)// TODO  hardcoded value change it 
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then(jsonData=>{
        console.log("address types",jsonData)
        setAddressTypes(jsonData);
    })
  }
   

   },[props.isOpen]);

   useEffect(() => {
    if(selectedCountry!=null && selectedAddressType!=null){
      setAddAddressButtonDisabled(false)
      console.log(selectedCountry)
    }
   }, [selectedCountry,selectedAddressType])


   useEffect(() => {
     
     if(selectedCountry!=null){
    getAllStates(selectedCountry.id)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then(jsonData=>{
        console.log("states",jsonData.results)
        setStates(jsonData.results);
        setStateDropDownDisabled(false);
    })
  }
   }, [selectedCountry])

   
   useEffect(() => {
     if(selectedState!=null){
      getAllCities(selectedState.id)
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then(jsonData=>{
        setCities(jsonData.results);
        setCityDropDownDisabled(false);
    })
     }
   }, [selectedState])


   useEffect(() => {
    if(props.edit_mode===true && addressTypes.length!=0 && countries.length!=0 && valueChangedInEditMode===false){
      getAllAddressByEntityId(props.entity_id,props.party_id,props.sub_party_type_id,props.edit_mode,props.addressLinkId)
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData=>{
        setExistingAddress(jsonData)
        console.log(jsonData)
        console.log("address types - 233",addressTypes)
        const address_type=addressTypes.filter(addressType=>addressType.id===jsonData[0].address_type_id)
        console.log("address_type",address_type)
        setSelectedAddressType(address_type[0])
        const country=countries.filter(country=>country.id===jsonData[0].country_id)
        console.log("country",country)
        setSelectedCountry(country[0])
        if(states.length!=0 && jsonData[0].state_id!=null){
          const state=states.filter(state=>state.id===jsonData[0].state_id)
          console.log("filtered state inside useffect",state)
          setSelectedState(state[0])
          }
        if(cities.length!=0 && jsonData[0].city_id!=null){
          console.log("cities",cities)
        const city=cities.filter(city=>city.id===jsonData[0].city_id)
        console.log("filtered city",city[0])
        
        setSelectedCity(city[0])
        }
       
        
        if(jsonData[0].pincode){
          setPinCode(jsonData[0].pincode)
        }
        
        setTruckAccessible(jsonData[0].truck_accessible)
       

        if(jsonData[0].address_line_1){
        setAddressLineOne(jsonData[0].address_line_1)
        }
        if(jsonData[0].address_line_2){
         setAddressLineTwo(jsonData[0].address_line_2)
        }
        setAttentionPerson(jsonData[0].attention_person)
        
      })
      .catch((error: any) => {
       
          props.showToast({
            message: 'Failed to fetch Address.',
            options: ToastError
          });
        });
    }
   }, [props.edit_mode,addressTypes,countries,states,cities])

  //make an api call with party_data_type_id

 const handleAddressLineOneChange=(address:string)=>{
  setAddressLineOne(address)
 }

 const handleAddressLineTwoChange=(address:string)=>{
  setAddressLineTwo(address)
 }

 const handleAddressTypeChange=(event:any)=>{
  const address_type = event.target.value
    setSelectedAddressType(address_type)
  
 }

const handleCountryChange=(value,reason)=>{
  if(props.edit_mode===true){
  setValueChangedInEditMode(true)
  }
  console.log("inside handle country change")
  if(selectedState!=null){//if user changes country after selecting state, reset the selected state
   setSelectedState(null)    
   setStateDropDownDisabled(true);
  }
  if(selectedCity!=null){
    setSelectedCity(null)
    setCityDropDownDisabled(true);

  }
  if(value!=null){
    setSelectedCountry(value)
  }
  if(reason=='clear'){
    setSelectedCountry(null)
    setAddAddressButtonDisabled(true)
    setSelectedState(null)    
    setStateDropDownDisabled(true);
    setCityDropDownDisabled(true);
  }
}
const handleCityChange=(value,reason)=>{
  if(props.edit_mode===true){
    setValueChangedInEditMode(true)
    }
  if(value!=null){
    setSelectedCity(value)
  }
  if(reason=='clear'){
    setSelectedCity(null)

  }
}
const handleStateChange=(value,reason)=>{
  if(props.edit_mode===true){
    setValueChangedInEditMode(true)
    }
  if(selectedCity!=null){
    setSelectedCity(null)
    setCityDropDownDisabled(true);

  }
  if(value!=null){
    console.log("selected state value",value)
    setSelectedState(value)
  }
  if(reason=='clear'){
    setSelectedState(null)
    setSelectedCity(null)
    setCityDropDownDisabled(true);

  }
}

const handleAttentionPersonChange=(value)=>{
  setAttentionPerson(value)
}

const handleIsTruckAccessible=(event)=>{
  console.log("event value",event.target.checked)
  setTruckAccessible(event.target.checked)
}

const handleDeliveryTimings=(value)=>{
  setHandleDeliveryTiming(value)

}



const handlePinCode=(pincode)=>{
  setPinCode(pincode)
}

const handleDialogClose=()=>{
  props.setDialogBoxState(false)
  setAddAddressButtonDisabled(true)
  setSelectedCity(null)
  setSelectedCountry(null)
  setSelectedState(null)
  setSelectedAddressType(null)
  setAttentionPerson('')
setTruckAccessible(false)
setStateDropDownDisabled(true);
setCityDropDownDisabled(true);
setValueChangedInEditMode(false)
setPinCode('')
setAddressLineOne('')
setAddressLineTwo('')
if(props.setEditMode){
props.setEditMode(false)
}
}


const AddAddress=()=>{
    const data={}
    if (props.edit_mode===true){
      data['edit_mode']=true
      data['link_address_id']=props.addressLinkId
      console.log("selectedAddressType",selectedAddressType)
      if (existingAddress[0].address_type_id!=selectedAddressType.id){
        data['address_type_changed']=true
        console.log("existingAddress",existingAddress)
        data['old_address_id']=existingAddress[0].address_type_id
      }
    } 
    data['sub_party_type']=props.sub_party_type
    data['party_type'] = props.party_id
    data['pincode']=pincode
    data['entity_id'] = props.entity_id
    data['country'] = selectedCountry
    data['address_line_1'] = addressLineOne
    data['address_line_2'] = addressLineTwo
    data['state'] = selectedState
    data['city'] = selectedCity
    data['address_type'] = selectedAddressType
    data['attention_person'] = attentionPerson
    data['truck_accessible'] =truckAccessible
    data['accept_delivery_during'] =handleDeliveryTiming
    const BASE_URL = API_CONFIG.API_BASE_PATH;
    const URL=BASE_URL+'core/address/add-address'

    composeRequest(URL, 'POST', JSON.stringify(data))
    .catch(handleError) // handle network issues
    .then(checkStatus)
    .then(parseJSON)
    .then(jsonData=>{
      if (props.edit_mode===true){
        props.showToast({
          message: 'Address Updated Successfully',
          options: ToastSuccess
        });
        props.setDialogBoxState(false)
        props.setNewAddressAdded(true)
        setAddressLineOne('')
        setAddressLineTwo('')
        setPinCode('')
        setSelectedCountry(null)
        setSelectedCity(null)
        setSelectedState(null)
        setValueChangedInEditMode(false)
      }
      else{
      props.showToast({
        message: 'Address Added Successfully',
        options: ToastSuccess
      });
        props.setNewAddressAdded(true)
        props.setDialogBoxState(false)
        setAddressLineOne('')
        setAddressLineTwo('')
        setPinCode('')
        setSelectedCountry(null)
        setSelectedCity(null)
        setSelectedState(null)
    }
      })
    .catch((error:any)=>{
      props.showToast({
        message: 'Failed To  Add Address',
        options: ToastError
      });
    }
      
      )


  }


  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={() => props.onClose && props.onClose()} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
        
          {props.edit_mode ?
            `Edit Address`
            : `Add Address`
          }
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {/* <Grid container spacing={3}> */}
            {/* <Grid item xs={12} sm={12} md={4} lg={6} xl={6}> */}
            <div style={{display: 'flex',flexDirection:'column',justifyContent:'space-between',width:'100%'}}>
              {/* {<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }} > */}

                {/* <Typography style={{ paddingRight: 15, color: GlobalStyles.primaryColor }}>
                  Address Type:
                </Typography> */}

                <FormControl
                  // style={{
                  //   marginRight: 20,
                  // }}
                  required
                  >
                  
                  <InputLabel id="address-type-select" variant="standard" style={{paddingLeft:'13px'}}>Address Type</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select-address-type"
                    value={selectedAddressType ? selectedAddressType: ''}
                    onChange={handleAddressTypeChange}
                    className={classes.select}
                    label={"  Address Type"}
                    inputProps={{ required: true,
                      // readOnly: props.edit_mode
                    }}
                    // ref={}
                  >
                    {
                      addressTypes.map(item => (
                        <MenuItem
                          value={item}
                          key={item.id}
                        >
                          {item.address_type_name}
                        </MenuItem>
                      ))
                    }

                  </Select>
                  {/* <FormHelperText>Required</FormHelperText> */}
                </FormControl>
              {/* </div> */}
              {/* } */}
              

              {/* <Grid container className={classes.row}> */}
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%'}}> */}
                  <TextField
                  
                    id="address"
                    className={classes.textFieldCardRow}
                    label="Address Line 1"
                    margin="dense"
                    variant="outlined"
                    value={addressLineOne}
                    onChange={(event) => handleAddressLineOneChange(event.target.value)}
                  // onBlur={() => {
                  //   if (isEditMode()) {
                  //     if (masterProductData && masterProductData.name.length === 0) {
                  //       setNameError({
                  //         isError: true,
                  //         message: 'Address Cannot be Blank'
                  //       });
                  //     } else {
                  //       setNameError({
                  //         isError: false,
                  //         message: ''
                  //       });
                  //     }
                  //   }
                  // }}
                  // error={nameError.isError}
                  // helperText={nameError.message}
                  // InputProps={{
                  //   readOnly: !isEditMode(),
                  //   required: true
                  // }}
                  />


                {/* </div> */}
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}> */}
                  <TextField
                    id="address"
                    className={classes.textFieldCardRow}
                    label="Address Line 2"
                    margin="dense"
                    variant="outlined"
                    value={addressLineTwo}
                    onChange={(event) => handleAddressLineTwoChange(event.target.value)}
                  // error={nameError.isError}
                  // helperText={nameError.message}
                  InputProps={{
                    // readOnly: !isEditMode(),
                    required: true
                  }}
                  />


                {/* </div> */}
              {/* </Grid> */}
              {/*  justifyContent: 'space-between', alignItems: 'flex-start', */}
              {/* <div style={{display: 'flex',flexDirection:'column',  width: '100%' }}> */}
              <div style={{ alignItems: 'center', width: '100%',paddingTop:'8px' }}>
              {/* <FormControl className={classes.formControl} style={{ maxWidth: '12rem' }} > */}
                <Autocomplete
                  autoComplete={false}

                  id="countries"
                  // style={{ width: 300 }}
                  open={countriesDropDownOpen}
                  onOpen={() => {
                    setCountriesDropDownOpen(true);
                  }}
                  onClose={() => {
                    setCountriesDropDownOpen(false);
                  }}
                  onChange={(event, value,reason) => {
                    handleCountryChange(value,reason);
                  }
                  
                  }
                  clearOnEscape={true}
                  // getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.country_name}
                  options={countries}
                  value={selectedCountry}
                  size={"small"}
                  // loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      variant="outlined"
                      required={true}
                      InputProps={{
                        ...params.InputProps,
                        value:selectedCountry
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                
                      
                  )}
                />
              {/* </FormControl> */}
              </div>
              <div style={{ alignItems: 'center', width: '100%',paddingTop:'12px' }}>
              {/* <FormControl className={classes.formControl} style={{ maxWidth: '12rem' }} > */}
                <Autocomplete
                  autoComplete={false}

                  id="states"
                  // style={{ width: 300 }}
                  open={statesDropDownOpen}
                  onOpen={() => {
                    setStatesDropDownOpen(true);
                  }}
                  onClose={() => {
                    setStatesDropDownOpen(false);
                  }}
                  onChange={(event, value,reason) => {
                    handleStateChange(value,reason);
                  }
                  }
                  // getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.state_name}
                  options={states}
                  // loading={loading}
                  value={selectedState}
                  size={"small"}
                  disabled={stateDropDownDisabled}
                  clearOnEscape={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                       
                      }}
                    />
                  )}
                />
              {/* </FormControl> */}
              </div>
              <div style={{ alignItems: 'center', width: '100%',paddingTop:'12px', paddingBottom:'7px'}}>
              {/* <FormControl className={classes.formControl} style={{ maxWidth: '12rem' }} > */}
                <Autocomplete
                  autoComplete={false}
                  id="cities"
                  // style={{ width: 300 }}
                  open={citiesDropDownOpen}
                  onOpen={() => {
                    setCitiesDropDownOpen(true);
                  }}
                  onClose={() => {
                    setCitiesDropDownOpen(false);
                  }}
                  onChange={(event, value,reason) => {
                    handleCityChange(value,reason);
                  }
                  }
                  // getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.city_name}
                  options={cities}
                  // loading={loading}
                  disabled={cityDropDownDisabled}
                  clearOnEscape={true}
                  value={selectedCity}
                  size={"small"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        // endAdornment: (
                        //   <React.Fragment>
                        //     {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        //     {params.InputProps.endAdornment}
                        //   </React.Fragment>
                        // ),
                      }}
                    />
                  )}
                />
                {/* </FormControl> */}
                </div>
              {/* </div> */}


                  <TextField
                    id="pincode"
                    className={classes.textFieldCardRow}
                    label="PIN/ZIP"
                    margin="dense"
                    variant="outlined"
                    value={pincode}
                    onChange={(event) => handlePinCode(event.target.value)}
                    inputProps={{maxlength:11}}
               
                  />


                {/* </div> */}


                {/* {
                selectedAddressType && selectedAddressType.id== 1 && */}

                  <TextField
                    id="billing-attention-person"
                    // className={classes.textFieldCardRow}
                    label="Attention"
                    margin="dense"
                    variant="outlined"
                    value={attentionPerson}
                    onChange={(event) => handleAttentionPersonChange(event.target.value)}
                  />




              {
                selectedAddressType && selectedAddressType.id== 2 &&

                <div style={{width:'100%'}}>
                    <div>

                      {/* <FormControlLabel
                              control={ */}
                               Truck Accessible
                                <Checkbox
                                  checked={truckAccessible}
                                  onChange={handleIsTruckAccessible}
                                  name="checkedB"
                                  color="primary"
                                  
                                />
                              {/* }
                              label="Truck Accessible"
                            /> */}
                      </div>
                    <TextField
                      id="shipping-delivery-timing"
                      className={classes.textFieldCardRow}
                      label="Accept Delivery During"
                      margin="dense"
                      variant="outlined"
                      value={handleDeliveryTiming}
                      disabled={true}
                      onChange={(event) => handleDeliveryTimings(event.target.value)}
                    />
              </div>
              }
            </div>
            {/* </Grid> */}




          {/* </Grid> */}
        </DialogContent>
        <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Button onClick={
            handleDialogClose
          } color="primary">
            Cancel
          </Button>

          {props.edit_mode ? 
          <Button onClick={AddAddress} disabled = {addAddressButtonDisabled} color="primary" >
          Update
          </Button>
          :
          <Button onClick={AddAddress} disabled = {addAddressButtonDisabled} color="primary" >
          Add
          </Button>

          }
        </DialogActions>
      </Dialog>

    </div>
)
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
    
  };
};



export default connect(null,mapDispatchToProps)(AddAddress);