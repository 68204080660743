import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  invoicePdfPreview: BASE_URL + 'invoice/list/preview_pdf/',
  playlists: BASE_URL + 'digital_signage/playlist/',
  playlist_gen_files: BASE_URL + 'digital_signage/products/html/',
  productTags: BASE_URL + 'korona/tags/',
  listingTemplates: BASE_URL + 'digital_signage/listing-templates/',
};


export const getInvoicePreview = () => {
  let URL = API_URLS.invoicePdfPreview;
  return composeRequest(URL, 'GET', null);
};

export const getPlaylistsByPage = (
  page: number, 
  pageSize: number,
  searchTerm: string, 
  business_id: number | string,
  orderBy: string | number,
) => {
  let URL = API_URLS.playlists +
    `?business=${business_id}&page=${page}&page_size=${pageSize}`;

  if (searchTerm) {
    URL = API_URLS.playlists +
      `?business=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
  }
  if(orderBy){
    URL = `${URL}&ordering=${orderBy}`;
  } 
  return composeRequest(URL, 'GET', null);
};

export const getPlaylistDetail = (
  id: string| number
) => {
  let URL = API_URLS.playlists +
    `${id}/`;
  return composeRequest(URL, 'GET', null);
};

export const syncPlaylistToSignVue = (
  id: string| number
) => {
  let URL = API_URLS.playlists +
    `${id}/sync_signvue/`;
  return composeRequest(URL, 'GET', null);
};

export const getProductTags = (
  page: number, 
  pageSize: number,
  searchTerm: string, 
  business_id: number | string
) => {
  let URL = API_URLS.productTags +
    `?business_id=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
  return composeRequest(URL, 'GET', null);
};

export const getSignageTemplates = (
) => {
  let URL = API_URLS.listingTemplates;
  return composeRequest(URL, 'GET', null);
};

export const createUpdatePlaylist = (
  payload: any
) => {
  let { id }  = payload;
  let URL = API_URLS.playlists;
  let method = 'POST';
  if (id) {
    URL = URL + `${id}/`;
    method = 'PATCH'
  }
  return composeRequest(URL, method, JSON.stringify(payload));
};

export const playlistGenerateFiles = (
  playlist_id, duration_per_file, business_ids, location_ids, tag_ids
) => {
  
  let URL = API_URLS.playlist_gen_files;
  let method = 'GET';
  URL = URL + `?playlist_id=${playlist_id}&duration_per_file=${duration_per_file}&business_ids=${business_ids}&location_ids=${location_ids}&tags__uuid__in=${tag_ids}`;
  return composeRequest(URL, method, null);
};