import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { RetailterCodeNotInLinkedMasterProductIdentifier } from '../../models';
import { PaginatedResponse } from '../../../shared/models';
import { PageToolbar } from '../../../page/components';
import { API_URLS } from '../../../master-data/api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getRetailterCodeNotLinkedMasterProductIdentifier, addMasterProductIdentifier, addExcludeBusinessCodeLinkedMpi } from '../../api';
import { getSuppliersByBusiness } from '../../../products/api/Api';
import { Supplier } from '../../../products/models';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Typography, Divider, SvgIcon, CircularProgress } from '@material-ui/core';
import { Button, Paper, Tooltip, TextField } from '@material-ui/core';
import { updateMasterProduct } from '../../../master-data/api';
import LinkIcon from '@material-ui/icons/Link';
import { MasterProduct, MasterProductIdentifier } from '../../../master-data/models';
import moment from 'moment';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../../shared/components/Notifier';
import GlobalStyles from '../../../../styles/GlobalStyles';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
    toolbar: {
      width: '80%',
    },
    rootSelectBtn: {
      minWidth: '1rem',
    },
    disableHover: {
      "&:hover": { backgroundColor: 'initial' }
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);
const minDate = moment('1970-01-01');
const dateFormat = 'dd-MM-yyyy';
const momentDateFormat = 'DD-MM-YYYY';
const dateSaveFormat = 'YYYY-MM-DD';
type CodeError = {
  isError: boolean;
  message: string;
  message2: string;
};
const InputErrorMsg = 'Invalid input! Please see errors below.';

const RetailerCodeNotLinkedMasterProductIdentifierScreen = (props: any) => {
  const classes = useStyles({});
  const tableRef = useRef<{ onQueryChange: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [mpIdentifierMapped, setMpIdentifierMapped] = useState(false);
  const [barcodeData, setBarcodeData] = useState(null);
  const [formError, setFormError] = useState({ isError: false, message: '', message2: '' } as CodeError);
  const [dialogOpen, setDialogOpen] = useState(false)
  const [mpLinkErrors, setMpLinkErrors] = useState({ identifier: [], remoteErrors: [] })
  const [zcodesRecords, setZcodesRecords] = useState([])
  const [excludedIdentifier, setExcludedIdentifier] = useState(false);


  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness,excludedIdentifier,mpIdentifierMapped]);

  useEffect(() => {
    if (selectedQueues.length != 0) {
      if (barcodeData.length != 0) {
        addCode()
      }
    }
  }, [barcodeData])

  // useEffect(()=>{
  //     console.log("BARCODEDATA", barcodeData)
  // },[barcodeData])

  // useEffect(() => {
  //   console.log("SELECTED-QUEUES", selectedQueues)
  // }, [selectedQueues])






  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      // const filters = getSupplierFilter();
      getRetailterCodeNotLinkedMasterProductIdentifier(
        sb.business_id,
        sb.orderBy,
        null,
        null,
        tableQuery.search,
        null,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'RetailterCodeNotInLinkedMasterProductIdentifier.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  // const navigateToMasterProductDetailScreen = (rowData: any) => {
  //     const masterProductDetailUrl = MasterDataRouterPaths.masterProducts + '/' + rowData.mp_id;
  //     props.history.push(masterProductDetailUrl, rowData);
  // };

  const renderTable = () => {
    return (
      <div>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <ExportStatusBar isVisible={isExporting} />

        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true,
              selection: true,
              selectionProps: rowData => ({
                color: 'secondary',
                // disabled: rowData.retl_item_code.includes('z') ? true : rowData.retl_item_code.includes('Z') ? true : false
              }),
              showTextRowsSelected: false,

            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              { title: 'Supplier Name', field: 'suppl_name' },
              { title: 'MasterProduct ID', field: 'mp_id' },
              { title: 'Master Item Name', field: 'master_item_name', },
              { title: 'Retailer Name', field: 'retl_name', },
              { title: 'Retailer Item Number', field: 'retl_item_num', },
              { title: 'Retailer Item Name', field: 'retl_item_name', },
              { title: 'Retailer Item Code', field: 'retl_item_code', },
              {
                title: 'Status',
                field: 'retl_item_code',
                render: (rowData: any) => {
                  const { retl_item_code } = rowData
                  // const RETAILER_CODE_ERROR = 'Excluded by z Rule';
                  if (retl_item_code.includes('z') || retl_item_code.includes('Z')) {
                    return (
                      // <IconButton >
                      <ErrorIcon style={{ color: GlobalStyles.red }} />
                      // </IconButton>
                    )
                  }
                }
              }
            ]}
            // onRowClick={(event, rowData) => navigateToMasterProductDetailScreen(rowData)}
            data={query =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  const sb = props.userData.selectedBusiness;
                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as RetailterCodeNotInLinkedMasterProductIdentifier[],
                      totalCount: 0,
                      page: query.page,
                    };

                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    }

                    getRetailterCodeNotLinkedMasterProductIdentifier(
                      sb.business_id,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      orderBy,
                      null,
                      false
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: any) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if ((error.status === 403 || error.status === 401)
                          ) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      });

                  }
                }
              })
            }
            onSelectionChange={(data: any) => {
              // console.log("data",data)
              let updatedArray = []
              // let zcodesData = []
              for (let i = 0; i < data.length; i++) {
                if (data[i].retl_item_code.includes('z') || data[i].retl_item_code.includes('z')) {
                  data[i]['contains_z_code'] = true
                  // setDisableMapNowButton(true)
                } else {
                  data[i]['contains_z_code'] = false
                  // setDisableMapNowButton(false)
                }
                updatedArray.push(data[i])
              }
              setSelectedQueues(updatedArray)
              // setZcodesRecords(zcodesData)

            }
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Retailer Code Not In Linked Master Product Identifier'}
                icon={SVG_ICONS.product}
                showHelp={true}
                helpText={
                  <div>
                    <Typography variant="body1" style={{ padding: '4px 8px' }}>
                      To see codes from all retailers that are missing from master identifiers, choose a supplier above to filter by their products.
                    </Typography>
                    <Typography variant="body1" style={{ padding: '4px 8px' }}>
                      <ErrorIcon style={{ color: GlobalStyles.red }} /> This indicates that the record includes z codes.
                    </Typography>
                    <Typography variant="body1" style={{ padding: '4px 8px' }}>
                      It will not be mapped, Even though you have checked.
                    </Typography>
                  </div>
                }
              />

            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
          <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

        </div>

      </div>
    )
  }

  const getUnique = (items: any) => {
    const uniqueArray = items.reduce((uniqueRows, currentRow) => {
      if (!uniqueRows.some(
        uniqueRow => uniqueRow.master_product === currentRow.master_product &&
          uniqueRow.quantity === currentRow.quantity &&
          uniqueRow.effective_date === currentRow.effective_date &&
          uniqueRow.description === currentRow.description &&
          uniqueRow.is_deleted === currentRow.is_deleted &&
          uniqueRow.expiry_date === currentRow.expiry_date
      )) {
        const dupIndex = uniqueRows.findIndex(uniqueRow => uniqueRow.identifier == currentRow.identifier);
        uniqueRows.push({
          ...currentRow,
          // tableData: dupIndex < 0 ? {checked: true} : {checked: false} , 
          isIdentifierDuplicate: dupIndex < 0 ? false : true,
        });

      }
      return uniqueRows;
    }, []);
    return uniqueArray;
  }

  const validateCodeAsync = async (): Promise<{} | null> => {
    let errors: any = {}
    let obj = {}
    let remoteErrors: any = []
    let errorIdentifier = []
    for (let i = 0; i < barcodeData.length; i++) {
      try {
        const URL = API_URLS.masterProductWithSameCodeDiffIdUrl
          + '?master_product_id=' + barcodeData[i].master_product.id
          + '&identifier_code=' + barcodeData[i].identifier;
        let response = await composeRequest(URL, 'GET', null);
        const products = await response.json();

        if (products.length) {
          errorIdentifier.push(barcodeData[i].identifier)
          remoteErrors.push(products.map(product => validateRemoteCodes(product)))
        }

      } catch (err) {
        let error = {
          isError: true,
          message: 'Oops, something went wrong!',
          message2: ''
        };
        return errors.push(error)
      }
    }
    // console.log("REMOTE-ERRors",{identifier : errorIdentifier , remoteErrors: remoteErrors }) 
    return { identifier: errorIdentifier, remoteErrors: remoteErrors }
  };

  const validateRemoteCodes = (mp: MasterProduct): CodeError | null => {
    // console.log("CURRENTDATA",barcodeData)
    // console.log("MP",mp)
    const barcodes = mp.identifiers;
    for (let exisitingCode of barcodes) {
      const currentEffectiveDate = moment(barcodeData.effective_date);
      const start = moment(exisitingCode.effective_date);
      if (exisitingCode.expiry_date) {
        const end = moment(exisitingCode.expiry_date);
        const defaultError = {
          isError: true,
          message: "Product with name: " + mp.name +
            " has a code with conflicting date range: ",
          message2:
            moment(exisitingCode.effective_date).format(momentDateFormat)
            + '  to ' +
            moment(exisitingCode.expiry_date).format(momentDateFormat)
        };
        if (currentEffectiveDate.isBetween(start, end, null, "[]")) {
          return defaultError;
        }
        if (barcodeData.expiry_date) {
          const currentExpiryDate = moment(barcodeData.expiry_date);
          if (currentExpiryDate.isBetween(start, end, null, "[]")) {
            return defaultError;
          } else if (start.isBetween(currentEffectiveDate, currentExpiryDate, null, "[]") ||
            end.isBetween(currentEffectiveDate, currentExpiryDate, null, "[]")) {
            return defaultError;
          }
        } else {
          if (currentEffectiveDate.isSameOrBefore(start)) {
            return defaultError;
          }
        }
      } else {
        if (currentEffectiveDate.isSameOrAfter(start)) {
          return {
            isError: true,
            message: "Product with name: " + mp.name +
              " has a code with conflicting date range: ",
            message2: moment(exisitingCode.effective_date).format(momentDateFormat)
              + ' and no expiry date.'
          };
        }
      }
    }
    return null;
  };

  const postValidatedIdentifiers = (errorIds: any) => {

    const { userData } = props.userData.userData
    let auth_token = userData.auth_token;
    // console.log("data", barcodeData)
    let records = barcodeData.filter(barcode => !errorIds.includes(barcode.identifier));
    let payload = { identifiers: records, auth_token: auth_token }
    console.log("payload", payload)
    addMasterProductIdentifier(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        // console.log("JSONDATA", jsonData)
        setLoading(false);
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        props.showToast({
          message: 'Master product(s) updated successfully!',
          options: ToastSuccess,
        });
        setMpIdentifierMapped(!mpIdentifierMapped)
        setSelectedQueues([])
        setMpLinkErrors({ identifier: [], remoteErrors: [] });
        setBarcodeData([])

      })
      .catch((error: any) => {
        setLoading(false);
        let msg = 'Failed to update master product(s).';
        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg, options: ToastError });
        setMpIdentifierMapped(!mpIdentifierMapped)
        setSelectedQueues([])
        setMpLinkErrors({ identifier: [], remoteErrors: [] });
        setBarcodeData([])
      });


  }





  const addCode = async () => {
    const remoteError: any = await validateCodeAsync();
    let errors = []

    if (remoteError) {
      // console.log("REMOTE-ERROR", remoteError)
      setDialogOpen(true)
      setMpLinkErrors(remoteError)

    } else {
      setMpLinkErrors({ identifier: [], remoteErrors: [] });
    }
    postValidatedIdentifiers(remoteError.identifier)


  }


  const handleMapNow = () => {
    setLoading(true);
    let identifiers = [];
    let records = selectedQueues.map(i => i);
    console.log(records)
    for (let i = 0; i < records.length; i++) {
      if (records[i].contains_z_code === false) {
        let obj = {};
        obj['master_product'] = records[i]['master_product']
        obj['identifier'] = records[i]['retl_item_code']
        obj['quantity'] = 1
        obj['effective_date'] = "1970-01-01"
        obj['description'] = null
        obj['is_deleted'] = false
        obj['expiry_date'] = null
        identifiers.push(obj);
      }
    }
    let data = getUnique(identifiers)
    let uniqueData = data.filter(data => !data.isIdentifierDuplicate)
    // console.log("data",data )
    // console.log("UNIQUEDATA",uniqueData)


    setBarcodeData(uniqueData)
  }

  const handleDialogCancel = () => {
    setDialogOpen(false)
  }


  const getErrorLinks = () => {
    return (
      <>
        {mpLinkErrors && mpLinkErrors.identifier.map((identifier, index) => (
          <>
            <Typography style={{ color: GlobalStyles.errorText, fontSize: 16 }}>
              {`For ${identifier}:`}
            </Typography>
            {
              mpLinkErrors.remoteErrors[index].map(e => {
                let msgindex = e.message.indexOf("has")
                let msg1 = e.message.substring(0, msgindex)
                let msg2 = e.message.substring(msgindex, e.message.length)
                return (
                  <div >
                    <Typography >
                      {msg1}
                    </Typography>
                    <Typography>
                      {msg2}
                    </Typography>
                    <Typography>
                      {e.message2}
                    </Typography>
                  </div>
                )
              }
              )}
          </>
        ))}
      </>
    )
  }

  const handleExcludeBusinessCodeLinkedMpi = () => {
    setLoading(true)
    let identifiers = [];
    let records = selectedQueues.map(i => i);
    console.log(records)
    for (let i = 0; i < records.length; i++) {
      let obj = {};
      obj['master_product'] = records[i]['master_product']
      obj['identifier'] = records[i]['retl_item_code']
      obj['retl_id'] = records[i]['retl_id']
      identifiers.push(obj);
    }

    // console.log("exclude-list",identifiers)
    let payload = { identifiers: identifiers }
    console.log("payload", payload)
    addExcludeBusinessCodeLinkedMpi(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        // console.log("JSONDATA", jsonData)
        setLoading(false);
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        props.showToast({
          message: 'Retailer Code(s) has been excluded!',
          options: ToastSuccess,
        });
        setExcludedIdentifier(!excludedIdentifier)
        setSelectedQueues([])
        setMpLinkErrors({ identifier: [], remoteErrors: [] });
        setBarcodeData([])

      })
      .catch((error: any) => {
        setLoading(false);
        let msg = 'Failed to exclude code(s).';
        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg, options: ToastError });
        setExcludedIdentifier(!excludedIdentifier)

        setSelectedQueues([])
        setMpLinkErrors({ identifier: [], remoteErrors: [] });
        setBarcodeData([])
      });


  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>

        <div style={{ flex: 1 }}>
          <Button
            variant="contained"
            onClick={handleExcludeBusinessCodeLinkedMpi}
            color="primary"
            disabled={selectedQueues.length === 0}
            startIcon={<RemoveCircleIcon />}
          > Exclude
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          {
            isLoading &&
            <CircularProgress />
          }
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
          <Button
            variant="contained"
            onClick={handleMapNow}
            color="primary"
            disabled={selectedQueues.length === 0}
            startIcon={<LinkIcon />}
          > Map Now
          </Button>
        </div>
      </div>
      <Divider className={classes.divider} />
      {renderTable()}
      {
        mpLinkErrors && mpLinkErrors['remoteErrors'].length != 0 &&
        <Dialog
          open={dialogOpen}
          onClose={handleDialogCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: GlobalStyles.error }}>
            Error
          </DialogTitle>
          <DialogContent style={{ alignSelf: 'center' }}>
            {getErrorLinks()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogCancel} color="primary" >
              OK
            </Button>
          </DialogActions>
        </Dialog>

      }
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetailerCodeNotLinkedMasterProductIdentifierScreen);
