
import { API_CONFIG } from 'components/src/modules/shared/api/config';
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  saveUserSettingsUrl: BASE_URL + 'core/user/settings',
  saveUserWebSettingsUrl: BASE_URL + 'core/user/web-settings',
};

export const saveUserSettings = (data: any) => {
  return composeRequest(API_URLS.saveUserSettingsUrl, 'POST', JSON.stringify(data));
};

export const saveUserWebSettings = (data: any) => {
  return composeRequest(API_URLS.saveUserWebSettingsUrl, 'POST', JSON.stringify(data));
};