import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  invoicePdfPreview: BASE_URL + 'invoice/list/preview_pdf/',
  invoices: BASE_URL + 'invoice/list/'
};


export const getInvoicePreview = () => {
  let URL = API_URLS.invoicePdfPreview;
  return composeRequest(URL, 'GET', null);
};

export const getInvoicesByPage = (
  page: number, 
  pageSize: number,
  searchTerm: string, 
  business_id: number | string,
  orderBy: string | number,
) => {
  let URL = API_URLS.invoices +
    `?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;

  if (searchTerm) {
    URL = API_URLS.invoices +
      `?business_ids=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
  }
  if(orderBy){
    URL = `${URL}&orderBy=${orderBy}`;
  } 
  return composeRequest(URL, 'GET', null);
};