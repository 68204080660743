import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { History } from 'history';
import { Route, Switch } from "react-router-dom";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Footer, PageRedirect, Navbar, SideDrawer } from '../../components';

interface Props {
    history: History,
    classes: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        mainContent: {
            flexGrow: 1,
            display: 'flex'
        },
        routeContent: {
            flexGrow: 1,
            padding: theme.spacing(3),
            backgroundColor: '#f6f7fC',
            maxWidth: '100%',
            overflow: 'auto',
        },
        toolbar: theme.mixins.toolbar,
    }),
);

const AuthLayout = (props: any) => {
    const classes = useStyles({});
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [isMobileScreen, setMobileScreen] = useState(false);
    const [menuItem, setMenuItem] = useState({ isOpen: false, name: 'dashboard' });

    const { userData } = props.userLocalData.userData;

    useEffect(() => {
        window.addEventListener("resize", () => resize());
        resize();
        return () => {
            window.removeEventListener("resize", resize);
        };

    }, []); //render only once

    useEffect(() => {
        // console.log('route has changed!');
        if (props.location && props.location.state) {
            if (props.location.state.menuItemName) {
                handleMenuItemClick(props.location.state.menuItemName);
            }
        }
    }, [props.location]);

    const resize = () => {
        setMobileScreen(window.innerWidth <= 760);
    };
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleMenuItemClick = (name: string) => {
        menuItem.name === name ?
            setMenuItem({ isOpen: !menuItem.isOpen, name: name }) :
            setMenuItem({ isOpen: true, name: name });
    };

    const getRoutes = (routes) => {
        if (routes) {
            return routes.map((route, index) => (
                <Route exact path={route.path} component={route.component} key={index} />
            ));
        }
        return null;
    };

    return (
        <div className={classes.root}>
            <div className={classes.mainContent}>
                {/* Navbar */}
                <Navbar
                    toggleDrawer={toggleDrawer}
                    isMobileScreen={isMobileScreen}
                    history={props.history}
                    isUILoading={props.uiLoader} />
                {/* SideDrawer */}
                <SideDrawer
                    drawerOpen={drawerOpen}
                    drawerMenuData={props.drawerMenuData}
                    handleDrawerClose={handleDrawerClose}
                    isMobileScreen={isMobileScreen}
                    history={props.history}
                    location={props.location} />

                {/* Main Routes */}
                <main className={classes.routeContent}>
                    <div className={classes.toolbar} />
                    <Switch>
                        {getRoutes(props.drawerMenuData.routes)}
                        <Route path="*" render={() => <PageRedirect user={userData} location={props.location} />} />
                    </Switch>
                </main>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData,
        uiLoader: state.uiLoader
    };
};

export default connect(mapStateToProps, null)(AuthLayout);
