import { SVG_ICONS } from '../../../../shared/icons/SvgIcons';
import { SideMenuItem } from './models';
import { BusinessRouterPaths, MasterDataRouterPaths } from '../../../Routes/RouterPaths';

export const RetailerPrimaryMenuItems: SideMenuItem[] = [
    {
        id: '0',
        name: 'dashboard',
        title: 'Dashboard',
        route: BusinessRouterPaths.dashboard,
        icon: SVG_ICONS.dashboard,
        requiredPermissions: [],
    },
    {
        id: '13',
        name:'ccrs',
        title:'CCRS',
        route: '/web/ccrs',
        icon: SVG_ICONS.reports,
        subItems: [
            {
                id: '13.1',
                name: 'ccrs-report-actions',
                title: 'File Actions',
                activeRoute: BusinessRouterPaths.ccrsHomeScreen.substring(4),
                route: BusinessRouterPaths.ccrsHomeScreen,
                icon: SVG_ICONS.reports,
                requiredPermissions: [''],
            },
            {
                id: '13.2',
                name: 'ccrs-report-list',
                title: 'Report History',
                activeRoute: BusinessRouterPaths.ccrsReportListScreen.substring(4),
                route: BusinessRouterPaths.ccrsReportListScreen,
                icon: SVG_ICONS.reports,
                requiredPermissions: ['view_ftpfileupload'],
            },
           
        ],
    },
    {
        id: '14',
        name: 'signage',
        title: 'Digital Signage',
        route: "/web/signage/",
        icon: SVG_ICONS.liveTv,
        requiredPermissions: ['view_digitalsignageplaylist'],
        subItems: [
            {
                id: '14.1',
                name: 'playlists',
                title: 'Playlists',
                route: BusinessRouterPaths.signagePlaylist,
                activeRoute: BusinessRouterPaths.signagePlaylist.substring(4),
                icon: SVG_ICONS.playlist,
                requiredPermissions: ['view_digitalsignageplaylist'],
            },
            // {
            //     id: '0.2',
            //     name: 'Settings',
            //     title: 'SignVue Settings',
            //     route: BusinessRouterPaths.signagePlaylist,
            //     activeRoute: BusinessRouterPaths.signagePlaylist.substring(4),
            //     icon: SVG_ICONS.settings,
            //     requiredPermissions: ['view_digitalsignageplaylist'],
            // },
        ]
    },
    {
        id: '15',
        name: 'customer_orders',
        title: 'Customer Orders',
        route: "/web/customer-orders/",
        icon: SVG_ICONS.liveTv,
        requiredPermissions: ['view_customerorder'],
        subItems: [
            {
                id: '15.1',
                name: 'summary',
                title: 'Order Summary',
                route: BusinessRouterPaths.customerOrderSummary,
                activeRoute: BusinessRouterPaths.customerOrderSummary.substring(4),
                icon: SVG_ICONS.playlist,
                requiredPermissions: ['view_customerorder'],
            },
            // {
            //     id: '0.2',
            //     name: 'Settings',
            //     title: 'SignVue Settings',
            //     route: BusinessRouterPaths.signagePlaylist,
            //     activeRoute: BusinessRouterPaths.signagePlaylist.substring(4),
            //     icon: SVG_ICONS.settings,
            //     requiredPermissions: ['view_digitalsignageplaylist'],
            // },
        ]
    },
    {
        id: '12',
        name: 'dispatch-notification',
        title: 'Dispatch Notification',
        route: '/web/dispatch-notification',
        icon: SVG_ICONS.clipboardEdit,
        subItems: [
            {
                id: '12.1',
                name: 'import-dispatch-notifications',
                title: 'Create Dispatch Notification',
                activeRoute: BusinessRouterPaths.dispatchNotificationsImport.substring(4),
                route: BusinessRouterPaths.dispatchNotificationsImport,
                icon: SVG_ICONS.import,
                requiredPermissions: [''],
            },
            {
                id: '12.2',
                name: 'dispatch-notifications-list',
                title: 'Dispatch Notifications',
                activeRoute: BusinessRouterPaths.dispatchNotificationsList.substring(4),
                route: BusinessRouterPaths.dispatchNotificationsList,
                icon: SVG_ICONS.clipboardEdit,
                requiredPermissions: [''],
            },
        ],
    },
    {
        id: '1',
        name: 'inventory',
        title: 'Inventory',
        route: BusinessRouterPaths.inventories,
        icon: SVG_ICONS.inventory,
        requiredPermissions: ['view_inventory'],
    },
    {
        id: '2',
        name: 'products',
        title: 'Products',
        route: BusinessRouterPaths.productList,
        icon: SVG_ICONS.product,
        requiredPermissions: ['view_product'],
    },
    {
        id: '9',
        name: 'invoices',
        title: 'Invoices',
        route: BusinessRouterPaths.invoices,
        icon: SVG_ICONS.invoice,
        requiredPermissions: ['view_invoice'],
    },
    {
        id: '3',
        name: 'stock-order',
        title: 'Stock Order',
        route: '/web/stock-order',
        icon: SVG_ICONS.stockOrder,
        subItems: [
            {
                id: '3.1',
                name: 'stock-order',
                title: 'Import Stock Order',
                route: BusinessRouterPaths.stockOrderImport,
                activeRoute: BusinessRouterPaths.stockOrderImport.substring(4),
                icon: SVG_ICONS.import,
                requiredPermissions: ['view_stockorder', 'add_stockorder', 'view_mastersupplier'],
            },
            {
                id: '3.2',
                name: 'stock-order',
                title: 'Stock Orders',
                route: BusinessRouterPaths.stockOrderList,
                activeRoute: BusinessRouterPaths.stockOrderList.substring(4),
                icon: SVG_ICONS.stockOrder,
                requiredPermissions: ['view_stockorder'],
            },
        ],
    },
    {
        id: '4',
        name: 'stock-receipt',
        title: 'Stock Receipt',
        route: '/web/stock-receipt',
        icon: SVG_ICONS.stockReceipt,
        subItems: [
            {
                id: '4.1',
                name: 'stock-receipt-import',
                title: 'Import Stock Receipt',
                activeRoute: BusinessRouterPaths.stockReceiptsImport.substring(4),
                route: BusinessRouterPaths.stockReceiptsImport,
                icon: SVG_ICONS.import,
                requiredPermissions: [''],
            },
            {
                id: '4.2',
                name: 'stock-receipt-list',
                title: 'Stock Receipts List',
                activeRoute: BusinessRouterPaths.stockReceiptList.substring(4),
                route: BusinessRouterPaths.stockReceiptList,
                icon: SVG_ICONS.stockReceipt,
                requiredPermissions: ['view_stockreceipt'],
            },
            {
                id: '4.3',
                name: 'stock-receipt-sugg-price-by-margin',
                title: 'Suggested Price By Margin',
                activeRoute: BusinessRouterPaths.suggestPriceByMargin.substring(4),
                route: BusinessRouterPaths.suggestPriceByMargin,
                icon: SVG_ICONS.reports,
                requiredPermissions: ['view_stockreceipt'],
            },
            {
                id: '4.4',
                name: 'margin-by-cg-list',
                title: 'Margin By Commodity Group',
                activeRoute: BusinessRouterPaths.marginByCgList.substring(4),
                route: BusinessRouterPaths.marginByCgList,
                icon: SVG_ICONS.reports,
                requiredPermissions: ['view_stockreceipt'],
            },
            {
                id: '4.5',
                name: 'price-round-list',
                title: 'Price Round',
                activeRoute: BusinessRouterPaths.priceRoundList.substring(4),
                route: BusinessRouterPaths.priceRoundList,
                icon: SVG_ICONS.addTag,
                requiredPermissions: ['view_stockreceipt'],
            },
        ],
    },
    {
        id: '5',
        name: 'labeler',
        title: 'Labeler',
        route: '/web/labeler',
        icon: SVG_ICONS.labeler,
        subItems: [
            {
                id: '5.1',
                name: 'labeler',
                title: 'Templates',
                route: BusinessRouterPaths.labelerTemplates,
                activeRoute: BusinessRouterPaths.labelerTemplates.substring(4),
                icon: SVG_ICONS.template,
                requiredPermissions: ['view_labelertemplate'],
            },
        ],
    },
    {
        id: '6',
        name: 'reports',
        title: 'Reports',
        route: '/web/reports/',
        icon: SVG_ICONS.reports,
        subItems: [
            {
                id: '6.0',
                name: 'reports',
                title: 'Cg By EBT Sector Disc Summary',
                route: BusinessRouterPaths.cgByEBTSectorDiscSummaryReport,
                activeRoute: BusinessRouterPaths.cgByEBTSectorDiscSummaryReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: [''],
            },
            {
                id: '6.0',
                name: 'reports',
                title: 'Expected Stock By Week',
                route: BusinessRouterPaths.expectedStockByWeekReport,
                activeRoute: BusinessRouterPaths.expectedStockByWeekReport.substring(4),
                icon: SVG_ICONS.stockOrder,
                requiredPermissions: [
                    'view_expectedstockbyweekbycommoditygroup',
                    'view_expectedstockbyweekbysupplier',
                ],
            },
            {
                id: '6.1',
                name: 'reports',
                title: 'Liquor Liter Tax Mismatch',
                route: BusinessRouterPaths.liquorLiterTaxMismatch,
                activeRoute: BusinessRouterPaths.liquorLiterTaxMismatch.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_liquorlitertaxmismatchreport'],
            },
            
            {
                id: '6.2',
                name: 'reports',
                title: 'Price Missing Or Zero',
                route: BusinessRouterPaths.priceMissingOrZeroReport,
                activeRoute: BusinessRouterPaths.priceMissingOrZeroReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_pricemissingorzeroreport'],
            },
            {
                id: '6.3',
                name: 'reports',
                title: 'Product Margins',
                route: BusinessRouterPaths.productMargins,
                activeRoute: BusinessRouterPaths.productMargins.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_productmarginsreport'],
            },
            {
                id: '6.4',
                name: 'reports',
                title: 'Products Most Recent SR By Supplier',
                route: BusinessRouterPaths.productsMostRecentSR,
                activeRoute: BusinessRouterPaths.productsMostRecentSR.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_productsmostrecentsrreport'],
            },
            {
                id: '6.5',
                name: 'reports',
                title: 'Products Not Mapped',
                route: BusinessRouterPaths.productsNotMappedReport,
                activeRoute: BusinessRouterPaths.productsNotMappedReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: [
                    'view_product',
                    'view_masterproduct',
                    'view_linkmasterbusinessproduct',
                ],
            },
            {
                id: '6.6',
                name: 'reports',
                title: 'Products With Duplicate Name',
                route: BusinessRouterPaths.productsWithDuplicateNameReport,
                activeRoute: BusinessRouterPaths.productsWithDuplicateNameReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_productwithduplicatename'],
            },
            {
                id: '6.7',
                name: 'reports',
                title: 'Products With Margin Less Than Zero',
                route: BusinessRouterPaths.productsWithMarginLessThanZero,
                activeRoute: BusinessRouterPaths.productsWithMarginLessThanZero.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_productswithmarginlessthanzeroreport'],
            },
            {
                id: '6.8',
                name: 'reports',
                title: 'Products Without Barcode',
                route: BusinessRouterPaths.productsWithoutBarcodeReport,
                activeRoute: BusinessRouterPaths.productsWithoutBarcodeReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_productnobarcode'],
            },
            {
                id: '6.9',
                name: 'reports',
                title: 'Sales Data Summary',
                route: BusinessRouterPaths.salesDataSummary,
                activeRoute: BusinessRouterPaths.salesDataSummary.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_salesdatasummary'],
            },
            {
                id: '7.0',
                name: 'reports',
                title: 'Store Price',
                route: BusinessRouterPaths.storePriceReport,
                activeRoute: BusinessRouterPaths.storePriceReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_productwithstoreprice'],
            },
            {
                id: '7.1',
                name: 'reports',
                title: 'Supplier Entry Multiples In Same Item',
                route: BusinessRouterPaths.supplierEntryMultiplesInSameItemScreen,
                activeRoute: BusinessRouterPaths.supplierEntryMultiplesInSameItemScreen.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_supplierentrymultiplesinsameitemreport'],
            },
            {
                id: '7.2',
                name: 'reports',
                title: 'Supplier ROC In Multiple Items',
                route: BusinessRouterPaths.supplierRocInMultipleItems,
                activeRoute: BusinessRouterPaths.supplierRocInMultipleItems.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_supplierrocinmultipleitemsreport'],
            },
            {
                id: '7.3',
                name: 'reports',
                title: 'Tags To Remove Code',
                route: BusinessRouterPaths.tagsToRemoveCode,
                activeRoute: BusinessRouterPaths.tagsToRemoveCode.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_tagstoremovecodereport'],
            },
            {
                id: '7.4',
                name: 'reports',
                title: 'Tags To Remove Price',
                route: BusinessRouterPaths.tagsToRemovePrice,
                activeRoute: BusinessRouterPaths.tagsToRemovePrice.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_tagstoremovepricereport'],
            },

            {
                id: '7.5',
                name: 'reports',
                title: 'Unknown Identifiers',
                route: BusinessRouterPaths.unknownIdentifierHistoryReport,
                activeRoute: BusinessRouterPaths.unknownIdentifierHistoryReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_unknownidentifierreport'],
            },
        ],
    },
    // {
    //     id: '10',
    //     name: 'rms-reports',
    //     title: 'RMS Reports',
    //     route: '/web/rms/',
    //     icon: SVG_ICONS.reports,
    //     subItems: [
    //         {
    //             id: '10.1',
    //             name: 'rms-reports',
    //             title: 'HQ Item Issues',
    //             route: BusinessRouterPaths.rmsItemIssuesReport,
    //             activeRoute: BusinessRouterPaths.rmsItemIssuesReport.substring(4),
    //             icon: SVG_ICONS.product,
    //             requiredPermissions: ['view_rmshqitemmismatch'],
    //         },
    //         {
    //             id: '10.2',
    //             name: 'rms-reports',
    //             title: 'Cost Price',
    //             route: BusinessRouterPaths.rmsCostPriceReport,
    //             activeRoute: BusinessRouterPaths.rmsCostPriceReport.substring(4),
    //             icon: SVG_ICONS.product,
    //             requiredPermissions: ['view_rmscostpricesd3'],
    //         },
    //     ],
    // },
    {
        id: '7',
        name: 'master-data',
        title: 'Master Data',
        route: '/web/master-data',
        icon: SVG_ICONS.masterData,
        subItems: [
            {
                id: '7.0',
                name: 'master-data',
                title: 'Global Lookup',
                route: MasterDataRouterPaths.masterGlobalLookup,
                activeRoute: MasterDataRouterPaths.masterGlobalLookup.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
            {
                id: '7.1',
                name: 'master-data',
                title: 'Products',
                route: MasterDataRouterPaths.masterProducts,
                activeRoute: MasterDataRouterPaths.masterProducts.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '7.2',
                name: 'master-data',
                title: 'Brands',
                route: MasterDataRouterPaths.masterBrands,
                activeRoute: MasterDataRouterPaths.masterBrands.substring(4),
                icon: SVG_ICONS.brands,
                requiredPermissions: ["superuser"]
            },
            {
                id: '7.3',
                name: 'master-data',
                title: 'Companies',
                route: MasterDataRouterPaths.masterCompany,
                activeRoute: MasterDataRouterPaths.masterCompany.substring(4),
                icon: SVG_ICONS.company,
                requiredPermissions: ['view_mastercompany'],
            },
            {
                id: '7.4',
                name: 'master-data',
                title: 'Company Products',
                route: MasterDataRouterPaths.companyProductsListScreen,
                activeRoute: MasterDataRouterPaths.companyProductsListScreen.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
            {
                id: '7.5',
                name: 'master-data',
                title: 'Link Products',
                route: MasterDataRouterPaths.masterProductsLink,
                activeRoute: MasterDataRouterPaths.masterProductsLink.substring(4),
                icon: SVG_ICONS.linkPlus,
                requiredPermissions: ['view_masterproduct', 'view_linkmasterbusinessproduct'],
            },
            {
                id: '7.6',
                name: 'master-data',
                title: 'Suppliers',
                route: MasterDataRouterPaths.masterSuppliers,
                activeRoute: MasterDataRouterPaths.masterSuppliers.substring(4),
                icon: SVG_ICONS.suppliers,
                requiredPermissions: ['view_mastersupplier'],
            },
            // {
            //     id: '7.7',
            //     name: 'master-data',
            //     title: 'Link Suppliers',
            //     route: MasterDataRouterPaths.masterBusinessSuppliersLink,
            //     activeRoute: MasterDataRouterPaths.masterBusinessSuppliersLink.substring(4),
            //     icon: SVG_ICONS.linkPlus,
            //     requiredPermissions: ['view_linkkoronasupplierbusiness'],
            // },
            {
                id: '7.8',
                name: 'master-data',
                title: 'Link Master Company Suppliers',
                route: MasterDataRouterPaths.linkBusinessMasterCompany,
                activeRoute: MasterDataRouterPaths.linkBusinessMasterCompany.substring(4),
                icon: SVG_ICONS.linkPlus,
                requiredPermissions: ['view_linkretailerssuppliermastercompany'],
            },
            {
                id: '7.9',
                name: 'master-data',
                title: 'Master Company Data Import',
                route: MasterDataRouterPaths.masterCompanyDataImport,
                activeRoute: MasterDataRouterPaths.masterCompanyDataImport.substring(4),
                icon: SVG_ICONS.import,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '8.0',
                name: 'master-data',
                title: 'Master Supplier Data Import',
                route: MasterDataRouterPaths.masterSupplierDataImport,
                activeRoute: MasterDataRouterPaths.masterSupplierDataImport.substring(4),
                icon: SVG_ICONS.import,
                requiredPermissions: ['view_linkkoronasupplierbusiness'],
            },
            {
                id: '8.1',
                name: 'master-data',
                title: 'Master Data Product Bulk Link',
                route: MasterDataRouterPaths.masterBulkProductsLink,
                activeRoute: MasterDataRouterPaths.masterBulkProductsLink.substring(4),
                icon: SVG_ICONS.import,
                requiredPermissions: ['view_linkkoronasupplierbusiness'],
            },
            {
                id: '8.2',
                name: 'master-data',
                title: 'Approve Temp Products',
                route: MasterDataRouterPaths.rtlAddedTempProductsList,
                activeRoute: MasterDataRouterPaths.rtlAddedTempProductsList.substring(4),
                icon: SVG_ICONS.clipboardEdit,
                requiredPermissions: ['view_masterproduct'],
            },
        ],
    },
    {
        id: '8',
        name: 'master-reports',
        title: 'Master Reports',
        route: '/web/master-reports/',
        icon: SVG_ICONS.reports,
        subItems: [
            {
                id: '10.2',
                name: 'master-reports',
                title: 'Homeneeds Supp Update Summary',
                route: MasterDataRouterPaths.hnSuppUpdateSummary,
                activeRoute: MasterDataRouterPaths.hnSuppUpdateSummary.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser', 'view_masterproduct'],
            },
            {
                id: '8.1',
                name: 'master-reports',
                title: 'Master Identifier CQ Mismatch Supp Product CQ',
                route: MasterDataRouterPaths.masterIdentifierCQMismatchSuppProductCQReport,
                activeRoute: MasterDataRouterPaths.masterIdentifierCQMismatchSuppProductCQReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser', 'view_masterproduct'],
            },
            {
                id: '8.2',
                name: 'master-reports',
                title: 'Master Identifier Duplicates',
                route: MasterDataRouterPaths.masterIdentifierDuplicates,
                activeRoute: MasterDataRouterPaths.masterIdentifierDuplicates.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
            {
                id: '8.3',
                name: 'master-reports',
                title: 'Master Item Linked To Retailer Multiple Items',
                route: MasterDataRouterPaths.masterItemLinkedRetailerMultipleItemsReport,
                activeRoute: MasterDataRouterPaths.masterItemLinkedRetailerMultipleItemsReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
            {
                id: '8.4',
                name: 'master-reports',
                title: 'Master Item Linked To Deleted Item',
                route: MasterDataRouterPaths.masterItemLinkedToDeletedItemReport,
                activeRoute: MasterDataRouterPaths.masterItemLinkedToDeletedItemReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
            {
                id: '8.5',
                name: 'master-reports',
                title: 'Master Product Duplicates',
                route: MasterDataRouterPaths.masterProductDuplicates,
                activeRoute: MasterDataRouterPaths.masterProductDuplicates.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_masterproductduplicate'],
            },
            {
                id: '8.6',
                name: 'master-reports',
                title: 'Master Product Name Unit Mismatch',
                route: MasterDataRouterPaths.masterProductNameUnitMismatchReport,
                activeRoute: MasterDataRouterPaths.masterProductNameUnitMismatchReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproductnameunitmismatch'],
            },
            {
                id: '8.7',
                name: 'master-reports',
                title: 'Master Product Orphans',
                route: MasterDataRouterPaths.masterProductOrphansReport,
                activeRoute: MasterDataRouterPaths.masterProductOrphansReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_masterproductorphans'],
            },
            {
                id: '8.8',
                name: 'master-reports',
                title: 'Master Products Brand Mismatch',
                route: MasterDataRouterPaths.masterProductBrandMismatchReport,
                activeRoute: MasterDataRouterPaths.masterProductBrandMismatchReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_masterproductbrandmismatch'],
            },
            {
                id: '8.9',
                name: 'master-reports',
                title: 'Master Products Without Barcode',
                route: MasterDataRouterPaths.masterProductsWithoutBarcodeReport,
                activeRoute: MasterDataRouterPaths.masterProductsWithoutBarcodeReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '9.0',
                name: 'master-reports',
                title: 'Master Products Without Quantity',
                route: MasterDataRouterPaths.masterProductsWithoutQtyValueReport,
                activeRoute: MasterDataRouterPaths.masterProductsWithoutQtyValueReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '9.1',
                name: 'master-reports',
                title: 'Master Products Without Unit Measure',
                route: MasterDataRouterPaths.masterProductsWithoutUnitMeasureReport,
                activeRoute: MasterDataRouterPaths.masterProductsWithoutUnitMeasureReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '9.2',
                name: 'master-reports',
                title: 'Master To Business Product Name Mismatch',
                route: MasterDataRouterPaths.masterBusinessProductNameMismatchReport,
                activeRoute: MasterDataRouterPaths.masterBusinessProductNameMismatchReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['superuser'],
            },
            {
                id: '9.3',
                name: 'master-reports',
                title: 'Produce Codes',
                route: MasterDataRouterPaths.produceCodesReport,
                activeRoute: MasterDataRouterPaths.produceCodesReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '9.4',
                name: 'master-reports',
                title: 'Retailer Code Not In Linked Master Product Identifier',
                route: MasterDataRouterPaths.retailerCodeNotInLinkedMasterProductIdentifierReport,
                activeRoute: MasterDataRouterPaths.retailerCodeNotInLinkedMasterProductIdentifierReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_retailtercodenotlinkedmasterproductidentifierreport'],
            },
            {
                id: '9.5',
                name: 'master-reports',
                title: 'Retailer Codes Within Group Not In Master Identifier',
                route: MasterDataRouterPaths.retailerCodesWithinGroupNotInMasterIdentifierReport,
                activeRoute: MasterDataRouterPaths.retailerCodesWithinGroupNotInMasterIdentifierReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_retailercodeswithingroupnotinmasteridentifierreport'],
            },
            {
                id: '9.6',
                name: 'master-reports',
                title: 'Retailers Supplier Items Unlinked',
                route: MasterDataRouterPaths.retailersSupplierItemsNotLinkedToMasterItemReport,
                activeRoute: MasterDataRouterPaths.retailersSupplierItemsNotLinkedToMasterItemReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_retailerssupplieritemsnotlinkedtomasteritem'],
            },
            {
                id: '9.7',
                name: 'master-reports',
                title: 'Roc By Supplier Company',
                route: MasterDataRouterPaths.rocBySupplierCompany,
                activeRoute: MasterDataRouterPaths.rocBySupplierCompany.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct'],
            },
            {
                id: '9.8',
                name: 'master-reports',
                title: 'Suppliers Linked To Retailers',
                route: MasterDataRouterPaths.suppliersLinkedToRetailersReport,
                activeRoute: MasterDataRouterPaths.suppliersLinkedToRetailersReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_supplierslinkretailerreport'],
            },
            {
                id: '9.9',
                name: 'master-reports',
                title: 'Supplier Products Not Linked To Master',
                route: MasterDataRouterPaths.supplierProductNotLinkedToMasterReport,
                activeRoute: MasterDataRouterPaths.supplierProductNotLinkedToMasterReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_supplieritemsnotlinkedtomaster'],
            },
            {
                id: '10.0',
                name: 'master-reports',
                title: 'Supplier Products',
                route: MasterDataRouterPaths.supplierProductListReport,
                activeRoute: MasterDataRouterPaths.supplierProductListReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_supplierproductlistreport'],
            },
            {
                id: '10.1',
                name: 'master-reports',
                title: 'Supplier Reorder Code Mismatch',
                route: MasterDataRouterPaths.supplierReorderCodeMismatchReport,
                activeRoute: MasterDataRouterPaths.supplierReorderCodeMismatchReport.substring(4),
                icon: SVG_ICONS.product,
                requiredPermissions: ['view_masterproduct', 'view_supplierreordercodemismatch'],
            },

        ],
    },
    {
        id: '11',
        name: 'web-store',
        title: 'Web Store',
        route: '/web/store/',
        icon: SVG_ICONS.cart,
        subItems: [
            // {
            //     id: '11.1',
            //     name: 'store-dashboard',
            //     title: 'Dashboard',
            //     route: '',
            //     activeRoute: '',
            //     icon: SVG_ICONS.dashboard,
            //     requiredPermissions: ['superuser'],
            // },
            // {
            //     id: '11.2',
            //     name: 'store-orders',
            //     title: 'Orders',
            //     route: '',
            //     activeRoute: '',
            //     icon: SVG_ICONS.clipboardEdit,
            //     requiredPermissions: ['superuser'],
            // },
            // {
            //     id: '11.3',
            //     name: 'store-report',
            //     title: 'Report',
            //     route: '',
            //     activeRoute: '',
            //     icon: SVG_ICONS.reports,
            //     requiredPermissions: ['superuser'],
            // },
            {
                id: '11.4',
                name: 'store-settings',
                title: 'Settings',
                route: BusinessRouterPaths.webStoreSettings,
                activeRoute: BusinessRouterPaths.webStoreSettings.substring(4),
                icon: SVG_ICONS.settingsGear,
                requiredPermissions: ['superuser'],
            },
            {
                id: '11.5',
                name: 'store-file-import',
                title: 'File Import',
                route: BusinessRouterPaths.importFile,
                activeRoute: BusinessRouterPaths.importFile.substring(4),
                icon: SVG_ICONS.upload,
                requiredPermissions: ['superuser'],
            }
        ]
    }
];

export const RetailerSecondaryMenuItems: SideMenuItem[] = [
    {

        id: '0',
        name: 'device',
        title: 'Device',
        route: "/web/devices/",
        icon: SVG_ICONS.devices,
        subItems: [
            {
                id: '0.1',
                name: 'devices',
                title: 'Devices',
                route: BusinessRouterPaths.deviceManager,
                activeRoute: BusinessRouterPaths.deviceManager.substring(4),
                icon: SVG_ICONS.devices,
                requiredPermissions: ['view_device'],
            },
            {
                id: '0.2',
                name: 'device-update',
                title: 'Device Update',
                route: BusinessRouterPaths.deviceUpdate,
                activeRoute: BusinessRouterPaths.deviceUpdate.substring(4),
                icon: SVG_ICONS.clipboardEdit,
                requiredPermissions: ['view_device'],
            },
        ]
    },
    {
        id: '1',
        name: 'users',
        title: 'Users',
        route: BusinessRouterPaths.userManager,
        icon: SVG_ICONS.users,
        requiredPermissions: ['view_user'],
    },
    
    {
    id: '2',
    name: 'business',
    title: 'Business',
    route: "/web/business/",
    icon: SVG_ICONS.business,
    subItems: [
        {
            id: '2.1',
            name: 'businesses-list',
            title: 'Businesses',
            route: BusinessRouterPaths.businessList,
            activeRoute: BusinessRouterPaths.businessList.substring(4),
            icon: SVG_ICONS.business,
            requiredPermissions: ['view_business'],
        },
        {
            id: '2.2',
            name: 'business-create',
            title: 'Create Business',
            route: BusinessRouterPaths.createbusiness,
            activeRoute: BusinessRouterPaths.createbusiness.substring(4),
            icon: SVG_ICONS.business,
            requiredPermissions: ['view_business'],
        },
        {
            id: '2.3',
            name: 'business-queue-tasks',
            title: 'Business Queue Tasks',
            route: BusinessRouterPaths.businessQueueTasks,
            activeRoute: BusinessRouterPaths.businessQueueTasks.substring(4),
            icon: SVG_ICONS.businessQueue,
            requiredPermissions: ['view_businessqueuesettings']
        },
        ,
        {
            id: '2.4',
            name: 'business-sync-tasks',
            title: 'Business sync Exception',
            route: BusinessRouterPaths.businessSyncTasks,
            activeRoute: BusinessRouterPaths.businessSyncTasks.substring(4),
            icon: SVG_ICONS.businessQueue,
            requiredPermissions: ['view_businessqueuesettings']
        },
    ]
    },
    
];
