import { SVG_ICONS } from '../../shared/icons/SvgIcons';
import GlobalStyles from '../../../styles/GlobalStyles.web';
import { ReportCard } from './models';

export const RetailSummaryFnMapping: { [key: string]: string; } = {
  deviceSummary: 'getDeviceSummary',
  userSummary: 'getUserSummary',
  productSummary: 'getProductSummary',
  labelerSummary: 'getLabelerSummary',
  stockOrderSummary: 'getStockOrderSummary',
  stockReceiptSummary: 'getStockReceiptSummary',
  inventorySummary: 'getInventorySummary',
};

export const RetailDashboardCards: ReportCard[] = [
  {
    id: '1',
    title: 'Modules',
    icon: SVG_ICONS.module,
    iconColor: GlobalStyles.green,
    navigationLink: 'account',
    isEnabled: true,
    // summaryMapping: 'moduleSummary',
    requiredPermissions: [],
    items: [
      {
        id: '1.1',
        title: 'Available',
        defaultValue: '12',
        isDateTime: false,
        isVisible: true,
      },
      {
        id: '1.2',
        title: 'Subscribed',
        defaultValue: '8',
        isDateTime: false,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
  {
    id: '2',
    title: 'Products',
    icon: SVG_ICONS.product,
    iconColor: GlobalStyles.primaryColor,
    navigationLink: 'products',
    isEnabled: true,
    summaryMapping: 'productSummary',
    requiredPermissions: ["view_product"],
    items: [
      {
        id: '2.1',
        title: 'Total',
        itemMapping: 'totalCount',
        isDateTime: false,
        isVisible: true,
      },
      {
        id: '2.2',
        title: 'Without barcode',
        itemMapping: 'withoutBarcodeCount',
        isDateTime: false,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
  {
    id: '3',
    title: 'Users',
    icon: SVG_ICONS.users,
    iconColor: GlobalStyles.orange,
    navigationLink: 'users',
    summaryMapping: 'userSummary',
    isEnabled: true,
    requiredPermissions: ['view_user'],
    items: [
      {
        id: '3.1',
        title: 'Total',
        itemMapping: 'activeUserCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '3.2',
        title: 'Mobile Access',
        itemMapping: 'deviceAccessCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '3.3',
        title: 'Web Access',
        itemMapping: 'webAccessCount',
        isDateTime: false,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
  {
    id: '4',
    title: 'Devices',
    icon: SVG_ICONS.devices,
    iconColor: GlobalStyles.lightBlue,
    navigationLink: 'devices',
    isEnabled: true,
    summaryMapping: 'deviceSummary',
    requiredPermissions: ['view_device'],
    items: [
      {
        id: '4.1',
        title: 'Total',
        itemMapping: 'totalCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '4.2',
        title: 'Enabled',
        itemMapping: 'activeCount',
        isDateTime: false,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
  {
    id: '5',
    title: 'Inventories',
    icon: SVG_ICONS.inventory,
    iconColor: GlobalStyles.primaryColor,
    navigationLink: 'inventories',
    isEnabled: true,
    summaryMapping: 'inventorySummary',
    requiredPermissions: ["view_inventory"],
    items: [
      {
        id: '5.1',
        title: 'Inventory Counts',
        itemMapping: 'totalInventoryCounts',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '5.2',
        title: 'Having Count Lists',
        itemMapping: 'totalInventoryCountList',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '5.3',
        title: 'Open',
        itemMapping: 'openInventoryListCount',
        isDateTime: false,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
  {
    id: '6',
    title: 'Labeler Templates',
    icon: SVG_ICONS.labeler,
    iconColor: GlobalStyles.orange,
    navigationLink: 'templates',
    isEnabled: true,
    summaryMapping: 'labelerSummary',
    requiredPermissions: ["view_labelertemplate"],
    items: [
      {
        id: '6.1',
        title: 'Total',
        itemMapping: 'totalCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '6.2',
        title: 'Enabled',
        itemMapping: 'activeCount',
        isDateTime: false,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
  {
    id: '7',
    title: 'Stock Orders',
    icon: SVG_ICONS.stockOrder,
    iconColor: GlobalStyles.orange,
    navigationLink: 'stockOrders',
    isEnabled: true,
    summaryMapping: 'stockOrderSummary',
    requiredPermissions: ["view_stockorder"],
    items: [
      {
        id: '7.1',
        title: 'Total',
        itemMapping: 'totalCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '7.2',
        title: 'Open',
        itemMapping: 'openCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '7.3',
        title: 'Oldest Still Open',
        itemMapping: 'oldestOpenDate',
        isDateTime: true,
        isVisible: true
      },
      {
        id: '7.4',
        title: 'Last created',
        itemMapping: 'lastCreatedOn',
        isDateTime: true,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
  {
    id: '8',
    title: 'Stock Receipts',
    icon: SVG_ICONS.stockReceipt,
    iconColor: GlobalStyles.green,
    navigationLink: 'stockReceipts',
    isEnabled: true,
    summaryMapping: 'stockReceiptSummary',
    requiredPermissions: ["view_stockreceipt"],
    items: [
      {
        id: '8.1',
        title: 'Total',
        itemMapping: 'totalCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '8.2',
        title: 'Open',
        itemMapping: 'openCount',
        isDateTime: false,
        isVisible: true
      },
      {
        id: '8.3',
        title: 'Oldest Still Open',
        itemMapping: 'oldestOpenDate',
        isDateTime: true,
        isVisible: true
      },
      {
        id: '8.4',
        title: 'Last Created',
        itemMapping: 'lastCreatedOn',
        isDateTime: true,
        isVisible: true,
        isLastItem: true
      },
    ]
  },
];