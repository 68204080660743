import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { PaginatedResponse } from '../../../shared/models';
import { getSuppliersLinkedToRetailers } from '../../api';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, useQuery, tableOptions } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { Filter, FilterType } from '../../../shared/models/Filter';
import { AvailableFilters, SelectedFilters, FilterRow } from '../../../products/components/Filters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mpLink: {
      "&:hover": {
        cursor: 'pointer',
        textDecoration: 'underline'
      },
    }
  }),
);

const FILTERS: Filter[] = [
  {
    id: 'f1',
    label: 'Business',
    types: [
      { id: '1', value: 'business__id__in', label: 'is equal' },
      { id: '2', value: 'business__id__not_in', label: 'is not equal' },
    ],
    selectedType: 'business__id__in',
    value: [],
    url: 'korona/master/businesses/',
    labelField: 'name',
    bindValue: 'id',
    isResponsePaginated: true
  },
  {
    id: 'f2',
    label: 'Supplier is Linked',
    types: [
      { id: '1', value: 'supp_is', label: 'is equal' },
    ],
    url: null,
    selectedType: 'supp_is',
    value: '',
    choices: [
      { id: '1', value: 'linked', label: 'Yes' },
      { id: '2', value: 'not_linked', label: 'No' },

    ],
  },
  {
    id: 'f3',
    label: 'Last Order',
    types: [
      { id: '1', value: 'last_order_within', label: 'is equal' },
    ],
    url: null,
    selectedType: 'last_order_within',
    value: '',
    choices: [
      { id: '1', value: 'one_month', label: 'Within a month' },
      { id: '2', value: 'three_months', label: 'Within 3 months' },
      { id: '2', value: 'six_months', label: 'Within 6 months' },
      { id: '2', value: 'year', label: 'Within a year' },

    ],
  },
];




const SuppliersLinkedToRetailersScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [filterVisible, showFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterString, setFilterString] = useState('');
  const [totalRecords, setTotalRecords] = useState(null as any)



  useEffect(() => {
		if (!isLoading) {
			tableRef.current && tableRef.current.onChangePage(null, 0);
      setTotalRecords(null)
		}
	}, [filterString]);


  useEffect(() => {
    const activeFilters = selectedFilters.filter(
      (f: Filter) => (f.selectedType && f.value && f.value.length > 0) || (f.selectedType.includes('range') && (f.minValue || f.maxValue))
    );
    let filterString = '';
    activeFilters.forEach((f: Filter) => {
      let filterValue = '';
      let isRangeFilter = f.selectedType.includes('range');
      if (f.url && Array.isArray(f.value)) {
        const mappedValues = f.value.map((v) => v[f.bindValue]);
        filterValue = mappedValues.toString();
      }
      else if (!isRangeFilter) {
        filterValue = f.value.toString();
      }
      if (isRangeFilter) {
        if (f.minValue) {
          filterString += `&${f.selectedType}_min=${f.minValue}`;
        }
        if (f.maxValue) {
          filterString += `&${f.selectedType}_max=${f.maxValue}`;
        }
      }
      else {
        filterString += `&${f.selectedType}=${filterValue}`;
      }
    });
    setFilterString(filterString);
  }, [selectedFilters]);


  const handleRefresh= () => {
    setTotalRecords(null)
    tableRef.current && tableRef.current.onQueryChange()
  }

  const toggleFilterPanel = () => {
    showFilters(!filterVisible);
  };

  const addFilter = (filter) => {
    if (!selectedFilters.find(f => f.id === filter.id)) {
      const filters = [...selectedFilters];
      filters.push(filter);
      setSelectedFilters(filters);
    }
  };

  const removeFilter = (index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1);
    setSelectedFilters(filters);
  };

  const updateFilter = (filter, index) => {
    const filters = [...selectedFilters];
    filters.splice(index, 1, filter);
    // console.log("Updated-Filter",filters)
    setSelectedFilters(filters);
  };

  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getSuppliersLinkedToRetailers(
        null,
        null,
        tableQuery.search,
        true ? totalRecords : false,
        sb.orderBy,
        filterString,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'SuppliersLinkRetailer.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };



  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };



  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />
      <div style={{ maxWidth: '100%' }}>
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            components={{
              Toolbar: (props) => (
                <>
                  <MTableToolbar {...props} />
                  {filterVisible && (
                    <>
                      <FilterRow
                        {...props}
                        renderAvailableFilters={
                          <AvailableFilters
                            filters={FILTERS}
                            addFilter={addFilter}
                          />
                        }
                        renderSelectedFilters={
                          <SelectedFilters
                            selectedFilters={selectedFilters}
                            removeFilter={removeFilter}
                            updateFilter={updateFilter}
                            businessId={null}
                          />
                        }
                      />
                    </>
                  )}
                </>
              ),
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            isLoading={isLoading}
            columns={[
              {
                title: 'Business',
                field: 'rtl_name',
                render: (rowData: any) => {

                  if (rowData.rtl_name != null) {
                    return (<div style={{ minWidth: rowData.rtl_name != null ? "380px" : null }}>  {rowData.rtl_name}  </div>)
                  }
                  return ' ';
                }
              },
              {
                title: 'SO Supplier Name',
                field: 'so_supp_name',
                render: (rowData: any) => {

                  if (rowData.so_supp_name != null) {
                    return (<div style={{ minWidth: rowData.so_supp_name != null ? "380px" : null }}>  {rowData.so_supp_name}  </div>)
                  }
                  return ' ';
                }
              },
              { 
                title: 'Last Order', 
                field: 'last_order',
                render: (rowData: any) => {

                  if (rowData.last_order != null) {
                    return (<div style={{ minWidth: rowData.last_order != null ? "150px" : null }}>  {rowData.last_order}  </div>)
                  }
                  return ' ';
                }
              },
              { title: 'No. of Orders', field: 'num_orders' },
              {
                title: 'Linked Supplier Name',
                field: 'linked_supp_name',
                render: (rowData: any) => {

                  if (rowData.linked_supp_name != null) {
                    return (<div style={{ minWidth: rowData.linked_supp_name != null ? "380px" : null }}>  {rowData.linked_supp_name}  </div>)
                  }
                  return ' ';
                }
              },
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                replaceHistory(query, props.history);
                let pageData = {
                  data: [] as any[],
                  totalCount: 0,
                  page: query.page
                };
                let orderBy = '';
                if (query.orderBy) {
                  orderBy = query.orderBy.field as string;
                  orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                }
                let totalRecsAvailable = totalRecords !== null ?  true : false
                getSuppliersLinkedToRetailers(
                  query.page + 1,
                  query.pageSize,
                  query.search,
                  totalRecsAvailable,
                  orderBy,
                  filterString,
                  false
                )
                  .catch(handleError) // handle network issues
                  .then(checkStatus)
                  .then(parseJSON)
                  .then((data: PaginatedResponse<any>) => {
                    setErrorStatusVisible(false);
                    setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                    if(!totalRecords){
                      setTotalRecords(data.count)
                    }
                    pageData = {
                      data: data.results,
                      totalCount: !totalRecords ? data.count : totalRecords,
                      page: query.page
                    };
                    setTableQuery({
                      ...tableQuery,
                      orderBy,
                      totalCount: !totalRecords ? data.count : totalRecords,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    resolve(pageData);
                  })
                  .catch((error: any) => {
                    if (error) {
                      if (error.status && error.status === 500) {
                        setEmptyDataSourceMessage(ERROR_500);
                        setErrorStatusVisible(false);
                      } else if ((error.status === 403 || error.status === 401)
                      ) {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(true);
                      } else {
                        setEmptyDataSourceMessage(ERROR_MSG);
                        setErrorStatusVisible(false);
                      }
                    }
                    setTableQuery({
                      ...tableQuery,
                      totalCount: 0,
                      page: query.page,
                      pageSize: query.pageSize
                    });
                    resolve(pageData);
                  });
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Suppliers Linked to Retailers'}
                icon={SVG_ICONS.reports}
              />
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => handleRefresh(),
              },
              {
                icon: 'filter_list',
                tooltip: filterVisible ? 'Hide Filters' : 'Show Filters',
                isFreeAction: true,
                iconProps: {
                  color: filterVisible ? 'primary' : 'inherit',
                },
                // hidden: !isSuperAdmin(props),
                onClick: toggleFilterPanel,
              },
            ]}
          />
          <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(SuppliersLinkedToRetailersScreen);