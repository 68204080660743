
import { BusinessSupplier } from '../../../master-data/models';
import { ProductCodeType, ProductSupplier, Supplier } from '../../../products/models';
import { 
    SET_ADD_CODE_ACTION_DATA, CLEAR_ADD_CODE_ACTION_DATA
} from '../actions/actionTypes';

export interface CodeActionData { 
    code: string;
    codeType: ProductCodeType.SCAN_CODE | ProductCodeType.REORDER_CODE;
    // srCreationMethod: SRCreationMethod.BLANK | SRCreationMethod.FROM_STOCK_ORDER | SRCreationMethod.EXISTING_RECEIPT
    srCreationMethod: string;
    supplier: BusinessSupplier;
}

export enum SRCreationMethod {
    BLANK = 'Empty Stock Receipt',
    FROM_STOCK_ORDER = 'From Stock Order',
    EXISTING_RECEIPT = 'Or work with an existing stock receipt'
}

const initialState: CodeActionData = {
    code: null,
    codeType: null,
    srCreationMethod: null,
    supplier: null
}

export const addCodeToProductReducer = (state=initialState, action: any) => {
    switch(action.type) {
        case SET_ADD_CODE_ACTION_DATA: return {
            ...state, 
            ...action.data

        }
        case CLEAR_ADD_CODE_ACTION_DATA: return {
            ...initialState
        }
        default: return state;
    }
}