import {
    GET_DEVICES, GET_DEVICES_SUCCESS, GET_DEVICES_FAILURE, UPDATE_DEVICE,
    DELETE_DEVICE, ADD_DEVICE, UPDATE_DEVICE_SUCCESS, UPDATE_DEVICE_FAILURE,
    DELETE_DEVICE_SUCCESS, DELETE_DEVICE_FAILURE,
    GET_DEVICE_AUTHORIZED_ACTIVE_COUNT,
    GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_SUCCESS, GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_FAILURE,
    GET_DEVICE_SUMMARY, GET_DEVICE_SUMMARY_FAILURE, GET_DEVICE_SUMMARY_SUCCESS
} from './actionTypes';
import composeRequest, { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import { Device } from '../../models';
import { API_URLS } from '../../api';
import { PaginatedResponse } from '../../../shared/models';

export function getDevicesFromAPI() {
    return (dispatch: any) => {
        dispatch(getDevices());
        const URL = API_URLS.deviceListUrl;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: PaginatedResponse<Device>) => {
                dispatch(getDevicesSuccess(data));
            })
            .catch((err: any) => {
                dispatch(getDevicesFailure(err));
            });
    };
}

export function updateDeviceAPI(data: Device) {
    return (dispatch: any) => {
        dispatch(updateDevice(data));
        let URL = API_URLS.deviceListUrl;
        const deviceId = data.id;
        data.device_platform = 'android';
        URL = URL + deviceId + '/';
        composeRequest(URL, 'PUT', JSON.stringify(data))
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: Device) => {
                dispatch(updateDeviceSuccess(data));
            })
            .catch((err: any) => {
                dispatch(updateDeviceFailure(err));
            });
    };
}

export function deleteDeviceAPI(data: Device) {
    return (dispatch: any) => {
        dispatch(deleteDevice(data));
        let URL = API_URLS.deviceListUrl;
        const deviceId = data.id;
        URL = URL + deviceId;
        composeRequest(URL, 'DELETE', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: Device) => {
                dispatch(deleteDeviceSuccess(data));
            })
            .catch((err: any) => {
                dispatch(deleteDeviceFailure(err));
            });
    };
}

export interface DeviceSummaryResponse {
    activeCount: number;
    authorizedCount: number,
    totalCount: number;
}

export function getDeviceDataSummary(businessId: number | string) {
    return (dispatch: any) => {
        dispatch(getDeviceSummary());
        const URL = API_URLS.deviceSummaryDataUrl + `?business_id=${businessId}`;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: DeviceSummaryResponse) => {
                dispatch(getDeviceSummarySuccess(data));
            })
            .catch((err: any) => {
                dispatch(getDeviceSummaryFailure(err));
            });
    };
}

function getDevices() {
    return {
        type: GET_DEVICES
    };
}

function getDevicesSuccess(data: PaginatedResponse<Device>) {
    return {
        type: GET_DEVICES_SUCCESS,
        data
    };
}

function getDevicesFailure(err: any) {
    return {
        type: GET_DEVICES_FAILURE,
        err
    };
}

export function updateDevice(data: any) {
    return {
        type: UPDATE_DEVICE,
        data
    };
}

function updateDeviceSuccess(data: Device) {
    return {
        type: UPDATE_DEVICE_SUCCESS,
        data
    };
}

function updateDeviceFailure(err: any) {
    return {
        type: UPDATE_DEVICE_FAILURE,
        err
    };
}

export function deleteDevice(data: any) {
    return {
        type: DELETE_DEVICE,
        data
    };
}

export function deleteDeviceSuccess(data: any) {
    return {
        type: DELETE_DEVICE_SUCCESS,
        data
    };
}
export function deleteDeviceFailure(data: any) {
    return {
        type: DELETE_DEVICE_FAILURE,
        data
    };
}
export function addDevice(data: any) {
    return {
        type: ADD_DEVICE,
        data
    };
}
function getAuthorizedAndActiveDeviceCount() {
    return {
        type: GET_DEVICE_AUTHORIZED_ACTIVE_COUNT
    };
}

function getDeviceSummary() {
    return {
        type: GET_DEVICE_SUMMARY
    };
}

function getDeviceSummarySuccess(data: DeviceSummaryResponse) {
    return {
        type: GET_DEVICE_SUMMARY_SUCCESS,
        data
    };
}

function getDeviceSummaryFailure(err: any) {
    return {
        type: GET_DEVICE_SUMMARY_FAILURE,
        err
    };
}