import {
    SET_SELECTED_INVENTORY_RECEIPT, CLEAR_SELECTED_INVENTORY_RECEIPT,
    GET_INVENTORY_RECEIPT, GET_INVENTORY_RECEIPT_SUCCESS, GET_INVENTORY_RECEIPT_FAILURE,
    GET_RECEIPTS_BY_INVENTORY_ID,
    GET_RECEIPTS_BY_INVENTORY_ID_SUCCESS, GET_RECEIPTS_BY_INVENTORY_ID_FAILURE
} from './actionTypes';
import { InventoryReceipt } from '../../models';
import composeRequest, { checkStatus, handleError, parseJSON, } from '../../../shared/api/core';
import { API_URLS } from '../../api';


export function getInventoryReceiptsByInventoryId(uuid: string) {
    return (dispatch: any) => {
        dispatch(getReceiptsByInventoryId());
        const URL = API_URLS.inventoryReceiptsByInventoryUrl + `${uuid}/`;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: InventoryReceipt[]) => {
                dispatch(getReceiptsByInventoryIdSuccess(data));
            })
            .catch((error: any) => {
                dispatch(getReceiptsByInventoryIdFailure(error));
            });
    };
}

export function getInventoryReceiptById(uuid: string) {
    return (dispatch: any) => {
        dispatch(getInventoryReceipt());
        const URL = API_URLS.inventoryReceiptsUrl + `${uuid}/`;
        composeRequest(URL, 'GET', null)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: InventoryReceipt) => {
                dispatch(getInventoryReceiptSuccess(data));
            })
            .catch((error: any) => {
                dispatch(getInventoryReceiptFailure(error));
            });
    };
}

function getReceiptsByInventoryId() {
    return {
        type: GET_RECEIPTS_BY_INVENTORY_ID
    };
}

function getReceiptsByInventoryIdSuccess(data: InventoryReceipt[]) {
    return {
        type: GET_RECEIPTS_BY_INVENTORY_ID_SUCCESS,
        data
    };
}

function getReceiptsByInventoryIdFailure(err: any) {
    return {
        type: GET_RECEIPTS_BY_INVENTORY_ID_FAILURE,
        err
    };
}

function getInventoryReceipt() {
    return {
        type: GET_INVENTORY_RECEIPT
    };
}

function getInventoryReceiptSuccess(data: InventoryReceipt) {
    return {
        type: GET_INVENTORY_RECEIPT_SUCCESS,
        data
    };
}

function getInventoryReceiptFailure(err: any) {
    return {
        type: GET_INVENTORY_RECEIPT_FAILURE,
        err
    };
}
export const saveSelectedInventoryReceipt = (data: InventoryReceipt) => (dispatch: any) => {
    dispatch(
        {
            type: SET_SELECTED_INVENTORY_RECEIPT,
            data
        }
    );
    return Promise.resolve();
};
export const clearSelectedInventoryReceipt = () => {
    return {
        type: CLEAR_SELECTED_INVENTORY_RECEIPT
    };
};