import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Typography, Container, SvgIcon, CircularProgress, Select, MenuItem, FormControl, Divider } from '@material-ui/core';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Paper } from '@material-ui/core';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import Papa from 'papaparse';
import MaterialTable from 'material-table';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { initQueryParams, replaceHistory, tableOptions, useQuery, } from '../../../shared/lib/TableQueryParams';
import { useLocation } from 'react-router-dom';
import { MasterSupplierDataImportStatusCode } from '../../models';
import { getRetailerAddedSuppProds, getSupplierMasterCompanies } from '../../api';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy'
import { PaginatedResponse } from '../../../shared/models';
import { EMPTY_ROW_MSG, ERROR_MSG, ERROR_500, API_RESPONSE, NO_PERMISSIONS_ERROR_MSG, ERROR_404, ERROR_409 } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';



export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);

const permission_model = 'masterproduct';


const ApproveTempLinksScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const [tableEditableProp, setTableEditableProp] = useState({});
  let tableRef = useRef<{ onQueryChange: any; onChangePage: any; }>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [filterVisible, showFilters] = useState(false);
  const [intialLoad, setInitialLoad] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [selectedMasterCompany, setSelectedMasterCompany] = useState(null)
  const [supplierMasterCompanies, setSupplierMasterCompanies] = useState(null)

  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [selectedMasterCompany]);

  useEffect(() => {
    setLoading(true)
    getSupplierMasterCompanies()
      .catch(handleError)
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setSupplierMasterCompanies(jsonData);
        setLoading(false)

      })
      .catch((error: any) => {
        console.log('error ', error);
        let msg = 'Failed to get companies!';
        props.showToast({ message: msg, options: ToastError });
        setLoading(false)

      });
  }, []);

  const navigateToTempProductDetailScreen = (rowData: any) => {
    console.log("RowData", rowData)
    const encodedProductName = encodeURIComponent(rowData.invoice_item_description);
    const tempProductDetail = MasterDataRouterPaths.rtlAddedProdDetail + '/' + encodedProductName;
    window.open(`${tempProductDetail}`, rowData)
  };


  const handleSelectMC = (event: any) => {
    console.log("Companyyyy", event.target.value)
    setSelectedMasterCompany(event.target.value);
  }

  const selectMasterCompany = () => {
    if (supplierMasterCompanies) {
      return (
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>

            <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
              Select Supplier Company:
            </Typography>
            <FormControl
              style={{
                marginRight: 16,
              }}>

              <Select
                value={selectedMasterCompany ? selectedMasterCompany : ''}
                onChange={handleSelectMC}
                className={classes.select}
              >
                {supplierMasterCompanies.map(item => (
                  <MenuItem
                    value={item.id}
                    key={item.id}
                  >
                    {item.name}
                  </MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </div>
        </div>
      )
    }

  }

  const renderTable = () => {
    return (
      <Container maxWidth="md">
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              {
                title: 'Description',
                field: 'invoice_item_description',
                editable: 'never',
                render: (rowData: any) => {

                  if (rowData.invoice_item_description != null) {
                    return (<div style={{ minWidth: rowData.invoice_item_description != null ? "200px" : null }}>  {rowData.invoice_item_description}  </div>)
                  }
                  return ' ';
                }
              },
              {
                title: 'Total Count',
                field: 'total_count',
                editable: 'never',
                render: (rowData: any) => {

                  if (rowData.total_count != null) {
                    return (<div style={{ minWidth: rowData.total_count != null ? "50" : null }}>  {rowData.total_count}  </div>)
                  }
                  return ' ';
                }
              }
            ]}
            data={
              query => new Promise((resolve, reject) => {
                if (props.userData && props.userData.selectedBusiness) {
                  const sb = props.userData.selectedBusiness;
                  replaceHistory(query, props.history);
                  let pageData = {
                    data: [] as any,
                    totalCount: 0,
                    page: query.page
                  };
                  let orderBy = '';
                  if (query.orderBy) {
                    orderBy = query.orderBy.field as string;
                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                  }
                  getRetailerAddedSuppProds(
                    query.page + 1,
                    query.pageSize,
                    query.search,
                    null,
                    selectedMasterCompany,
                    orderBy
                  )
                    .catch(handleError) // handle network issues
                    .then(checkStatus)
                    .then(parseJSON)
                    .then((data: PaginatedResponse<any>) => {
                      setErrorStatusVisible(false);
                      setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                      pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      setTableQuery({
                        ...tableQuery,
                        orderBy,
                        totalCount: data.count,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                      setInitialLoad(false)
                    })
                    .catch((error: any) => {
                      if (error) {
                        if (error.status && error.status === 500) {
                          setEmptyDataSourceMessage(ERROR_500);
                          setErrorStatusVisible(false);
                        } else if ((error.status === 403 || error.status === 401)
                        ) {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(true);
                        } else {
                          setEmptyDataSourceMessage(ERROR_MSG);
                          setErrorStatusVisible(false);
                        }
                      }
                      setTableQuery({
                        ...tableQuery,
                        totalCount: 0,
                        page: query.page,
                        pageSize: query.pageSize
                      });
                      resolve(pageData);
                    });
                }

              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Approve Temp Supplier Product Links'}
                icon={SVG_ICONS.product} />
            }
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
              {
                icon: 'edit',
                tooltip: 'Edit',
                onClick: (event, rowData) => navigateToTempProductDetailScreen(rowData),
              },

            ]}
          // editable={tableEditableProp}
          />
        </div>
      </Container>
    );
  }

  return (  

    <>
      {supplierMasterCompanies && selectMasterCompany()}
      <Divider className={classes.divider} />

      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {
        !isLoading &&
        <div>

          {selectedMasterCompany && renderTable()}
        </div>
      }
    </>
  )
}


const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTempLinksScreen);