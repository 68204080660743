import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

export const MatExpansionPanel = withStyles({
  root: {
    marginTop: '0px',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginTop: '16px !important'
    },
  },
  expanded: {},
})(MuiExpansionPanel);

export const MatExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .08)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      // margin: '12px 0',
      margin: '0',
    },
    margin: 0
  },
  expandIcon: {
    padding: 8
  },
  expanded: {},
})(MuiExpansionPanelSummary);

export const MatExpansionPanelDetails = withStyles(theme => ({
  root: {
    //   padding: theme.spacing(2),
    padding: 0,
  },
}))(MuiExpansionPanelDetails);