
import _difference from 'lodash/difference';
import { SideMenuItem } from 'components/src/modules/page/components/SideDrawer/Menu/models';
import { Permission } from 'components/src/modules/shared/models/Permission';

export const getAuthorizedMenuItems = (menuItems: SideMenuItem[], userPermissions: Permission[], is_superuser: boolean) => {
  if (is_superuser) {
    return menuItems;
  }
  const permissions = userPermissions.map(p => p.codename);
  let items: SideMenuItem[] = [];
  menuItems.forEach(m => {
    if (!m.subItems) {
      if (isMenuItemAuthorized(m, permissions)) {
        items.push(m);
      }
    }
    if (m.subItems) {
      const newMenuItem = { ...m };
      newMenuItem.subItems = [];
      m.subItems.forEach(s => {
        if (isMenuItemAuthorized(s, permissions)) {
          //@ts-ignore
          newMenuItem.subItems.push(s);
        }
      });
      if (newMenuItem.subItems.length > 0) {
        items.push(newMenuItem);
      }
    }
  });
  return items;
};

const isMenuItemAuthorized = (menuItem: SideMenuItem, userPermissions: string[]) => {
  if (menuItem.requiredPermissions && menuItem.requiredPermissions.length > 0) {
    return _difference(menuItem.requiredPermissions, userPermissions).length === 0;
  }
  return true;
};