// import AsyncStorage from '@react-native-community/async-storage';
import { AsyncStorage } from 'react-native';
import { checkStatus, parseJSON, parseText, handleError } from './common';
import { LOGIN_HEADERS } from '../../../auth/api';

export default function composeRequest(
  url: string,
  method: string,
  body: any,
  content_type?: string,
  public_auth_token?:string,
  signal?: AbortSignal

) {
  try {
    const headersPromise = AsyncStorage.getItem('headers');
    // console.log("headersPromise",headersPromise)
    return headersPromise.then(headerString => {
      // console.log("header string",headerString)
      if (headerString) {
        const reqHeaders = JSON.parse(headerString);
        if (content_type) {
          reqHeaders['Content-Type'] = content_type;
          if (public_auth_token){
            reqHeaders['USER_AUTH_TOKEN']=public_auth_token;
          }
          if (content_type === 'text/plain') {
            reqHeaders['accept'] = 'text/plain';
          } else if (content_type === "multipart/form-data") {
            delete reqHeaders['Content-Type'];
          }
         
        }
        if (method === 'GET') {
          // console.log("inside get")
          return fetch(url, {
            headers: reqHeaders,
            method: method,
            signal: signal
          });
        } else if (method === 'POST') {
          return fetch(url, {
            headers: reqHeaders,
            method: method,
            body: body,
            signal: signal
          });
        } else if (method === 'PUT') {
          return fetch(url, {
            headers: reqHeaders,
            method: method,
            body: body,
            signal: signal
          });
        } else if (method === 'PATCH') {
          return fetch(url, {
            headers: reqHeaders,
            method: method,
            body: body,
            signal: signal
          });
        } else if (method === 'DELETE') {
          return fetch(url, {
            headers: reqHeaders,
            method: method,
            signal: signal
          });
        }
      }
    });
  } catch {
    console.log('error in asyncStorage');
  }
  return fetch(url, {
    headers: LOGIN_HEADERS,
    method: method,
    body: body,
    signal: signal
  });
}

const getPaginatedResponse = (
  URL: string,
  page: number,
  pageSize: number,
  searchTerm: string | null
) => {
  URL = `${URL}?page=${page}&page_size=${pageSize}`;
  if (searchTerm) {
    URL = `${URL}?search=${searchTerm}&page=${page}&page_size=${pageSize}`;
  }
  return composeRequest(URL, 'GET', null);
};


export { checkStatus, parseJSON, parseText, handleError, getPaginatedResponse };
