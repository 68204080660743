import { combineReducers, Action } from 'redux';
import {
    GET_MASTER_PRODUCTS,
    GET_MASTER_PRODUCTS_FAILURE,
    GET_MASTER_PRODUCTS_SUCCESS,
    DELETE_MASTER_PRODUCT,
    DELETE_MASTER_PRODUCT_FAILURE,
    DELETE_MASTER_PRODUCT_SUCCESS,
    UPDATE_MASTER_PRODUCT,
    UPDATE_MASTER_PRODUCT_FAILURE,
    UPDATE_MASTER_PRODUCT_SUCCESS,
    ADD_MASTER_PRODUCT,
    ADD_MASTER_PRODUCT_FAILURE,
    ADD_MASTER_PRODUCT_SUCCESS,
    SEARCH_MASTER_PRODUCTS,
    SEARCH_MASTER_PRODUCTS_SUCCESS,
    SEARCH_MASTER_PRODUCTS_FAILURE,
    SET_SELECTED_MASTER_PRODUCT,
    CLEAR_SELECTED_MASTER_PRODUCT,
} from '../actions/actionTypes';
import { MasterProduct } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

export interface InitialState {
    data: PaginatedResponse<MasterProduct>;
    masterProductList: MasterProduct[];
    selectedMasterProduct: null | MasterProduct;
    isFetching: boolean;
    success: boolean;
    error: boolean;
    errorData: any;
}

const initialState: InitialState = {
    data: {
        count: 0,
        results: [],
        next: null,
        previous: null,
    } as PaginatedResponse<MasterProduct>, // products
    masterProductList: [] as MasterProduct[],
    selectedMasterProduct: null as MasterProduct,
    isFetching: false,
    success: false,
    error: false,
    errorData: null,
};

export function masterProductListReducer(state = initialState, action: any) {
    let nextProducts = [];
    let oldList = [];
    let masterProductList = [];
    switch (action.type) {
        case GET_MASTER_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case GET_MASTER_PRODUCTS_SUCCESS:
            // return {
            //     ...state,
            //     isFetching: false,
            //     data: action.data,
            //     success: true,
            //     error: false
            // }
            nextProducts = [...action.data.results];
            oldList = [];
            if (!action.resetList) {
                oldList = [...state.masterProductList];
            }
            masterProductList = oldList.concat(nextProducts);
            return {
                ...state,
                isFetching: false,
                data: action.data,
                masterProductList: masterProductList,
                success: true,
                error: false,
                errorData: null,
            };
        case GET_MASTER_PRODUCTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case SEARCH_MASTER_PRODUCTS:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case SEARCH_MASTER_PRODUCTS_SUCCESS:
            // console.log(action.data)
            nextProducts = [...action.data.results];
            oldList = [];
            if (!action.resetList) {
                oldList = [...state.masterProductList];
            }
            masterProductList = oldList.concat(nextProducts);
            return {
                ...state,
                isFetching: false,
                data: action.data,
                masterProductList: masterProductList,
                success: true,
                error: false,
                errorData: null,
            };
        case SEARCH_MASTER_PRODUCTS_FAILURE:
            return {
                ...state,
                data: initialState,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case SET_SELECTED_MASTER_PRODUCT:
            return {
                ...state,
                selectedMasterProduct: action.data,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case CLEAR_SELECTED_MASTER_PRODUCT:
            return {
                ...state,
                selectedMasterProduct: null,
                isFetching: false,
                success: false,
                error: false,
                errorData: null,
            };
        case DELETE_MASTER_PRODUCT:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case DELETE_MASTER_PRODUCT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                // data: action.data.results,
                success: true,
                error: false,
                errorData: null,
            };
        case DELETE_MASTER_PRODUCT_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case UPDATE_MASTER_PRODUCT:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case UPDATE_MASTER_PRODUCT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                // data: action.data.results,
                success: true,
                error: false,
                errorData: null,
            };
        case UPDATE_MASTER_PRODUCT_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        case ADD_MASTER_PRODUCT:
            return {
                ...state,
                isFetching: true,
                success: false,
                error: false,
                errorData: null,
            };
        case ADD_MASTER_PRODUCT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                // data: action.data.results,
                success: true,
                error: false,
                errorData: null,
            };
        case ADD_MASTER_PRODUCT_FAILURE:
            return {
                ...state,
                isFetching: false,
                success: false,
                error: true,
                errorData: action.err ? action.err : null,
            };
        default:
            return state;
    }
}
