import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            width: '100%',
            height: 56,
            bottom: 0,
            backgroundColor: '#455a64',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff'
        },
        textCenter: {
            textAlign: 'center'
        },
    }),
);

const Footer = (props: any) => {
    const classes = useStyles({});
    const year = moment().format('YYYY');
    return (
        <div className={classes.footer}>
            <div className={classes.textCenter}>
                <Typography variant='h6'>Konnect &copy; {year}</Typography>
            </div>
        </div>
    )
}

export default Footer;