import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import MaterialTable, { MaterialTableProps } from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Business, LocationBusiness } from '../../../shared/models';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterSupplier, BusinessSupplierLinkRecord, BusinessSupplier } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS, getUnMappedSuppliers, getUnMappedBusinesses, getMasterSuppliersByPage, getBusinessList } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';

interface LinkSupplierModalProps {
    onClose?: (canceled: boolean, success: boolean, errorData?: any) => any,
    isOpen: boolean,
    mode: string,
    onSave?: () => any,
    onUpdate?: () => any,
    business: LocationBusiness,
    linkedRecord?: BusinessSupplierLinkRecord | null | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#F3F5F7'
        }
    }),
);

//@ts-nocheck
export default function LinkSupplierModal(props: LinkSupplierModalProps) {
    const classes = useStyles({});
    const [selectedBusinessSupplier, setSelectedBusinessSupplier] = useState<BusinessSupplier | null>(null);
    const [selectedMasterSupplier, setSelectedMasterSupplier] = useState<MasterSupplier | null>(null);
    const [selectedNewMasterSupplier, setNewSelectedMasterSupplier] = useState<MasterSupplier | null>(null);
    const masterSupplierTableRef = useRef<{ onQueryChange: any; }>();
    const unmappedSupplierTableRef = useRef<{ onQueryChange: any; }>();

    useEffect(() => {
        if (props.linkedRecord) {
            const { korona_supplier, korona_supplier_business } = props.linkedRecord;
            setSelectedBusinessSupplier(korona_supplier);
            setSelectedMasterSupplier(korona_supplier_business);
        }
    }, [props.linkedRecord]);

    const getCurrentMasterSupplierLabel = () => {
        if (props.mode === 'edit') {
            return 'Current Master Supplier';
        }
        return 'Master Supplier';
    };

    const addRecord = () => {
        if (selectedBusinessSupplier && selectedMasterSupplier) {
            const URL = API_URLS.masterKoronaSupplierBusinessLinkUrl;
            const data = {
                business: props.business.business_id,
                korona_supplier_business: selectedMasterSupplier.id,
                korona_supplier: selectedBusinessSupplier.uuid
            };
            composeRequest(URL, 'POST', JSON.stringify(data))
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then((jsonData: any) => {
                    props.onClose && props.onClose(false, true);
                })
                .catch((error: any) => {
                    props.onClose && props.onClose(false, false, error);
                });
        }
    };

    const updateRecord = () => {
        if (selectedNewMasterSupplier && props.linkedRecord) {
            const URL = API_URLS.masterKoronaSupplierBusinessLinkUrl + props.linkedRecord.id + '/';
            const data = {
                id: props.linkedRecord.id,
                business: props.linkedRecord.business,
                korona_supplier: props.linkedRecord.korona_supplier.uuid,
                korona_supplier_business: selectedNewMasterSupplier.id
            };
            composeRequest(URL, 'PUT', JSON.stringify(data))
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then((jsonData: any) => {
                    props.onClose && props.onClose(false, true);
                })
                .catch((error: any) => {
                    props.onClose && props.onClose(false, false, error);
                });
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={props.mode === 'add' ? true : false}
                maxWidth={props.mode === 'add' ? 'md' : 'sm'}
                open={props.isOpen}
                onClose={() => props.onClose && props.onClose(true, false)}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {
                        props.mode === 'add' ? 'New Supplier Link Record' : 'Edit Supplier Link Record'
                    }
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {
                            props.mode === 'add' &&
                            <div>
                                <MaterialTable
                                    options={{
                                        debounceInterval: 650,
                                        pageSize: 5,
                                        padding: 'dense',
                                        headerStyle: {
                                            backgroundColor: '#eee',
                                            paddingTop: 6,
                                            paddingBottom: 6
                                        },
                                        emptyRowsWhenPaging: false,
                                        minBodyHeight: 160,
                                        maxBodyHeight: 300,
                                        showTitle: false,
                                        selection: true,
                                        showSelectAllCheckbox: false,
                                        showTextRowsSelected: false,
                                        searchFieldAlignment: 'left',
                                        selectionProps: (rowData: any) => {
                                            const checked = rowData && rowData.tableData && rowData.tableData.checked;
                                            return {
                                                disabled: selectedBusinessSupplier && !checked ? true : false,
                                                color: 'primary'
                                            };
                                        }
                                    }}
                                    columns={[
                                        { title: 'Korona Supplier', field: 'name' },
                                    ]}
                                    data={query =>
                                        new Promise((resolve, reject) => {
                                            setSelectedBusinessSupplier(null);
                                            const pageData = {
                                                data: [] as MasterSupplier[],
                                                totalCount: 0,
                                                page: query.page
                                            };
                                            if (props && props.business) {
                                                getUnMappedSuppliers(props.business.business_id,
                                                    query.page + 1, query.pageSize, query.search)
                                                    .catch(handleError) // handle network issues
                                                    .then(checkStatus)
                                                    .then(parseJSON)
                                                    .then((data: PaginatedResponse<MasterSupplier>) => {
                                                        pageData.data = data.results;
                                                        pageData.totalCount = data.count;
                                                        pageData.page = query.page;
                                                        resolve(pageData);
                                                    })
                                                    .catch((error: any) => {
                                                        resolve(pageData);
                                                    });
                                            } else {
                                                resolve(pageData);
                                            }
                                        })
                                    }
                                    tableRef={unmappedSupplierTableRef}
                                    onSelectionChange={(rows: any) => {
                                        if (rows.length > 0) {
                                            let businessSupplier = { ...rows[0] } as any;
                                            delete businessSupplier['tableData'];
                                            setSelectedBusinessSupplier(businessSupplier);
                                        } else {
                                            setSelectedBusinessSupplier(null);
                                        }
                                    }}
                                />
                            </div>
                        }
                        <div>
                            <MaterialTable
                                options={{
                                    pageSize: 5,
                                    padding: 'dense',
                                    headerStyle: {
                                        backgroundColor: '#eee',
                                        paddingTop: 6,
                                        paddingBottom: 6
                                    },
                                    emptyRowsWhenPaging: false,
                                    minBodyHeight: 160,
                                    maxBodyHeight: 300,
                                    showTitle: false,
                                    selection: true,
                                    showSelectAllCheckbox: false,
                                    showTextRowsSelected: false,
                                    searchFieldAlignment: 'left',
                                    debounceInterval: 350,
                                    selectionProps: (rowData: any) => {
                                        const checked = rowData && rowData.tableData && rowData.tableData.checked;
                                        const selectionProps = {
                                            disabled: selectedMasterSupplier && !checked ? true : false,
                                            color: 'primary'
                                        };
                                        if (props.mode !== 'add') {
                                            selectionProps.disabled = selectedNewMasterSupplier && !checked ? true : false;
                                        }
                                        return selectionProps;
                                    }
                                }}
                                columns={[
                                    { title: 'ID', field: 'id', editable: 'never' },
                                    { title: 'Master Supplier', field: 'name' },
                                ]}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        if (props.mode === 'add') {
                                            setSelectedMasterSupplier(null);
                                        } else if (props.mode === 'edit') {
                                            setNewSelectedMasterSupplier(null);
                                        }
                                        const pageData = {
                                            data: [] as Business[],
                                            totalCount: 0,
                                            page: query.page
                                        };
                                        getUnMappedBusinesses(query.page + 1, query.pageSize, query.search)
                                            .catch(handleError) // handle network issues
                                            .then(checkStatus)
                                            .then(parseJSON)
                                            .then((data: PaginatedResponse<Business>) => {
                                                pageData.data = data.results;
                                                pageData.totalCount = data.count;
                                                pageData.page = query.page;
                                                resolve(pageData);
                                            })
                                            .catch((error: any) => {
                                                resolve(pageData);
                                            });
                                    })
                                }
                                tableRef={masterSupplierTableRef}
                                onSelectionChange={((rows: any) => {
                                    if (rows.length > 0) {
                                        let masterSupplier = { ...rows[0] } as any;
                                        delete masterSupplier['tableData'];
                                        if (props.mode === 'add') {
                                            setSelectedMasterSupplier(masterSupplier);
                                        } else {
                                            setNewSelectedMasterSupplier(masterSupplier);
                                        }
                                    } else {
                                        if (props.mode === 'add') {
                                            setSelectedMasterSupplier(null);
                                        } else {
                                            setNewSelectedMasterSupplier(null);
                                        }
                                    }
                                })}
                            />
                        </div>
                    </div>
                    <div style={props.mode === 'edit' ? styles.selectColumn : styles.selectRow}>
                        <TextField
                            id="selected-business-supplier"
                            label="Korona Supplier"
                            margin="normal"
                            value={selectedBusinessSupplier ? selectedBusinessSupplier.name : ''}
                        />
                        <TextField
                            id="selected-master-supplier"
                            disabled={props.mode === 'edit' ? true : false}
                            label={getCurrentMasterSupplierLabel()}
                            margin="normal"
                            value={selectedMasterSupplier ? selectedMasterSupplier.name : ''}
                        />
                        {
                            props.mode === 'edit' &&
                            <TextField
                                id="selected-new-master-supplier"
                                label="New Master Supplier"
                                margin="normal"
                                value={selectedNewMasterSupplier ? selectedNewMasterSupplier.name : ''}
                            />
                        }
                    </div>
                </DialogContent>
                <DialogActions >
                    <Button onClick={() => props.onClose && props.onClose(true, false)} color="primary">Cancel</Button>
                    {
                        props.mode === 'add' &&
                        <Button onClick={() => addRecord()} color="primary">Save</Button>
                    }
                    {
                        props.mode === 'edit' &&
                        <Button onClick={updateRecord} color="primary">Update</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

const styles = {
    selectColumn: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        maxWidth: 300,
        margin: 'auto'

    },
    selectRow: {
        display: 'flex',
        justifyContent: 'space-around'
    }
};