import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button, Paper, Typography, CircularProgress, TextField, TextareaAutosize
} from '@material-ui/core';
import { Toast, enqueueSnackbar } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      // maxWidth: "100%",
      // padding: theme.spacing(1, 1),
      borderRadius: 0,
      paddingTop: 12,
      paddingLeft: 24,
      paddingRight: 24,
      // paddingBottom: 16
    },
    fileInputWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 12,
      paddingBottom: 12,
      alignSelf: 'flex-end'
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
    errorText: {
      color: '#f44336'
    },
    textLabel: {
      paddingLeft: 16,
      marginTop: 6,
      marginBottom: 6
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    instructions: {
      //paddingBottom: 12,
      alignSelf: 'center'
    },
    sectionTitle: {
      paddingTop: 16,
      paddingBottom: 16
    },
    formRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '64px'
    },
    uploadContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px'
    }
  }),
);


const JsonImport = (props: any) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //console.log("props.history",props.history);
  const {
    uploadPDF, parseCSV,
    selectedFile, selectedFileError,
    jsonUrl,
    handleJsonUrlChange,
    setVendorDataJson,
    vendorDataJson
  } = props;

  const handleUploadClick = (event: any) => {
    console.log("handle upload click", event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleUploadMenuClose = (type: string) => {
    setAnchorEl(null);
    if (type === 'pdf') {
      const el = document.getElementById('pdf-file-input');
      el && el.click();
    } else if (type === 'tsv') {
      const el = document.getElementById('tsv-file-input');
      el && el.click();
    }
  };

  useEffect(() => {
    if (props.selectedFile) {
      // console.log("props.selectedFile",props.selectedFile)
      const fileExtension = props.selectedFile.name.split('.').pop();
      const validExtensions = ['csv', 'tsv', 'txt'];
      if (fileExtension.toLowerCase() === 'pdf') {
        uploadPDF();
      } else if (validExtensions.includes(fileExtension)) {
        props.setIsFileExtensionPDF(false)
        parseCSV();
      }
    }
  }, [props.selectedFile, props.selectedSupplier]);

  return (
    <div style={{ width: '100%', maxHeight: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8 }}>
        {
          !selectedFile && selectedFileError.hasError &&
          <div className={classes.textLabel}>
            <Typography variant="subtitle1" className={classes.errorText}>{selectedFileError.message}</Typography>
          </div>
        }
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', marginLeft: 10, marginRight: 10 }} >
          <Typography variant="h4" className={classes.sectionTitle}>Let's Get Started!</Typography>
          <Typography variant="subtitle1" style={{ marginBottom: 8 }} className={classes.instructions}> Enter the vendor provided JSON url:  </Typography>
          <TextField
            style={{ width: '65rem', marginBottom: 8 }}
            margin="dense"
            variant="outlined"
            value={jsonUrl != null ? jsonUrl : ''}
            onChange={(event) => handleJsonUrlChange(event.target.value)}
            disabled={vendorDataJson !== null ? true : false}
          />
          <Typography variant="subtitle2" style={{ marginBottom: 8 }} className={classes.instructions}> OR</Typography>
          <Typography variant="subtitle1" style={{ marginBottom: 8 }} className={classes.instructions}> Enter JSON in the field:  </Typography>
          <TextareaAutosize
            disabled={jsonUrl != null ? true : false}
            style={{ width: '30rem' }}
            rowsMin={10}
            value={vendorDataJson != null ? vendorDataJson : ''}
            onChange={(event) => setVendorDataJson(event.target.value)}
          />

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
    dnData: state.dnModuleData.dnData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JsonImport);
