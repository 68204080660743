import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  labelerTemplatesUrl: BASE_URL + 'labeler/templates/',
  labelerZPLPreviewUrl: BASE_URL + 'labeler/templates/preview/',
  labelerSummaryDataUrl: BASE_URL + 'labeler/templates/summary/'
};


export const getTemplatesByPage = (
  page: number, 
  pageSize: number,
  searchTerm: string | null, 
  businessId: number | string,
  orderBy: string | number,
) => {
  let URL = API_URLS.labelerTemplatesUrl +
    `?business_ids=${businessId}&page=${page}&page_size=${pageSize}`;

  if (searchTerm) {
    URL = API_URLS.labelerTemplatesUrl +
      `?business_id=${businessId}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
  }
  if(orderBy){
    URL = `${URL}&orderBy=${orderBy}`;
  } 
  return composeRequest(URL, 'GET', null);
};

export const deleteTemplate = (data: any) => {
  const URL = API_URLS.labelerTemplatesUrl + data.id + '/';
  return composeRequest(URL, 'DELETE', null);
};