import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import {
    TextField, Button, Input, MenuItem, Typography, Paper,
    SvgIcon, FormControl, Select, InputLabel, CircularProgress
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterSupplier, MasterSupplierLinkedRecord, BusinessSupplierLinkRecord } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { getBusinessListFromAPI } from '../../../shared/data/actions';

import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { LinkSupplierModal } from '../../components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError } from '../../../shared/components/Notifier';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { CustomTableTitle, ErrorStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG, NO_PERMISSIONS_ERROR_MSG } from '../../../shared/lib/Localization';
import { hasAddPermission, hasUpdatePermission, hasDeletePermission } from '../../../shared/lib/PermissionManager';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import { getUnMappedBusinessSuppliersCount, deleteMasterSupplierLinkRecord, getLinkedMasterSuppliersByPage } from '../../api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
        formControl: {
            // margin: theme.spacing(1),
            minWidth: 120,
            width: '100%',
            maxWidth: '16rem'
        },
        progress: {
            margin: theme.spacing(2),
        },
        defaultText: {
            fontSize: '0.875rem',
            fontWeight: 400
        }
    }),
);
const permission_model = 'linkmasterbusinesssupplier';

interface SupplierDialog {
    open: boolean;
    business: any;
    onClose: any; //((canceled: boolean, success: boolean, errorData?: any) => void | null);
    mode: 'add' | 'edit',
    linkedRecord: BusinessSupplierLinkRecord | undefined | null;
}

const initalDialogData: SupplierDialog = {
    open: false,
    business: null,
    onClose: null,
    mode: 'add',
    linkedRecord: undefined
};

const MasterSupplierLinkScreen = (props: any) => {
    const classes = useStyles({});
    // const [selectedBusiness, setSelectedBusiness] = useState({name : '', id: -1} as Business);
    const [supplierDialogData, setSupplierDialogData] = useState<SupplierDialog>(initalDialogData);
    const [unMappedSupplierCountData, setUnMappedSupplierCountData] = useState({ unmapped_supplier_count: 0 });
    const tableRef = useRef<{ onQueryChange: any; }>();
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const [tableEditableProp, setTableEditableProp] = useState<any>({});
    const [disableEditAction, setDisableEditAction] = useState(false);
    const [disableAddAction, setDisableAddAction] = useState(false);
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    // useEffect(() => {
    //     props.getBusinessList();
    // }, []);

    // const handleBusinessChange = (event: React.ChangeEvent<{ name?: string; value: number }>) => {
    //     setSelectedBusiness({ name: event.target.name,  id: event.target.value });
    // };

    useEffect(() => {
        tableRef && tableRef.current && tableRef.current.onQueryChange();
        if (props.userData && props.userData.selectedBusiness) {
            const sb = props.userData.selectedBusiness;
            getUnMappedBusinessSuppliersCount(sb.business_id)
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then((jsonData: any) => {
                    setUnMappedSupplierCountData(jsonData);
                })
                .catch((error: any) => {
                    console.log('error fetching unmapped supplier count', error);
                });
        }

    }, [props.userData.selectedBusiness]);

    const openSupplierModalAddMode = () => {
        if (props.userData && props.userData.selectedBusiness) {
            const sb = props.userData.selectedBusiness;
            setSupplierDialogData({
                open: true,
                business: sb,
                onClose: (canceled: boolean, success: boolean, errorData?: any) => {
                    setSupplierDialogData(initalDialogData);
                    tableRef.current && tableRef.current.onQueryChange();
                    if (!canceled) {
                        if (success) {
                            props.showToast({
                                message: 'Record added successfully!',
                                options: ToastSuccess
                            });
                        } else {
                            let msg = 'Failed to add record.';
                            if (errorData) {
                                if ((errorData.status === 403 || errorData.status === 401)) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (errorData.status === 500) {
                                    msg = ERROR_500;
                                }
                            }
                            props.showToast({ message: msg, options: ToastError });
                        }
                    }
                },
                mode: 'add',
                linkedRecord: null
            });
        }
    };

    const openModalEditMode = (event: any, rowData: any) => {
        const { userData } = props.userData.userData;
        const { selectedBusiness } = props.userData;
        if (hasUpdatePermission(userData.is_superuser,
            userData.user_permissions, permission_model)) {
            setErrorStatusVisible(false);
            if (selectedBusiness && selectedBusiness.business_id) {
                setSupplierDialogData({
                    open: true,
                    business: selectedBusiness,
                    onClose: (canceled: boolean, success: boolean, errorData?: any) => {
                        setSupplierDialogData(initalDialogData);
                        tableRef.current && tableRef.current.onQueryChange();
                        if (!canceled) {
                            if (success) {
                                props.showToast({
                                    message: 'Record updated successfully!',
                                    options: ToastSuccess
                                });
                            } else {
                                let msg = 'Failed to upate record.';
                                if (errorData) {
                                    if ((errorData.status === 403 || errorData.status === 401)) {
                                        msg = NO_PERMISSIONS_ERROR_MSG;
                                    } else if (errorData.status === 500) {
                                        msg = ERROR_500;
                                    }
                                }
                                props.showToast({ message: msg, options: ToastError });
                            }
                        }
                    },
                    mode: 'edit',
                    linkedRecord: rowData
                });
            }
        } else {
            // setErrorStatusVisible(true);
            props.showToast({ message: NO_PERMISSIONS_ERROR_MSG, options: ToastError });
        }
    };

    const getDeleteFn = () => {
        return (oldData: any) => new Promise((resolve, reject) => {
            setTimeout(() => {
                {
                    deleteMasterSupplierLinkRecord(oldData)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then((jsonData: any) => {
                            props.showToast({
                                message: 'Record deleted successfully!',
                                options: ToastSuccess
                            });
                            tableRef.current && tableRef.current.onQueryChange();
                        })
                        .catch((error: any) => {
                            let msg = 'Failed to delete record.';
                            if (error) {
                                if ((error.status === 403 || error.status === 401)) {
                                    msg = NO_PERMISSIONS_ERROR_MSG;
                                } else if (error.status === 500) {
                                    msg = ERROR_500;
                                }
                            }
                            props.showToast({ message: msg, options: ToastError });
                        });
                }
                resolve();
            }, 1000);
        });
    };

    useEffect(() => {
        const { userData } = props.userData.userData;
        const editable: any = {};
        if (userData) {
            if (hasDeletePermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                editable['onRowDelete'] = getDeleteFn();
            }
            if (hasUpdatePermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                setDisableEditAction(false);
            } else {
                setDisableEditAction(true);
            }
            if (hasAddPermission(userData.is_superuser,
                userData.user_permissions, permission_model)) {
                setDisableAddAction(false);
            } else {
                setDisableAddAction(true);
            }
            setTableEditableProp(editable);
        }
    }, [props.userData]);


    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <Paper className={classes.paper}>
                <div style={{ display: 'flex', }}>
                    <div style={{ width: '100%' }}>
                        {
                            props.userData.selectedBusiness &&
                            props.userData.selectedBusiness.business_id &&
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', flexWrap: 'wrap', paddingTop: 12 }}>
                                <Typography variant='h6'
                                    style={{ alignSelf: 'center', color: GlobalStyles.primaryColor, padding: '0 16px' }}
                                >Business Suppliers not mapped:&nbsp;</Typography>
                                <Typography variant='h6'
                                    style={{ alignSelf: 'center', paddingRight: 24 }}>
                                    {unMappedSupplierCountData.unmapped_supplier_count}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={disableAddAction}
                                    onClick={() => openSupplierModalAddMode()}>
                                    Map Now
                            </Button>
                            </div>
                        }

                    </div>
                </div>
            </Paper>
            <LinkSupplierModal
                isOpen={supplierDialogData.open}
                mode={supplierDialogData.mode}
                business={supplierDialogData.business}
                onClose={supplierDialogData.onClose}
                linkedRecord={supplierDialogData.linkedRecord}
            />
            <div style={{ maxWidth: '100%' }}>
                <div className="table-wrapper">
                    <MaterialTable
                        // options={{
                        //     debounceInterval: 650,
                        //     pageSize: 10,
                        //     // padding: 'dense',
                        //     headerStyle: {
                        //         backgroundColor: '#eee',
                        //     }
                        // }}
                        options={{ ...tableOptions(tableQuery) }}
                        tableRef={tableRef}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataSourceMessage
                            }
                        }}
                        columns={[
                            {
                                title: 'Number',
                                field: 'business_supplier',
                                editable: 'never',
                                render: rowData => {
                                    if (rowData && rowData.business_supplier && rowData.business_supplier.number) {
                                        return <Typography variant="body1" className={classes.defaultText}>{rowData.business_supplier.number}</Typography>;
                                    }
                                    return <div></div>;
                                },
                            },
                            {
                                title: 'Business Supplier',
                                field: 'business_supplier',
                                render: rowData => {
                                    if (rowData && rowData.business_supplier && rowData.business_supplier.name) {
                                        return <Typography variant="body1" className={classes.defaultText}>{rowData.business_supplier.name}</Typography>;
                                    }
                                    return <div></div>;
                                },
                            },
                            {
                                title: 'Master Supplier ID',
                                field: 'master_supplier',
                                type: 'numeric',
                                render: rowData => {
                                    if (rowData && rowData.master_supplier && rowData.master_supplier.id) {
                                        return <Typography variant="body1" className={classes.defaultText}>{rowData.master_supplier.id}</Typography>;
                                    }
                                    return <div></div>;
                                },
                                editComponent: props => {
                                    return (
                                        <Input
                                            type="number"
                                            value={props.value ? props.value.id : ''}
                                            inputProps={{
                                                'aria-label': 'master_supplier_id',
                                            }}
                                            onChange={e => props.onChange(e.target.value)}
                                        />
                                    );
                                }
                            },
                            {
                                title: 'Master Supplier Name',
                                field: 'master_supplier',
                                editable: 'never',
                                render: rowData => {
                                    if (rowData && rowData.master_supplier && rowData.master_supplier.name) {
                                        return <Typography variant="body1" className={classes.defaultText}>{rowData.master_supplier.name}</Typography>;
                                    }
                                    return <div></div>;
                                },
                            },
                        ]}
                        data={query =>
                            new Promise((resolve, reject) => {
                                replaceHistory(query, props.history);
                                let pageData = {
                                    data: [] as MasterSupplierLinkedRecord[],
                                    totalCount: 0,
                                    page: query.page
                                };
                                if (props.userData && props.userData.selectedBusiness) {
                                    const sb = props.userData.selectedBusiness;
                                    getLinkedMasterSuppliersByPage(sb.business_id,
                                        query.page + 1, query.pageSize, query.search)
                                        .catch(handleError) // handle network issues
                                        .then(checkStatus)
                                        .then(parseJSON)
                                        .then((data: PaginatedResponse<MasterSupplierLinkedRecord>) => {
                                            setErrorStatusVisible(false);
                                            setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                            pageData = {
                                                data: data.results,
                                                totalCount: data.count,
                                                page: query.page
                                            };
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: data.count,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            resolve(pageData);
                                        })
                                        .catch((error: any) => {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_500);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)
                                            ) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                if (error.status) {
                                                    console.log(error.status);
                                                }
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                            setTableQuery({
                                                ...tableQuery,
                                                totalCount: 0,
                                                page: query.page,
                                                pageSize: query.pageSize
                                            });
                                            resolve(pageData);
                                        });
                                }
                            })
                        }
                        title={
                            <CustomTableTitle
                                history={props.history}
                                title={'Link Suppliers OLD'}
                                icon={SVG_ICONS.linkPlus} />
                        }
                        actions={[
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            },
                            {
                                icon: 'edit',
                                tooltip: 'Edit',
                                disabled: disableEditAction,
                                isFreeAction: false,
                                onClick: (event, rowData) => openModalEditMode(event, rowData)
                            }
                        ]}
                        editable={tableEditableProp}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        businessList: state.sharedModuleData.businessList,
        userData: state.userLocalData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getBusinessList: () => dispatch(getBusinessListFromAPI()),
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MasterSupplierLinkScreen);