import React from 'react';
import { AppRegistry } from 'react-native';
import { PersistGate } from 'redux-persist/integration/react';
import AppNavigator from './navigation/AppNavigator';
import { Provider } from 'react-redux';
import configureStore from 'components/src/store/configureStore.web';

export const { persistor, store } = configureStore();

if (!__DEV__) {
  console.log = () => { };
}

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppNavigator />
    </PersistGate>
  </Provider>
);

AppRegistry.registerComponent('connectors_app', () => App);
AppRegistry.runApplication('connectors_app', {
  rootTag: document.getElementById('root'),
});

export default App; 
