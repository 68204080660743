import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { MasterProductDuplicate } from '../../models';
import { PaginatedResponse } from '../../../shared/models';

import { getMasterItemLinkedRetailerMultipleItems } from '../../api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, useQuery, tableOptions } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      mpLink:{
        "&:hover": { 
            cursor: 'pointer',
            textDecoration:'underline'
        },
      }
    }),
);



const MasterItemLinkedRetailerMultipleItemsScreen = (props: any) => {
    const classes = useStyles({});
    const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
    const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
    const tableRef = useRef<{ onQueryChange: any; }>();
    let locationQuery = useQuery(useLocation);
    const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
    const [isExporting, setExporting] = useState(false);
    const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedBusiness, setSelectedBusiness] = useState<string | number | undefined>(-1);
    const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
    const [isLoading, setLoading] = useState(false);


    const exportCSV = () => {
        if (props.userData && props.userData.selectedBusiness) {
            setExporting(true);
            const sb = props.userData.selectedBusiness;
            const shouldExport = true;
            getMasterItemLinkedRetailerMultipleItems(
                null,
                null,
                tableQuery.search,
                sb.orderBy,
                shouldExport
            )
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then((res) => res.blob())
                .then((data) => {
                    download(data, 'Master Item Linked Retailer Multiple Items.csv', 'text/csv');
                    setExporting(false);
                })
                .catch((error: any) => {
                    setExporting(false);
                    if (error) {
                        if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        } else if (error.status === 403 || error.status === 401) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                        } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                        }
                    }
                });
        }
    };



    const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setExportBtnAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = (shouldExport: boolean) => {
        if (shouldExport) {
            exportCSV();
        }
        setExportBtnAnchorEl(null);
    };

    const navigateToMasterProductDetailScreen = (data: any) => {
        const masterProductDetailUrl = MasterDataRouterPaths.masterProducts + '/' + data.mp_id;
        window.open(`${masterProductDetailUrl}`)
    };

    const navigateToProductDetailScreen = (rowData: any) => {
        const productDetailUrl = '/web/products/' + rowData.retailer_item_uuid;
        window.open(`${productDetailUrl}`)
    };


    const handleMpOnClick = (data: any) => {
        if (data) {
            navigateToMasterProductDetailScreen(data)
        }
    }

    const handleProductOnClick = (data: any) => {
        if (data) {
            navigateToProductDetailScreen(data)
        }
    }


    return (
        <div>
            <ErrorStatusBar isVisible={isErrorStatusVisible} />
            <ExportStatusBar isVisible={isExporting} />
            <div style={{ maxWidth: '100%' }}>
                <div className="table-wrapper">
                    <MaterialTable
                        options={{
                            ...tableOptions(tableQuery),
                            thirdSortClick: false,
                            draggable: false,
                            sorting: true
                        }}
                        tableRef={tableRef}
                        localization={{
                            body: {
                                emptyDataSourceMessage: emptyDataSourceMessage
                            }
                        }}
                        columns={[
                            { 
                                title: 'MP ID', 
                                field: 'mp_id',
                                width:'10%',
                                render: (rowData: any) => { 
                                    return(
                                        <div 
                                            className={classes.mpLink}
                                            onClick={()=>handleMpOnClick(rowData)} 
                                        > 
                                            {rowData.mp_id}
                                        </div>
                                    )
                                }
                         },
                            { 
                                title: 'Master Product Name', 
                                field: 'mp_name',
                                render: (rowData: any) => { 
                                    return(
                                        <div 
                                            className={classes.mpLink}
                                            onClick={()=>handleMpOnClick(rowData)} 
                                        > 
                                            {rowData.mp_name}
                                        </div>
                                    )
                                }
                            },
                            { title: 'Master Product Barcodes', field: 'mp_identifiers' },
                            { title: 'Retailer Name', field: 'retailer_name' },
                            { 
                                title: 'Retailer Item Number', 
                                field: 'retailer_item_num',
                                render: (rowData: any) => { 
                                    return(
                                        <div 
                                            className={classes.mpLink}
                                            onClick={()=>handleProductOnClick(rowData)} 
                                        > 
                                            {rowData.retailer_item_num}
                                        </div>
                                    )
                                }
                            },
                            { 
                                title: 'Retailer Item Name', 
                                field: 'retailer_item_name',
                                render: (rowData: any) => { 
                                    return(
                                        <div 
                                            className={classes.mpLink}
                                            onClick={()=>handleProductOnClick(rowData)} 
                                        > 
                                            {rowData.retailer_item_name}
                                        </div>
                                    )
                                }
                            }
                        ]}
                        data={query =>
                            new Promise((resolve, reject) => {
                                replaceHistory(query, props.history);
                                let pageData = {
                                    data: [] as MasterProductDuplicate[],
                                    totalCount: 0,
                                    page: query.page
                                };
                                let orderBy = '';
                                if (query.orderBy) {
                                    orderBy = query.orderBy.field as string;
                                    orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                                }
                                getMasterItemLinkedRetailerMultipleItems(
                                    query.page + 1,
                                    query.pageSize,
                                    query.search,
                                    orderBy,
                                    false
                                )
                                    .catch(handleError) // handle network issues
                                    .then(checkStatus)
                                    .then(parseJSON)
                                    .then((data: PaginatedResponse<MasterProductDuplicate>) => {
                                        setErrorStatusVisible(false);
                                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                                        pageData = {
                                            data: data.results,
                                            totalCount: data.count,
                                            page: query.page
                                        };
                                        setTableQuery({
                                            ...tableQuery,
                                            orderBy,
                                            totalCount: data.count,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                    })
                                    .catch((error: any) => {
                                        if (error) {
                                            if (error.status && error.status === 500) {
                                                setEmptyDataSourceMessage(ERROR_500);
                                                setErrorStatusVisible(false);
                                            } else if ((error.status === 403 || error.status === 401)
                                            ) {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(true);
                                            } else {
                                                setEmptyDataSourceMessage(ERROR_MSG);
                                                setErrorStatusVisible(false);
                                            }
                                        }
                                        setTableQuery({
                                            ...tableQuery,
                                            totalCount: 0,
                                            page: query.page,
                                            pageSize: query.pageSize
                                        });
                                        resolve(pageData);
                                    });
                            })
                        }
                        title={
                            <CustomTableTitle
                                history={props.history}
                                title={'Master Item Linked To Retailer Multiple Items'}
                                icon={SVG_ICONS.product} 
                            />
                        }
                        actions={[
                            {
                                icon: 'save_alt',
                                tooltip: 'Export',
                                isFreeAction: true,
                                onClick: handleExportClick,
                                disabled: isExporting,
                            },
                            {
                                icon: 'refresh',
                                tooltip: 'Refresh Data',
                                isFreeAction: true,
                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                            },
                        ]}
                    />
                    <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        userData: state.userLocalData,
    };
};

export default connect(mapStateToProps, null)(MasterItemLinkedRetailerMultipleItemsScreen);