import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { History } from 'history';

import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GlobalStyles from '../../../../styles/GlobalStyles.web';

import { SideMenuItem } from './Menu/models';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { UserSettings } from '../../../../store/reducers/userDataReducer';
import { Permission } from '../../../shared/models/Permission';
// , SideDrawerPrimaryMenuItems, SideDrawerSecondaryMenuItems } from './RetailerSideDrawerMenu';
// import { MainRoutes } from './Routes';

const drawerWidth = 240;

interface ExpansionPanel {
    isOpen: boolean;
    name: string;
}

interface Props {
    userLocalData: UserSettings;
    isMobileScreen: boolean;
    drawerMenuData: any;
    drawerOpen: boolean;
    handleDrawerClose: () => void;
    history: History;
    location: any;
}

const SideDrawer = (props: Props) => {
    // console.log(props);
    const classes = useStyles({});
    const [menuItem, setMenuItem] = useState({ isOpen: false, name: 'dashboard' });

    const { userData } = props.userLocalData.userData;
    let userLocation = null;
    let masterProductPermission = false;
    if (props.userLocalData.userLocation) {
        userLocation = props.userLocalData.userLocation;
    }
    if (userData) {
        if (userData.is_superuser) {
            masterProductPermission = true;
        } if (userData.user_permissions.length > 0) {
            const permissions = userData.user_permissions.map((p: Permission) => p.codename);
            masterProductPermission = permissions.includes('view_masterproduct');
        }
    }

    useEffect(() => {
        if (props.location && props.location.state) {
            if (props.location.state.menuItemName) {
                handleMenuItemClick(props.location.state.menuItemName);
            }
        }
    }, [props.location]);

    const handleMenuItemClick = (name: string) => {
        menuItem.name === name ?
            setMenuItem({ isOpen: !menuItem.isOpen, name: name }) :
            setMenuItem({ isOpen: true, name: name });
    };

    const containsParentRoute = (routeName: string, subRoute: string) => {
        return subRoute.includes(routeName);
    };

    const renderMenuItem = (item: SideMenuItem, menuItem: ExpansionPanel) => {
        let location = useLocation();
        const currentPath = location && location.pathname;
        return (
            <React.Fragment key={item.id}>
                <ListItem button
                    selected={
                        currentPath === item.route ? true :
                            containsParentRoute(item.route, currentPath)
                    }
                    onClick={() => handleMenuItemClick(item.name)}
                    //@ts-ignore
                    component={item.subItems ? null : Link}
                    to={item.subItems ? null : item.route}
                    className={
                        item.subItems &&
                            menuItem.isOpen &&
                            menuItem.name === item.name ? classes.menuOpen : undefined}
                    classes={{
                        selected: item.subItems ? classes.topListItemSelected
                            : classes.topListItemSingleSelected
                    }}>
                    <ListItemIcon
                        className={
                            containsParentRoute(item.route, currentPath) ?
                                classes.listItemSelected : undefined}>
                        <SvgIcon>
                            <path d={item.icon} />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                    {
                        item.subItems ?
                            (
                                menuItem.isOpen && currentPath === item.route ?
                                    <ExpandLess /> : <ExpandMore />
                            )
                            : null

                    }
                </ListItem>

                {
                    item.subItems && item.subItems.length > 0 ?
                        (<Collapse in={menuItem.isOpen && menuItem.name === item.name}
                            timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {
                                    item.subItems.map(subItem =>
                                        <ListItem button className={classes.nested}
                                            key={subItem.id}
                                            classes={{ root: classes.innerListItem }}
                                            selected={containsParentRoute(subItem.activeRoute, currentPath)}
                                            component={Link} to={subItem.route}>
                                            <ListItemIcon className={
                                                containsParentRoute(subItem.activeRoute, currentPath) ?
                                                    classes.listItemSelected : undefined}>
                                                <SvgIcon>
                                                    <path d={subItem.icon} />
                                                </SvgIcon>
                                            </ListItemIcon>
                                            <ListItemText primary={subItem.title}
                                                className={
                                                    containsParentRoute(subItem.activeRoute, currentPath) ?
                                                        classes.listItemSelected : undefined} />
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Collapse>)
                        : null
                }
            </React.Fragment>
        );
    };

    const { primaryMenuItems, secondaryMenuItems } = props.drawerMenuData;

    const renderSideMenuItems = (menuItems: SideMenuItem[]) => {
        if (menuItems) {
            return menuItems.map(item => renderMenuItem(item, menuItem));
        }
        return null;
    };

    return (
        <Drawer
            className={classes.drawer}
            variant={props.isMobileScreen ? 'temporary' : "persistent"}
            anchor="left"
            open={props.drawerOpen}
            classes={{
                paper: classes.drawerPaper,
                docked: !props.drawerOpen ? classes.shrinkDrawerWidth : undefined
            }}>
            <div className={classes.drawerHeader}>
                <IconButton onClick={() => props.handleDrawerClose()}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <div style={{ overflow: 'auto' }}>
                <List>{renderSideMenuItems(primaryMenuItems)}</List>
                <Divider />
                <List>{renderSideMenuItems(secondaryMenuItems)}</List>
            </div>
        </Drawer>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: theme.mixins.toolbar,
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
            overflow: 'hidden'
        },
        shrinkDrawerWidth: {
            width: 0
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        drawerHeaderBanner: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '16px 0 16px 0'
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            backgroundColor: '#f4f6f8',
            maxWidth: '100%',
            overflow: 'auto',
        },
        listItemSelected: {
            color: GlobalStyles.primaryColor
        },
        topListItemSelected: {
            color: GlobalStyles.primaryColor,
            backgroundColor: '#e9ecef !important',
            borderLeft: '5px solid',
            borderLeftColor: GlobalStyles.primaryColor,
        },
        menuOpen: {
            borderLeft: '6px solid',
            borderLeftColor: GlobalStyles.primaryColor
        },
        topListItemSingleSelected: {
            color: GlobalStyles.primaryColor,
            backgroundColor: 'rgba(0,0,0,0.06) !important',
            borderLeft: '5px solid',
            borderLeftColor: GlobalStyles.primaryColor
        },
        innerListItemSelected: {
            backgroundColor: 'rgba(0,0,0,0.12) !important',
        },
        innerListItem: {
            backgroundColor: '#f5f5f5',
            borderLeft: '5px solid',
            borderLeftColor: GlobalStyles.primaryColor
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        select: {
            color: GlobalStyles.lightTheme,
            marginRight: 4,
            '&:before': {
                borderColor: GlobalStyles.lightTheme,
            },
            '&:after': {
                borderColor: GlobalStyles.lightTheme,
            }
        },
        icon: {
            fill: GlobalStyles.lightTheme,
        },

        iconCircle: {
            display: 'flex',
            justifyContent: 'center',
            padding: '12px',
            borderRadius: '50%',
            border: '2px solid #757575'
        },
        svgIconWrapper: {
            width: 60,
            height: 60,
            opacity: 0.6
        },
        svgIcon: {
            width: '100%',
            height: '100%'
        },
        footer: {
            width: '100%',
            height: 56,
            bottom: 0,
            backgroundColor: '#455a64',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
    }),
);
const mapStateToProps = (state: any) => {
    return {
        userLocalData: state.userLocalData
    };
};

export default connect(mapStateToProps, null)(SideDrawer);
