import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, FormControl, Select, MenuItem } from '@material-ui/core'
import { PaginatedResponse } from '../../../shared/models';

import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getProductsMostRecentSR } from '../../api';
import { getSuppliersByBusiness } from '../../../products/api/Api';
import { Toast, enqueueSnackbar, ToastError, ToastSuccess } from '../../../shared/components/Notifier';

import GlobalStyles from '../../../../styles/GlobalStyles.web';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '25rem',
      minWidth: '9rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
    suppliermsg: {
      color: GlobalStyles.primaryColor,
      fontSize: 16
    },
    errorText: {
      color: theme.palette.error.main,
      fontWeight: 400
    },
    defaultText: {
      fontSize: '0.875rem',
      fontWeight: 400
    },
    overwriteDataRadioButton: {
      // margin: auto;
      // width: 60%;
      // padding: 10px;
      marginLeft: '0.9rem'
    }
  }),
);


const ProductsMostRecentSRScreen = (props: any) => {
  const classes = useStyles({});
  const tableRef = useRef<{ onQueryChange: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [koronaSuppliers, setKoronaSuppliers] = useState([]);
  const [selectedSupplierUUID, setSelectedSupplierUUID] = useState(null as any);



  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
    fetchSuppliersForBusiness();
  }, [props.userData.selectedBusiness]);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [selectedSupplierUUID]);

  const fetchSuppliersForBusiness = () => {
    const sb = props.userData.selectedBusiness;
    getSuppliersByBusiness(sb.business_id, null)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((data: PaginatedResponse<any>) => {
        console.log("SUPPLIERS", data.results)
        setSelectedSupplierUUID(null)
        setKoronaSuppliers(data.results);
      })
      .catch((error: any) => {
      });
  };



  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      if (selectedSupplierUUID) {
        setExporting(true);
        const sb = props.userData.selectedBusiness;
        const shouldExport = true;
        getProductsMostRecentSR(
          sb.business_id,
          selectedSupplierUUID,
          null,
          null,
          tableQuery.search,
          sb.orderBy,
          shouldExport
        )
          .catch(handleError) // handle network issues
          .then(checkStatus)
          .then((res) => res.blob())
          .then((data) => {
            download(data, 'Products_Most_Recent_SR_By_Supplier.csv', 'text/csv');
            setExporting(false);
          })
          .catch((error: any) => {
            setExporting(false);
            if (error) {
              if (error.status && error.status === 500) {
                setEmptyDataSourceMessage(ERROR_MSG);
                setErrorStatusVisible(false);
              } else if (error.status === 403 || error.status === 401) {
                setEmptyDataSourceMessage(ERROR_MSG);
                setErrorStatusVisible(true);
              } else {
                setEmptyDataSourceMessage(ERROR_MSG);
                setErrorStatusVisible(false);
              }
            }
          });
      } else {
        props.showToast({ message: "Select a Korona Supplier", options: ToastError })

      }
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };

  const renderTable = () => {
    return (
      <div>

        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <ExportStatusBar isVisible={isExporting} />

        <div style={{ maxWidth: '100%' }}>
          <div className="table-wrapper">
            <MaterialTable
              options={{
                ...tableOptions(tableQuery),
                thirdSortClick: false,
                draggable: false,
                sorting: true
              }}
              tableRef={tableRef}
              localization={{
                body: {
                  emptyDataSourceMessage: emptyDataSourceMessage
                }
              }}
              columns={[
                {
                  title: 'Kp_Num',
                  field: 'kp_number',
                  render: rowData => {

                    if (rowData.kp_number != null) {
                      return <Typography style={{ minWidth: 75 }}>{rowData.kp_number}</Typography>;
                    }
                    return <Typography>{''}</Typography>;
                  }
                },
                {
                  title: 'Kp_Name ',
                  field: 'kp_name',
                  render: rowData => {

                    if (rowData.kp_name != null) {
                      return <Typography style={{ minWidth: 251 }}>{rowData.kp_name}</Typography>;
                    }
                    return <Typography>{''}</Typography>;
                  }

                },
                {
                  title: 'Recd_Qty',
                  field: 'recd_qty',
                  render: rowData => {

                    if (rowData.recd_qty != null) {
                      return <Typography style={{ minWidth: 50 }}>{rowData.recd_qty}</Typography>;
                    }
                    return <Typography>{''}</Typography>;
                  }
                },
                {
                  title: 'Unit_Price',
                  field: 'unit_price',
                  render: rowData => {

                    if (rowData.unit_price != null) {
                      return <Typography style={{ minWidth: 50 }}>{rowData.unit_price}</Typography>;
                    }
                    return <Typography>{''}</Typography>;
                  }
                },
                {
                  title: 'RoC',
                  field: 'reorder_code',
                  render: rowData => {

                    if (rowData.reorder_code != null) {
                      return <Typography style={{ minWidth: 75 }}>{rowData.reorder_code}</Typography>;
                    }
                    return <Typography>{''}</Typography>;
                  }
                },
                {
                  title: 'Last_SO_Date',
                  field: 'last_so_date',
                  render: rowData => {

                    if (rowData.last_so_date != null) {
                      return <Typography style={{ minWidth: 50 }}>{rowData.last_so_date}</Typography>;
                    }
                    return <Typography>{''}</Typography>;
                  }
                },
                {
                  title: 'description',
                  field: 'description',
                  render: rowData => {

                    if (rowData.description != null) {
                      return <Typography style={{ minWidth: 50 }}>{rowData.description}</Typography>;
                    }
                    return <Typography>{''}</Typography>;
                  }
                },
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  if (!isLoading) {
                    // console.log('query', query);
                    const sb = props.userData.selectedBusiness;
                    const supp_id = selectedSupplierUUID
                    if (sb && sb.business_id && supp_id) {
                      replaceHistory(query, props.history);
                      let pageData = {
                        data: [] as any[],
                        totalCount: 0,
                        page: query.page
                      };
                      let orderBy = '';
                      if (query.orderBy) {
                        orderBy = query.orderBy.field as string;
                        orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                      }
                      getProductsMostRecentSR(
                        sb.business_id,
                        supp_id,
                        query.page + 1,
                        query.pageSize,
                        query.search,
                        orderBy,
                        false
                      )
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then((data: PaginatedResponse<any>) => {
                          setErrorStatusVisible(false);
                          setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                          pageData = {
                            data: data.results,
                            totalCount: data.count,
                            page: query.page
                          };
                          setTableQuery({
                            ...tableQuery,
                            orderBy,
                            totalCount: data.count,
                            page: query.page,
                            pageSize: query.pageSize
                          });
                          resolve(pageData);
                        })
                        .catch((error: any) => {
                          if (error) {
                            if (error.status && error.status === 500) {
                              setEmptyDataSourceMessage(ERROR_500);
                              setErrorStatusVisible(false);
                            } else if ((error.status === 403 || error.status === 401)
                            ) {
                              setEmptyDataSourceMessage(ERROR_MSG);
                              setErrorStatusVisible(true);
                            } else {
                              setEmptyDataSourceMessage(ERROR_MSG);
                              setErrorStatusVisible(false);
                            }
                          }
                          setTableQuery({
                            ...tableQuery,
                            totalCount: 0,
                            page: query.page,
                            pageSize: query.pageSize
                          });
                          resolve(pageData);
                        });
                    }
                  }
                })
              }
              title={
                <CustomTableTitle
                  history={props.history}
                  title={'Products Most Recent SR By Supplier'}
                  icon={SVG_ICONS.product}
                />
              }
              actions={[
                {
                  icon: 'save_alt',
                  tooltip: 'Export',
                  isFreeAction: true,
                  onClick: handleExportClick,
                  disabled: isExporting,
                },
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
            />
          </div>
        </div>
        <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />
      </div>

    )
  }


  const handleSupplierSelectionChange = (event: any) => {
    console.log("supplier", event)
    setSelectedSupplierUUID(event.target.value);
  };

  return (
    <div>
      {
        props.userData &&
        props.userData.selectedBusiness &&
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>

            <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
              Select Korona Supplier:
            </Typography>
            <FormControl
              style={{
                marginRight: 16,
              }}>

              <Select
                labelId="select-label"
                id="select-business"
                value={selectedSupplierUUID ? selectedSupplierUUID : ''}
                onChange={handleSupplierSelectionChange}
                className={classes.select}
              >
                {koronaSuppliers.map(item => (
                  <MenuItem
                    value={item.uuid}
                    key={item.uuid}
                  >
                    {item.name}
                  </MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </div>
          <Divider className={classes.divider} />
          {selectedSupplierUUID != null && renderTable()}

        </div>
      }

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsMostRecentSRScreen);
