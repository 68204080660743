import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Typography, FormControl, FormLabel, FormGroup,
  FormControlLabel, Radio, RadioGroup, Switch, TextField,
  Checkbox, FormHelperText, Tabs, Tab, Card, CardMedia, CardActionArea, CardContent,
  Box, CircularProgress, Button
} from '@material-ui/core';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import { Theme, createStyles, makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core';
import { FileDrop } from 'react-file-drop';

import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../../shared/lib/Localization';
import { getWebStoreOptionsByBusiness, saveWebStoreSettings, getAdditionalUserDetailsWebStore } from '../../api'
import { WebStoreOptions } from '../../models'
import _uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import { PageToolbar } from '../../../page/components';
import { Toast, enqueueSnackbar, ToastSuccess, ToastError, ToastWarning } from '../../../shared/components/Notifier';

import { BusinessRouterPaths } from '../../../page/Routes/RouterPaths';

import { ItemType, ItemCategory } from '../../../master-data/models'
import { Image } from '../../../master-data/components';
import {
  uploadMasterItemImage, getMasterItemImages,
  updateMediaBySelectedAction, createMediaLinkItem

} from '../../../master-data/api';

import GridOnRoundedIcon from '@material-ui/icons/GridOnRounded';
import ListRoundedIcon from '@material-ui/icons/ListRounded';

//@ts-nocheck
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    card: {
      padding: theme.spacing(1),
    },
    tab: {
      flexGrow: 1,
      backgroundColor: 'transparent',
    },
    formControl: {
      margin: theme.spacing(3),
    },
    formContent: {

      display: 'flex',
      flexDirection: 'row'
    },
    formLabel: {
      marginTop: 10,
    },
    textFieldCardRow: {
      marginRight: theme.spacing(1),
      marginBottom: 8,
      width: '50%'
    },
    dnd: {
      border: '1px solid black',
      // display: 'flex',
      // flexWrap: 'wrap',
      // color: 'black',
      padding: '15px',
      borderStyle: 'dashed',
      // justifyContent: 'center',
      marginBottom: '30px',
      marginTop: '10px'

    },
    textFieldStyle: {
      // marginTop:-7,
      width: '50%',
    },
  }),
);

const RequiredSettings = {
  DEFAULT_CASHIER: true,
  DEFAULT_CUSTOMER_GROUP: true,
  DEFAULT_POINT_OF_SALE: true,
}

const WebStoreSettings = (props: any) => {
  const classes = useStyles({});
  const [tabValue, setTabValue] = useState('general')

  const [paymentSettings, setPaymentSettings] = useState({
    allow_pay_at_checkout: null,
    allow_pay_at_pickup: null,
    allow_pay_after_invoice: null
  })

  const [deliverySettings, setDeliverySettings] = useState({
    allow_pickup_at_store: null,
    provide_delivery_service: null,
  })

  const [pickupOptions, setPickupOptions] = useState({
    inStorePickup: null,
    curbSidePickup: null,
  });
  const [showPickupOptions, setShowPickupOptions] = useState(false)
  const [storeLocations, setStoreLocations] = useState([]);
  const [isLoading, setLoading] = useState(false)
  const [settingsSavedSuccessfully, setSettingsSavedSuccessfully] = useState(false)
  const [data, setData] = useState(null as any)

  const [userUploadedMedia, setUserUploadedMedia] = useState(null as any)
  const [mediaUrls, setMediaUrls] = useState([])
  const [userSelectedMedia, setUserSelectedMedia] = useState(null)
  const [mediaData, setMediaData] = useState([])
  const [defaultMediaData, setDefaultMediaData] = useState(null as any)
  const [isMediaLoading, setMediaLoading] = useState(false)
  const [inActiveMedia, setInActiveMedia] = useState([])
  const [showDefaultMedia, setShowDefaultMedia] = useState(false);
  const [selectedMediaForAction, setSelectedMediaForAction] = useState([])
  // const [selectedActionIndex, setSelectedActionIndex] = useState(null)
  const [optionsBtnAnchorEl, setOptionsBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const fileInputRef = useRef(null);

  const [cashiers, setCashiers] = useState([]);
  const [pointOfSales, setPointOfSales] = useState([]);
  const [customerGroups, setCustomerGroups] = useState([])

  const [selectedPos, setSelectedPos] = useState(null)
  const [selectedCg, setSelectedCg] = useState(null)
  const [selectedCashier, setSelectedCashier] = useState(null)

  const [priceSettings, setPriceSettings] = useState(null)
  // display_prices_when_user_is_logged_in: null,
  // display_prices_when_user_is_not_logged_in: null
  // })

  const [zeroPriceSettings, setZeroPriceSettings] = useState(null)
  // zero_price_hidden_from_listing: null,
  //   zero_price_shown_with_alert: null,
  //   zero_price_is_not_hidden: null
  // })

  const [tagSettings, setTagSettings] = useState({
    display_products_only_with_this_tag: false,
    do_not_display_products_with_this_tag: false
  })

  const [deactivatedItemsSettings, setDeactivatedItemsSettings] = useState(null)
  //   deactivated_items_hidden_from_listing: null,
  //   deactivated_items_shown_with_alert: null,
  //   deactivated_items_is_not_hidden: null
  // })

  const [priceOptions, setPriceOptions] = useState({
    display_prices: false,
    zero_price: false
  })

  const [includedTag, setIncludedTag] = useState('')
  const [excludedTag, setExcludedTag] = useState('')
  const [defaultViewSettings, setDefaultViewSettings] = useState(null)

  useEffect(() => {
    if (props.match && props.match.path) {
      if (props.match.path === BusinessRouterPaths.webStoreSettings) {
        handleResetData()
        fetchSettingsByBusiness()

      }
    }
    console.log("PROPSS", props)
  }, [props.userLocalData.selectedBusiness])

  useEffect(() => {
    if (data !== null && data.length > 0) {
      console.log("data-called")
      initializeData(data)
    }
  }, [data])

  // useEffect(() => {
  //   if (deliverySettings && deliverySettings.allow_pickup_at_store) {
  //     deliverySettings.allow_pickup_at_store === true ? setShowPickupOptions(true) : setShowPickupOptions(false)
  //   }

  // }, [deliverySettings])


  // useEffect(() => {
  //   console.log("PaymentSettings", paymentSettings)
  //   console.log("DeliverySettings", deliverySettings)
  //   console.log("PickupOptions", pickupOptions)

  // }, [paymentSettings, deliverySettings, pickupOptions])

  useEffect(() => {
    if (props && props.userLocalData) {
      const { userData } = props.userLocalData.userData
      if (userData) {
        fetchWebStoreAdditionalUserDetails()
      }
    }

  }, [props.userLocalData])


  const fetchWebStoreAdditionalUserDetails = () => {
    setLoading(true);

    const { auth_token } = props.userLocalData.userData.userData
    const payload= { auth_token: auth_token }
    getAdditionalUserDetailsWebStore(payload)
      // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setLoading(false);
        initializeAdditionalData(jsonData)
      })
      .catch((error: any) => {
        if (error) {
          setLoading(false);
          getItemImages(props.userLocalData.selectedBusiness)
        }
      });
  }


  const initializeAdditionalData = (jsonData:any) =>{
    let koronaCashiers = jsonData.korona_cashiers && jsonData.korona_cashiers.length > 0 ? jsonData.korona_cashiers.filter((l: any) => l.business === props.userLocalData.selectedBusiness.business_id) : null
    if (koronaCashiers) {
      koronaCashiers = koronaCashiers.filter((i: any) => {
        i['checked'] = false
        return i
      })
      setCashiers(koronaCashiers)
    }
    let koronaCustomerGroups = jsonData.korona_customer_groups && jsonData.korona_customer_groups.length > 0 ? jsonData.korona_customer_groups.filter((l: any) => l.business === props.userLocalData.selectedBusiness.business_id) : null
    if (koronaCustomerGroups) {
      koronaCustomerGroups = koronaCustomerGroups.filter((i: any) => {
        i['checked'] = false
        return i
      })
      setCustomerGroups(koronaCustomerGroups)
    }
    let koronaPointOfSales = jsonData.korona_point_of_sales && jsonData.korona_point_of_sales.length > 0 ? jsonData.korona_point_of_sales.filter((l: any) => l.business === props.userLocalData.selectedBusiness.business_id) : null
    if (koronaPointOfSales) {
      koronaPointOfSales = koronaPointOfSales.filter((i: any) => {
        i['checked'] = false
        return i
      })
      setPointOfSales(koronaPointOfSales)
    }

    if (props && props.userLocalData) {
      const { userData } = props.userLocalData.userData
      if (userData) {
        let locations = userData.locations && userData.locations.length > 0 ? userData.locations.filter((l: any) => l.business_id === props.userLocalData.selectedBusiness.business_id) : null
        if (locations) {
          locations = locations.filter((i: any) => {
            i['checked'] = false
            return i
          })
          setStoreLocations(locations)
        }
        
      }
    }


  }


  useEffect(() => {
    if (userUploadedMedia) {
      uploadItemImage()
    }
  }, [userUploadedMedia])



  useEffect(() => {
    let defaultImage;
    let inactiveImageUrls;
    if (mediaData.length > 0) {

      let urls = mediaData.map(i => i.media_core ? i.media_core.source : '');
      setMediaUrls(urls)
      // console.log("IMAGE-URLS", urls)

      defaultImage = mediaData.filter(i => i.default === true)
      setDefaultMediaData(defaultImage)
      setUserSelectedMedia(mediaData ? mediaData[0].media_core.source : null)

      // console.log("Default Image", defaultImage)

      inactiveImageUrls = mediaData.filter(i => i.is_active === true)
      setInActiveMedia(inactiveImageUrls)
      // console.log("INACTIVE-IMAGES", inactiveImageUrls)

    }
  }, [mediaData])


  const uploadItemImage = () => {

    setMediaLoading(true)
    const fd = new FormData()


    for (let i = 0; i < userUploadedMedia.length; i++) {

      fd.append('files', userUploadedMedia[i], userUploadedMedia[i].name)
      fd.append('item_category', String(ItemCategory.IMAGE))
    }

    //console.log("form-data", fd)

    uploadMasterItemImage(fd)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        createItemMediaLinks(res)
      })
      .catch((error: any) => {
        setUserUploadedMedia(null)

      });
  }

  const createItemMediaLinks = (mediaCoreData: any) => {

    let data = []

    for (let i = 0; i < userUploadedMedia.length; i++) {

      let obj = {}

      obj['item_id'] = props.userLocalData.selectedBusiness && props.userLocalData.selectedBusiness.business_id
      obj['default'] = mediaData && mediaData.length > 0 ? false : mediaCoreData && mediaCoreData.length == 1 ? true : mediaCoreData && mediaCoreData.length > 1 && i == 0 ? true : false
      obj['item_type'] = ItemType.WEB_STORE_LOGO
      obj['item_category'] = ItemCategory.IMAGE
      obj['is_active'] = true
      obj['is_deleted'] = false
      obj['media_core_id'] = mediaCoreData[i].id && mediaCoreData[i].id
      data.push(obj)
    }

    let payload = { 'item_data': data }

    //console.log("payload", payload)

    createMediaLinkItem(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(data => {
        setUserUploadedMedia(null)
        setMediaLoading(false)
        getItemImages()
        data.failure.length > 0 ?
          props.showToast({
            message: 'Failed to store media for your Business.',
            options: ToastError
          }) :
          props.showToast({
            message: 'Media stored successfully',
            options: ToastSuccess
          });

      })
      .catch((error: any) => {
        setMediaLoading(false);
        setUserUploadedMedia(null)
      });

  }



  const getItemImages = (data?: any) => {
    setMediaLoading(true)
    let payload = {}
    if (data) {
      payload = { item_id: data.business_id, item_type: ItemType.WEB_STORE_LOGO }
    } else {
      payload = { item_id: props.userLocalData.selectedBusiness.business_id, item_type: ItemType.WEB_STORE_LOGO }
    }
    getMasterItemImages(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((res) => {
        let result = res.map(r => ({ ...r, checked: false }))
        setMediaData(result)
        setMediaLoading(false)
        setShowDefaultMedia(false)

      })
      .catch((error: any) => {
        // let msg = ""
        setShowDefaultMedia(true)
        // setShowDefaultMedia(true)
        setMediaLoading(false)
        setMediaData([])
        setMediaUrls([])
        setDefaultMediaData(null)
        setUserSelectedMedia(null)
        setSelectedMediaForAction([])

      });
  }

  const handleSelectedActionIndex = (option: any) => {
    setOptionsBtnAnchorEl(null);
    let selectionAction = option ? option.toLowerCase() : option
    // setMediaLoading(true)
    let selectedMediaActionData = selectedMediaForAction.filter(i => i.checked === true)

    // console.log("selectedMediaActionData", selectedMediaActionData)
    // console.log("Action", selectedMediaForAction)

    let payload = { data: selectedMediaActionData, selected_action: selectionAction }
    // console.log(payload)

    updateMediaBySelectedAction(payload)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        props.showToast({
          message: 'Master brand media updated successfully!',
          options: ToastSuccess
        });
        setMediaLoading(false);
        setOptionsBtnAnchorEl(null);
        setSelectedMediaForAction([])
        removeSelectedImagesForAction()
        getItemImages()

        // setTimeout(() => navigateBack(), 1200);
      })
      .catch((error: any) => {
        let msg = 'Failed to update brand media.';
        if ((error.status === 403 || error.status === 401)
          && error.body.detail.length > 0) {
          msg = NO_PERMISSIONS_ERROR_MSG;
        }
        props.showToast({ message: msg, options: ToastError });
        setMediaLoading(false)
        setOptionsBtnAnchorEl(null);
      });

  }

  const handleSelectedMediaForAction = (data: any) => {
    let selectedImgArr = []

    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex((obj => obj.id == data.id));
    // console.log("ID-FOUND", imageDataArr[checked])
    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : imageDataArr[checked].checked = true
    // console.log("CONVERTED-CHECKED", imageDataArr)
    setMediaData(imageDataArr)
    setSelectedMediaForAction(imageDataArr)
  }

  const removeSelectedImagesForAction = () => {
    let imageDataArr = mediaData.map(i => i)
    let checked = imageDataArr.findIndex(i => i.checked ? i.checked === true : null)

    imageDataArr[checked].checked ? imageDataArr[checked].checked = false : null
    // console.log("filteredArr", imageDataArr)
    setMediaData(imageDataArr)

  }

  const handleResetData = () => {
    setPriceSettings(null)
    setZeroPriceSettings(null)
    setTagSettings({ do_not_display_products_with_this_tag: false, display_products_only_with_this_tag: false })
    setIncludedTag('')
    setExcludedTag('')
    setDeactivatedItemsSettings(null)
    setDefaultViewSettings(null)
  }


  const fetchSettingsByBusiness = () => {
    setLoading(true);
    const { business_id } = props.userLocalData.selectedBusiness;
    const { auth_token } = props.userLocalData.userData.userData
    getWebStoreOptionsByBusiness(business_id)
      // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then(jsonData => {
        setData(jsonData)
        console.log("DATAAAA", jsonData)
        setLoading(false);
        getItemImages(props.userLocalData.selectedBusiness)

      })
      .catch((error: any) => {
        if (error) {
          setLoading(false);
          getItemImages(props.userLocalData.selectedBusiness)
        }
      });
  }

  const initializeData = (data: any) => {
    console.log("Data", data)
    let paySettings = cloneDeep(paymentSettings)
    let deliverySett = cloneDeep(deliverySettings)
    let pickupSett = cloneDeep(pickupOptions)
    let posSett = cloneDeep(pointOfSales)
    let cashierSett = cloneDeep(cashiers)
    let cgSett = cloneDeep(customerGroups)
    let locSett = cloneDeep(storeLocations)
    setIncludedTag('')
    setExcludedTag('')

    if (data.length > 0) {
      data.forEach((a: any, index: any) => {
        let val = a.option_value && a.option_value.trim()
        let name = a.web_store_option.name && a.web_store_option.name.trim()
        // console.log("Value", val, name, name.length)
        switch (name) {
          case WebStoreOptions.ALLOW_PAY_AT_CHECKOUT: {
            paySettings[name] = val === "True" ? "true" : val === "False" ? "false" : null
            break
          }
          case WebStoreOptions.ALLOW_PAY_AT_PICKUP: {
            paySettings[name] = val === "True" ? "true" : val === "False" ? "false" : null
            break
          }
          case WebStoreOptions.ALLOW_PAY_AFTER_INVOICE: {
            paySettings[name] = val === "True" ? "true" : val === "False" ? "false" : null
            break
          }
          case WebStoreOptions.ALLOW_PICKUP_AT_STORE: {
            deliverySett[name] = val === "True" ? "true" : val === "False" ? "false" : null
            break
          }
          case WebStoreOptions.PROVIDE_DELIVERY_SERVICE: {
            deliverySett[name] = val === "True" ? "true" : val === "False" ? "false" : null
            break
          }
          case WebStoreOptions.CURBSIDE_PICKUP: {
            pickupSett[name] = val === "True" ? true : val === "False" ? false : null
            break
          }
          case WebStoreOptions.INSTORE_PICKUP: {
            pickupSett[name] = val === "True" ? true : val === "False" ? false : null
            break
          }
          case WebStoreOptions.DEFAULT_POS: {
            posSett = posSett.filter((i: any) => {
              if (i.uuid === val) {
                i['checked'] = true
                setSelectedPos(i.uuid)
              }
              return i

            })
            break
          }
          case WebStoreOptions.DEFAULT_CASHIER: {
            cashierSett = cashierSett.filter((i: any) => {
              if (i.uuid === val) {
                i['checked'] = true
                setSelectedCashier(i.uuid)
              }
              return i

            })
            break
          }
          case WebStoreOptions.DEFAULT_CUSTOMER_GROUP: {
            cgSett = cgSett.filter((i: any) => {
              if (i.uuid === val) {
                i['checked'] = true
                setSelectedCg(i.uuid)
              }
              return i

            })
            break
          }
          case WebStoreOptions.DISPLAY_PRICES: {
            setPriceOptions({ ...priceOptions, display_prices: true })
            setPriceSettings(val ? val : null)
            break
          }
          case WebStoreOptions.ZERO_PRICES: {
            setPriceOptions({ ...priceOptions, zero_price: true })
            setZeroPriceSettings(val ? val : null)
            break
          }
          case WebStoreOptions.DISPLAY_PRODUCTS_ONLY_WITH_THIS_TAG: {
            let resultant = []
            setTagSettings(val ? { ...tagSettings, display_products_only_with_this_tag: true } : { ...tagSettings })
            let obj = val.replace(/'/g, '"');
            let updatedObj = JSON.parse(obj)
            let keysArr = Object.values(updatedObj)
            for (const k of keysArr) {
              resultant.push(k)
            }
            let includTags = resultant.join(',')
            setIncludedTag(includTags)
            break
          }
          case WebStoreOptions.DO_NOT_DISPLAY_PRODUCTS_WITH_THIS_TAG: {
            let resultant = []
            setTagSettings(val ? { ...tagSettings, do_not_display_products_with_this_tag: true } : { ...tagSettings })

            let obj = val.replace(/'/g, '"');
            let updatedObj = JSON.parse(obj)
            let keysArr = Object.values(updatedObj)
            for (const k of keysArr) {
              resultant.push(k)
            }
            let excludTags = resultant.join(',')
            setExcludedTag(excludTags)
            break
          }
          case WebStoreOptions.DEACTIVATED_ITEMS: {
            setDeactivatedItemsSettings(val ? val : null)
            break
          }
          case WebStoreOptions.DEFAULT_VIEW: {
            setDefaultViewSettings(val ? val : null)
            break
          }
          case WebStoreOptions.LOCATIONS: {
            let obj = val.replace(/'/g, '"');
            let updatedObj = JSON.parse(obj)
            let keysArr = Object.values(updatedObj)
            let resultant: any

            for (const k of keysArr) {
              resultant = locSett.filter((i: any) => {
                if (i.korona_org_unit === k) {
                  i['checked'] = true
                }
                return i
              })
            }
            setStoreLocations(resultant)
            break
          }
          default: {
            console.log("default")
            break
          }
        }

      });
    }

    console.log("test")
    console.log("PAY-SETTINGS", paySettings)
    setPaymentSettings(paySettings)
    setDeliverySettings(deliverySett)
    setPickupOptions(pickupSett)
    setPointOfSales(posSett)
    setCashiers(cashierSett)
    setCustomerGroups(cgSett)
  };



  const onTabChange = (e: any, newValue: any) => {
    setTabValue(newValue)
  }

  const handlePaymentSettingsChange = (e: any, option: any) => {
    setPaymentSettings({ ...paymentSettings, [option]: e.target.value });
  }

  const handleDeliverySettingsChange = (e: any, option: any) => {
    setDeliverySettings({ ...deliverySettings, [option]: e.target.value });
  }


  const handlePickupOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPickupOptions({ ...pickupOptions, [event.target.name]: event.target.checked });
  };

  const handleStoreLocationChange = (event: React.ChangeEvent<HTMLInputElement>, obj: any) => {
    let locs = storeLocations.map((i: any) => JSON.parse(JSON.stringify(i)))
    locs.find(l => l.id === obj.id).checked = event.target.checked
    setStoreLocations(locs)
  };

  const handlePriceChange = (event: any, option: any) => {
    setPriceOptions({ ...priceOptions, [option]: event.target.checked })
  }

  const handleShowPriceSettingsChange = (e: any) => {
    setPriceSettings(e.target.value)
  }

  const handleZeroPriceSettingsChange = (e: any) => {
    setZeroPriceSettings(e.target.value)
  }

  const handleTagSettChange = (event: any, option: any) => {
    setTagSettings({ ...tagSettings, [option]: event.target.checked })
  }

  const handleDeactivatedItemsSettingsChange = (e: any) => {
    setDeactivatedItemsSettings(e.target.value)
  }
  const handleDefaultViewSettingsChange = (e: any) => {
    setDefaultViewSettings(e.target.value)
  }

  const handleStoreDefaultPosChange = (event: any) => {
    let posId = event.target.value
    setSelectedPos(posId)
    console.log("EVENT-------------------", posId)
    let pos = pointOfSales.map((i: any) => JSON.parse(JSON.stringify(i)))
    pos = pos.filter((p: any) => {
      if (p.uuid === posId) {
        p.checked = event.target.checked
      }
      else {
        p.checked = false
      }
      return p
    })
    setPointOfSales(pos)
  };

  const handleStoreDefaultCgChange = (event: any) => {
    let cgId = event.target.value
    setSelectedCg(cgId)
    let cgs = customerGroups.map((i: any) => JSON.parse(JSON.stringify(i)))
    // cgs.find(l => l.uuid === obj.uuid).checked = event.target.checked
    cgs = cgs.filter((cg: any) => {
      if (cg.uuid === cgId) {
        cg.checked = event.target.checked
      }
      else {
        cg.checked = false
      }
      return cg
    })
    setCustomerGroups(cgs)
  };

  const handleStoreDefaultCashierChange = (event: any) => {
    let cashierId = event.target.value
    setSelectedCashier(cashierId)
    let cashiers_grp = cashiers.map((i: any) => JSON.parse(JSON.stringify(i)))
    cashiers_grp = cashiers_grp.filter((cg: any) => {
      if (cg.uuid === cashierId) {
        cg.checked = event.target.checked
      }
      else {
        cg.checked = false
      }
      return cg
    })
    setCashiers(cashiers_grp)
  };

  const renderContent = () => {
    switch (tabValue) {
      case 'general':
        return (
          renderGeneralContent()
        )
      case 'payments':
        return (
          renderPaymentContent()
        )

      case 'delivery':
        return (
          renderDeliveryContent()
        )

    }
  }


  const showStoreAddressData = () => {
    return (
      <div className={classes.formControl}>
        <FormLabel component="legend">Locations enabled:</FormLabel>
        <FormGroup >
          {
            storeLocations && storeLocations.map((l: any, index: any) => {
              return (
                <FormControlLabel
                  key={index}
                  control={<Checkbox checked={l.checked} onChange={(e: any) => handleStoreLocationChange(e, l)} name={l.name} />}
                  label={l.name}
                />
              )
            })
          }
        </FormGroup>
      </div>
    )
  }


  const showPosData = () => {
    return (
      <div className={classes.formControl}>
        <FormLabel component="legend">Select a default POS for Web Store ({pointOfSales && pointOfSales.length}){RequiredSettings.DEFAULT_POINT_OF_SALE ? '*' : null}:</FormLabel>
        <RadioGroup value={selectedPos ? selectedPos : null} onChange={handleStoreDefaultPosChange}>
          {
            pointOfSales && sortBy(pointOfSales, 'name').map((l: any, index: any) => {
              return (
                <FormControlLabel key={index} value={l.uuid} control={<Radio />} label={l.name} />
              )
            })
          }
        </RadioGroup>
        {/* <FormGroup > */}
        {/* <FormGroup >
          {
            pointOfSales && pointOfSales.map((l: any, index: any) => {
              return (
                <FormControlLabel
                  key={index}
                  control={<Checkbox checked={l.checked} onChange={(e: any) => handleStoreDefaultPosChange(e, l)} name={l.name} />}
                  label={l.name}
                />)
            })
          }*/}
        {/* </FormGroup>  */}
      </div>
    )
  }


  const showCustomerGroupData = () => {
    return (
      <div className={classes.formControl}>
        <FormLabel component="legend">Select a default Customer Group for new customers ({customerGroups && customerGroups.length}){RequiredSettings.DEFAULT_CUSTOMER_GROUP ? '*' : null}:</FormLabel>
        <RadioGroup value={selectedCg ? selectedCg : null} onChange={handleStoreDefaultCgChange}>
          {
            customerGroups && sortBy(customerGroups, 'name').map((l: any, index: any) => {
              return (
                <FormControlLabel key={index} value={l.uuid} control={<Radio />} label={l.name} />
              )
            })
          }
        </RadioGroup>
        {/* <FormGroup >
          {
            customerGroups && customerGroups.map((l: any, index: any) => {
              return (
                <FormControlLabel
                  key={index}
                  control={<Checkbox checked={l.checked} onChange={(e: any) => handleStoreDefaultCgChange(e, l)} name={l.name} />}
                  label={l.name}
                />)
            })
          }
        </FormGroup> */}
      </div>
    )
  }

  const showCashierData = () => {
    return (
      <div className={classes.formControl}>
        <FormLabel component="legend">Select a default Cashier for Web Store ({cashiers && cashiers.length}){RequiredSettings.DEFAULT_CASHIER ? '*' : null}:</FormLabel>
        <RadioGroup value={selectedCashier ? selectedCashier : null} onChange={handleStoreDefaultCashierChange}>
          {
            cashiers && sortBy(cashiers, 'firstname').map((l: any, index: any) => {
              return (
                <FormControlLabel key={index} value={l.uuid} control={<Radio />} label={l.firstname + ` ` + l.surname} />
              )
            })
          }
        </RadioGroup>
        {/* <FormGroup >
          {
            cashiers && cashiers.map((l: any, index: any) => {
              return (
                <FormControlLabel
                  key={index}
                  control={<Checkbox checked={l.checked} onChange={(e: any) => handleStoreDefaultCashierChange(e, l)} name={l.name} />}
                  label={l.surname +` `+ l.firstname}
                />)
            })
          }
        </FormGroup> */}
      </div>
    )
  }

  const showPriceSettings = () => {
    return (
      <div className={classes.formControl}>
        <FormLabel component="legend">Prices:</FormLabel>
        <div style={{ marginLeft: 10, }}>
          <FormControlLabel
            style={{ flex: 1, marginTop: 8, fontWeight: 'bold' }}
            control={
              <Switch
                checked={priceOptions.display_prices}
                onChange={(e) => handlePriceChange(e, 'display_prices')}
                color="primary"
                style={{ paddingLeft: "10px" }}
              />
            }
            label={<span style={{ fontWeight: 'bold' }}>Display Prices</span>}
            labelPlacement="start"
          />
          {priceOptions.display_prices && renderDisplayPriceSettings()}
          <FormControlLabel
            style={{ flex: 1, marginTop: 8, fontWeight: 'bold' }}
            control={
              <Switch
                checked={priceOptions.zero_price}
                onChange={(e) => handlePriceChange(e, 'zero_price')}
                color="primary"
                style={{ paddingLeft: "10px" }}
              />
            }
            label={<span style={{ fontWeight: 'bold' }}>Zero Price</span>}
            labelPlacement="start"
          />
          {priceOptions.zero_price && renderZeroPriceSettings()}
        </div>

      </div>
    )
  }

  const renderDisplayPriceSettings = () => {
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={priceSettings} onChange={(e) => handleShowPriceSettingsChange(e)} className={classes.formContent} >
            <FormGroup>
              <FormControlLabel value={'display_prices_when_user_is_logged_in'} control={<Radio />} label="Display prices only when user is logged in" />
              <FormControlLabel value={'display_prices_when_user_is_not_logged_in'} control={<Radio />} label="Display prices when user is not logged in" />
            </FormGroup>
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  const renderZeroPriceSettings = () => {
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormLabel className={classes.formLabel} >All items that have 0(ZERO) price or no price will be </FormLabel>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={zeroPriceSettings} onChange={(e) => handleZeroPriceSettingsChange(e)} className={classes.formContent}>
            <FormGroup>
              <FormControlLabel value={'zero_price_hidden_from_listing'} control={<Radio />} label="not listed" />
              <FormControlLabel value={'zero_price_shown_with_alert'} control={<Radio />} label={`listed with alert showing "This item cannot be ordered"`} />
              <FormControlLabel value={'zero_price_is_not_hidden'} control={<Radio />} label="listed regardless of zero price" />
            </FormGroup>
          </RadioGroup>
        </FormControl>
      </div>
    )
  }


  const showTagsForListingFilter = () => {
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <FormLabel component="legend">Tags for listing filter:</FormLabel>
        <Typography variant="subtitle2">Note: If no options are selected, all the products will be displayed. If you need more than one tag, please use ,(comma) to separate tags.</Typography>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={tagSettings} className={classes.formContent}>
            <FormGroup style={{ width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <FormControlLabel style={{}} checked={tagSettings.display_products_only_with_this_tag} value={'display_products_only_with_this_tag'} onChange={(e) => handleTagSettChange(e, 'display_products_only_with_this_tag')} control={<Checkbox />} label="Only list products with this tag" />
                <TextField
                  className={classes.textFieldStyle}
                  margin="dense"
                  variant="outlined"
                  value={includedTag}
                  onChange={(event) => setIncludedTag(event.target.value)}
                  disabled={!tagSettings.display_products_only_with_this_tag}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FormControlLabel checked={tagSettings.do_not_display_products_with_this_tag} value={'do_not_display_products_with_this_tag'} onChange={(e) => handleTagSettChange(e, 'do_not_display_products_with_this_tag')} control={<Checkbox />} label={`Do not list products with this tag`} />
                <TextField
                  className={classes.textFieldStyle}
                  margin="dense"
                  variant="outlined"
                  value={excludedTag}
                  onChange={(event) => setExcludedTag(event.target.value)}
                  disabled={!tagSettings.do_not_display_products_with_this_tag}
                />
              </div>
            </FormGroup>
          </RadioGroup>
        </FormControl>
      </div>
    )
  }


  const showDeactivatedItemsSettings = () => {
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormLabel component="legend">Deactivated Items:</FormLabel>
        <Typography variant="subtitle2">Note: All items that are marked as DEACTIVATED will be</Typography>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={deactivatedItemsSettings} onChange={(e) => handleDeactivatedItemsSettingsChange(e)} className={classes.formContent}>
            <FormGroup>
              <FormControlLabel value={'deactivated_items_hidden_from_listing'} control={<Radio />} label="not listed" />
              <FormControlLabel value={'deactivated_items_shown_with_alert'} control={<Radio />} label={`listed with alert showing "This item cannot be ordered"`} />
              <FormControlLabel value={'deactivated_items_is_not_hidden'} control={<Radio />} label="listed regardless of deactivated status" />
            </FormGroup>
          </RadioGroup>
        </FormControl>
      </div>
    )
  }

  const showDefaultViewSettings = () => {
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormLabel component="legend">Default view:</FormLabel>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup value={defaultViewSettings} onChange={(e) => handleDefaultViewSettingsChange(e)} className={classes.formContent}>
            <FormGroup>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FormControlLabel value={'list_view'} control={<Radio />} label="List view" />
                <ListRoundedIcon style={{ marginLeft: 22 , marginTop: 8}} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FormControlLabel value={'grid_view'} control={<Radio />} label={`Grid View`} />
                <GridOnRoundedIcon style={{ marginLeft: 22 , marginTop: 8}} />
              </div>
            </FormGroup>
          </RadioGroup>
        </FormControl>
      </div>
    )
  }
  const onFileSelectChangeHandler = (e: any) => {
    if (!userUploadedMedia) {
      if (e.target) {
        if (e.target.files && e.target.files.length > 0) {
          console.log("InputFiles-", e.target.files)
          let files = e.target.files
          setUserUploadedMedia(files);
        }
      }
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number, option: any) => {

    // console.log("Option", option)

    // setSelectedActionIndex(index);
    setOptionsBtnAnchorEl(null);

    handleSelectedActionIndex(option)

  };


  const handleSelectedMedia = (data: any) => {
    setUserSelectedMedia(data)
  }

  const handleUploadClick = (e: any) => {
    // console.log(e)
    if (!userUploadedMedia) {
      const el = document.getElementById('import-media');
      el && el.click();

    }
  };

  const handleFileDrop = (files: any, event: any) => {
    event.preventDefault()
    console.log("Dnd-Files---", files)
    files && files.length > 0 ? setUserUploadedMedia(files) : null
  }
  const onTargetClick = () => {
    fileInputRef.current.click()
  }


  const renderGeneralContent = () => {
    return (

      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormLabel component="legend">Upload Logo:</FormLabel>
        <Typography variant="subtitle2">Note: The default one will be visible in Web Store</Typography>
        {
          isMediaLoading &&
          <div className={classes.rowCenter}>
            <CircularProgress />
          </div>
        }
        {!isMediaLoading &&
          <>

            <div className={classes.dnd}>
              <FileDrop
                onDrop={(files, event) => handleFileDrop(files, event)}
                onTargetClick={onTargetClick}
              >
                Drop files here to upload
              </FileDrop>
            </div>
            <Image
              itemData={props.userLocalData ? props.userLocalData.selectedBusiness : null}
              mediaUrls={mediaUrls ? mediaUrls.length > 0 ? mediaUrls : null : null}
              mediaData={mediaData ? mediaData.length > 0 ? mediaData : null : null}
              handleUploadClick={handleUploadClick}
              showDefaultMedia={showDefaultMedia ? showDefaultMedia : null}
              defaultMediaData={defaultMediaData ? defaultMediaData : null}
              userUploadedMedia={userUploadedMedia ? userUploadedMedia : null}
              handleSelectedMediaForAction={handleSelectedMediaForAction}
              handleSelectedMedia={handleSelectedMedia}
              userSelectedMedia={userSelectedMedia ? userSelectedMedia : null}
              onFileSelectChangeHandler={onFileSelectChangeHandler}
              selectedMediaForAction={selectedMediaForAction ? selectedMediaForAction : null}
              handleMenuItemClick={handleMenuItemClick}
              optionsBtnAnchorEl={optionsBtnAnchorEl}
              fileInputRef={fileInputRef}
            />
          </>
        }
        {/* {showStoreAddressData()} */}
        {showPosData()}
        {showCustomerGroupData()}
        {showCashierData()}
        {showPriceSettings()}
        {showTagsForListingFilter()}
        {showDeactivatedItemsSettings()}
        {showDefaultViewSettings()}
      </div>

    )
  }


  const renderPaymentContent = () => {
    const { allow_pay_at_checkout, allow_pay_at_pickup, allow_pay_after_invoice } = paymentSettings

    console.log("paymentSettings", paymentSettings)
    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={classes.formLabel}>Do you want to allow customers to pay at checkout online ?</FormLabel>
          <RadioGroup value={allow_pay_at_checkout} onChange={(e) => handlePaymentSettingsChange(e, 'allow_pay_at_checkout')} className={classes.formContent}>
            <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
            <FormControlLabel value={'false'} control={<Radio />} label="No" />
          </RadioGroup>
          <FormLabel component="legend" className={classes.formLabel} >Do you want to allow for payment at pickup ?</FormLabel>
          <RadioGroup value={allow_pay_at_pickup} onChange={(e) => handlePaymentSettingsChange(e, 'allow_pay_at_pickup')} className={classes.formContent} >
            <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
            <FormControlLabel value={'false'} control={<Radio />} label="No" />
          </RadioGroup>
          <FormLabel component="legend" className={classes.formLabel} >Do you want to allow payment on invoice ?</FormLabel>
          <RadioGroup value={allow_pay_after_invoice} onChange={(e) => handlePaymentSettingsChange(e, 'allow_pay_after_invoice')} className={classes.formContent} >
            <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
            <FormControlLabel value={'false'} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    )
  }



  const renderDeliveryContent = () => {
    const { inStorePickup, curbSidePickup } = pickupOptions
    const { allow_pickup_at_store, provide_delivery_service } = deliverySettings

    return (
      <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={classes.formLabel}>Do you have pickup at your store?</FormLabel>
          <RadioGroup value={allow_pickup_at_store} onChange={(e) => handleDeliverySettingsChange(e, 'allow_pickup_at_store')} className={classes.formContent}>
            <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
            <FormControlLabel value={'false'} control={<Radio />} label="No" />
          </RadioGroup>
          {allow_pickup_at_store === 'true' &&
            <div>
              <FormLabel component="legend">Do you have pickup at your store? ?</FormLabel>
              <FormGroup style={{ display: 'flex', flexDirection: 'row', }}>
                <FormControlLabel
                  control={<Checkbox checked={inStorePickup} onChange={handlePickupOptionsChange} name="inStorePickup" />}
                  label="Instore"
                />
                <FormControlLabel
                  control={<Checkbox checked={curbSidePickup} onChange={handlePickupOptionsChange} name="curbSidePickup" />}
                  label="Curbside"
                  style={{ paddingLeft: 35 }}
                />
              </FormGroup>
              <FormHelperText style={{ color: GlobalStyles.orange }}>Note:You can choose both also</FormHelperText>
            </div>
          }
          <FormLabel component="legend" className={classes.formLabel}>Do you provide delivery services ?</FormLabel>
          <RadioGroup value={provide_delivery_service} onChange={(e) => handleDeliverySettingsChange(e, 'provide_delivery_service')} className={classes.formContent}>
            <FormControlLabel value={'true'} control={<Radio />} label="Yes" />
            <FormControlLabel value={'false'} control={<Radio />} label="No" />
          </RadioGroup>

        </FormControl>
      </div>
    )
  }

  const getArrTags = (tag: any) => {
    let resultant: any = []
    resultant.push(tag)
    return resultant
  }

  const handleSave = () => {
    let warningSettings = ''
    let pos_found = pointOfSales.findIndex(i => i.uuid === selectedPos)
    let cashier_found = cashiers.findIndex(i => i.uuid === selectedCashier)
    let default_cg = customerGroups.findIndex(i => i.uuid === selectedCg)



    console.log("settingddddd", pos_found, cashier_found, default_cg)

    let settings = {
      default_pos: pos_found !== -1 ? pointOfSales[pos_found]['uuid'] : null,
      default_cashier: cashier_found !== -1 ? cashiers[cashier_found]['uuid'] : null,
      default_customer_group: default_cg !== -1 ? customerGroups[default_cg]['uuid'] : null
    }

    if(storeLocations && storeLocations.filter(i => i.checked).length > 0){
      settings[WebStoreOptions.LOCATIONS] = storeLocations.filter(i => i.checked)
    }

    if(paymentSettings.allow_pay_at_checkout){
      settings[WebStoreOptions.ALLOW_PAY_AT_CHECKOUT] = paymentSettings.allow_pay_at_checkout === "true" ? true : paymentSettings.allow_pay_at_checkout === "false" ? false : null
    }

    if(paymentSettings.allow_pay_at_pickup){
      settings[WebStoreOptions.ALLOW_PAY_AT_PICKUP] = paymentSettings.allow_pay_at_pickup === "true" ? true : paymentSettings.allow_pay_at_pickup === "false" ? false : null
    }

    if(paymentSettings.allow_pay_after_invoice){
      settings[WebStoreOptions.ALLOW_PAY_AFTER_INVOICE] = paymentSettings.allow_pay_after_invoice === "true" ? true : paymentSettings.allow_pay_after_invoice === "false" ? false : null
    }
    
    if(deliverySettings.allow_pickup_at_store){
      settings[WebStoreOptions.ALLOW_PICKUP_AT_STORE] = deliverySettings.allow_pickup_at_store === "true" ? true : deliverySettings.allow_pickup_at_store === "false" ? false : null
    }

    if(deliverySettings.provide_delivery_service){
      settings[WebStoreOptions.PROVIDE_DELIVERY_SERVICE] = deliverySettings.provide_delivery_service === "true" ? true : deliverySettings.provide_delivery_service === "false" ? false : null
    }

    if(pickupOptions.inStorePickup){
      settings[WebStoreOptions.INSTORE_PICKUP] = pickupOptions.inStorePickup === "true" ? true : pickupOptions.inStorePickup === "false" ? false : null
    }

    if(pickupOptions.curbSidePickup){
      settings[WebStoreOptions.CURBSIDE_PICKUP] = pickupOptions.curbSidePickup === "true" ? true : pickupOptions.curbSidePickup === "false" ? false : null
    }



    if (priceOptions.display_prices) {
      settings[WebStoreOptions.DISPLAY_PRICES] = priceSettings
    }
    if (priceOptions.zero_price) {
      settings[WebStoreOptions.ZERO_PRICES] = zeroPriceSettings
    }

    if (tagSettings.display_products_only_with_this_tag && includedTag != '') {
      settings[WebStoreOptions.DISPLAY_PRODUCTS_ONLY_WITH_THIS_TAG] = includedTag.includes(",") ? includedTag.split(',') : getArrTags(includedTag)
    }

    if (tagSettings.do_not_display_products_with_this_tag && excludedTag != '') {
      settings[WebStoreOptions.DO_NOT_DISPLAY_PRODUCTS_WITH_THIS_TAG] = excludedTag.includes(",") ? excludedTag.split(',') : getArrTags(excludedTag)

    }
    if(deactivatedItemsSettings != null){
      settings[WebStoreOptions.DEACTIVATED_ITEMS] = deactivatedItemsSettings
    }
    if(defaultViewSettings != null){
      settings[WebStoreOptions.DEFAULT_VIEW] = defaultViewSettings
    }

    console.log("payload", settings)

    const { business_id } = props.userLocalData.selectedBusiness;
    const { auth_token } = props.userLocalData.userData.userData
    const payload = { settings: settings, business_id, auth_token };

    if (RequiredSettings.DEFAULT_POINT_OF_SALE && pos_found === -1) {
      warningSettings = 'default_pos'
    }

    if (RequiredSettings.DEFAULT_CASHIER && cashier_found === -1) {
      warningSettings = warningSettings !== '' ? warningSettings + ',' + 'default_cahier' : 'default_cahsier'
      console.log("warningText-2", warningSettings)
    }

    if (RequiredSettings.DEFAULT_CUSTOMER_GROUP && default_cg === -1) {
      warningSettings = warningSettings != '' ? warningSettings + ',' + 'default_customer_group' : 'default_customer_group'
      console.log("warningText-3", warningSettings)
    }
    console.log("warningText", warningSettings)
    if (warningSettings !== '') {
      props.showToast({
        message: 'Please select the required settings for Web Store',
        options: ToastWarning
      });
    } else {
      setLoading(true);
      saveWebStoreSettings(payload)
        // handle network issues
        .then(checkStatus)
        .then(parseJSON)
        .then(jsonData => {
          setSettingsSavedSuccessfully(false);
          setLoading(false);
          props.showToast({
            message: 'Settings saved successfully!',
            options: ToastSuccess
          });
          fetchSettingsByBusiness()
        })
        .catch((error: any) => {
          if (error) {
            setSettingsSavedSuccessfully(false);
            setLoading(false);
            props.showToast({
              message: 'Failed to save settings!',
              options: ToastError
            });
          }
        });
    }

  }

  return (
    <div style={{ maxWidth: '100%' }}>
      {
        isLoading &&
        <div className={classes.rowCenter}>
          <CircularProgress />
        </div>
      }
      {!isLoading &&
        <>
          <PageToolbar
            title={`Settings`}
            history={props.history}
            titleColor={GlobalStyles.primaryColor}
            actions={
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {
                  <div style={{ alignSelf: 'center', marginTop: 8 }}>
                    <Button variant="contained" color={'primary'} onClick={handleSave}>Save changes</Button>
                  </div>
                }
              </div>
            }
          />
          <div style={{ width: '100%', marginTop: 25 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Paper className={classes.tab}>
                <Tabs
                  value={tabValue}
                  onChange={onTabChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  // variant="fullWidth"
                  centered
                >
                  <Tab label={`General`} value="general" />
                  {/* <Tab label={`Payments`} value="payments" />
                  <Tab label={`Delivery/Pickup`} value="delivery" /> */}
                </Tabs>
              </Paper>
            </div>
            <Card className={classes.card}>
              <CardContent>
                {renderContent()}
              </CardContent>
            </Card>
          </div>
        </>
      }
    </div>
  )

}


const mapStateToProps = (state: any) => {
  return {
    userLocalData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebStoreSettings);