import {
  SET_SELECTED_CUSTOMER_ORDER, SET_CUSTOMER_ORDER_SUMMARY_ITEMS, CLEAR_SELECTED_CUSTOMER_ORDER_DATA, SET_CUSTOMER_ORDERS
} from '../actions/actionTypes';
import cloneDeep from 'lodash/cloneDeep';


const initialState = {
  customerOrderList: null as any,
  selectedCustomerOrder: null as any,
  customerOrderSummaryItems: null as any
};

export function customerOrderListReducer(state = initialState, action: any) {
  let nextProducts = [];
  let oldList = [];
  let masterProductList = [];
  switch (action.type) {

    case SET_CUSTOMER_ORDERS:
      return {
        ...state,
        customerOrderList: action.data
      }
    

    case SET_SELECTED_CUSTOMER_ORDER:
      return {
        ...state,
        selectedCustomerOrder: action.data,

      };
    case SET_CUSTOMER_ORDER_SUMMARY_ITEMS:
      return {
        ...state,
        customerOrderSummaryItems: action.data
      }
    case CLEAR_SELECTED_CUSTOMER_ORDER_DATA: return cloneDeep(initialState);
    default:
      return state;
  }
}
