import React, { useEffect, useState, useRef } from 'react';
import MaterialTable from 'material-table';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import { formatDate, formatDate2 } from '../../../shared/lib/Localization';
import { getCustomerOrdersForStatus, getCustomerOrdersSummary } from '../../api';
import { connect } from 'react-redux';
import { Toast, ToastError, enqueueSnackbar } from '../../../shared/components/Notifier';
import { handleError } from '../../../shared/api/core';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',
        flex: 1
    },
    listContainer: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',
    }
    
  }),
);


const OrderSummaryScreen = (props: any) => {
    const classes = useStyles({});
    const [selectedBusinessId, setSelectedBusinessId] = useState(props?.userData?.selectedBusiness?.business_id);
    
    const [newOrdersCount, setNewOrdersCount] = useState(null);
    const [fulfillingOrdersCount, setFulfillingOrdersCount] = useState(null);
    const [fulfilledOrdersCount, setFulfilledOrdersCount] = useState(null);
    
    const [newOrders, setNewOrders] = useState([]);
    const [fulfillingOrders, setFulifillingOrders] = useState([]);
    const [fulfilledOrders, setFulfilledOrders] = useState([]);
    


    useEffect(() => {
        console.log("selectedBusinessId", selectedBusinessId)
        getCustomerOrdersSummary(selectedBusinessId)
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setNewOrdersCount(res.new_orders_count)
                    setFulfillingOrdersCount(res.fulfilling_orders_count)
                    setFulfilledOrdersCount(res.fulfilled_orders_count)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch order summary.',
                  options: ToastError
                });
              });
      }, [])
    useEffect(() => {
        getCustomerOrdersForStatus(selectedBusinessId, 'new')
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setNewOrders(res)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch new orders.',
                  options: ToastError
                });
              });
        getCustomerOrdersForStatus(selectedBusinessId, 'fulfilling')
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setFulifillingOrders(res)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch fulfilling orders.',
                  options: ToastError
                });
              });
        getCustomerOrdersForStatus(selectedBusinessId, 'fulfilled')
            .catch(handleError) // handle network issues
              .then(res => {
                if (res) {
                    setFulfilledOrders(res)
                }
              })
              .catch((error: any) => {
                // props.setPageLoader(false);
                props.showToast({
                  message: 'Failed to fetch fulfilled orders.',
                  options: ToastError
                });
              });
      }, [])
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div className={classes.headerContainer}>
                        <h2>New Orders</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{newOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                newOrders && newOrders.map(nw => {
                                    return (<ListItem>
                                        {/* <ListItemText primary={nw.order_number} /> */}
                                        <h5>{nw.order_number}-{nw.user.first_name} {nw.user.last_name}-{formatDate2(nw.created_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={classes.headerContainer}>
                        <h2>Fulfilling</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{fulfillingOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                fulfillingOrders && fulfillingOrders.map(nw => {
                                    return (<ListItem>
                                        <h5>{nw.order_number}-{nw.user.first_name} {nw.user.last_name}-{formatDate2(nw.created_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div className={classes.headerContainer}>
                        <h2>Fulfilled</h2>
                    </div>
                    <div className={classes.headerContainer}>
                        <h2>{fulfilledOrdersCount}</h2>
                    </div>
                    <div className={classes.listContainer}>
                        <List component="nav" aria-label="orders summary">
                            {
                                fulfilledOrders && fulfilledOrders.map(nw => {
                                    return (<ListItem>
                                        <h5>{nw.order_number}-{nw.user.first_name} {nw.user.last_name}-{formatDate2(nw.booking_time	)}</h5>
                                    </ListItem>)
                                })
                            }
                        </List>
                    </div>
                </Grid>
            </Grid>


        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            
          </div>
        </div>
      );
}



const mapStateToProps = (state: any) => {
    return {
      userData: state.userLocalData,
    };
  };
  
const mapDispatchToProps = (dispatch: any) => {
    return {
        showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderSummaryScreen);