import { EXPECTED_HEADERS  } from '../../models';
let reorder_code_in_headers = false;


export const getSRManifestItemsToMatch = (grid, mappedHeaders) => {
  let requestData = [];
  let index = 0;
  let reOrderCodeRetailer=false;
  for (let row = 0; row < grid.length; row++) {
    if (row === 0) {
      continue;
    }
    if (grid[row][0] && !(grid[row][0].isSelected)) {
      index += 1;
    }
    else if (grid[row][0] && grid[row][0].isSelected) {
      continue;
    }
    let data: any = {
      itemIndex: index,
      reOrderCode: '',
      supplierProductName: '',
      serialNumber: '',
      caseQuantity: '',
      master_supplier_container_qty: '',
      casesDelivered: '',
      costPerCase: '',
      unitsDelivered: '',
      costPerUnit: '',
      lineTotal: '',
      calculatedLineTotal: '',
      reOrderCodeOfRetailerToSend: reOrderCodeRetailer,
      product_edited_or_updated:false,
      headerContainsReOrderCode:reorder_code_in_headers,
      supplier_code:'',
      supplier_quantity:'',
      retailer_quantity:'',
      retailer_reorder_code:'',
      product_sku:'',
      product: {} as any
    };

    for (let col = 0; col < grid[row].length; col++) {
      if (grid[row][col].isSelected) {
        continue;
      } else {
        const columnHeader = mappedHeaders.get(col);
        // console.log('columnHeader', columnHeader);
        if (columnHeader) {
          switch (columnHeader) {
            case EXPECTED_HEADERS.RE_ORDER_CODE:
              data.reOrderCode = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.SUPPLIER_PRODUCT_NAME:
              data.supplierProductName = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.CASES_DELIVERED:
              const casesDelivered = String(grid[row][col].value).replace(',', '');
              data.casesDelivered = parseInt(casesDelivered);
              break;
            case EXPECTED_HEADERS.SERIAL_NUMBER:
              data.serialNumber = String(grid[row][col].value);
              break;
            case EXPECTED_HEADERS.COST_PER_CASE:
              const costPerCase = String(grid[row][col].value).replace(',', '');
              data.costPerCase = Number(costPerCase).toFixed(2);
              break;
            case EXPECTED_HEADERS.LINE_TOTAL:
              const lineTotal = String(grid[row][col].value).replace(',', '');
              data.lineTotal = Number(lineTotal).toFixed(2);
              break;
            case EXPECTED_HEADERS.CASE_QUANTITY:
              const caseQuantity = String(grid[row][col].value).replace(',', '');
              data.caseQuantity = parseInt(caseQuantity);
              break;
            case EXPECTED_HEADERS.COST_PER_UNIT:
              const costPerUnit = String(grid[row][col].value).replace(',', '');
              data.costPerUnit = Number(costPerUnit).toFixed(2);
              break;
            case EXPECTED_HEADERS.UNITS_DELIVERED:
              const unitsDelivered = String(grid[row][col].value).replace(',', '');
              data.unitsDelivered = parseInt(unitsDelivered);
              break;
            case EXPECTED_HEADERS.PRODUCT_SKU:
              data.product_sku = String(grid[row][col].value);
              break;
          }
        }
      }
    }
    requestData.push(data);
  }
  console.log("Get Invoice Item to match called")
  return requestData;
};




export const getSRProductsMatchedItemsSummary = (SRProducts, dispatchNotificationsSummary) => {
  console.log("getMatchedItemSummary called")
  const itemList = [...SRProducts];
  const dnSummary = { ...dispatchNotificationsSummary };
  dnSummary.itemCount = itemList.length;
  let expectedTotal = 0;
  let calculatedTotal = 0;
  let unMappedProductCount = 0;
  itemList.forEach(item => {
    let itemCost = item.costPerCase !== '' ? item.costPerCase : item.unitsDelivered !== '' ? item.unitsDelivered : null
    let qtyDelivered = item.casesDelivered !== '' ? item.casesDelivered : item.costPerUnit !== '' ? item.costPerUnit : null 
    expectedTotal = expectedTotal + Number(itemCost) * Number(qtyDelivered);
    console.log("ITEM-INDEX", item.itemIndex)
    console.log("Summaryitem",item)
    if (item.error && item.error_type!=='') {
      item.calculatedLineTotal = '0.00';
      unMappedProductCount += 1;

    } else {
      item.calculatedLineTotal = Number(itemCost) * Number(qtyDelivered);
      calculatedTotal = calculatedTotal + item.calculatedLineTotal;
      item.calculatedLineTotal = Number(item.calculatedLineTotal).toFixed(2);
    }
    if (!item.caseQuantity) {
      item.caseQuantity = '';
    }
    console.log("Fixedsummary Item", item)
  });

  dnSummary.expectedTotal = expectedTotal.toFixed(2);
  dnSummary.calculatedTotal = calculatedTotal.toFixed(2);
  dnSummary.unMappedProducts = unMappedProductCount;
  return {
    summary: dnSummary,
    items: itemList
  };
};

const getAction =(rowItem:any) =>{
  console.log('iTemmmm', rowItem)
  if (rowItem.warning) {
    if (rowItem.warning_type === 'new_supp_rec') {
      return `Add new supplier record for this item on Korona with ${rowItem.reOrderCode},${rowItem.master_supplier_container_qty}, ${rowItem.costPerUnit}.(Automatic, when Create Stock Receipt is clicked)`
    }
    else if(rowItem.warning_type === 'cost_diff'){
      return `There is a difference in price, imported price and CODE/PLU will be updated in Korona` 
    }
    else return `Supplier Record is missing on Retailer, New Record will be created on korona`
  }
  return `----`;
}

const getIssue =(rowItem:any)=>{
  if (rowItem.warning) {
    return `${rowItem.warning}`;
  }
  return `----`;
}


export const getSRRequestData = (selectedSupplier, selectedLocation, description, srProducts) => {
  const selectedLocationId = selectedLocation.id;
  const orgUnitId = selectedLocation.korona_org_unit;
  const requestData = {
    supplier: selectedSupplier.korona_supplier.uuid, // supplier id
    target: orgUnitId, // org unit id
    description: description,
    items: [],
    location: selectedLocationId
  };
  let isError = false;
  let items = [];
  srProducts = srProducts.filter(p => !p.error);
  for (let i = 0; i < srProducts.length; i++) {
    let currProd = srProducts[i]
    //console.log("SR",srProducts);
    const {
      itemIndex,
      supplierProductName,
      reOrderCode,
      product,
      unitsDelivered,
      casesDelivered,
      master_supplier_container_qty,
      costPerUnit,
      costPerCase,
      reOrderCodeOfRetailerToSend,
      product_sku,
      warning,
      warning_type,
      lineTotal,
      
      
    } = srProducts[i];

    const item = {
      itemIndex: Number(itemIndex),
      name: supplierProductName,
      supplierItemNumber: reOrderCode ? reOrderCode : null,
      actualGoods: Number(unitsDelivered), // this field is required = desiredOverallQuantity
      number: null,
      article: null,
      code: null,
      color: null,
      size: null,
      quantity: Number(unitsDelivered), // this field is required
      bookedQuantity: null,
      purchasePrice: null,
      salesPrice: null,
      desiredOverallQuantity: Number(unitsDelivered), // this field is required
      actualOverallQuantity: null,
      receivedQuantity: null,
      containerQuantity: Number(master_supplier_container_qty), // this field is required
      //containerQuantity:Number(product.suppliers[0].boxSize),
      uuid: null,
      itemPrice: null,
      deleted: false,
      assortment: null as any,
      commodityGroup: null as any,
      prices: null as any,
      sector: null as any,
      suppliers: null as any,
      product_sku : product_sku ? product_sku : null,
      warning_type: null,
      lineTotal: lineTotal,
      action : '',
      issue:''
    };
    if (costPerUnit !== '') {
      item.itemPrice = Number(costPerUnit);
    }
    if (product.name) {
      item.number = product.number;
      item.uuid = product.uuid;
    }
    if (product.assortment){
      item.assortment = product.assortment
    }
    if (product.commodityGroup){
      item.commodityGroup = product.commodityGroup
    }
    if(product.prices && product.prices.length > 0){
      item.prices = product.prices
    }
    if (product.sector){
      item.sector = product.sector
    }
    if(product.suppliers && product.suppliers.length > 0){
      item.suppliers = product.suppliers
    }
    if(reOrderCodeOfRetailerToSend===true){
      item.supplierItemNumber=product.suppliers[0].orderNumber
      item.containerQuantity=Number(product.suppliers[0].boxSize)
    }
    
    
    // if (stockOrderItemDatum.caseQuantity === '') {
    //     item.containerQuantity = Number(stockOrderItemDatum.master_supplier_container_qty);
    // }
    if (item.containerQuantity !== 0) {
      if (costPerCase !== '') {
        item.itemPrice = Number(costPerCase) / item.containerQuantity;
      }
      if (Number(casesDelivered) !== 0) {
        if (unitsDelivered === '') {
          item.desiredOverallQuantity = Number(casesDelivered) * item.containerQuantity;
          item.quantity = Number(casesDelivered) * item.containerQuantity;
          item.actualGoods = item.desiredOverallQuantity;
        }
      }
    }
    // item should be validated here
    // console.log(item);
    if(['new_supp_rec','cost_diff'].includes(warning_type) ){
      item.warning_type = warning_type
    }
    item.issue = getIssue(currProd)
    item.action = getAction(currProd)



    items.push(item);
  }
  requestData.items = items;
  return { payload: requestData, hasError: false };
};