import { 
  SET_STOCK_RECEIPT_ITEMS, ADD_NEW_SR_ITEM,UPDATE_STOCK_RECEIPT_ITEMS,
  DELETE_STOCK_RECEIPT_PRODUCT_IN_LIST,CLEAR_STOCK_RECEIPTS_ITEMS, SET_SR_VENDOR_DATA 
} from './actionTypes'

export const saveSRList = (data: any) => {
  return {
      type: SET_STOCK_RECEIPT_ITEMS,
      data
  };
};

export const addNewSRItem = (data: any) => {
  return {
      type: ADD_NEW_SR_ITEM,
      data
  };
};

export const updateSRProductInList = (data: any) => {
  return {
      type: UPDATE_STOCK_RECEIPT_ITEMS,
      data
  };
};

export const deleteSRProductInList = (data: any) => {
  return{
      type:DELETE_STOCK_RECEIPT_PRODUCT_IN_LIST,
      data
  }

}

export const clearSRData = () => {
  return {
      type: CLEAR_STOCK_RECEIPTS_ITEMS
  };
};

export const saveSRVendorData = (data: any) => {
  return {
      type: SET_SR_VENDOR_DATA,
      data
  };
};
