import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { PaginatedResponse } from '../../../shared/models';

import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { SVG_ICONS } from '../../../shared/icons/SvgIcons';
import { MasterDataRouterPaths } from '../../../page/Routes/RouterPaths';
import { ErrorStatusBar, CustomTableTitle, ExportMenu, ExportStatusBar, } from '../../../shared/components';
import { EMPTY_ROW_MSG, ERROR_500, ERROR_MSG } from '../../../shared/lib/Localization';
import { initQueryParams, replaceHistory, tableOptions, useQuery } from '../../../shared/lib/TableQueryParams';
import download from 'downloadjs';
import { getLiquorLiterTaxMismatch } from '../../api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      maxWidth: '16rem'
    },
    progress: {
      margin: theme.spacing(2),
    },
  }),
);


const LiquorLiterTaxMismatchReportScreen = (props: any) => {
  const classes = useStyles({});
  const tableRef = useRef<{ onQueryChange: any; }>();
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);



  useEffect(() => {
    if (!isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [props.userData.selectedBusiness]);


  const exportCSV = () => {
    if (props.userData && props.userData.selectedBusiness) {
      setExporting(true);
      const sb = props.userData.selectedBusiness;
      const shouldExport = true;
      getLiquorLiterTaxMismatch(
        sb.business_id,
        null,
        null,
        tableQuery.search,
        sb.orderBy,
        shouldExport
      )
        .catch(handleError) // handle network issues
        .then(checkStatus)
        .then((res) => res.blob())
        .then((data) => {
          download(data, 'LiquorLiterTaxMismatch.csv', 'text/csv');
          setExporting(false);
        })
        .catch((error: any) => {
          setExporting(false);
          if (error) {
            if (error.status && error.status === 500) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            } else if (error.status === 403 || error.status === 401) {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(true);
            } else {
              setEmptyDataSourceMessage(ERROR_MSG);
              setErrorStatusVisible(false);
            }
          }
        });
    }
  };

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportBtnAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = (shouldExport: boolean) => {
    if (shouldExport) {
      exportCSV();
    }
    setExportBtnAnchorEl(null);
  };


  return (
    <div>
      <ErrorStatusBar isVisible={isErrorStatusVisible} />
      <ExportStatusBar isVisible={isExporting} />

      <div style={{ maxWidth: '100%' }}>
        <div className="table-wrapper">
          <MaterialTable
            options={{
              ...tableOptions(tableQuery),
              thirdSortClick: false,
              draggable: false,
              sorting: true
            }}
            tableRef={tableRef}
            localization={{
              body: {
                emptyDataSourceMessage: emptyDataSourceMessage
              }
            }}
            columns={[
              { title: 'Kp Number', field: 'kp_number' },
              { title: 'Kp Name ', field: 'kp_name' },
              { title: 'Last Word', field: 'last_word' },
              { title: 'Liter tax Amount', field: 'liter_tax_amount' },
              { title: 'Num Part', field: 'num_part' },
              { title: 'After Num Part', field: 'after_num_part' },
              { title: 'Vol in ML', field: 'vol_in_ml' },
            ]}
            data={query =>
              new Promise((resolve, reject) => {
                if (!isLoading) {
                  // console.log('query', query);
                  const sb = props.userData.selectedBusiness;
                  if (sb && sb.business_id) {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as any[],
                      totalCount: 0,
                      page: query.page
                    };
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                    }
                    getLiquorLiterTaxMismatch(
                      sb.business_id,
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      orderBy,
                      false
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: PaginatedResponse<any>) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if ((error.status === 403 || error.status === 401)
                          ) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      });
                  }
                }
              })
            }
            title={
              <CustomTableTitle
                history={props.history}
                title={'Liquor Liter Tax Mismatch'}
                icon={SVG_ICONS.product}
              />
            }
            actions={[
              {
                icon: 'save_alt',
                tooltip: 'Export',
                isFreeAction: true,
                onClick: handleExportClick,
                disabled: isExporting,
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
              },
            ]}
          />
        </div>
      </div>
      <ExportMenu anchorEl={exportBtnAnchorEl} handleClose={handleExportMenuClose} />

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.userLocalData,
  };
};

export default connect(mapStateToProps, null)(LiquorLiterTaxMismatchReportScreen);
