export const WebStoreOptions = {
  ALLOW_PAY_AT_CHECKOUT: 'allow_pay_at_checkout',
  ALLOW_PAY_AT_PICKUP: 'allow_pay_at_pickup',
  ALLOW_PAY_AFTER_INVOICE: 'allow_pay_after_invoice',
  ALLOW_PICKUP_AT_STORE: 'allow_pickup_at_store',
  PROVIDE_DELIVERY_SERVICE: 'provide_delivery_service',
  CURBSIDE_PICKUP: 'curbsidePickup',
  INSTORE_PICKUP: 'inStorePickup',
  LOCATIONS:'locations',
  DEFAULT_POS :'default_pos',
  DEFAULT_CUSTOMER_GROUP : 'default_customer_group',
  DEFAULT_CASHIER:'default_cashier',
  DISPLAY_PRICES : 'display_prices',
  ZERO_PRICES:'zero_prices',
  DISPLAY_PRODUCTS_ONLY_WITH_THIS_TAG: 'display_products_only_with_this_tag',
  DO_NOT_DISPLAY_PRODUCTS_WITH_THIS_TAG: 'do_not_display_products_with_this_tag',
  DEACTIVATED_ITEMS:'deactivated_items',
  DEFAULT_VIEW :'default_view'
};