import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  dispatchNotificationsListUrl: BASE_URL + 'korona/dispatch-notifications/list/',
  dispatchNotificationsDetailUrl: BASE_URL + 'korona/dispatch-notification/detail/',
  dispatchNotificationMatchInvoiceProductListUrl : BASE_URL + 'korona/dispatch-notifications/match-invoice-products',
  createDispatchNotification: BASE_URL + 'korona/dispatch-notifications/create',
  parseUrlData : BASE_URL + 'korona/dispatch-notifications/parse-url-data',
  tempDNCustomerProductLinkUrl : BASE_URL + 'korona/dispatch-notifications/temp-dn-customer-prod-link',
  tempDNNewProductLinkUrl : BASE_URL + 'korona/dispatch-notifications/temp-dn-new-prod-link',
  changeRocTempRecUrl : BASE_URL + 'korona/dispatch-notifications/change-roc-temp-rec',
  getKoronaSOInfoUrl: BASE_URL + 'korona/dispatch-notifications/get-korona-so-info',
  getDnSOListUrl : BASE_URL + 'korona/dispatch-notifications/get-so-list-from-korona'

}


export const getDispatchNotificationsDetail = (id: number) => {
  let URL = API_URLS.dispatchNotificationsDetailUrl + id + '/';
  return composeRequest(URL, 'GET', null);
};

export const getDispatchNotificationsListByPage = (
  business_id: number | string,
  page: number, 
  pageSize: number,
  searchTerm: string, 
  orderBy: string | number,
  location_ids?: any,
) => {
  let URL = API_URLS.dispatchNotificationsListUrl +
    `?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;

  if (searchTerm) {
    URL = API_URLS.dispatchNotificationsListUrl +
      `?business_ids=${business_id}&search=${searchTerm}&page=${page}&page_size=${pageSize}`;
  }
  if(location_ids != null){
    `${URL}&locations_ids=${location_ids}`
  }
  if(orderBy){
    URL = `${URL}&orderBy=${orderBy}`;
  } 
  return composeRequest(URL, 'GET', null);
};


export const getDispatchNotificationsInvoiceProductList =(data: any)=>{
  const URL = API_URLS.dispatchNotificationMatchInvoiceProductListUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const addDN =(data:any)=>{
  const URL = API_URLS.createDispatchNotification;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const parseJsonUrlData =(data:any)=>{
  const URL = API_URLS.parseUrlData;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}


export const changeRocTempRec =(data:any)=>{
  const URL = API_URLS.changeRocTempRecUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}

export const getKoronaSOInfo = (data: any) => {
  const URL = API_URLS.getKoronaSOInfoUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getDNStockOrdersByPage =(data:any)=>{
  const URL = API_URLS.getDnSOListUrl;
  return composeRequest(URL, 'POST', JSON.stringify(data));
}

