import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Snackbar, FormControl, InputLabel, IconButton, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterProduct, MasterProductIdentifier } from '../../models';
import { Toast, enqueueSnackbar, ToastError } from '../../../shared/components/Notifier';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { API_URLS } from '../../api';
import composeRequest from '../../../shared/api/core';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      width: 200,
      height: 230,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    textField: {
      width: '100%'
    },
    rowCenter: {
      minHeight: 150,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    },
    codeGroupList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    addButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',

    },
  }),
);

//@ts-nocheck
const MasterCodeGroupModal = (props: any) => {
  const classes = useStyles({});
  const [codeGroupData, setCodeGroupData] = useState()
  const {
    itemData, onClose, isOpen, onSave, onUpdate, codeData, toast, handleCloseToast,
    handleStartCodeBlock, handleEndCodeBlock, blockError, codeGroupConflicts, isLoading,
    handlResetToast, newGroupCreated
  } = props

  useEffect(() => {
    console.log("CODE-DATA", codeData)
    codeData ? setCodeGroupData(codeData) : null
  }, [codeData])


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll={'paper'}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={toast.variant=== 'error'}
        onClick={handlResetToast}
      >
        <DialogTitle >
          {
            props.mode ? (props.mode === 'add' ?
              'Add Code Group' : 'Edit Code Group') : ''
          }
        </DialogTitle>
        <DialogContent dividers >
          <React.Fragment>
            <div className={classes.codeGroupList}>
              <TextField
                key="startfield"
                label="Start"
                margin="dense"
                variant="outlined"
                size="small"
                style={{ minWidth: "100px", marginRight: '5px' }}
                value={codeData ? codeData.code_block_start : ''}
                onChange={(e) => handleStartCodeBlock(e)}
                inputProps={{ style: { textAlign: 'center' } }}
              />
              <TextField
                key="endfield"
                label="End"
                margin="dense"
                variant="outlined"
                size="small"
                style={{ minWidth: "100px", marginRight: '5px' }}
                value={codeData ? codeData.code_block_end : ''}
                onChange={(e) => handleEndCodeBlock(e)}
                error={blockError}
                inputProps={{ style: { textAlign: 'center' } }}
              />
            </div>
            {
              blockError &&
              <div style={{ textAlign: 'center', marginTop: 10 }}>
                <Typography style={{ color: GlobalStyles.error }}>{`Start and End are not same in length or swapped`} </Typography>
              </div>
            }
            {
              isLoading &&
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            }
          </React.Fragment>
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={toast.open} autoHideDuration={4000} onClose={handleCloseToast} >
            <Alert onClose={handleCloseToast} severity={toast.variant}>
              {toast.message}
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
          <Button onClick={() => onClose()}>
            Cancel
          </Button>
          {
            props.mode ? props.mode === 'add' &&
              <Button
                onClick={() => onSave(codeData)}
                color="primary"
                disabled={blockError ? true : newGroupCreated ? true : false}
              >
                Add
              </Button>
              : null
          }
          {
            props.mode ? props.mode === 'edit' &&
              <Button
                onClick={() => onUpdate(codeData)}
                color="primary"
                disabled={blockError ? true : newGroupCreated ? true : false}
              >
                Update
              </Button>
              : null
          }
        </DialogActions>
      </Dialog>

    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast))
  };
};
export default connect(null, mapDispatchToProps)(MasterCodeGroupModal);
