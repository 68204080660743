export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILURE = 'GET_DEVICES_FAILURE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAILURE = 'DELETE_DEVICE_FAILURE';
export const ADD_DEVICE = 'ADD_DEVICE';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAILURE = 'ADD_DEVICE_FAILURE';


export const GET_DEVICE_AUTHORIZED_ACTIVE_COUNT = 'GET_DEVICE_AUTHORIZED_ACTIVE_COUNT';
export const GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_SUCCESS = 'GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_SUCCESS';
export const GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_FAILURE = 'GET_DEVICE_AUTHORIZED_ACTIVE_COUNT_FAILURE';

export const GET_DEVICE_SUMMARY = 'GET_DEVICE_SUMMARY';
export const GET_DEVICE_SUMMARY_FAILURE = 'GET_DEVICE_SUMMARY_FAILURE';
export const GET_DEVICE_SUMMARY_SUCCESS = 'GET_DEVICE_SUMMARY_SUCCESS';
