import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Checkbox } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable, { MaterialTableProps, MTableToolbar } from 'material-table';
import { Business, LocationBusiness } from '../../../shared/models';
import GlobalStyles from '../../../../styles/GlobalStyles.web';
import { MasterProduct, MasterProductLinkedRecord, MappingSuggestion, MasterProductIdentifier } from '../../models';
import { Product, ProductCode } from '../../../products/models';
import { PaginatedResponse } from '../../../shared/models';

import { API_URLS, getMasterProductsToLinkByPage, getUnMappedProductsByPage } from '../../api';
import { getProductsByPage, getProductsNotLinked } from '../../../products/api/Api';
import composeRequest from '../../../shared/api/core';
import { checkStatus, parseJSON, handleError } from '../../../shared/api/core/common';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '../../../shared/components';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

interface LinkProductProps {
  // onClose?: (canceled: boolean, success: boolean, errorData?: any) => any,
  // isOpen: boolean,
  mode: string;
  // onSave?: () => any,
  // onUpdate?: () => any,
  business: LocationBusiness;
  linkedRecord?: MasterProductLinkedRecord;
  suggestions?: MappingSuggestion[];
  handleBPSelectionChange: any;
  handleMPSelectionChange: any;
  selectedBusinessProduct: any;
  setSelectedBusinessProduct: any;


}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F3F5F7'
    },
    heading: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
    },
    panelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '12px 16px',
      alignItems: 'center'
    },
    panelCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    panelRowHeader: {
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '16px'
    },
    panelHeaderText: {
      fontSize: '0.95rem'
    },
    flexBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '4px 0'
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '4px 0'
    },
    textFieldWrapper: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 16
    },
    infoText: {
      fontSize: '14px'
    }
  }),
);


export default function LinkProduct(props: any) {
  const {
    mode,
    business,
    linkedRecord,
    suggestions,
    handleBPSelectionChange,
    handleMPSelectionChange,
    selectedBusinessProduct, setSelectedBusinessProduct,
    selectedMasterProduct, setSelectedMasterProduct,
    selectedNewMasterProduct, setNewSelectedMasterSupplier,
    addLinkRecord,
  } = props;
  // export default function LinkProduct(props) {
  console.log("LinkedProduct---Props",props);
  const classes = useStyles({});
  const masterProductTableRef = useRef<{ onQueryChange: any; }>();
  const unmappedProductTableRef = useRef<{ onQueryChange: any; }>();
  const [expandSuggestionPanel, setExpandSuggestionPanel] = useState(false);
  const theme = useTheme();
  const [productsFromSupp, setProductsFromSupp] = useState(true);
  const [unlinkedFilter, setUnlinkedFilter] = useState('all');

  useEffect(() => {
    if (props.linkedRecord) {
      setSelectedBusinessProduct({ ...props.linkedRecord.business_product });
      setSelectedMasterProduct({ ...props.linkedRecord.master_product });
    }
  }, [props.linkedRecord]);

  useEffect(() => {
    if (props.suggestions && props.suggestions.length > 0) {
      setExpandSuggestionPanel(true);
    }
  }, [props.suggestions]);


  useEffect(() => {

    if (props.tempLink && props.tempLink === true) {
      unmappedProductTableRef && unmappedProductTableRef.current && unmappedProductTableRef.current.onQueryChange()
    }
  }, [productsFromSupp])
  
  useEffect(() => {

    if (unlinkedFilter) {
      unmappedProductTableRef && unmappedProductTableRef.current && unmappedProductTableRef.current.onQueryChange()
    }
  }, [unlinkedFilter])

  const getMatchedIdentifier = (s: MappingSuggestion) => {
    const { master_product, business_products, match_type } = s;
    const p = business_products[0];
    switch (match_type) {
      case 'Identifier':
        if (master_product.identifiers && p.article_codes) {
          let match = '';
          const codes = p.article_codes.map(i => i.code);
          const identifiers = master_product.identifiers.filter(i => codes.includes(i.identifier));
          return identifiers[0].identifier;
        }
        break;
      case 'Reorder Code':
        if (p.suppliers && p.suppliers[0]) {
          return p.suppliers[0].orderNumber;
        }
    }

    return '';
  };

  const renderIdentifiers = () => {
    const { master_product } = props.linkedRecord;
    if (master_product && master_product.identifiers) {
      if (master_product.identifiers.length === 0) {
        return (
          <div className={classes.flexCenter}>
            <div className={classes.infoText}>No Product Codes</div>
          </div>);
      }
      return (
        <div>
          <div className={classes.flexCenter}>
            <div className={classes.infoText}>Product Codes</div>
          </div>
          <Divider />
          <div className={classes.flexBetween}>
            <div className={classes.infoText}>Code</div>
            <div className={classes.infoText}>Quantity</div>
          </div>
          <Divider />
          {
            master_product.identifiers.map((i: MasterProductIdentifier, index: number) =>
              <div key={`code-${index}`}>
                <div className={classes.flexBetween}>
                  <div className={classes.infoText}>{i.identifier}</div>
                  <div className={classes.infoText}>{i.quantity}</div>
                </div>
                <Divider />
              </div>
            )
          }
        </div>
      );
    }
    return null;
  };
  const renderCodes = (article_codes: ProductCode[]) => {
    if (article_codes) {
      if (article_codes.length === 0) {
        return (
          <div className={classes.flexCenter}>
            <div className={classes.infoText}>No Product Codes</div>
          </div>);
      }
      return (
        <div>
          <div className={classes.flexCenter}>
            <div className={classes.infoText}>Product Codes</div>
          </div>
          <Divider />
          <div className={classes.flexBetween}>
            <div className={classes.infoText}>Code</div>
            <div className={classes.infoText}>Quantity</div>
          </div>
          <Divider />
          {
            article_codes.map((i: ProductCode, index: number) =>
              <div key={`supcode-${index}`}>
                <div className={classes.flexBetween}>
                  <div className={classes.infoText}>{i.code}</div>
                  <div className={classes.infoText}>{i.quantity}</div>
                </div>
                <Divider />
              </div>
            )
          }
        </div>
      );
    }
    return null;
  };

  const renderProductSupplierInfo = (supplierInfo: any) => {
    if (supplierInfo) {
      if (supplierInfo.length === 0) {
        return (
          <div className={classes.flexCenter}>
            <div className={classes.infoText}>No Supplier Info</div>
          </div>);
      }
      return (
        <div>
          <div className={classes.flexCenter}>
            <div className={classes.infoText}>Supplier Info</div>
          </div>
          <Divider />
          <div className={classes.flexBetween}>
            <div className={classes.infoText} style={{ flex: 2 }}>Supplier</div>
            <div className={classes.infoText} style={{ flex: 1 }}>Reorder Code</div>
            <div className={classes.infoText} style={{ flex: 1, textAlign: 'right' }}>Case Qty</div>
          </div>
          <Divider />
          {
            supplierInfo.map((i: any, index: number) =>
              <div key={`supinfo-${index}`}>
                <div className={classes.flexBetween}>
                  <div className={classes.infoText} style={{ flex: 2 }}>{i.supplier ? i.supplier.name : ''}</div>
                  <div className={classes.infoText} style={{ flex: 1 }}>{i.orderNumber}</div>
                  <div className={classes.infoText} style={{ flex: 1, textAlign: 'right' }}>{i.boxSize}</div>
                </div>
                <Divider />
              </div>
            )
          }
        </div>
      );
    }
    return null;
  };

  const handleUnlinkedFilterOptionChange = (event: any) => {
    setUnlinkedFilter(event.target.value);
    
  }

  return (
    <div>
      {!props.tempLink &&
        props.suggestions &&
        props.suggestions.length > 0 &&
        <ExpansionPanel
          expanded={expandSuggestionPanel}
          onChange={() => setExpandSuggestionPanel(!expandSuggestionPanel)}
          style={{ marginBottom: 8 }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Mapping Suggestions</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.panelCol}>
              <div className={classes.panelRowHeader}>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1 }}>Match Type</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 2 }}>Master Product</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 1 }}>Value</Typography>
                <Typography className={classes.panelHeaderText}
                  style={{ flex: 2 }}>Business Products</Typography>
              </div>
              <div className={classes.panelCol}>
                {
                  props.suggestions.map((i: MappingSuggestion, index: number) =>
                    <div key={index}>
                      <Divider />
                      <div className={classes.panelRow}>
                        <Typography style={{ flex: 1 }}>{i.match_type}</Typography>

                        <Typography style={{ flex: 2 }}>{i.master_product ? i.master_product.name : 'None'}</Typography>
                        <Typography style={{ flex: 1 }}>{getMatchedIdentifier(i)}</Typography>
                        <div style={{ flex: 2 }}>
                          {
                            i.business_products.map((b: any, bIndex: any) => (
                              <div key={`b${bIndex}`} style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingBottom: i.business_products.length === bIndex + 1 ? 0 : 8
                              }}>
                                <Typography >{b.name}</Typography>
                                <Button style={{ textAlign: 'center', maxWidth: 72 }}
                                  variant="contained"
                                  onClick={() => addLinkRecord(i, b)}>
                                  Choose</Button>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      }
      {
        (props.mode === 'edit' || props.mode === 'link_supplier_product') &&
        <div className={classes.textFieldWrapper}>
          <TextField
            id="selected-business-product"
            label="Supplier Product"
            disabled
            value={selectedBusinessProduct ? selectedBusinessProduct.name : ''}
          />
        </div>
      }
      {/* {
        (props.mode === 'link_business_product') &&
        <div className={classes.textFieldWrapper}>
          <TextField
            id="selected-master-product"
            label="Master Product"
            disabled
            value={selectedMasterProduct ? selectedMasterProduct.name : ''}
          />
        </div>
      } */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>

        {
          props.mode === 'link_business_product' &&
          <div style={{
            flex: 1,
            maxWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            // border: '1px solid #cdcdcd',
            padding: 16,
            borderRadius: 4,
            maxHeight: 450,
            overflow: 'auto',
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
          }}>
            {!props.tempLink &&
              <div style={{ paddingBottom: 8 }}>
                <Typography variant="body1" color="primary"
                  style={{ paddingBottom: 4, fontSize: '0.875rem' }}>Master Product Info</Typography>
                <div className={classes.flexBetween}>
                  <div className={classes.infoText}>Name</div>
                  <div className={classes.infoText}>{selectedMasterProduct ? selectedMasterProduct.name : ''}</div>
                </div>
                <Divider />
                <div className={classes.flexBetween}>
                  <div className={classes.infoText}>Commodity Group</div>
                  <div className={classes.infoText}>---</div>
                </div>
                <Divider />
                {renderIdentifiers()}
              </div>
            }
            {
              props.tempLink &&
              <div>
                <Typography variant="body1" color="primary"
                  style={{ paddingBottom: 4, fontSize: '0.875rem' }}>Filters</Typography>
                <div className={classes.flexBetween}>
                  <div className={classes.infoText}>Show  products from {props.selectedSupplier && props.selectedSupplier.korona_supplier && props.selectedSupplier.korona_supplier.name} only</div>
                  <Checkbox
                    checked={productsFromSupp}
                    onChange={() => setProductsFromSupp(!productsFromSupp)}
                  />
                </div>
              </div>

            }
            <Divider />
            <div>

              <Typography variant="body1" color="primary"
                style={{ paddingBottom: 4, fontSize: '0.875rem' }}>Supplier Product Info</Typography>
              {
                props.tempLink ? props.invoiceItem &&
                  <div className={classes.flexBetween}>
                    <div className={classes.infoText}>Name</div>
                    <div style={{ marginLeft: 10}} className={classes.infoText}>
                      {props.invoiceItem && props.invoiceItem.supplierProductName ? props.invoiceItem.supplierProductName : '' }
                    </div>
                  </div> :
                  <div className={classes.flexBetween}>
                    <div className={classes.infoText}>Name</div>
                    <div style={{ marginLeft: 10}} className={classes.infoText}>
                      {props.supplierProduct ? props.supplierProduct.name : ''}
                    </div>
                  </div>
              }
              <Divider />
              {
                !props.tempLink &&
                <>
                  <div className={classes.flexBetween}>
                    <div className={classes.infoText}>Commodity Group</div>
                    <div className={classes.infoText}>{
                      props.supplierProduct ?
                        props.supplierProduct.commodityGroup ? props.supplierProduct.commodityGroup.name : ''
                        : ''
                    }</div>
                  </div>
                  <Divider />
                </>
              }
              {props.tempLink && props.invoiceItem &&
                <div className={classes.flexBetween}>
                  <div className={classes.infoText}>Reorder Code</div>
                  <div 
                    className={classes.infoText}
                  >
                    {props.invoiceItem && props.invoiceItem.reOrderCode ? props.invoiceItem.reOrderCode : '' }
                  </div>
                </div>
              }
              {props.tempLink && props.invoiceItem &&  
                props.invoiceItem.casesDelivered && String(props.invoiceItem.casesDelivered) !== '' &&  Number(props.invoiceItem.casesDelivered) !== 0 ? 
                <div className={classes.flexBetween}>
                  <div 
                    className={classes.infoText}
                  >
                    Cases Delivered
                  </div>
                  <div 
                    className={classes.infoText}
                  >
                    {props.invoiceItem.casesDelivered}
                  </div> 
                </div> : null
              }
              {console.log("Units Delivered", props.tempLink && props.invoiceItem && props.invoiceItem.unitsDelivered && String(props.invoiceItem.unitsDelivered) !== '' && Number(props.invoiceItem.unitsDelivered) !== 0 ? props.invoiceItem.unitsDelivered : null)}
              {props.tempLink && props.invoiceItem &&  
                props.invoiceItem.unitsDelivered && String(props.invoiceItem.unitsDelivered) !== '' && Number(props.invoiceItem.unitsDelivered) !== 0 ? 
                <div className={classes.flexBetween}>
                  <div 
                    className={classes.infoText}
                  >
                    Units Delivered
                  </div>
                  <div 
                    className={classes.infoText}
                  >
                    {props.invoiceItem.unitsDelivered}
                  </div> 
                </div> : null
              }
              {props.tempLink && props.invoiceItem &&  
                props.invoiceItem.caseQuantity &&  String(props.invoiceItem.caseQuantity) !== '' && Number(props.invoiceItem.caseQuantity) !== 0 ?
                <div className={classes.flexBetween}>
                  <div 
                    className={classes.infoText}
                  >
                    Case Quantity
                  </div>
                  <div 
                    className={classes.infoText}
                  >
                    {props.invoiceItem.caseQuantity}
                  </div>
                </div> : null
              }
              {props.tempLink && props.invoiceItem &&  
                props.invoiceItem.costPerCase &&  String(props.invoiceItem.costPerCase) !== '' && Number(props.invoiceItem.costPerCase) !== 0 ?
                <div className={classes.flexBetween}>
                  <div 
                    className={classes.infoText}
                  >
                    Cost Per Case
                  </div>
                  <div 
                    className={classes.infoText}
                  >
                    {props.invoiceItem.costPerCase}
                  </div>
                </div> : null
              }
              {props.tempLink && props.invoiceItem &&  
                props.invoiceItem.costPerUnit && String(props.invoiceItem.costPerUnit) !== '' && Number(props.invoiceItem.costPerUnit ) !== 0 ?
                <div className={classes.flexBetween}>
                  <div 
                    className={classes.infoText}
                  >
                    Cost Per Unit
                  </div>
                  <div 
                    className={classes.infoText}
                  >
                    {props.invoiceItem.costPerUnit}
                  </div>
                </div> : null
              }
              <Divider />
              {props.tempLink && props.invoiceItem &&
                <div className={classes.flexBetween}>
                  <div className={classes.infoText}>Total</div>
                  <div className={classes.infoText}>{
                    props.invoiceItem ?
                      props.invoiceItem.lineTotal ? props.invoiceItem.lineTotal : ''
                      : ''
                  }</div>
                </div>
              }
              <Divider />
              {
                props.supplierProduct &&
                props.supplierProduct.article_codes &&
                renderCodes(props.supplierProduct.article_codes)
              }
            </div>

          </div>
        }
        {
          (props.mode === 'add' || props.mode === 'link_business_product') &&
          <div style={{ flex: 1, maxWidth: props.mode === 'link_business_product' ? 400 : 440 }}>
            <MaterialTable
              options={{
                search: true,
                pageSize: 20,
                pageSizeOptions: [20],
                padding: 'dense',
                headerStyle: {
                  backgroundColor: '#eee',
                  paddingTop: 6,
                  paddingBottom: 6
                },
                showFirstLastPageButtons: false,
                debounceInterval: 650,
                maxBodyHeight: props.mode === 'link_business_product' ? 330 : 300,
                minBodyHeight: props.mode === 'link_business_product' ? 330 : 'inherit',
                showTitle: false,
                selection: true,
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                searchFieldAlignment: 'left',
                tableLayout: 'auto',
                selectionProps: (rowData: any) => {
                  const checked = rowData && rowData.tableData && rowData.tableData.checked;
                  return {
                    disabled: selectedBusinessProduct && !checked ? true : false,
                    color: 'primary'
                  };
                }
              }}
              columns={[
                {
                  title: props.mode === 'link_business_product' ? 'Number' : 'Number',
                  field: 'number', cellStyle: { width: '25%' }
                },
                {
                  title: props.mode === 'link_business_product' ? 'Customer Product' : 'Business Product',
                  field: 'name', cellStyle: { width: '75%' }
                },
              ]}
              data={query =>
                new Promise((resolve, reject) => {
                  setSelectedBusinessProduct(null);
                  if (props && props.business) {
                    let pageData = {
                      data: [] as Product[],
                      totalCount: 0,
                      page: query.page
                    };
                    console.log("props.tempLink", props.tempLink)
                    if (props.tempLink) {
                      getProductsNotLinked(query.page + 1, query.pageSize, query.search, props.business.business_id, productsFromSupp, props.selectedSupplier.korona_supplier.uuid)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then((data: PaginatedResponse<Product>) => {
                          pageData = {
                            data: data.results,
                            totalCount: data.count,
                            page: query.page
                          };
                          resolve(pageData);
                        })
                        .catch((error: any) => {
                          resolve(pageData);
                        });
                    } else {
                      getProductsNotLinked(query.page + 1, query.pageSize, query.search, props.business.business_id, false, null, null, unlinkedFilter)
                        .catch(handleError) // handle network issues
                        .then(checkStatus)
                        .then(parseJSON)
                        .then((data: PaginatedResponse<Product>) => {
                          pageData = {
                            data: data.results,
                            totalCount: data.count,
                            page: query.page
                          };
                          resolve(pageData);
                        })
                        .catch((error: any) => {
                          resolve(pageData);
                        });
                    }

                  }
                })
              }
              tableRef={unmappedProductTableRef}
              onSelectionChange={handleBPSelectionChange}
              components={{
                Toolbar: props => (
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <MTableToolbar {...props} />
                    <div style={{padding: '0px 10px'}}>
                    <Select
                      id="select-download-option"
                      value={unlinkedFilter}
                      onChange={handleUnlinkedFilterOptionChange}
                      // variant="outlined"
                      // className={classes.select}

                    >
                      <MenuItem value={"all"}>Show All</MenuItem>
                      <MenuItem value={"unlinked"}>Show Unlinked</MenuItem>
                      <MenuItem value={"priority"}>Show Priority</MenuItem>
                    </Select>
                    </div>
                  </div>
                ),
              }}
            />
          </div>
        }
        {
          props.mode === 'link_business_product' &&
          <div style={{
            flex: 1,
            maxWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            // border: '1px solid #cdcdcd',
            padding: 16,
            borderRadius: 4,
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
          }}>
            <div style={{ paddingBottom: 8 }}>
              <Typography variant="body1" color="primary"
                style={{ paddingBottom: 4, fontSize: '0.875rem' }}>Selected Customer Product Info</Typography>
              {
                !selectedBusinessProduct &&
                <div className={classes.infoText}>Please select a customer product.</div>
              }
              {
                selectedBusinessProduct &&
                <>
                  <div className={classes.flexBetween}>
                    <div className={classes.infoText}>Name</div>
                    <div className={classes.infoText}>{selectedBusinessProduct ? selectedBusinessProduct.name : ''}</div>
                  </div>
                  <Divider />
                  <div className={classes.flexBetween}>
                    <div className={classes.infoText}>Commodity Group</div>
                    <div className={classes.infoText}>{
                      selectedBusinessProduct ?
                        selectedBusinessProduct.commodityGroup ? selectedBusinessProduct.commodityGroup.name : ''
                        : ''}</div>
                  </div>
                  <Divider />
                  {
                    selectedBusinessProduct.article_codes &&
                    renderCodes(selectedBusinessProduct.article_codes)
                  }
                  {renderProductSupplierInfo(selectedBusinessProduct.suppliers)}
                </>
              }

            </div>
          </div>
        }

        {!(props.mode === 'link_business_product') &&
          <div style={{ flex: 1, maxWidth: 440 }}>
            <MaterialTable
              options={{
                pageSize: 20,
                pageSizeOptions: [20],
                padding: 'dense',
                headerStyle: {
                  backgroundColor: '#eee',
                  paddingTop: 6,
                  paddingBottom: 6
                },
                debounceInterval: 650,
                showFirstLastPageButtons: false,
                maxBodyHeight: 300,
                showTitle: false,
                selection: true,
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,
                searchFieldAlignment: 'left',
                selectionProps: (rowData: any) => {
                  const checked = rowData && rowData.tableData && rowData.tableData.checked;
                  const selectionProps = {
                    disabled: selectedMasterProduct && !checked ? true : false,
                    color: 'primary'
                  };
                  if (props.mode === 'edit') {
                    selectionProps.disabled = selectedNewMasterProduct && !checked ? true : false;
                  }
                  return selectionProps;
                }
              }}
              columns={[
                { title: 'ID', field: 'id', editable: 'never', cellStyle: { width: '25%' } },
                { title: 'Master Product', field: 'name', cellStyle: { width: '75%' } },
              ]}
              data={query => 
                new Promise((resolve, reject) => {
                  if (props.mode === 'add' ||
                    props.mode === 'link_supplier_product' ||
                    props.mode === 'link_business_product'
                  ) {
                    setSelectedMasterProduct(null);
                  } else if (props.mode === 'edit') {
                    setNewSelectedMasterSupplier(null);
                  }
                  getMasterProductsToLinkByPage(query.page + 1, query.pageSize, query.search)
                    //@ts-ignore
                    .then((res: { json: () => PromiseLike<PaginatedResponse<MasterProduct>>; }) => {
                      return res.json();
                    })
                    .then((data: PaginatedResponse<MasterProduct>) => {
                      const pageData = {
                        data: data.results,
                        totalCount: data.count,
                        page: query.page
                      };
                      resolve(pageData);
                    });
                })}
              tableRef={masterProductTableRef}
              onSelectionChange={handleMPSelectionChange}
            />
          </div>
        }
      </div>
      <div style={
        props.mode === 'edit' ? styles.selectColumn :
          props.mode === 'link_supplier_product' ? {
            display: 'flex',
            justifyContent: 'flex-start',
          }
            : styles.selectRow}>
        {
          props.mode === 'add' &&
          <TextField
            id="selected-business-product"
            label={"Business Product"}
            style={{ width: props.mode === 'add' ? 'inherit' : '100%' }}
            margin="normal"
            inputProps={{
              readOnly: true,
            }}
            value={selectedBusinessProduct ? selectedBusinessProduct.name : ''}
          />
        }
        {
          (props.mode !== 'edit') &&
          (props.mode !== 'link_business_product') &&
          <TextField
            id="selected-master-product"
            style={{ width: props.mode === 'add' ? 'inherit' : '100%' }}
            label="Master Product"
            margin="normal"
            value={selectedMasterProduct ? selectedMasterProduct.name : ''}
          />
        }
        {
          props.mode === 'edit' &&
          <TextField
            id="selected-master-product"
            style={{ width: '100%' }}
            disabled
            label="Current Master Product Product"
            margin="normal"
            value={selectedMasterProduct ? selectedMasterProduct.name : ''}
          />
        }
        {
          props.mode === 'edit' &&
          <TextField
            id="selected-new-master-product"
            style={{ width: '100%' }}
            label="New Master Product"
            margin="normal"
            value={selectedNewMasterProduct ? selectedNewMasterProduct.name : ''}
          />
        }
      </div>
    </div >
  );
}

const styles = {
  selectColumn: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    maxWidth: 300,
    width: '100%'
  },
  selectRow: {
    display: 'flex',
    justifyContent: 'space-around'
  }
};