import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, FormControl, InputLabel, FormGroup, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GlobalStyles from '../../../../styles/GlobalStyles.web';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { TreeNode } from '../../models';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Report, SelectedReport, ReportItem } from '../../models';

interface SelectTileModalProps {
    onCancel: () => void;
    isOpen: boolean;
    onConfirm: (selectedReports: SelectedReport[]) => void;
    // selectedOptions: string[];
    selectedReportItems: SelectedReport[]; // temporarily optional
    reports: Report[];
    toggleCards: (checked: boolean, parentId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        treeSelect: {
            height: '100%',
            flexGrow: 1,
            // maxWidth: 400,
        },
        childNode: {
            marginLeft: '18px !important'
        }
    }),
);

export const SelectTileModal = (props: SelectTileModalProps) => {
    // console.log('SelectTileModal', props);
    const classes = useStyles({});
    const [open, setOpen] = useState(props.isOpen);
    const [selectedReports, setSelectedReports] = useState(props.selectedReportItems as SelectedReport[]);
    const [parentChildMap, setParentChildMap] = useState(new Map());

    useEffect(() => {
        const map = new Map();
        props.selectedReportItems.forEach(t => {
            map.set(t.id, t.items);
        });
        setSelectedReports(props.selectedReportItems);
        setParentChildMap(map);
    }, [props.selectedReportItems]);

    const parentCheckboxClicked = (event: any, checked: boolean, parentId: string, children: { id: string; }[]) => {
        event.stopPropagation();
        const map = new Map(parentChildMap);
        if (checked) {
            const childIds = children.map(c => c.id);
            map.set(parentId, childIds);
        } else {
            if (map.has(parentId)) {
                map.delete(parentId);
            }
        }
        setParentChildMap(map);
    };

    const childCheckboxClicked = (event: any, checked: boolean, parentId: string, childId: string) => {
        event.stopPropagation();
        const map = new Map(parentChildMap);
        if (checked) {
            if (map.has(parentId)) {
                const currentChildIds = parentChildMap.get(parentId);
                currentChildIds.push(childId);
                map.set(parentId, currentChildIds);
            } else {
                map.set(parentId, [childId]);
            }
        } else {
            if (map.has(parentId)) {
                const currentChildIds = parentChildMap.get(parentId).filter((id: string) => id !== childId);
                map.set(parentId, currentChildIds);
            }
        }
        setParentChildMap(map);
    };

    const isCheckboxIndeterminate = (parentId: string, children: { id: string; }[]) => {
        if (children && children.length > 0 && parentChildMap.has(parentId)) {
            const childrenIds = children.map(c => c.id);
            const selectedChildrenIds = parentChildMap.get(parentId);
            return selectedChildrenIds.length === 0 ? false
                : selectedChildrenIds.length !== childrenIds.length;
        }
        return false;
    };

    const hasSelectedChildren = (parentId: string) => {
        if (parentChildMap.has(parentId)) {
            return parentChildMap.get(parentId).length > 0;
        }
        return false;
    };

    const handleSwitchChange = (event: any, checked: boolean, parentId: string) => {
        event.stopPropagation();
        props.toggleCards(checked, parentId);
    };

    const getCurrentSelectedReports = () => {
        const reports = props.reports.filter(item => Array.from(parentChildMap.keys()).includes(item.id));
        const selected: SelectedReport[] = reports.map(r => {
            return {
                id: r.id,
                isEnabled: r.isEnabled,
                items: parentChildMap.get(r.id)
            };
        });
        return selected;
    };

    const renderParent = (parent: Report) => {
        const label = (
            <FormGroup row style={{ justifyContent: 'space-between' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={`checkbox-${parent.id}`}
                            checked={
                                parentChildMap.has(parent.id) &&
                                parentChildMap.get(parent.id).length > 0}
                            onChange={
                                (event, checked) =>
                                    parentCheckboxClicked(event, checked, parent.id, parent.items)}
                            onClick={e => e.stopPropagation()}
                            color="primary"
                            indeterminate={isCheckboxIndeterminate(parent.id, parent.items)}
                            disabled={!parent.isEnabled}
                        />
                    }
                    label={parent.title}
                    onClick={e => e.stopPropagation()}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                parent.isEnabled &&
                                hasSelectedChildren(parent.id)}
                            onChange={(event, checked) => handleSwitchChange(event, checked, parent.id)}
                            onClick={e => e.stopPropagation()}
                            value={parent}
                            color="secondary"
                        />
                    }
                    label=""
                />
            </FormGroup>
        );
        return (<TreeItem key={parent.id} nodeId={parent.id} label={label}>
            {
                Array.isArray(parent.items) ?
                    parent.items.map((item: ReportItem) =>
                        renderChild(item, parent.id, parent.isEnabled))
                    : null
            }
        </TreeItem>);
    };

    const renderChild = (child: TreeNode, parentId: string, isParentEnabled: boolean) => {
        const label = (
            <FormControlLabel
                control={
                    <Checkbox
                        id={`checkbox-${child.id}`}
                        checked={
                            parentChildMap.has(parentId) &&
                            parentChildMap.get(parentId).includes(child.id)}
                        onChange={
                            (event, checked) =>
                                childCheckboxClicked(event, checked, parentId, child.id)}
                        onClick={e => (e.stopPropagation())}
                        color="primary"
                        disabled={!isParentEnabled}
                    />
                }
                label={child.title}
                className={classes.childNode}
            />
        );
        return (
            <TreeItem
                key={child.id}
                nodeId={child.id}
                label={label}>
            </TreeItem>
        );
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={props.isOpen ? props.isOpen : false}
                onClose={() => props.onCancel()}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" disableTypography>
                    <Typography variant="h5">Summary Tiles To Display On Dashboard</Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ paddingLeft: 8 }}>Summary Value For Sub Items</Typography>
                        <Typography style={{ paddingLeft: 0 }}>Hide/Show</Typography>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <TreeView
                        className={classes.treeSelect}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpanded={['root']}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {props.reports.map(r => renderParent(r))}
                    </TreeView>
                </DialogContent>
                <DialogActions style={{ paddingLeft: 24, paddingRight: 24 }}>
                    <Button onClick={() => props.onCancel()}>
                        Cancel
                    </Button>
                    <Button color="primary"
                        onClick={() => props.onConfirm(getCurrentSelectedReports())}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};