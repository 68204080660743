import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      // padding: theme.spacing(1, 1),
      borderRadius: 0,
      paddingTop: 12,
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 16
    },
    fileInputWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingTop: 12,
      paddingBottom: 12,
      alignSelf: 'flex-end'
    },
    button: {
      margin: theme.spacing(1, 0, 2, 2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '15rem'
    },
    errorText: {
      color: '#f44336'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    textLabel: {
      paddingLeft: 16,
      marginTop: 6,
      marginBottom: 6
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    instructions: {
      paddingBottom: 12
    },
    sectionTitle: {
      paddingTop: 16,
      paddingBottom: 16
    },
    actionRow: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%',
      minHeight: '56px'
    },
    actionRowLabel: {
      alignSelf: 'flex-start'
    },
    actionRowBtn: {
      alignSelf: 'flex-end'
    },
    actionCol: {
      margin: '4px 8px',
      maxWidth: '408px',
      width: '100%'
    }
  }),
);