import { API_CONFIG } from "../../shared/api/config";
import composeRequest from '../../shared/api/core';

const BASE_URL = API_CONFIG.API_BASE_PATH;

const parseJSON = (response: any) => {
  if (response.status === 200) {
    return response.json();
  }
  
}

export const API_URLS = {
  getCustomerOrderSummaryUrl: BASE_URL + 'customer_order/orders/summary/',
  getCustomerOrdersForStatusUrl: BASE_URL + 'customer_order/orders/for_status/',
};



export const getCustomerOrdersSummary = (
  business_id: number | string
) => {
  let URL = API_URLS.getCustomerOrderSummaryUrl +
    `?business_id=${business_id}`;

  return composeRequest(URL, 'GET', null).then(parseJSON);
};


export const getCustomerOrdersForStatus = (
  business_id: number | string,
  status: string
) => {
  let URL = API_URLS.getCustomerOrdersForStatusUrl +
    `?business_id=${business_id}&status=${status}`;

  return composeRequest(URL, 'GET', null).then(parseJSON);
};

