import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AsyncSelect from '../AsyncSelect';
import { Filter, FilterType } from '../../../shared/models/Filter';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup'

const useFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      paddingTop: 4.5,
      paddingBottom: 4.5,
    },
    filterContainer: {
      minHeight: 200,
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
    },
    header: {
      padding: '8px 16px',
      backgroundColor: '#eee',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
    },
    selectOutline: {
      padding: '10.5px 14px',
    },
    textField: {
      width: '100%',
    },
  })
);

export const AvailableFilters = (props) => {
  console.log("UPDATEDD_FILTERS_MASTER", props)
  const { filters, addFilter } = props;
  return filters.map((f, i) => {
    return (
      <React.Fragment key={`filter-label-${f.id}`}>
        <ListItem button onClick={() => addFilter(f)}>
          <ListItemText primary={f.label} />
          <ListItemSecondaryAction>
            <AddIcon />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  });
};

export const SelectedFilters = (props) => {
  const classes = useFilterStyles({});
  const { selectedFilters, removeFilter, updateFilter, businessId } = props;
  const [filterTextInputMap, setFilterTextInputMap] = useState({});

  useEffect(() => {
    const textFilters = selectedFilters.filter((f) => !f.url && !Array.isArray(f.value));
    if (textFilters.length > 0) {
      const map = { ...filterTextInputMap };
      textFilters.forEach((f) => {
        // console.log(f.selectedType)
        if (f.selectedType.includes('range') && !f.choices && !Array.isArray(f.choices)) {
          map[f.id] = {
            minValue: f.minValue,
            maxValue: f.maxValue
          }

        }
        else if (!f.selectedType.includes('range') && f.choices && Array.isArray(f.choices)) {
          map[f.id] = f.value ? f.value : '';
        }
        else if (!f.selectedType.includes('range') && !f.choices && !Array.isArray(f.choices) && f.checkboxes && Array.isArray(f.checkboxes) ) {
          map[f.id] = f.value ? f.value : '';
        }
        else {
          map[f.id] = f.value ? f.value : '';
          // console.log(map)
        }
      });
      // console.log("map",map)
      setFilterTextInputMap(map);
    }
  }, [selectedFilters]);

  const handleFilterTypeChange = (e, f: Filter, index: number) => {
    const filter = { ...f };
    filter.selectedType = e.target.value;
    if ((!f.url && !Array.isArray(f.value) && !f.selectedType.includes('range')) || (f.choices && Array.isArray(f.choices)) || (f.checkboxes && Array.isArray(f.checkboxes))) {
      filter.value = filterTextInputMap[f.id];
    }
    updateFilter(filter, index);
  };

  const handleValueChange = (values: string | any[], f: Filter, index: number) => {
    const filter = { ...f };
    filter.value = values;
    updateFilter(filter, index);
  };

  const handleTextChange = (e, f: Filter) => {
    const val = e.target ? (e.target.value ? e.target.value : '') : '';
    const map = { ...filterTextInputMap };
    map[f.id] = val;
    setFilterTextInputMap(map);
  };

  const handleRangeInputChange = (e, f: Filter, rangeType) => {
    const val = e.target ? (e.target.value ? e.target.value : '') : '';
    const map = { ...filterTextInputMap };
    map[f.id][rangeType] = val;
    setFilterTextInputMap(map);
  };

  const handleChoiceChange = (e, f: Filter, i) => {
    const val = e.target ? e.target.value : ''
    const map = { ...filterTextInputMap };
    map[f.id] = val;
    console.log("MAP", map)
    setFilterTextInputMap(map);
    handleValueChange(val, f, i);
  }

  const handleCheckBoxChange = (e, f: Filter, obj) => {

    f.checkboxes.find(l => l.id === obj.id).checked = e.target.checked
    const map = { ...filterTextInputMap };
    console.log("map-checkbox", map)
    const val = f.checkboxes.find(l => l.id === obj.id).value
    map[f.id] = val;
    console.log("MAP", map)
    setFilterTextInputMap(map);
    handleValueChange(val, f, obj);
  }


  const onKeyDown = (e, f, i) => {
    if (e.keyCode === 13) {
      const val = filterTextInputMap[f.id] ? filterTextInputMap[f.id].trim() : '';
      handleValueChange(val, f, i);
    }
  };

  const onRangeKeyDown = (e, f, i, rangeType) => {
    if (e.keyCode === 13) {
      const val = filterTextInputMap[f.id][rangeType] ? filterTextInputMap[f.id][rangeType] : '';
      const filter = { ...f };
      filter[rangeType] = val;
      // console.log("inRangeKeydown", filterTextInputMap)
      if (rangeType === 'minValue') {
        filterTextInputMap[f.id]['maxValue'].length > 0 ? filter['maxValue'] = filterTextInputMap[f.id]['maxValue'].trim() : ''
      }
      else if (rangeType === 'maxValue') {
        filterTextInputMap[f.id]['minValue'].length > 0 ? filter['minValue'] = filterTextInputMap[f.id]['minValue'].trim() : ''
      }
      updateFilter(filter, i);
    }
  };

  const renderFilterOptions = (options: FilterType[]) => {
    return options.map((o, i) => {
      return (
        <MenuItem key={`opt-${i}`} value={o.value}>
          {o.label}
        </MenuItem>
      );
    });
  };

  const renderFilter = (f: Filter, i: number) => {
    let choices = []
    let checkboxes = []
    if (f.choices && f.choices.length > 0) {
      for (let i = 0; i < f.choices.length; i++) {
        choices.push(<FormControlLabel key={`choice-${i}`} value={f.choices[i].value} control={<Radio />} label={f.choices[i].label} />);
      }
    }
    return (
      <React.Fragment key={`selected-filter-${i}`}>
        <div className={classes.row}>
          <div style={{ width: '25%', paddingLeft: 16, paddingRight: 16 }}>
            <Typography variant="body1">{f.label}</Typography>
          </div>
          {!(!f.url && !Array.isArray(f.value) && !f.selectedType.includes('range') && Array.isArray(f.choices) && Array.isArray(f.checkboxes)) && (
            <div style={{ width: '20%' }}>
              <Select
                id={`select-${f.id}`}
                value={f.selectedType}
                onChange={(e) => handleFilterTypeChange(e, f, i)}
                variant="outlined"
                style={{ width: '100%' }}
                classes={{ outlined: classes.selectOutline }}
              >
                {renderFilterOptions(f.types)}
              </Select>
            </div>
          )}
          <div style={{ width: '55%', paddingLeft: 8, paddingRight: 8 }}>
            {f.url && Array.isArray(f.value) ?
               f.data ? (
              <AsyncSelect
                url={f.url}
                businessId={businessId}
                labelField={f.labelField}
                onSelectChange={(values) => handleValueChange(values, f, i)}
                selected={f.value}
                data={f.data}
                bindValue={f.bindValue}
                isResponsePaginated={f.isResponsePaginated}
                hasSelect={f.types.find(ele => ele.value == f.selectedType) && f.types.find(ele => ele.value == f.selectedType).hasSelect}
                showSuppliers={f.types.find(ele => {
                  if (ele.value == f.selectedType) {
                    if (ele.showSuppliers) {
                      return f
                    }
                  }
                }) ? true : false}

              />
            ) : 
            (
              <AsyncSelect
                url={f.url}
                businessId={businessId}
                labelField={f.labelField}
                onSelectChange={(values) => handleValueChange(values, f, i)}
                selected={f.value}
                bindValue={f.bindValue}
                isResponsePaginated={f.isResponsePaginated}
                hasSelect={f.types.find(ele => ele.value == f.selectedType) && f.types.find(ele => ele.value == f.selectedType).hasSelect}
                showSuppliers={f.types.find(ele => {
                  if (ele.value == f.selectedType) {
                    if (ele.showSuppliers) {
                      return f
                    }
                  }
                }) ? true : false}

              />
            ) : null
            }

            {!f.url && !Array.isArray(f.value) && !f.selectedType.includes('range') && !Array.isArray(f.choices) && !Array.isArray(f.checkboxes) && (
              <TextField
                id={`outlined-field-${f.id}`}
                margin="none"
                variant="outlined"
                className={classes.textField}
                onKeyDown={(e) => onKeyDown(e, f, i)}
                onChange={(e) => handleTextChange(e, f)}
                size="small"
                value={filterTextInputMap[f.id] ? filterTextInputMap[f.id] : ''}
              />
            )}
            {!f.url && !Array.isArray(f.value) && f.selectedType.includes('range') && !Array.isArray(f.choices) && !Array.isArray(f.checkboxes) && (
              <>
                <TextField
                  id="mp_quantity_min"
                  size="small"
                  label="Min"
                  type="number"
                  key="mp_quantity_min"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: '5.6rem', marginLeft: '50px' }}
                  variant="outlined"
                  value={filterTextInputMap[f.id] && filterTextInputMap[f.id].minValue ? filterTextInputMap[f.id].minValue : ''}
                  onKeyDown={(e) => onRangeKeyDown(e, f, i, 'minValue')}
                  onChange={(e) => handleRangeInputChange(e, f, 'minValue')}
                />

                <TextField
                  id="mp_quantity_max"
                  size="small"
                  label="Max"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  key="mp_quantity_max"
                  style={{ width: '5.6rem', marginLeft: '30px' }}
                  variant="outlined"
                  value={filterTextInputMap[f.id] && filterTextInputMap[f.id].maxValue ? filterTextInputMap[f.id].maxValue : ''}
                  onKeyDown={(e) => onRangeKeyDown(e, f, i, 'maxValue')}
                  onChange={(e) => handleRangeInputChange(e, f, 'maxValue')}
                />
              </>
            )}
            {!f.url && !Array.isArray(f.value) && !f.selectedType.includes('range') && !Array.isArray(f.checkboxes) && Array.isArray(f.choices ) && (
              <>
                <FormControl component="fieldset">
                  <RadioGroup
                    id={`choice-${f.id}`}
                    key="choices_filter"
                    value={filterTextInputMap[f.id] ? filterTextInputMap[f.id] : ''}
                    onChange={(e) => handleChoiceChange(e, f, i)}
                  >
                    {choices.map((c) => c)}
                  </RadioGroup>
                </FormControl>
              </>
            )}
            {!f.url && !Array.isArray(f.value) && !f.selectedType.includes('range') && !Array.isArray(f.choices) && Array.isArray(f.checkboxes) && (
              <>
                <FormControl component="fieldset">
                <FormGroup >
          {
            f.checkboxes && f.checkboxes.map((l: any, index: any) => {
              return (
                <FormControlLabel
                  key={index}
                  control={<Checkbox checked={l.checked} onChange={(e: any) => handleCheckBoxChange(e, f, l)} name={l.label} />}
                  label={l.label}
                />
              )
            })
          }
        </FormGroup>
                </FormControl>
              </>
            )}
          </div>
          <div style={{ paddingRight: 8 }}>
            <IconButton
              style={{ padding: 8 }}
              aria-label="delete"
              onClick={() => removeFilter(i)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        <Divider />
      </React.Fragment>
    );
  };

  return (
    <>
      {selectedFilters && selectedFilters.length === 0 && (
        <div className={classes.row}>
          <div style={{ width: '100%', padding: 16 }}>
            <Typography variant="body1" align="center">
              No Active Filters
            </Typography>
          </div>
        </div>
      )}
      {selectedFilters &&
        selectedFilters.length > 0 &&
        selectedFilters.map((f: Filter, i) => renderFilter(f, i))}
    </>
  );
};

export const FilterRow = (props) => {
  // console.log(props);
  const classes = useFilterStyles({});
  const { renderAvailableFilters, renderSelectedFilters } = props;
  return (
    <div
      style={{
        padding: 16,
        width: '100%',
        borderTop: '1px solid rgba(0,0,0,0.12)',
        borderBottom: '1px solid rgba(0,0,0,0.12)',
        display: 'flex',
      }}
    >
      <div style={{ width: '30%' }}>
        <div className={classes.filterContainer} style={{ marginRight: 8 }}>
          <div>
            <div className={classes.header}>
              <Typography variant="body1">Available Filters</Typography>
            </div>
            <List disablePadding style={{ overflow: 'auto', maxHeight: 192, }}>
              {renderAvailableFilters ? renderAvailableFilters : null}
            </List>
          </div>
        </div>
      </div>
      <div style={{ width: '70%' }}>
        <div className={classes.filterContainer}>
          <div>
            <div className={classes.header}>
              <Typography variant="body1">Active Filters</Typography>
            </div>
            <List disablePadding style={{ overflow: 'auto', maxHeight: 192, }}>
              {renderSelectedFilters ? renderSelectedFilters : null}
            </List>
          </div>
        </div>
      </div>
    </div>
  );
};