import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MaterialTable, { MaterialTableProps } from 'material-table';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BusinessQueueTasks, BusinessQueueTypes } from '../models';
import { Button, Paper, Typography, CircularProgress, Divider, SvgIcon, Input, IconButton, Tooltip } from '@material-ui/core';
import { getBusinessQueues, updateQueue, scheduleQueues, runQueues, updateQueueCron } from '../api';
import { checkStatus, parseJSON, handleError } from '../../shared/api/core/common';
import { getBusinessListFromAPI } from '../../shared/data/actions';
import { PaginatedResponse, Business } from '../../shared/models';
import { CustomTableTitle, ErrorStatusBar } from '../../shared/components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import cronstrue from 'cronstrue';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { SVG_ICONS } from '../../shared/icons/SvgIcons';
import {
  EMPTY_ROW_MSG,
  ERROR_MSG,
  ERROR_500,
  NO_PERMISSIONS_ERROR_MSG,
} from '../../shared/lib/Localization';
import {
  isSuperAdmin,
  hasAddPermission,
  hasUpdatePermission,
  hasDeletePermission,
  hasUpdateBusinessQueuePermission
} from '../../shared/lib/PermissionManager';
import {
  initQueryParams,
  replaceHistory,
  tableOptions,
  useQuery,
} from '../../shared/lib/TableQueryParams';
import {
  Toast,
  enqueueSnackbar,
  ToastSuccess,
  ToastError,
} from '../../shared/components/Notifier';
import GlobalStyles from '../../../styles/GlobalStyles';
import ClearIcon from '@material-ui/icons/Clear';

// const QRCode = require('qrcode.react');
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: '#0a0a0a',
      marginRight: 4,
      '&:before': {
        borderColor: '#0a0a0a',
      },
      '&:after': {
        borderColor: '#0a0a0a',
      },
      maxWidth: '12rem',
      minWidth: '9rem',
    },
    divider: {
      margin: theme.spacing(0.5, 0),
    },
  }),
);
const permission_model = 'device';

const BusinessQueueScreen = (props: any) => {
  const classes = useStyles({});
  const [emptyDataSourceMessage, setEmptyDataSourceMessage] = useState(EMPTY_ROW_MSG);
  const [isErrorStatusVisible, setErrorStatusVisible] = useState(false);
  const tableRef = useRef<{ onQueryChange: any, onAllSelected:any}>();
  let locationQuery = useQuery(useLocation);
  const [tableQuery, setTableQuery] = useState(initQueryParams(locationQuery));
  const [tableEditableProp, setTableEditableProp] = useState(false);
  const [isExporting, setExporting] = useState(false);
  const [exportBtnAnchorEl, setExportBtnAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [businessQueueData, setBusinessQueueData] = useState([]);
  const [businessQueueUpdated, setBusinessQueuesUpdated] = useState([]);
  const [selectedQueueForCron, setSelectedQueueForCron] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [confirmTimePickerModal, setConfirmTimePickerModal] = useState(false);
  const [confirmSyncModal, setConfirmSyncModal] = useState(false);
  const [selectedCronValue, setSelectedCronValue] = useState('');
  const [selectedCronDescription, setSelectedCronDescription] = useState('');
  const [selectedCronString, setSelectedCronString] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date() || '');
  const [utcTime, setUtcTime] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [rowEdited,setRowEdited]=useState(false);
  const [clearTaskSelection, setClearTaskSelection ] = useState(false)
  const [clearBusinessSelection, setClearBusinessSelection] = useState(false)

  useEffect(()=>{
    console.log("tableRef", tableRef)
  },[tableRef])

  useEffect(() => {
    const { userData } = props.userData.userData;
    const editable: any = {};
    console.log("USERDATA", userData)
    if (userData) {

      if (hasUpdateBusinessQueuePermission(userData.is_superuser)
      ) {
        setTableEditableProp(true);
      }

    }
  }, [props.userData]);

  useEffect(() => {
    if (isSuperAdmin(props)) {
      props.getBusinessList();
    }
  }, []);

  useEffect(() => {
    if (isSuperAdmin(props) && !isLoading) {
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, [selectedBusiness, selectedTask,rowEdited]);

  useEffect(() => {
    if (utcTime) {
      let time = utcTime.split(":");
      let cronString = `${time[1]} ${time[0]} * * *`;
      setSelectedCronString(cronString)
    }
  }, [utcTime]);

  const SelectBusinessEditComponent = (componentProps: any) => {
    const { value, onChange } = componentProps.colDataProps;
    return (
      <Select
        value={value ? value : ''}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        inputProps={{
          name: 'business',
          id: 'select-business',
        }}
      >
        {props.businessList &&
          props.businessList.data &&
          props.businessList.data.map((b: Business) => (
            <MenuItem value={b.id} key={String(b.id)}>
              {b.name}
            </MenuItem>
          ))}
      </Select>
    );
  };

  const getUpdateFn = (newData: any) => {
    console.log("newData", newData)
    let updated = {}
    let validated = { ...newData };
    if (validated.mail_recipient_list) {
      validated.mail_recipient_list = validated.mail_recipient_list.toString().split(/\s+/).join("") === "" ? null : validated.mail_recipient_list.toString()
    }
    if (validated.mail_cc_recipient_list) {
      validated.mail_cc_recipient_list = validated.mail_cc_recipient_list.toString().split(/\s+/).join("") === "" ? null : validated.mail_cc_recipient_list.toString()

    }
    if (validated.mail_bcc_recipient_list) {
      validated.mail_bcc_recipient_list = validated.mail_bcc_recipient_list.toString().split(/\s+/).join("") === "" ? null : validated.mail_bcc_recipient_list.toString()

    }
    updated = validated

    console.log("before api-call", updated)
    setRowEdited(true)
    updateQueue(updated)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        props.showToast({
          message: 'Task updated successfully!',
          options: ToastSuccess,
        });
        setRowEdited(false);
      })
      .catch((error: any) => {
        let msg = 'Failed to update Task.';
        setRowEdited(false);
        if (error && error.status) {

          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg, options: ToastError });
      });

  };


  const handleSyncCancel = () => {
    setConfirmSyncModal(false)
  }


  const handleSyncAgree = (newData: any) => {
    setLoading(true);
    console.log("NEW-DATA", newData)
    let ids = [];
    for (let i = 0; i < newData.length; i++) {
      ids.push(newData[i].id)
    }
    runQueues(newData)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        setLoading(false);
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        props.showToast({
          message: 'Task(s) added to the queue, should run shortly. ',
          options: ToastSuccess,
        });
        setConfirmSyncModal(false);
        for (let i = 0; i < selectedQueueForCron.length; i++) {
          selectedQueueForCron[i].tableData.checked = false
        }
        setSelectedQueueForCron([])
        for (let i = 0; i < selectedQueues.length; i++) {
          selectedQueues[i].tableData.checked = false
        }
        setSelectedQueues([])
        if (tableRef.current) {
          tableRef.current.onAllSelected(false)
        }
      })
      .catch((error: any) => {
        setLoading(false);
        let msg = 'Failed to add Task(s).';
        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg, options: ToastError });
        setConfirmSyncModal(false);
        for (let i = 0; i < selectedQueueForCron.length; i++) {
          selectedQueueForCron[i].tableData.checked = false
        }
        setSelectedQueueForCron([])
        for (let i = 0; i < selectedQueues.length; i++) {
          selectedQueues[i].tableData.checked = false
        }
        setSelectedQueues([])
        if (tableRef.current) {
          tableRef.current.onAllSelected(false)
        }
      });
  };


  const handleTimePickerCancel = () => {
    setConfirmTimePickerModal(false)
  }


  const handleTimePickerAgree = (data: any) => {
    setLoading(true);
    let updatedArray = []
    if (data) {
      console.log("inside-if", data)
      let validatedRecords = data.map(i => i);
      for (let i = 0; i < validatedRecords.length; i++) {
        // const exists = validatedRecords[i].cron_string ? true : false;
        // if(exists){
        validatedRecords[i].cron_string = selectedCronString
        console.log("CRON-STRING", validatedRecords[i].cron_string)
        // }
        if (validatedRecords[i].mail_recipient_list) {
          validatedRecords[i].mail_recipient_list = validatedRecords[i].mail_recipient_list.toString() === "" ? null : validatedRecords[i].mail_recipient_list.toString()
        }
        if (validatedRecords[i].mail_cc_recipient_list) {
          validatedRecords[i].mail_cc_recipient_list = validatedRecords[i].mail_cc_recipient_list.toString() === "" ? null : validatedRecords[i].mail_cc_recipient_list.toString()

        }
        if (validatedRecords[i].mail_bcc_recipient_list) {
          validatedRecords[i].mail_bcc_recipient_list = validatedRecords[i].mail_bcc_recipient_list.toString() === "" ? null : validatedRecords[i].mail_bcc_recipient_list.toString()

        }
      }
      updatedArray = validatedRecords
    }
    console.log("UPDATED-ARRAY", updatedArray)
    updateQueueCron(updatedArray)
      .catch(handleError) // handle network issues
      .then(checkStatus)
      .then(parseJSON)
      .then((jsonData) => {
        setLoading(false);
        setErrorStatusVisible(false);
        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
        props.showToast({
          message: 'Queue updated successfully!',
          options: ToastSuccess,
        });
        setConfirmTimePickerModal(false)
        for (let i = 0; i < selectedQueueForCron.length; i++) {
          selectedQueueForCron[i].tableData.checked = false
        }
        setSelectedQueueForCron([])
        for (let i = 0; i < selectedQueues.length; i++) {
          selectedQueues[i].tableData.checked = false
        }
        setSelectedQueues([])
        setSelectedTime("")
        setSelectedCronValue("")

      })
      .catch((error: any) => {
        setLoading(false);
        let msg = 'Failed to update Queue.';
        if (error && error.status) {
          if (error.status === 403 || error.status === 401) {
            msg = NO_PERMISSIONS_ERROR_MSG;
          } else if (error.status === 500) {
            msg = ERROR_500;
          }
        }
        props.showToast({ message: msg, options: ToastError });
        setConfirmTimePickerModal(false)
        for (let i = 0; i < selectedQueueForCron.length; i++) {
          selectedQueueForCron[i].tableData.checked = false
        }
        setSelectedQueueForCron([])
        for (let i = 0; i < selectedQueues.length; i++) {
          selectedQueues[i].tableData.checked = false
        }
        setSelectedQueues([])
        setSelectedTime("")
        setSelectedCronValue("")
      });
  }



  const handleDateChange = (date: any | null) => {
    let utcTime = Date()
    if (date != null) {
      // date.toTimeString()
      utcTime = date.toUTCString().slice(17, 22)

      console.log("DATE", date.toUTCString())
      console.log("DATE", date.toUTCString().slice(17, 22))
      // utcTime = date.toISOString().slice(11, 14)
      // console.log("DATE", date.toISOString())
      // console.log("DATE", date.toISOString().slice(11,14))
      setSelectedDate(date);
      // console.log("MOMENT-UTC", moment.utc(date))
      setUtcTime(utcTime);
    }
  };


  const handleCronTimeChange = (event: React.ChangeEvent<{ value: "10" | "20" | "40" | "24" | "0" }>) => {
    // setSelectedTime(event.target.value)
    if (event.target.value === "10") {
      setSelectedTime(event.target.value)
      setSelectedCronString("*/10 8-6 * * *")

    }
    else if (event.target.value === "20") {
      setSelectedCronString("*/20 8-6 * * *")
      setSelectedTime(event.target.value)
    }
    else if (event.target.value === "40") {
      setSelectedCronString("*/40 * * * *")
      setSelectedTime(event.target.value)
    }
    else if (event.target.value === "24") {
      setSelectedCronValue("24")
      setSelectedTime(event.target.value)
    }
    else if (event.target.value === "0") {
      setSelectedCronString(null)
      setSelectedTime(event.target.value)
    }
  }

  const renderTable = () => {
    return (
      <div>
        <ErrorStatusBar isVisible={isErrorStatusVisible} />
        <div style={{ maxWidth: '100%' }}>
          <div className="table-wrapper">
            <MaterialTable
              options={{
                ...tableOptions(tableQuery),
                thirdSortClick: false,
                draggable: false,
                sorting: true,
                selection: true,
                selectionProps: {
                  color: 'secondary'
                },
                showTextRowsSelected: false,
              }}

              tableRef={tableRef}
              localization={{
                body: {
                  emptyDataSourceMessage: emptyDataSourceMessage
                }
              }}
              isLoading={tableLoading}
              columns={[
                { title: 'ID', field: 'id', editable: 'never' },
                {
                  title: 'Task type',
                  field: 'queue',
                  render: (rowData: any) => {

                    if(rowData.queue !== null){
                      let queueIndex = BusinessQueueTypes.findIndex(b=>b.type ===  rowData.queue);
                      if(queueIndex >=0){
                        return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {BusinessQueueTypes[queueIndex].value}  </div>)
                      } else return ''
                    }
                    else return ''

                    // if (rowData.queue === 'korona_db_sync') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Korona DB Sync`}  </div>)
                    // }
                    // if (rowData.queue === 'custom_reports') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Custom Reports`}  </div>)
                    // }
                    // if (rowData.queue === 'rms_db_sync') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`RMS DB Sync`}  </div>)
                    // }
                    // if (rowData.queue === 'korona_load_older_receipts') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Korona Load Older Receipts`}  </div>)
                    // }
                    // if (rowData.queue === 'receipt_index_catch_up') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Receipt Index Catch Up`}  </div>)
                    // }
                    // if (rowData.queue === 'es_sync_sales_report') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`ES Sales Data Summary`}  </div>)
                    // }
                    // if (rowData.queue === 'ftp_task') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`FTP Task`}  </div>)
                    // }
                    // if (rowData.queue === 'ftp_task') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`FTP Task`}  </div>)
                    // }
                    // if (rowData.queue === 'homesome_sales_report') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Homesome Sales Report`}  </div>)
                    // }
                    // if (rowData.queue === 'korona_receipts_only_sync') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Korona Receipts Only Sync`}  </div>)
                    // }
                    // if (rowData.queue === 'stock_order_customer_order') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Stock Order Customer Order`}  </div>)
                    // }
                    // if (rowData.queue === 'customer_order_stock_receipt') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Customer Order Stock Receipt`}  </div>)
                    // }
                    // if (rowData.queue === 'notification_events') {
                    //   return (<div style={{ minWidth: rowData.queue != null ? "250px" : null }}>  {`Notification Events`}  </div>)
                      
                    // }
                  },
                  editable: 'never'
                },
                {
                  title: 'Name',
                  field: 'name',
                  render: (rowData: any) => {

                    if (rowData.name != null) {
                      return (<div style={{ minWidth: rowData.name != null ? "250px" : null }}>  {rowData.name}  </div>)
                    }
                    return ' ';
                  },
                },
                {
                  title: 'Business',
                  field: 'business',
                  hidden: !isSuperAdmin(props),
                  editComponent: (props) => {
                    return <SelectBusinessEditComponent colDataProps={props} />;
                  },
                  render: (rowData: any) => {
                    if (props.businessList && props.businessList.data) {
                      const filteredBusiness = props.businessList.data.filter(
                        (b: Business) => b.id === rowData.business
                      )[0];
                      if (filteredBusiness) {
                        // return <div>{filteredBusiness.name}</div>;
                        return (<div style={{ minWidth: filteredBusiness.name != null ? "250px" : null }}>  {filteredBusiness.name}  </div>)
                      }
                    }
                    return null;
                  },
                  sorting: false
                },
                {
                  title: 'Enabled',
                  field: 'should_proccess',
                  type: 'boolean',
                  render: (rowData: any) => (
                    <Switch
                      checked={rowData.should_proccess}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  ),
                },
                {
                  title: 'Schedule',
                  field: 'cron_string',
                  editable: 'never',
                  render: (rowData: any) => {

                    if (rowData.cron_string != null) {
                      return (<div style={{ minWidth: rowData.cron_string != null ? "250px" : null }}>  {cronstrue.toString(rowData.cron_string).replace("At", "at")}  </div>)
                    }
                    return ' ';
                  },
                },
                // { title: 'Periodic Task', field: 'periodic_task', editable:'never' },

                // { 
                //     title: 'Data Info', 
                //     field: 'data_info',
                //     render: (rowData: any) => { 
                //         const {max_idle_threshold} = rowData.data_info
                //         if(max_idle_threshold){
                //             return (`Max-Idle-Threshold:${max_idle_threshold}`);
                //         }
                //         return null
                //     }
                // },

                {
                  title: 'Mail TO',
                  field: 'mail_recipient_list',
                  editComponent: (props) => {
                    const { value, onChange } = props
                    // let updatedValue= "";
                    return (
                      <Input
                        {...props}
                        // value={Object.keys(value).length != 0 && value != null ? '':value}
                        value={value ? value : ''}
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                        style={{ minWidth: value != null ? "250px" : "310px" }}
                        placeholder={value === null || "" ? "Separate multiple addresses by comma(s)." : null}
                      />
                    )
                  },
                  render: (rowData: any) => {
                    const { mail_recipient_list } = rowData
                    if (mail_recipient_list != null) {
                      return (<div style={{ minWidth: mail_recipient_list != null ? "250px" : null }}>  {mail_recipient_list.toString()}  </div>)
                    } return null;
                  },

                },
                {
                  title: 'Mail CC',
                  field: 'mail_cc_recipient_list',
                  editComponent: (props) => {
                    const { value, onChange } = props
                    let updatedValue = "";
                    return (
                      <Input
                        {...props}
                        // value={Object.keys(value).length != 0 && value != null ? value:''}
                        value={value ? value : ''}
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                        style={{ minWidth: value != null ? "250px" : "310px" }}
                        placeholder={value === null || "" ? "Separate multiple addresses by comma(s)." : null}
                      />
                    )
                  },
                  render: (rowData: any) => {
                    const { mail_cc_recipient_list } = rowData
                    if (mail_cc_recipient_list != null) {
                      return (<div style={{ minWidth: mail_cc_recipient_list != null ? "250px" : null }}>  {mail_cc_recipient_list.toString()}  </div>)
                    } return null
                  }
                },
                {
                  title: 'Mail BCC',
                  field: 'mail_bcc_recipient_list',
                  editComponent: (props) => {
                    const { value, onChange } = props
                    let updatedValue = "";
                    return (
                      <Input
                        {...props}
                        // value={Object.keys(value).length != 0 && value != null ? value:''}
                        value={value ? value : ''}
                        onChange={(e) => {
                          onChange(e.target.value)
                        }}
                        style={{ minWidth: value != null ? "250px" : "310px" }}
                        placeholder={value === null || "" ? "Separate multiple addresses by comma(s)." : null}
                      />
                    )
                  },
                  render: (rowData: any) => {
                    const { mail_bcc_recipient_list } = rowData
                    if (mail_bcc_recipient_list != null) {
                      return (<div style={{ minWidth: mail_bcc_recipient_list != null ? "250px" : null }}>  {mail_bcc_recipient_list.toString()}  </div>)
                    } return null;
                  }

                },
                {
                  title: 'Last successful run time',
                  field: 'last_successful_run',
                  editable: 'never',
                  render: (rowData: any) => {
                    if (rowData.last_successful_run) {
                      const { last_successful_run } = rowData
                      if (last_successful_run["last_successful_run_time"] != null) {
                        return (<div style={{ minWidth: last_successful_run["last_successful_run_time"] != null ? "250px" : null }}>  {last_successful_run["last_successful_run_time"]}  </div>)
                      }
                    } return null
                  }
                },
                // { title: 'HTML Template', field: 'html_template' },
                // { title: 'Service Provider', field: 'service_provider' }

              ]}
              data={
                query =>
                  new Promise((resolve, reject) => {
                    replaceHistory(query, props.history);
                    let pageData = {
                      data: [] as BusinessQueueTasks[],
                      totalCount: 0,
                      page: query.page
                    };
                    let orderBy = '';
                    if (query.orderBy) {
                      orderBy = query.orderBy.field as string;
                      orderBy = `${query.orderDirection && query.orderDirection === 'desc' ? '-' : ''}${orderBy}`;
                      // console.log("DIRECTION",orderBy)
                    }
                    getBusinessQueues(
                      query.page + 1,
                      query.pageSize,
                      query.search,
                      selectedBusiness,
                      selectedTask,
                      orderBy,
                    )
                      .catch(handleError) // handle network issues
                      .then(checkStatus)
                      .then(parseJSON)
                      .then((data: PaginatedResponse<BusinessQueueTasks>) => {
                        setErrorStatusVisible(false);
                        setEmptyDataSourceMessage(EMPTY_ROW_MSG);
                        pageData = {
                          data: data.results,
                          totalCount: data.count,
                          page: query.page
                        };
                        setTableQuery({
                          ...tableQuery,
                          orderBy,
                          totalCount: data.count,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      })
                      .catch((error: any) => {
                        if (error) {
                          if (error.status && error.status === 500) {
                            setEmptyDataSourceMessage(ERROR_500);
                            setErrorStatusVisible(false);
                          } else if ((error.status === 403 || error.status === 401)
                          ) {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(true);
                          } else {
                            setEmptyDataSourceMessage(ERROR_MSG);
                            setErrorStatusVisible(false);
                          }
                        }
                        setTableQuery({
                          ...tableQuery,
                          totalCount: 0,
                          page: query.page,
                          pageSize: query.pageSize
                        });
                        resolve(pageData);
                      });
                  })
              }
              title={
                <CustomTableTitle
                  history={props.history}
                  title={'Business Queue Tasks'}
                  icon={SVG_ICONS.businessQueue}
                  showHelp={true}
                  helpText={
                    <div>
                      <Typography variant="body1" style={{ padding: '4px 8px' }}>
                        {`In this screen you can edit the row(s),can select and schedule task and can run the task(s)`}
                      </Typography>
                      <Typography variant="body1" style={{ padding: '4px 8px' }}>
                        {`Separate multiple email addresses by comma(s).`}
                      </Typography>
                      <Typography variant="subtitle2" style={{ padding: '4px 8px' }}>
                        {`Note: Please select a single task and schedule, you cannot schedule more than one task at a time `}
                      </Typography>
                    </div>
                  }
                />
              }
              onSelectionChange={(data) => {
                if (data.length === 1) {
                  setSelectedQueues(data)
                  setSelectedQueueForCron(data)
                }
                setSelectedQueues(data)
              }
              }
              actions={[

                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
                {
                  icon: 'schedule',
                  tooltip: 'Schedule the Task',
                  position: "toolbarOnSelect",
                  disabled: selectedQueues.length === 1 && selectedQueueForCron.length === 1 ? false : true,
                  onClick: () => {
                    setConfirmTimePickerModal(true)
                  },
                },
                {
                  icon: 'play_arrow',
                  tooltip: 'Run Now',
                  position: "toolbarOnSelect",
                  onClick: () => {
                    setConfirmSyncModal(true);
                  },
                },

              ]}
              editable={tableEditableProp ? {
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      resolve(getUpdateFn(newData));
                    }, 1000)
                  }),
              } : null}

            />


          </div>
        </div>
      </div>
    )
  }

  const getQueueName = (queue: any) => {
    if (queue === 'korona_db_sync') {
      return ("Korona DB Sync")
    }
    if (queue === 'custom_reports') {
      return ("Custom Reports")
    }
    if (queue === 'rms_db_sync') {
      return ("RMS DB Sync")
    }
    if (queue === 'korona_load_older_receipts') {
      return ("Korona Load Older Receipts")
    }
    if (queue === 'receipt_index_catch_up') {
      return ("Receipt Index Catch Up")
    }
  }

  const renderDialog = () => {
    return (
      <div>
        {
          confirmTimePickerModal && selectedQueueForCron.length === 1 &&
          <Dialog
            open={confirmTimePickerModal}
            onClose={handleTimePickerCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>{`For the selected task(s),${selectedQueueForCron[0].queue},Current Schedule is ${selectedQueueForCron[0].cron_string != null ? cronstrue.toString(selectedQueueForCron[0].cron_string).replace("At", "at") : 'not set '}.`}</DialogTitle>
            <DialogContent style={{ alignSelf: 'center' }}>
              <div >
                <FormControl
                  style={{
                    marginRight: 16,
                  }}>

                  <Select
                    // labelId="select-label"
                    id="select-cron-time"
                    value={selectedTime}
                    onChange={handleCronTimeChange}
                    className={classes.select}

                  >
                    <MenuItem value={"10"}>Every 10th min</MenuItem>
                    <MenuItem value={"20"}>Every 20th min</MenuItem>
                    <MenuItem value={"40"}>Every 40th min</MenuItem>
                    <MenuItem value={"24"}>Every 24 hrs</MenuItem>
                    <MenuItem value={"0"} >On Demand</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {
                selectedCronValue === "24" &&
                <div>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        // margin="normal"
                        id="time-picker"
                        label="Time picker"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div>
                    {
                      utcTime != '' ?
                        <Typography>
                          {`UTC Time:${utcTime}`}
                        </Typography>
                        : null
                    }
                  </div>
                </div>
              }
              {
                isLoading &&
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleTimePickerCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleTimePickerAgree(selectedQueueForCron)} style={{ color: "#ff1744" }} >
                OK
              </Button>
            </DialogActions>


          </Dialog>

        }
        {
          confirmSyncModal && selectedQueues.length === 1 &&

          <Dialog
            open={confirmSyncModal}
            onClose={handleSyncCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>
              {<div>
                {`For the selected task(s),${selectedQueues[0].queue}, current schedule is ${selectedQueues[0].cron_string != null ? cronstrue.toString(selectedQueues[0].cron_string).replace("At", "at") : 'not set'}. `}<div>{`Do you want to run now?`} </div>
              </div>
              }
            </DialogTitle>
            <DialogContent style={{ alignSelf: 'center' }}>
              {
                isLoading &&
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSyncCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleSyncAgree(selectedQueues)} style={{ color: "#ff1744" }} >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }

        {
          confirmSyncModal && selectedQueues.length > 1 &&

          <Dialog
            open={confirmSyncModal}
            onClose={handleSyncCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle style={{ color: GlobalStyles.primaryColor }}>{`For the selected task(s), multiple queues for multiple businesses, do you want to run now? `}</DialogTitle>
            <DialogContent style={{ alignSelf: 'center' }}>
              {
                isLoading &&
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSyncCancel} color="primary" autoFocus>
                Cancel
              </Button>
              <Button onClick={() => handleSyncAgree(selectedQueues)} style={{ color: "#ff1744" }} >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }

  const handleBusinessChange = (event: any) => {
    console.log("selectedBusiness", event)
    setSelectedBusiness(event.target.value);
  }

  const handleTaskChange = (event: any) => {
    setSelectedTask(event.target.value);
  }

  return (
    <div style={{ maxWidth: '100%', marginTop: -16 }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>
        <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
          Filter by Task:
        </Typography>
        <FormControl
          style={{ }}>
          <Select
            labelId="select-label"
            id="select-task"
            value={selectedTask}
            onChange={handleTaskChange}
            className={classes.select}
            disabled={tableLoading}
          >
            { sortBy(BusinessQueueTypes, 'value').map((b: any) => (
            <MenuItem value={b.type} key={String(b.id)}>
              {b.value}
            </MenuItem>
          ))}
            {/* <MenuItem value={"custom_reports"}>Custom Reports</MenuItem>
            <MenuItem value={"korona_db_sync"}>Korona DB Sync</MenuItem>
            <MenuItem value={"korona_load_older_receipts"}>Korona Load Older Receipts</MenuItem>
            <MenuItem value={"receipt_index_catch_up"} >Receipt Index Catch Up</MenuItem>
            <MenuItem value={"rms_db_sync"}>RMS DB Sync</MenuItem>
            <MenuItem value={"es_sync_sales_report"}>ES Sales Data Summary</MenuItem>
            <MenuItem value={"homesome_sales_report"}>Homesome Sales Report</MenuItem>
            <MenuItem value={"korona_receipts_only_sync"}>Korona Receipts Only Sync</MenuItem>
            <MenuItem value={"stock_order_customer_order"}>Stock Order Customer Order</MenuItem>
            <MenuItem value={"customer_order_stock_receipt"}>Customer Order Stock Receipt</MenuItem>
            <MenuItem value={"notification_events"}>Notification Events</MenuItem> */}
          </Select>
        </FormControl>
        <Tooltip title="Clear task" placement="bottom">
          <IconButton onClick={() => setSelectedTask('')} style={{ marginRight: 16 }}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
        {<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }} >
          <Typography style={{ paddingRight: 10, color: GlobalStyles.primaryColor }}>
            Filter by Business:
          </Typography>
          <FormControl
            style={{  }}>
            <Select
              labelId="select-label"
              id="select-business"
              value={selectedBusiness}
              onChange={handleBusinessChange}
              className={classes.select}
              disabled={tableLoading}
            >
              {
                props.businessList &&
                props.businessList.data &&
                sortBy(props.businessList.data, 'name').map((b: Business) => (
                  <MenuItem value={b.id} key={String(b.id)}>
                    {b.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Tooltip title="Clear business" placement="bottom">
            <IconButton onClick={() => setSelectedBusiness('')}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </div>
        }
      </div>
      <Divider className={classes.divider} />
      {props.businessList && props.businessList.data && renderTable()}
      {renderDialog()}

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    businessList: state.sharedModuleData.businessList,
    userData: state.userLocalData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBusinessList: () => dispatch(getBusinessListFromAPI()),
    showToast: (toast: Toast) => dispatch(enqueueSnackbar(toast)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessQueueScreen);
