export const GET_STOCK_ORDER_PRODUCTS = 'GET_STOCK_ORDER_PRODUCTS';
export const GET_STOCK_ORDER_PRODUCTS_SUCCESS = 'GET_STOCK_ORDER_PRODUCTS_SUCCESS';
export const GET_STOCK_ORDER_PRODUCTS_FAILURE = 'GET_STOCK_ORDER_PRODUCTS_FAILURE';
export const CLEAR_STOCK_ORDER_PRODUCTS = 'CLEAR_STOCK_ORDER_PRODUCTS';

export const UPDATE_STOCK_ORDER_PRODUCT_IN_LIST = 'UPDATE_STOCK_ORDER_PRODUCT_IN_LIST';
export const ADD_NEW_SO_ITEM = 'ADD_NEW_SO_ITEM';
export const ADD_NEW_CREATE_SO_ITEM = 'ADD_NEW_CREATE_SO_ITEM';
export const ADD_NEW_CREATE_SO_ITEM_BULK = 'ADD_NEW_CREATE_SO_ITEM_BULK';
export const EDIT_NEW_CREATE_SO_ITEM = 'EDIT_NEW_CREATE_SO_ITEM';
export const REMOVE_NEW_CREATE_SO_ITEM = 'REMOVE_NEW_CREATE_SO_ITEM';
export const CLEAR_NEW_CREATE_SO_ITEMS = 'CLEAR_NEW_CREATE_SO_ITEMS';

export const CREATE_STOCK_ORDER = 'CREATE_STOCK_ORDER';
export const CREATE_STOCK_ORDER_SUCCESS = 'CREATE_STOCK_ORDER_SUCCESS';
export const CREATE_STOCK_ORDER_FAILURE = 'CREATE_STOCK_ORDER_FAILURE';

export const GET_STOCK_ORDER_SUMMARY = 'GET_STOCK_ORDER_SUMMARY';
export const GET_STOCK_ORDER_SUMMARY_FAILURE = 'GET_STOCK_ORDER_SUMMARY_FAILURE';
export const GET_STOCK_ORDER_SUMMARY_SUCCESS = 'GET_STOCK_ORDER_SUMMARY_SUCCESS';
export const DELETE_STOCK_ORDER_PRODUCT_IN_LIST='DELETE_STOCK_ORDER_PRODUCT_IN_LIST'
